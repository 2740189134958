import React from 'react';
import Button from '@material-ui/core/Button/Button';
import IntlMessages from '../utils/IntlMessages';
import history from '../utils/history';

const FormControlButtons = ({handleSubmit, applyTextId = 'audit.apply', saveTextId = 'audit.save'}) => {
    return (
        <div>
            <Button className="FormControlButtons"
                onClick={() => handleSubmit(false)}
                type="submit"
                variant="contained"
                color="primary"
            >
                <IntlMessages id={saveTextId}/>
            </Button>
            <Button
                className="FormControlButtons FormControlButtonsFormat"
                onClick={() => history.goBack()}
                variant="contained"
                color="secondary"
            >
                <IntlMessages id="button.back"/>
            </Button>

            {/*<Button*/}
            {/*    className="FormControlButtons FormControlButtonsFormat"*/}
            {/*    onClick={() => handleSubmit(true)}*/}
            {/*    type="submit"*/}
            {/*    variant="contained"*/}
            {/*    color="default"*/}
            {/*>*/}
            {/*    <IntlMessages id={applyTextId}/>*/}
            {/*</Button>*/}

        </div>
    );
};

export default FormControlButtons;
