import React from 'react';
import Classes from './Classes';
import callApi from "../../../utils/call-api";
import config from '../../../config';
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth';
import Lottie from 'react-lottie';
import logoutLottie from '../../../assets/lottie/sign_out.json';
import {Declination} from "../../Declination";
import redDotLottie from '../../../assets/lottie/red_dot.json';
import './styles/Student.scss';
import TimeTable from '../common/timeTable/TimeTable';
import EditProfile from '../common/editProfile/EditProfile';
import IntlMessages from '../../../utils/IntlMessages';
import lang from '../../../utils/lang';
// import switchAccountLottie from '../../../assets/lottie/switch_account.json';
// import bellLottie from '../../../assets/lottie/bell.json';
// import buyLottie from '../../../assets/lottie/buy.json';
// import chatLottie from '../../../assets/lottie/chat.json';
// import dictionaryLottie from '../../../assets/lottie/dictionary.json';
// import monitorLottie from '../../../assets/lottie/monitor.json';

const defaultLottieOptions = {
    loop: true,
    autoplay: false,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

class AdminTeacher extends React.Component {
    state = {
        // isActive: false,
        // isActiveFL: false,

        dialogVisible: false,
        lessonsCount: 0,
        currentMonthGroupLessonsCount: 0,
        currentMonthVipLessonsCount: 0,
        futureLessonsCount: 0,
        avatarLink: `${config.imageStubGeneratorUrl}/50x50.png`,
        defaultUsername: lang('Аноним'),
        unreadNewsCount: 1,
        homeworksCount: 1,
        conductedLessons: [],
        futureLessonsData: [],
        nextLessonData: [],
        conductedLessonsIsOpen: false,
        futureLessonsDataIsOpen: false,
        nextLessonDataIsOpen: true,
        lottiesParams: {
            switchAccountLottie: { isPaused: true },
            logoutLottie: { isPaused: true },
            bellLottie: { isPaused: true },
            buyLottie: { isPaused: true },
            chatLottie: { isPaused: true },
            dictionaryLottie: { isPaused: true },
            monitorLottie: { isPaused: true }
        },
        isShowTimeTable: false,
        isShowEditProfile: false,
        subscriptionsData: {},
    };

    componentDidMount() {
        this.init();
    }

    init = () => {
        callApi('dashboard/teachersMain', 'GET').then(res => {
            if (res && res.data.ok) {
                this.setState({
                    // allStudentsData: res.data.allStudentsData,
                    studentsData: res.data.studentsData,
                    conductedLessons: res.data.conductedLessons.sort((i1,i2)=>{
                        if (i1.startDate > i2.startDate){
                            return -1;
                        }
                        if (i1.startDate < i2.startDate){
                            return 1;
                        }
                        if (i1.startDate === i2.startDate){
                            return 0;
                        }
                    })||[],
                    futureLessonsData: res.data.futureLessonsData||[],
                    nextLessonData: res.data.nextLessonData ? [res.data.nextLessonData] : [],
                    lessonsCount: res.data.conductedLessons ? res.data.conductedLessons.length : 0,
                    futureLessonsCount: res.data.futureLessonsData ? res.data.futureLessonsData.length : 0
                    // currentMonthGroupLessonsCount: res.data.currentMonthGroupLessonsCount,
                    // currentMonthVipLessonsCount: res.data.currentMonthVipLessonsCount,
                });
            } else {
                console.log(res.data.message);
            }
        }).catch(e => {
            this.props.logout();
            console.log(e);
        });
    }

    ToggleFurtherLessonsMenu = () => {
        this.setState({ isActive: !this.state.isActive })
    };

    ToggleFinishedLessonsMenu = () => {
        this.setState({ isActiveFL: !this.state.isActiveFL })
    };

    activateDialog() {
        this.setState({ dialogVisible: true })
    }

    deactivateDialog() {
        this.setState({ dialogVisible: false })
    }

    setLottieParam(lottieName, param, value) {

        if (typeof lottieName !== 'string' || typeof param !== 'string' || value === undefined) {

            return;
        }

        this.setState(prevState => {
            const lottiesParams = { ...prevState.lottiesParams };
            lottiesParams[lottieName][param] = value;

            return {
                lottiesParams: { ...prevState.lottiesParams }
            }
        })
    }

    render() {
        const storage = JSON.parse(localStorage.getItem('user') || null);
        let avatarLink = this.state.avatarLink;
        let username = this.state.defaultUsername;
        const subscriptionsData = this.state.subscriptionsData;

        if (storage) {

            if (storage.username) {
                username = storage.username;
            }

            if (storage.avatarLink) {
                avatarLink = storage.avatarLink;
            }
        }

        return (
            <div className="student">
                <TimeTable
                    closeModal={() => this.setState({ isShowTimeTable: false })}
                    allStudentsData={this.state.studentsData}
                    isShowTimeTable={this.state.isShowTimeTable}
                    subscriptionsData={subscriptionsData}
                    timeTableType="teacher"
                />
                <EditProfile
                    closeModal={() => this.setState({ isShowEditProfile: false })}
                    isShowEditProfile={this.state.isShowEditProfile}
                    // updateStudent={() => this.init()} // TODO
                />
                <div className='statistics'>
                    <div className='statistics-wrap-left'>
                        <div className="stat-left-block">
                            <div className="stat-left-block-header">
                                <IntlMessages id="Всего в EpicLang" /><br />
                                <IntlMessages id="у вас было" />
                            </div>
                            <div className="stat-left-block-num">
                                {this.state.lessonsCount}
                            </div>
                            <div className="stat-left-block-footer">
                                {lang(Declination(this.state.lessonsCount, 'homePage'))}
                            </div>
                        </div>
                        
                    </div>
                    <div className='statistics-wrap-middle'>
                        <div className="stat-group-block">
                            
                            <div className="stat-group-block-part">
                                <div className={"stat-group-block-row blue-border scale" +
                                    (this.state.isShowTimeTable ? ' blue-table' : '')}>
                                    <div className="stat-group-block-title max-width"
                                        onClick={() => this.setState({isShowTimeTable: !this.state.isShowTimeTable})}
                                    >
                                        <IntlMessages id="Моё" />
                                        <br />
                                        <IntlMessages id="расписание" />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="panel right-panel user-data border-with-shadow"
                     onClick={() => this.setState({ isShowEditProfile: !this.state.isShowEditProfile })}
                >
                    <div className="panel-img-wrap right-panel-img-wrap">
                        <img className="id-photo"
                             src={this.props.userInfo?.userInfo?.avatar ?
                                 this.props.userInfo?.userInfo?.avatar :
                                 avatarLink}
                             alt={lang('Avatar')}
                        />
                    </div>
                    <div className="panel-nav right-panel-nav">
                        {username}
                    </div>
                </div>
                <div className="panel right-panel right-panel-logout border-with-shadow"
                    onClick={() => this.props.logout()}
                    onMouseEnter={() => this.setLottieParam('logoutLottie', 'isPaused', false)}
                    onMouseLeave={() => this.setLottieParam('logoutLottie', 'isPaused', true)}
                >
                    <div className="panel-img-wrap right-panel-img-wrap">
                        <div className="logout-lottie">
                            <Lottie
                                options={{
                                    ...defaultLottieOptions,
                                    animationData: logoutLottie
                                }}
                                isStopped={this.state.lottiesParams.logoutLottie.isPaused}
                            />
                        </div>
                    </div>
                    <div className="panel-nav right-panel-nav">
                        <IntlMessages id="Выйти из" />
                        <br />
                        <IntlMessages id="аккаунта" />
                    </div>
                </div>
                <div className="logo-wrap">
                    <div className="red-dot-lottie">
                        <Lottie
                            options={{
                                ...defaultLottieOptions,
                                autoplay: true,
                                animationData: redDotLottie
                            }}
                        />
                    </div>
                    <div
                        data-w-id="3356b772-9822-f0e3-b397-a5a92641c48a"
                        data-animation-type="lottie"
                        data-src={config.DomainForFront + "/icons/5eb8e3d5c32e37860e3f363f_lf30_editor_t3fyRp.json"}
                        data-loop="1"
                        data-direction="1"
                        data-autoplay="1"
                        data-is-ix2-target="0"
                        data-renderer="svg"
                        data-default-duration="1.0666666666666667"
                        data-duration="0"
                        className="lottie-animation-48"
                    >
                    </div>
                    <div className="logo-img"/>
                </div>
                <div className="schedule">
                    <Classes
                        updateStudent={() => {
                            this.init();
                        }}
                        nextLessonData={this.state.nextLessonData}
                        isOpen={this.state.nextLessonDataIsOpen}
                        lessonsCount={this.state.nextLessonData.length}
                        allStudentsData={this.state.studentsData}
                    />
                    <Classes
                        updateStudent={() => {
                            this.init();
                        }}
                        futureLessonsData={this.state.futureLessonsData}
                        isOpen={this.state.futureLessonsDataIsOpen}
                        lessonsCount={this.state.futureLessonsCount}
                        allStudentsData={this.state.studentsData}
                    />
                    <Classes
                        updateStudent={() => {
                            this.init();
                        }}
                        conductedLessons={this.state.conductedLessons}
                        isOpen={this.state.conductedLessonsIsOpen}
                        lessonsCount={this.state.lessonsCount} // LessonsCount === conductedLessonsCount
                        allStudentsData={this.state.studentsData}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    userInfo: auth.userInfo,
});

export default connect(mapStateToProps, {logout})(AdminTeacher);
