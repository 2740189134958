import React from 'react';
import { DragSource } from 'react-dnd';

export const Box = ({ name, isDropped, connectDragSource, isHomework }) => {
    const style = (isDropped && !isHomework) ?
        { opacity: '0.4', border: '1px solid gray', pointerEvents: 'none' } :
        { opacity: '0.99' }

    return connectDragSource(
        <div style={style} className="Dad2Box">{name}</div>
    )
};
export default DragSource(
    props => props.type,
    {
        beginDrag: props => ({
            name: props.name,
            className: 'DnDItemsBox',
        }),
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }),
)(Box);
