import React from "react";

const Footer = () => (
    // <footer className="app-footer">
    //     <div className="d-flex flex-row">
    //         <div>
    //             &copy; 2020
    //         </div>
    //     </div>
    // </footer>
    <></>
);

export default Footer;