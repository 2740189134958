import React, { useState, useEffect } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { SimpleTreeView ,TreeItem} from '@mui/x-tree-view';




import callApi from '../../../utils/call-api';


const LessonContent = ({ open, title, contentType, lang, lessonList, onDone, onClose}) => {
    const [isHomeWork, setIsHomework] = useState(contentType);
    const [language, setLanguages] = useState(lang);
    const [lessons, setLessons] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if(open){
            loadCourses()
            .then(setSelectedItems(lessonList));    
        }
    }, [open, lessonList]);

    const loadCourses = async() => {
        await callApi('content/lessons', 'GET', {lenguageId: language.id, isHomeWork: isHomeWork})
        .then(courses => courses.data.lessons)
        .then(data => {
            setLessons(data);
        })
        .catch(err => {
            console.log(err);            
        });
    }


    const createTree=(data) => {
        return (<>
            {data.map(item => {
                if (item.courses.length==1){
                    console.log(item.courses[0].name+item.courses[0].id);
                    return <TreeItem itemId={`${item.courses[0].name}-${item.courses[0].type}`} label={`${item.name} - ${item.courses[0].type} (${item.language})`}>
                        {item.courses[0].lessons.map(lesson => {
                            return <TreeItem itemId={lesson.id} label={lesson.name} />
                        })}
                    </TreeItem> 
                } else {
                    return <TreeItem itemId={item.name} label={`${item.name} (${item.language})`}>
                        {item.courses.map(course => {
                            return <TreeItem itemId={`${item.courses[0].name}-${item.courses[0].type}`} label={course.type}>
                                {course.lessons.map(lesson => {
                                    return <TreeItem itemId={lesson.id} label={lesson.name} />
                                })}
                            </TreeItem>
                        })}
                    </TreeItem>
                }
            })}

        </>);
    }
    const handleSelectedItemsChange = (event, ids) => {
        setSelectedItems(ids);
      };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <SimpleTreeView
                        selectedItems={selectedItems}
                        onSelectedItemsChange={handleSelectedItemsChange} 
                        checkboxSelection
                        multiSelect={isHomeWork ? true:false}
                    >
                        {createTree(lessons)}
                    </SimpleTreeView>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onDone(selectedItems)} color="primary">
                    Принять
                </Button>
                <Button onClick={onClose} color="primary">
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>       
    );
};

export default LessonContent;
