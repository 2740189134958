import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import history from '../utils/history';

const styles = theme => ({
    dialogContent: {
        minWidth: 500,
        [theme.breakpoints.up('sm')]: {
            minHeight: 120
        }
    }
});

class Dialog extends Component {
    state = {
        open: false,
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.HideDialog();
    };

    componentWillMount() {
        setTimeout(() => {
            this.setState({
                open: this.props.show
            });
        }, 500);
    }

    componentWillUnmount() {
        if (window.location.pathname === '/resetstep2'
            || window.location.pathname === '/confirm'
            || window.location.pathname === '/signup') {
            history.push(global.authUrl);
        }
    }

    render() {
        const {classes, title, text} = this.props;

        return (
            <div>
                <MUIDialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText>
                            {text}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            Отмена
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </MUIDialog>
            </div>
        );
    }
}

export default withStyles(styles)(Dialog);
