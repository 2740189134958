import React, {useEffect, useState} from "react";
import './AddInterestingFacts.css'
import callApi from "../../../../utils/call-api";


const EditInterestingFacts = ({setActiveList, setActiveEdit, activeEditFacts}) => {

    const [editFact, setEditFact] = useState(activeEditFacts);
    const [getCourses, setGetCourses] = useState([]);
    const [getSystemFiles, setGetSystemFiles] = useState();
    const [selectedActive, setSelectedActive] = useState('');
    const [enterObjName, setEnterObjName] = useState(false);

    console.log(getSystemFiles)

    const lang = textId => textId;

    function selected(value) {
        if (selectedActive) {
            setSelectedActive('')
        } else {
            setSelectedActive(value)
        }
    }

    async function sendInterestingFacts() {

        if(editFact.name) {
            const formData = new FormData();

            //выборка dashboard/getVirtualLessonEndInformation
            if (!editFact.id) {//Fact id
                alert(lang('Fact id is missing'));

                return;
            }

            if (editFact.languageId) {
                formData.append('languageId', editFact.languageId);
            }

            if (editFact.courseId) {
                formData.append('courseId', editFact.courseId);
            }

            if (editFact.type) {
                formData.append('type', editFact.type);
            }
            
            if (editFact.description) {
                formData.append('description', editFact.description);
            }
            
            if (editFact.descriptionRu) {
                formData.append('descriptionRu', editFact.descriptionRu);
            }
            
            formData.append('id', editFact.id);
            formData.append('name', editFact.name);
            formData.append('isEnabled', editFact.isEnabled);

            if (editFact.image) {
                formData.append('file', editFact.image);
            }

            await callApi('dashboard/updateVirtualLessonEndInformation', 'post', formData).then(res => {
    
                if (res && res.data.ok) {
                    console.log(res.data.message);
                    alert(lang(res.data.message));
                } else {
                    alert(lang(res.data.message));
                }
            }).catch(err => {
                alert(lang('Cannot update intersting fact'));
                console.log(err);
            });

            setEnterObjName(false);
        } else {
            setEnterObjName(true);
        }

    }

    useEffect(() => {
        async function getCourses() {
            const courses = [];

            await callApi('dashboard/getCourses', 'post').then(res => {

                if (res && res.data.ok) {
                    res.data.courses.forEach(el => {
                        courses.push(el);
                    });
                } else {
                    alert(lang(res.data.message));
                }
            }).catch(err => {
                alert(lang('Receive courses data error'));
                console.log('err', err);
            });

            setGetCourses(courses);
        }

        getCourses();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const result = await callApi('dashboard/getSystemFilesAdmin', 'post', {});
            console.log('result',result)
            const filesData =  result && result.data && result.data.filesData || [];
            const prepareData = filesData.map(el => {
                return el
            });

            setGetSystemFiles(prepareData);
        };

        fetchData();
    }, []);

    function showSelected() {
        const languageArr = [
            { languageId: 2, language: 'english' },
            { languageId: 4, language: 'french' },
        ];

        return (
            <div className='show_selected_container'>
                {selectedActive === 'language' ? languageArr.map(item => {

                    return (
                        <div className='show_selected_line' onClick={() => {
                            setEditFact({...editFact, languageId: item.languageId});
                            setSelectedActive('');
                        }}>{item.language}</div>
                    )
                }) : null}
                {selectedActive === 'courses' ? getCourses.map(item => {

                    return (
                        <div className='show_selected_line' onClick={() => {
                            setEditFact({...editFact, courseId: item.id});
                            setSelectedActive('');
                        }}>{`${item.name}(${item.type})`}</div>
                    )
                }) : null}
            </div>
        )
    }



    return (
        <React.Fragment>
            <div className='facts_header'>
                <div className='facts_header_selects'>
                    <div className={selectedActive === 'language' ? 'selected_container active' : 'selected_container'}
                         onClick={()=>selected('language')}>
                        <div className='selected_language'>{editFact.languageId === 2 ? 'English' : editFact.languageId === 4 ? 'French' : null}</div>
                        <div className={selectedActive === 'language' ? 'selected_arrow active' : 'selected_arrow'}>></div>
                    </div>
                    {selectedActive === 'language' ? showSelected() : null}
                </div>
                <div className='facts_header_selects'>
                    <div className={selectedActive === 'courses' ? 'selected_container active' : 'selected_container'}
                         onClick={()=>selected('courses')}>
                        {getCourses ? getCourses.map(item => {
                            if (item.id === editFact.courseId) {
                                return (
                                    <div className='selected_language'>{item.name + '(' +item.type +')'}</div>
                                )
                            }
                        }) : null}

                        <div className={selectedActive === 'courses' ? 'selected_arrow active' : 'selected_arrow'}>></div>
                    </div>
                    {selectedActive === 'courses' ? showSelected() : null}
                </div>
            </div>
            <div className='lottie_upload_file'>
                Загрузите файл(замена существующего):
                <input name="file" type="file" onChange={e => setEditFact({...editFact, image: e.target.files[0]})} />
            </div>
            <div className='lottie_upload_name'>
                <p>Введите название факта:</p>
                <input
                    type="text"
                    placeholder={enterObjName && editFact.name.length === 0 ? 'Введите имя' : null}
                    className={enterObjName && editFact.name.length === 0 ? 'lottie_upload_input' : null}
                    value={editFact.name}
                    onInput={e => setEditFact({...editFact, name: e.target.value})}/>
            </div>
            <div className='lottie_upload_name'>
                <p>Введите тип факта:</p>
                <input
                    type="text"
                    value={editFact.type}
                    onInput={e => setEditFact({...editFact, type: e.target.value})}/>
            </div>
            <div className='facts_textarea'>
                <p>Введите интересный факт о выбраном языке:</p>
                <textarea
                    id="facts_text"
                    cols="30"
                    rows="10"
                    value={editFact.description}
                    onInput={e => setEditFact({...editFact, description: e.target.value})}
                ></textarea>
            </div>
            <div className='facts_textarea'>
                <p>Введите его перевод:</p>
                <textarea
                    id="facts_translate"
                    cols="30"
                    rows="10"
                    value={editFact.descriptionRu}
                    onInput={e => setEditFact({...editFact, descriptionRu: e.target.value})}
                ></textarea>
            </div>
            <div style={{marginLeft: '10px'}}>
                <input
                    type="checkbox"
                    checked={editFact.isEnabled}
                    onClick={e => e.target.checked ? setEditFact({...editFact, isEnabled: true}) : setEditFact({...editFact, isEnabled: false})}/>
                <span>Показывать</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className='facts_button' onClick={sendInterestingFacts}>Сохранить</div>
                <div className='facts_button' onClick={() => {
                    setActiveEdit(false);
                    setActiveList(true);
                }}>Отменить</div>
            </div>

        </React.Fragment>
    );
}

export default EditInterestingFacts;
