import * as types from '../constants/dictionary';

const numberWords = JSON.parse(localStorage.getItem('MyWordDataEN') || '[]').length;

const initialState = {
    isReload: false,
    openCloseDict: false,
    selectedObj: '',
    lessonScrollTop: 0,
    wordsCount: numberWords,
}

export default function dictionary (state = initialState, action) {
    switch (action.type) {
        case types.OPEN_DICTIONARY:
            return {
                ...state,
                openCloseDict: action.payload,
            };
        case types.CLOSE_DICTIONARY:
            return {
                ...state,
                openCloseDict: action.payload,
            };
        case types.SELECTION_WIN:
            return {
                ...state,
                selectedObj: action.payload,
            };
        case types.LESSON_SCROLL_TOP:
            return {
                ...state,
                lessonScrollTop: action.payload,
            };
        case types.WORDS_COUNT:
            return {
                ...state,
                wordsCount: action.payload,
            };
        case types.RE_LOAD:
            return {
                ...state,
                isReload: !state.isReload,
            }
        default:
            return state;
    }
};