import * as types from '../constants/dialog';

export function ShowDialog() {
    return dispatch => {
        dispatch({
            type: types.SHOW_DIALOG
        });
    };
}

export function dialogSelectLesson(lesson) {

    return dispatch => {
        dispatch({
            type: types.DIALOG_SELECT_LESSON,
            payload: lesson,
        });
    };
}

export function dialogSetWhiteboard(whiteboard) {
    return dispatch => {
        dispatch({
            type: types.DIALOG_SET_WHITEBOARD,
            payload: whiteboard,
        });
    };
}

export function dialogSetImgWhiteboard(showImgWhiteboard) {
    return dispatch => {
        dispatch({
            type: types.SET_IMG_WHITEBOARD,
            payload: showImgWhiteboard,
        });
    };
}

export function dialogSetSocketMessage(socketMessage) {
    return dispatch => {
        dispatch({
            type: types.SET_CHART_MESSAGE,
            payload: socketMessage,
        });
    };
}

export function HideDialog() {
    return dispatch => {
        dispatch({
            type: types.HIDE_DIALOG
        });
    };
}
