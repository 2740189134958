import React, { useState, useEffect } from 'react';

import MaterialTextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import {
    DataGrid,
    GRID_DATETIME_COL_DEF,
    getGridDateOperators,
  } from '@mui/x-data-grid';
import { Button } from '@mui/material';

import { enUS as locale } from 'date-fns/locale';
import format from 'date-fns/format';
 

import s from "../SuperAdmin.module.css";
import callApi from '../../../../utils/call-api';
import YesNoDialog from '../../../common/YesNoDialog';
import ErrorDialog from "../../../common/ErrorDialog";
import './Schedule.scss';

const ScheduleBoard = ({languageInfo, editSchedule}) => {
    const [language, setLanguages] = useState(languageInfo);
    const [startDate, setStartDate] = useState((new Date(new Date().getTime()-7*24*3600000).toISOString()).slice(0, 10));
    const [endDate, setEndDate] = useState();
    const [schedule, setSchedule] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState([]);
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
    const [isErrorShow, setIsErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        loadVl();
    }, [languageInfo]);
    
    useEffect(() => {
        loadVl();
    }, [startDate, endDate]);

    const loadVl=()=>{
        callApi("dashboard/schedule", "GET",{language:language.id, startDate: Date.parse(startDate)/1000, endDate:endDate? Date.parse(endDate)/1000: 0})
        .then(data=>data.data.schedule)
        .then(data=>data.map(item=>{
            item.studentId = item.students[0].id;
            item.student = `${item.students[0].name} ${item.students[0].surname}`;
            item.teacherId = item.teacher.id;
            item.teacher = `${item.teacher.name} ${item.teacher.surname}`;
            return item;
        }))
        .then(data=>{            
            setSchedule(data||[]);
        }).catch(err=>{ 
            console.log(err);
        });
    };

    const changeSelection=(row)=>{
        setSelectedLesson(row.filter(item=>item!=selectedLesson[0]));
    };

    const deleteLesson=async(lesson)=>{
      callApi('dashboard/deleteVl', 'post', {
        id: selectedLesson[0]
      }).then(() => {
        loadVl();
        setSelectedLesson([]);
        setIsShowDeleteDialog(false);        
      }).catch(err => {
        switch (err.response.data.message) {
          case "lesson is conducted":
            setErrorMessage("Нельзя удалить урок который уже проведен");
            setIsErrorShow(true);
            break;
          default:
            setIsErrorShow(true);
            break;
        }
      });
    };

    function GridFilterDateInput(props) {
        const { item, showTime, applyValue, apiRef } = props;
      
        const Component = showTime ? DateTimePicker : DatePicker;
      
        const handleFilterChange = (newValue) => {
          applyValue({ ...item, value: newValue });
        };
      
        return (
          <Component
            value={item.value ? new Date(item.value) : null}
            autoFocus
            label={apiRef.current.getLocaleText('filterPanelInputLabel')}
            slotProps={{
              textField: {
                variant: 'standard',
              },
              inputAdornment: {
                sx: {
                  '& .MuiButtonBase-root': {
                    marginRight: -1,
                  },
                },
              },
            }}
            onChange={handleFilterChange}
          />
        );
      }

    const dateTimeColumnType = {
        ...GRID_DATETIME_COL_DEF,
        resizable: false,
        renderEditCell: (params) => {
          return <GridEditDateCell {...params} />;
        },
        filterOperators: getGridDateOperators(true).map((item) => ({
          ...item,
          InputComponent: GridFilterDateInput,
          InputComponentProps: { showTime: true },
        })),
        valueFormatter: (value) => {
          if (value) {
            return format(value*1000, 'MM/dd/yyyy hh:mm a', { locale });
          }
          return '';
        },
      };

    return (
        <>
        <div className='schedule_board'>
            <div className='schedule_board_header'>
               <Button variant="contained" onClick={()=>editSchedule({})}>Добваит Урок</Button>
               <Button
                    disabled={selectedLesson.length!=1}
                    variant="contained" 
                    onClick={()=>editSchedule(schedule.find(item=>item.id==selectedLesson))}>
                        Редактировать
                </Button>
               <Button
                    disabled={selectedLesson.length!=1}
                    variant="contained" 
                    onClick={()=>{setIsShowDeleteDialog(true);}}>
                        Удалить
                </Button>
                <MaterialTextField 
                    label="C - "
                    type="date"
                    value={startDate}
                    onChange={val => setStartDate(val.target.value)}
                    className='object-TextField'
                />
                <MaterialTextField
                    label="По - "
                    type="date"
                    value={endDate}
                    onChange={val => setEndDate(val.target.value)}
                    className='object-TextField'
                />
            </div>
            <Box sx={{ height: '90%', width: '100%' }}>
            <DataGrid
                rows={schedule}
                columns={[{field: 'id', headerName: 'ID', width: 70, editable: false},
                    {field: 'name', headerName: 'название урока', width: 200, editable: false},
                    {field: 'teacher', headerName: 'Учитель', width: 150, editable: false}, 
                    {field: 'students', headerName: 'Студенты', width: 200, editable: false, valueFormatter: (students) => {
                       return students.map(item=>`${item.name} ${item.surname}`).join(', ');
                    }},
                    {
                      field: 'lessonDuration', 
                      headerName: 'Длительность урока', 
                      width: 100, 
                      editable: false,
                      valueFormatter: (duration) => {
                        return `${duration.duration} мин`;
                      }
                    },
                                      
                    {
                        field: 'startDate',
                        ...dateTimeColumnType,
                        headerName: 'Дата начала',
                        width: 170,
                        editable: true,
                      },
                      
                    {field: "isConducted", headerName: "Проведен", width: 50, editable: false},
                ]}
                rowSelectionModel={selectedLesson}
                onRowSelectionModelChange={changeSelection}
                checkboxSelection
                pagination
                autoPageSize
                disableMultipleRowSelection/>
            </Box>
        </div>
        {isShowDeleteDialog && selectedLesson.length==1 && <YesNoDialog
            open={isShowDeleteDialog&&selectedLesson.length==1}
            onCansel={() => setIsShowDeleteDialog(false)}
            onOk={() => {
                deleteLesson(selectedLesson[0]);
            }}
            title="Удалить урок?"
            message="Вы уверены, что хотите удалить урок?"
        />}
        <ErrorDialog open={isErrorShow} onClose={() => setIsErrorShow(false)} message={errorMessage}/>
        </>
    );
};

export default ScheduleBoard;