import React from 'react';
import Button from '@material-ui/core/Button/Button';
// import history from "../utils/history";
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';


const FormDialog = ({closeDialog, dialogVisible, titleId, contentId}) => (
    <Dialog
        open={dialogVisible}
        onClose={closeDialog}
    >

        <DialogTitle>

        </DialogTitle>
        <DialogContent>
            <div className="student-profile">
                <div className="student-profile-left">
                    <div className="student-profile-photo">
                        <img src="https://uploads-ssl.webflow.com/5ef03707b6da21108e2655b9/5ef03707b6da21f81a2655c7_IMG_8322.jpg" alt="Anna"/>
                    </div>
                </div>
                <div className="student-profile-right">
                    <div className="student-profile-name">
                        <div className="profile-content-item student-profile-name-txt">
                            Анна
                        </div>

                    </div>
                    <div className="about-myself">
                        <div className="profile-content-item">
                            <span>Страна: Россия</span>
                            <br/>
                            <span>Возраст: 27</span>
                            <br/>
                            <span>Языки: русский, английский, французский</span>
                            <br/>
                            <br/>
                            <br/>
                            <span>О себе:</span>
                            <br/>
                           <span> Люблю свою работу</span>
                        </div>
                    </div>
                </div>

            </div>

        </DialogContent>


        <DialogActions>
            <div className="close-profile">


                <Button onClick={closeDialog} color="primary">
                    X

                </Button>







            </div>

        </DialogActions>
    </Dialog>
);

export default FormDialog;





