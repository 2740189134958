import React, { useState, useCallback, useEffect } from 'react';
import Dustbin2Columns from './Dustbin2Columns';
import Box from './Box';
import update from 'immutability-helper';
import random from '../../../utils/generator/numbers';

import { studentAnswerWrongAudio, studentAnswerSuccessSounds } from '../helpers/SoundsHelper';

export const ContainerFor2Columns = ({
    isHomework,
    isHomeworkFinish,
    updateHomeworkErrors = () => {},
    updateHomeworkTasksInformation = () => {},
    resDustbin,
    resBox,
    socket,
    uniqId,
    socketMessage = {},
    val,
}) => {
    const [dustbins, setDustbins] = useState(resDustbin);
    const [boxes] = useState(resBox);
    const [previousMessage, setPreviousMessage] = useState('');
    const [droppedBoxNames, setDroppedBoxNames] = useState([]);

    function isDropped(boxName) {
        return droppedBoxNames.indexOf(boxName) > -1;
    }

    useEffect(() => {
        updateHomeworkTasksInformation(uniqId, boxes.length);
    }, []);

    const handleDrop = useCallback((dustbinName, dustbinIndex, item, flag = true) => {
            if (flag && !isHomework) {
                return socket.send(JSON.stringify({
                    command: 'handleDrop',
                    data: {
                        dustbinIndex,
                        item,
                        uniqId,
                        dustbinName,
                    },
                    val,
                }));
            }

            const { DroppedItems } = dustbins[dustbinIndex];

            if (dustbinName.includes(item.name) || isHomework) {
                setDroppedBoxNames(
                    update(droppedBoxNames, item.name ? { $push: [item.name] } : { $push: [] }),
                );

                updateHomeworkTasksInformation(uniqId);

                if (!dustbinName.includes(item.name)) {
                    updateHomeworkErrors(uniqId);
                }

                const variant = random(studentAnswerSuccessSounds.length);
                studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
            } else {
                studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
            }

            DroppedItems.push(item);

            setDustbins(
                update(dustbins, {
                    [dustbinIndex]: {
                        DroppedItems: {$set: DroppedItems}
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins],
    );

    if ((previousMessage !== JSON.stringify(socketMessage)) && (socketMessage.command === 'handleDrop') && (uniqId === socketMessage.data.uniqId)) {
        setPreviousMessage(JSON.stringify(socketMessage));
        handleDrop(socketMessage.data.dustbinName, socketMessage.data.dustbinIndex, socketMessage.data.item, false);
    }

    return (
        <div className="tests_flex">
            <div style={{ overflow: 'hidden', clear: 'both' }}>
                <div style={{ overflow: 'hidden', clear: 'both' }}>
                    {boxes.map(({ name, type }, index) => (
                        <Box
                            useOnlyOnce
                            isHomework={isHomework}
                            name={name}
                            type={type}
                            isDropped={isDropped(name)}
                            key={index}
                        />
                    ))}
                </div>
                <div className="Dustbin2Columns">
                {dustbins.map(({name, accepts, DroppedItems, i }, index) => (
                    <React.Fragment key={index+'_'}>
                        <Dustbin2Columns
                            isHomework={isHomework}
                            isHomeworkFinish={isHomeworkFinish}
                            dustbinName={name}
                            trueWords={i}
                            accepts={accepts}
                            DroppedItems={DroppedItems}
                            onDrop={item => handleDrop(i, index, item)}
                            key={index}
                        />
                    </React.Fragment>
                ))}
                </div>
            </div>
        </div>
    )
};
