import React, { useState, useCallback, useEffect } from 'react';
import Dustbin from './Dustbin';
import Box from './Box';
import update from 'immutability-helper';
import IconDictionary from '../../../assets/images/icons/icon_infinite.png';
import random from '../../../utils/generator/numbers';

import { studentAnswerWrongAudio, studentAnswerSuccessSounds } from '../helpers/SoundsHelper';

export const ContainerSpecial = ({
    isHomework,
    isHomeworkFinish,
    updateHomeworkErrors = () => {},
    updateHomeworkTasksInformation = () => {},
    resText,
    resDustbin,
    resBox,
    socket,
    uniqId,
    socketMessage = {},
    val,
}) => {
    const [dustbins, setDustbins] = useState(resDustbin);
    const [boxes] = useState(resBox);
    const [previousMessage, setPreviousMessage] = useState('');
    const [droppedBoxNames, setDroppedBoxNames] = useState([]);

    function isDropped(boxName) {
        return droppedBoxNames.indexOf(boxName) > -1
    }

    useEffect(() => {
        updateHomeworkTasksInformation(uniqId, boxes.length);
    }, []);

    const handleDrop = useCallback(
        (dustbinName, dustbinIndex, item, flag = true) => {
            if (flag) {
                socket.send(JSON.stringify({
                    command: 'handleDrop',
                    uniqId, 
                    data: {
                        dustbinIndex,
                        item, 
                        uniqId,
                        dustbinName,
                    },
                    val,
                }));
            }

            const { name } = item;

            if (dustbinName === name) {
                setDroppedBoxNames(
                    update(droppedBoxNames, name ? {$push: [name]} : {$push: []}),
                );

                const variant = random(studentAnswerSuccessSounds.length);
                studentAnswerSuccessSounds[variant].play();
                studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
            } else {
                studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
            }

            if (isHomework) {
                if (!dustbins[dustbinIndex].lastDroppedItem) {
                    updateHomeworkTasksInformation(uniqId);

                    if (dustbinName !== name) {
                        updateHomeworkErrors(uniqId, 1);
                        item.isError = true;
                    }
                } else {
                    item.isError = dustbins[dustbinIndex].lastDroppedItem.isError;

                    if (dustbins[dustbinIndex].lastDroppedItem.name !== name) {
                        if (dustbins[dustbinIndex].lastDroppedItem.isError && dustbinName === name) {
                            updateHomeworkErrors(uniqId, -1);
                            item.isError = false;
                        } else if (!dustbins[dustbinIndex].lastDroppedItem.isError && dustbinName !== name) {
                            updateHomeworkErrors(uniqId, 1);
                            item.isError = true;
                        }
                    }
                }
            }

            setDustbins(
                update(dustbins, {
                    [dustbinIndex]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedBoxNames, dustbins],
    );

    if ( (previousMessage !== JSON.stringify(socketMessage)) && (socketMessage.command === 'handleDrop') &&
        (uniqId === socketMessage.data.uniqId)) {
        setPreviousMessage(JSON.stringify(socketMessage));
        handleDrop(socketMessage.data.dustbinName, socketMessage.data.dustbinIndex, socketMessage.data.item, false);
    }

    return (
        <div >
            <div className="tests_flex" style={{marginTop: '10px'}}>
                <div style={{ overflow: 'hidden', clear: 'both' }}>
                    {boxes.map(({ name, type }, index) => (
                        <Box
                            isHomework={isHomework}
                            name={name}
                            type={type}
                            isDropped={isDropped(name)}
                            key={index}
                        />
                    ))}
                </div>
                {dustbins.map(({ accepts, lastDroppedItem, i }, index) => (
                    <React.Fragment key={index+'_'}>
                        <div className="ContainerSpecialWrapper">
                            <div style={{width: '50%'}}>
                                <div className="ContainerSpecialLeft">
                                    {resText[index] || ''}
                                </div>
                            </div>
                            <img src={IconDictionary} className="ContainerSpecialImgCenter"/>
                            <div style={{width: '50%'}}>
                                <Dustbin
                                    isHomework={isHomework}
                                    isHomeworkFinish={isHomeworkFinish}
                                    customWidth
                                    dustbinName={i}
                                    accepts={accepts}
                                    lastDroppedItem={lastDroppedItem}
                                    onDrop={(item) => handleDrop(i, index, item)}
                                    key={index}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                {dustbins.length < resText.length && resText[resText.length - 1]}
            </div>
        </div>
    )
};
