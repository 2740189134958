import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '../containers/Dialog';
import LogoBrand from '../components/LogoBrand';
import { Alert } from 'reactstrap';

import IntlMessages from "../utils/IntlMessages";

class ResetStep1 extends React.Component {
     constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

     static propTypes = {
        resetStep1: PropTypes.func.isRequired
    };

     state = {
        email: {
            value: '',
            isValid: true
        },
        openDialog: false
    };

    handleInputChange(event) {
        event.persist();

        const { name, value } = event.target;

        this.setState(prevState => ({
            [name]: {
                ...prevState[name],
                value
            }
        }));
    }

     handleSubmit(event) {
        event.preventDefault();

        const { email } = this.state;

        this.props.showAuthLoader();
        this.props.resetStep1(email.value);
    }

    componentWillMount() {
        this.props.hideAuthMessage();
    }

    render() {
        const { 
            loader,
            showMessage,
            alertTitle,
            dialog,
            alertMessage
        } = this.props;
        const { email } = this.state;
        return (
                <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                    <div className="app-login-main-content">

                        <div className="app-logo-content d-flex align-items-center justify-content-center">
                           <LogoBrand alt="FraudZeroider" />
                        </div>

                        <div className="app-login-content">
                        
                            <div className="app-login-header mb-4">
                            <h1><IntlMessages id="appModule.reset" /><span className="app-module-step text-muted"><IntlMessages id="appModule.step1" /></span></h1>
                            </div>

                            {showMessage && (
                                <Alert className="shadow-lg bg-danger text-white">
                                    <p style={{ marginBottom: 0 }}>
                                        {alertTitle}
                                    </p>
                                    { alertMessage ?
                                        <ul className="auth-errors">
                                            <li>{alertMessage}</li>
                                        </ul> : ''}
                                </Alert>
                            )}

                            <div className="app-login-form">
                                <form onSubmit={this.handleSubmit}>
                                    <fieldset>
                                        <TextField
                                            required
                                            type="email"
                                            name="email"
                                            label={<IntlMessages id="appModule.email"/>}
                                            value={email.value}
                                            fullWidth
                                            onChange={this.handleInputChange}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                            error={!email.isValid}
                                        />

                                        <div className="mb-3 d-flex align-items-center justify-content-between">
                                            <Button type="submit"
                                                    variant="contained"
                                                    color="primary">
                                                <IntlMessages id="button.reset"/>
                                            </Button>

                                            <Link to="/signup">
                                                <IntlMessages id="signIn.signUp"/>
                                            </Link>
                                        </div>                                    

                                    </fieldset>
                                </form>
                            </div>
                        </div>

                    </div>

                {loader && (
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                )}

                {dialog && <Dialog title={<IntlMessages id="appModule.checkYourEmail" />} text={<IntlMessages id="appModule.pleaseCheckYourEmail" />} />}

            </div>
        );
    }
}

export default ResetStep1;
