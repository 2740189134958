import React, { useState, useEffect } from 'react';

import { MenuItem, Select, InputLabel, Button } from '@mui/material';
import MaterialTextField from '@material-ui/core/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import moment from 'moment';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter
  } from '@mui/x-data-grid';


import callApi from '../../../../utils/call-api';
import "./Schedule.scss";
import ErrorDialog from "../../../common/ErrorDialog";
import FormDialog from '../../../../components/FormDialog';
import LessonContent from '../../../common/lessonContent/LessonContent';
import AddPaidLessonsDialog from './AddPaidLessonsDialog';


const ScheduleEdit = ({languageInfo, lesson, onBackClick}) => {
    const [titleText, setTitleText] = useState("Редактировать урок");   
    const [language, setLanguages] = useState(languageInfo);
    const [lessonDurations, setLessonDurations] = useState([]);
    const [lessonTypes, setLessonTypes] = useState([]);
    const [lessonDurationFilter, setLessonDurationFilter] = useState('');
    const [lessonName, setLessonName] = useState('');
    const [lessonDate, setLessonDate] = useState('');
    const [students, setStudents] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [activStudent, setActivStudent] = useState(false);
    const [isConducted, setIsConducted] = useState(false);
    const [studentSelection, setStudentSelection] = useState([]);
    const [teacherSelection, setTeacherSelection] = useState([]);


    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorShow, setIsErrorShow] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [lessonInfo, setLessonInfo] = useState(lesson);
    const [homeworks, setHomeworks] = useState("");
    const [isShowHomework, setIsShowHomework] = useState(false);
    const [isShowAddPaidLessonsDialog, setIsShowAddPaidLessonsDialog] = useState(false);
    const [isGroupLesson, setIsGroupLesson] = useState(false);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            addLessonToSchedule();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    useEffect(() => {
        loadLessonDurations();
        loadStudents();
        loadTeachers(); 
        loadLessonTypes();      
    }, []);

    useEffect(() => {
        if (Object.keys(lesson).length == 0) {
            setLessonName('');
            setLessonDate('');
            setLessonDurationFilter('');                      
        } else {
            setLessonName(lesson.name);
            setLessonDate(moment(new Date(lesson.startDate*1000)).format('YYYY-MM-DDTHH:mm'));
            setLessonDurationFilter( lesson.lessonDuration.id);
            setStudentSelection([lesson.studentId]);
            setTeacherSelection([lesson.teacherId]);
            setHomeworks(lesson.homeworks.filter(item=>item.isHomework==1).map(item=>item.lesson));                  
        }
        setLessonInfo(lesson);
        
    }, [lesson]);

    useEffect(() => {
        loadStudents();
    }, [activStudent]);

    const loadTeachers = () => {
        callApi("dashboard/users", "GET", {type: 2})
        .then(data=>data.data.users)
        .then(users=>{
            console.log(users);
            const teachers = users.map(item=>{
                const teacher ={};
                teacher.id = item.id;
                teacher.email = item.email;
                teacher.name = `${item.name} ${item.surname}`;
                return teacher;
            });
            setTeachers(teachers);        
            
            if (lessonInfo && lessonInfo.id){
                setTeacherSelection([lessonInfo.teacherId]);
            }
        })
        .catch(err=>{
            console.log(err);
            setStudents([]);
            setTeacherSelection([]);
        });
    }

    const loadLessonTypes=()=>{
        callApi("guide/lessonTypes", "GET")
        .then(data=>{
            setLessonTypes(data.data.lessonTypes||[]);
        })
        .catch(err=>{
            console.log(err);
            setLessonTypes([]);
        });
    };

    const loadLessonDurations=()=>{
        callApi("guide/lessonDurations", "GET")
        .then(data=>{
            setLessonDurations(data.data.lessonDurations||[]);
        })
        .catch(err=>{
            console.log(err);
            setLessonDurations([]);
        });
    };


    const loadStudents = () => {
        callApi("dashboard/studentWithPaymentLesson", "GET", {languageId: language.id, activStudent: activStudent})
        .then(data=>{
            setStudents(data.data.users.map(item=>{
                const student ={};
                student.id = item.id;
                student.name = `${item.name} ${item.surname}`;
                item.lessonCount.reduce((res, countItem)=>{
                    res[countItem.duration] = countItem.count;
                    return res;
                },student)
                return student;
            }));
            if (lessonInfo.id && lessonInfo.id){
                setStudentSelection([lessonInfo.studentId]);
            }
            // setStudents(data.data.users);
        })
        .catch(err=>{
            console.log(err);
            setStudents([]);
             setStudentSelection([]);
        });
    };

    const addLessonToSchedule = () => {
        const scheduleInfo = validate();
        if (scheduleInfo){
            createOrUpdateLessonToSchedule(scheduleInfo);
        } else {
            setIsErrorShow(true);
        }
    };

    const validate =() =>{
        if (lessonName.length<=0){
            setErrorMessage("Введите название урока");
            return false;
        }
        if (lessonDate === ""){
            setErrorMessage("Введите дату урока");
            return false;
        }
        if (Date.parse(lessonDate)<Date.now()){
            setErrorMessage("Дата урока не может быть меньше текущей даты");
            return false;
        }
        if (studentSelection.length === 0){
            setErrorMessage("Выберите ученика");
            return false;
        }
        if (teacherSelection.length === 0){
            setErrorMessage("Выберите преподавателя");
            return false;
        }
        if (lessonDurationFilter == 0) {
            setErrorMessage("Выберите длительность урока");
            return false;
        }
        return {
            id: (lessonInfo && lessonInfo.id) ?lessonInfo.id : null,
            name: lessonName,
            date: Date.parse(lessonDate)/1000,
            students: students.filter(item=> studentSelection.find(studentId=>studentId===item.id)),
            teacher: teachers.find(item=>item.id===teacherSelection[0]),
            language: language,
            lessonType: isGroupLesson ? 2 : 1,
            homeworks: homeworks,
            isConducted: isConducted,
            duration: lessonDurations.find(item=>item.id===lessonDurationFilter)
        }
    };

    const createOrUpdateLessonToSchedule = (scheduleInfo) => {
        callApi("dashboard/lessonToSchedule", "POST", scheduleInfo)
        .then(data=>{
            setDialogVisible(true);
            if (!scheduleInfo.id){
                setHomeworks([]);
            }            
        })
        .catch(err=>{
            switch (err.response.data.message) {
                case "Student don't have enough paid lessons":
                    setErrorMessage("У ученика не хватает оплаченных уроков");
                    break;
                case "lesson already exists":
                    setErrorMessage("Есть урок у данного учителя на это время");
                    break;
                case "too late for change":
                    setErrorMessage("Слишком поздно для изменения");
                    break;               
                default:
                    setErrorMessage("Ошибка");
                    break;
            }            
            setIsErrorShow(true);
        });
    };

    

    const changeStudentSelection = (row)=>{
        console.log(row);
        if (isGroupLesson){
            setStudentSelection(row);    
        } else {
            setStudentSelection(row.filter(item=>item!=studentSelection[0]));
        }
    }

    const changeTeacherSelection = (row)=>{
        console.log(row);
        setTeacherSelection(row.filter(item=>item!=teacherSelection[0]));
    }

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter />
          </GridToolbarContainer>
        );
    }

    const setHomeworksToLesson = (homeworks) => {
        setHomeworks(homeworks.filter(item=>!isNaN(item)));
        setIsShowHomework(false);
    }

    const Row_MouseDoubleClick = (sender, e) => {
        setIsShowHomework(true);
        setHomeworksToLesson(params.row.homeworks);
    }

    const manualChangePaimendLesson = (row) => {
        setIsShowAddPaidLessonsDialog(false);
        callApi("dashboard/manualUpdatePaidLesson", "PUT", row)
        .then(data=>{
            loadStudents();
            
        }).catch(err=>{
            switch (err.response.data.message) {
                case "only admin can update PaimentLesson":
                    setErrorMessage("Только администратор может изменять количество оплаченных уроков");
                    break;
                case "the number of paid lessons cannot be less than 0":
                    setErrorMessage("Количество оплаченных уроков не может быть меньше 0");
                    break;             
                default:
                    setErrorMessage("Ошибка");
                    break;
            }            
            setIsErrorShow(true);
        });

    }

    return (
        <div className="add_schedule">
            <h1 className='title'>{titleText}</h1>
            <div className="add_schedule" >
                <div  className='menu_bar_1'>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className='menu_button' 
                        onClick={onBackClick}>
                            Вернутся
                    </Button>
                    <div className='menu_bar'>
                        <MaterialTextField 
                            label="Название урока "
                            type="text"
                            value={lessonName}
                            onChange={val => setLessonName(val.target.value)}
                            className='LessonName'
                        />
                        <MaterialTextField
                            label="Дата Урока"
                            type="datetime-local"
                            value={lessonDate}
                            onChange={val => setLessonDate(val.target.value)}
                            className='LessonDate'
                        />
                        <div>
                            <InputLabel id={`duration-select-label`} className='combobox_label'>Продолжительность</InputLabel>
                            <Select
                                labelId={`duration-select-label`}
                                id={`select_duration`}
                                value={lessonDurationFilter}
                                label={"Продолжительность"}
                                onChange={val => setLessonDurationFilter(val.target.value)}
                                className='ComboboxSelect'>
                                {lessonDurations.map(item=><MenuItem value={item.id}>{`${item.duration} мин`}</MenuItem>)}
                            </Select>
                        </div>
                    </div>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className='menu_button' 
                        onClick={addLessonToSchedule}>
                            Сохранить
                    </Button>
                    
                </div> 
                <div className='menu_bar_2'>
                    <MaterialTextField 
                        label="Домашнее задание "
                        type="text"
                        value={homeworks}
                        onChange={val => setHomeworks(val.target.value)}
                        className='homeWorks'
                        readOnly
                    />
                    <Button variant="contained" 
                        color="primary" 
                        className='menu_button' 
                        onClick={()=>setIsShowHomework(true)}>
                            ДЗ</Button>
                    <FormControlLabel control={<Checkbox checked={activStudent} onChange={vall=>setActivStudent(vall.target.checked)} />} label="Активные студенты" />
                    <FormControlLabel control={<Checkbox checked={isConducted} onChange={vall=>setIsConducted(vall.target.checked)} />} label="Урок завершен" />
                    <FormControlLabel control={<Checkbox checked={isGroupLesson} onChange={vall=>setIsGroupLesson(vall.target.checked)} />} label="Групповой урок" />
                </div>              
                <div className='users_grids'>
                    <Box className='users_grid_left'>
                        <InputLabel id={`duration-select-label`} className='combobox_label'>Учителя</InputLabel>
                        <DataGrid
                            rows={teachers}
                            columns={[
                                { field: 'name', headerName: 'Имя', width: 130 },
                                { field: 'email', headerName: 'Email', width: 130 },
                            ]}
                            rowSelectionModel={teacherSelection}
                            onRowSelectionModelChange={changeTeacherSelection}
                            checkboxSelection
                            slots={{ toolbar: CustomToolbar }}
                            slotProps={{
                                toolbar: {
                                showQuickFilter: true,
                                },
                            }}
                            rowHeight={38}
                            pagination
                            autoPageSize
                            disableMultipleRowSelection
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterValues:  lessonInfo ? [lessonInfo.teacher]:[],
                                    },
                                },
                            }}
                            
                        />
                    </Box>
                    <Box className='users_grid_right'>
                        <div className='student-title'>
                            <InputLabel id={`duration-select-label`} className='combobox_label'>Ученики</InputLabel>
                            <Button 
                                disabled={studentSelection.length!=1}
                                variant="contained" 
                                color="primary" 
                                className='menu_button' 
                                onClick={()=>setIsShowAddPaidLessonsDialog(true)}
                            >
                                Добавить оплату
                            </Button>
                        </div>
                        <DataGrid
                            rows={students}
                            columns={students.length>0? 
                                Object.keys(students[0]).map(item=>({field: item, headerName: item, width: 130 }))
                            :[{ field: 'id', headerName: 'ID', width: 50 },
                                { field: 'name', headerName: 'Имя', width: 130 }]}
                            checkboxSelection
                            rowSelectionModel={studentSelection}
                            onRowSelectionModelChange={changeStudentSelection}
                            slots={{ toolbar: CustomToolbar }}
                            slotProps={{
                                toolbar: {
                                showQuickFilter: true,
                                },
                            }}
                            rowHeight={38}
                            pagination
                            autoPageSize
                            disableMultipleRowSelection  = {!isGroupLesson}
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterValues:  lessonInfo ? [lessonInfo.student]:[],
                                    },
                                },
                            }}
                            MouseDoubleClick={Row_MouseDoubleClick}
                        />                    
                    </Box>
                   
                </div>

            </div>
            <ErrorDialog open={isErrorShow} onClose={() => setIsErrorShow(false)} message={errorMessage}/>
            <FormDialog
                        closeDialog={() => setDialogVisible(false)}
                        dialogVisible={dialogVisible}
                        titleId="audit.addlessons"
                        contentId="audit.lessonsAddedSuccessfully"
            />
            <LessonContent
                open={isShowHomework}
                onClose={() => setIsShowHomework(false)}
                onDone={setHomeworksToLesson}
                lang= {language}
                contentType={true}
                title="Домашнее задание"
                lessonList={homeworks}>
            </LessonContent>
            <AddPaidLessonsDialog
                open={isShowAddPaidLessonsDialog}
                onClose={() => setIsShowAddPaidLessonsDialog(false)}
                studentInfo={students.filter(item=>item.id===studentSelection[0])[0]}
                type={lessonTypes.find(item=>item.lesson_type==="individual")}
                lang={language}                
                durationList={lessonDurations}
                onOkClick={manualChangePaimendLesson}
            ></AddPaidLessonsDialog>
        </div>
    );
};  

export default ScheduleEdit;