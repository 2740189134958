import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { dialogSelectLesson, dialogSetSocketMessage } from '../../actions/dialog';
import { setLessonSocket, setLessonsArr, setUsersInfo } from '../../actions/settings';
import { bindActionCreators } from 'redux';
import callApi from '../../utils/call-api';
import LessonEditor from '../lessonEditor/main/LessonEditor';
import config from '../../config';
import { logout } from '../../actions/auth';

const IS_WEBSOCKET_OPEN_STATUS = 1;

let counter = 1;

class LessonIndex extends React.Component {
    initState = {
        tabVal: 0,
        lessons: [],
        coursesRaw: [],
        open: false,
        socketMessage: { command: null },
        isLesson: false,
        isSendRequestForLessonInfo: false,
        vCId: '',
        userEmail: '',
        userType: '',
        isReadyToDoWssQuery: false,
    };

    constructor(props) {
        super(props);

        this.state = {...this.initState };
    }

    checkWebsocket = () => {
        if (this.state.socket.readyState !== IS_WEBSOCKET_OPEN_STATUS ) {
            this.setState({ ...this.initState }, this.componentInit );
        }
    };

    async componentDidMount() {
        await this.componentInit();
    }

    async componentInit () {
        const storage = JSON.parse(localStorage.getItem('user') || null);
        const vCId = new URLSearchParams(this.props.location.search).get('id');

        if (storage && storage.userInfo) {

            if (storage.userInfo.type) {
                this.setState({ userType: storage.userInfo.type });
            }

            if (storage.userInfo.email) {
                this.setState({ userEmail: storage.userInfo.email });
            }
        }

        this.setState({ vCId: vCId });

        const socket = new WebSocket(config.SOCKET_URI + `?vCId=${vCId}&userEmail=${this.props.userInfo.userInfo.email}&userType=${this.props.userInfo.userInfo.type}&userName=${this.props.userInfo.userInfo.name}`);

        socket.onopen = () => {
            this.props.setLessonSocket(socket);

            socket.onerror = error => {
                this.checkWebsocket();
            }

            socket.onclose = () => {
                this.checkWebsocket();
            }

            socket.onmessage = e => {
                try {
                    e = JSON.parse(e.data);

                    this.setState({ socketMessage: e });
                    this.props.dialogSetSocketMessage(e);

                    if (e.command === 'lesson') {
                        this.props.dialogSelectLesson(e.data.lessonId);
                    } else if (e.command === 'tab') {
                        this.setState({ tabVal: e.val });
                    } else if (e.command === 'roomUsersInfo') {
                        this.props.setUsersInfo(e.data);
                    }
                } catch (e) {}
            };

            this.setState({ socket });

            const f = () => {
                this.checkWebsocket();

                socket.send(JSON.stringify({
                    command: 'ping',
                    val: {
                        vCId: this.state.vCId,
                        userEmail: this.state.userEmail,
                        userType: this.state.userType
                    }
                }));

                setTimeout(() => f(), 10000);
            };

            f();
        };

        await this.lessonsFromServer();
    }

    async lessonsFromServer() {
        const courses = {};
        let coursesRaw = [];
        const lessons = [];
        const lessonsTreeArr = [];

        await callApi('dashboard/getCourses', 'post').then(res => {
            if (res && res.data.ok) {
                coursesRaw = res.data.courses;
                res.data.courses.forEach(el => {
                    if (courses[el.name]) {
                        if (!courses[el.name].levels) {
                            courses[el.name].levels = [];
                        }
                        courses[el.name].levels.push({
                            isForHomework: el.isForHomework,
                            id: el.id,
                            name: el.type,
                            isVisible: true,
                        });
                    } else {
                        courses[el.name] = {
                            isForHomework: el.isForHomework,
                            name: el.name,
                            isVisible: false,
                            content: { levels: '', lessons: '', Teachers: '' },
                            levels: [{
                                isForHomework: el.isForHomework,
                                id: el.id,
                                name: el.type,
                                isVisible: true,
                            }],
                        };
                    }
                });
            } else {
                alert('Error1');
            }
        }).catch(err => {
            this.props.logout();
            _('err', err);
        });

        // Т.к. если добавить в forEach, тогда вернется pending
        for (let courseKey in courses) {
            for (let i = 0; i < courses[courseKey].levels.length; i++) {
                courses[courseKey].levels[i].lessons = await callApi('dashboard/getLessons', 'POST', {
                    name: courseKey,
                    type: courses[courseKey].levels[i].name
                }).then(lessons => lessons.data.lessonsData[0].lessons).catch(() => []);

                lessonsTreeArr.push({
                    isForHomework: courses[courseKey].levels[i].isForHomework,
                    parentId: null,
                    id: 'c' + courses[courseKey].levels[i].id,
                    name: `${courses[courseKey].name} - ${courses[courseKey].levels[i].name}`,
                });

                for (let j = 0; j < courses[courseKey].levels[i].lessons.length; j++) {
                    lessonsTreeArr.push({
                        parentId: 'c' + courses[courseKey].levels[i].id,
                        id: courses[courseKey].levels[i].lessons[j].id,
                        name: courses[courseKey].levels[i].lessons[j].name,
                        isForHomework: courses[courseKey].levels[i].isForHomework,
                    });

                    lessons.push({
                        ...courses[courseKey].levels[i].lessons[j],
                        type: 'usual',
                        name: courses[courseKey].levels[i].lessons[j].page.lessonName || 'Lesson ' + counter,
                        number: counter++,
                        isForHomework: courses[courseKey].levels[i].isForHomework,
                        editor: null,
                    });
                }
            }
        }

        this.setState({ lessons, coursesRaw, isReadyToDoWssQuery: true });
        this.props.setLessonsArr(lessonsTreeArr);
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.lesson || nextProps.lesson === 0) && nextProps.lesson !== this.props.lesson) {
            if (this.state.socket)
                this.state.socket.send(JSON.stringify({
                    command: 'lesson',
                    data: {lessonId: nextProps.lesson},
                    val: {
                        vCId: nextProps.lessonIdent.id,
                        userEmail: nextProps.userInfo.userInfo.email,
                        userType: nextProps.userInfo.userInfo.type
                    }
                }));

            if (['admin', 'teacher'].includes(this.props.userInfo.userInfo.type) && this.state.socket) {
                this.state.socket.send(JSON.stringify({
                    command: 'setVirtualClassInfo',
                    val:{
                        vCId: this.props.lessonIdent && this.props.lessonIdent.id,
                        vCParam: 'lessonId',
                        vCValue: nextProps.lesson,
                        userEmail: nextProps.userInfo.userInfo.email,
                        userType: nextProps.userInfo.userInfo.type
                    }
                }));
            }

            this.setState({ isLesson: false },
                () => setTimeout(() => this.setState({ isLesson: true }), 200));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.socket && !this.props.lesson && this.props.lessonIdent &&
            this.state.isReadyToDoWssQuery &&
            !this.state.isSendRequestForLessonInfo
        ) {
            this.setState({ isSendRequestForLessonInfo: true }, () => {
                setTimeout(() => {
                    this.state.socket.send(JSON.stringify({
                        command: 'getVirtualClassInfo', 
                        val: {
                            vCId: this.state.vCId, //this.props.lessonIdent.id
                            userEmail: this.state.userEmail,
                            userType: this.state.userType
                        }
                    }));
                }, 1);
            });
        }
    }

    render() {
        if (!this.state.isLesson) {
            return (
                <div className="HelloImgWrapper" >
                    <img alt="Welcome" src="/icons/for_studio.png"/>
                </div>
            )
        }

        const lessonForVirtualClass = this.state.lessons.find(lesson => lesson.id === this.props.lesson);

        return (
            <LessonEditor
                virtualClassId={this.props.lessonIdent.id}
                lesson={lessonForVirtualClass}
                coursesRaw={this.state.coursesRaw}
                lessonType="presentation"
                socket={this.state.socket}
                socketMessage={this.state.socketMessage}
                whiteboard={this.props.whiteboard}
            />
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        dialogSelectLesson,
        setLessonSocket,
        setLessonsArr,
        setUsersInfo,
        dialogSetSocketMessage,
        logout,
    },
    dispatch
);


const mapStateToProps = state => ({
    lesson: state.dialog.lesson,
    whiteboard: state.dialog.whiteboard,
    userInfo: state.auth.userInfo,
    lessonIdent: state.settings.lessonIdent || {},
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonIndex));
