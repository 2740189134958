/* global localStorage */
import * as types from '../constants/auth';
import * as dialog from '../constants/dialog';
import callApi from '../utils/call-api';
import handleErrors from '../utils/handle-errors';

export function signup(user) {
    const { email, password, displayName, displaySurname, ageType, phoneNumber } = user;

    return (dispatch, getState) => {
        const {isFetching} = getState().services;

        if (isFetching.signup) {
            return Promise.resolve();
        }

        dispatch({
            type: types.SIGNUP_REQUEST
        });

        return callApi('auth/signUp', 'post', {
            authenticationType: 'email',
            email,
            phoneNumber,
            ageType,
            password,
            passwordConfirm: password,
            name: displayName,
            surname: displaySurname,
        }).then(res => {
            const {code} = res.data;
            if (code === 200) {
                const errors = handleErrors(res);
                dispatch({
                    type: types.SHOW_MESSAGE,
                    payload: errors
                });
            } else {
                dispatch({
                    type: types.SIGNUP_SUCCESS,
                    payload: user
                });
                dispatch({
                    type: types.HIDE_MESSAGE
                });
                dispatch({
                    type: dialog.SHOW_DIALOG
                });
            }
        }).catch(error => {
            dispatch({
                type: types.SHOW_MESSAGE,
                payload: error
            });
            dispatch({
                type: types.SIGNUP_FAILURE,
                payload: error
            });
        });
    };
}

export function login(user) {
    const {email, password} = user;
    return (dispatch, getState) => {
        const {isFetching} = getState().services;

        if (isFetching.login) {
            return Promise.resolve();
        }

        dispatch({
            type: types.LOGIN_REQUEST
        });

        return callApi('auth/signIn', 'post', {
            authenticationType: 'email',
            email,
            password
        }).then(res => {
            const { code } = res.data;
            if (code === 200) {
                const errors = handleErrors(res);
                dispatch({
                    type: types.SHOW_MESSAGE,
                    payload: errors
                });
                dispatch({
                    type: types.LOGIN_FAILURE,
                    payload: errors
                });
            } else {
                const token = res.data.userData.token;
                const username = user.email;

                const logged = {
                    token,
                    username,
                    userInfo: res.data.userData
                };

                localStorage.setItem('user', JSON.stringify(logged) || {});

                dispatch({
                    type: types.LOGIN_SUCCESS,
                    payload: { user: username, token, logged}
                });
            }
        }).catch(error => {
            dispatch({
                type: types.SHOW_MESSAGE,
                payload: error
            });
            dispatch({
                type: types.LOGIN_FAILURE,
                payload: error
            });
        });
    };
}

export function logout() {
    const storage = JSON.parse(localStorage.getItem('user') || null);
    return (dispatch, getState) => {
        const {isFetching} = getState().services;

        if (isFetching.logout) {
            return Promise.resolve();
        }

        dispatch({
            type: types.LOGOUT_REQUEST
        });
        if (storage.token) {
            localStorage.removeItem('user');
            dispatch({
                type: types.LOGOUT_SUCCESS
            });
        } else {
            dispatch({
                type: types.LOGOUT_FAILURE
            });
        }
    };
}

export function resetStep1(email) {
    return (dispatch, getState) => {
        const {isFetching} = getState().services;

        if (isFetching.resetStep1) {
            return Promise.resolve();
        }

        dispatch({
            type: types.RESET_STEP1_REQUEST
        });

        return callApi('authmanagement', 'post', {
            action: 'sendResetPwd',
            value: {
                email
            }
        }).then(res => {
            const {code} = res.data;
            if (code === 200) {
                const errors = handleErrors(res);
                dispatch({
                    type: types.SHOW_MESSAGE,
                    payload: errors
                });
                dispatch({
                    type: types.RESET_STEP1_FAILURE,
                    payload: errors
                });
            } else {
                dispatch({
                    type: types.RESET_STEP1_SUCCESS,
                    payload: email
                });
                dispatch({
                    type: dialog.SHOW_DIALOG
                });
            }
        }).catch(error => {
            dispatch({
                type: types.SHOW_MESSAGE,
                payload: error
            });
            dispatch({
                type: types.RESET_STEP1_FAILURE,
                payload: error
            });
        });
    };
}

export function resetStep2(token, password) {
    return (dispatch, getState) => {
        const {isFetching} = getState().services;

        if (isFetching.resetStep2) {
            return Promise.resolve();
        }

        dispatch({
            type: types.RESET_STEP2_REQUEST
        });

        return callApi('authmanagement', 'post', {
            action: 'resetPwdLong',
            value: {
                token,
                password
            }
        }).then(res => {
            const {code} = res.data;
            if (code === 200) {
                const errors = handleErrors(res);
                dispatch({
                    type: types.SHOW_MESSAGE,
                    payload: errors
                });
                dispatch({
                    type: types.RESET_STEP2_FAILURE,
                    payload: errors
                });
            } else {
                dispatch({
                    type: types.RESET_STEP2_SUCCESS,
                    payload: password
                });
                dispatch({
                    type: dialog.SHOW_DIALOG
                });
            }
        }).catch(error => {
            dispatch({
                type: types.SHOW_MESSAGE,
                payload: error
            });
            dispatch({
                type: types.RESET_STEP2_FAILURE,
                payload: error
            });
        });
    };
}

export function confirm(token) {
    return (dispatch, getState) => {
        const {isFetching} = getState().services;

        if (isFetching.confirm) {
            return Promise.resolve();
        }

        dispatch({
            type: types.VERIFY_REQUEST
        });

        return callApi('authmanagement', 'post', {
            action: 'verifySignupLong',
            value: token
        }).then(() => {
            dispatch({
                type: types.VERIFY_SUCCESS
            });
            dispatch({
                type: types.HIDE_LOADER
            });
            dispatch({
                type: dialog.SHOW_DIALOG
            });
        }).catch(error => {
            dispatch({
                type: types.SHOW_MESSAGE,
                payload: error
            });
            dispatch({
                type: types.VERIFY_FAILURE,
                payload: error
            });
        });
    };
}

export function recieveAuth() {
    return dispatch => {
        dispatch({
            type: types.RECIEVE_AUTH_REQUEST
        });

        return callApi('users', 'get').then(json => {
            const storage = JSON.parse(localStorage.getItem('user') || null);
            if (storage.token) {
                dispatch({
                    type: types.RECIEVE_AUTH_SUCCESS,
                    payload: json
                });
            }
        }).catch(reason => {
            dispatch({
                type: types.RECIEVE_AUTH_FAILURE,
                payload: reason
            });
        });
    };
}

export function showAuthMessage(message) {
    return {
        type: types.SHOW_MESSAGE,
        payload: message
    };
}

export function hideAuthMessage() {
    return {
        type: types.HIDE_MESSAGE
    };
}

export function showAuthLoader() {
    return {
        type: types.SHOW_LOADER
    };
}

export function hideAuthLoader() {
    return {
        type: types.HIDE_LOADER
    };
}

export function setInitUrl(url) {
    return {
        type: types.INIT_URL,
        payload: url
    };
}

export function updateUserInfo(userInfo) {
    console.log('sssssssssss1', userInfo)
    return dispatch => {
        dispatch({
            type: types.UPDATE_USERINFO,
            payload: userInfo
        });
    }
}
