import React from 'react';
import './ModalCloseButton.scss';
import closeModal from '../../../../assets/lottie/close_lottie.json';
// import Lottie from 'react-lottie';


// const defaultLottieOptions = {
//     loop: true,
//     autoplay: false,
//     rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice'
//     }
// };

class ModalCloseButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLottiePaused: true,
        };
    }

    render() {
        return (
            <div className="ModalCloseButton"
                onClick={() => this.props.closeModal()}
                onMouseEnter={() => this.setState({ isLottiePaused: false })}
                onMouseLeave={() => this.setState({ isLottiePaused: true })}
            >

                {/*<Lottie*/}
                {/*    options={{*/}
                {/*        ...defaultLottieOptions,*/}
                {/*        animationData: closeModal,*/}
                {/*    }}*/}
                {/*    isStopped={this.state.isLottiePaused}*/}
                {/*/>*/}
            </div>
        );
    }
}

export default ModalCloseButton;
