import React from 'react';
import FormDialog from './AlertDialogSlide';
import './FurtherClasses'
import Admins from "./Admins/Admins";
import Finance from "./Finance/Finance";
import Supervisors from "./Supervisors/Supervisors";
import Teachers from "./Teachers/Teachers";
import Students from "./Students/Students";
import Settings from "./Settings/Settings";
import Demo from "./Demo/Demo";
import Courses from "./Courses/Courses";
import Lessons from '../../brand/lessons/Router';
import Schedule from './Schedule/Schedule';
import AddInterestingFacts from './InterestingFacts/InterestingFactsList';
import Lottie from './Lottie/LottieList'
import Navlink from "react-router-dom/NavLink";
import { Route } from "react-router-dom";
import s from "./SuperAdmin.module.css";
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth';
import history from '../../../utils/history';
import UsersCalendars from './usersCalendars/UsersCalendars';

function leng(textId) {
    return textId;
}

const Path = "/admin/superadmin/";

class SuperAdmin extends React.Component {
    state = {
        dialogVisible: false,
        cancelWindowVisible: true,
        isActive: false,
        isActiveFL: false,

    };


    activateDialog() {
        this.setState({dialogVisible: true})
    }

    deactivateDialog() {
        this.setState({dialogVisible: false})
    }

    render() {
        const storage = JSON.parse(localStorage.getItem('user') || null);
        let username = '';
        if (storage && storage.username) {
            username = storage.username;
        }

        if (storage && storage.userInfo && storage.userInfo.type === 'student') {
            history.push('/admin/student');
        }

        if (storage && storage.userInfo && storage.userInfo.type === 'teacher') {
            history.push('/admin/teacher');
        }

        const isNotSupervisor = storage && storage.userInfo && storage.userInfo.type !== 'supervisor';

        return (
            <div className={s.superadmin_page}>
                <FormDialog dialogVisible={this.state.dialogVisible}
                    closeDialog={this.deactivateDialog.bind(this)}
                />
                {/*<div className={s.statistics_wrap}>*/}

                {/*    <div className={s.stat_first_block}>*/}
                {/*        <div className={s.stat_first_block_header}>*/}
                {/*            {leng('In total')}*/}
                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_num}>*/}
                {/*            {leng('1000')}*/}

                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_footer}>*/}
                {/*            {leng('lessons')}*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className={s.stat_first_block}>*/}
                {/*        <div className={s.stat_first_block_header}>*/}
                {/*            {leng('This month')}*/}
                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_num}>*/}
                {/*            {leng('100')}*/}

                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_footer}>*/}
                {/*            {leng('lessons')}*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className={s.stat_first_block}>*/}
                {/*        <div className={s.stat_first_block_header}>*/}
                {/*            {leng('This month')}*/}
                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_num}>*/}
                {/*            {leng('50')}*/}

                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_footer}>*/}
                {/*            {leng('demo classes')}*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className={s.stat_first_block}>*/}
                {/*        <div className={s.stat_first_block_header}>*/}
                {/*            {leng('Teachers')}*/}
                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_num}>*/}
                {/*            {leng('20')}*/}

                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_footer}>*/}
                {/*            {leng('people')}*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className={s.stat_first_block}>*/}
                {/*        <div className={s.stat_first_block_header}>*/}
                {/*            {leng('Students')}*/}
                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_num}>*/}
                {/*            {leng('200')}*/}

                {/*        </div>*/}
                {/*        <div className={s.stat_first_block_footer}>*/}
                {/*            {leng('people')}*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*</div>*/}


                <div
                    className={s.right_panel}
                    // onClick={this.activateDialog.bind(this)}
                    style={{float: 'none'}}
                >
                    {/*<div className={s.id_photo}>*/}
                    {/*</div>*/}

                    <div className={s.right_panel_txt}>
                        {username}
                        <br /><br />
                        <div onClick={() => this.props.logout()}><b>Logout</b></div>
                    </div>

                </div>
                <div
                    className={s.right_panel_schedule_new + ' ' + s.right_panel}>
                    <div className={s.right_panel_img_wrap}>
                        <div className={s.schedule_new_img}>
                        </div>
                    </div>
                    <div className={s.right_panel_txt}>
                        Schedule a<br/>
                        new lesson
                    </div>
                </div>

                <div
                    className={s.right_panel_schedule + ' ' + s.right_panel}>
                    <div className={s.right_panel_img_wrap}>
                        <div className={s.schedule_img}>
                        </div>
                    </div>
                    <div className={s.right_panel_txt}>
                        My<br/>
                        schedule
                    </div>
                </div>


                <div
                    className={s.right_panel_chat + ' ' + s.right_panel}>
                    <div className={s.right_panel_img_wrap}>
                        <div className={s.chat_img}>
                        </div>
                    </div>
                    <div className={s.right_panel_txt}>
                        Chat
                    </div>
                </div>

                <div
                    className={s.right_panel_test_platform + ' ' + s.right_panel}>
                    <div className={s.right_panel_img_wrap}>
                        <div className={s.test_platform_img}>
                        </div>
                    </div>
                    <div className={s.right_panel_txt}>
                        Test platform
                    </div>
                </div>


                <div
                    className={s.right_panel_news + ' ' + s.right_panel}>
                    <div className={s.right_panel_img_wrap}>
                        <div className={s.news_img}>
                        </div>
                    </div>
                    <div className={s.right_panel_txt}>
                        News
                    </div>

                </div>

                <div
                    className={s.right_panel_notification + ' ' + s.right_panel}>
                    <div className={s.right_panel_img_wrap}>
                        <div className={s.notification_img}>
                        </div>
                    </div>
                    <div className={s.right_panel_txt}>
                        Notifications
                    </div>
                </div>

                <div className={s.main}>
                    <div className={s.left_panel}>
                        <div className={s.row + ' ' + s.row1}>
                            {isNotSupervisor &&
                            <>
                                < Navlink to={Path + 'admins'} activeClassName={s.active} className={s.nav + ' ' + s.nav_first}>
                                admins
                                </Navlink>

                                <Navlink to={Path + 'supervisors'} activeClassName={s.active} className={s.nav}>
                                supervisors
                                </Navlink>

                                <Navlink to={Path + 'teachers'} activeClassName={s.active} className={s.nav}>
                                teachers
                                </Navlink>

                                <Navlink to={Path + 'students'} activeClassName={s.active} className={s.nav}>
                                students
                                </Navlink>

                                <Navlink to={Path + 'lessons'} activeClassName={s.active} className={s.nav}>
                                    Lessons
                                </Navlink>
                                <Navlink to={Path + 'schedule'} activeClassName={s.active} className={s.nav}>
                                    Schedule
                                </Navlink>
                                <Navlink to={Path + 'users-calendars'} activeClassName={s.active} className={s.nav}>
                                    Calendars
                                </Navlink>
                            </>
                            }

                            {/*<Navlink to={Path + 'demo'} activeClassName={s.active} className={s.nav}>*/}
                            {/*        demo*/}
                            {/*</Navlink>*/}
                        </div>



                        <div className={s.row + ' ' + s.row2}>
                            <Navlink to={Path + 'courses'} activeClassName={s.active} className={s.nav}>
                                COURSES
                            </Navlink>                            
                            <Navlink to={Path + 'homeworks'} activeClassName={s.active} className={s.nav}>
                                HOMEWORKS
                            </Navlink>

                            {isNotSupervisor &&
                            <>
                                <Navlink to={Path + 'recordings'} activeClassName={s.active} className={s.nav}>
                                    RECORDINGS
                                </Navlink>

                                <Navlink to={Path + 'facts'} activeClassName={s.active} className={s.nav}>
                                    Interesting Facts
                                </Navlink>

                                <Navlink to={Path + 'lottie'} activeClassName={s.active} className={s.nav}>
                                    Lottie Files
                                </Navlink>
                            </>
                            }
                        </div>

                        <div className={s.row + ' ' + s.row2}>
                            <Navlink to={Path + 'settings'} activeClassName={s.active} className={s.nav}>
                                SETINGS
                            </Navlink>
                            <Navlink to={Path + 'finance'} activeClassName={s.active} className={s.nav}>
                                FINANCE
                            </Navlink>                            
                        </div>
                        {/*<div className={s.row + ' ' + s.row3}>*/}
                        {/*    <Navlink to={Path + 'supervisors_news'} activeClassName={s.active} className={s.nav}>*/}
                        {/*        News for supervisors*/}
                        {/*    </Navlink>*/}
                        {/*    <Navlink to={Path + 'teachers_news'} activeClassName={s.active} className={s.nav}>*/}
                        {/*        News for teachers*/}
                        {/*    </Navlink>*/}
                        {/*    <Navlink to={Path + 'students_news'} activeClassName={s.active} className={s.nav}>*/}
                        {/*        News for students*/}
                        {/*    </Navlink>*/}

                        {/*</div>*/}
                    </div>
                   
                        {isNotSupervisor && <Route path={Path + 'admins'} component={Admins}/>}
                        {isNotSupervisor && <Route path={Path + 'supervisors'} component={Supervisors}/>}
                        {isNotSupervisor && <Route path={Path + 'lessons'} component={Lessons}/>}
                        <div className={s.body_panel}>{isNotSupervisor && <Route path={Path + 'schedule'} component={Schedule}/>}</div>
                        {isNotSupervisor && <Route path={Path + 'teachers'} component={Teachers}/>}
                        {isNotSupervisor && <Route path={Path + 'students'} component={Students}/>}
                        {isNotSupervisor && <Route path={Path + 'demo'} component={Demo}/>}
                        <Route path={Path + 'courses'} render={() => <Courses isHomework={false} />}/>                        
                        <Route path={Path + 'homeworks'} render={() => <Courses isHomework />}/>
                        {isNotSupervisor && <Route path={Path + 'facts'} component={AddInterestingFacts}/>}
                        {isNotSupervisor && <Route path={Path + 'lottie'} component={Lottie}/>}
                        {isNotSupervisor && <Route path={Path + 'users-calendars'} component={UsersCalendars}/>}
                        {isNotSupervisor && <Route path={Path + 'settings'} component={Settings}/>}
                        {isNotSupervisor && <Route path={Path + "finance"} component={Finance}/>}
                    
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({}) => ({});

export default connect(mapStateToProps, { logout })(SuperAdmin);
