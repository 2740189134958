import * as types from '../constants';

const token = JSON.parse(localStorage.getItem('user') || null) && JSON.parse(localStorage.getItem('user') || null).token;
const userInfo = JSON.parse(localStorage.getItem('user') || null);

const initialState = {
    loader: false,
    showMessage: false,
    alertTitle: '',
    alertMessage: '',
    initURL: '',
    isAuthenticated: !!token,
    token,
    userInfo,
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case types.SIGNUP_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                user: action.payload.user,
                loader: false
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                user: action.payload.user,
                userInfo: action.payload.logged,
                loader: false
            };
        case types.RECIEVE_AUTH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                // user: action.payload.user,
            };
        case types.SIGNUP_FAILURE:
        case types.LOGIN_FAILURE:
        case types.RECIEVE_AUTH_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: '',
                showMessage: true,
                loader: false
            };
        case types.RESET_STEP1_REQUEST:
        case types.RESET_STEP2_REQUEST:
            return {
                ...state,
                loader: true
            };
        case types.RESET_STEP1_SUCCESS:
        case types.RESET_STEP2_SUCCESS:
            return {
                ...state,
                showMessage: false,
                loader: false
            };
        case types.RESET_STEP1_FAILURE:
        case types.RESET_STEP2_FAILURE:
            return {
                ...state,
                showMessage: true,
                loader: false
            };
        case types.VERIFY_SUCCESS:
        case types.VERIFY_FAILURE:
            return {
                ...state,
                loader: false
            };
        case types.SHOW_LOADER:
            return {
                ...state,
                loader: true
            };
        case types.HIDE_LOADER:
            return {
                ...state,
                loader: false
            };
        case types.SHOW_MESSAGE:
            return {
                ...state,
                alertTitle: action.payload.title,
                alertMessage: action.payload.message,
                showMessage: true,
                loader: false
            };
        case types.HIDE_MESSAGE:
            return {
                ...state,
                alertTitle: '',
                alertMessage: '',
                showMessage: false,
                loader: false
            };
        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: '',
                showMessage: false,
                initURL: global.authUrl,
            };
        case types.UPDATE_USERINFO:
            return {
                ...state,
                userInfo: {...action.payload}
            };
        case types.INIT_URL:
            return {
                ...state,
                initURL: action.payload
            };
        default:
            return state;
    }
}
