export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const WINDOW_WIDTH = 'WINDOW_WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const DRAWER_TYPE = 'DRAWER_TYPE';
export const FIXED_DRAWER = 'FIXED_DRAWER';
export const COLLAPSED_DRAWER = 'COLLAPSED_DRAWER';
export const VERTICAL_NAVIGATION = 'VERTICAL_NAVIGATION';
export const INSIDE_THE_HEADER = 'INSIDE_THE_HEADER';
export const CHANGE_NAVIGATION_STYLE = 'CHANGE_NAVIGATION_STYLE';
export const HORIZONTAL_MENU_POSITION = 'HORIZONTAL_MENU_POSITION';
export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE';
export const SET_LESSON_IDENT = 'SET_LESSON_IDENT';
export const SET_LESSON_SOCKET = 'SET_LESSON_SOCKET';
export const SET_LESSONS_ARR = 'SET_LESSONS_ARR';
export const SET_USERS_INFO = 'SET_USERS_INFO';
export const DEMO_LESSON = 'DEMO_LESSON';
export const DEMO_GRADE = 'DEMO_GRADE';
