// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminCalendar {
  font-family: Oswald, sans-serif !important;
}
.AdminCalendar .CustomJumpModal {
  margin-top: 0 !important;
  top: 0 !important;
}
.AdminCalendar .CustomJumpModal .CustomJumpModalBody.CustomJumpModalBodyShow {
  height: 95vh;
  overflow-y: scroll !important;
  overflow-x: clip;
}
.AdminCalendar .ModalCloseButton {
  right: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/SuperAdmin/usersCalendars/AdminCalendar.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;AACF;AACE;EACE,wBAAA;EACA,iBAAA;AACJ;AAEE;EACE,YAAA;EACA,6BAAA;EACA,gBAAA;AAAJ;AAGE;EACE,sBAAA;AADJ","sourcesContent":[".AdminCalendar {\r\n  font-family: Oswald, sans-serif !important;\r\n\r\n  .CustomJumpModal {\r\n    margin-top: 0 !important;\r\n    top: 0 !important;\r\n  }\r\n\r\n  .CustomJumpModal .CustomJumpModalBody.CustomJumpModalBodyShow {\r\n    height: 95vh;\r\n    overflow-y: scroll !important;\r\n    overflow-x: clip;\r\n  }\r\n\r\n  .ModalCloseButton {\r\n    right: 15px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
