import * as types from '../constants/homeWork';


export function visibleCounter() {
    localStorage.setItem('showCounter', JSON.stringify(true));
    return dispatch => {
        dispatch({
            type: types.OPEN_COUNTER,
            payload: true
        });
    };
}

export function hideCounter() {
    localStorage.setItem('showCounter', JSON.stringify(false));
    return dispatch => {
        dispatch({
            type: types.CLOSE_COUNTER,
            payload: false
        });
    };
}