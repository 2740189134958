import * as types from '../constants/folders';

const foldersData = JSON.parse(localStorage.getItem('foldersStudents') || '[]');

const initialState = {
    foldersData: foldersData,
}

export default function folders (state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FOLDERS:
            return {
                ...state,
                foldersData: action.payload,
            };
        default:
            return state;
    }
};