import React,{useState, useEffect, useRef} from "react";
import HanziWriter from 'hanzi-writer';
import TextField from '@material-ui/core/TextField';
import { Button } from "@material-ui/core";

const WritingHieroglyph = ({el, i, activeTabIndex, options, socketMessage}) => {
    const refWh = useRef();    
    const [hieroglyph, setHieroglyph] = useState(el.hieroglyph);
    const [isPresentation ] = useState(options.isPresentation);
    const [uniqId] = useState(el.uniqId);
    const [hanziWriter, setHanziWriter] = useState();

    useEffect(() => {
        if (hieroglyph !=""){
            setHanziWriter( HanziWriter.create(refWh.current, hieroglyph,{
                width: 200,
                height: 200,
                showCharacter: false}
            ));
        }
        options.changeElement({hieroglyph}, i, activeTabIndex);
    }, [hieroglyph]);    

    useEffect(() => {
        if (isPresentation && hanziWriter&& socketMessage.command === 'AnimaeHieroglyphWriter' && socketMessage.data.uniqId === uniqId) {
            hanziWriter.hideCharacter();      
            hanziWriter.animateCharacter();    
        }
    }, [socketMessage]);

    const chainAnimations =()=> {
        hanziWriter.hideCharacter();      
        hanziWriter.animateCharacter();
        options.sendMessage({
            command: 'AnimaeHieroglyphWriter',
            data: {
                uniqId,
                animate: true
            }
        });
    };
  
    return (
      <>
        {!isPresentation &&
            <div className="col-12 dad2_render">
                <TextField
                    placeholder={""}
                    InputLabelProps={{ shrink: true }}
                    required
                    name="text"
                    value={hieroglyph}
                    onChange={event => setHieroglyph(event.target.value)}
                    label={"Иероглифы"}
                    fullWidth
                    multiline={false}
                    margin="normal"
                    className="mt-0 mb-4"
                />
            </div>
        }
        <div>
            {(hieroglyph != "") && 
            <>
                <dev ref={refWh}></dev>
                {options.userInfo.userInfo.type != 'student' && <Button onClick={chainAnimations}> Play</Button>}
            </>
            }
        </div>
      </>  
    );

}

export default WritingHieroglyph;