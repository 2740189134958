import React from 'react';
import { DropTarget } from 'react-dnd';

const style = {
    display: 'flex',
    alignContent: 'flex-start',
    flexDirection: 'column',
    height: 'auto',
    minHeight: '40px',
    marginRight: '5px',
    marginLeft: '5px',
    marginBottom: '10px',
    color: '#000',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    float: 'left',
    borderRadius: '30px',
    border: '1px solid #4db2fa',
    overflow: 'hidden',
};
export const Dustbin2Columns = ({
    isHomework,
    isHomeworkFinish,
    dustbinName,
    trueWords,
    isOver,
    canDrop,
    connectDropTarget,
    DroppedItems,
}) => {
    // работа над закрашивание площади, куда перетаскиваем
    const isActive = isOver && canDrop;
    let backgroundColor = '#fff';

    if (isActive) {
        backgroundColor = '#92ddf6';
    } else if (canDrop) {
        backgroundColor = '#d3ecf5';
    }

    if (DroppedItems && DroppedItems.name === dustbinName) {
        backgroundColor = '#4db2fa';
    }
    ////////////////////////////////////

    return connectDropTarget(
        <div style={{ ...style,  backgroundColor}}>
            <p className='w2col_title'>{dustbinName}</p>
            {DroppedItems && DroppedItems.map(el => {
                let dustbinItemStyle = trueWords.includes(el.name) ?
                    { backgroundColor: '#4db2fa', borderRadius: '10px', color: '#fff', margin: '8px auto', width: 'max-content',
                    padding: '5px 15px' }
                    : { backgroundColor: '#fff', borderRadius: '10px', border: '1px solid #e83320', color: '#000', margin: '8px auto', width: 'max-content',
                    padding: '5px 15px' };

                if (isHomework && !isHomeworkFinish) {
                    dustbinItemStyle = { backgroundColor: '#fff', borderRadius: '10px', border: '1px solid #4db2fa', color: '#000', margin: '8px auto', width: 'max-content',
                    padding: '5px 15px' };
                }

                return (
                    <p style={dustbinItemStyle}>{el.name}</p>
                );
            })}
        </div>
    );
};
export default DropTarget(
    (props) => props.accepts,
    {
        drop(props, monitor) {
            props.onDrop(monitor.getItem());
        },
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
)(Dustbin2Columns);
