import enLang from './entries/en-US';
import ruLang from './entries/ru_RU';
import ukLang from './entries/uk_UA';

import {addLocaleData} from 'react-intl';

const AppLocale = {
    en: enLang,
    ru: ruLang,
    uk: ukLang
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.ru.data);
addLocaleData(AppLocale.uk.data);

export default AppLocale;
