import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { dialogSelectLesson, dialogSetSocketMessage } from '../../actions/dialog';
import { setLessonSocket, setLessonsArr, setUsersInfo } from '../../actions/settings';
import { bindActionCreators } from 'redux';
import callApi from '../../utils/call-api';
import LessonEditor from '../lessonEditor/main/LessonEditor';
import config from '../../config';
import { logout } from '../../actions/auth';

const IS_WEBSOCKET_OPEN_STATUS = 1;

let counter = 1;

class LessonIndex extends React.Component {
    initState = {
        tabVal: 0,
        lesson: {},
        coursesRaw: [],
        open: false,
        socketMessage: { command: null },
        isLesson: false,
        isSendRequestForLessonInfo: false,
        vCId: '',
        userEmail: '',
        userType: '',
        isReadyToDoWssQuery: false,
    };

    constructor(props) {
        super(props);

        this.state = {...this.initState };
    }

    checkWebsocket = () => {
        if (this.state.socket.readyState !== IS_WEBSOCKET_OPEN_STATUS ) {
            this.setState({ ...this.initState }, this.componentInit );
        }
    };

    async componentDidMount() {
        await this.componentInit();
    }

    async componentInit () {
        const storage = JSON.parse(localStorage.getItem('user') || null);
        const vCId = new URLSearchParams(this.props.location.search).get('id');

        if (storage && storage.userInfo) {

            if (storage.userInfo.type) {
                this.setState({ userType: storage.userInfo.type });
            }

            if (storage.userInfo.email) {
                this.setState({ userEmail: storage.userInfo.email });
            }
        }

        this.setState({ vCId: vCId });

        const socket = new WebSocket(config.SOCKET_URI + `?vCId=${vCId}&userEmail=${this.props.userInfo.userInfo.email}&userType=${this.props.userInfo.userInfo.type}&userName=${this.props.userInfo.userInfo.name}`);

        socket.onopen = () => {
            this.props.setLessonSocket(socket);

            socket.onerror = error => {
                this.checkWebsocket();
            }

            socket.onclose = () => {
                this.checkWebsocket();
            }

            socket.onmessage = e => {
                try {
                    e = JSON.parse(e.data);

                    this.setState({ socketMessage: e });
                    this.props.dialogSetSocketMessage(e);

                    if (e.command === 'lesson') {
                        this.props.dialogSelectLesson(e.data.lessonId);
                    } else if (e.command === 'tab') {
                        this.setState({ tabVal: e.val });
                    } else if (e.command === 'roomUsersInfo') {
                        this.props.setUsersInfo(e.data);
                    }
                } catch (e) {}
            };

            this.setState({ socket });

            const f = () => {
                this.checkWebsocket();

                socket.send(JSON.stringify({
                    command: 'ping',
                    val: {
                        vCId: this.state.vCId,
                        userEmail: this.state.userEmail,
                        userType: this.state.userType
                    }
                }));

                setTimeout(() => f(), 10000);
            };

            f();
        };

    }    

    componentWillReceiveProps(nextProps) {
        if ((nextProps.lesson || nextProps.lesson === 0) && nextProps.lesson !== this.props.lesson) {
            callApi("content/getLesson", "GET", {id: nextProps.lesson})
            .then(data=>{
                return data.data.lesson;
            })
            .then(lesson=>this.setState({lesson: lesson}))
            .then(()=>{
                this.setState({ isLesson: true })
            })

            if (this.state.socket)
                this.state.socket.send(JSON.stringify({
                    command: 'lesson',
                    data: {lessonId: nextProps.lesson},
                    val: {
                        vCId: nextProps.lessonIdent.id,
                        userEmail: nextProps.userInfo.userInfo.email,
                        userType: nextProps.userInfo.userInfo.type
                    }
                }));

            if (['admin', 'teacher'].includes(this.props.userInfo.userInfo.type) && this.state.socket) {
                this.state.socket.send(JSON.stringify({
                    command: 'setVirtualClassInfo',
                    val:{
                        vCId: this.props.lessonIdent && this.props.lessonIdent.id,
                        vCParam: 'lessonId',
                        vCValue: nextProps.lesson,
                        userEmail: nextProps.userInfo.userInfo.email,
                        userType: nextProps.userInfo.userInfo.type
                    }
                }));
            }

            // this.setState({ isLesson: false },
            //     () => setTimeout(() => this.setState({ isLesson: true }), 200));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.socket && !this.props.lesson && this.props.lessonIdent &&
            this.state.isReadyToDoWssQuery &&
            !this.state.isSendRequestForLessonInfo
        ) {
            this.setState({ isSendRequestForLessonInfo: true }, () => {
                setTimeout(() => {
                    this.state.socket.send(JSON.stringify({
                        command: 'getVirtualClassInfo', 
                        val: {
                            vCId: this.state.vCId, //this.props.lessonIdent.id
                            userEmail: this.state.userEmail,
                            userType: this.state.userType
                        }
                    }));
                }, 1);
            });
        }
    }

    render() {
        if (!this.state.isLesson) {
            return (
                <div className="HelloImgWrapper" >
                    <img alt="Welcome" src="/icons/for_studio.png"/>
                </div>
            )
        }

        return (
            <LessonEditor
                virtualClassId={this.props.lessonIdent.id}
                lesson={this.state.lesson}
                coursesRaw={this.state.coursesRaw}
                lessonType="presentation"
                socket={this.state.socket}
                socketMessage={this.state.socketMessage}
                whiteboard={this.props.whiteboard}
            />
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        dialogSelectLesson,
        setLessonSocket,
        setLessonsArr,
        setUsersInfo,
        dialogSetSocketMessage,
        logout,
    },
    dispatch
);


const mapStateToProps = state => ({
    lesson: state.dialog.lesson,
    whiteboard: state.dialog.whiteboard,
    userInfo: state.auth.userInfo,
    lessonIdent: state.settings.lessonIdent || {},
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonIndex));
