import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import SideNav from './SideNav';
import MainContent from './MainContent';
import WrapperTop from './WrapperTop';
import {COLLAPSED_DRAWER, FIXED_DRAWER} from '../constants';
import comparingValuesInSymbol from '../utils/comparing-symbol-values';
import ExitFromLesson from './ExitFromLesson';
import callApi from '../utils/call-api';
import {setLessonIdent} from '../actions/settings';
import {bindActionCreators} from 'redux';
// import Tour from './Tour';

class VirtualClass extends React.Component {

    state = {
      showSideNav: true,
    };

    async componentDidMount() {
        const virtualLessonId = new URLSearchParams(this.props.location.search).get('id');

        await callApi('other/getVl', 'post', { virtualLessonId: virtualLessonId })
        .then(res => {
            if (res && res.data.ok) {
                const lessonData = res.data.virtualLessonData;
                this.props.setLessonIdent(lessonData);
            }
        })
        .catch(err => {
            console.log(err);
            window.location.href = `/admin/teacher`;
        });

    };


    render() {
        const { drawerType } = this.props;
        const virtualLessonId = new URLSearchParams(this.props.location.search).get('id');

        const drawerStyle = comparingValuesInSymbol(drawerType, FIXED_DRAWER) ? 'fixed-drawer' : comparingValuesInSymbol(drawerType, COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
        return (
            <div className={`app-container ${drawerStyle}`}>
                <ExitFromLesson
                    lessonsArr={this.props.lessonsArr}
                    vCId={virtualLessonId}
                />
                {/* <Tour /> */}
                {this.state.showSideNav &&
                    <SideNav
                        vCId={virtualLessonId}
                        updateSideNav={() => {
                            this.setState({ showSideNav: false }, () => {
                                setTimeout(
                                   () => this.setState({ showSideNav: true }),
                                   100
                                );
                            });
                        }}
                    />
                }
                <div className="app-main-container">
                    <WrapperTop/>
                    <MainContent/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setLessonIdent
    },
    dispatch
);

const mapStateToProps = ({settings}) => ({
    lessonsArr: settings.lessonsArr,
    drawerType: settings.drawerType,
    navigationStyle: settings.navigationStyle,
    horizontalNavPosition: settings.horizontalNavPosition,
    lessonIdent: settings.lessonIdent || {},
});

VirtualClass.propTypes = {
    drawerType: PropTypes.string,
    globalErrorDialogVisible: PropTypes.string,
    globalErrorDialogMessage: PropTypes.string,
    displayErrorMessage: PropTypes.func
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(VirtualClass));
