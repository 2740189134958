import React, { useState } from 'react';
import callApi from "../../../../utils/call-api";
import './AddLottie.css'

const EditLottie = ({ setActiveList, setActiveEdit, activeEditLottie }) => {
    const [editLottie, setEditLottie] = useState(activeEditLottie);
    const [selectedObjName, setSelectedObjName] = useState('');
    const [enterObjName, setEnterObjName] = useState(false);
    console.log(editLottie)

    const lang = textId => textId;

    async function saveLottie() {

        if (editLottie.name) {
            const formData = new FormData();

            if (!editLottie.fileId) {
                alert(lang('File id is missing'));

                return;
            }

            if (editLottie.description) {
                formData.append('description', editLottie.description);
            }

            formData.append('fileId', editLottie.fileId);
            formData.append('name', editLottie.name);
            formData.append('isCommon', editLottie.isCommon);
            formData.append('isIndividual', editLottie.isIndividual);

            if (editLottie.image) {
                formData.append('file', editLottie.image);
            }

            await callApi('dashboard/updateSystemFile', 'post', formData).then(res => {
    
                if (res && res.data.ok) {
                    console.log(res.data.message);
                    alert(lang(res.data.message));
                } else {
                    alert(lang(res.data.message));
                }
            }).catch(err => {
                alert(lang('Cannot update system file'));
                console.log(err);
            });

            setEnterObjName(false);
            setSelectedObjName('');
            setActiveEdit(false);
            setActiveList(true);
        } else {
            setEnterObjName(true)
        }
    }

    function cancelLottie() {
        setActiveEdit(false);
        setActiveList(true);
    }

    return (
        <div>
            <div className='lottie_upload_file'>
                Загрузите файл(замена существующего):
                <input name="file" type="file" onChange={e => setEditLottie({ ...editLottie, image: e.target.files[0] })} />
            </div>
            <div className='lottie_upload_name'>
                <p>Имя файла:</p>
                <input
                    type="text"
                    placeholder={enterObjName && selectedObjName.length === 0 ? 'Введите имя' : null}
                    className={enterObjName && selectedObjName.length === 0 ? 'lottie_upload_input' : null}
                    value={editLottie.name}
                    onInput={e => setEditLottie({ ...editLottie, name: e.target.value })} />
            </div>
            <div className='lottie_upload_description'>
                <p>Описание файла:</p>
                <div className='lottie_upload_text'>
                    <textarea id="lottie_edit_textarea" cols="30" rows="10"
                        value={editLottie.description}
                        onInput={e => setEditLottie({ ...editLottie, description: e.target.value })}
                    ></textarea>
                </div>
            </div>
            <div className='check_lottie_show'>
                <div>
                    <input
                        type="checkbox"
                        id='check_lottie_show_all'
                        checked={editLottie.isCommon}
                        onChange={e => setEditLottie({ ...editLottie, isCommon: e.target.checked })} />
                    <span>Показывать всем</span>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id='check_lottie_show_privat'
                        checked={editLottie.isIndividual}
                        onChange={e => setEditLottie({ ...editLottie, isIndividual: e.target.checked })} />
                    <span>Показывать индивидуально</span>
                </div>
            </div>
            <button className='lottie_upload_button' onClick={saveLottie}>Сохранить</button>
            <button className='lottie_upload_button' onClick={cancelLottie}>Отмена</button>
        </div>
    )
}

export default EditLottie;