let DomainForBack = process.env.REACT_APP_DOMAIN_FOR_BACK;
let DomainForFront = process.env.REACT_APP_DOMAIN_FOR_FRONT;
let SockerUri = process.env.REACT_APP_SOCKET_URI;

console.log(`DomainForBack - ${DomainForBack} ; DomainForFront - ${DomainForFront} ; SockerUri - ${SockerUri}`);
global.authUrl = '/enter';

export default {
    filePathSubstLength: 20,
    DomainForFront,
    path: '',
    SOCKET_URI: SockerUri,
    API_URI: DomainForBack + '/backend/api/v1',

    defaultSkipValue: 0,
    defaultSkipStepValue: 200,
    defaultLimitValue: 200,
    maxLimitValue: 1000,

    imageStubGeneratorUrl: DomainForFront + '/icons',

    defaultCommentLength: 256,

    maxMarkValue: 5, //from 1 to 5

    imgUrlFromBD: DomainForFront + '/imgs/',
    lottieUrlFromBD: DomainForFront + '/lottie/',

    supportedLanguages: {//must be like in DB, becuse using not id
        en: 'English'
    }
};
