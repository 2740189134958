import React from 'react';
import Lottie from "react-lottie";
import redDotLottie from "../assets/lottie/red_dot.json";

const defaultLottieOptions = {
    loop: true,
    autoplay: false,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const LogoBrand = () => (
    <div className="logo-wrap">
        <div className="red-dot-lottie">
            <Lottie
                options={{
                    ...defaultLottieOptions,
                    autoplay: true,
                    animationData: redDotLottie
                }}
            />
        </div>
        <div className="logo-img"/>
    </div>
);

export default LogoBrand;
