import React from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { TIME_PERIODS_IN_HOUR } from "./TimeTableHelper";
import callApi from "../../../../utils/call-api";
import IntlMessages from "../../../../utils/IntlMessages";

class TimeTableCreateTeacherSchedule extends React.Component {
    state = {
        isOpen: false,
        userGroupInfo: null,
        isShowBannerNaw: false,
        activeBannerKey: '',
        selectFirstVariant: false,
        selectSecondVariant: false,
    };
    wrapperRef = React.createRef();

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    prepareUserGroupInfo = () => {
        const { virtualLessonInfo } = this.props;

        if (!virtualLessonInfo?.id) return;
        if (virtualLessonInfo?.students?.length < 2) return;

        callApi('dashboard/getGroupsByStudents', 'POST', {
            virtualLessonId: virtualLessonInfo.id,
        }).then(res => {
            const result = res?.data;
            if (!result?.ok) return;

            const userGroupInfo = result.groupData;
            const userGroupStudentsData = result.studentsData;

            this.props.setGroupInfo(virtualLessonInfo.id, userGroupInfo);
            this.setState({ userGroupInfo, userGroupStudentsData });
        });
    }

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            document.removeEventListener("mousedown", this.handleClickOutside);
            this.setState({
                isOpen: false,
                isShowBannerNaw: false,
                selectFirstVariant: false,
                selectSecondVariant: false,
            });
        }
    }

    componentDidMount() {
        this.prepareUserGroupInfo();
    }

    render() {
        const { dayNumber, hourNumber, weekBeginDate, isSchedule, virtualLessonInfo, currentTeacherSchedule } = this.props;
        const { isOpen } = this.state;
        console.log('virtualLessonInfo',virtualLessonInfo)


        let scheduleItemStartDatetime = new Date(
            weekBeginDate.getFullYear(),
            weekBeginDate.getMonth(),
            weekBeginDate.getDate(),
            Math.floor(hourNumber / TIME_PERIODS_IN_HOUR),
            Math.floor(hourNumber % TIME_PERIODS_IN_HOUR) * Math.floor(60 / TIME_PERIODS_IN_HOUR));

        scheduleItemStartDatetime.setDate(scheduleItemStartDatetime.getDate() + dayNumber);
        scheduleItemStartDatetime = scheduleItemStartDatetime.getTime() / 1000;


        return (
            <div className={"TimeTableContextDialogWrapper" +
                (isSchedule ? " TimeTableGridRowCellInfoGreen" : "")
            }
                 ref={this.wrapperRef}
                 onClick={() => {
                     const { isOpen } = this.state;
                     if (isOpen) {
                         this.setState({ isOpen: !isOpen, isShowBannerNaw: false });
                         document.removeEventListener("mousedown", this.handleClickOutside);
                     } else {
                         this.setState({ isOpen: !isOpen });
                         document.addEventListener("mousedown", this.handleClickOutside);
                     }
                 }}
            >
                {isOpen &&
                <div className={"TimeTableContextDialog TimeTableContextDialogGreen"}
                     onClick={e => e.stopPropagation()}
                >
                    <div className="TimeTableContextDialogContent">
                        <div className="TimeTableContextDialogContentBanner TimeTableContextDialogContentBannerGreen scale"
                            onClick={() => {
                                if (!isSchedule) {
                                    currentTeacherSchedule.push({
                                        startDate: scheduleItemStartDatetime,
                                        endDate: scheduleItemStartDatetime + Math.floor(60 / TIME_PERIODS_IN_HOUR) * 60,
                                    });
                                } else {
                                    const elemIndex = currentTeacherSchedule.findIndex(el => (el.startDate === scheduleItemStartDatetime));

                                    if (elemIndex !== -1) {
                                        currentTeacherSchedule.splice(elemIndex, 1);
                                    }
                                }

                                callApi('dashboard/manageTeacherSchedule', 'POST', {
                                    schedule: currentTeacherSchedule,
                                }).then(res => {
                                    const result = res?.data;
                                    if (!result?.ok) return;

                                    this.props.updateSchedule();
                                });
                            }}
                        >
                            {isSchedule ? <IntlMessages id="Закрыть для" /> : <IntlMessages id="Открыть для" />}
                                <br /><IntlMessages id="бронирования урока" />
                        </div>
                    </div>

                    {/*<div className="TimeTableContextDialogContentNext">*/}
                    {/*    <div className="TimeTableContextDialogContentBannerCircle"/>*/}
                    {/*    <div className="TimeTableContextDialogContentBannerText">*/}
                    {/*        Открыть это время для бронирования урока на последующие недели</div>*/}
                    {/*</div>*/}

                </div>}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

const mapStateToProps = state => (
    {
        userInfo: state.auth.userInfo,
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeTableCreateTeacherSchedule));
