import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const lang = textId => textId;

const FormDialog = ({closeDialog, dialogVisible, addLessonComment, lessonId, comment}) => (
    <Dialog open={dialogVisible} onClose={closeDialog} fullWidth={true}>
        <DialogTitle>{lang('Комментарий')}</DialogTitle>
        <DialogContent>
            <div className="about-myself">
                <div className="profile-content-item">
                    <TextField
                        id='addLessonCommentInput'
                        multiline={true}
                        variant='outlined'
                        rowsMax={15}
                        color="primary"
                        fullWidth={true}
                        placeholder={lang('Ваш комментарий...')}
                        defaultValue={comment || ''}
                    ></TextField>
                    <Typography component="h3" id='addLessonCommentInputError' color='error'></Typography>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <div className="close-profile">
                <Button onClick={closeDialog} color="primary">X</Button>
            </div>
            <Button
                onClick={() => {
                    addLessonComment(
                        lessonId,
                        document.getElementById('addLessonCommentInput').value
                    )
                }}
                color="primary"
            >
                {lang('Отправить')}
            </Button>
        </DialogActions>
    </Dialog>
);

export default FormDialog;





