import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import history from '../../utils/history';
import {
    IntegratedSelection,
    SelectionState,
    TreeDataState,
    CustomTreeData,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableSelection,
    Toolbar,
    TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';

const styles = theme => ({
    dialogContent: {
        minWidth: 500,
        [theme.breakpoints.up('sm')]: {
            minHeight: 120,
        }
    }
});

const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter(r => r.parentId === (row ? row.id : null));

    return childRows.length ? childRows : null;
};

class LessonCheckDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: this.props.open || false,
            columns: [
                { name: 'name', title: 'Name' },
            ],
            rows: [],
            selection: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.lessonsArr.length !== this.props.lessonsArr.length) {
            this.setState({ rows: this.props.lessonsArr });
        }

        if ((prevProps.recommendedLesson !== this.props.recommendedLesson) || (prevState.rows.length !== this.state.rows.length)) {
            const recommendedLessonRowNum = this.state.rows.findIndex(el => el.id === this.props.recommendedLesson);

            if (recommendedLessonRowNum > -1) {
                this.setState({ selection: [recommendedLessonRowNum] });
            }
        }
    }

    handleClose = () => {
        this.setState({ open: false });
        this.props.HideDialog();
    };

    handleOk = () => {
        const selectedRowsIds = [];
        this.state.rows.forEach((el, i) => {

            if (this.state.selection.includes(i)) {
                selectedRowsIds.push(el.id);
            }
        });

        if (this.props.selectionType === 'homework') {
            this.props.dialogSelectLesson(selectedRowsIds);
        } else if (selectedRowsIds.length) {
            this.props.dialogSelectLesson(selectedRowsIds[0]);
        }

        this.setState({ open: false });
        this.props.HideDialog();
    };

    componentWillMount() {
        setTimeout(() => {
            this.setState({
                open: this.props.show
            });
        }, 500);
    }

    componentWillUnmount() {
        if (window.location.pathname === '/resetstep2'
            || window.location.pathname === '/confirm'
            || window.location.pathname === '/signup') {
            history.push(global.authUrl);
        }
    }

    render() {
        const { classes } = this.props;
        const { rows, columns, selection } = this.state;

        return (
            <div>
                <MUIDialog open={this.props.open} onClose={this.handleClose}>
                    <DialogTitle>
                        Select lesson
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText>
                            <Grid
                                rows={rows}
                                columns={columns}
                            >
                                <SelectionState
                                    selection={selection}
                                    onSelectionChange={selection => {
                                        if (this.props.selectionType === 'homework') {
                                            return this.setState({ selection });
                                        }

                                        return this.setState({ selection: [selection[selection.length - 1]] });
                                    }}
                                />
                                <TreeDataState/>
                                <CustomTreeData
                                    getChildRows={getChildRows}
                                />
                                <IntegratedSelection/>
                                <Table/>
                                <TableSelection/>
                                <TableHeaderRow/>
                                <Toolbar/>
                                <TableTreeColumn
                                    for="name"
                                    // showSelectionControls
                                />
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleOk} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </MUIDialog>
            </div>
        );
    }
}

export default withStyles(styles)(LessonCheckDialog);
