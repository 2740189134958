import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from './TextField';
import { Alert } from 'reactstrap';
import { Redirect, NavLink } from 'react-router-dom';
import Config from '../../config';
import { login } from '../../actions';
import { validateEmail } from './helpers';
import IntlMessages from '../../utils/IntlMessages';
import lang from '../../utils/lang';


export default function withRouterSignIn ({ className = '', updateAuthType = () => {} }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    const errorMessage = useSelector(state => state.auth.showMessage);
    const alertMessage = useSelector(state => state.auth.alertMessage);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);


    const isValidForm = validateEmail(email) && password;

    if (isAuthenticated) {
        return <Redirect to={ Config.path + "/admin/superadmin"} />;
    }

    return (
        <div className={"AuthComponentPathSignin " + className}>
            {errorMessage && (
                <Alert className="shadow-lg bg-danger text-white">
                    {(alertMessage === 'Request failed with status code 404' || alertMessage === 'Request failed with status code 400') ?
                        lang('Почта или пароль некорректны') :
                        lang('Ошибка входа. Обратитесь в службу поддержки')
                    }
                </Alert>
            )}

                <div className="AuthComponentPathForm AuthComponentPathFormSignin">
                    <TextField
                        type="email"
                        label="Email"
                        value={email}
                        handleInputChange={val => setEmail(val)}
                    />

                    <TextField
                        type="password"
                        label={lang("Пароль")}
                        value={password}
                        handleInputChange={val => setPassword(val)}
                    />

                    <div className="AuthComponentPathSigninLinks">
                        <div className="ForgotPassword">
                            <div className="ForgotPasswordButton">
                                <NavLink to={Config.path + "/pass/recovery"} >
                                    <IntlMessages id="Забыли пароль?" />
                                </NavLink>
                            </div>

                        </div>
                        <div className="SigninButtons">
                            <div className="AuthComponentPathSigninLinksButton"
                                 style={isValidForm ? {} : { pointerEvents: 'none', filter: 'grayscale(90%)' }}
                                 onClick={() => {
                                     dispatch(login({
                                         email: email,
                                         password: password,
                                     }));
                                 }}
                            >
                                <IntlMessages id="ВОЙТИ" />
                            </div>
                            <div className="AuthComponentPathSigninLinksLink"
                                 onClick={() => updateAuthType()}
                            >
                                <IntlMessages id="Создать аккаунт" />
                            </div>
                        </div>
                    </div>
                </div>

            <div className="AuthComponentPathSigninRocket"/>
        </div>
    );
}
