import React, {useState} from "react";
import p from './paintPanel.module.scss';

const SMALL = '5';
const MIDDLE = '10';
const LARGE = '20';

//exo2 размер 20 высота 22 ширина 300. Для заголовков ширина 600


const PaintPanel = props => {
    const [state, setState] = useState({
        width: MIDDLE,
        color: '#000',
        clear: false,
        erase: false,
    });

    let onChangeWidth = (width) => {
        if (state.color === false) {
            // после терки
            setState({...state, width, color: '#000', erase: false});
            props.getPaintState({color: '#000', width, clear: state.clear});
        }
        else {
            setState({...state, width, erase: false}) ;
            props.getPaintState({color: state.color, width, clear: state.clear});
        }
    };

    let onChangeColor = (color) => {
        if (state.width === false) {
            // после терки
            setState({...state, width: MIDDLE, color, erase: false});
            props.getPaintState({color, width: MIDDLE, clear: state.clear});
        }
        else {
            setState({...state, color, erase: false}) ;
            props.getPaintState({color, width: state.width, clear: state.clear});
        }
    };

    function eraser () {
        setState({...state, erase: true, color: false, width: false});
        props.getPaintState({color: '#FFF', width: '50', clear: state.clear});
    }

    function deleteAll () {
        setState({color: '#000', width: MIDDLE, clear: !state.clear,  erase: false});
        props.getPaintState({color: '#000', width: MIDDLE, clear: !state.clear});
    }

    return (
        <div className={p.panel_container_block}>
            <div className={p.panel_container} onClick={e => e.stopPropagation()}>
                <div className={p.line_size}>
                    <div className={state.width===SMALL ? p.small_excretion +' '+ p.active : p.small_excretion}
                         onClick={()=>onChangeWidth(SMALL)}>
                        <div className={p.small_btn} />
                    </div>

                    <div className={state.width===MIDDLE ? p.middle_excretion + ' ' + p.active : p.middle_excretion}
                         onClick={()=>onChangeWidth(MIDDLE)}>
                        <div className={p.middle_btn} />
                    </div>

                    <div className={state.width===LARGE ? p.large_excretion +' '+ p.active : p.large_excretion}>
                        <div className={p.large_btn} onClick={()=>onChangeWidth(LARGE)} />
                    </div>
                </div>
                <div className={p.line_color}>
                    <div className={state.color==='#FFF' ? p.white_excretion +' '+ p.active : p.white_excretion}>
                        <div className={p.white_btn} onClick={()=>{onChangeColor('#FFF')}}/>
                    </div>
                    <div className={state.color==='#000' ? p.black_excretion +' '+ p.active : p.black_excretion}>
                        <div className={p.black_btn} onClick={()=>{onChangeColor('#000')}}/>
                    </div>
                    <div className={state.color==='#00F' ? p.blue_excretion +' '+  p.active : p.blue_excretion}>
                        <div className={p.blue_btn} onClick={()=>{onChangeColor('#00F')}}/>
                    </div>
                    <div className={state.color==='#F00' ? p.red_excretion +' '+  p.active : p.red_excretion}>
                        <div className={p.red_btn} onClick={()=>{onChangeColor('#F00')}}/>
                    </div>
                    <div className={state.color==='#FF0' ? p.yellow_excretion +' '+ p.active : p.yellow_excretion}>
                        <div className={p.yellow_btn} onClick={()=>{onChangeColor('#FF0')}}/>
                    </div>
                    <div className={state.color==='#0F0' ? p.green_excretion +' '+  p.active : p.green_excretion}>
                        <div className={p.green_btn} onClick={()=>{onChangeColor('#0F0')}}/>
                    </div>
                    <div className={state.color==='#F0F' ? p.purple_excretion  +' '+ p.active : p.purple_excretion}>
                        <div className={p.purple_btn} onClick={()=>{onChangeColor('#F0F')}}/>
                    </div>
                </div>
                <div className={p.line_clear}>
                    <div
                        style={!props.showImgWhiteboard ? {} : {visibility: 'hidden', pointerEvents: 'none'}}
                        className={state.erase ? p.clear_erase +' '+ p.active : p.clear_erase} onClick={eraser}
                    />
                    <div className={p.clear_del} >
                        <div className={p.del_btn} onClick={deleteAll}>+</div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default PaintPanel;
