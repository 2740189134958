import React, {useEffect, useState} from 'react';

import { DnDGrid } from '../DnDGrid/Container';
import { switchArrayElements } from '../helpers/arrayForRandomSimulation';
import TeacherHelpInfo from "./common/TeacherHelpInfo";
import l from '../main/LessonEditor.module.scss';

const DnDGrid1 = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [rows, setRows] = useState(el.rows || []);
    const [resDustbin, setResDustbin] = useState(el.resDustbin || []);
    const [resBox, setResBox] = useState(el.resBox || []);
    const [heightPriority, setHeightPriority] = useState(el.heightPriority || '');
    const [heightInVh, setHeightInVh] = useState(el.heightInVh || '');
    const [widthInPercent, setWidthInPercent] = useState(el.widthInPercent || '');
    const [uuid, setUuid] = useState(el.uuid || '');
    const [gridRowsCnt, setGridRowsCnt] = useState(el.rows?.length || 1);
    const [clue, setClue] = useState(el.clue || []);

    useEffect(() => {
        options.changeElement({resDustbin, resBox, clue}, i, activeTabIndex)
    }, [resDustbin, resBox, clue]);

    const init= () => {
        let j = 0;
        const dustbin = [];
        const box = [];
        const clue = [];
        el.rows.map((row, rowIndex) => {
            let rowClue = '';
            row.map((item, columnIndex, columnArr) => {
                dustbin.push({ accepts: ['DnDGrid'], lastDroppedItem: null, i: item.text, imgSrc: item.src });
                box.push({ name: item.text, type: 'DnDGrid', i: j++ });
                rowClue += `${rowIndex + 1}x${columnIndex + 1} -> ${item.text}`;

                if (columnIndex + 1 !== columnArr.length) {
                    rowClue +=', ';
                }
            });
            clue.push(rowClue);
        });
        setResDustbin(dustbin);
        setResBox(switchArrayElements(box, i));
        setClue(clue);
    }
    
    useEffect(() => {
        init();
    }, []);
    

    return (
        <div className="DnDGridContainer">
            {options.userInfo.userInfo.type === 'teacher' && options.demoLesson ?
                <TeacherHelpInfo info={clue.map((elem) => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
            {resDustbin && resBox &&
                <>
                    <DnDGrid
                        updateHomeworkTasksInformation={options.updateHomeworkTasksInformation}
                        updateHomeworkErrors={options.updateHomeworkErrors}
                        isHomework={options.isHomework}
                        isHomeworkFinish={options.isHomeworkFinish}
                        uuid={uuid}
                        gridRowsCnt={gridRowsCnt}
                        heightPriority={heightPriority}
                        heightInVh={heightInVh}
                        widthInPercent={widthInPercent}
                        resDustbin={resDustbin}
                        resBox={resBox}
                        socket={options.socket}
                        socketMessage={socketMessage}
                        val={{
                            vCId: options.virtualClassId,
                            userEmail: options.userInfo.userInfo.email,
                            userType: options.userInfo.userInfo.type,
                        }}
                    />
                    {myVarIsMobileDevice &&
                        <Preview generator={generatePreview}/>
                    }
                </>}
        </div>
    );
};

export default DnDGrid1;