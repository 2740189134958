/* global document */
import React from 'react';
import PropTypes from 'prop-types';
import {isIOS, isMobile} from 'react-device-detect';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {IntlProvider} from 'react-intl';
import AppLocale from '../lngProvider';
import AppRoutes from './AppRoutes';
// import history from '../utils/history';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        suppressDeprecationWarnings: true
    }
});

class App extends React.Component {
    componentDidMount() {

        // ZoomMtg.generateSignature({
        //     meetingNumber: '12312312312',
        //     apiKey: 'ldhOu0PDTkaY01P1QZXiFg',
        //     apiSecret: 'f0LzP4686Eze0Gn46TaVSvAzgrLUSzLsDpGX',
        //     role: 0,
        //     success: function(res){
        //         console.log('sssssss!!!!',res.result);
        //     }
        // });

        // ZoomMtg.join({
        //     signature: 'bGRoT3UwUERUa2FZMDFQMVFaWGlGZy4xMjMxMjMxMjMxMi4xNTg3MzE1MzMzMjkxLjAuL0NMYTdWbFF3VGx4OFd3VGNETkI5aTBucm81MjEvRFNDc1Z1anU3QVBJOD0',
        //     meetingNumber: '12312312312',
        //     userName: 'sdfds',
        //     apiKey: 'ldhOu0PDTkaY01P1QZXiFg',
        //     userEmail: 'ogirok.mihail@gmail.com',
        //     passWord: 'f0LzP4686Eze0Gn46TaVSvAzgrLUSzLsDpGX',
        //     success: (success) => {
        //         console.log('sssssssss3')
        //         console.log(success)
        //     },
        //     error: (error) => {
        //         console.log('sssssssss4')
        //         console.log(error)
        //     }
        // })

        // ZoomMtg.init({
        //     leaveUrl: 'http://194.87.111.144:3000/meetingEnd',
        //     isSupportAV: true,
        //     success: (success) => {
        //         console.log('sssssssss1')
        //         console.log(success)
        //
        //
        //     },
        //     error: (error) => {
        //         console.log('sssssssss2')
        //         console.log(error)
        //     }
        // })

        // if (this.props.initURL === '') {
        //     this.props.setInitUrl(history.location.pathname);
        // }
    }

    render() {
        // const { locale } = this.props;
        const currentAppLocale = AppLocale['ru'];

        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height');
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height');
        }
        return (
            <MuiThemeProvider theme={theme}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    <div className="app-main">
                        <AppRoutes />
                    </div>
                </IntlProvider>
            </MuiThemeProvider>
            // <div></div>
        );
    }
}

App.propTypes = {
    locale: PropTypes.object
};

export default App;
