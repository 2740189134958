import React, { useState, useEffect } from 'react';
import s from "../SuperAdmin.module.css";
import callApi from '../../../../utils/call-api';
import YesNoDialog from '../../../common/YesNoDialog';
import "./cours.scss";

const LessonList = ({selectedCourse, homework, onSelectedLesson}) => {
    const [cours, setCourse] = useState(selectedCourse);
    const [isHomework, setIsHomework] = useState(homework);
    const [lessons, setLessons] = useState([]);
    const [isShowYesNoDialog, setIsShowYesNoDialog] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(null);

    useEffect(() => {
        setCourse(selectedCourse);
    }, [selectedCourse]);

    useEffect(() => {
        setIsHomework(homework);
    }, [homework]);

    useEffect(() => {
        loadLessons(cours.id);
    }, [cours]);

    const loadLessons = async(id) => {
        await callApi('dashboard/lessons', 'GET', {courseId: cours.id})
        .then(lessons => lessons.data.lessons)
        .then(lessons => setLessons(lessons))
        .catch(err => {
            console.log(err);            
        });
    }

    const deleteLesson = async(lesson) => {
        callApi('dashboard/deleteLesson', 'post', {
            lessonId: lesson.id
        })
        .then(res => {
            if (res && res.data.ok) {
                alert('Успешно удален');
                loadLessons(cours.id);
            }
        })
        .then(() => {
            setIsShowYesNoDialog(false);
        })
        .catch(err => {
            alert('Ошибка при удалении');
            setIsShowYesNoDialog(false);
        });
    };

    const onDelClick = (lesson)=> {
        setSelectedLesson(lesson);
        setIsShowYesNoDialog(true);
    }


    return (
        <>
            {lessons && lessons.map((lesson) => {
                return (
                    <div className='inline-block'>
                        <div 
                            key={lesson.id} 
                            className={s.lessonName_item}
                            style={{position: "relative"}}
                            onClick={() => {onSelectedLesson(lesson)}}
                        > 
                            {lesson.name}
                        
                        </div>
                        <div className="LessonNameItemRemoveLesson"
                            style={{position: "relative"}}
                            onClick={() => {onDelClick(lesson)}}
                        >
                            Del
                        </div>
                    </div>                    
                );})
            }
            {isShowYesNoDialog && <YesNoDialog
                title={`Are you sure you want to delete this lesson ${selectedLesson.name}?`}
                onOk={() => {deleteLesson(selectedLesson)}}
                onCansel={() => {setIsShowYesNoDialog(false)}}
            />}
        </>
        );
};

export default LessonList;