import * as types from '../constants/exitFromLesson';

const initialState = {
    exitFromLesson: false,
}

export default function exitFromLesson (state = initialState, action) {
    switch (action.type) {
        case types.OPEN_EXIT_FROM_LESSON:
            return {
                ...state,
                exitFromLesson: action.payload,
            };
        case types.CLOSE_EXIT_FROM_LESSON:
            return {
                ...state,
                exitFromLesson: action.payload,
            };
        default:
            return state;
    }
};