export default function handleErrors(response) {
    const errors = response.data;
    let title = '';
    let message = '';
    for (let error in errors) {
        if (error === 'className') {
            title = errors[error];
        }
        if (error === 'errors') {
            message = errors[error][0] ? errors[error][0].message : '' ;
        }
    }

    return {
        title,
        message
    };
}