import * as types from '../constants/dictionary';


export function openDict() {
    return dispatch => {
        dispatch({
            type: types.OPEN_DICTIONARY,
            payload: true
        });
    };
}

export function closeDict() {
    return dispatch => {
        dispatch({
            type: types.CLOSE_DICTIONARY,
            payload: false
        });
    };
}

export function selectedWordToTranslate(word) {
    return dispatch => {
        dispatch({
            type: types.SELECTION_WIN,
            payload: word
        });
    };
}

export function reLoad() {
    return dispatch => {
        dispatch({
            type: types.RE_LOAD,
            payload: true
        });
    };
}