import React, {useRef, useEffect, useState} from 'react';

import Button from '@material-ui/core/Button/Button';
import Input from '@material-ui/core/Input';

import l from '../main/LessonEditor.module.scss';
import callApi from '../../../utils/call-api';
import config from '../../../config';

// {
//     "src": "ct/Epiclang/epiclang_back/.tmp/uploads/undefinedfile/e59732e0-fce0-11ef-b35b-af352a237ad0.mp3",
//     "link": "",
//     "name": "Audio",
//     "uniqId": "9bacc640-fce0-11ef-ab9b-e5b81623bf06",
//     "audioPlayerId": "audio_0_item_0"
//   }

const AudioPlayer = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [ isHomework ] = useState(options.isHomework);
    const [isPresentation]  = useState(options.isPresentation);
    const [src, setSrc ] = useState(el.src);
    const [selectedFile, setSelectedFile  ] = useState(null);
    const [currentAudioTime, setCurrentAudioTime] = useState(0);
    const [uniqId] = useState(el.uniqId);
    const index= i;

    const audioRef = useRef(null);

    useEffect(() => {
        options.changeElement({src}, i, activeTabIndex);
    }, [src]);

    useEffect(()=>{
        if (socketMessage&&socketMessage.command === 'handleAudioPlayer') {
            if (options.isPresentation && socketMessage.data.uniqId === uniqId) {
                if (socketMessage.data.AudioPlayerAction === 'playAudio') {
                    audioRef.current.currentTime = socketMessage.data.currentAudioTime;
                    audioRef.current.play();
                } else if (socketMessage.data.AudioPlayerAction === 'pauseAudio') {
                    audioRef.current.pause();
                }
            }
        }
    },[socketMessage]);


    return (
        
        <div className={l.WIT_example} key={`tab_${activeTabIndex}_item_${i}`}>
            {!isPresentation && !src &&
                <div>
                    <div className={l.WIT_button}>
                        <Input type="file" name="file"
                               onChange={e => setSelectedFile(e.target.files[0])} 
                        />
                    </div>
                    <div className={l.WIT_button}>
                        <Button onClick={() => {
                            const formData = new FormData();

                            formData.append('name', 'mp3.mp3');
                            formData.append('fileType', 'mp3');
                            formData.append('file', selectedFile);

                            callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                if (res && res.data && res.data.length && res.data.fileName) {
                                    setSrc(res.data.fileName.substr(config.filePathSubstLength));
                                    // lessonItems[i].src = res.data.fileName.substr(config.filePathSubstLength);

                                    // this.setState({ tabs });
                                }
                            }).catch(err => {
                                console.log(err)
                            });
                        }}>Upload</Button>
                    </div>
                </div>}
            {src &&
                <div className={"YouTubePlayerContainer" +
                    ((['student'].includes(options.userInfo.userInfo.type) && !isHomework) ? " YouTubePlayerContainerPointerEvent" : " ")}
                >
                    <audio
                        controls
                        id={`audio_${activeTabIndex}_item_${i}`}
                        onSeeking={(event)=>{setCurrentAudioTime(event.target.currentTime)}}
                        ref={audioRef}
                    >
                        <source
                            src={src}
                            type="audio/ogg; codecs=vorbis"
                        />
                    </audio>
                </div>
            }

            {!['student'].includes(options.userInfo.userInfo.type) &&
                <div className="YouTubePlayerContainer">
                    <Button onClick={() => {
                        audioRef.current.play();
                        options.sendMessage(JSON.stringify({
                                command: 'handleAudioPlayer',
                                data: {
                                    i,
                                    AudioPlayerAction: 'playAudio',
                                    currentAudioTime: currentAudioTime,
                                }
                            }));
                    }}
                    >Старт</Button>
                    <Button onClick={() => {
                        audioRef.current.pause();

                        options.sendMessage(JJSON.stringify({
                            command: 'handleAudioPlayer',
                            data: { i, AudioPlayerAction: 'pauseAudio' },                            
                        }));                        
                    }}
                    >Стоп</Button>
                </div>}
        </div>
    );
};

export default AudioPlayer;