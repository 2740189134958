import React, { useState, useCallback, useEffect } from 'react';
import Dustbin from './Dustbin';
import Box from './Box';
import update from 'immutability-helper';
import random from '../../../utils/generator/numbers';

import { studentAnswerWrongAudio, studentAnswerSuccessSounds } from '../helpers/SoundsHelper';

export const DnDGrid = ({
    isHomework,
    isHomeworkFinish,
    updateHomeworkErrors = () => {},
    updateHomeworkTasksInformation = () => {},
    gridRowsCnt,
    resDustbin,
    resBox,
    socket,
    socketMessage = {},
    uuid,
    val,
    heightPriority,
    heightInVh,
    widthInPercent,
}) => {
    const [dustbins, setDustbins] = useState(resDustbin);
    const [boxes] = useState(resBox);
    const [previousMessage, setPreviousMessage] = useState('');
    const [droppedBoxNames, setDroppedBoxNames] = useState([]);

    function isDropped(boxName) {
        return droppedBoxNames.indexOf(boxName) > -1
    }

    useEffect(() => {
        updateHomeworkTasksInformation(uuid, dustbins.length);
    }, []);

    const handleDrop = useCallback(
        (dustbinName, dustbinIndex, item, flag = true) => {

            if (flag) {
                socket.send(JSON.stringify({
                    command: 'handleDropMatrix', 
                    data: {
                        dustbinIndex,
                        item, 
                        uuid,
                        dustbinName,
                    },
                    val,
                }));
            }

            try {
                const { name } = item;

                if (dustbinName === name) {
                    setDroppedBoxNames(
                        update(droppedBoxNames, name ? {$push: [name]} : {$push: []}),
                    );

                    const variant = random(studentAnswerSuccessSounds.length);
                    studentAnswerSuccessSounds[variant].play();
                    studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                } else {
                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                }

                if (isHomework) {
                    if (!dustbins[dustbinIndex].lastDroppedItem) {
                        updateHomeworkTasksInformation(uuid);

                        if (dustbinName !== name) {
                            updateHomeworkErrors(uuid, 1);
                            item.isError = true;
                        }
                    } else {
                        item.isError = dustbins[dustbinIndex].lastDroppedItem.isError;

                        if (dustbins[dustbinIndex].lastDroppedItem.name !== name) {
                            if (dustbins[dustbinIndex].lastDroppedItem.isError && dustbinName === name) {
                                updateHomeworkErrors(uuid, -1);
                                item.isError = false;
                            } else if (!dustbins[dustbinIndex].lastDroppedItem.isError && dustbinName !== name) {
                                updateHomeworkErrors(uuid, 1);
                                item.isError = true;
                            }
                        }
                    }
                }

                setDustbins(
                    update(dustbins, {
                        [dustbinIndex]: {
                            lastDroppedItem: {
                                $set: item,
                            },
                        },
                    }),
                )
            } catch (e) {
                _(e);
            }
        },
        [droppedBoxNames, dustbins],
    );

    if ((previousMessage !== JSON.stringify(socketMessage)) && (socketMessage.command === 'handleDropMatrix') &&
        (socketMessage.data.uuid === uuid)) {
        setPreviousMessage(JSON.stringify(socketMessage));
        handleDrop(socketMessage.data.dustbinName, socketMessage.data.dustbinIndex, socketMessage.data.item, false);
    }

    const dustbinsInRowCnt = dustbins.length / gridRowsCnt;

    if (typeof gridRowsCnt !== 'number' || typeof dustbinsInRowCnt !== 'number') {
        return (<></>);
    }

    return (
        <div>
            <div style={{ overflow: 'hidden', clear: 'both', width: 'fit-content', margin: '0 auto' }}>
                {boxes.map(({ name, type }, index) => (
                    <Box
                        isHomework={isHomework}
                        name={name}
                        type={type}
                        isDropped={isDropped(name)}
                        key={index}
                    />
                ))}
            </div>

            <div className="DnDItems" style={{ overflow: 'hidden', clear: 'both', width: '100%' }}>
                {[...Array(gridRowsCnt).keys()].map(i => (
                    <div className="DndRow">
                        {[...Array(dustbinsInRowCnt).keys()].map(j => (
                            <Dustbin
                                isHomework={isHomework}
                                isHomeworkFinish={isHomeworkFinish}
                                dustbinsInRowCnt={dustbinsInRowCnt}
                                heightPriority={heightPriority}
                                heightInVh={heightInVh}
                                widthInPercent={widthInPercent}
                                imgSrc={dustbins[i * dustbinsInRowCnt + j].imgSrc}
                                dustbinName={dustbins[i * dustbinsInRowCnt + j].i}
                                accepts={dustbins[i * dustbinsInRowCnt + j].accepts}
                                lastDroppedItem={dustbins[i * dustbinsInRowCnt + j].lastDroppedItem}
                                onDrop={item => handleDrop(dustbins[i * dustbinsInRowCnt + j].i, i * dustbinsInRowCnt + j, item)}
                                key={i * dustbinsInRowCnt + j}
                                index={i * dustbinsInRowCnt + j}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
};
