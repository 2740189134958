import React, {useEffect, useState} from "react";
import Button from '@material-ui/core/Button/Button';
import { Preview } from 'react-dnd-preview';
import TextField from '@material-ui/core/TextField';

import TeacherHelpInfo from "./common/TeacherHelpInfo";
import { Container } from '../n1/Container'
import { ContainerSpecial } from '../n1/ContainerSpecial';
import { ContainerFor2Columns } from '../n1/ContainerFor2Columns';
import { switchArrayElements } from '../helpers/arrayForRandomSimulation';

import l from '../main/LessonEditor.module.scss';

const Dad1 = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [text, setText] = useState(options.isPresentation ? true : el.text);
    const [resText, setResText] = useState(el.resText);
    const [resDustbin, setResDustbin] = useState(el.resDustbin)
    const [resBox, setResBox] = useState(el.resBox);
    const [isText, setIsText] = useState(el.isText);
    const [uniqId, setUniqId] = useState(el.uniqId);
    const [clue, setClue] = useState(el.clue);
    const [type] = useState(el.type);
    const [customTitle, setCustomTitle] = useState(el.customTitle||"");
    const [isPresentation, setIsPresentation] = useState(options.isPresentation||false);
 
    let renderClue = null;

    useEffect(()=>{
        options.changeElement({text, resBox, resDustbin, resText, clue, isText}, i, activeTabIndex);
    }, [text, resBox, resDustbin, resText, clue, isText]);

    
    const getPlaceholder = ()=>{
        if (type === 'special3') {
            return '[t1]a|b|c[t2]d|f|g';
        } if (type === 'special2') {
            return 'phrase_1_path_1[phrase_1_path_2]phrase_2_path_1[phrase_2_path_2]';
        } else {
            return 'I[lie]in[london]';
        }
    };

    const okHandlerDAD1 = () => {        
        let clue = [];

        if (type === 'special2') {
            let splitArr = text.split(']');
            for (let i = 0; i < splitArr.length; i++) {
                if (splitArr[i]) {
                    clue[i] = splitArr[i].split('[').join(' -> ');
                }
            }
        } else if (type === 'simple') {
            clue = text;
        }

        const textLocal = text.replace('\n', '');
        let textArray = textLocal.split('[');
        let resText = [], resDustbin = [], resBox = [];

        textArray.forEach((el, i) => {
            if (el.includes(']')) {
                el = el.split(']');
                resDustbin.push({ accepts: ['1'], lastDroppedItem: null, i: el[0] });
                resBox.push({ name: el[0], type: '1', i });
                resText.push(el[1]);
            } else {
                resText.push(el);
            }
        });

        resBox = switchArrayElements(resBox, i);
        setResText(resText);
        setResDustbin(resDustbin);
        setResBox(resBox);
        setIsText(true);
        setClue(clue);
    };

    const okHandlerDAD1Special = () => { // [деревья]клен|ольха|сосна[города]Донецк|Ангарск|Киев
        
        let textArray = text.split(']');

        const dustbin1 = textArray[0] ? textArray[0].substring(1) : '';
        const dustbinBefore1 = textArray[1] ? textArray[1].split('[') : [];
        const dustbinBefore2 = textArray[2];
        const dustbin2 = dustbinBefore1[1];

        text = dustbinBefore1[0] + '|' + dustbinBefore2;

        const resDustbinLocal = [
            { name: dustbin1, accepts: ['n11'], DroppedItems: [],
                i: dustbinBefore1[0] ? dustbinBefore1[0].split('|') : [] },
            { name: dustbin2, accepts: ['n11'], DroppedItems: [],
                i: dustbinBefore2 ? dustbinBefore2.split('|') : [] },
        ];
        let clue = [];

        for (let i = 0; i < resDustbinLocal.length; i++) {
            clue[i] = `${resDustbinLocal[i].name} -> (${resDustbinLocal[i].i.join(', ')})`;
        }

        let resBox = [];
        text = text.split('|');
        text.forEach((el, i) => {
            resBox.push({ name: el, type: 'n11', i });
        });
        resBox = switchArrayElements(resBox, i);

        setResDustbin(resDustbinLocal);
        setResBox(resBox);
        setIsText(true);
        setClue(clue);
    };

    const renderExerciseTitle = () => (
        <>
            {options.isPresentation ? customTitle :
                <input className="ExerciseContextInput"
                       value={customTitle}
                       onChange={e => {
                           setCustomTitle(e.target.value);
                       }}
                />
            }
        </>
    );

    if (options.userInfo.userInfo.type === 'teacher' && clue && el.type !== 'simple') {
        renderClue = <TeacherHelpInfo info={clue.map(elem => <div className={l.exercise_clue}>{elem}</div>)} />;
    } else if (options.userInfo.userInfo.type === 'teacher' && clue && el.type === 'simple') {
        renderClue = <TeacherHelpInfo info={<div className={l.exercise_clue}>{clue}</div>} />
    }

    return (
        <div key={`tab_${activeTabIndex}_item_${i}`}>
            {!isPresentation &&
                <div>
                    <div className="col-12 dad2_render">
                        <TextField
                            placeholder={getPlaceholder()}
                            InputLabelProps={{ shrink: true }}
                            required
                            name="text"
                            value={text}
                            onChange={event => setText(event.target.value)}
                            label={"text"}
                            fullWidth
                            multiline={false}
                            margin="normal"
                            className="mt-0 mb-4"
                        />
                    </div>

                    {(type !== 'special3') && <Button onClick={() => okHandlerDAD1()} color="primary">Ok</Button>}
                    {(type === 'special3') && <Button onClick={() => okHandlerDAD1Special()} color="primary">Ok</Button>}
                    <br /> <br />
                </div>}

            {(isPresentation||isText) &&
                <div className={l.dad2_example}>
                    <div className={l.wit_exercise_title}>
                        {renderExerciseTitle()}
                    </div>
                    {options.demoLesson ? renderClue : null}
                    <>
                        {(el.type === 'simple') &&
                            <Container
                                updateHomeworkErrors={options.updateHomeworkErrors}
                                updateHomeworkTasksInformation={options.updateHomeworkTasksInformation}
                                isHomework={options.isHomework}
                                isHomeworkFinish={options.isHomeworkFinish}
                                uniqId={uniqId}
                                resDustbin={resDustbin}
                                resBox={resBox}
                                resText={resText}
                                socket={options.socket}
                                socketMessage={socketMessage}
                                val={{
                                    vCId: options.virtualClassId,
                                    userEmail: options.userInfo.userInfo.email,
                                    userType: options.userInfo.userInfo.type,
                                }}
                            />}
                        {(el.type === 'special2') &&
                            <ContainerSpecial
                                updateHomeworkErrors={options.updateHomeworkErrors}
                                updateHomeworkTasksInformation={options.updateHomeworkTasksInformation}
                                isHomework={options.isHomework}
                                isHomeworkFinish={options.isHomeworkFinish}
                                uniqId={uniqId}
                                resDustbin={resDustbin}
                                resBox={resBox}
                                resText={resText}
                                socket={options.socket}
                                socketMessage={options.socketMessage}
                                val={{
                                    vCId: options.virtualClassId,
                                    userEmail: options.userInfo.userInfo.email,
                                    userType: options.userInfo.userInfo.type,
                                }}
                            />}
                        {(el.type === 'special3') &&
                            <ContainerFor2Columns
                                updateHomeworkErrors={options.updateHomeworkErrors}
                                updateHomeworkTasksInformation={options.updateHomeworkTasksInformation}
                                isHomework={options.isHomework}
                                isHomeworkFinish={options.isHomeworkFinish}
                                uniqId={uniqId}
                                resDustbin={resDustbin}
                                resBox={resBox}
                                resText={resText}
                                socket={options.socket}
                                socketMessage={options.socketMessage}
                                val={{
                                    vCId: options.virtualClassId,
                                    userEmail: options.userInfo.userInfo.email,
                                    userType: options.userInfo.userInfo.type,
                                }}
                            />}
                        {myVarIsMobileDevice &&
                            <Preview generator={generatePreview}/>
                        }
                    </>
                </div>}
        </div>
    );
}

export default Dad1;

