import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import MaterialTextField from '@material-ui/core/TextField';
import { number } from 'prop-types';


const AddPaidLessonsDialog = ({open, studentInfo, lang, type, durationList, onOkClick, onClose}) => {
    const [student, setStudent] = useState(studentInfo||{});
    const [language, setLanguage] = useState(lang||{});
    const [lessonType, setLessonType] = useState(type||{});
    const [durations, setDurations] = useState(durationList||{});

    const [paimentLessons, setPaimentLessons] = useState({});

    useEffect(() => {
        setStudent(studentInfo||{});
        setLanguage(lang||{});
        setLessonType(type||{});
        setDurations(durationList||[]);
        setPaimentLessons(getEmptyPaimenLesson(studentInfo, lang, type, durationList));
    }, [studentInfo, lang, type, durationList]);

    const getEmptyPaimenLesson= (student, language, lessonType, durations) => {
        if (!student || !language || !lessonType || !durations) return {};
        const object = {
            userId: student.id,
            languageId: language.id,
            lessonTypeId: lessonType.id
        };
        durations.forEach(duration => object[duration.duration]=0);
        return object;        
    }

    const change=(name, value)=> {
        let clone = { ...paimentLessons }
        clone[name]= value.target.value;
        setPaimentLessons(clone);
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{`Добавить ${lessonType.description} уроков по ${language.name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div>{`для студента ${student.name}`}</div>
                    {durations.map((item, index) => {
                        let v = paimentLessons[item.duration];
                        return (
                            <MaterialTextField
                                labelId={`select-label_${item.id}`}
                                id = {item.id}                            
                            // error = {element.validate && validateColumn(v, element.type, element.validate)}
                                label={item.duration}
                                type='number'
                                value={v}
                                onChange={val => change(item.duration, val)}                                
                            />
                        )
                    })}                   
                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onOkClick(paimentLessons)} color="primary">
                    Добавить
                </Button>
                <Button onClick={onClose} color="primary">
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>   
    )
}

export default AddPaidLessonsDialog;