import React, { useState, useRef } from 'react'
import { Editor, EditorState, ContentState } from 'draft-js';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'draft-js/dist/Draft.css';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button/Button';
import Input from '@material-ui/core/Input';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import CardMedia from '@material-ui/core/CardMedia';
import P5Wrapper from 'react-p5-wrapper';
import { withStyles } from '@material-ui/core/styles';
import IconDictionary from '../../../assets/images/icons/dictionary_white.svg';
import draw from '../draw/Draw';
import callApi from '../../../utils/call-api';
import { dialogSetWhiteboard, dialogSetImgWhiteboard } from '../../../actions/dialog';
import { v1 } from '../../../utils/uuid';
import config from '../../../config';
import l from './LessonEditor.module.scss';
import { openDict, selectedWordToTranslate } from '../../../actions/dictionary';
import random from '../../../utils/generator/numbers';
import { studentAnswerWrongAudio, studentAnswerSuccessSounds } from '../helpers/SoundsHelper';
import platformAccessSound from '../../../assets/sounds/platform_access.mp3';
import blockEndSound from '../../../assets/sounds/block_end.mp3';
import { switchArrayElements } from '../helpers/arrayForRandomSimulation';
import lang from "../../../utils/lang";

import VideoPlayer from '../lessonComponent/Player';
import AudioPlayer from '../lessonComponent/AudioPlayer';
import Dad1 from '../lessonComponent/Dad1';
import Dad2 from '../lessonComponent/Dad2';
import EditorComponent from '../lessonComponent/EditorComponent';
import EditorTeachersNote from '../lessonComponent/EditorTeacherNote';
import Wig from '../lessonComponent/Wig';
import Wit from '../lessonComponent/Wit';
import CWF from '../lessonComponent/CWF';
import TrueLies from '../lessonComponent/TrueLies';
import TestQuestion from '../lessonComponent/TestQuestion';
import DnDGrid1 from '../lessonComponent/DnDGrid';
import DnDGrid2 from '../lessonComponent/DnDGrid2';
import ButtonL from '../lessonComponent/ButtonL';
import PDF from '../lessonComponent/PDF';
import HieroglyphAndTransktiption from '../lessonComponent/HieroglyphAndTransktiption';
import WritingHieroglyph from '../lessonComponent/WritingHieroglyph';
import IMG from '../lessonComponent/Img';
import Slider from '../lessonComponent/Slider';
import ImgGrid from '../lessonComponent/ImgGrid';

const platformAccessAudio = new Audio();
platformAccessAudio.src = platformAccessSound;

const blockEndAudio = new Audio();
blockEndAudio.src = blockEndSound;

const StyledAppBar = withStyles({
    root: {
        'color': '#000',
        'backgroundColor': '#fff',
        'border-top-right-radius': '30px',
        'border-top-left-radius': '30px',
        'display': 'flex',
        'justify-content': 'space-around',
    },
    label: { textTransform: 'capitalize' },
})(AppBar);

const StyledTab = withStyles({
    root: {}
})(Tab);

class LessonEditor extends React.Component {
    
    prepareLessonItems = socket => {
        const { lesson } = this.props;
        let tabs;

        if (lesson && lesson.page && Array.isArray(lesson.page.tabs)) {
            tabs = lesson.page.tabs.map((tab, tabIndex) => {
                tab.tabPanel.lessonItems = tab.tabPanel.lessonItems.map((el, lessonItemIndex) => {
                    if (el.editorState) {
                        let currentVal = el.currentVal || '';
                        if (el.name === 'TeachersNote' || el.name === 'Editor') {
                            currentVal =  JSON.stringify(el.currentVal || '').replaceAll('u0001', 'n');
                            currentVal = JSON.parse(currentVal) || '';
                        }

                        return {
                            ...el,
                            editorState: EditorState.createWithContent(ContentState.createFromText(currentVal)),
                            itemCreateFunction: this.getItemCreateFunction(el, 0, false),
                            isText: false,
                            isRenderCode: false,
                        }
                    }

                    if (el.isDrawing) {
                        return {
                            ...el,
                            whiteboard: draw(
                                socket || { send: () => null },
                                el.uuid,
                                this.prepareInfo(),
                                false,
                                () => (this.state.isUserHaveNotControl && ['student'].includes(this.props.userInfo.userInfo.type)),
                                () => {
                                    return tabs[tabIndex].tabPanel.lessonItems[lessonItemIndex].canvasImgContainerRef;
                                }
                            ),
                            itemCreateFunction: this.getItemCreateFunction(el, 0, false),
                            isText: false,
                            isRenderCode: false,
                            canvasImgContainerRef: React.createRef(),
                        };
                    }

                    return {
                        ...el,
                        itemCreateFunction: this.getItemCreateFunction(el, 0, false),
                        isText: false,
                        isRenderCode: false,
                    };
                });

                return tab;
            });
        }

        return tabs;
    };

    isPresentation = (this.props.lessonType === 'presentation');
    isNotPresentation = (this.props.lessonType !== 'presentation');

    constructor(props) {
        super(props);

        this.LessonEditorComponentRef = React.createRef();

        const { lesson } = this.props;
        const socket = this.props.socket || {
            addEventListener: () => {
            }, send: () => {
            }
        }; // TODO
        let lessonItems = this.prepareLessonItems(socket);

        if (socket) {
            socket.addEventListener("message", e => {
                try {
                    const data = JSON.parse(e.data);

                    if (data.command === 'sketchResize') {
                        const sketchWidth = data.data.sketchWidth;
                        if (sketchWidth) {
                            this.setState({ sketchWidth });
                        }
                    }

                    if (data.command === 'onControlledDrag') {
                        const { x, y } = data.data.position;
                        this.setState({ controlledPosition: { x, y } });
                    }

                    if (data.command === 'dialogSetWhiteboard') {
                        setTimeout(() => {
                            this.props.dialogSetWhiteboard(data.data);
                        }, 1000);
                    }

                    if (data.command === 'dialogChangeTab') {
                        const { tabs } = this.state;
                        let i = data.data || 1;
                        const lessonItems = tabs[i - 1];

                        if (!lessonItems) i = 1;

                        this.setState({ activeTabNumber: i });
                    }

                    // if (data.command === 'setLessonItems') {
                    //     this.setState({isRerend: true}, () => {
                    //         this.setState({...data.data, socket, isRerend: false});
                    //     });
                    // }

                    if (data.command === 'sendTruesLiesAnswer') {
                        const { tabs } = this.state;
                        tabs[data.data.activeTabIndex].tabPanel.lessonItems[data.data.lessonItemIndex]
                            .items[data.data.itemIndex].studentAnswer = data.data.answer;

                        const trueFalseSlider = tabs[data.data.activeTabIndex].tabPanel.lessonItems[data.data.lessonItemIndex]
                            .items[data.data.itemIndex];

                        if (typeof trueFalseSlider.studentAnswer === 'boolean') {
                            if (trueFalseSlider.studentAnswer === true) {
                                if (trueFalseSlider.isCorrect) {
                                    const variant = random(studentAnswerSuccessSounds.length);
                                    studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                } else {
                                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                }
                            } else {
                                if (trueFalseSlider.isCorrect) {
                                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                } else {
                                    const variant = random(studentAnswerSuccessSounds.length);
                                    studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                }
                            }
                        }

                        this.setState(tabs);
                    }

                    if (data.command === 'sendTestQuestionAnswer') {
                        const { tabs } = this.state;
                        tabs[data.data.activeTabIndex].tabPanel.lessonItems[data.data.lessonItemIndex].studentAnswer = data.data.answer;
                        tabs[data.data.activeTabIndex].tabPanel.lessonItems[data.data.lessonItemIndex].isSubmit = data.data.isSubmit;

                        if (data.data.isSubmit) {
                            if (data.data.isCorrect) {
                                const variant = random(studentAnswerSuccessSounds.length);
                                studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                            } else {
                                studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                            }
                        }

                        this.setState(tabs);
                    }

                    if (data.command === 'giveControlRemoteParticipant') {
                        if (data.data.participantEmail === this.props.userInfo.userInfo.email) {
                            this.setState(prevState => {
                                return { isUserHaveNotControl: !prevState.isUserHaveNotControl }
                            });

                            if (!this.state.isUserHaveNotControl) {
                                const appDivInputs = document.querySelectorAll('.App input');

                                for (let i = 0; i < appDivInputs.length; i++) {
                                    appDivInputs[i].disabled = '';
                                }
                            }
                        } else {
                            this.setState({ isUserHaveNotControl: true });
                        }

                        platformAccessAudio.play(); platformAccessAudio.volume = global.soundsVolume;
                    }
                } catch (e) {
                }
            });
        }

        let courseInfo = '';
        if (lesson && this.isPresentation) {
            const courseRaw = this.props.coursesRaw.find( elem => elem.id === lesson.courseId);
            if (courseRaw) {
                courseInfo = `${courseRaw.name} ${courseRaw.type}`;
            }
        }

        this.state = {
            homeworkErrors: {},
            homeworkTasksInformation: {},
            isHomeworkFinish: false,
            fewTasksCompletedMessage: false,
            isRerend: false,
            open: false,
            lessonName: lesson ? lesson.name : '',
            lessonId: lesson ? lesson.id : null,
            numberOfLesson: lesson && lesson.page && lesson.page.numberOfLesson,
            lessonCourseId: this.props.courseId,
            timeOfClass: lesson && lesson.page && lesson.page.timeOfClass,
            keyWords: lesson && lesson.page && lesson.page.keyWords,
            Grammar: lesson && lesson.page && lesson.page.Grammar,
            courseInfo,
            editor: null,
            selectItemsDialogVisible: true,
            lessonItems,
            socket: socket || { onmessage: () => null, send: () => null },
            selectedFile: null,
            selectedFiles: null,
            imageStubLink: `${config.imageStubGeneratorUrl}/100x100.png`,
            tabs: lessonItems || [{
                tab: {label: 'Main', value: '1'},
                tabPanel: {value: '1', lessonItems: []},
            }],
            activeTabNumber: '1',
            gridMaxRowsCount: 5,
            gridMaxColumnsCount: 5,

            sketch: (this.isPresentation) ? draw(
                socket || { send: () => null },
                'lessonWhiteboard',
                this.prepareInfo()
            ) : () => {},
            sketchWidth: 320,
            controlledPosition: {
                x: 0, y: 0
            },
            isUserHaveNotControl: !this.props.isHomework,
            selectedObj: {
                display: 'none',
                top: '',
                left: ''
            },
            selectedWordToTranslate: '',
            socketMessage: this.props.socketMessage || {},
        };
    }

    updateHomeworkTasksInformation = (uniqId, numberOfResponses = 0) => {
        const { homeworkTasksInformation } = this.state;

        try {
            if (numberOfResponses ) {
                homeworkTasksInformation[uniqId] = {
                    numberOfResponses,
                    currentResponsesCnt: 0,
                };
            } else {
                if (homeworkTasksInformation[uniqId] && (homeworkTasksInformation[uniqId].currentResponsesCnt < homeworkTasksInformation[uniqId].numberOfResponses)) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt++;
                }
            }
        } catch (e) {}

        this.setState({
            homeworkTasksInformation,
            fewTasksCompletedMessage: false,
        });
    };

    updateHomeworkWigInformation = (uniqId, responseCnt = 0) => {
        const { homeworkTasksInformation } = this.state;
        try {
            if (homeworkTasksInformation[uniqId]) {
                if (responseCnt > 0 && (homeworkTasksInformation[uniqId].currentResponsesCnt < homeworkTasksInformation[uniqId].numberOfResponses)) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt += responseCnt;
                } else if (responseCnt < 0 && (homeworkTasksInformation[uniqId].currentResponsesCnt >= Math.abs(responseCnt))) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt += responseCnt;
                }
            }
        } catch (e) {}

        this.setState({
            homeworkTasksInformation,
            fewTasksCompletedMessage: false,
        });
    }

    updateHomeworkErrors = (uniqId, changeErrorCnt = 1, isOneAnswer = false) => {
        const { homeworkErrors } = this.state;

        try {
            if (typeof homeworkErrors[uniqId] === 'undefined') {
                if (changeErrorCnt > 0) {
                    homeworkErrors[uniqId] = 1;
                }
            } else {
                if (isOneAnswer) {
                    if (changeErrorCnt > 0) {
                        homeworkErrors[uniqId] = 1;
                    } else {
                        homeworkErrors[uniqId] = 0;
                    }
                } else {
                    homeworkErrors[uniqId] += changeErrorCnt;
                }

            }
        } catch (e) {}

        this.setState({ homeworkErrors });
    };

    prepareInfo = () => ({
        vCId: this.props.virtualClassId,
        userEmail: this.props.userInfo.userInfo.email,
        userType: this.props.userInfo.userInfo.type,
    });

    componentDidUpdate(prevProps, prevState, snapshot) {
        // const { socketMessage } = this.props;

        if ((JSON.stringify(this.state.socketMessage) !== JSON.stringify(this.props.socketMessage))) {
            const { tabs } = this.state;
            this.setState({ socketMessage: this.props.socketMessage });

            // try {
            //     const lessonItems = tabs[this.state.activeTabNumber - 1].tabPanel.lessonItems;

            //     if (socketMessage.command === 'handleWig') {
            //         if (socketMessage.data.wigVal) {
            //             lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.wordsArrIndex].wigVal =
            //                 socketMessage.data.wigVal || [];
            //             lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.wordsArrIndex].className = '';
            //         } else  if (socketMessage.data.className) {
            //             lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.wordsArrIndex].className =
            //                 socketMessage.data.className || '';

            //             if (socketMessage.data.className === ' WIG-successful') {
            //                 const variant = random(studentAnswerSuccessSounds.length);
            //                 studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
            //             } else {
            //                 studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
            //             }
            //         }
            //     } else if (socketMessage.command === 'handleSimpleSlider' || socketMessage.command === 'handleTrueFalseSlider') {
            //         if (this.isPresentation && lessonItems[socketMessage.data.i] && lessonItems[socketMessage.data.i].carouselRef) {
            //             lessonItems[socketMessage.data.i].carouselRef.goTo(Number(socketMessage.data.pageIndex));
            //         }
            //     } else if (socketMessage.command === 'handleYoutubePlayer') {
            //         if (this.isPresentation &&
            //             lessonItems[socketMessage.data.i] && lessonItems[socketMessage.data.i].youtubePlayerRef) {
            //             if (socketMessage.data.YoutubePlayerAction === 'playVideo') {
            //                 // lessonItems[socketMessage.data.i].youtubePlayerRef.target.seekTo(socketMessage.data.youtubePlayerCurrentTime);
            //                 // lessonItems[socketMessage.data.i].youtubePlayerRef.target.playVideo();
            //                 lessonItems[socketMessage.data.i].youtubePlayerRef.playVideo(socketMessage.data.youtubePlayerCurrentTime);
            //             } else if (socketMessage.data.YoutubePlayerAction === 'pauseVideo') {
            //                 // lessonItems[socketMessage.data.i].youtubePlayerRef.target.pauseVideo();
            //                 lessonItems[socketMessage.data.i].youtubePlayerRef.pauseVideo();
            //             }
            //         }
            //     } else if (socketMessage.command === 'handleAudioPlayer') {
            //         if (this.isPresentation &&
            //             lessonItems[socketMessage.data.i] && lessonItems[socketMessage.data.i].audioPlayerId) {
            //             const audio = document.getElementById(lessonItems[socketMessage.data.i].audioPlayerId);
            //             if (socketMessage.data.AudioPlayerAction === 'playAudio') {
            //                 audio.currentTime = socketMessage.data.currentAudioTime || 0;
            //                 audio.play();
            //             } else if (socketMessage.data.AudioPlayerAction === 'pauseAudio') {
            //                 audio.pause();
            //             }
            //         }
            //     } else if (socketMessage.command === 'handleWIT' || socketMessage.command === 'handleCWF') {
            //         lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.index].className = socketMessage.data.className;
            //         lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.index].val = socketMessage.data.val;

            //         if (socketMessage.data.className === l.WIT_successful) {
            //             const variant = random(studentAnswerSuccessSounds.length);
            //             studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
            //         } else {
            //             studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
            //         }
            //     } else if (socketMessage.command === 'handleChangeEditor') {
            //         let currentVal = JSON.stringify(socketMessage.data.currentVal || lessonItems[socketMessage.data.i].currentVal).replaceAll('u0001', 'n');
            //         currentVal = JSON.parse(currentVal) || '';
            //         lessonItems[socketMessage.data.i].currentVal = currentVal;
            //         lessonItems[socketMessage.data.i].editorState = EditorState.createWithContent(ContentState.createFromText(currentVal));
            //     }
            // } catch (e) { }

            this.setState({ tabs });
        }

        if (prevProps.whiteboard.color !== this.props.whiteboard.color
            || prevProps.whiteboard.width !== this.props.whiteboard.width
        ) {
            this.props.socket.send(JSON.stringify({
                command: 'dialogChangeColor',
                data: this.props.whiteboard,
                val: this.prepareInfo(),
            }));
        }

        if (prevProps.whiteboard.clear !== this.props.whiteboard.clear) {
            this.props.socket.send(JSON.stringify({
                command: 'dialogWhiteboardClear',
                data: this.props.whiteboard,
                val: this.prepareInfo(),
            }));
        }
    }

    getItemCreateFunction(el, i = null, isRender, activeTabIndex) {
        const isPresentation = this.isPresentation;
        switch (el.name) {
            case 'DAD1':
                if (el.type === 'special3') {
                    return (isRender ? this.renderDAD1(el, i, activeTabIndex) : this.okHandlerDAD1Special);
                }
                return (isRender ? this.renderDAD1(el, i, activeTabIndex) : this.okHandlerDAD1);
            case 'DAD2':
                return (isRender ? this.renderDAD2(el, i, activeTabIndex) : this.okHandlerDAD2);
            case 'Editor':
                return (isRender ? this.renderEditor(el, i, activeTabIndex) : () => null);
            case 'TeachersNote':
                return (isRender ? this.renderEditorTeachersNote(el, i, activeTabIndex) : () => null);
            case 'WIG':
                return (isRender ? this.renderWIG(el, i, activeTabIndex) : this.okHandlerWIG);
            case 'WIT':
                return (isRender ? this.renderWIT(el, i, activeTabIndex) : this.okHandlerWIG);
            case 'CWF':
                return (isRender ? this.renderCWF(el, i, activeTabIndex) : this.okHandlerWIG);
            case 'IMG':
                return (isRender ? this.renderIMG(el, i, activeTabIndex) : this.renderIMG);
            case 'Slider':
                return (isRender ? this.renderSlider(el, i, activeTabIndex) : this.renderSlider);
            case 'Summer':
                return (isRender ? this.renderSummer(el, i, activeTabIndex) : this.renderSummer);
            case 'ImgGrid':
                return (isRender ? this.renderImgGrid(el, i, activeTabIndex) : this.renderImgGrid);
            case 'DnDGrid':
                return (isRender ? (isPresentation ? this.renderDnDGrid(el, i, activeTabIndex) : this.renderImgGrid(el, i, activeTabIndex, true)) : this.renderDnDGrid);
            case 'DnDGrid2':
                return (isRender ? (isPresentation ? this.renderDnDGrid2(el, i, activeTabIndex) : this.renderImgGrid(el, i, activeTabIndex, true, true)) : this.renderDnDGrid);
            case 'TrueLies':
                return (isRender ? this.renderTrueLies(el, i, activeTabIndex, isPresentation) : this.renderTrueLies);
            case 'testQuestion':
                return (isRender ? this.renderTestQuestion(el, i, activeTabIndex) : this.renderTestQuestion);
            case 'ButtonL':
                return (isRender ? this.renderButtonL(el, i, activeTabIndex) : this.renderButtonL);
            case 'HorizontalLine':
                return (isRender ? this.HorizontalLine(el, i, activeTabIndex) : this.HorizontalLine);
            case 'Youtube':
                return (isRender ? this.renderYoutube(el, i, activeTabIndex) : this.renderYoutube);
            // case 'Audio':
            //     return (isRender ? this.renderAudio(el, i, activeTabIndex) : this.renderAudio);
            case 'Audio':
                    return (isRender ? this.renderAudio(el, i, activeTabIndex) : this.renderAudio);
            case 'PDF':
                return (isRender ? this.renderPDF(el, i, activeTabIndex) : this.renderPDF);
            case 'HieroglyphText':
                return (isRender ?  this.renderHieroglyphText(el, i, activeTabIndex): this.renderHieroglyphText)
            case 'WritingHieroglyph':
                return (isRender ?  this.renderWritingHieroglyph(el, i, activeTabIndex): this.renderWritingHieroglyph)
            default:
                return (isRender ? (<div />) : () => {
                });
        }
    }

    componentDidMount() {
        if (this.isPresentation) {
            this.state.tabs.forEach((tab, index) => {
                tab.tabPanel.lessonItems.forEach((el, i) => {
                    el.itemCreateFunction(el, i, index);
                });
            });
        }

        if (this.props.isHomework) {
            setTimeout(() => {
                this.LessonEditorComponentRef.current.scrollTo({ top: 0 });
            }, 0);
        }
    };

    changeElement = (el, i, activeTabIndex) => {
        const { tabs } = this.state;
        let value = tabs[activeTabIndex].tabPanel.lessonItems[i];
        tabs[activeTabIndex].tabPanel.lessonItems[i] = {...value, ...el};
        this.setState({ tabs });
    }

    handleLinkChange = (event, i, activeTabIndex) => {
        event.persist();

        const { value } = event.target;
        const { tabs } = this.state;
        tabs[activeTabIndex].tabPanel.lessonItems[i].link = value;

        this.setState({ tabs });
    };

    handleDADChange = (event, i, activeTabIndex) => {
        event.persist();

        const { value } = event.target;
        const { tabs } = this.state;
        tabs[activeTabIndex].tabPanel.lessonItems[i].text = value;

        this.setState({ tabs });
    };

    okHandlerDAD1 = (el, i, activeTabIndex) => {
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        let { text } = el;
        let clue = [];

        if (el.type === 'special2') {
            let splitArr = text.split(']');

            for (let i = 0; i < splitArr.length; i++) {

                if (splitArr[i]) {
                    clue[i] = splitArr[i].split('[').join(' -> ');
                }
            }
        } else if (el.type === 'simple') {
            clue = text;
        }

        text = text.replace('\n', '');

        text = text.split('[');
        let resText = [], resDustbin = [], resBox = [];

        text.forEach((el, i) => {
            if (el.includes(']')) {
                el = el.split(']');
                resDustbin.push({ accepts: ['1'], lastDroppedItem: null, i: el[0] });
                resBox.push({ name: el[0], type: '1', i });
                resText.push(el[1]);
            } else {
                resText.push(el);
            }
        });

        resBox = switchArrayElements(resBox, i);
        lessonItems[i].resText = resText;
        lessonItems[i].resDustbin = resDustbin;
        lessonItems[i].resBox = resBox;
        lessonItems[i].isText = true;
        lessonItems[i].clue = clue;

        this.setState({ tabs });
    };

    okHandlerDAD2 = (el, i, activeTabIndex) => {
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        let { text } = el;

        const clue = (el.type === 'special1') ? text.split('|').join(' ') : text;
        text = (el.type === 'special1') ? text.split('|') : text.split('');
        const resDustbin = [];
        let resBox = [];

        text.forEach((el, i) => {
            resDustbin.push({ accepts: ['2'], lastDroppedItem: null, i: el });
            resBox.push({ name: el, type: '2', i });
        });

        resBox = switchArrayElements(resBox, i);

        lessonItems[i].resDustbin = resDustbin;
        lessonItems[i].resBox = resBox;
        lessonItems[i].isText = true;
        lessonItems[i].clue = clue;

        this.setState({ tabs });
    };

    okHandlerWIG = (el, i, activeTabIndex) => {
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        let text = el.editorState.getCurrentContent().getPlainText('\u0001').split('[');
        const textArr = [], wordsArr = [];
        const clue = [];
        let answerVar = '';

        if (el.name === 'WIG') {
            answerVar = 'text';
        } else if (el.name === 'WIT' || el.name === 'CWF') {
            answerVar = 'rightWord';
        }

        text.forEach((el, i) => {
            if (el.includes(']')) {
                el = el.split(']');
                textArr.push(el[1]);

                const infinitiveStart = el[0].indexOf('('), infinitiveEnd = el[0].indexOf(')');

                let infinitive = '';
                if (infinitiveStart !== -1 && infinitiveEnd !== -1) {
                    infinitive = el[0].substring(infinitiveStart + 1, infinitiveEnd) + ';';
                    el[0] = el[0].substring(infinitiveEnd + 2, el[0].length).trim();
                }

                let rightWord = '';
                el[0].split(';').forEach(elem => {
                    if (elem[0] === '{' && elem[elem.length - 1] === '}') {
                        rightWord = elem.replace('}', '').replace('{', '');
                    }
                });

                el[0] = el[0].replace('}', '').replace('{', '');
                wordsArr.push({
                    text: infinitive.trim() + el[0],
                    className: '',
                    val: infinitive.replace(';', ''),
                    rightWord
                });
            } else {
                if (el.includes('<')) {
                    el = el.split('<');
                    textArr.push(el[0]);
                } else {
                    textArr.push(el);
                }
            }
        });

        for (let i = 0; i < wordsArr.length; i++) {
            clue[i] = `${i + 1} -> ${wordsArr[i][answerVar]}`;
        }

        lessonItems[i].isRenderCode = true;
        lessonItems[i].textArr = textArr;
        lessonItems[i].wordsArr = wordsArr;
        lessonItems[i].className = '';
        lessonItems[i].clue = clue;

        if (!lessonItems[i].uuid) lessonItems[i].uuid = v1();

        this.setState({ tabs });
    };

    renderDnDGrid2 = (el, i, activeTabIndex) => {
        return <DnDGrid2
            el = {el}
            i = {i}
            activeTabIndex={activeTabIndex}
            options = {{
                updateHomeworkTasksInformation: this.updateHomeworkTasksInformation,
                updateHomeworkErrors: this.updateHomeworkErrors,
                isHomework: this.props.isHomework,
                isHomeworkFinish: this.state.isHomeworkFinish,
                socket: this.state.socket,
                virtualClassId: this.props.virtualClassId,
                userInfo: this.props.userInfo,
            }}
        />;
    }
    
    renderDnDGrid = (el, i,activeTabIndex) => {
        return <DnDGrid1 
            el={el} 
            i={i} 
            activeTabIndex={activeTabIndex}
            options={{
                changeElement: this.changeElement,
                updateHomeworkTasksInformation: this.updateHomeworkTasksInformation,
                updateHomeworkErrors: this.updateHomeworkErrors,
                isHomework: this.props.isHomework,
                isHomeworkFinish: this.state.isHomeworkFinish,
                socket: this.state.socket,
                virtualClassId: this.props.virtualClassId,
                userInfo: this.props.userInfo,
            }}
            socketMessage={this.state.socketMessage}
        />;
    }

    renderImgGrid = (el, i, activeTabIndex, isDnD = false, isDnD2 = false) => {
        return <ImgGrid
            el = {el}
            i = {i}
            activeTabIndex = {activeTabIndex}
            isDnD = {isDnD}
            isDnD2 = {isDnD2}
            options={{
                changeElement: this.changeElement,
                isPresentation: this.isPresentation,
            }}
        />;
    };

    renderButtonL = (el, i, activeTabIndex) => {
        return <ButtonL
            el={el}
            i={i}
            options={{
                changeElement: this.changeElement,
                isPresentation: this.isPresentation
            }}            
        />;
    }

    HorizontalLine = () => (<div style={{width: '100%', height: '1px', background: 'lightgray'}}/>);

    renderYoutube = (el, i, activeTabIndex) =>{
        // const player = VideoPlayer(el, i, activeTabIndex,
        //     {handleLinkChange: this.handleLinkChange,
        //      userInfo: this.props.userInfo,
        //      isPresentation: this.isPresentation,
        //      sendMessage: this.sendMessageToSocket
        //     });
        const { tabs } = this.state;
        // tabs[activeTabIndex].tabPanel.lessonItems[i].youtubePlayerRef = player;

        return (<VideoPlayer            
            el={el}
            i={i}
            activeTabIndex={activeTabIndex}
            options={{changeElement: this.changeElement,
                      userInfo: this.props.userInfo,
                      isPresentation: this.isPresentation,
                      sendMessage: this.sendMessageToSocket,
                      isHomework: this.props.isHomework,
                      isPresentation: this.isPresentation
                    }}
            socketMessage = {this.state.socketMessage}
            onReady = {ref => {
                tabs[activeTabIndex].tabPanel.lessonItems[i].youtubePlayerRef = ref;
                this.setState({ tabs });
            }}
        />);
    };

    sendMessageToSocket = (message)=> {
        message.val = this.prepareInfo();
        this.state.socket.send(JSON.stringify(message));
    };  

    renderAudio = (el, i, activeTabIndex) => {
        return <AudioPlayer el={el}
            i={i} 
            activeTabIndex={activeTabIndex} 
            options={{
                changeElement: this.changeElement,
                handleLinkChange: this.handleLinkChange,
                userInfo: this.props.userInfo,
                isPresentation: this.isPresentation,
                sendMessage: this.sendMessageToSocket,
                isHomework: this.props.isHomework,
                isPresentation: this.isPresentation
              }}
            socketMessage = {this.state.socketMessage}
        />;
    } 

    renderIMG = (el, i, activeTabIndex) => {
        return <IMG
            el = {el}
            i = {i}
            activeTabIndex = {activeTabIndex}
            options = {{
                changeElement: this.changeElement,
                isHomework: this.props.isHomework,
                isPresentation: this.isPresentation,
                ReactshowImgWhiteboard:  this.props.ReactshowImgWhiteboard,
                dialogSetImgWhiteboard: dialogSetImgWhiteboard
            }}
        />;
    }
    
    renderPDF = (el, i, activeTabIndex) => {
        return <PDF 
            el={el} 
            i={i} 
            activeTabIndex={activeTabIndex} 
            options={{
                changeElement: this.changeElement,
                isPresentation: this.isPresentation,
            }}
            socketMessage={this.state.socketMessage}
        />;
    };    

    renderHieroglyphText = (el, i, activeTabIndex)=>{
        return <HieroglyphAndTransktiption
            el = {el}
            i={i} 
            activeTabIndex={activeTabIndex} 
            options = {{
                changeElement: this.changeElement,
                isPresentation:  this.isPresentation,
                sendMessage: this.sendMessageToSocket,
                userInfo: this.props.userInfo
            }}
            socketMessage = {this.state.socketMessage}
        />;

    };

    renderWritingHieroglyph = (el, i, activeTabIndex)=>{
        return <WritingHieroglyph
            el ={{
                hieroglyph: "课"
            }}
            i={i} 
            activeTabIndex={activeTabIndex} 
            options ={{
                changeElement: this.changeElement,
                isPresentation: this.isPresentation,
                sendMessage: this.sendMessageToSocket,
                userInfo: this.props.userInfo
            }}
            socketMessage = {this.state.socketMessage}
        ></WritingHieroglyph>;
    }

    renderSlider = (el, i, activeTabIndex) => {
        return <Slider
            el = {el}
            i = {i}
            activeTabIndex = {activeTabIndex}
            options = {{
                changeElement: this.changeElement,
                isPresentation: this.isPresentation,
                sendMessage: this.sendMessageToSocket
            }}
            socketMessage = {this.state.socketMessage}
        />;  
    }

    renderSummer = (/*el, i, activeTabIndex*/) => {
        return (<></>);

        // const { showStudents } = el;
        // const { text } = el;
        // const { tabs } = this.state;
        //
        // const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        // if (!lessonItems[i]) return (<></>);
        //
        // let localStorageObject = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : '';
        // let { supervisor, admin, teacher } = JSON.parse(localStorageObject.userInfo.viewPermission);
        //
        // return (
        //     <div className={l.dad2_example} key={i}>
        //         {this.isNotPresentation &&
        //             <div>
        //                 <TextField // variant="outlined"
        //                     id="standard-multiline-flexible"
        //                     label="How did I spend my summer"
        //                     multiline
        //                     fullWidth
        //                     margin="normal"
        //                     name='Summer'
        //                     value={text}
        //                     onChange={e => this.handleDADChange(e, i, activeTabIndex)}
        //                 />
        //                 <div className={l.WIT_button}>
        //                     <Button>Done</Button>
        //                 </div>
        //             </div>}
        //
        //         {/*teacher presentation*/}
        //         {this.isPresentation && (supervisor || admin || teacher) &&
        //         <>
        //             <div className={l.wit_exercise_title}>How I spend my summer</div>
        //             <div className={l.summer_present}>
        //                 <div>
        //                     {this.state.tabs[activeTabIndex].tabPanel.lessonItems[i].text}
        //                 </div>
        //                 <div className={l.summer_teacher}>
        //                     <br/>
        //                     <h3 className={l.summer_teacher_header}>answers</h3>
        //                     {studentsData.map(s=>
        //                         <div key={s.id}>
        //                             <i>{s.name}</i>
        //                             <div>{s.text}</div>
        //                             <br/>
        //                         </div>)}
        //                     <Button className={l.summer_teacher_btn}
        //                         onClick={() => {
        //                             lessonItems[i].showStudents = !lessonItems[i].showStudents;
        //                             this.setState(tabs);
        //                         }}
        //                     >{!(lessonItems[i].showStudents) && 'Show' || 'Hide'}
        //                     </Button>
        //                 </div>
        //             </div>
        //         </>
        //         }
        //
        //         {/*student presentation*/}
        //         {this.isPresentation && (!teacher) && showStudents &&
        //             <div className={l.carousel}>
        //                 <div>
        //                     {this.state.tabs[activeTabIndex].tabPanel.lessonItems[i].text}
        //                 </div>
        //                 <div>
        //                     <br />
        //                     <h3>answers</h3>
        //                     {studentsData.map(s =>
        //                         <div key={s.id}>
        //                             <i>{s.name}</i>
        //                             <div>{s.text}</div>
        //                             <br />
        //                         </div>)}
        //                 </div>
        //             </div>
        //         }
        //         {this.isPresentation && (!teacher) &&
        //             <div>
        //                 <TextField
        //                     id="standard-multiline-flexible"
        //                     label="How did I spend my summer"
        //                     variant="outlined"
        //                     multiline
        //                     fullWidth
        //                     margin="normal"
        //                     name='Summer'
        //                     value={text}
        //                     onChange={e => this.handleDADChange(e, i, activeTabIndex)}
        //                 />
        //             </div>
        //         }
        //         <br />
        //     </div>
        // );
    };

    okHandlerDAD1Special = (el, i, activeTabIndex) => { // [деревья]клен|ольха|сосна[города]Донецк|Ангарск|Киев
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        let { text } = el;

        text = text.split(']');

        const dustbin1 = text[0] ? text[0].substring(1) : '';
        const dustbinBefore1 = text[1] ? text[1].split('[') : [];
        const dustbinBefore2 = text[2];
        const dustbin2 = dustbinBefore1[1];
        text = dustbinBefore1[0] + '|' + dustbinBefore2;

        const resDustbin = [
            { name: dustbin1, accepts: ['n11'], DroppedItems: [],
                i: dustbinBefore1[0] ? dustbinBefore1[0].split('|') : [] },
            { name: dustbin2, accepts: ['n11'], DroppedItems: [],
                i: dustbinBefore2 ? dustbinBefore2.split('|') : [] },
        ];
        let clue = [];

        for (let i = 0; i < resDustbin.length; i++) {
            clue[i] = `${resDustbin[i].name} -> (${resDustbin[i].i.join(', ')})`;
        }

        let resBox = [];
        text = text.split('|');
        text.forEach((el, i) => {
            resBox.push({ name: el, type: 'n11', i });
        });
        resBox = switchArrayElements(resBox, i);

        lessonItems[i].resDustbin = resDustbin;
        lessonItems[i].resBox = resBox;
        lessonItems[i].isText = true;
        lessonItems[i].clue = clue;

        this.setState({ tabs });
    };

    renderDAD1(el, i, activeTabIndex) { 
        return <Dad1 
            el={el} 
            i={i} 
            activeTabIndex={activeTabIndex} 
            options={{
                changeElement: this.changeElement,
                isPresentation: this.isPresentation,
                userInfo: this.props.userInfo,
                socket: this.state.socket,                
                updateHomeworkErrors: this.updateHomeworkErrors,
                updateHomeworkTasksInformation: this.updateHomeworkTasksInformation,
                isHomework: this.props.isHomework,
                isHomeworkFinish: this.state.isHomeworkFinish,
                virtualClassId: this.props.virtualClassId,
                demoLesson:this.props.demoLesson
            }}
            socketMessage={this.state.socketMessage}/>;
    }

    renderTrueLies= (el, i, activeTabIndex) => {
        return <TrueLies
            el={el}
            i={i}
            activeTabIndex={activeTabIndex}
            options={{
                changeElement: this.changeElement,
                homeworkTasksInformation: this.state.homeworkTasksInformation,
                isPresentation: this.isPresentation,
                userInfo: this.props.userInfo,
                sendMessage: this.sendMessageToSocket,
                updateHomeworkErrors: this.updateHomeworkErrors,
                updateHomeworkTasksInformation: this.updateHomeworkTasksInformation,
                isHomework: this.props.isHomework,
                isHomeworkFinish: this.state.isHomeworkFinish,
                virtualClassId: this.props.virtualClassId,
                demoLesson:this.props.demoLesson
            }}
            socketMessage={this.state.socketMessage}
        />;
    }

    renderTestQuestion = (el, i, activeTabIndex) => {
        return <TestQuestion
            el = {el}
            i = {i}
            activeTabIndex = {activeTabIndex}
            options={{
                changeElement: this.changeElement,
                homeworkTasksInformation: this.state.homeworkTasksInformation,
                isPresentation: this.isPresentation,
                userInfo: this.props.userInfo,
                sendMessage: this.sendMessageToSocket,
                updateHomeworkErrors: this.updateHomeworkErrors,
                updateHomeworkTasksInformation: this.updateHomeworkTasksInformation,
                isHomework: this.props.isHomework,
                isHomeworkFinish: this.state.isHomeworkFinish,
                virtualClassId: this.props.virtualClassId,
                demoLesson:this.props.demoLesson
            }}
            socketMessage={this.state.socketMessage}
        />;

    }
  
    renderDAD2(el, i, activeTabIndex) {
        return <Dad2
            el={el} 
            i={i} 
            activeTabIndex={activeTabIndex} 
            options={{
                changeElement: this.changeElement,
                isPresentation: this.isPresentation,
                userInfo: this.props.userInfo,
                socket: this.state.socket,                
                updateHomeworkErrors: this.updateHomeworkErrors,
                updateHomeworkTasksInformation: this.updateHomeworkTasksInformation,
                isHomework: this.props.isHomework,
                isHomeworkFinish: this.state.isHomeworkFinish,
                virtualClassId: this.props.virtualClassId,
                demoLesson:this.props.demoLesson
            }}
            socketMessage={this.state.socketMessage}/>;        
    } ;

    renderEditor(el, i, activeTabIndex) {
        return <EditorComponent
            el= {el}
            i = {i}
            activeTabIndex = {activeTabIndex}
            options = {{
                changeElement: this.changeElement,
                sendMessage: this.sendMessageToSocket
            }}
            socketMessage = {this.state.socketMessage}
        />;
    };

    renderEditorTeachersNote(el, i, activeTabIndex) {
        return <EditorTeachersNote
            el= {el}
            i = {i}
            activeTabIndex = {activeTabIndex}
            options = {{
                changeElement: this.changeElement,
                sendMessage: this.sendMessageToSocket,
                userInfo: this.props.userInfo
            }}
            socketMessage = {this.state.socketMessage}
        />
    };

    renderWIG(el, i, activeTabIndex) {  
        return <Wig
            el= {el}
            i = {i}
            activeTabIndex = {activeTabIndex}
            options = {{
                changeElement: this.changeElement,
                isHomework: this.props.isHomework,
                isPresentation: this.isPresentation,
                isHomeworkFinish: this.state.isHomeworkFinish,
                homeworkTasksInformation: this.state.homeworkTasksInformation,
                fewTasksCompletedMessage: this.state.fewTasksCompletedMessage,
                userInfo: this.props.userInfo,
                demoLesson: this.props.demoLesson,
                updateHomeworkErrors: this.updateHomeworkErrors,
                updateHomeworkWigInformation: this.updateHomeworkWigInformation,
                sendMessage: this.sendMessageToSocket
            }}
            socketMessage = {this.state.socketMessage}
        />; 
    }
    
    renderWIT(el, i, activeTabIndex) {
        return <Wit
            el= {el}
            i = {i}
            activeTabIndex = {activeTabIndex}
            options = {{
                changeElement: this.changeElement,
                isHomework: this.props.isHomework,
                isPresentation: this.isPresentation,
                isHomeworkFinish: this.state.isHomeworkFinish,
                homeworkTasksInformation: this.state.homeworkTasksInformation,
                fewTasksCompletedMessage: this.state.fewTasksCompletedMessage,
                userInfo: this.props.userInfo,
                demoLesson: this.props.demoLesson,
                updateHomeworkErrors: this.updateHomeworkErrors,
                updateHomeworkWigInformation: this.updateHomeworkWigInformation,
                sendMessage: this.sendMessageToSocket
            }}
            socketMessage = {this.state.socketMessage}
        />;
    }

    renderCWF(el, i, activeTabIndex) {
        return <CWF
            el={el}
            i={i}
            activeTabIndex={activeTabIndex}
            options={{
                changeElement: this.changeElement,
                isHomework: this.props.isHomework,
                isHomeworkFinish: this.state.isHomeworkFinish,
                homeworkTasksInformation: this.state.homeworkTasksInformation,
                sendMessage: this.sendMessageToSocket,
                updateHomeworkTasksInformation: this.updateHomeworkTasksInformation,
                updateHomeworkErrors: this.updateHomeworkErrors,
                userInfo: this.props.userInfo
            }}
            socketMessage={this.state.socketMessage}
        />
    }


    renderTabs() {
        const activeTabNumber = this.state.activeTabNumber;
        const activeTabIndex = activeTabNumber - 1;

        let disabledStyles = {};

        if ((this.props.userInfo.userInfo.type !== 'teacher' && this.props.userInfo.userInfo.type !== 'admin'
                && this.props.userInfo.userInfo.type !== 'supervisor'
            ) &&
            this.state.isUserHaveNotControl) {
            disabledStyles = { pointerEvents: 'none' };
            const appDivInputs = document.querySelectorAll('.App input');

            for (let i = 0; i < appDivInputs.length; i++) {
                appDivInputs[i].disabled = 'disabled';
            }
        }

        return (
            <TabContext value={activeTabNumber.toString()}>
                <div className={l.tab_header}>
                    <StyledAppBar position="static">
                        {!!['admin', 'teacher', 'supervisor'].includes(this.props.userInfo.userInfo.type) &&
                            <TabList variant="scrollable" scrollButtons="auto" aria-label="simple tabs example"
                                     onChange={this.tabHandleChange}
                            >
                                {this.state.tabs.map((tabItem, i) =>
                                    <StyledTab indicatorColor="primary"
                                               textColor="primary"
                                               value={tabItem.tab.value.toString()}
                                               label={ this.isPresentation ? tabItem.tab.label :
                                                   <input className="TabContextInput"
                                                          value={tabItem.tab.label}
                                                          onChange={e => {
                                                              const { tabs } = this.state;
                                                              tabs[i].tab.label = e.target.value;
                                                              this.setState(tabs);
                                                          }}
                                                   />
                                               }
                                    />
                                )}
                            </TabList>}
                        {!!['student'].includes(this.props.userInfo.userInfo.type) &&
                            <div className={"lessonEditorHeaderForStudent" + (this.props.isHomework ? " lessonEditorHeaderForStudentHomework" : "")}>
                                {this.state.lessonName}
                            </div>}
                    </StyledAppBar>
                </div>
                {this.state.tabs.map((tabItem, i) =>
                    <TabPanel
                        ref={this.LessonEditorComponentRef}
                        value={tabItem.tabPanel.value.toString()}
                    >
                        {(this.props.isHomework && this.state.isHomeworkFinish) ? this.props.renderHomeworkStudentMark() : (<></>)}
                        {!['student'].includes(this.props.userInfo.userInfo.type) && this.isPresentation && !i &&
                            <div className={l.summary_block}>
                                <div className={l.summary_header}>{lang('Summary of lesson')}</div>
                                <div> <br/>
                                    <div><b>{lang('Level: ')}</b>{this.state.courseInfo}</div>
                                    <div><b>{lang('Topic: ')}</b>{this.state.lessonName}</div>
                                    <div><b>{lang('№ of lesson: ')}</b>{this.state.numberOfLesson}</div>
                                    <div><b>{lang('Time of class: ')}</b>{this.state.timeOfClass}</div>
                                    <div><b>{lang('Key words: ')}</b>{this.state.keyWords}</div>
                                    <div><b>{lang('Grammar: ')}</b>{this.state.Grammar}</div>
                                </div>
                            </div>}
                        <div>
                            {tabItem.tabPanel.lessonItems.map((el, j) => {
                                return (
                                    <div style={ el?.name === 'PDF' ? {} : disabledStyles}>
                                        { this.isNotPresentation && <>
                                            <Button color='primary' onClick={() => {
                                                const { tabs } = this.state;
                                                let currentTab = tabs[i].tabPanel.lessonItems;
                                                currentTab.splice(j, 1);
                                                this.setState(tabs);
                                            }}>Delete</Button>
                                            {j > 0 &&
                                                <Button color='primary' onClick={() => {
                                                    const { tabs } = this.state;
                                                    let currentTab = tabs[i].tabPanel.lessonItems;
                                                    [currentTab[j - 1], currentTab[j]] = [currentTab[j], currentTab[j - 1]];
                                                    this.setState(tabs);
                                                }}>Move up</Button>}
                                            {j < tabItem.tabPanel.lessonItems.length - 1 &&
                                                <Button color='primary' onClick={() => {
                                                    const { tabs } = this.state;
                                                    let currentTab = tabs[i].tabPanel.lessonItems;
                                                    [currentTab[j + 1], currentTab[j]] = [currentTab[j], currentTab[j + 1]];
                                                    this.setState(tabs);
                                                }}>Move down</Button>}
                                        </>}
                                        {(i === activeTabIndex) && this.getItemCreateFunction(el, j, true, activeTabIndex)}
                                    </div>
                                );
                            })}
                            {(this.props.isHomework && !this.state.isHomeworkFinish)  &&
                                <div className="homeworkOkButton scale2"
                                     onClick={() => {
                                         const  { homeworkTasksInformation, homeworkErrors, fewTasksCompletedMessage } = this.state

                                         if (fewTasksCompletedMessage) return;

                                         try {
                                             let allTasksCnt = 0;
                                             let executedTasksCnt = 0;

                                             for (const [key, value] of Object.entries(homeworkTasksInformation)) {
                                                 allTasksCnt += value.numberOfResponses;
                                                 executedTasksCnt += value.currentResponsesCnt;
                                             }

                                             if ((executedTasksCnt / allTasksCnt) < this.props.homeworkEnoughAnswers) {
                                                 return this.setState({ fewTasksCompletedMessage: true });
                                             }

                                         } catch (e) {}

                                         this.setState({
                                             isHomeworkFinish: true,
                                             isUserHaveNotControl: true,
                                         });

                                         this.props.homeworkOnButtonOk(
                                             homeworkTasksInformation,
                                             homeworkErrors
                                         );

                                         this.LessonEditorComponentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                                     }}
                                >
                                    {!this.state.fewTasksCompletedMessage &&
                                        <>
                                            <div>{lang("ЗАВЕРШИТЬ ДОМАШНЕЕ ЗАДАНИЕ")}</div>
                                            <div>{lang("И ПРОВЕРИТЬ ПРАВИЛЬНОСТЬ ОТВЕТОВ")}</div>
                                        </>}
                                    {this.state.fewTasksCompletedMessage &&
                                        <>
                                            <div>{lang("ПОЖАЛУЙСТА,")}</div>
                                            <div>{lang("ВЫПОЛНИТЕ ВСЕ ЗАДАНИЯ")}</div>
                                        </>}
                                </div>
                            }
                        </div>
                    </TabPanel>
                )}
            </TabContext>
        );
    }

    onControlledDrag = (e, position) => {
        const { x, y } = position;
        this.setState({ controlledPosition: { x, y } });
        this.state.socket.send(JSON.stringify({
            command: 'onControlledDrag',
            data: { sketchId: 1, position: { x, y } },
            val: this.prepareInfo(),
        }));
    };

    tabHandleChange = (e, value) => {
        if (!['admin', 'teacher', 'supervisor'].includes(this.props.userInfo.userInfo.type)) return;

        const { tabs } = this.state;
        const lessonItems = tabs[value - 1];

        if (!lessonItems) value = 1;

        this.setState({ activeTabNumber: value });


        this.state.socket.send(JSON.stringify({
            command: 'dialogChangeTab',
            data: value,
            val: this.prepareInfo(),
        }));

        this.state.socket.send(JSON.stringify({
            command: 'setVirtualClassInfo',
            val: {
                ...this.prepareInfo(),
                vCParam: 'tabNum',
                vCValue: value,
            }
        }));
    };

    addTab = () => {
        this.setState(prevState => {
            const tabs = prevState.tabs;
            const tabCount = tabs.length + 1;
            const label = `Additional tab ${tabCount}`;

            tabs.push({
                tab: {label: label, value: tabCount},
                tabPanel: {value: tabCount, lessonItems: []}
            });

            return {
                selectItemsDialogVisible: true,
                activeTabNumber: tabs.length,
                tabs: tabs
            }
        });
    };

    deleteTab = () => {
        this.setState(prevState => {
            const { tabs, activeTabNumber } = prevState;

            if (tabs.length <= 1) { alert('You cannot delete last tab'); return; }

            const activeTabIndex = activeTabNumber - 1;
            const newTabs = [];
            let tabValue = 1;
            let newActiveTabNumber = 1;

            for (let i = 0; i < tabs.length; i++) {
                if (i !== activeTabIndex) {
                    tabs[i].tab.value = tabValue;
                    tabs[i].tabPanel.value = tabValue;
                    newTabs.push(tabs[i]);
                    tabValue++;
                }
            }

            if (activeTabNumber > 1) {
                newActiveTabNumber = activeTabNumber - 1;
            }

            return {
                selectItemsDialogVisible: true,
                activeTabNumber: newActiveTabNumber,
                tabs: newTabs
            }
        });
    }
    

    getRangeObject = (win) => {
        win = win || window;
        if (win.getSelection) {
            try {
                return win.getSelection();
            } catch (e) { }
        }
        return null;
    }

    selectedObjectValue = () => {
        const word = this.state.selectedWordToTranslate
        const spaceNumber = word.indexOf(' ');
        if ((word.length >= 2) && (spaceNumber === -1)) {
            this.props.selectedWordToTranslate(word);
            this.props.openDict();
        } else if (spaceNumber !== 0) {
            this.props.selectedWordToTranslate(word.substring(0, spaceNumber))
            this.props.openDict()
        }
    }

    selectedObjectCoordinates = () => {
        const scrollTop = this.props.lessonScrollTop
        const range = this.getRangeObject().toString();
        if (range.length >= 2) {
            const oRange = this.getRangeObject().getRangeAt(0);
            const oRect = oRange.getBoundingClientRect();

            this.setState({
                selectedObj: {
                    display: 'flex',
                    top: `${oRect.top - oRect.height  + scrollTop}`,
                    left: `${oRect.left - 370}`
                },
                selectedWordToTranslate: range,
            });
        } else {
            this.setState({
                selectedObj: {
                    display: 'none',
                    top: '',
                    left: '',
                },
            });
        }
    }

    startSelectedObj = () => setTimeout(this.selectedObjectCoordinates, 200);

    render() {
        const { lessonName, numberOfLesson, timeOfClass, keyWords, Grammar,
            lessonItems, selectItemsDialogVisible, sketch, tabs } = this.state;
        const selectedObjStyle = {
            display: this.state.selectedObj.display,
            top: this.state.selectedObj.top + 'px',
            left: this.state.selectedObj.left + 'px',
        }
        if (this.state.isRerend) return (<></>);

        if ((this.props.userInfo.userInfo.type !== 'teacher' && this.props.userInfo.userInfo.type !== 'admin'
            && this.props.userInfo.userInfo.type !== 'supervisor'
        ) && this.state.isUserHaveNotControl) {

            const appDivInputs = document.querySelectorAll('.App input');
            for (let i = 0; i < appDivInputs.length; i++) {
                appDivInputs[i].disabled = 'disabled';
            }
        }

        const renderLessonSummaryInput = (valName, label) => (
            <TextField label={label} fullWidth margin="normal" className="mt-0 mb-4"
                       InputLabelProps={{shrink: true}} required value={this.state[valName]}
                       onChange={t => this.setState({[valName]: t.target.value})}
            />
        );

        return (
            <DndProvider backend={myVarIsMobileDevice ? TouchBackend : HTML5Backend}>
                <div className={!this.props.isHomework ? "App AppShadow" : "HomeworkApp"}
                     onMouseUp={this.startSelectedObj}>
                    <div className='translate_word_search' style={selectedObjStyle}>
                        <div onClick={this.selectedObjectValue}>
                            <img className="right_panel_img" src={IconDictionary} alt="dictionary"/>
                        </div>
                    </div>
                    {this.isPresentation && this.props.whiteboard && this.props.whiteboard.show &&
                        <div className={'whiteBoardDiv no-cursor' + (this.props.whiteboard && this.props.whiteboard.showBg ? ' whiteBoardDivWithoutBg' : '')}>
                            <div onClick={e => e.stopPropagation()}>
                                <P5Wrapper sketch={sketch} />
                            </div>
                        </div>}
                    {this.isNotPresentation &&
                        <div className="col-12">
                            {renderLessonSummaryInput('lessonCourseId', 'Id курса')}
                            {renderLessonSummaryInput('lessonName', 'Lesson Name')}
                            {renderLessonSummaryInput('numberOfLesson', '№ of lesson')}
                            {renderLessonSummaryInput('timeOfClass', 'Time of class')}
                            {renderLessonSummaryInput('keyWords', 'Key words')}
                            {renderLessonSummaryInput('Grammar', 'Grammar')}
                        </div>}

                    {this.isNotPresentation &&
                        <div className={l.addtabs_button_panel}>
                            <Button color='primary' onClick={this.addTab}>Add tab</Button>
                            <Button color='primary' onClick={this.deleteTab}>Delete tab</Button>
                        </div>}

                    <div>
                        {this.isNotPresentation &&
                            <div className={l.selectitems_button}>
                                <Button color='primary'
                                        onClick={() => this.setState({ selectItemsDialogVisible: !selectItemsDialogVisible })}>
                                    Select items</Button>
                            </div>
                        }
                        {this.isNotPresentation && selectItemsDialogVisible &&
                            <div className={l.select_items_visible_block}>
                                <Button variant="outlined" color="primary"
                                        onClick={
                                            () => {
                                                const { tabs, activeTabNumber } = this.state;
                                                tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                    name: 'DAD1',
                                                    type: 'simple',
                                                    text: '',
                                                    resText: [],
                                                    resDustbin: [],
                                                    resBox: [],
                                                    isText: false,
                                                    uniqId: v1(),
                                                    customTitle: 'Make the sentence',
                                                });
                                                this.setState({ tabs });
                                            }
                                        }
                                >DAD1</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DAD2',
                                                text: '',
                                                resDustbin: [],
                                                resBox: [],
                                                isText: false,
                                                uniqId: v1(),
                                                customTitle: 'Make the word using the letters:\n',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >DAD2</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DAD2',
                                                type: 'special1',
                                                text: '',
                                                resDustbin: [],
                                                resBox: [],
                                                isText: false,
                                                uniqId: v1(),
                                                customTitle: 'Make the sentence:',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Sentence from words</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DAD1',
                                                type: 'special2',
                                                text: '',
                                                resText: [],
                                                resDustbin: [],
                                                resBox: [],
                                                isText: false,
                                                uniqId: v1(),
                                                customTitle: 'Make the sentence (type 2):',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Phrase to phrase</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={
                                            () => {
                                                const { tabs, activeTabNumber } = this.state;
                                                tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                    name: 'DAD1',
                                                    type: 'special3',
                                                    text: '',
                                                    resText: [],
                                                    resDustbin: [],
                                                    resBox: [],
                                                    isText: false,
                                                    uniqId: v1(),
                                                    customTitle: 'Sort the words',
                                                });
                                                this.setState({ tabs });
                                            }
                                        }
                                >Words and 2 columns</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Editor',
                                                editorState: EditorState.createEmpty(),
                                                currentVal: '',
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Editor</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'TeachersNote',
                                                editorState: EditorState.createEmpty(),
                                                currentVal: '',
                                                uniqId: v1(),
                                            });
                                            this.setState({tabs});
                                        }}
                                >Teacher's Note</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'WIG',
                                                editorState: EditorState.createEmpty(),
                                                isRenderCode: false,
                                                currentVal: '',
                                                customTitle: 'Complete the sentences',
                                                uniqId: v1(),
                                            });
                                            this.setState({tabs});
                                        }}
                                >Put word in gaps</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'WIT',
                                                editorState: EditorState.createEmpty(),
                                                isRenderCode: false,
                                                currentVal: '',
                                                customTitle: 'Read the text and complete the sentences',
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Select word in text</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'CWF',
                                                editorState: EditorState.createEmpty(),
                                                isRenderCode: false,
                                                currentVal: '',
                                                customTitle: 'Read the text and complete the sentences',
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Choose the correct word form</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'IMG',
                                                src: '',
                                                whiteboard: true,
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                                uniqId: v1(),
                                                isDrawing: false,
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Insert img</Button>
                                <Button
                                    color="primary" variant="outlined"
                                    onClick={
                                        () => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Slider',
                                                src: '',
                                                items: [],
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }
                                    }
                                >Insert Slider</Button>
                                <Button
                                    color="primary" variant="outlined"
                                    onClick={
                                        () => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Summer',
                                                text: '',
                                                showStudents: false,
                                                items: [],
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }
                                    }
                                >How did you spend your summer</Button>
                                <Button
                                    color="primary" variant="outlined"
                                    onClick={
                                        () => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'ImgGrid',
                                                gridData: { spacing: 3 },
                                                rows: [[{
                                                    src: this.state.imageStubLink,
                                                    uniqId: v1(),
                                                }]],
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                            });
                                            this.setState({tabs });
                                        }
                                    }
                                >Image grid</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={
                                            () => {
                                                const { tabs, activeTabNumber } = this.state;
                                                tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                    name: 'TrueLies',
                                                    items: [{
                                                        question: 'Test question',
                                                        isCorrect: false,
                                                        studentAnswer: ''
                                                    }],
                                                    uniqId: v1(),
                                                    customTitle: 'Answer the question',
                                                });
                                                this.setState({ tabs });
                                            }
                                        }
                                >True false slider</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'testQuestion',
                                                question: 'Test question',
                                                studentAnswer: [],
                                                isSubmit: false,
                                                variants: [
                                                    { isCorrect: true, value: 'Test variant' },
                                                    { isCorrect: false, value: 'Test variant'}
                                                ],
                                                uniqId: v1(),
                                                customTitle: 'Answer the question',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Test question</Button>
                                <Button
                                    color="primary" variant="outlined"
                                    onClick={
                                        () => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DnDGrid',
                                                gridData: { spacing: 3 },
                                                rows: [[{
                                                    src: this.state.imageStubLink,
                                                    uniqId: v1(),
                                                }]],
                                                uniqId: v1(),
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                            });
                                            this.setState({ tabs });
                                        }
                                    }
                                >DnD Img grid</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DnDGrid2',
                                                gridData: { spacing: 3 },
                                                rows: [[{
                                                    src: this.state.imageStubLink,
                                                    uniqId: v1(),
                                                }]],
                                                uniqId: v1(),
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                            });
                                            this.setState({ tabs });
                                        }}
                                >DnD Img grid 2</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'ButtonL',
                                                text: '',
                                                link: '',
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Button</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'HorizontalLine',
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Line</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Youtube',
                                                link: '',
                                                youtubePlayerRef: null,
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Video</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Audio',
                                                src: '',
                                                link: '',
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Audio</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'PDF',
                                                src: '',
                                                heightInVh: 50,
                                                widthInPercent: 50,
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Insert pdf</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'HieroglyphText',
                                                hieroglyphs: "",
                                                transktiption: "",
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Иероглифы</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'WritingHieroglyph',
                                                hieroglyphs: "",
                                                transktiption: "",
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Написание Иероглифы</Button>
                            </div>}
                    </div>
                    {this.renderTabs(this.state.tabs)}

                    {this.isNotPresentation &&
                        <div className={l.create_lesson_button}>
                            <Button color="primary"
                                    onClick={() => {
                                        const { lesson: lessonInfo } = this.props;
                                        const { lessonCourseId: courseId } = this.state;
                                        if (!courseId) return;

                                        let cloneTabs = tabs.map(tab =>{
                                            return {
                                                tab: {...tab.tab},
                                                tabPanel: {
                                                    value: tab.tabPanel.value,
                                                    lessonItems: tab.tabPanel.lessonItems.map(item=>{
                                                        let el = {...item};
                                                        if (el.editorState) {
                                                            el.editorState = {};
                                                        }
                                                        if (el.canvasImgContainerRef){
                                                            el.canvasImgContainerRef = {}
                                                        }
                                                        return el;
                                                    })
                                                }
                                            }
                                        });
                                        const tabsForSave = JSON.parse(JSON.stringify(cloneTabs));

                                        const apiMethod = lessonInfo ? 'dashboard/updateLesson' : 'dashboard/addLesson';

                                        callApi(apiMethod, 'post', {
                                            lessonId: lessonInfo && lessonInfo.id || 0,
                                            courseId,
                                            "name": lessonName,
                                            "page": { tabs: tabsForSave, lessonName, numberOfLesson, timeOfClass, keyWords, Grammar },
                                            "startDate": 1603792547, "endDate": 1603802547,
                                        }).then(res => {
                                            if (res && res.data.ok) {
                                                lessonInfo ? alert('Успешно обновлен') : alert('Успешно создан');
                                            } else { alert('Error_Editor_1'); }
                                        }).catch(e => {
                                            console.log('Error_Editor_2', e)
                                            alert('Error_Editor_2') ;
                                        });

                                        // let lessons = JSON.parse(localStorage.getItem('lessons')) || {};
                                        // lessons[lessonName] = { lessonName, lessonItems, tabsForSave };

                                        // if (lessonName) {
                                        //     localStorage.setItem('lessons', JSON.stringify(lessons))
                                        // }
                                    }}
                            >{this.props.lesson ? 'Изменить урок' : 'Создать урок'}</Button>
                        </div>
                    }
                </div>
            </DndProvider>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        dialogSetWhiteboard,
        dialogSetImgWhiteboard,
        selectedWordToTranslate,
        openDict
    },
    dispatch
);

const mapStateToProps = state => (
    {
        whiteboard: state.dialog.whiteboard,
        userInfo: state.auth.userInfo,
        lessonScrollTop: state.dictionary.lessonScrollTop,
        showImgWhiteboard: state.dialog.showImgWhiteboard,
        demoLesson: state.settings.demoLesson,
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonEditor));
