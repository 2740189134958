import React from 'react';
import { DropTarget } from 'react-dnd';

export const Dustbin = ({
    isHomework,
    isHomeworkFinish,
    index,
    dustbinName,
    connectDropTarget,
    lastDroppedItem,
    imgSrc,
    heightPriority,
    heightInVh,
    widthInPercent,
    dustbinsInRowCnt,
}) => {
    let border = 'none';
    let color = '#000';
    let background = 'none';
    let borderText = '#00b3ff';

    if (lastDroppedItem && lastDroppedItem.name !== dustbinName) {
        border = '2px solid #f00';
    } else if (lastDroppedItem && lastDroppedItem.name === dustbinName) {
        border = 'none';
        color = '#fff';
        border = '2px solid #00b3ff';
        background = '#00b3ff';
        borderText = '#fff'
    }

    if (isHomework && !isHomeworkFinish) {
        border = 'none';
        background =  '#fff';
        color = '#000';
        borderText = '#000';
    }

    return connectDropTarget(
        <div style={{width: 'fitContent', display: 'flex', justifyContent:
                ((dustbinsInRowCnt > 2  || dustbinsInRowCnt === 1) ?
            'center' : (index % 2 === 0 ? 'right' : 'left'))}}
             className="DnDItemContainer"
        >
            <div className="DnDItem"
                 style={ heightPriority ?
                         {border, color, height: heightInVh + 'vh'} :
                         {border, color, width: widthInPercent + '%'}}
            >
                {lastDroppedItem && (
                    <div className="DnDItemTextBackground">
                        <div className="DnDText"  style={{
                            background, border: `1px solid ${borderText}`
                        }}>
                            {lastDroppedItem.name}
                        </div>
                    </div>
                )}
                <img src={imgSrc} className="DndItemImg"/>
            </div>
        </div>,
    )
}
export default DropTarget(
    (props) => props.accepts,
    {
        drop(props, monitor) {
            props.onDrop(monitor.getItem())
        },
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
)(Dustbin)
