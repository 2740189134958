import React from 'react';
import {injectIntl} from 'react-intl';
import TextField from '@material-ui/core/TextField';
import ContainerHeader from '../../ContainerHeader';
import {isFQDN} from 'validator';
import IntlMessages from '../../../utils/IntlMessages';
import callApi, {cancelToken, CancelAjaxRequest} from '../../../utils/call-api-new';
import history from '../../../utils/history';
import FormControlButtons from '../../FormControlButtons';
import FormAlert from '../../FormAlert';
import FormDialog from '../../FormDialog';
import Fz from '../../Fz';

class Add extends Fz {
    constructor(props) {
        super(props);
        this.state = {
            cancelAjaxRequest: {cancel: () => null},
            domain: {
                value: '',
                isValid: true
            },
            comment: {
                value: '',
                isValid: true
            },
            dialogVisible: false,
            alertVisible: false,
            alertTitle: false,
            alertMessage: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    validate() {
        const {domain} = this.state;
        let isValid = true;
        const validateFields = {};

        const validate = isFQDN(domain.value);
        isValid = !validate ? false : isValid;
        validateFields.domain = {...domain, isValid: !!validate};

        this.setState(validateFields);
        return isValid;
    }

    handleInputChange(event) {
        event.persist();
        const {name, value} = event.target;
        this.setState(prevState => ({
            [name]: {
                ...prevState[name],
                value
            }
        }));
    }

    componentWillUnmount() {
        this.state.cancelAjaxRequest.cancel(new CancelAjaxRequest(this.state.baseUrl));
    }

    handleSubmit(isApply) {
        if (!this.validate()) {
            return true;
        }
        const source = cancelToken().source();
        this.setState({
            cancelAjaxRequest: source
        });

        return callApi('brand/landing-bidder', 'post', {
            domain: this.state.domain.value,
            comment: this.state.comment.value
        }, source).then(() => {
            console.log('res');
            if (!isApply) {
                return history.goBack();
            }
            return this.setState({
                alertVisible: false,
                dialogVisible: true
            });
        }).catch(err => this.ErrorHundler(err));
    }

    closeDialog() {
        this.setState({
            dialogVisible: false,
            domain: {
                value: '',
                isValid: true
            },
            comment: {
                value: '',
                isValid: true
            }
        });
    }

    render() {
        const {domain, comment} = this.state;

        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <ContainerHeader match={this.props.match}
                    title={<span><IntlMessages id="sidebar.landingBidders"/> - <IntlMessages
                        id="audit.addDomain"/></span>}/>

                <div className="row">
                    <div className="col-xl-12 col-12">
                        <div className="jr-card">
                            <div className="jr-card-body">
                                <form onSubmit={e => e.preventDefault()}>
                                    {this.state.alertVisible && (
                                        <FormAlert alertMessage={this.state.alertMessage}/>
                                    )}

                                    <div className="row max-width-600">
                                        <div className="col-12">
                                            <TextField
                                                InputLabelProps={{shrink: true}}
                                                required
                                                name="domain"
                                                value={domain.value}
                                                onChange={this.handleInputChange}
                                                label={<IntlMessages id="audit.domain"/>}
                                                fullWidth
                                                margin="normal"
                                                className="mt-0 mb-4"
                                                error={!domain.isValid}
                                                helperText={!domain.isValid ? <IntlMessages id="validation.domain"/> : ''}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextField
                                                multiline
                                                rows={3}
                                                rowsMax={10}
                                                InputLabelProps={{shrink: true}}
                                                name="comment"
                                                value={comment.value}
                                                onChange={this.handleInputChange}
                                                label={<IntlMessages id="audit.comment"/>}
                                                fullWidth
                                                margin="normal"
                                                className="mt-0 mb-4"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <FormControlButtons handleSubmit={this.handleSubmit}
                                                applyTextId="button.createAndContinue"
                                                saveTextId="button.create"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <FormDialog
                    closeDialog={this.closeDialog}
                    dialogVisible={this.state.dialogVisible}
                    titleId="audit.addDomain"
                    contentId="audit.domainAddedSuccessfully"
                />
            </div>
        );
    }
}

export default injectIntl(Add);
