export const arrayForRandomSimulation = [
    [37, 19, 31, 1, 24, 41, 3, 32, 7, 18, 21, 47, 0, 16, 42, 14, 8, 48, 30, 45, 22, 44, 28, 40, 36, 33, 27, 25, 4, 38, 5, 9, 35, 29, 11, 17, 39, 15, 26, 13, 46, 20, 10, 2, 12, 6, 49, 34, 23, 43],
    [37, 13, 8, 26, 3, 1, 29, 28, 36, 38, 43, 2, 18, 27, 40, 42, 32, 31, 33, 16, 4, 48, 45, 10, 34, 9, 0, 6, 25, 19, 41, 20, 21, 17, 15, 23, 47, 49, 5, 44, 12, 46, 11, 35, 39, 24, 30, 7, 22, 14],
    [23, 3, 7, 47, 15, 45, 0, 5, 33, 21, 11, 18, 43, 17, 24, 30, 9, 27, 13, 29, 26, 8, 12, 37, 49, 4, 28, 19, 40, 14, 1, 6, 25, 10, 44, 38, 48, 22, 31, 2, 35, 42, 36, 16, 41, 32, 39, 34, 20, 46],
    [1, 44, 21, 17, 45, 39, 20, 2, 32, 9, 24, 14, 49, 0, 8, 29, 13, 30, 16, 12, 25, 41, 38, 22, 48, 33, 18, 4, 6, 31, 40, 10, 23, 35, 28, 19, 3, 46, 47, 15, 7, 42, 5, 34, 27, 43, 37, 36, 26, 11],
    [13, 39, 23, 10, 37, 8, 2, 7, 32, 5, 35, 3, 48, 34, 6, 14, 30, 24, 16, 33, 9, 46, 11, 28, 17, 21, 1, 40, 19, 42, 36, 29, 20, 41, 15, 26, 4, 38, 25, 43, 27, 44, 31, 47, 45, 18, 22, 0, 49, 12],
    [11, 39, 6, 17, 12, 5, 18, 9, 46, 29, 48, 19, 33, 1, 30, 25, 47, 28, 40, 13, 38, 32, 35, 20, 34, 37, 0, 49, 31, 45, 36, 16, 3, 2, 42, 21, 14, 44, 41, 23, 22, 24, 7, 10, 15, 4, 26, 43, 8, 27],
    [21, 1, 15, 38, 36, 0, 45, 25, 32, 10, 31, 37, 3, 6, 40, 13, 19, 47, 4, 46, 41, 20, 34, 39, 29, 33, 14, 48, 5, 27, 2, 23, 22, 12, 30, 43, 42, 7, 11, 49, 17, 24, 28, 16, 26, 35, 18, 8, 9, 44],
    [43, 10, 20, 16, 37, 24, 1, 0, 46, 3, 5, 42, 48, 12, 34, 30, 17, 40, 29, 39, 21, 8, 19, 36, 25, 33, 35, 13, 26, 49, 2, 23, 11, 31, 28, 41, 38, 6, 32, 9, 22, 44, 4, 47, 15, 14, 45, 27, 7, 18],
    [9, 14, 6, 34, 47, 28, 12, 17, 35, 32, 26, 33, 48, 27, 23, 16, 21, 13, 1, 4, 29, 41, 39, 24, 22, 36, 37, 7, 40, 5, 25, 43, 44, 0, 2, 30, 18, 11, 15, 20, 42, 38, 45, 46, 19, 3, 49, 10, 31, 8],
    [29, 45, 47, 11, 36, 37, 16, 5, 14, 0, 42, 23, 31, 33, 38, 3, 35, 32, 13, 17, 21, 39, 25, 34, 7, 1, 10, 46, 26, 49, 27, 43, 44, 19, 8, 20, 28, 2, 30, 18, 24, 12, 9, 40, 4, 41, 22, 6, 15, 48],
    [30, 36, 26, 1, 16, 19, 48, 37, 41, 23, 42, 2, 7, 15, 24, 45, 6, 20, 46, 0, 49, 31, 39, 43, 9, 28, 33, 40, 22, 21, 13, 47, 14, 18, 3, 34, 32, 5, 29, 27, 44, 11, 35, 38, 10, 12, 17, 4, 8, 25],
    [4, 2, 48, 42, 27, 3, 8, 7, 19, 35, 6, 23, 13, 11, 16, 40, 15, 31, 45, 34, 10, 29, 17, 30, 5, 41, 46, 28, 25, 26, 1, 18, 43, 33, 38, 0, 9, 24, 49, 47, 22, 44, 36, 39, 14, 12, 32, 37, 21, 20],
    [16, 12, 7, 24, 5, 6, 29, 28, 26, 22, 30, 44, 45, 15, 42, 0, 14, 19, 47, 48, 2, 20, 39, 13, 40, 11, 38, 46, 3, 8, 4, 32, 43, 27, 25, 36, 35, 31, 34, 23, 17, 18, 33, 41, 9, 21, 37, 1, 10, 49],
    [16, 39, 36, 7, 21, 40, 37, 5, 11, 3, 23, 17, 30, 31, 45, 13, 24, 29, 20, 32, 4, 25, 26, 18, 6, 10, 35, 0, 42, 33, 44, 2, 41, 38, 43, 19, 48, 27, 14, 28, 34, 15, 12, 1, 9, 22, 49, 46, 8, 47],
];

export const getArrayForRandomSimulation = (arrayIndex = (new Date).getDay()) => {
    if (arrayIndex < arrayForRandomSimulation.length) {
        return arrayForRandomSimulation[arrayIndex];
    }

    return arrayForRandomSimulation[0];
}

export const switchArrayElements = (originalArr, arrayPosition = 0, getRandomSimulationFunc = getArrayForRandomSimulation) => {
    const arrayForRandomSimulation = getRandomSimulationFunc((new Date).getDay() + arrayPosition);
    const originalArrLength = originalArr.length;
    const newArr = [...Array(originalArrLength).keys()];

    let originalArrCurrentIndex = 0;

    arrayForRandomSimulation.forEach(element => {
        if (element < originalArrLength) {
            newArr[element] = originalArr[originalArrCurrentIndex];
            originalArrCurrentIndex++;
        }
    });

    return newArr;
}