import React from 'react';
import Radio from "@material-ui/core/Radio/Radio";
import Input from "@material-ui/core/Input";

class ImgsSizeHelper extends React.Component {
    render() {
        const { parentThis, activeExerciseIndex, exerciseItem, activeTabIndex } = this.props;
        const { tabs } = parentThis.state;
        const {heightPriority, widthPriority, heightInVh, widthInPercent} = exerciseItem;
        
        return(
            <>
                Height in VH<Radio name={`img${activeTabIndex}${activeExerciseIndex}`}
                     checked={!!heightPriority}
                     onChange={() => {
                         const { tabs } = parentThis.state;
                         const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                         lessonItems[activeExerciseIndex].heightPriority = !heightPriority;
                         lessonItems[activeExerciseIndex].widthPriority = !widthPriority;
                         parentThis.setState({ tabs });
                     }}
            />
                <Input type="number"
                   value={heightInVh}
                   onChange={e => {
                       const { tabs } = parentThis.state;
                       const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                       if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                           lessonItems[activeExerciseIndex].heightInVh = e.target.value;
                           parentThis.setState({ tabs });
                       }
                   }}
                />
                Width in %<Radio name={`img${activeTabIndex}${activeExerciseIndex}`}
                     checked={!!widthPriority}
                     onChange={() => {
                         const { tabs } = parentThis.state;
                         const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                         lessonItems[activeExerciseIndex].heightPriority = !heightPriority;
                         lessonItems[activeExerciseIndex].widthPriority = !widthPriority;
                         parentThis.setState({ tabs });
                     }}
                />
                <Input type="number"
                   value={widthInPercent}
                   onChange={e => {
                       const { tabs } = parentThis.state;
                       const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                       if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                           lessonItems[activeExerciseIndex].widthInPercent = e.target.value;
                           parentThis.setState({ tabs });
                       }
                   }}
                />
            </>
        );
    }
}

export default ImgsSizeHelper;
