import React, { useEffect, useState } from "react";

import Button from '@material-ui/core/Button/Button';
import Input from '@material-ui/core/Input';
import l from '../main/LessonEditor.module.scss';


const PDF = ({el, i, activeTabIndex, options, socketMessage}) => {
    
    const [isPresentation, setIsPresentation] = useState(options.isPresentation);
    const [selectedFile, setSelectedFile] = useState(el.selectedFile);
    const [src, setSrc] = useState(el.src);
    const [heightInVh, setHeightInVh] = useState(el.heightInVh);
    const [widthInPercent, setWidthInPercent] = useState(el.widthInPercent);

    useEffect(() => {
        options.changeElement({selectedFile, src, heightInVh, widthInPercent}, i, activeTabIndex);
    }, [selectedFile, src, heightInVh, widthInPercent]);
    
    
    if (!el) return (<></>);

    return (
        <div key={`tab_${activeTabIndex}_item_${i}`}>
            {!isPresentation && 
                <>        
                {'Height in VH: '}
                <Input type="number"
                    value={heightInVh}
                    onChange={e => {
                        if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                            setHeightInVh(e.target.value);
                        }
                    }}
                />
                {'Width in %: '}
                <Input type="number"
                    value={widthInPercent}
                    onChange={e => {
                        if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                            setWidthInPercent(e.target.value);
                        }
                    }}
                />
            </>
            }
            {!isPresentation && !src &&
                <div>
                    <div className={l.WIT_button}>
                        <Input type="file" name="file"
                               onChange={e => setSelectedFile(e.target.files[0])}
                        />
                    </div>
                    <div className={l.WIT_button}>
                        <Button onClick={() => {
                            
                            const formData = new FormData();

                            formData.append('name', 'pdf.pdf');
                            formData.append('fileType', 'pdf');
                            formData.append('file', selectedFile);

                            callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                if (res && res.data && res.data.length && res.data.fileName) {
                                    setSrc(res.data.fileName.substr(config.filePathSubstLength));
                                }
                            }).catch(err => {
                                console.log(err)
                            });
                        }}>Upload</Button>
                    </div>
                </div>}
            {src &&
                <div
                    className="canvasImgContainer"
                    style={{ height: heightInVh + 'vh', width: widthInPercent + '%' }}
                >
                    <iframe src={src} style={{ height: '100%', width: '100%' }}></iframe>
                </div>
            }
            <br />
        </div>
    );
};

export default PDF;