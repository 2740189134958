import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import s from "../SuperAdmin.module.css";
import callApi from '../../../../utils/call-api';
import CoursList from './CoursList';
import LessonList from './LessonList';
import LessonEditor from '../../../lessonEditor/main/LessonEditor';

const SHOW_MODE = ["COURS_LIST", "LESSON_LIST", "LESSON_EDIT"];

const Courses = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [languagesData, setLanguagesData] = useState([]);
    const [isHomework, setIsHomework] = useState(props.isHomework||false);
    const [showMode, setShowMode] = useState(SHOW_MODE[0]);

    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(null);
    
    useEffect(() => {
        setIsHomework(props.isHomework);
    }
    ,[props.isHomework]);

    useEffect(() => {
        callApi("guide/languages","GET")
        .then(data=>{
            if(data.data.languages.length) {
                setTabIndex(data.data.languages[0].id);
            }
            setLanguagesData(data.data.languages||[]);
        }).catch(err=>{
            console.log(err);
            setLanguagesData([]);
            setTabIndex(0);
        });
    }, []);

    const onSelectedCourse = (course) => {
        setSelectedCourse(course);
        setShowMode(SHOW_MODE[1]);
    };

    const onSelectedLesson = (lesson) => {
        setSelectedLesson(lesson);
        setShowMode(SHOW_MODE[2]);
    };

   
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    
    return (
        <div className={s.content_admin}>
            {showMode===SHOW_MODE[0] && (                
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={tabIndex}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    {languagesData.map((item, i) => <Tab label={item.name} value={item.id} />)}
                                </TabList>
                            </Box>
                            {languagesData.map((item, i) => <TabPanel value={item.id}>
                                <CoursList lang={item} homework={isHomework} onSelectedCourse={onSelectedCourse}/>
                            </TabPanel>)}                                           
                        </TabContext>
                    </Box>                   
            )}
            {(showMode === SHOW_MODE[2] || showMode === SHOW_MODE[1]) && (              
                <div>
                    <div className={s.levels_bar}>
                        <div onClick={() => setShowMode(SHOW_MODE[0])}
                            className={s.back}
                        >
                            <div className={s.back_img} />
                            Back
                        </div>
                        <div className={s.levels_title}>
                            {'' + capitalizeFirstLetter(selectedCourse.name) + "->"}
                            {capitalizeFirstLetter(selectedCourse.type)}
                        </div>
                        <div className={s.create_new_course_button_header}
                            onClick={() => {onSelectedLesson(null)}}
                        >
                            Create new lesson
                        </div>
                    </div>
                    {showMode === SHOW_MODE[1] && (
                        <LessonList 
                            selectedCourse={selectedCourse} 
                            homework={isHomework}
                            onSelectedLesson = {onSelectedLesson} 
                        />
                   )}
                    {showMode === SHOW_MODE[2] && 
                        <LessonEditor
                            courseId={selectedCourse.id}
                            lesson={selectedLesson}
                        />
                    }
                </div>
            )}
        </div>
    );
}  

export default Courses;