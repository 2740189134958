import React, { useEffect, useState } from 'react';

import { Editor, EditorState, ContentState } from 'draft-js';
import Button from '@material-ui/core/Button/Button';

import l from '../main/LessonEditor.module.scss';
import ReactCodeInput from '../2fa/TwoFa';
import { studentAnswerWrongAudio, studentAnswerSuccessSounds } from '../helpers/SoundsHelper';
import TeacherHelpInfo from "./common/TeacherHelpInfo";
import random from '../../../utils/generator/numbers';


const Wig =({el, i, activeTabIndex, options, socketMessage}) => {
    const [name, setName] = useState(el.name);
    const [textArr, setTextArr] = useState(el.textArr);
    const [wordsArr, setWordsArr] = useState(el.wordsArr);
    const [isRenderCode, setIsRenderCode] = useState(el.isRenderCode);
    const [clue, setClue] = useState(el.clue);
    const [customTitle, setCustomTitle] = useState(el.customTitle);
    const [editorState, setEditorState] = useState(el.editorState);
    const [currentVal, setCurrentValue] = useState(el.currentVal);
    const [uniqId, setUniqId] = useState(el.uniqId);
    
    const [isHomework, setIsHomework] = useState(options.isHomework); 
    const [isPresentation, setIsPresentation] = useState(options.isPresentation);
    const [isHomeworkFinish, setIsHomeworkFinish] = useState(options.isHomeworkFinish);
    const [homeworkTasksInformation, setHomeworkTasksInformation] = useState(options.homeworkTasksInformation);
    const [fewTasksCompletedMessage, setFewTasksCompletedMessage] = useState(options.fewTasksCompletedMessage);

    useEffect(() => {
        options.changeElement({editorState, currentVal, isRenderCode, textArr, wordsArr, clue, customTitle}, i, activeTabIndex);
    }, [textArr, wordsArr, isRenderCode, clue, customTitle, editorState, currentVal]);

    useEffect(() => {
        if (socketMessage) {
            if (socketMessage.command === 'handleWig'&& socketMessage.data.uniqId === uniqId) {
                if (socketMessage.data.wigVal) {
                    wordsArr[socketMessage.data.wordsArrIndex].wigVal =socketMessage.data.wigVal || [];
                    wordsArr[socketMessage.data.wordsArrIndex].className = '';
                    setWordsArr([...wordsArr]);
                } else  if (socketMessage.data.className) {
                    wordsArr[socketMessage.data.wordsArrIndex].className =socketMessage.data.className || '';
                    setWordsArr([...wordsArr]);
                    if (socketMessage.data.className === ' WIG-successful') {
                        const variant = random(studentAnswerSuccessSounds.length);
                        studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                    } else {
                        studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                    }
                }
                
            }
        }
    }, [socketMessage]);

    const updateHomeworkTasksInformation = (uniqId, numberOfResponses = 0) => {
        try {
            if (numberOfResponses ) {
                homeworkTasksInformation[uniqId] = {
                    numberOfResponses,
                    currentResponsesCnt: 0,
                };
            } else {
                if (homeworkTasksInformation[uniqId] && (homeworkTasksInformation[uniqId].currentResponsesCnt < homeworkTasksInformation[uniqId].numberOfResponses)) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt++;
                }
            }
        } catch (e) {}

        setHomeworkTasksInformation(homeworkTasksInformation);
        setFewTasksCompletedMessage(false);
    };

    const onChangeEditor = (editorState) => {
        setEditorState(editorState);
        setCurrentValue(editorState.getCurrentContent().getPlainText('\u0001'));
        options.sendMessage({
            command: 'handleChangeEditor',
            data: {
                uniqId,
                i,
                currentVal: currentVal,
            }
        });
    };

    const okHandlerWIG = () => {
        let text = editorState.getCurrentContent().getPlainText('\u0001').split('[');
        let textArr = [], wordsArr = [];
        let clue = [];
        let answerVar = '';

        if (name === 'WIG') {
            answerVar = 'text';
        } else if (name === 'WIT' || name === 'CWF') {
            answerVar = 'rightWord';
        }

        text.forEach((item, i) => {
            if (item.includes(']')) {
                item = item.split(']');
                textArr.push(item[1]);

                const infinitiveStart = item[0].indexOf('('), infinitiveEnd = item[0].indexOf(')');

                let infinitive = '';
                if (infinitiveStart !== -1 && infinitiveEnd !== -1) {
                    infinitive = item[0].substring(infinitiveStart + 1, infinitiveEnd) + ';';
                    item[0] = item[0].substring(infinitiveEnd + 2, item[0].length).trim();
                }

                let rightWord = '';
                item[0].split(';').forEach(elem => {
                    if (elem[0] === '{' && elem[elem.length - 1] === '}') {
                        rightWord = elem.replace('}', '').replace('{', '');
                    }
                });

                item[0] = item[0].replace('}', '').replace('{', '');
                wordsArr.push({
                    text: infinitive.trim() + item[0],
                    className: '',
                    val: infinitive.replace(';', ''),
                    rightWord
                });
            } else {
                if (item.includes('<')) {
                    item = item.split('<');
                    textArr.push(item[0]);
                } else {
                    textArr.push(item);
                }
            }
        });

        for (let i = 0; i < wordsArr.length; i++) {
            clue[i] = `${i + 1} -> ${wordsArr[i][answerVar]}`;
        }

        setIsRenderCode(true);
        setTextArr(textArr);
        setWordsArr(wordsArr);
        //setClassName('');
        setClue(clue);
    };

    const renderExerciseTitle = () => (
        <>
            {isPresentation ? customTitle :
                <input className="ExerciseContextInput"
                       value={customTitle}
                       onChange={e => {
                            setCustomTitle(e.target.value);
                       }}
                />
            }
        </>
    );

    const prepareLineBreakText = text => {
        const elems = text.split('\u0001');

        if (elems.length <= 1) {
            return text;
        }

        let res = '';

        for (let i = 0; i < elems.length; i++) {
            if (i < elems.length - 1) {
                res = res + elems[i] + '<br />';
            } else {
                res = res + elems[i];
            }
        }

        return (
            <span dangerouslySetInnerHTML={{ __html: res }} />
        );
    };    

    if (!homeworkTasksInformation[uniqId] && Array.isArray(wordsArr)) {
        updateHomeworkTasksInformation(uniqId, wordsArr.length);
    };

    return (
        <div key={`tab_${activeTabIndex}_item_${i}`}>
            {!isPresentation &&
                <div className={l.WIT_formlesson}>
                    <Editor
                        editorState={editorState}
                        onChange={onChangeEditor}
                        placeholder="I [live] in London"
                    />
                    <div className={l.WIG_button}>
                        <Button onClick={event => {okHandlerWIG();}} color="primary">Ok</Button>
                    </div>
                </div>}
            {isRenderCode &&
                <div className={l.WIT_example}>
                    <div className={l.wit_exercise_title}>
                        {renderExerciseTitle()}
                    </div>
                    {(options.userInfo.userInfo.type === 'teacher' && clue && options.demoLesson) ?
                        <TeacherHelpInfo info={clue.map(elem => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
                    <div className={l.wit_body}>
                        {textArr.map((elem, index) => {
                            return (
                                <React.Fragment key={elem}>
                                    {prepareLineBreakText(elem)}
                                    {wordsArr[index] &&
                                        <div style={{display: 'inline-block'}}>
                                            <ReactCodeInput
                                                // Uncaught TypeError: Cannot read properties of undefined (reading 'wordsArr')
                                                specialClassName={
                                                    ((!isHomework || isHomeworkFinish) && wordsArr) ? wordsArr[index].className : ''}
                                                type="text" className=""
                                                fields={wordsArr[index].text.length}
                                                values={wordsArr[index].wigVal || []}
                                                onComplete={result => {
                                                    const wigClassname = (result === wordsArr[index].text) ? ' WIG-successful' : ' WIG-error';
                                                    // className = wigClassname;

                                                    if (wigClassname === ' WIG-successful') {
                                                        const variant = random(studentAnswerSuccessSounds.length);
                                                        studentAnswerSuccessSounds[variant].play();
                                                        studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                                    } else {
                                                        options.updateHomeworkErrors(el.uuid, 1);
                                                        studentAnswerWrongAudio.play();
                                                        studentAnswerWrongAudio.volume = global.soundsVolume;
                                                    }

                                                    const message = {
                                                        command: 'handleWig',
                                                        data: {uniqId, i, wordsArrIndex: index, className: wigClassname}
                                                    };                                                
                                                    options.sendMessage(message);
                                                }}
                                                onChange={wigArr => {
                                                    
                                                    const trueAnswerTextLength = wordsArr[index].text.length;
                                                    const previousAnswerLength = wordsArr[index].wigVal ?
                                                        wordsArr[index].wigVal.join('').length : 0;
                                                    const currentAnswerLength = wigArr.join('').length;

                                                    if (previousAnswerLength < trueAnswerTextLength && currentAnswerLength === trueAnswerTextLength) {
                                                        options.updateHomeworkWigInformation(uniqId, 1);
                                                    } else if (previousAnswerLength === trueAnswerTextLength && currentAnswerLength < trueAnswerTextLength){
                                                        options.updateHomeworkWigInformation(uniqId, -1);
                                                    }

                                                    if (wordsArr[index].className.trim() === 'WIG-error') {
                                                        options.updateHomeworkErrors(uniqId, -1);
                                                    }

                                                    wordsArr[index].wigVal = wigArr || [];
                                                    wordsArr[index].className = '';

                                                    setWordsArr([...wordsArr]);
                                                    const message = {
                                                        command: 'handleWig',
                                                        data: {uniqId,i, wigVal: wigArr, wordsArrIndex: index},
                                                    };
                                                    options.sendMessage(message);
                                                }}
                                            />
                                        </div>
                                    }
                                </React.Fragment>
                            );
                        })} </div></div>}
        </div>
    );
}

export default Wig;