import React from 'react';
import Button from '@material-ui/core/Button/Button';
// import history from "../utils/history";
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import IntlMessages from '../utils/IntlMessages';

const FormDialog = ({closeDialog, dialogVisible, titleId, contentId}) => (
    <Dialog
        open={dialogVisible}
        onClose={closeDialog}
    >
        <DialogTitle>
            <IntlMessages id={titleId}/>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <IntlMessages id={contentId}/>
            </DialogContentText>

        </DialogContent>
        <DialogActions>
            <Button onClick={closeDialog} color="primary">
                Ok
            </Button>
        </DialogActions>
    </Dialog>
);

export default FormDialog;
