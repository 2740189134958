import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import callApi from "../../../../utils/call-api";
import Button from '@material-ui/core/Button/Button';
import './BuyClasses.scss';
import InfoDialog from '../InfoDialog';
import lang from '../../../../utils/lang';

class BuyClassesItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenGroupBuyDialog: false,
            phoneNumber: this.props.userInfo.userInfo.phoneNumber,
            isShowOkModalDialog: false,
        }
    }

    render() {
        const { BuyClassesItemInfo, isActualAge, actualGroupLeveName } = this.props;

        const { itemType, itemId, itemName,
            HeaderBig, HeaderSmall, TopRightText,
            CenterHeader, CenterInfo, CenterComment, CenterPlusHeader, CenterPlusInfo, CenterFooter1, CenterFooter2,
            FooterTopText, FooterPrice, FooterButton, FooterBottomRed
        } = BuyClassesItemInfo;
        const { phoneNumber, isShowOkModalDialog } = this.state;

        return (
            <div className="BuyClassesItem BuyClassesItemScale">
                {isShowOkModalDialog && <InfoDialog
                    HideDialog={() => this.setState({ isShowOkModalDialog: false })}
                    title="Запись на групповые занятия"
                    text="Вы успешно подали заявку. Наш менеджер свяжется с вами в кратчайшие сроки"
                />}
                {TopRightText && <div className="TopRightText">{TopRightText}</div>}
                <div className="HeaderBig">{HeaderBig}</div>
                <div className="HeaderSmall">{HeaderSmall}</div>
                <div className="CenterBlock">
                    <div className="CenterHeader">{CenterHeader}</div>
                    <div className="CenterInfo" dangerouslySetInnerHTML={{__html: CenterInfo}}/>
                    <div className="CenterComment">{CenterComment}</div>
                    {CenterPlusHeader &&
                        <>+
                            <div className="CenterPlusHeader">{CenterPlusHeader}</div>
                        </>}
                    {CenterPlusInfo && <div className="CenterPlusInfo">{CenterPlusInfo}</div>}
                </div>
                <div className="CenterFooter">{CenterFooter1}</div>
                <div className="CenterFooter" dangerouslySetInnerHTML={{__html: CenterFooter2}}/>
                <div className="FooterBlock">
                    <div className="FooterTopText">{FooterTopText}</div>
                    <div className="FooterPrice">{FooterPrice}</div>
                    <div className={"FooterButton" +
                        (isActualAge ? ' BuyClassesItemScale' : ' FooterButtonDisabled')}
                         onClick={() => {
                             if (!isActualAge) return;

                             if (itemType === 'group') {
                                 this.setState({ isOpenGroupBuyDialog: !this.state.isOpenGroupBuyDialog });
                             } else {
                                 callApi('dashboard/getSubscriptionPaymentData  ', 'post',
                                     { subscriptionId: itemId }).then(res => {
                                     const result = res?.data;
                                     if (result?.ok && result.paymentLink) {
                                         window.open(result.paymentLink, '_blank');
                                     }
                                 }).catch(err => console.log(err));
                             }
                         }}
                    >
                        {FooterButton}
                        <div style={{display: 'none'}}
                            className={isActualAge? '' : 'isActualAgeInfo'}>
                            <div>Не соответсвует вашему уровню.</div>
                            <div>Ваш уровень:</div>
                            {!!actualGroupLeveName &&
                                <div className={"BuyClassesLev1Icon " + actualGroupLeveName}/>
                            }
                            {!actualGroupLeveName &&
                                <div className="BuyClassesNotAge">
                                    НЕОБХОДИМО УКАЗАТЬ ВОЗРАСТ В НАСТРОЙКАХ ПОЛЬЗОВАТЕЛЯ
                                </div>
                            }
                            <div>Вы можете приобрести пакеты занятий специально подобранные для вашего уровня.</div>
                            <div>Для этого перейдите в соответствующую вкладку в верхнем меню этой страницы</div>
                        </div>
                    </div>
                    {!!FooterBottomRed && <div className="FooterBottomRed">{FooterBottomRed}</div>}
                    {this.state.isOpenGroupBuyDialog &&
                        <div className="croupItem">
                            <div>Для записи на групповые занятия необходимо подать заявку.</div>
                            <div>Наш менеджер свяжется с вами в кратчайшие сроки, чтобы обсудить график занятий.</div>
                            <div>Счёт на оплату групповых занятий выставляется после предварительного формирования группы и графика занятий.</div>
                            <input type="text" className="modal_input" placeholder={lang("Номер телефона")}
                                value={phoneNumber}
                                onChange={e => this.setState({phoneNumber : e.target.value })}
                            />
                            <Button variant='contained' color='primary'
                                className="BuyClassesItemScale"
                                    style={{pointerEvents: ((!phoneNumber || phoneNumber.length < 7) ? 'none' : 'auto')}}
                                onClick={() => {
                                    const { name, surname, age } = this.props.userInfo.userInfo;

                                    callApi('student/createApplicationGroupLesson  ', 'post',
                                        {
                                            phoneNumber,
                                            name,
                                            surname,
                                            age,
                                            itemName,
                                            actionType: 'ЗАПИСЬ НА ГРУППОВЫЕ ЗАНЯТИЯ'
                                        }).then(res => {
                                        const result = res?.data;
                                        if (result?.ok) {
                                            this.setState({ isShowOkModalDialog: true });
                                        }
                                    }).catch(err => console.log(err));
                                }}
                            >
                                Отправить заявку
                            </Button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { },
    dispatch
);

const mapStateToProps = state => (
    {
        userInfo: state.auth.userInfo,
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(BuyClassesItem);
