/* global document  */
import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IntlMessages from '../utils/IntlMessages';
import CustomScrollbars from '../utils/CustomScrollbars';

import Config from '../config';

class SideNavContent extends React.Component {
    componentDidUpdate() {
        this.navControl();
    }

    componentDidMount() {
        this.navControl();
    }

    navControl() {
        const {history} = this.props;
        const that = this;
        let pathname = `${history.location.pathname}`;// get current path
        const pathnameArr = pathname.split('/');
        pathname = `/${pathnameArr[1]}/${pathnameArr[2] ? pathnameArr[2] : ''}`;

        const subMenuLi = document.querySelectorAll('.sub-menu > li');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event) {
                // event.stopPropagation();
            };
        }

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (e) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open');
                    }
                }
                this.classList.toggle('open');
                // event.stopPropagation();
            };
        }

        const activeLi = document.querySelector(`.menu a[href="${pathname}"]`);// select current a element

        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(fn => {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        return (
            <CustomScrollbars className="scrollbar">
                <ul className="nav-menu">

                    <li className="menu collapse-box">
                        <Button href="javascript:void(0)">

                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />

                            <span className="nav-text">
                                School
                            </span>
                        </Button>
                        <ul className="sub-menu">
                            {/*<li className="menu no-arrow">*/}
                            {/*    <NavLink to="/brand/report" >*/}
                            {/*        <span className="nav-text">*/}
                            {/*            <IntlMessages id="sidebar.brand.reports" />*/}
                            {/*        </span>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}

                            <li className="menu no-arrow">

                                <NavLink to={Config.path + "/brand/lessons"} >

                                    <span className="nav-text">
                                        <IntlMessages id="Lessons" />
                                    </span>
                                </NavLink>
                            </li>

                            {/*<li className="menu no-arrow">*/}
                            {/*    <NavLink to="/brand/landing-bidders" >*/}
                            {/*        <span className="nav-text">*/}
                            {/*            <IntlMessages id="sidebar.brand.landingBidders" />*/}
                            {/*        </span>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}

                            {/*<li className="menu no-arrow">*/}
                            {/*    <NavLink to="/brand/not-cpa" >*/}
                            {/*        <span className="nav-text">*/}
                            {/*            <IntlMessages id="sidebar.brand.notCpa" />*/}
                            {/*        </span>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}

                            {/*<li className="menu no-arrow">*/}
                            {/*    <NavLink to="/brand/settings" >*/}
                            {/*        <span className="nav-text">*/}
                            {/*            <IntlMessages id="sidebar.brand.settings" />*/}
                            {/*        </span>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}

                        </ul>
                    </li>

                    <li className="menu collapse-box">
                        <Button href="javascript:void(0)">

                            <i className="zmdi zmdi-account zmdi-hc-fw" />

                            <span className="nav-text">
                            Profile
                            </span>
                        </Button>
                        <ul className="sub-menu">
                            <li className="menu no-arrow">

                                <NavLink to={Config.path + "/profile/account"}>

                                    <span className="nav-text">
                                        Account
                                    </span>
                                </NavLink>
                            </li>
                            {/*<li className="menu no-arrow">*/}
                                {/*<NavLink to="/profile/notification">*/}
                                    {/*<span className="nav-text">*/}
                                        {/*notifications*/}
                                    {/*</span>*/}
                                {/*</NavLink>*/}
                            {/*</li>*/}

                            {/*<li className="menu no-arrow">*/}
                                {/*<NavLink to="/profile/plan">*/}
                                    {/*<span className="nav-text">*/}
                                        {/*plans*/}
                                    {/*</span>*/}
                                {/*</NavLink>*/}
                            {/*</li>*/}

                        </ul>
                    </li>

                </ul>
            </CustomScrollbars>
        );
    }
}

export default withRouter(SideNavContent);
