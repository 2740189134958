import React, {useEffect, useState} from "react";
import { Editor, EditorState, ContentState } from 'draft-js';

const EditorTeachersNote = ({el, i, activeTabIndex, options, socketMessage}) => {
    
    const [editorState, setEditorState] =  useState(el.editorState);
    const [currentVal, setCurrentVal] = useState(el.currentVal);
    const [uniqId, setUniqId] = useState(el.uniqId);

    useEffect(() => {
        
        options.changeElement({editorState, currentVal}, i, activeTabIndex);
    }, [editorState, currentVal]);

    const onChangeEditor = (editorState) => {
        setEditorState(editorState);
        setCurrentVal(editorState.getCurrentContent().getPlainText('\u0001'));

        options.sendMessage({command: 'handleChangeEditor',
            data: {
                uniqId,
                i,
                currentVal: currentVal,
            }
        });
    }
    return (
        <>
            {(!['student'].includes(options.userInfo.userInfo.type) )&&
                <div className="TeachersNote" key={`tab_${activeTabIndex}_item_${i}`}>
                    <div className="TeachersNoteHeader">Teacher's Note</div>
                        <Editor placeholder="Enter text..."
                            editorState={editorState}
                            onChange={onChangeEditor}
                    /> <br />
                </div>
            }
        </>
    );
}

export default EditorTeachersNote;
