import React from 'react';
import {connect} from 'react-redux';
import {logout} from '../../../actions/auth';
import Classes from './Classes';
import callApi from '../../../utils/call-api';
import config from '../../../config';
import t from './Teacher.module.scss';

import IconTeachingMaterial from '../../../assets/images/icons/teaching material.svg';

const lang = textId => textId;

class Teacher extends React.Component {
    state = {
        dialogVisible: false,
        lessonsCount: 0,
        currentMonthLessonsCount: 0,
        currentMonthGroupLessonsCount: 0,
        currentMonthVipLessonsCount: 0,
        currentMonthIncome: 0,
        futureLessonsCount: 0,
        teacherStatus: lang('Эксперт'),
        teacherExpToNextLevel: 100,
        avatarLink: `${config.imageStubGeneratorUrl}/50x50.png`,
        defaultUsername: lang('Аноним'),
        unreadNewsCount: 0,
        conductedLessons: [],
        futureLessonsData: [],
        nextLessonData: [],
        conductedLessonsIsOpen: false,
        futureLessonsDataIsOpen: false,
        nextLessonDataIsOpen: true
    };

    componentDidMount() {
        callApi('dashboard/main', 'GET').then(res => {

            if (res && res.data.ok) {
                for (let i = 0; i < res.data.studentsData.length; i++) {
                    const studentData = res.data.studentsData[i];
                    const nextLessonDataStudentIndex = res.data.nextLessonData.students.indexOf(studentData.id);

                    if (nextLessonDataStudentIndex !== -1) {
                        res.data.nextLessonData.students[nextLessonDataStudentIndex] = res.data.studentsData[i];
                    }

                    for (let j = 0; j < res.data.futureLessonsData.length; j++) {
                        const futureLessonStudentIndex = res.data.futureLessonsData[j].students.indexOf(studentData.id);

                        if (futureLessonStudentIndex !== -1) {
                            res.data.futureLessonsData[j].students[futureLessonStudentIndex] = res.data.studentsData[i];
                        }
                    }

                    for (let k = 0; k < res.data.conductedLessons.length; k++) {
                        const conductedLessonStudentIndex = res.data.conductedLessons[k].students.indexOf(studentData.id);

                        if (conductedLessonStudentIndex !== -1) {
                            res.data.conductedLessons[k].students[conductedLessonStudentIndex] = res.data.studentsData[i];
                        }
                    }
                }

                this.setState({
                    conductedLessons: res.data.conductedLessons,
                    futureLessonsData: res.data.futureLessonsData,
                    nextLessonData: [res.data.nextLessonData],
                    lessonsCount: res.data.lessonsCount,
                    futureLessonsCount: res.data.futureLessonsCount,
                    currentMonthLessonsCount: res.data.currentMonthLessonsCount,
                    currentMonthGroupLessonsCount: res.data.currentMonthGroupLessonsCount,
                    currentMonthVipLessonsCount: res.data.currentMonthVipLessonsCount,
                });
            } else {
                console.log(res.data.message);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    activateDialog() {
        this.setState({dialogVisible: true});
    }

    deactivateDialog() {
        this.setState({dialogVisible: false});
    }

    render() {
        const storage = JSON.parse(localStorage.getItem('user') || null);
        let avatarLink = this.state.avatarLink;
        let username = this.state.defaultUsername;

        if (storage) {

            if (storage.username) {
                username = storage.username;
            }

            if (storage.avatarLink) {
                avatarLink = storage.avatarLink;
            }
        }

        return (
            <div className={t.teacher_page}>
                {/*Из-за дизайна пока что не получается сделать нормальный цикл*/}
                <div className={t.statistics_wrap}>
                    <div className={t.stat_first_block}>
                        <div className={t.stat_first_block_header}>
                            {lang('Всего в EpicLang')}
                            <br/>
                            {lang('у вас было')}
                        </div>
                        <div className={t.stat_first_block_num}>
                            {this.state.lessonsCount}
                        </div>
                        <div className={t.stat_first_block_footer}>
                            {lang('занятий')}
                        </div>
                    </div>
                    <div className={t.stat_first_block}>
                        <div className={t.stat_first_block_header}>
                            {lang('В этом месяце')}
                            <br/>
                            {lang('у вас было')}
                        </div>
                        <div className={t.stat_first_block_num}>
                            {this.state.currentMonthLessonsCount}
                        </div>
                        <div className={t.stat_first_block_footer}>
                            {lang('занятий')}
                        </div>
                    </div>
                    <div className={t.stat_group}>
                        <div className={t.stat_group_row}>
                            <div className={t.stat_group_num}>
                                {this.state.currentMonthGroupLessonsCount}
                            </div>
                            <div className={t.stat_group_title}>
                                {lang('Групповых')}
                                <br/>
                                {lang('занятий')}
                            </div>
                        </div>
                        <div className={t.stat_group_row}>
                            <div className={t.stat_group_num}>
                                {this.state.currentMonthVipLessonsCount}
                            </div>
                            <div className={t.stat_group_title}>
                                {lang('VIP')}
                                <br/>
                                {lang('занятия')}
                            </div>
                        </div>
                    </div>
                    <div className={t.stat_first_block}>
                        <div className={t.stat_first_block_header}>
                            {lang('Ваш доход')}
                            <br/>
                            {lang('в этом месяце')}
                        </div>
                        <div className={t.stat_first_block_num}>
                            {this.state.currentMonthIncome}
                        </div>
                        <div className={t.stat_first_block_footer}>
                            {lang('рублей')}
                        </div>
                    </div>
                    <div className={t.stat_status}>
                        <div className={t.stat_status_header}>
                            {lang('Ваш стутус')}
                        </div>
                        <div className={t.stat_status_pic}>
                        </div>
                        <div className={t.stat_status_footer}>
                            {this.state.teacherStatus}
                        </div>
                    </div>
                    <div className={t.stat_next_status}>
                        <div className={t.stat_next_status_content}>
                            {lang('До следующего ')}
                            <br/>
                            {lang('профессионального')}
                            <br/>
                            {lang('статуса')}
                            <br/>
                            {lang('осталось')}
                        </div>
                        <div className={t.stat_next_status_left}>
                            <div className={t.stat_next_status_empty}>
                            </div>
                            <div className={t.stat_next_status_left_days}>
                                {this.state.teacherExpToNextLevel}
                                <br/>
                                {lang('баллов')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={t.right_panel}>
                    <div>
                        <img className={t.id_photo} src={avatarLink} alt={lang('Avatar')}></img>
                    </div>
                    <div className={t.right_panel_txt}>
                        {username}
                        <br/><br/>
                        <div onClick={() => this.props.logout()}><b>Logout</b></div>
                    </div>
                </div>
                <div className={t.right_panel_schedule + ' ' + t.right_panel}>
                    <div className={t.right_panel_img_wrap}>
                        <div className={t.schedule_img}>
                        </div>
                    </div>
                    <div className={t.right_panel_txt}>
                        {lang('Мое')}
                        <br/>
                        {lang('расписание')}
                    </div>
                </div>
                <div className={t.right_panel_chat + ' ' + t.right_panel}>
                    <div className={t.right_panel_img_wrap}>
                        <div className={t.chat_img}></div>
                    </div>
                    <div className={t.right_panel_txt}>
                        {lang('Чат')}
                    </div>
                </div>
                <div className={t.right_panel_test_platform + ' ' + t.right_panel}>
                    <div className={t.right_panel_img_wrap}>
                        <div className={t.test_platform_img}></div>
                    </div>
                    <div className={t.right_panel_txt}>
                        {lang('Тестовая платформа')}
                    </div>
                </div>
                <div
                    className={t.right_panel_my_students + ' ' + t.right_panel}>
                    <div className={t.right_panel_img_wrap}>
                        <div className={t.my_students_img}>
                        </div>
                    </div>
                    <div className={t.right_panel_txt}>
                        {lang('Мои студенты')}
                    </div>
                </div>
                <div className={t.right_panel_news + ' ' + t.right_panel}>
                    <div className={t.right_panel_img_wrap}>
                        {/*<div className={t.news_img}>
                        </div>*/}
                        <div >
                            <img className={t.right_panel_img} src={IconTeachingMaterial} alt="teaching material"/>
                        </div>
                    </div>
                    <div className={t.right_panel_txt}>
                        {lang('Новости')}
                    </div>
                    {
                        this.state.unreadNewsCount > 0 ?
                            <div className={t.right_panel_notification}>
                                <div className={t.right_panel_notific_num}>{this.state.unreadNewsCount}</div>
                            </div> :
                            null
                    }
                </div>
                <div className={t.logo_wrap}>
                    <div
                        data-w-id='3356b772-9822-f0e3-b397-a5a92641c48a'
                        data-animation-type='lottie'
                        data-src={config.DomainForFront + "/icons/5eb8e3d5c32e37860e3f363f_lf30_editor_t3fyRp.json"}
                        data-loop='1'
                        data-direction='1'
                        data-autoplay='1'
                        data-is-ix2-target='0'
                        data-renderer='svg'
                        data-default-duration='1.0666666666666667'
                        data-duration='0'
                        className='lottie-animation-48'
                    >
                    </div>
                    <div className={t.logo_img}></div>
                </div>
                <div className={t.schedule}>
                    <Classes
                        nextLessonData={this.state.nextLessonData}
                        isOpen={this.state.nextLessonDataIsOpen}
                        lessonsCount={this.state.nextLessonData.length}
                    ></Classes>
                    <Classes
                        futureLessonsData={this.state.futureLessonsData}
                        isOpen={this.state.futureLessonsDataIsOpen}
                        lessonsCount={this.state.futureLessonsCount}
                    ></Classes>
                    <Classes
                        conductedLessons={this.state.conductedLessons}
                        isOpen={this.state.conductedLessonsIsOpen}
                        lessonsCount={this.state.lessonsCount} // LessonsCount === conductedLessonsCount
                    ></Classes>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({settings}) => ({});

export default connect(mapStateToProps, {logout})(Teacher);
