import axios from 'axios';
import config from '../config';

export default function callApi(endpoint, options, payload, contentType = 'application/json') {
    const user = JSON.parse(localStorage.getItem('user') || null);
    let token = '';

    if (user) {
        token = user.token;
    }

    const authHeaders = token ? {Authorization: token} : {};
    let lang = 'en';
    const locale = JSON.parse(localStorage.getItem('locale') || null);

    if (locale && locale.locale) {
        lang = locale.locale;
    }

    return axios({
        method: `${options}`,
        url: `${config.API_URI}/${endpoint}`,
        headers: {
            'Content-Type': contentType,
            'X-Requested-With': 'XMLHttpRequest',
            'X-Fraudzeroider-Language': lang,
            ...authHeaders
        },
        data: payload
    });
}

export function getRecordId() {
    const url = new URL(window.location.href);

    return url.searchParams.get('id');
}
