import * as types from '../constants/likes';

const initialState = {
    openCloseLikes: false,
    selectedParticipant: '',
    participantEmail: '',
    participantName: '',
}

export default function likes (state = initialState, action) {
    switch (action.type) {
        case types.OPEN_LIKES:
            return {
                ...state,
                openCloseLikes: action.payload,
            };
        case types.CLOSE_LIKES:
            return {
                ...state,
                openCloseLikes: action.payload,
            };
        case types.SELECTION_PARTICIPANT:
            return {
                ...state,
                selectedParticipant: action.payload,
            };
        case types.PARTICIPANT_EMAIL:
            return {
                ...state,
                participantEmail: action.payload.email,
                participantName: action.payload.name,
            };
        default:
            return state;
    }
};