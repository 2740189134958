import React from 'react';
import moment from 'moment';
import 'moment-duration-format';
import Pagination from '@material-ui/lab/Pagination';
import { Box } from '@material-ui/core';
import callApi from '../../../utils/call-api';
import { v1 } from '../../../utils/uuid';
import config from '../../../config'
import './Student.scss';
import { SMALL_LESSON_LENGTH } from '../common/timeTable/TimeTableHelper';
import CancelClassesDialog from '../common/cancelClassesDialog/CancelClassesDialog';
import IntlMessages from '../../../utils/IntlMessages';
import lang from '../../../utils/lang';


const classTypes = {
    next: 'Ближайшее занятие',
    future: 'Следующие занятия',
    conducted: 'Завершённые занятия',
    homeworkDataIncomplete: 'Невыполненные домашние задания',
    homeworkDataComplete: 'Завершённые домашние задания',
};
let classType = '';

class Classes extends React.Component {
    state = {
        dialogVisible: false,
        cancelWindowVisible: true,
        isActiveFL: false,
        avatarLink: `${config.imageStubGeneratorUrl}/50x50.png`,
        defaultSkipValue: config.defaultSkipValue,
        defaultLimitValue: 20,
        currentPage: 1,
        lessonsCount: 0,
        lessonsData: [],
        commentLength: config.defaultCommentLength,
        selectedLessonId: '',
        selectedLessonTeacherComment: '',
        maxMarkValue: config.maxMarkValue,
        cancelVLSubmitIndex: null,
        timeUntilLesson: 0,
    };

    componentDidMount() {
        this.setState({
            isActiveFL: !!this.props.isOpen
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isOpen > prevProps.isOpen) {
            this.setState({ isActiveFL: false });
        } else if (this.props.isOpen > prevProps.isOpen) {
            this.setState({ isActiveFL: true });
        }
        if (Array.isArray(this.props.nextLessonData) && this.props.nextLessonData[0]) {
            const timer = setInterval(() => {
                const newData = Date.now();
                const startDate = this.props.nextLessonData[0].startDate * 1000 || newData;
                const timeUntil = startDate - newData;
                const threeMinutes = 3 * 60 * 1000;

                if (startDate) {
                    this.setState({timeUntilLesson: timeUntil});
                }

                if (timeUntil < threeMinutes) {
                    clearInterval(timer);
                }

                return clearInterval(timer);
            }, 1000);
        }
    }

    ToggleLessonsMenu = () => {
        this.setState({ isActiveFL: !this.state.isActiveFL });
    }

    getLessonsData = (e) => {
        const options = {
            skip: this.state.defaultSkipValue + this.state.defaultLimitValue * (+e.target.innerText - 1),
            limit: this.state.defaultLimitValue
        };
        const pageNumber = +e.target.innerText;

        if (this.props.futureLessonsData) {
            options.type = 'future';
        } else if (this.props.conductedLessons) {
            options.type = 'conducted';
        } else {
            console.log(lang('Wrong lessons type'));

            return;
        }

        callApi('student/getLessons', 'POST', options).then(res => {
            if (res && res.data.ok) {
                this.setState({
                    lessonsData: res.data.lessonsData,
                    currentPage: pageNumber,
                    lessonsCount: res.data.lessonsCount
                });
            } else {
                console.log(res.data.message);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    leaveLesson = lessonData => {
        const lessonLengthInMinutes = Math.floor((lessonData.endDate - lessonData.startDate) / 60);

        callApi((lessonData.isIndividual) ?
                'student/leaveIndividualVirtualLesson' : 'student/leaveLesson',
            'POST',
            {
            virtualLessonId: lessonData.id,
            individualClassType: (lessonLengthInMinutes > SMALL_LESSON_LENGTH) ?
                'individualClass' : 'individualShortClass',
        }).then(res => {
            if (res && res.data.ok) {
                this.setState({ cancelVLSubmitIndex: null });
                this.props.updateStudent();
            } else {
                alert(res.data.message);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    getGroupsByStudents = (lessonId) => {
        // callApi('dashboard/getGroupsByStudents', 'POST', {
        //     virtualLessonId: lessonId
        // }).then(res => {

        //     if (res && res.data.ok) {
        //         console.log(res.data);
        //     } else {
        //         console.log(res.data.message);
        //     }
        // }).catch(e => {
        //     console.log(e);
        // });
    }

    // activateDialog = (e) => {
    //     this.setState({
    //         dialogVisible: true,
    //         selectedLessonId: e.target.dataset.lessonId,
    //         selectedLessonTeacherComment: e.target.dataset.lessonTeacherComment
    //     })
    // }

    // deactivateDialog = () => {
    //     const inputError = document.getElementById('addLessonCommentInputError');
    //     inputError.innerHTML = '';
    //     inputError.style.color = '';
    //
    //     this.setState({dialogVisible: false})
    // }

    // createStudentMark = (markValue = 0) => {
    //     return Array.from(Array(this.state.maxMarkValue)).map((value, index) => {
    //
    //         return (
    //             <div className={`review-s-rocket ${markValue <= index ? 'no-rocket' : ''}`}/>
    //         );
    //     });
    // }

    prepareLessonLength = lessonData => {
        const lessonLength  = lessonData.endDate - lessonData.startDate;
        const lessonLengthText = (lessonLength < (60 * 35)) ? lang('25 минут') : lang('50 минут');
        return (
            <>{lessonLengthText}</>
        );
    }

    visibleCustomHeight(item) {
        let lessonsData = [];

        if (item === classTypes.conducted) {
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.conductedLessons;
        }
        if (item === classTypes.future) {
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.futureLessonsData;
        }
        if (item === classTypes.nextLessonData) {
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.futureLessonsData;
        }
        if (item === classTypes.homeworkDataIncomplete || item === classTypes.homeworkDataComplete) {
            lessonsData = this.props.conductedLessons;
        }


        return this.customHeight(lessonsData.length)
    }

    customHeight(num) {
        const heights = [
            'custom_height_0',
            'custom_height_1',
            'custom_height_2',
            'custom_height_3',
            'custom_height_4',
            'custom_height_5',
            'custom_height_6',
        ];

        if (num < 6) {
            return heights[num];
        } else {
            return heights[6];
        }
    }

    render() {
        const { cancelVLSubmitIndex } = this.state;
        const { isForHomework } = this.props;

        let lessonsData = [];
        let lessonsCount = this.state.lessonsCount ? this.state.lessonsCount : this.props.lessonsCount;

        let specialClassName = '';

        if (this.props.nextLessonData) {
            specialClassName = ' studentSpecialClassname ';
            classType = classTypes.next;
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.nextLessonData;
        } else if (this.props.futureLessonsData) {
            classType = classTypes.future;
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.futureLessonsData;
        } else {
            classType = classTypes.conducted;
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.conductedLessons;
        }

        if (this.props.isForHomework && this.props.isIncompleteHomework ) {
            classType = classTypes.homeworkDataIncomplete;
        } else if (this.props.isForHomework) {
            classType = classTypes.homeworkDataComplete;
        }

        if (lessonsData.length) {
            lessonsData.sort((a, b) => b.endDate - a.endDate);
        }

        return (
            <div>
                {/*<CommentDialog*/}
                {/*    dialogVisible={this.state.dialogVisible}*/}
                {/*    closeDialog={this.deactivateDialog}*/}
                {/*    addLessonComment={this.addLessonComment}*/}
                {/*    lessonId={this.state.selectedLessonId}*/}
                {/*    comment={this.state.selectedLessonTeacherComment}*/}
                {/*/>*/}
                <div className='finished_classes'>
                    <div data-w-id={v1()}
                         onClick={this.ToggleLessonsMenu}
                         className='finished_classes_title'>
                        <div className='finished_class_txt'>
                            {lang(classType)}
                        </div>
                    </div>
                    <div
                        className={this.state.isActiveFL === true ?
                            (isForHomework && this.props.isIncompleteHomework ?
                                specialClassName + 'finished_visible_block visible_custom_width ' + this.visibleCustomHeight(classType) : (isForHomework && !this.props.isIncompleteHomework ?
                                    specialClassName + 'finished_visible_block visible_custom_width ' + this.visibleCustomHeight(classType) : specialClassName + 'finished_visible_block ' + this.visibleCustomHeight(classType))) :
                            (isForHomework && this.props.isIncompleteHomework ?
                                specialClassName + 'finished_hidden_block visible_custom_width' : (isForHomework && !this.props.isIncompleteHomework ?
                                    specialClassName + 'finished_hidden_block visible_custom_width' : specialClassName + 'finished_hidden_block'))}
                    >
                        {
                            lessonsData.length ?
                                <div className='further_class_info'>
                                    {
                                        lessonsData.map((lessonData, index) => {
                                            const nodeIndex = index;
                                            return (
    <div key={nodeIndex} style={{ position: 'relative' }}>
        {cancelVLSubmitIndex === nodeIndex &&
            <CancelClassesDialog
                className="CancelClassesDialogBorder"
                onCancel={() => this.setState({ cancelVLSubmitIndex: null })}
                onSuccess={() => this.leaveLesson(lessonData)}
            />
        }
        <div className='next_class_info_items'>
            <div key={v1()} className='teacher_item'>
                <div className={lessonData.teacherId.avatar ? "" : "teacher_photo_container"}>
                    <img className={lessonData.teacherId.avatar ? "teacher_photo" : "teacher_photo_img"}
                        src={lessonData.teacherId.avatar || this.state.avatarLink}
                        alt={lang('')}
                    ></img>
                </div>
                <div className='teacher_name'>
                    <div className='text_title'>
                        <IntlMessages id="Учитель:" /><br/>
                    </div>
                    <div className='text_item'>
                        {lessonData.teacherId.name} {(typeof lessonData.teacherId.surname === 'string'?
                            lessonData.teacherId.surname.substring(0, 1) : ''
                        )}
                    </div>
                </div>
            </div>
            {!isForHomework && <div className='class_type'>
                <div className='text_title'>
                    <IntlMessages id="Тип занятия" />:
                    <br/>
                </div>
                <div className='text_item'>
                    {lessonData.isIndividual ? lang('VIP-занятие') : lang('Мини-группа')}
                    
                </div>
            </div>}
            {!isForHomework && <div className='class_duration'>
                <div className='text_title'>
                    <IntlMessages id="Продолжительность" />
                    <br/>
                    <IntlMessages id="занятия" />:
                    <br/>
                </div>
                <div className='text_item'>
                    {this.prepareLessonLength(lessonData)}
                </div>
            </div>}
            <div className='class_review'>
                <div className='text_title'>
                    {!isForHomework ? <IntlMessages id="Дата начала" /> : <IntlMessages id="Дата урока" />}:
                </div>
                <div className='text_item'>
                    {moment(lessonData.startDate * 1000).format('DD.MM.YYYY')}
                </div>
                <div className='text_item'>
                    {moment(lessonData.startDate * 1000).format('HH:mm')}
                </div>
            </div>
            {(() => {

                if (this.props.conductedLessons) {
                    if (isForHomework && this.props.isIncompleteHomework) {
                        return (
                            <div className='comment_review_title homeworkDo'
                                onClick={()=> this.props.onMadeHomeworkClick(lessonData)}
                            >
                                <div>
                                    <IntlMessages id="ВЫПОЛНИТЬ ДОМАШНЕЕ ЗАДАНИЕ" />
                                </div>
                            </div>
                        );
                    } else if (isForHomework && !this.props.isIncompleteHomework) {
                        const { markImg, markText } = this.props.getMarkInfo(lessonData.homeworkStudentMark);

                        return (
                            <div className='comment_review_title'>
                                <div className="marks_info">
                                    <div className="marks_info_img" >
                                        <img src={markImg}/>
                                    </div>
                                    <div className="marks_info_text">
                                        <div>{markText}!</div>
                                        <div>{lessonData.homeworkStudentMark}/100</div>
                                    </div>

                                </div>

                            </div>
                        );
                    }

                    return (
                        <div className='comment_review_title'>
                            <IntlMessages id="Оценка:" />
                            <div className='review-s-rockets'>
                                {Array.from(Array(this.state.maxMarkValue)).map((value, index) =>
                                    <div className={`review-s-rocket ${lessonData.studentsMarks <= index ? 'no-rocket' : ''}`}/>
                                )}
                            </div>
                        </div>
                    )
                } else if (this.state.timeUntilLesson > 3 * 60 * 1000) {

                    return (
                        <div data-w-id={v1()} className='countdown' style={{ justifyContent: 'center' }}>
                            <div className='teacher_review_title'>
                                <IntlMessages id="До начала урока" />:
                            </div>
                            <div className='text_item'>
                                {`~${moment.duration(this.state.timeUntilLesson / 1000, 'seconds')
                                    .format(`dd ${lang('д.')} hh ${lang('ч.')} mm ${lang('м.')} ss ${lang('с.')}`)}`}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div
                            className='comment comment_review_title comment_review_title_student'
                            onClick={() => {
                                window.location.href = `/brand/vl?id=${lessonData.id}`
                            }}
                        >
                            <IntlMessages id="Войти" />
                        </div>
                    )
                }
            }
            )()}
        </div>
        {
            index !== lessonsData.length - 1 ?
                <div className='line'/> :
                null
        }
    </div>
                                            )
                                        })
                                    }
                                </div> :
                                null
                        }
                        {
                            classType !== classTypes.next && this.props.lessonsCount > this.state.defaultLimitValue ?
                                <Box display="flex" justifyContent="center">
                                    <Pagination
                                        count={Math.ceil(lessonsCount / this.state.defaultLimitValue)}
                                        page={this.state.currentPage}
                                        onChange={this.getLessonsData}
                                    />
                                </Box> :
                                null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Classes;
