import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import s from "../SuperAdmin.module.css";
import callApi from '../../../../utils/call-api';
import ScheduleBoard from './ScheduleBoard';
import ScheduleEdit from './ScheduleEdit';
import "./Schedule.scss";

const Schedule = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [languages, setLanguages] = useState([]);
    const [isShowBoard, setIsShowBoard] = useState(true);
    const [selectedLesson, setSelectedLesson] = useState(null);

    useEffect(() => {
        callApi("guide/languages","GET")
        .then(data=>{
            if(data.data.languages.length) {
                setTabIndex(data.data.languages[0].id);
            }
            setLanguages(data.data.languages||[]);
        }).catch(err=>{
            console.log(err);
            setLanguages([]);
            setTabIndex(0);
        });
    }, []);

    useEffect(() => {
        if(languages.length) {
            setTabIndex(languages[0].id);
        }
    }, [languages]);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        setIsShowBoard(true);
    };

    const showBoard=()=>{
        setIsShowBoard(true);
    };

    const editSchedule = (scheduleItem)=>{
        setSelectedLesson(scheduleItem);
        setIsShowBoard(false);

    }

    return (
        <div className="schedule_main">
             <Box sx={{ width: '100%', typography: 'body1' }}> 
                 <TabContext value={tabIndex} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            {languages.map((item, i) => <Tab label={item.name} value={item.id} />)}
                        </TabList>
                    </Box>
                    {languages.map((item, i) => <TabPanel value={item.id} 
                        className='my-tab-panel-root'>
                            {isShowBoard && <ScheduleBoard languageInfo={item} editSchedule={editSchedule}/> }
                            {!isShowBoard && <ScheduleEdit languageInfo={item} lesson={selectedLesson} onBackClick={showBoard}/> }
                         </TabPanel>)}                                           
                </TabContext>  
             </Box>         
        </div>
    );    
};

export default Schedule;