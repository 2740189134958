/* global localStorage */
import * as types from '../constants/settings';

export function setLessonIdent(lessonIdent) {
    return dispatch => {
        dispatch({
            type: types.SET_LESSON_IDENT,
            lessonIdent,
        });
    };
}

export function close(isNavCollapsed) {
    return {
        type: types.TOGGLE_COLLAPSED_NAV,
        isNavCollapsed
    };
}

export function toggleCollapsedNav(isNavCollapsed) {
    return {
        type: types.TOGGLE_COLLAPSED_NAV,
        isNavCollapsed
    };
}

export function updateWindowWidth(width) {
    return {
        type: types.WINDOW_WIDTH,
        width
    };
}

export function switchLanguage(locale) {
    localStorage.setItem('locale', JSON.stringify(locale));

    return {
        type: types.SWITCH_LANGUAGE,
        payload: locale
    };
}

export function displayErrorMessage(message) {
    return {
        type: types.DISPLAY_ERROR_MESSAGE,
        message
    };
}

export function setLessonSocket(socket) {
    return dispatch => {
        dispatch({
            type: types.SET_LESSON_SOCKET,
            payload: socket
        });
    };
}

export function setLessonsArr(array) {
    return dispatch => {
        dispatch({
            type: types.SET_LESSONS_ARR,
            payload: array
        });
    };
}

export function setUsersInfo(usersInfo) {
    return dispatch => {
        dispatch({
            type: types.SET_USERS_INFO,
            payload: usersInfo
        });
    };
}

export function changeDemoGrade(grade) {
    return dispatch => {
        dispatch({
            type: types.DEMO_GRADE,
            payload: grade
        });
    };
}