import React, {useEffect, useState} from "react";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button/Button';
import l from '../main/LessonEditor.module.scss';
import { use } from "react";

const ButtonL = ({el, i, activeTabIndex, options }) => {
    const [text, setText] = useState(el.text);
    const [link, setLink] = useState(el.link);
    const [isPresentation,  setIsPresentation] = useState(options.isPresentation);

    useEffect(()=>{
        options.changeElement({text, link}, i, activeTabIndex);
    }, [text, link]);
    
    return (
        <div className={l.WIT_example} key={`tab_${activeTabIndex}_item_${i}`}>
            {!isPresentation &&
                <div>
                    <div className="col-12 dad2_render">
                        <TextField
                            placeholder={""}
                            InputLabelProps={{ shrink: true }}
                            required
                            name="text"
                            value={text}
                            onChange={event => setText(event.target.value)}
                            label={'Enter the text'}
                            fullWidth
                            multiline={false}
                            margin="normal"
                            className="mt-0 mb-4"
                        />
                    </div>
                    <div className="col-12 dad2_render">
                        <TextField
                            placeholder={""}
                            InputLabelProps={{ shrink: true }}
                            required
                            name="text"
                            value={link}
                            onChange={event => setLink(event.target.value)}
                            label={'Enter the link'}
                            fullWidth
                            multiline={false}
                            margin="normal"
                            className="mt-0 mb-4"
                        />
                    </div>
                    
                </div>}
            <Button onClick={() => window.open(link, '_blank')}
                    style={{ minWidth: '60px' }} color="primary" variant="contained">{text ? text : 'Button'}</Button>
        </div>
    );
};

export default ButtonL; 