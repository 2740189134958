import React, { useState } from "react";

import useTable from "../useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";

const Table = ({ data, rowsPerPage, sort, setSort, setActiveEdit, setActiveList, setActiveEditLottie }) => {
    const [page, setPage] = useState(1);

    const { slice, range } = useTable(data, page, rowsPerPage);

    function sortLottie() {
        if (sort === true) {
            setSort(false)
        } else if (sort === false){
            setSort('')
        } else {
            setSort(true)
        }
    }



    return (
        <React.Fragment>
            <table className={styles.table}>
                <thead className={styles.tableRowHeader}>
                <tr>
                    <th className={`${styles.tableHeader} ${styles.tableCellName}`} onClick={() => sortLottie()}>
                        Name
                        {sort === true ? <span>&#129045;</span> : ''}
                        {sort === false ? <span>&#129047;</span> : ''}
                    </th>
                    <th className={styles.tableHeader}>Description</th>

                </tr>
                </thead>
                <tbody>
                {slice.map((el) => (
                    <tr className={styles.tableRowItems} key={el.id}>
                        <td className={`${styles.tableCell} ${styles.tableCellName}`} onClick={() => {
                            setActiveEdit(true)
                            setActiveList(false)
                            setActiveEditLottie(el)
                        }}>{el.name}</td>
                        <td className={styles.tableCell}>{el.description}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </React.Fragment>
    );
};

export default Table;