import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Alert} from 'reactstrap';
import LogoBrand from '../components/LogoBrand';
import Dialog from '../containers/Dialog';
import IntlMessages from '../utils/IntlMessages';

class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static propTypes = {
        confirm: PropTypes.func.isRequired
    };

    state = {
        token: ''
    };

    handleSubmit(event) {
        event.preventDefault();

        const {token} = this.state;
        this.props.showAuthLoader();
        this.props.confirm(token);
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        const token = url.searchParams.get('token');
        this.setState({
            token
        });
    }

    render() {
        const {
            loader,
            showMessage,
            alertTitle,
            dialog,
            alertMessage
        } = this.props;

        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <LogoBrand alt="FraudZeroider"/>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1><IntlMessages id="appModule.confirmEmail"/></h1>
                        </div>

                        {showMessage && (
                            <Alert className="shadow-lg bg-danger text-white">
                                <p style={{marginBottom: 0}}>
                                    {alertTitle}
                                </p>
                                {alertMessage
                                    ? <ul className="auth-errors">
                                        <li>{alertMessage}</li>
                                    </ul> : ''}
                            </Alert>
                        )}

                        {dialog && <Dialog title={<IntlMessages id="appModule.congratulations"/>}
                            text={<IntlMessages id="appModule.youHaveSuccessfullyRegistered"/>}/>}

                        <div className="app-login-form">
                            <form onSubmit={this.handleSubmit}>
                                <fieldset>
                                    <div className="mb-3 d-flex align-items-center justify-content-between"
                                        style={{paddingTop: '20px'}}>
                                        <Button type="submit"
                                            variant="contained"
                                            color="primary">
                                            <IntlMessages id="appModule.confirm"/>
                                        </Button>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>

                </div>

                {loader && (
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                )}

            </div>
        );
    }
}

export default Confirm;
