/* global document */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import './styles/jumbo.css';
import './styles/breakpoints.scss';
import './styles/responcove-init.scss';
import './styles/responcove.scss';
import './styles/fromJumbo_CustomStyles.scss';
import './styles/myApp.scss';

const store = configureStore();

global.soundsVolume = 0.05;
global._ = console.log;

global.myVarIsMobileDevice = false;

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    global.myVarIsMobileDevice = true;
    // _('navigator.userAgent', navigator.userAgent)
} else {
    // _('navigator.userAgent_', navigator.userAgent)
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,

    document.getElementById('app-site')
);

serviceWorker.unregister();
