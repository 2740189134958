import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { connect } from 'react-redux';

import {bindActionCreators} from 'redux';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from '../constants';
import {toggleCollapsedNav, switchLanguage} from '../actions/settings';
import { dialogSelectLesson, dialogSetWhiteboard, dialogSetImgWhiteboard } from '../actions/dialog';
import comparingValuesInSymbol from '../utils/comparing-symbol-values';

import IconLogo from '../assets/images/icons/studio_logo_black.png';
import IconWhiteboard from '../assets/images/icons/whiteboard.svg';
import IconSpeech from '../assets/images/icons/speech bubble.png';
import IconDictionary from '../assets/images/icons/dictionary.svg';
import IconStickers from '../assets/images/icons/stickers.svg';
import IconTeachingMaterial from '../assets/images/icons/teaching material.svg';

import IconTimer from '../assets/images/icons/timer.svg';
import IconRocket from '../assets/images/icons/new rocket.png';
import IconRedRocket from '../assets/images/icons/red rocket.png';

import IconExit from '../assets/images/icons/exit.svg';
import LessonCheckDialog from './virtualLesson/LessonCheckDialog';

import PaintPanel from './paint/paintPanel'
import Sticks from "./sticks/sticks";
import {closeDict, openDict, selectedWordToTranslate} from "../actions/dictionary";
import {openExitFromLesson} from "../actions/exitFromLesson";

import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import DnDWrapperForChat from './DnDWrapperForChat';
import DnDWrapperForDict from './DnDWrapperForDict';
import timerEndSound from "../assets/sounds/timer_end.mp3";

const timerEndAudio = new Audio();
timerEndAudio.src = timerEndSound;

class Header extends React.Component {
    state = {
        anchorEl: undefined,
        searchBox: false,
        searchText: '',
        mailNotification: false,
        userInfo: false,
        langSwitcher: false,
        appNotification: false,
        isOpenWhiteboardDialog: false,
        isOpenLessonCheckDialog: false,
        generalStick: false,
        chat: false,
        timer: false,
        timerHours: 0,
        timerMinutes: 0,
        minutesForTimer: 0,
        studentTime: 0,
        studentsGrades: false,
        whiteboard: this.props.whiteboard,
        color: '#000',
        width: '10',
        clear: false,
    };

    minutesToTime = minute => ({
        hour: Math.floor(minute / 60),
        minute: minute % 60
    });

    componentDidMount() {
        const timerParams = JSON.parse(localStorage.getItem('TimerParams') || null);

        if (timerParams && timerParams.minutesForTimer) {
            const minutesForTimer = timerParams.minutesForTimer;
            this.setState({
                timerHours: this.minutesToTime(minutesForTimer).hour,
                timerMinutes: this.minutesToTime(minutesForTimer).minute,
                minutesForTimer,
                studentTime: minutesForTimer,
            }, () => {
                setTimeout(() => this.startTimer(),
                    60 * 1000 - ((new Date). getTime() - timerParams.timerDateTime));
            });

            localStorage.setItem('TimerParams', JSON.stringify({minutesForTimer, timerDateTime: (new Date()).getTime()}));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.socketMessage.command === 'giveTimeToTimer') &&
            ((this.props.socketMessage.data.timerTime + 2) !== prevState.studentTime)) {
            const timeLeftSocket = this.minutesToTime(this.props.socketMessage.data.timerTime) +2;
            this.setState({
                timerHours: timeLeftSocket.hour,
                timerMinutes: timeLeftSocket.minute,
                studentTime: this.props.socketMessage.data.timerTime + 2,
                minutesForTimer: this.props.socketMessage.data.timerTime + 2,
            }, () => {
                clearTimeout(this.timer);
                this.startTimer();
            })
        }

        if (this.props.socketMessage.command === 'showDictionaryForUsers') {
            if (this.props.userInfo.userInfo.email === this.props.socketMessage.data.userEmail
                || this.props.socketMessage.data.userEmail === 'all') {
                this.props.selectedWordToTranslate(this.props.socketMessage.data.dictionaryWord);
                this.props.openDict();
            }
        }
    }

    startTimer = () => {
        if (this.state.minutesForTimer === 1) {
            localStorage.setItem('TimerParams', JSON.stringify({minutesForTimer: null}));
            timerEndAudio.play(); timerEndAudio.volume = global.soundsVolume;
        }

        if (this.state.minutesForTimer > 0) {
            this.countDown();
            this.timer = setTimeout(this.startTimer, 60 * 1000);
        }
    }

    countDown = () => {
        const minutesForTimer = this.state.minutesForTimer - 1;
        this.setState({
            timerHours: this.minutesToTime(minutesForTimer).hour,
            timerMinutes: this.minutesToTime(minutesForTimer).minute,
            minutesForTimer,
        });

        localStorage.setItem('TimerParams', JSON.stringify({minutesForTimer, timerDateTime: (new Date()).getTime()}));
    }

    getClear = (clear) => {
        this.setState({ clear });
        this.props.dialogSetWhiteboard({...this.props.whiteboard,  clear});
    };

    getPaintState = paintState => {
        this.setState({...paintState});
        this.props.dialogSetWhiteboard({...this.props.whiteboard, ...paintState});
    };

    openCloseDictionary = () => {
        if (this.props.openCloseDict === true) {
            this.props.closeDict()
        } else {
            this.props.openDict()
        }
    };

    openCloseGeneralStick = () => {
        if (this.state.generalStick === true) {
            this.setState({generalStick: false})
        } else {
            this.setState({generalStick: true})
        }
    };

    openCloseChat = () => {
        if (this.state.chat === true) {
            this.setState({chat: false})
        } else {
            this.setState({chat: true})
        }
    };

    openCloseTimer = () => {
        if (this.state.timer === true) {
            this.setState({timer: false})
        } else {
            this.setState({timer: true})
        }
    };

    openCloseStudentsGrades = () => {
        if (this.state.studentsGrades === true) {
            this.setState({studentsGrades: false})
        } else {
            this.setState({studentsGrades: true})
        }
    };

    HideDialog = () => {
        this.setState({isOpenLessonCheckDialog: false})
    };

    onClickWhiteboardIcon = () => {
        this.props.dialogSetImgWhiteboard(false);

        const { whiteboard } = this.state;
        this.setState({
            isOpenWhiteboardDialog: !this.state.isOpenWhiteboardDialog,
            whiteboard: {
                ...whiteboard,
                show: !whiteboard.show,
                color: this.state.color,
                width: this.state.width,
                clear: this.state.clear,
            }
        }, () => {
            this.props.dialogSetWhiteboard(this.state.whiteboard);
            this.props.socket.send(JSON.stringify({
                command: 'dialogSetWhiteboard',
                data: this.state.whiteboard,
                val: {
                    vCId: this.props.lessonIdent && this.props.lessonIdent.id,
                    userEmail: this.props.userInfo.userInfo.email,
                    userType: this.props.userInfo.userInfo.type,
                },
            }));
        });
    };

    render() {
        const hours = this.state.timerHours;
        const minutes = this.state.timerMinutes;
        const yourGrade = this.props.gradeParticipant;
        const { drawerType, locale } = this.props;
        const drawerStyle = comparingValuesInSymbol(drawerType, FIXED_DRAWER) ? 'd-block d-xl-none' : comparingValuesInSymbol(drawerType, COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
        const generalStickActive =  this.state.generalStick;
        const chatActive =  this.state.chat;
        const timerActive =  this.state.timer;
        const studentsGradesActive =  this.state.studentsGrades;
        const recommendedLesson = (this.props.lessonIdent && this.props.lessonIdent.lessonId) ? this.props.lessonIdent.lessonId : null;
        const virtualClassId = (this.props.lessonIdent && this.props.lessonIdent.lessonId) ? this.props.lessonIdent.id : null;

        return (
            <AppBar className="app-main-header">
                <Toolbar className="app-toolbar" disableGutters={false}>
                    {<>
                        <LessonCheckDialog
                            dialogSelectLesson={this.props.dialogSelectLesson}
                            HideDialog={() => this.setState({ isOpenLessonCheckDialog: false })}
                            open={this.state.isOpenLessonCheckDialog}
                            lessonsArr={this.props.lessonsArr.filter(el => !el.isForHomework)}
                            recommendedLesson={recommendedLesson}
                        />
                        <div className="navIcons">
                            <Sticks
                                socket={this.props.socket}
                                socketMessage={this.props.socketMessage}
                                userInfo={this.props.userInfo}
                                virtualClassId={virtualClassId}
                                generalActive={generalStickActive}
                                setGeneralActive={this.openCloseGeneralStick}
                                timerActive={timerActive}
                                setTimerActive={this.openCloseTimer}
                                studentsGradesActive={studentsGradesActive}
                                setStudentsGradesActive={this.openCloseStudentsGrades}
                            />

                            {!myVarIsMobileDevice &&
                                <DndProvider backend={HTML5Backend}>
                                    <DnDWrapperForChat
                                        chatActive={chatActive}
                                    />
                                </DndProvider>}
                            {!myVarIsMobileDevice &&
                                <DndProvider backend={HTML5Backend}>
                                    <DnDWrapperForDict/>
                                </DndProvider>}

                            {myVarIsMobileDevice &&
                                <DndProvider backend={TouchBackend}>
                                    <DnDWrapperForChat
                                        chatActive={chatActive}
                                    />
                                </DndProvider>}
                            {myVarIsMobileDevice &&
                                <DndProvider backend={TouchBackend}>
                                    <DnDWrapperForDict/>
                                </DndProvider>}
                            <div className="navIconsLeft">
                                <div className={"navIconsDiv"  + (this.props.openCloseDict ? ' navIconsDivOpen' : '')}
                                     onClick={this.openCloseDictionary}>
                                    <img className="right_panel_img" src={IconDictionary} alt="dictionary"/>
                                </div>
                                <div className={"navIconsDiv"  + (this.state.chat ? ' navIconsDivOpen' : '')}
                                     onClick={this.openCloseChat}>
                                    <img className="right_panel_img" src={IconSpeech} alt="chat"/>
                                </div>
                                <div className={"navIconsDiv" +
                                    ((this.state.isOpenWhiteboardDialog || this.props.showImgWhiteboard || this.props.whiteboard.show) ? ' navIconsDivOpen' : '')}
                                     style={{ overflow: 'visible' }}
                                     onClick={ ['admin', 'teacher'].includes(this.props.userInfo.userInfo.type) ?
                                         this.onClickWhiteboardIcon : () => {}}
                                >
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                        <div style={{position: 'fixed', width: '20px', height: '21px'}}>
                                            <img className="right_panel_img" src={IconWhiteboard} alt="whiteboard"/>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <PaintPanel
                                        showImgWhiteboard={this.props.showImgWhiteboard}
                                        getClear={this.getClear}
                                        getPaintState={this.getPaintState}
                                    />
                                </div>

                                {(!this.props.userInfo || !this.props.userInfo.userInfo || ['admin', 'teacher'].includes(this.props.userInfo.userInfo.type)) &&
                                    <div className="navIconsDiv" onClick={this.openCloseGeneralStick}>
                                        <img className="right_panel_img" src={IconStickers} alt="stickers"/>
                                    </div>}
                                {/*<div className="navIconsDiv">*/}
                                {/*    <img className="right_panel_img" src={IconVideos} alt="video"/>*/}
                                {/*</div>*/}
                                {/*<div className="navIconsDiv">*/}
                                {/*    <img className="right_panel_img" src={IconPrivateRooms} alt="private rooms"/>*/}
                                {/*</div>*/}
                            </div>
                            <div className="navIconsCenter">
                                <img src={IconLogo} alt="logo"/>
                            </div>





                            <div className="navIconsRight">
                                {(!this.props.userInfo || !this.props.userInfo.userInfo || ['admin', 'teacher'].includes(this.props.userInfo.userInfo.type)) &&
                                    <div className="navIconsDiv" onClick={() => this.setState({isOpenLessonCheckDialog: true})}>
                                        <img className="right_panel_img" src={IconTeachingMaterial} alt="teaching material"/>
                                    </div>}
                                {/*{(!this.props.userInfo || !this.props.userInfo.userInfo || ['admin', 'teacher'].includes(this.props.userInfo.userInfo.type)) &&*/}
                                {/*<div className="navIconsDiv">*/}
                                {/*    <img className="right_panel_img" src={IconPoll} alt="poll"/>*/}
                                {/*</div>}*/}
                                {(!this.props.userInfo || !this.props.userInfo.userInfo || ['admin', 'teacher'].includes(this.props.userInfo.userInfo.type)) &&
                                    <div className="navIconsDiv" onClick={this.openCloseTimer}>
                                        {this.state.minutesForTimer > 0 ?
                                            <div> {'0' + hours}:{minutes < 10 ? '0' + minutes : minutes} </div> :
                                            <img className="right_panel_img" src={IconTimer} alt="timer"/>}

                                    </div>}
                                {(!this.props.userInfo || !this.props.userInfo.userInfo || ['admin', 'teacher'].includes(this.props.userInfo.userInfo.type)) &&
                                    <div className="navIconsDiv" onClick={this.openCloseStudentsGrades}>
                                        <img className="right_panel_img" src={IconRocket} alt="rocket"/>
                                    </div>}
                                {(!this.props.userInfo || !this.props.userInfo.userInfo || ['student'].includes(this.props.userInfo.userInfo.type)) &&
                                    <div className="navIconsDivStudent">
                                        <img className='studentIconsRocket' src={yourGrade >= 1 ? IconRedRocket : IconRocket} alt="rocket"/>
                                        <img className='studentIconsRocket' src={yourGrade >= 2 ? IconRedRocket : IconRocket} alt="rocket"/>
                                        <img className='studentIconsRocket' src={yourGrade >= 3 ? IconRedRocket : IconRocket} alt="rocket"/>
                                        <img className='studentIconsRocket' src={yourGrade >= 4 ? IconRedRocket : IconRocket} alt="rocket"/>
                                        <img className='studentIconsRocket' src={yourGrade >= 5 ? IconRedRocket : IconRocket} alt="rocket"/>
                                    </div>}
                                {(!this.props.userInfo || !this.props.userInfo.userInfo || ['student'].includes(this.props.userInfo.userInfo.type)) &&
                                    <div className="navIconsDiv">
                                        <div>
                                            {'0' + hours}:{minutes < 10 ? '0' + minutes : minutes}
                                        </div>
                                    </div>}
                                {(!this.props.userInfo || !this.props.userInfo.userInfo || ['admin', 'teacher'].includes(this.props.userInfo.userInfo.type)) &&
                                    <div className="navIconsDiv" onClick={this.props.openExitFromLesson}>
                                        <img className="right_panel_img" src={IconExit} style={{transform: 'rotate(0.25turn)'}} alt="exit"/>
                                    </div>}
                            </div>
                        </div>
                    </>}
                </Toolbar>
            </AppBar>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        toggleCollapsedNav,
        switchLanguage,
        dialogSelectLesson,
        dialogSetWhiteboard,
        openDict,
        closeDict,
        selectedWordToTranslate,
        openExitFromLesson,
        dialogSetImgWhiteboard,
    },
    dispatch
);


const mapStateToProps = ({ settings, dialog, auth, dictionary, timerLesson }) => ({
    drawerType: settings.drawerType,
    lessonsArr: settings.lessonsArr,
    locale: settings.locale,
    navigationStyle: settings.navigationStyle,
    horizontalNavPosition: settings.horizontalNavPosition,
    whiteboard: dialog.whiteboard,
    lessonIdent: settings.lessonIdent,
    userInfo: auth.userInfo,
    socket: settings.socket,
    socketMessage: dialog.socketMessage,
    openCloseDict: dictionary.openCloseDict,
    gradeParticipant: dialog.gradeParticipant,
    timerTime: timerLesson.timerTime,
    usersInfo: settings.usersInfo,
    showImgWhiteboard: dialog.showImgWhiteboard,
});


// export default withRouter(connect(mapStateToProps, { toggleCollapsedNav, switchLanguage })(Header));

export default (connect(mapStateToProps, mapDispatchToProps)(Header));


