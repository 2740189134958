
import React, { useState, useEffect } from 'react';
import s from "../SuperAdmin.module.css";

const CoursLevelItem = ({item, homework, onClick}) => {
    const [value, setValue] = useState(item);
    
    useEffect(() => setValue(item), [item]);

    const click = () => {
        onClick(value)
    };

    return (
        <div key={value.id}
            className={s.levels}
            onClick={click}
        >
            <div>{value.type}</div>
            <div style={{ color: '#0df' }}>( {value.lessonCount} ) <small style={{ color: '#f0f' }}>(id: {value.id})</small></div>
        </div>
    );
};

export default CoursLevelItem;