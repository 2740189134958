import React, { Component } from 'react';
import './CancelClassesDialog.scss';

class CancelClassesDialog extends Component {
    state = {
        open: true,
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.HideDialog();
    };

    render() {
        const { className } = this.props;

        return (
            <div className={className ? className : ''}>
                <div className="CancelClassesDialog">
                    <div className="CCDHeader">
                        <div>ВНИМАНИЕ!</div>
                        <div>Условия отмены VIP занятий</div>
                    </div>

                    <div className="CCDInfoTitle">
                        <div>Отменено вами более, чем</div>
                        <div>за 24 часа до начала</div>
                        <div>занятия</div>
                    </div>
                    <div className="CCDInfoText">
                        <div>Ваше занятие НЕ сгорает</div>
                        <div>и вы можете забронировать урок</div>
                        <div>на другое время</div>
                    </div>
                    <div className="CCDBlueLine" />

                    <div className="CCDInfoTitle">
                        <div>Отменено вами менее, чем  </div>
                        <div>за 24 часа до начала</div>
                        <div>занятия или неявка</div>
                    </div>
                    <div className="CCDInfoText">
                        <div>Занятие никак не</div>
                        <div>компенсируется и формально</div>
                        <div>считается проведённым</div>
                    </div>

                    <div className="CCDButton" style={{ backgroundColor: 'red'}}
                        onClick={() => this.props.onSuccess()}
                    >
                        <div>ОТМЕНИТЬ ЗАНЯТИЕ</div>
                    </div>
                    <div className="CCDButton"
                         onClick={() => this.props.onCancel()}
                    >
                        <div>НЕ ОТМЕНЯТЬ</div>
                        {/*<div>ЗАНЯТИЕ</div>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default CancelClassesDialog;
