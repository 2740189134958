import React, {useRef, useEffect, useState} from 'react';
import renderInputText from './common/renderInputtext';
import Button from '@material-ui/core/Button/Button';
import ReactPlayer from 'react-player';

const RUTUBE = "rutube";

const VideoPlayer = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [link, setLink] = useState(el.link);
    const [uniqId] = useState(el.uniqId);

    //let link = el.link;
    const myRef = useRef(null);
    let isRutube = link.includes(RUTUBE);
    let currentVideoTime = -1;
    const index= i;

    
    window.addEventListener('message', function (event) {
        try{
            var message = JSON.parse(event.data);
            console.log(message.type); // some type
            switch (message.type) {
                case 'player:currentTime':
                    currentVideoTime = message.data.time; // текущее состояние плеера
                    break;
        };
        } catch(e){
            console.log(e);
        }
        
    });

    useEffect(()=>{
        if (socketMessage&&socketMessage.command === 'handleYoutubePlayer') {
            if (options.isPresentation && socketMessage.data.uniqId === uniqId) {
                if (socketMessage.data.YoutubePlayerAction === 'playVideo') {
                    playVideo(socketMessage.data.youtubePlayerCurrentTime);
                } else if (socketMessage.data.YoutubePlayerAction === 'pauseVideo') {
                    pauseVideo();
                }
            }
        }
    },[socketMessage]);
 
    const handleLinkChange = (event, i, activeTabIndex) => {
        event.persist();

        const { value } = event.target;
        setLink(value);
        isRutube = value.includes(RUTUBE);
        console.log(isRutube);
    };

    useEffect(() => {
        options.changeElement({link: link}, i, activeTabIndex);
    }, [link]);

    const stratButtonClick = () => {
        playVideo();
        const time = getCurrentTime();
        const message = {
            command: 'handleYoutubePlayer',
            data: {uniqId, i, YoutubePlayerAction: "playVideo", youtubePlayerCurrentTime: time}
        };
        options.sendMessage(message);
    };

    const playVideo = (time) => {
        if (isRutube){
            if (time>=0) {
                myRef.current.contentWindow.postMessage(JSON.stringify({
                    type: 'player:setCurrentTime',
                    data: {time: time}
                }), '*');
            }
            myRef.current.contentWindow.postMessage(JSON.stringify({
                type: 'player:play',
                data: {}
            }), '*');
        } else {
            if(time){
                myRef.current.player.player.seekTo(time, 'second');
            }
            myRef.current.player.player.play();
        }
    };

    const stopButtonClick= () => {
        pauseVideo();
        const message = {
            command: 'handleYoutubePlayer',
            data: {uniqId, i, YoutubePlayerAction: "pauseVideo"}
        };
        options.sendMessage(message);
    };

    const pauseVideo = () => {
        if (isRutube){
            myRef.current.contentWindow.postMessage(JSON.stringify({
                type: 'player:pause',
                data: {}
            }), '*');
        } else {
            myRef.current.player.player.pause();
        }
    };

    const getCurrentTime = ()=>{
        if(!isRutube){
            return myRef.current.player.player.getCurrentTime();
        } else {
            return currentVideoTime;
        }
    };

    
    return (
        <>
        {(!options.isPresentation||(['student'].includes(options.userInfo.userInfo.type)&&options.isHomework))&&
        <div>
            {renderInputText(i, link, handleLinkChange, activeTabIndex, 0, 'Введите id видео (например k4ufz3fRiIY)')}
        </div>
        }
    
        <div className={"YouTubePlayerContainer" +
            ((['student'].includes(options.userInfo.userInfo.type) && !options.isHomework) ? " YouTubePlayerContainerPointerEvent" : " ")}>
        {isRutube&&                  
        <iframe ref={myRef} width="720px" height="405px"
            src={link}
            frameBorder="0" allow="clipboard-write; autoplay" 
            webkitAllowFullScreen allowFullScreen
           >

        </iframe>        
        }
        {!isRutube&&<ReactPlayer ref= {myRef}
                        width="720px" height="405px"
                        url={link.length===0?"": link.includes("youtu.be")?link:`https://youtu.be/${link}`}
                        controls={true}
                        playing={false}
                    />}
        </div>
        {(!['student'].includes(options.userInfo.userInfo.type)) &&
            <div className="YouTubePlayerContainer">
                <Button onClick={stratButtonClick}>Старт</Button>
                <Button onClick={stopButtonClick}>Стоп</Button>
            </div>
        }
        </>
        )
};


export default VideoPlayer;
