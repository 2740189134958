import * as types from '../constants/likes';


export function openLikes() {
    return dispatch => {
        dispatch({
            type: types.OPEN_LIKES,
            payload: true
        });
    };
}

export function closeLikes() {
    return dispatch => {
        dispatch({
            type: types.CLOSE_LIKES,
            payload: false
        });
    };
}

export function participantEmail(email, name) {
    return dispatch => {
        dispatch({
            type: types.PARTICIPANT_EMAIL,
            payload: { email, name}
        });
    };
}