import React, {useEffect, useState} from "react";

import useTable from "../../Lottie/useTable";
import styles from "../../Lottie/TableComponents/Table.module.css";
import TableFooter from "../../Lottie/TableComponents/TableFooter/index";
import callApi from "../../../../../utils/call-api";

const Table = ({ data, rowsPerPage, sort, setSort, setActiveEdit, setActiveList, setActiveEditFacts, setSortTarget, sortTarget }) => {
    const [page, setPage] = useState(1);
    const [getCourses, setGetCourses] = useState([]);
    const lang = textId => textId;

    const { slice, range } = useTable(data, page, rowsPerPage);
    function sortFacts(target) {
        setSortTarget(target)
        if (sort === true) {
            setSort(false)
        } else if (sort === false){
            setSort('')
        } else {
            setSort(true)
        }
    }

    useEffect(() => {
        async function getCourses() {
            const courses = [];

            await callApi('dashboard/getCourses', 'post').then(res => {

                if (res && res.data.ok) {
                    res.data.courses.forEach(el => {
                        courses.push(el);
                    });
                } else {
                    alert(lang(res.data.message));
                }
            }).catch(err => {
                alert(lang('Receive courses data error'));
                console.log('err', err);
            });

            setGetCourses(courses);
        }

        getCourses();
    }, []);

    console.log('Data', data)

    return (
        <React.Fragment>
            <table className={styles.table}>
                <thead className={styles.tableRowHeader}>
                <tr>
                    <th className={`${styles.tableHeader} ${styles.tableCellNameFacts}`}
                        onClick={() => sortFacts('name')}
                    >
                        Name
                        {sort === true && sortTarget === 'name' ? <span>&#129045;</span> : ''}
                        {sort === false && sortTarget === 'name' ? <span>&#129047;</span> : ''}
                    </th>
                    <th className={`${styles.tableHeader} ${styles.tableCellName}`}
                        onClick={() => sortFacts('description')}
                    >
                            Description
                            {sort === true && sortTarget === 'description' ? <span>&#129045;</span> : ''}
                            {sort === false && sortTarget === 'description' ? <span>&#129047;</span> : ''}
                    </th>
                    <th className={`${styles.tableHeader} ${styles.tableCellName} ${styles.tableCellLanguage}`}>
                        Language
                    </th>
                    <th className={`${styles.tableHeader} ${styles.tableCellName} ${styles.tableCellLanguage}`}>
                        Courses
                    </th>
                    <th className={`${styles.tableHeader} ${styles.tableCellName} ${styles.tableCellLanguage}`}>
                        On/Off
                    </th>

                </tr>
                </thead>
                <tbody>
                {slice.map((el) => (
                    <tr className={styles.tableRowItems} key={el.id}>
                        <td className={`${styles.tableCell} ${styles.tableCellName}`} onClick={() => {
                            setActiveEdit(true)
                            setActiveList(false)
                            setActiveEditFacts(el)
                        }}>{el.name}</td>
                        <td className={styles.tableCell}>{el.description}</td>
                        <td className={styles.tableCell}>{el.languageId === 2 ? 'English' : null}</td>
                        {getCourses ? getCourses.map(item => {
                            if(item.id === el.courseId) {
                                return (
                                    <td className={styles.tableCell}>{item.name + '(' +item.type +')'}</td>
                                )
                            }
                        }) : null}
                        <td className={styles.tableCell}>{el.isEnabled === true ? 'On' : 'Off'}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </React.Fragment>
    );
};

export default Table;