import React from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BuyClassesItem  from './BuyClassesItem';
import { Portal } from 'react-portal';

import './BuyClasses.scss';
import callApi from '../../../../utils/call-api';
import BUY_CLASSES_OBJ from './BUY_CLASSES_OBJ';
import BUY_CLASSES_OBJ_V2 from './BUY_CLASSES_OBJ_V2';
import ModalCloseButton from "../closeButton/ModalCloseButton";

const DATE_FOR_NEW_TARIFFS = 1690902860; // Tue Aug 01 2023 15:14:20 GMT+0000

class BuyClasses extends React.Component {
    constructor(props) {
        super(props);

        const userInfo = this.props.userInfo.userInfo;

        let buyClassesArr = JSON.parse(JSON.stringify(BUY_CLASSES_OBJ.groupLevel1));

        if (userInfo.creationDate > DATE_FOR_NEW_TARIFFS) {
            buyClassesArr = JSON.parse(JSON.stringify(BUY_CLASSES_OBJ_V2.groupLevel1));
        }

        let actualGroupLevel1Index = null;

        buyClassesArr.forEach((el, i) => {
            el.show = false;
            if (userInfo.age >= el.ageFrom && userInfo.age <= el.ageTo) {
                el.isActualAge = true;
                // TODO не правильно определяет и выводит актуальны йвозраст
                el.show = true;
                actualGroupLevel1Index = i;
            }
        });
        this.state = {
            isFirstShow: false,
            buyClassesArr,
            actualGroupLevel1Index,
        }
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isShowBuyClasses !== this.props.isShowBuyClasses && !this.state.isFirstShow) {
            this.setState({ isFirstShow: true });

            const actualGroupLevel1Index =  this.state.actualGroupLevel1Index ? this.state.actualGroupLevel1Index : 0;

            setTimeout(() => this.showLevel(this.state.buyClassesArr, actualGroupLevel1Index),0);
        }
    }

    buy = () => {
        callApi('dashboard/getSubscriptionPaymentData  ', 'post',
            {subscriptionId: 1}).then(res => {
            const result = res?.data;
            if (result?.ok && result.paymentLink) {
                window.open(result.paymentLink, '_blank');
            }
        }).catch(err => console.log(err));
    }

    showLevel = (arr, groupLevel1Index) => {
        if (!Array.isArray(arr)) return;

        arr.forEach((el, i) => {
            el.show = (i === groupLevel1Index);
            this.setState({ });
        });
    };

    render() {
        const { isShowBuyClasses } = this.props;
        const { isFirstShow, buyClassesArr } = this.state;

        if (!buyClassesArr) return (<></>);

        return (
            <>{isFirstShow &&
                <div className="CustomJumpModal BuyClassesContainer">
                    <div className={'CustomJumpModalBody BuyClassesBody' +
                        (isShowBuyClasses ? ' CustomJumpModalBodyShow' : '')}
                    >
                        <ModalCloseButton
                            closeModal={this.props.closeModal}
                        />
                        <div id="BuyClassesLevGroup1">
                            <div className="BuyClassesLevGroup1">
                                {buyClassesArr.map((groupLevel1Item, groupLevel1Index) => (
                                    <div key={groupLevel1Index}
                                         className={"BuyClassesLev1" +
                                             (!groupLevel1Item.show ? '' : ' BuyClassesLev1Curr') +
                                             (groupLevel1Item.isActualAge ? '' : ' notActualAge')
                                        }
                                         onClick={() => this.showLevel(buyClassesArr, groupLevel1Index)}
                                    >
                                        <div className={"BuyClassesLev1Icon " + groupLevel1Item.iconUrlClass}/>
                                        <div className="BuyClassesLev1Text">
                                            <div style={{ whiteSpace: 'nowrap'}}>{groupLevel1Item.text1}</div>
                                            <div>{groupLevel1Item.text2}</div>
                                        </div>
                                        <Portal node={document.getElementById('BuyClassesLevGroup1')}>
                                            {groupLevel1Item.show &&
                                                <div className={"BuyClassesLevGroup2" + ((groupLevel1Item.groupLevel2.length > 1) ? '' : ' BuyClassesLevHidden')}>
                                                    {groupLevel1Item.groupLevel2.map((groupLevel2Item, groupLevel2Index) => (
                                                        <div key={groupLevel2Index}
                                                             className={"BuyClassesLev2" + (!groupLevel2Item.show ? '' : ' BuyClassesLev2Curr')}
                                                             onClick={() => this.showLevel(groupLevel1Item.groupLevel2, groupLevel2Index)}
                                                        >
                                                            <div>{groupLevel2Item.text1}</div>

                                                            <Portal node={document.getElementById('BuyClassesLevGroup2')}>
                                                                {groupLevel2Item.show &&
                                                                    <div className={"BuyClassesLevGroup3" + ((groupLevel2Item.groupLevel3.length > 1) ? '' : ' BuyClassesLevHidden')}>
                                                                        {groupLevel2Item.groupLevel3.map((groupLevel3Item, groupLevel3Index) => (
                                                                            <div key={groupLevel3Index}
                                                                                 className={"BuyClassesLev3" + (!groupLevel3Item.show ? '' : ' BuyClassesLev3Curr')}
                                                                                 onClick={() => this.showLevel(groupLevel2Item.groupLevel3, groupLevel3Index)}
                                                                            >
                                                                                <div>{groupLevel3Item.text1}</div>
                                                                                <div>{groupLevel3Item.text2}</div>

                                                                                <Portal node={document.getElementById('BuyClassesLevItems')}>
                                                                                    {groupLevel3Item.BuyClassesItems.map((BuyClassesItemInfo) => (
                                                                                    <>
                                                                                    {groupLevel3Item.show &&
                                                                                        <div className="BuyClassesItems">
                                                                                            <BuyClassesItem
                                                                                                isActualAge={groupLevel1Item.isActualAge}
                                                                                                actualGroupLeveName={this.state.actualGroupLevel1Index ?
                                                                                                    buyClassesArr[this.state.actualGroupLevel1Index].iconUrlClass :
                                                                                                    null
                                                                                                }
                                                                                                BuyClassesItemInfo={BuyClassesItemInfo}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                    </>
                                                                                    ))}
                                                                                </Portal>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                }
                                                            </Portal>
                                                        </div>
                                                    ))}
                                                </div>}
                                        </Portal>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div id="BuyClassesLevGroup2"/>
                        <div id="BuyClassesLevItems"/>
                    </div>
                </div>
            }</>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { },
    dispatch
);

const mapStateToProps = state => (
    {
        userInfo: state.auth.userInfo,
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyClasses));
