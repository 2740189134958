import React from 'react';
import { DragSource } from 'react-dnd';

export const Box = ({ useOnlyOnce, name, isDropped, connectDragSource, isHomework /*isDragging*/ }) => {
    const style = ((isDropped && !isHomework) || (isHomework && useOnlyOnce && isDropped)) ?
        { opacity: '0.4', border: '1px solid gray', pointerEvents: 'none' } :
        { opacity: '0.99' }

    return connectDragSource(
        <div style={style} className="Dad2Box">{name}</div>
    )
};
export default DragSource(
    props => props.type,
    {
        beginDrag: props => ({
            name: props.name,
            className: 'Dad2Box',
            boxIndex: props.boxIndex,
        }),
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }),
)(Box);


// display: flex;
// justify-content: center;
// flex-wrap: wrap;