import React, { useReducer, useState, useEffect } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import './Auth.scss';
import AuthLogo from './imgs/auth_logo.png';
import { useDispatch } from 'react-redux';
import { hideAuthMessage } from '../../actions';
import IntlMessages from '../../utils/IntlMessages';
import packageJson from '../../../package.json';

const ANIMATION_PERIOD_1 = 600; // ms
const AUTH_TYPE_SIGNIN = 'signin';
const AUTH_TYPE_SIGNUP = 'signup';

const authReducer = (state, action) => {
    switch (action.type) {
        case AUTH_TYPE_SIGNIN:
            return {
                ...state,
                authType: AUTH_TYPE_SIGNIN,
                signInClassName: action.signInClassName || state.signInClassName,
                signUpClassName: action.signUpClassName || state.signUpClassName,
                titleSignInClass: action.titleSignInClass || state.titleSignInClass,
                titleSignUpClass: action.titleSignUpClass || state.titleSignUpClass,
            };
        case AUTH_TYPE_SIGNUP:
            return {
                ...state,
                authType: AUTH_TYPE_SIGNUP,
                signInClassName: action.signInClassName || state.signInClassName,
                signUpClassName: action.signUpClassName || state.signUpClassName,
                titleSignInClass: action.titleSignInClass || state.titleSignInClass,
                titleSignUpClass: action.titleSignUpClass || state.titleSignUpClass,
            };
        default:
            return state;
    }
}

export default function Auth () {
    const version =packageJson.version;
    const dispatchRedux = useDispatch();

    const [state, dispatch] = useReducer(authReducer, {
        authType: AUTH_TYPE_SIGNIN,
        signInClassName: '',
        signUpClassName: 'scaleAndHide',
        titleSignInClass: '',
        titleSignUpClass: 'toTopAndHide',
    });

    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 566) {
            setIsMobileDevice(true);
        } else {
            setIsMobileDevice(false);
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth <= 566) {
                setIsMobileDevice(true);
            } else {
                setIsMobileDevice(false);
            }
        });
    }, []);

    return (
        <div className="AuthComponentContainer">
        <div className="AuthComponent">
            <div className="AuthComponentHeader"
                onClick={() => dispatch({ type: AUTH_TYPE_SIGNUP })}
            >
                <div style={{position: 'absolute'}} className={state.titleSignInClass}><IntlMessages id="Вход в личный кабинет" /></div>
                <div style={{position: 'absolute'}} className={state.titleSignUpClass}><IntlMessages id="Регистрация на интерактивной платформе" /></div>
            </div>
            <div className="AuthComponentLogo">
                <img alt="LOGO" src={AuthLogo}/>
            </div>

            <div className="AuthComponentPath">
                <SignIn
                    className={state.signInClassName}
                    updateAuthType={() => {
                        dispatch({
                            type: AUTH_TYPE_SIGNUP,
                            signInClassName: 'scaleAndHide',
                            titleSignInClass: 'toTopAndHide',
                        });

                        dispatchRedux(hideAuthMessage());

                        setTimeout(() => {
                            dispatch({
                                type: AUTH_TYPE_SIGNUP,
                                signUpClassName: 'scaleAndShow',
                                titleSignUpClass: 'toTBottomAndShow',
                            });
                        }, ANIMATION_PERIOD_1);
                    }}
                />
                <SignUp
                    isMobileDevice={isMobileDevice}
                    className={state.signUpClassName}
                    updateAuthType={() => {
                        dispatch({
                            type: AUTH_TYPE_SIGNIN,
                            signUpClassName: 'scaleAndHide',
                            titleSignUpClass: 'toTopAndHide',
                        });

                        dispatchRedux(hideAuthMessage());

                        setTimeout(() => {
                            dispatch({
                                type: AUTH_TYPE_SIGNIN,
                                signInClassName: 'scaleAndShow',
                                titleSignInClass: 'toTBottomAndShow',
                            });
                        }, ANIMATION_PERIOD_1);
                    }}
                />
            </div>
            <div className="AuthComponentSiteName"
                 onClick={() => {
                     window.open('https://www.epiclang.com');
                 }}
            >
                www.epiclang.com
            </div>
            <div>{`version = ${version}`}</div>
        </div>
        </div>
    );
}
