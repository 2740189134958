import {injectIntl} from 'react-intl';
import Form from './Form';
import callApi, {cancelToken, getRecordId} from '../../../utils/call-api-new';

class Edit extends Form {
    constructor(props) {
        super(props);
        this.state = {
            formAction: 'brand/not-cpa',
            ...this.state,
            formType: 'edit',
            formTitle: 'audit.editDomain',
            formDialogTitleId: 'audit.editDomain',
            formDialogContentId: 'audit.domainEditedSuccessfully',
            applyTextId: 'audit.apply',
            saveTextId: 'audit.save',
            saveMethod: 'put'
        };
    }

    getEndpoint() {
        return `${this.state.formAction}/${getRecordId()}`;
    }

    componentDidMount() {
        this.getDomain();
    }

    getDomain() {
        const source = cancelToken().source();
        this.setState({
            cancelAjaxRequest: source
        });
        return callApi(this.getEndpoint(), 'get', {}, source).then(res => {
            this.setState({
                alertVisible: false,
                domain: {
                    value: res.domain,
                    isValid: true
                },
                pattern: {
                    value: res.pattern,
                    isValid: true
                },
                comment: {
                    value: res.comment,
                    isValid: true
                },
                isEnable: !!res.status
            });
        }).catch(err => this.ErrorHundler(err));
    }
}

export default injectIntl(Edit);
