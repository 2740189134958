import React, {useEffect, useState} from "react";

import { Editor, EditorState, ContentState } from 'draft-js';
import Button from '@material-ui/core/Button/Button';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

import TeacherHelpInfo from "./common/TeacherHelpInfo";
import uuid, { v1 } from '../../../utils/uuid';
import l from '../main/LessonEditor.module.scss';

const dropdownMenuProps = {
    padding: { padding: '0' },
    disablePadding: true,
    getContentAnchorEl: null,
    anchorOrigin: { vertical: "bottom", horizontal: "left" },
};

const CWF = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [isHomework]  = useState(options.isHomework);
    const [isPresentation]  = useState(options.isPresentation);
    const [isHomeworkFinish, setIsHomeworkFinish] = useState(options.isHomeworkFinish);
    const [homeworkTasksInformation, setHomeworkTasksInformation] = useState(options.homeworkTasksInformation);
    const [editorState, setEditorState] = useState(el.editorState);
    const [currentValue, setCurrentValue] = useState(el.currentVal);


    const [isRenderCode, setIsRenderCode] = useState(el.isRenderCode);
    const [textArr, setTextArr] = useState(el.textArr);
    const [wordsArr, setWordsArr] = useState(el.wordsArr);
    const [clue, setClue] = useState(el.clue);
    const [customTitle, setCustomTitle] = useState(el.customTitle);
    const [uniqId, setUuid] =  useState(el.uniqId);

    useEffect(()=>{
        options.changeElement({editorState, currentValue, isRenderCode, textArr, wordsArr, clue, customTitle}, i, activeTabIndex);
    },[editorState, currentValue, isRenderCode, textArr, wordsArr, clue, customTitle]);

    useEffect(() => {
        if (socketMessage && socketMessage.data.uniqId === uniqId) {
            if(socketMessage.command === 'handleChangeEditor') {
                let val = JSON.stringify(socketMessage.data.currentVal || currentVal).replaceAll('u0001', 'n');
                    val = JSON.parse(val) || '';
                    setCurrentVal(val);
                    setEditorState(EditorState.createWithContent(ContentState.createFromText(val)));
            }
            if(socketMessage.command === 'handleCWF') {
                wordsArr[socketMessage.data.index].className = socketMessage.data.className;
                wordsArr[socketMessage.data.index].val = socketMessage.data.val;
                setWordsArr([...wordsArr]);

                if (socketMessage.data.className === l.WIT_successful) {
                    const variant = random(studentAnswerSuccessSounds.length);
                    studentAnswerSuccessSounds[variant].play();
                    studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                } else {
                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                }
            }
        }
    },[socketMessage]); 
            

    const index = i;

    if (!homeworkTasksInformation[uuid] && Array.isArray(wordsArr)) {
        options.updateHomeworkTasksInformation(uuid, wordsArr.length);
    }

    const onChangeEditor = (editorState) => {
        setEditorState(editorState);
        setCurrentValue(editorState.getCurrentContent().getPlainText('\u0001'));
        options.sendMessage({
            command: 'handleChangeEditor',
            data: {uuid, index, currentVal: currentValue },                            
        });
    };

    const okHandlerWIG = (el) => {
        let text = editorState.getCurrentContent().getPlainText('\u0001').split('[');
        const textArr = [], wordsArr = [];
        const clue = [];
        let answerVar = 'rightWord';

        text.forEach((el, i) => {
            if (el.includes(']')) {
                el = el.split(']');
                textArr.push(el[1]);

                const infinitiveStart = el[0].indexOf('('), infinitiveEnd = el[0].indexOf(')');

                let infinitive = '';
                if (infinitiveStart !== -1 && infinitiveEnd !== -1) {
                    infinitive = el[0].substring(infinitiveStart + 1, infinitiveEnd) + ';';
                    el[0] = el[0].substring(infinitiveEnd + 2, el[0].length).trim();
                }

                let rightWord = '';
                el[0].split(';').forEach(elem => {
                    if (elem[0] === '{' && elem[elem.length - 1] === '}') {
                        rightWord = elem.replace('}', '').replace('{', '');
                    }
                });

                el[0] = el[0].replace('}', '').replace('{', '');
                wordsArr.push({
                    text: infinitive.trim() + el[0],
                    className: '',
                    val: infinitive.replace(';', ''),
                    rightWord
                });
            } else {
                if (el.includes('<')) {
                    el = el.split('<');
                    textArr.push(el[0]);
                } else {
                    textArr.push(el);
                }
            }
        });

        for (let i = 0; i < wordsArr.length; i++) {
            clue[i] = `${i + 1} -> ${wordsArr[i][answerVar]}`;
        }

        setIsRenderCode(true);
        setTextArr(textArr);
        setWordsArr(wordsArr);
        setClue(clue);        
    };

    const renderExerciseTitle = (el) => (
        <>
            {isPresentation ? customTitle :
                <input className="ExerciseContextInput"
                       value={el.customTitle}
                       onChange={e => setCustomTitle(e.target.value)}
                />
            }
        </>
    );

    const prepareLineBreakText = text => {
        const elems = text.split('\u0001');

        if (elems.length <= 1) {
            return text;
        }

        let res = '';

        for (let i = 0; i < elems.length; i++) {
            if (i < elems.length - 1) {
                res = res + elems[i] + '<br />';
            } else {
                res = res + elems[i];
            }
        }

        return (
            <span dangerouslySetInnerHTML={{ __html: res }} />
        );
    }


    return (
        <div key={`tab_${activeTabIndex}_item_${i}`}>
            {!options.isNotPresentation &&
                <div className={l.WIT_formlesson}>
                    <Editor
                        editorState={editorState}
                        onChange={e => onChangeEditor(e)}
                        placeholder="I [(be);{was};been;are] there"
                    />
                    <div className={l.WIT_button}>
                        <Button onClick={() => okHandlerWIG(el)} color="primary">Ok</Button>
                    </div>
                </div>}
            {isRenderCode &&
                <div className={l.WIT_example}>
                    <div className={l.wit_exercise_title}>
                        {renderExerciseTitle(el)}
                    </div>
                    {(options.userInfo.type === 'teacher' && clue && options.demoLesson) ?
                        <TeacherHelpInfo info={clue.map(elem => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
                    <div className={l.wit_body}>
                        {textArr.map((elem, index) => {
                            return (
                                <React.Fragment key={elem}>
                                    {prepareLineBreakText(elem)}
                                    {wordsArr[index] &&
                                        <div className={((!isHomework || isHomeworkFinish) ? wordsArr[index].className : '') +
                                            ' ' + l.WIT_selected_data   + ' ' + l.WIT_Input}>
                                            <Select
                                                disabled={(!isHomework || isHomeworkFinish) && (wordsArr[index].className === l.WIT_successful)}
                                                disableUnderline
                                                MenuProps={dropdownMenuProps}
                                                value={wordsArr[index].val}
                                                onChange={event => {
                                                    const className = (event.target.value === wordsArr[index].rightWord) ?
                                                        l.WIT_successful : l.WIT_error;

                                                    if (!className) {
                                                        options.updateHomeworkTasksInformation(uuid);
                                                    }

                                                    if (!wordsArr[index].className || wordsArr[index].className === l.WIT_successful) {

                                                        if (className === l.WIT_error) {
                                                            options.updateHomeworkErrors(uuid, 1);
                                                        }
                                                    }

                                                    if (wordsArr[index].className === l.WIT_error) {
                                                        if (className === l.WIT_successful) {
                                                            this.updateHomeworkErrors(uuid, -1);
                                                        }
                                                    }

                                                    wordsArr[index].className = className;
                                                    wordsArr[index].val = event.target.value;

                                                    options.sendMessage({
                                                        command: 'handleCWF',
                                                        data: {uniqId,i, className, index, val: event.target.value}
                                                    });
                                                    setWordsArr(wordsArr);

                                                    if (className === l.WIT_successful) {
                                                        const variant = random(studentAnswerSuccessSounds.length);
                                                        studentAnswerSuccessSounds[variant].play();
                                                        studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                                    } else {
                                                        studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                                    }
                                                }}
                                                input={(<Input classes={{}}/>)}
                                            >
                                                {wordsArr[index].text.split(';').map(item => (
                                                    <MenuItem key={item} value={item}> {item} </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    }
                                </React.Fragment>
                            );
                        })}</div>
                </div>}
        </div>
    );
}

export default CWF;