// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.object-dialog-contener {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  justify-content: center;
  align-items: center;
}
.object-dialog-contener .object-dialog {
  background-color: white;
  border-radius: 5px;
  padding: 2rem;
}
.object-dialog-contener .object-dialog .object-title {
  color: black;
  font-weight: bold;
}
.object-dialog-contener .object-dialog .object-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.object-dialog-contener .object-dialog .object-body .object-TextField {
  margin: 7px;
}
.object-dialog-contener .object-dialog .object-body .MuiInputLabel-root {
  transform: translateY(5px) scale(0.75);
  transform-origin: top left;
}
.object-dialog-contener .object-dialog .object-body .MuiInputLabel-root.MuiInputLabel-shrink {
  transform: translateY(-10px) scale(0.75);
  transform-origin: top left;
}
.object-dialog-contener .object-dialog .object-footer {
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ObjectDialog.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAEI;EACI,uBAAA;EACA,kBAAA;EACA,aAAA;AAAR;AAEQ;EACI,YAAA;EACA,iBAAA;AAAZ;AAGQ;EACI,aAAA;EACA,sBAAA;EACA,aAAA;AADZ;AAGY;EACI,WAAA;AADhB;AAIY;EACI,sCAAA;EACA,0BAAA;AAFhB;AAKY;EACI,wCAAA;EACA,0BAAA;AAHhB;AAMQ;EACI,aAAA;EACA,yBAAA;AAJZ","sourcesContent":["\n.object-dialog-contener {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 9999;\n    justify-content: center;\n    align-items: center;\n\n    .object-dialog {\n        background-color: white;\n        border-radius: 5px;\n        padding: 2rem;\n\n        .object-title {\n            color: black;\n            font-weight: bold;\n        }\n\n        .object-body {\n            display: flex;\n            flex-direction: column;\n            padding: 10px;\n\n            .object-TextField {\n                margin: 7px;\n            }\n\n            .MuiInputLabel-root {\n                transform: translateY(5px) scale(0.75);\n                transform-origin: top left;\n              }\n              \n            .MuiInputLabel-root.MuiInputLabel-shrink {\n                transform: translateY(-10px) scale(0.75);\n                transform-origin: top left;\n              }\n        }\n        .object-footer {\n            display: flex;\n            justify-content: flex-end;\n\n            .object-ControlButtons{}\n        }\n    }    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
