import React, { useState, useEffect } from 'react';
import CRUDGrid from '../../../common/CRUDGrid';
import callApi from "../../../../utils/call-api";
import ObjectDialog from '../../../common/ObjectDialog';
import PricePackageInfo from './PricePackageInfo';
import './PricePackages.scss';
import { MenuItem, Select, InputLabel, Button } from '@mui/material';



const PricePackages = ({})=>{
    const [packeges, setPackeges] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [ageTypes, setAgeTypes] = useState([]);
    const [lessonTypes, setLessonTypes] = useState([]);
    const [lessonDurations, setLessonDurations] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({}); //SelectedPackage
    const [prices, setPrices] = useState([]);
    const [languageFilter, setLanguageFilter] = useState('');
    const [ageTypeFilter, setAgeTypeFilter] = useState('');
    const [lessonTypeFilter, setLessonTypeFilter] = useState('');
    const [lessonDurationFilter, setLessonDurationFilter] = useState('');

    useEffect(() => {
        loadLanguages();
        loadAgeTypes();
        loadLessonDurations();
        loadLessonTypes();
        loadPackages();
    }, [])

    const loadLanguages=()=>{
        callApi("guide/languages","GET")
        .then(data=>{
            setLanguages(data.data.languages||[]);
        }).catch(err=>{
            console.log(err);
            setLanguages([]);
        });
    }

    const loadAgeTypes=()=>{
        callApi("guide/ageTypes", "GET")
        .then(data=>{
            setAgeTypes(data.data.ageTypes||[]);
        })
        .catch(err=>{
            console.log(err);
            setAgeTypes([]);
        });
    }

    const loadLessonTypes=()=>{
        callApi("guide/lessonTypes", "GET")
        .then(data=>{
            setLessonTypes(data.data.lessonTypes||[]);
        })
        .catch(err=>{
            console.log(err);
            setLessonTypes([]);
        });
    }

    const loadLessonDurations=()=>{
        callApi("guide/lessonDurations", "GET")
        .then(data=>{
            setLessonDurations(data.data.lessonDurations||[]);
        })
        .catch(err=>{
            console.log(err);
            setLessonDurations([]);
        });
    }

    const loadPackages=()=>{
        callApi("price/getPackages","GET")
        .then(data=>{
            console.log(data);
            setPackeges(data.data.packages||[]);
        }).catch(err=>{
            console.log(err);
            setPackeges([]);
        });
    }

    const loadPriceByPackage = (packageInfo) => {
        packageInfo = packageInfo||selectedPackage;        
        callApi("price/getPricesByPackageId", "GET", {packageId: packageInfo.id})
        .then(data=>{
            console.log(data);
            const priceList = data.data.prices.map(item=>{
                item.startDate = new Date(item.startDate*1000);
                item.endDate = item.endDate? new Date(item.endDate*1000):"";
                return item;
            });
            setPrices(priceList||[]);
        }).catch(err=>{
            console.log(err);
            setPrices([]);
        });
    }

    const savePrice = async (priceInfo) => {
        priceInfo.package = selectedPackage.id;
        if(priceInfo.startDate){
            priceInfo.startDate = (Date.parse(priceInfo.startDate))/1000;
        }
        if(priceInfo.endDate){
            priceInfo.endDate = (Date.parse(priceInfo.endDate))/1000;
        }
        await callApi("price/createPrice", "POST", priceInfo)
        .then(loadPriceByPackage(selectedPackage));        
    }

    /**
     * @function addPackage
     * @description Opens the package modal.
     * @example
     * addPackage()
     */
    const addPackage=()=>{
        setShowModal(true)
    }
    
    const onDialogOK = (packageInfo)=>{
        setShowModal(false);
        if(packageInfo.startDate){
            packageInfo.startDate = (Date.parse(packageInfo.startDate))/1000;
        }
        if(packageInfo.endDate){
            packageInfo.endDate = (Date.parse(packageInfo.endDate))/1000;
        }
        packageInfo.language = languages.find(item=>item.id===packageInfo.language);
        packageInfo.ageType = ageTypes.find(item=>item.id===packageInfo.ageType);
        packageInfo.lessonType = lessonTypes.find(item=>item.id===packageInfo.lessonType);
        packageInfo.duration = lessonDurations.find(item=>item.id===packageInfo.duration);
        packageInfo.permanent = packageInfo.permanent==="on"?true:false;
        savePackage(packageInfo)
        .then(()=>loadPackages())
        .catch(err=>console.log(err));
    }

    const onDialogCansel = ()=>{
        setShowModal(false);
    }

    const savePackage=(packageInfo)=>{
        
        return callApi("price/package", "POST", packageInfo);
    }

    const handlePackageClick = (packageInfo) => {
        setSelectedPackage(packageInfo);
        loadPriceByPackage(packageInfo)
      };

    return (<>
        <div>
            <div className='PricePackagesContext'>
                <div className='PricePackagesMenu'>
                    <Button className='PricePackagesMenuButton'
                        onClick={addPackage} 
                    >
                        Добавить пакет
                    </Button>
                    <div>
                    <InputLabel id={`language-select-label`} className='PricePackageMenuInputLabel'>Язык</InputLabel>
                    <Select
                        labelId={`language-select-label`}
                        id={`select_language`}
                        value={languageFilter}
                        label={"Язык"}
                        onChange={val => setLanguageFilter(val.target.value)}
                        className='PricePackageMenuSelect'>
                        {languages.map(item=><MenuItem value={item.id}>{item.name}</MenuItem>)}
                    </Select></div>
                    <div>
                    <InputLabel id={`duration-select-label`} className='PricePackageMenuInputLabel'>Продолжительность</InputLabel>
                    <Select
                        labelId={`duration-select-label`}
                        id={`select_duration`}
                        value={lessonDurationFilter}
                        label={"Продолжительность"}
                        onChange={val => setLessonDurationFilter(val.target.value)}
                        className='PricePackageMenuSelect'>
                        {lessonDurations.map(item=><MenuItem value={item.id}>{item.description}</MenuItem>)}
                    </Select></div>
                    <div>
                    <InputLabel id={`lessonType-select-label`} className='PricePackageMenuInputLabel'>Тип урока</InputLabel>
                    <Select
                        labelId={`lessonType-select-label`}
                        id={`select_lessonType`}
                        value={lessonTypeFilter}
                        label={"Тип урока"}
                        onChange={val => setLessonTypeFilter(val.target.value)}
                        className='PricePackageMenuSelect'>
                        {lessonTypes.map(item=><MenuItem value={item.id}>{item.description}</MenuItem>)}
                    </Select></div>
                    <div>
                    <InputLabel id={`ageType-select-label`} className='PricePackageMenuInputLabel'>Возростная каттегория</InputLabel>
                    <Select
                        labelId={`ageType-select-label`}
                        id={`select_ageType`}
                        value={ageTypeFilter}
                        label={"Возростная каттегория"}
                        onChange={val => setAgeTypeFilter(val.target.value)}
                        className='PricePackageMenuSelect'>
                        {ageTypes.map(item=><MenuItem value={item.id}>{item.description}</MenuItem>)}
                    </Select></div>                    
                </div>
                <div className='PricePackagesList'>
                    {packeges
                    .filter(item=>languageFilter===""||item.language.id===languageFilter)
                    .filter(item=>lessonDurationFilter===""||item.duration.id===lessonDurationFilter)    
                    .filter(item=>lessonTypeFilter===""||item.lessonType.id===lessonTypeFilter)
                    .filter(item=>ageTypeFilter===""||item.ageType.id===ageTypeFilter)
                    .map((item, index)=><PricePackageInfo
                        key={index}
                        pricePackageInfo = {item}
                        onClickCallBack={handlePackageClick}
                        />)}
                </div>
            </div>
            <div className='PricePackagesContext'>
                <div className="PricePackageInfo">
                    
                    <label>{`Язык: ${selectedPackage.language? selectedPackage.language.name: ""}`}</label>
                    <label>{`Продолжительность: ${selectedPackage.duration? selectedPackage.duration.description: ""}`}</label>
                    <label>{`Тип урока: ${selectedPackage.lessonType? selectedPackage.lessonType.description: ""}`}</label>
                    <label>{`Возрастная группа: ${selectedPackage.ageType? selectedPackage.ageType.description: ""}`}</label>
                    <label>{`Действует с: ${selectedPackage.startDate? new Date(selectedPackage.startDate*1000).toDateString(): ""} по ${selectedPackage.endDate? new Date(selectedPackage.endDate*1000).toDateString(): " - "}`}</label>
                </div>
            
            <CRUDGrid
                options = {{header: {refreshButton:true, removeButton:false, addButton:true, editButton:false},
                    title:"Цены на Пакет",
                    columns:[{field: "id", headerName: "id", type: "number", viseble:false},
                    {field: "price", headerName: "Цена", type: "number", viseble:true, defaultValue: 0, required: true, validate:{min: 0}}, 
                    {field: "discount", headerName: "Скидка", type: "number", viseble:true, defaultValue: 0,required: true, validate: {min: 0, max: 100}},
                    {field: "startDate", headerName: "Дата начала", type: "date", viseble:true, required:true},
                    {field: "endDate", headerName: "Дата окончания", type: "date", viseble:true},],
                    exceptionTitle: [
                        {errorCode: 404, message: "Packages.id.notFound", title: `Пакет ${selectedPackage.name? selectedPackage.name: ""}\n не найден`},
                        {errorCode:400, message: "Prices.price.incorrect", tittle: `Цена не корректна`},
                        {errorCode:404, message: "Prices.price.missing", tittle: `Цена не указана`},
                        {errorCode:400, message: "Prices.discount.incorrect", tittle: `Скидка не корректна`},
                        {errorCode:404, message: "Prices.discount.missing", tittle: `Скидка не указана`},
                        {errorCode:400, message: "Prices.package.incorrect", tittle: `Выберите пакет`},
                        {errorCode:404, message: "Prices.package.missing", tittle: `Выберите пакет`},
                        {errorCode:400, message: "Prices.startDate.incorrect", tittle: `Дата начала не корректна`},
                        {errorCode:404, message: "Prices.startDate.missing", tittle: `Дата начала не указана`},
                        {errorCode:400, message: "Prices.startDate.notInPackageInterval", tittle: `Дата начала не попала в интервал c ${selectedPackage.startDate? new Date(selectedPackage.startDate*1000).toDateString(): ""} по ${selectedPackage.endDate? new Date(selectedPackage.endDate*1000).toDateString(): " - "}`},
                        {errorCode:400, message: "Prices.endDate.incorrect", tittle: `Дата конца не корректна`},
                        {errorCode:400, message: "Prices.endDate.notInPackageInterval", tittle: `Дата конца не попадает в интервал c ${selectedPackage.startDate? new Date(selectedPackage.startDate*1000).toDateString(): ""} по ${selectedPackage.endDate? new Date(selectedPackage.endDate*1000).toDateString(): " - "}`}
                    ]
                }}
                data={prices||[]}
                onRefreshCallBack={loadPriceByPackage}
                onAddCallBack={savePrice}
                

            ></CRUDGrid>
            </div>
        </div>
        {showModal && 
            <ObjectDialog
                options={{title: "Создать Пакет",
                    columns:[{field: "id", headerName: "id", type: "number", viseble:false},                    
                    {field: "language", headerName: "Язык", type: "combobox", viseble:true, list: languages, objectId: "id", objectValue: "name", required: true},
                    {field: "duration", headerName: "Продолжительность", type: "combobox", viseble:true, list: lessonDurations, objectId: "id", objectValue: "description", required: true},
                    {field: "lessonType", headerName: "Тип Урока", type: "combobox", viseble:true, list: lessonTypes, objectId: "id", objectValue: "description", required: true},
                    {field: "ageType", headerName: "Возрастная группа", type: "combobox", viseble:true, list: ageTypes, objectId: "id", objectValue: "description", required: true},
                    {field: "count", headerName: "Количество занятий", type: "number", viseble:true, default: 0, required: true, validate: {min: 0}},
                    {field: "permanent", headerName: "Постоянный", type: "boolean", viseble:true, default: true, required: true},
                    {field: "hit", headerName: "Хит", type: "boolean", viseble:true, default: true, required: true},
                    {field: "startDate", headerName: "Начало действия", type: "date", viseble:true, required: true},
                    {field: "endDate", headerName: "Конец действия", type: "date", viseble:true}
                ],
                exceptionTitle: [
                    {errorCode: 404, message: "Packages.message.name.missing", title: `Не указано название пакета`},
                    {errorCode:400, message: "Packages.message.name.incorrect", tittle: `Не корректное название пакета`},
                    {errorCode:404, message: "Languages.message.id.missing", tittle: `Не указан язык`},
                    {errorCode:400, message: "Languages.message.id.incorrect", tittle: `Не корректный язык`},
                    {errorCode:404, message: "Packages.message.language.missing", tittle: `Не указан язык`},
                    {errorCode:400, message: "LessonType.message.id.incorrect", tittle: `Не корректный тип урока`},
                    {errorCode:404, message: "LessonType.message.id.missing", tittle: `Не указан тип урока`},
                    {errorCode:404, message: "Packages.message.lessonType.missing", tittle: `Не указан тип урока`},
                    {errorCode:404, message: "LessonDuration.message.id.missing", tittle: `Не указана продолжительность урока`},
                    {errorCode:400, message: "LessonDuration.message.id.incorrect", tittle: `Не корректная продолжительность урока`},
                    {errorCode:404, message: "Packages.message.lessonDuration.missing", tittle: `Не указана продолжительность урока`},
                    {errorCode:400, message: "AgeType.message.id.incorrect", tittle: `Не корректная возрастная группа`},
                    {errorCode:404, message: "AgeType.message.id.missing", tittle: `Не указана возрастная группа`},
                    {errorCode:404, message: "Packages.message.ageType.missing", tittle: `Не указана возрастная группа`},
                    {errorCode:400, message: "Packages.message.count.incorrect", tittle: `Не корректное количество занятий`},
                    {errorCode:404, message: "Packages.message.count.missing", tittle: `Не указано количество занятий`},
                    {errorCode:400, message: "Packages.message.startDate.incorrect", tittle: `Не корректная дата начала`},
                    {errorCode:404, message: "Packages.message.startDate.missing", tittle: `Не указана дата начала`},
                    {errorCode:400, message: "Packages.message.endDate.lessStart", tittle: `Дата окончания не может быть меньше даты начала`},
                    {errorCode:400, message: "Packages.message.endDate.incorrect", tittle: `Не корректная дата окончания`}
                ]
                }}
                value={{}}
                onOkClickCallBack={onDialogOK}
                onCanselClickCallBack={onDialogCansel}
            >
            </ObjectDialog>}
    </>);
};

export default PricePackages;

