export const ADD_DOMAIN_REQUEST = 'ADD_DOMAIN_REQUEST';
export const ADD_DOMAIN_SUCCESS = 'ADD_DOMAIN_SUCCESS';
export const ADD_DOMAIN_FAILURE = 'ADD_DOMAIN_FAILURE';

export const EDIT_DOMAIN_REQUEST = 'EDIT_DOMAIN_REQUEST';
export const EDIT_DOMAIN_SUCCESS = 'EDIT_DOMAIN_SUCCESS';
export const EDIT_DOMAIN_FAILURE = 'EDIT_DOMAIN_FAILURE';

export const UPDATE_DOMAIN_REQUEST = 'UPDATE_DOMAIN_REQUEST';
export const UPDATE_DOMAIN_SUCCESS = 'UPDATE_DOMAIN_SUCCESS';
export const UPDATE_DOMAIN_FAILURE = 'UPDATE_DOMAIN_FAILURE';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
