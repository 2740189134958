import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import signupKids from './imgs/signup-kids.png';
import signupAdult from './imgs/signup-adult.png';
import TextField from './TextField';
import { Alert } from 'reactstrap';
import { validateEmail, validatePhone } from './helpers';
import InfoDialog from '../admin/common/InfoDialog';
import { signup, HideDialog } from '../../actions';
import history from '../../utils/history';
import IntlMessages from "../../utils/IntlMessages";
import lang from "../../utils/lang";

const ANIMATION_PERIOD_1 = 600; // ms
const STEP_DEFAULT = 'default';
const STEP_KIDS = 'kids';
const STEP_ADULT = 'adult';

export default function SignUp ({ isMobileDevice, className = '', updateAuthType = () => {} }) {
    const dispatch = useDispatch();

    const errorMessage = useSelector(state => state.auth.showMessage);
    const alertMessage = useSelector(state => state.auth.alertMessage);
    const dialogForm = useSelector(state => state.dialog.dialog);

    const [showOkModal, setShowOkModal] = useState(true);
    const [stepState, setStepState] = useState(STEP_DEFAULT);
    const [userName, setUserName] = useState('');
    const [userSurname, setUserSurname] = useState('');
    const [email, setEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [password, setPassword] = useState('');
    const [privacy, setPrivacy] = useState(false);

    const [leftImgClass, setLeftImgClass] = useState('');
    const [rightImgClass, setRightImgClass] = useState('');
    const [leftFormClass, setLeftFormClass] = useState('AuthComponentHide toLeftAndHide2');
    const [rightFormClass, setRightFormClass]  = useState('AuthComponentHide toRightAndHide');

    const [mobileImgsClass, setMobileImgsClass] = useState('');
    const [mobileFormClass, setMobileFormClass] = useState('scaleAndHide');

    const isValidForm = userName && userSurname &&  validateEmail(email) && userPhone && password && privacy;

    const renderForm = () => {
        return (
            <div className="AuthComponentPathForm AuthComponentPathFormSignup">
                <TextField
                    label={lang("Имя")}
                    value={userName}
                    handleInputChange={val => setUserName(val)}
                />
                <TextField
                    label={lang("Фамилия")}
                    value={userSurname}
                    handleInputChange={val => setUserSurname(val)}
                />
                <TextField
                    label="Email"
                    value={email}
                    handleInputChange={val => setEmail(val)}
                />
                <TextField
                    label={lang("Телефон")}
                    value={userPhone}
                    handleInputChange={val => {
                        if (validatePhone(val)) {
                            setUserPhone(val)
                        }
                    }}
                />
                <TextField
                    type="password"
                    label={lang("Пароль")}
                    value={password}
                    handleInputChange={val => setPassword(val)}
                />
                <div className="AuthComponentPrivacyManagement"
                     onClick={() => setPrivacy(state => !state)}
                >
                    <div
                        className={privacy ?
                            "AuthComponentPrivacyManagementCheckboxActive":
                            "AuthComponentPrivacyManagementCheckbox"}
                    />
                    <div>
                        <IntlMessages id="Я согласен с " /><a target="_blank" href="https://www.epiclang.com/privacy"><IntlMessages id="политикой конфиденциальности" /></a>
                    </div>

                </div>
                <div className="AuthComponentPathSigninLinks">
                    <div className="AuthComponentPathSigninLinksButton"
                        style={isValidForm ? {} : { pointerEvents: 'none', filter: 'grayscale(90%)' }}
                         onClick={() => {
                             dispatch(signup({
                                 email,
                                 password,
                                 displayName: userName,
                                 displaySurname: userSurname,
                                 ageType: (stepState === STEP_ADULT) ? 2 : 1,
                                 phoneNumber: userPhone,
                             }));
                         }}
                    >
                        <IntlMessages id="ЗАРЕГИСТРИРОВАТЬСЯ" />
                    </div>
                    <div className="AuthComponentPathSigninLinksLink"
                         onClick={() => updateAuthType()}
                    >
                        <IntlMessages id="Я уже зарегистрирован" />
                    </div>
                </div>
            </div>
        );
    }

    const renderImgBlock = imgBlockNum => {
        const isFirstBlock = imgBlockNum === 1;

        return (
            <div style={{position: 'absolute', bottom: 0 }}
                 // TODO
                 className={isFirstBlock ? leftImgClass: rightImgClass}
                 onClick={() => {
                     if (stepState === STEP_DEFAULT) {
                         setStepState(isFirstBlock ? STEP_KIDS : STEP_ADULT);

                         if (!isMobileDevice) {
                             isFirstBlock ? setRightImgClass('toRightAndHide') : setLeftImgClass('toLeftAndHide');
                             isFirstBlock ? setRightFormClass('toLeftAndShow') : setLeftFormClass('toRightAndShow2');

                             setTimeout(() => {
                                 isFirstBlock ?
                                     setRightImgClass(state => (state + ' AuthComponentHide')) :
                                     setLeftImgClass(state => (state + ' AuthComponentHide'));
                             }, ANIMATION_PERIOD_1);
                         } else {
                             setMobileImgsClass('scaleAndHide');

                             setTimeout(() => {
                                 setMobileFormClass('scaleAndShow');
                             }, ANIMATION_PERIOD_1);
                         }

                     } else if (stepState !== STEP_DEFAULT) {
                         setStepState(STEP_DEFAULT);

                         if (!isMobileDevice) {
                             isFirstBlock ? setRightImgClass('toLeftAndShow') : setLeftImgClass('toRightAndShow');
                             isFirstBlock ? setRightFormClass('toRightAndHide') : setLeftFormClass('toLeftAndHide2');

                             setTimeout(() => {
                                 isFirstBlock ?
                                     setRightFormClass(state => (state + ' AuthComponentHide')) :
                                     setLeftFormClass(state => (state + ' AuthComponentHide'))

                             }, ANIMATION_PERIOD_1);
                         } else {
                             setMobileImgsClass('scaleAndHide');

                             setTimeout(() => {
                                 setMobileFormClass('scaleAndShow');
                             }, ANIMATION_PERIOD_1);
                         }
                     }
                 }}
            >
                <div>
                    <div className="AuthComponentPathSignupBlue">
                        {isMobileDevice &&
                            <div className="AuthComponentPathSignupTextMobile">
                                {isFirstBlock ?
                                    <><IntlMessages id="Английский" /><br/><IntlMessages id="для детей" /><br/><IntlMessages id="от 4 до 16 лет" /></> :
                                    <><IntlMessages id="Английский" /><br/><IntlMessages id="для" /><br/><IntlMessages id="взрослых" /></>
                                }
                            </div>
                        }
                    </div>
                    <img alt="" src={isFirstBlock ? signupKids : signupAdult}/>
                </div>
                <div className="AuthComponentPathSignupText">
                    {isFirstBlock ?
                        <><IntlMessages id="Английский для" /><br/><IntlMessages id="детей от 4 до 16 лет" /></> :
                        <><IntlMessages id="Английский для" /><br/><IntlMessages id="взрослых" /></>
                    }
                </div>
            </div>
        );
    }

   const renderAlert = () => {
        if (!errorMessage) return (<></>);

        return (
            <Alert className="shadow-lg bg-danger text-white">
                {(alertMessage === 'Request failed with status code 400') ?
                    lang('Почта или пароль некорректны. Длина пароля должна быть от 4 до 32 символов') :
                    lang('Произошла ошибка. Обратитесь в службу поддержки')
                }
            </Alert>
        )
   }

    return (
        <div className={"AuthComponentPathSignup " + className} >
            {dialogForm && showOkModal &&
                <InfoDialog
                    HideDialog={() => {
                        setShowOkModal(false);
                        dispatch(HideDialog());
                        return setTimeout(() => history.push('/'), 200);
                    }}
                    title={lang("Регистрация прошла успешно")}
                    text={lang("Теперь вы можете войти на свою личную страницу")}
                />
            }
            {!isMobileDevice &&
            <>
                <div className={"AuthComponentPathSignupChild" + (stepState !== STEP_DEFAULT ? " AuthComponentPathSignupForm" : "")}>
                    {renderImgBlock(1)}
                    <div className={leftFormClass}>
                        {renderAlert()}
                        {renderForm()}
                    </div>
                </div>
                <div className={"AuthComponentPathSignupAdult" + (stepState !== STEP_DEFAULT ? " AuthComponentPathSignupForm" : "")}>
                    {renderImgBlock(2)}
                    <div className={rightFormClass}>
                        {renderAlert()}
                        {renderForm()}
                    </div>
                </div>
            </>
            }

            {isMobileDevice &&
                <>
                    <div className={mobileImgsClass} style={{ position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <div className={"AuthComponentPathSignupChild" + (stepState !== STEP_DEFAULT ? " AuthComponentPathSignupForm" : "")}>
                            {renderImgBlock(1)}
                        </div>
                        <div className={"AuthComponentPathSignupAdult" + (stepState !== STEP_DEFAULT ? " AuthComponentPathSignupForm" : "")}>
                            {renderImgBlock(2)}
                        </div>
                    </div>
                    <div className={mobileFormClass}>
                        {renderAlert()}
                        {renderForm()}
                    </div>
                </>
            }
        </div>
    );
}
