import React, { useState, useCallback, useEffect } from 'react';
import Dustbin from './Dustbin';
import Box from '../n1/Box';
import update from 'immutability-helper';
import l from '../main/LessonEditor.module.scss';
import random from '../../../utils/generator/numbers';

import { studentAnswerWrongAudio, studentAnswerSuccessSounds } from '../helpers/SoundsHelper';

export const Container2 = ({
    isHomework,
    isHomeworkFinish,
    updateHomeworkErrors = () => {},
    updateHomeworkTasksInformation = () => {},
    resDustbin,
    resBox,
    socket,
    uniqId,
    socketMessage = {},
    val,
}) => {
    const [dustbins, setDustbins] = useState(resDustbin);
    const [boxes] = useState(resBox);
    const [previousMessage, setPreviousMessage] = useState('');
    const [droppedBoxNames, setDroppedBoxNames] = useState(resBox.map(() => false));

    const  isDropped = boxIndex => (!!droppedBoxNames[boxIndex]);

    useEffect(() => {
        updateHomeworkTasksInformation(uniqId, boxes.length);
    }, []);

    const handleDrop = useCallback(
        (dustbinName, dustbinIndex, item, flag = true) => {
            if (flag) {
                socket.send(JSON.stringify({
                    command: 'handleDrop2',
                    data: {
                        dustbinIndex,
                        item,
                        uniqId,
                        dustbinName,
                    },
                    val,
                }));
            }

            const { name, boxIndex } = item;

            if (dustbinName === name) {
                setDroppedBoxNames(
                    update(droppedBoxNames, {[boxIndex]: {$set: true}}),
                );

                const variant = random(studentAnswerSuccessSounds.length);
                studentAnswerSuccessSounds[variant].play();
                studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
            } else {
                studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
            }

            if (isHomework) {
                if (!dustbins[dustbinIndex].lastDroppedItem) {
                    updateHomeworkTasksInformation(uniqId);

                    if (dustbinName !== name) {
                        updateHomeworkErrors(uniqId, 1);
                        item.isError = true;
                    }
                } else {
                    item.isError = dustbins[dustbinIndex].lastDroppedItem.isError;

                    if (dustbins[dustbinIndex].lastDroppedItem.name !== name) {
                        if (dustbins[dustbinIndex].lastDroppedItem.isError && dustbinName === name) {
                            updateHomeworkErrors(uniqId, -1);
                            item.isError = false;
                        } else if (!dustbins[dustbinIndex].lastDroppedItem.isError && dustbinName !== name) {
                            updateHomeworkErrors(uniqId, 1);
                            item.isError = true;
                        }
                    }
                }
            }

            setDustbins(
                update(dustbins, {
                    [dustbinIndex]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins],
    );

    if ((previousMessage !== JSON.stringify(socketMessage)) && (socketMessage.command === 'handleDrop2') &&
        (uniqId === socketMessage.data.uniqId)) {
        setPreviousMessage(JSON.stringify(socketMessage));
        handleDrop(socketMessage.data.dustbinName, socketMessage.data.dustbinIndex, socketMessage.data.item, false);
    }

    return (
        <div className="tests_flex">
            <div className={l.dad2_example_answer_item}>
                {dustbins.map(({ accepts, lastDroppedItem, i }, index) => (
                    <Dustbin
                        isHomework={isHomework}
                        isHomeworkFinish={isHomeworkFinish}
                        dustbinName={i}
                        accepts={accepts}
                        lastDroppedItem={lastDroppedItem}
                        onDrop={item => handleDrop(i, index, item)}
                        key={index}
                    />
                ))}
            </div>

            <div className={l.dad2_example_data_item}>
                {boxes.map(({ name, type }, index) => (
                    <Box
                        isHomework={isHomework}
                        boxIndex={index}
                        name={name}
                        type={type}
                        isDropped={isDropped(index)}
                        key={index}
                    />
                ))}
            </div>
        </div>
    )
};
