import React, { useState, useRef } from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Editor, EditorState, ContentState } from 'draft-js'; // convertToRaw
import 'draft-js/dist/Draft.css';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { Preview } from 'react-dnd-preview';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button/Button';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardMedia from '@material-ui/core/CardMedia';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Carousel from 'react-elastic-carousel';
import P5Wrapper from 'react-p5-wrapper';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch/Switch';
import IconDictionary from '../../../assets/images/icons/dictionary_white.svg';
import draw from '../draw/Draw';
import callApi from '../../../utils/call-api';
import ReactCodeInput from '../2fa/TwoFa';
import { dialogSetWhiteboard, dialogSetImgWhiteboard } from '../../../actions/dialog';
import { Container } from '../n1/Container';
import { ContainerSpecial } from '../n1/ContainerSpecial';
import { ContainerFor2Columns } from '../n1/ContainerFor2Columns';
import { Container2 } from '../n2/Container';
import { DnDGrid } from '../DnDGrid/Container';
import { v1 } from '../../../utils/uuid';
import config from '../../../config';
import l from './LessonEditor.module.scss';
import { openDict, selectedWordToTranslate } from '../../../actions/dictionary';
import random from '../../../utils/generator/numbers';
import { studentAnswerWrongAudio, studentAnswerSuccessSounds } from '../helpers/SoundsHelper';
import platformAccessSound from '../../../assets/sounds/platform_access.mp3';
import blockEndSound from '../../../assets/sounds/block_end.mp3';
import t from '../../admin/Teacher/Teacher.module.scss';
import IconWhiteboard from '../../../assets/images/icons/whiteboard.svg';
import ImgsSizeHelper from '../helpers/ImgsSizeHelper';
import PdfSizeHelper from '../helpers/PdfSizeHelper';
import { switchArrayElements } from '../helpers/arrayForRandomSimulation';
import lang from "../../../utils/lang";
import VideoPlayer from './Player';
// import { Editor as EditorV2 } from 'react-draft-wysiwyg';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const platformAccessAudio = new Audio();
platformAccessAudio.src = platformAccessSound;

const blockEndAudio = new Audio();
blockEndAudio.src = blockEndSound;

const TeacherHelpInfo = ({info}) => {
    const [ isHelpInfoOpen, setIsHelpInfoOpen ] = useState(false);

    return (
        <div className="">
            <Button onClick={() => {setIsHelpInfoOpen(!isHelpInfoOpen)}} className="TeacherHelpInfoButton"
                    color="primary" variant="contained">
                {isHelpInfoOpen ? lang('Скрыть ответ') :  lang('Показать ответ')}
            </Button>
            <div style={{display: (isHelpInfoOpen ? 'block' : 'none')}}>{info}</div>
        </div>
    );
}

const GRID_IMG_INPUT_MAX_LETTERS = 25;

const StyledAppBar = withStyles({
    root: {
        'color': '#000',
        'backgroundColor': '#fff',
        'border-top-right-radius': '30px',
        'border-top-left-radius': '30px',
        'display': 'flex',
        'justify-content': 'space-around',
    },
    label: { textTransform: 'capitalize' },
})(AppBar);

const StyledTab = withStyles({
    root: {}
})(Tab);

const StyledImgGrid = withStyles({
    root: { borderRadius: '50px', maxHeight: '50vh', maxWidth: '980px', boxShadow: '0px 2px 3px 0px rgba(34, 60, 80, 0.2)'}
})(CardMedia);

const studentsData = [
    { id: 0, type: 1, name: 'johnny', login: 'bigJ', text: 'hi i just spend time well' },
    { id: 1, name: 'ja', login: 'ja123', text: 'I smoked weed all summer' },
    { id: 2, name: 'Bob', login: 'Robert', text: 'I know what you did last summer' }
];

const dropdownMenuProps = {
    padding: { padding: '0' },
    disablePadding: true,
    getContentAnchorEl: null,
    anchorOrigin: { vertical: "bottom", horizontal: "left" },
};

// var result = document.getElementById("app-site")
// var info = result.getBoundingClientRect()

// console.log('infoinfoinfoinfo', info)
// var dpi_x = document.getElementById('app-site').offsetWidth;
// console.log('infoinfoinfoinfo2', dpi_x)

const generatePreview = ({itemType, item, style}) => {
    return <div className={item.className} style={style}>{item.name}</div>
}

class LessonEditor extends React.Component {
    prepareLessonItems = socket => {
        const { lesson } = this.props;
        let tabs;

        if (lesson && lesson.page && Array.isArray(lesson.page.tabs)) {
            tabs = lesson.page.tabs.map((tab, tabIndex) => {
                tab.tabPanel.lessonItems = tab.tabPanel.lessonItems.map((el, lessonItemIndex) => {
                    if (el.editorState) {
                        let currentVal = el.currentVal || '';
                        if (el.name === 'TeachersNote' || el.name === 'Editor') {
                            currentVal =  JSON.stringify(el.currentVal || '').replaceAll('u0001', 'n');
                            currentVal = JSON.parse(currentVal) || '';
                        }

                        return {
                            ...el,
                            editorState: EditorState.createWithContent(ContentState.createFromText(currentVal)),
                            itemCreateFunction: this.getItemCreateFunction(el, 0, false),
                            isText: false,
                            isRenderCode: false,
                        }
                    }

                    if (el.isDrawing) {
                        return {
                            ...el,
                            whiteboard: draw(
                                socket || { send: () => null },
                                el.uuid,
                                this.prepareInfo(),
                                false,
                                () => (this.state.isUserHaveNotControl && ['student'].includes(this.props.userInfo.userInfo.type)),
                                () => {
                                    return tabs[tabIndex].tabPanel.lessonItems[lessonItemIndex].canvasImgContainerRef;
                                }
                            ),
                            itemCreateFunction: this.getItemCreateFunction(el, 0, false),
                            isText: false,
                            isRenderCode: false,
                            canvasImgContainerRef: React.createRef(),
                        };
                    }

                    return {
                        ...el,
                        itemCreateFunction: this.getItemCreateFunction(el, 0, false),
                        isText: false,
                        isRenderCode: false,
                    };
                });

                return tab;
            });
        }

        return tabs;
    };

    isPresentation = (this.props.lessonType === 'presentation');
    isNotPresentation = (this.props.lessonType !== 'presentation');

    constructor(props) {
        super(props);

        this.LessonEditorComponentRef = React.createRef();

        const { lesson } = this.props;
        const socket = this.props.socket || {
            addEventListener: () => {
            }, send: () => {
            }
        }; // TODO
        let lessonItems = this.prepareLessonItems(socket);

        if (socket) {
            socket.addEventListener("message", e => {
                try {
                    const data = JSON.parse(e.data);

                    if (data.command === 'sketchResize') {
                        const sketchWidth = data.data.sketchWidth;
                        if (sketchWidth) {
                            this.setState({ sketchWidth });
                        }
                    }

                    if (data.command === 'onControlledDrag') {
                        const { x, y } = data.data.position;
                        this.setState({ controlledPosition: { x, y } });
                    }

                    if (data.command === 'dialogSetWhiteboard') {
                        setTimeout(() => {
                            this.props.dialogSetWhiteboard(data.data);
                        }, 1000);
                    }

                    if (data.command === 'dialogChangeTab') {
                        const { tabs } = this.state;
                        let i = data.data || 1;
                        const lessonItems = tabs[i - 1];

                        if (!lessonItems) i = 1;

                        this.setState({ activeTabNumber: i });
                    }

                    // if (data.command === 'setLessonItems') {
                    //     this.setState({isRerend: true}, () => {
                    //         this.setState({...data.data, socket, isRerend: false});
                    //     });
                    // }

                    if (data.command === 'sendTruesLiesAnswer') {
                        const { tabs } = this.state;
                        tabs[data.data.activeTabIndex].tabPanel.lessonItems[data.data.lessonItemIndex]
                            .items[data.data.itemIndex].studentAnswer = data.data.answer;

                        const trueFalseSlider = tabs[data.data.activeTabIndex].tabPanel.lessonItems[data.data.lessonItemIndex]
                            .items[data.data.itemIndex];

                        if (typeof trueFalseSlider.studentAnswer === 'boolean') {
                            if (trueFalseSlider.studentAnswer === true) {
                                if (trueFalseSlider.isCorrect) {
                                    const variant = random(studentAnswerSuccessSounds.length);
                                    studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                } else {
                                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                }
                            } else {
                                if (trueFalseSlider.isCorrect) {
                                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                } else {
                                    const variant = random(studentAnswerSuccessSounds.length);
                                    studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                }
                            }
                        }

                        this.setState(tabs);
                    }

                    if (data.command === 'sendTestQuestionAnswer') {
                        const { tabs } = this.state;
                        tabs[data.data.activeTabIndex].tabPanel.lessonItems[data.data.lessonItemIndex].studentAnswer = data.data.answer;
                        tabs[data.data.activeTabIndex].tabPanel.lessonItems[data.data.lessonItemIndex].isSubmit = data.data.isSubmit;

                        if (data.data.isSubmit) {
                            if (data.data.isCorrect) {
                                const variant = random(studentAnswerSuccessSounds.length);
                                studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                            } else {
                                studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                            }
                        }

                        this.setState(tabs);
                    }

                    if (data.command === 'giveControlRemoteParticipant') {
                        if (data.data.participantEmail === this.props.userInfo.userInfo.email) {
                            this.setState(prevState => {
                                return { isUserHaveNotControl: !prevState.isUserHaveNotControl }
                            });

                            if (!this.state.isUserHaveNotControl) {
                                const appDivInputs = document.querySelectorAll('.App input');

                                for (let i = 0; i < appDivInputs.length; i++) {
                                    appDivInputs[i].disabled = '';
                                }
                            }
                        } else {
                            this.setState({ isUserHaveNotControl: true });
                        }

                        platformAccessAudio.play(); platformAccessAudio.volume = global.soundsVolume;
                    }
                } catch (e) {
                }
            });
        }

        let courseInfo = '';
        if (lesson && this.isPresentation) {
            const courseRaw = this.props.coursesRaw.find( elem => elem.id === lesson.courseId);
            if (courseRaw) {
                courseInfo = `${courseRaw.name} ${courseRaw.type}`;
            }
        }

        this.state = {
            homeworkErrors: {},
            homeworkTasksInformation: {},
            isHomeworkFinish: false,
            fewTasksCompletedMessage: false,
            isRerend: false,
            open: false,
            lessonName: lesson ? lesson.name : '',
            lessonId: lesson ? lesson.id : null,
            numberOfLesson: lesson && lesson.page && lesson.page.numberOfLesson,
            lessonCourseId: this.props.courseId,
            timeOfClass: lesson && lesson.page && lesson.page.timeOfClass,
            keyWords: lesson && lesson.page && lesson.page.keyWords,
            Grammar: lesson && lesson.page && lesson.page.Grammar,
            courseInfo,
            editor: null,
            selectItemsDialogVisible: true,
            lessonItems,
            socket: socket || { onmessage: () => null, send: () => null },
            selectedFile: null,
            selectedFiles: null,
            imageStubLink: `${config.imageStubGeneratorUrl}/100x100.png`,
            tabs: lessonItems || [{
                tab: {label: 'Main', value: '1'},
                tabPanel: {value: '1', lessonItems: []},
            }],
            activeTabNumber: '1',
            gridMaxRowsCount: 5,
            gridMaxColumnsCount: 5,

            sketch: (this.isPresentation) ? draw(
                socket || { send: () => null },
                'lessonWhiteboard',
                this.prepareInfo()
            ) : () => {},
            sketchWidth: 320,
            controlledPosition: {
                x: 0, y: 0
            },
            isUserHaveNotControl: !this.props.isHomework,
            selectedObj: {
                display: 'none',
                top: '',
                left: ''
            },
            selectedWordToTranslate: '',
        };
    }

    updateHomeworkTasksInformation = (uniqId, numberOfResponses = 0) => {
        const { homeworkTasksInformation } = this.state;

        try {
            if (numberOfResponses ) {
                homeworkTasksInformation[uniqId] = {
                    numberOfResponses,
                    currentResponsesCnt: 0,
                };
            } else {
                if (homeworkTasksInformation[uniqId] && (homeworkTasksInformation[uniqId].currentResponsesCnt < homeworkTasksInformation[uniqId].numberOfResponses)) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt++;
                }
            }
        } catch (e) {}

        this.setState({
            homeworkTasksInformation,
            fewTasksCompletedMessage: false,
        });
    };

    updateHomeworkWigInformation = (uniqId, responseCnt = 0) => {
        const { homeworkTasksInformation } = this.state;
        try {
            if (homeworkTasksInformation[uniqId]) {
                if (responseCnt > 0 && (homeworkTasksInformation[uniqId].currentResponsesCnt < homeworkTasksInformation[uniqId].numberOfResponses)) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt += responseCnt;
                } else if (responseCnt < 0 && (homeworkTasksInformation[uniqId].currentResponsesCnt >= Math.abs(responseCnt))) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt += responseCnt;
                }
            }
        } catch (e) {}

        this.setState({
            homeworkTasksInformation,
            fewTasksCompletedMessage: false,
        });
    }

    updateHomeworkErrors = (uniqId, changeErrorCnt = 1, isOneAnswer = false) => {
        const { homeworkErrors } = this.state;

        try {
            if (typeof homeworkErrors[uniqId] === 'undefined') {
                if (changeErrorCnt > 0) {
                    homeworkErrors[uniqId] = 1;
                }
            } else {
                if (isOneAnswer) {
                    if (changeErrorCnt > 0) {
                        homeworkErrors[uniqId] = 1;
                    } else {
                        homeworkErrors[uniqId] = 0;
                    }
                } else {
                    homeworkErrors[uniqId] += changeErrorCnt;
                }

            }
        } catch (e) {}

        this.setState({ homeworkErrors });
    };

    prepareInfo = () => ({
        vCId: this.props.virtualClassId,
        userEmail: this.props.userInfo.userInfo.email,
        userType: this.props.userInfo.userInfo.type,
    });

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { socketMessage } = this.props;

        if ((JSON.stringify(socketMessage) !== JSON.stringify(prevProps.socketMessage))) {
            const { tabs } = this.state;

            try {
                const lessonItems = tabs[this.state.activeTabNumber - 1].tabPanel.lessonItems;

                if (socketMessage.command === 'handleWig') {
                    if (socketMessage.data.wigVal) {
                        lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.wordsArrIndex].wigVal =
                            socketMessage.data.wigVal || [];
                        lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.wordsArrIndex].className = '';
                    } else  if (socketMessage.data.className) {
                        lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.wordsArrIndex].className =
                            socketMessage.data.className || '';

                        if (socketMessage.data.className === ' WIG-successful') {
                            const variant = random(studentAnswerSuccessSounds.length);
                            studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                        } else {
                            studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                        }
                    }
                } else if (socketMessage.command === 'handleSimpleSlider' || socketMessage.command === 'handleTrueFalseSlider') {
                    if (this.isPresentation && lessonItems[socketMessage.data.i] && lessonItems[socketMessage.data.i].carouselRef) {
                        lessonItems[socketMessage.data.i].carouselRef.goTo(Number(socketMessage.data.pageIndex));
                    }
                } else if (socketMessage.command === 'handleYoutubePlayer') {
                    if (this.isPresentation &&
                        lessonItems[socketMessage.data.i] && lessonItems[socketMessage.data.i].youtubePlayerRef) {
                        if (socketMessage.data.YoutubePlayerAction === 'playVideo') {
                            // lessonItems[socketMessage.data.i].youtubePlayerRef.target.seekTo(socketMessage.data.youtubePlayerCurrentTime);
                            // lessonItems[socketMessage.data.i].youtubePlayerRef.target.playVideo();
                            lessonItems[socketMessage.data.i].youtubePlayerRef.playVideo(socketMessage.data.youtubePlayerCurrentTime);
                        } else if (socketMessage.data.YoutubePlayerAction === 'pauseVideo') {
                            // lessonItems[socketMessage.data.i].youtubePlayerRef.target.pauseVideo();
                            lessonItems[socketMessage.data.i].youtubePlayerRef.pauseVideo();
                        }
                    }
                } else if (socketMessage.command === 'handleAudioPlayer') {
                    if (this.isPresentation &&
                        lessonItems[socketMessage.data.i] && lessonItems[socketMessage.data.i].audioPlayerId) {
                        const audio = document.getElementById(lessonItems[socketMessage.data.i].audioPlayerId);
                        if (socketMessage.data.AudioPlayerAction === 'playAudio') {
                            audio.currentTime = socketMessage.data.currentAudioTime || 0;
                            audio.play();
                        } else if (socketMessage.data.AudioPlayerAction === 'pauseAudio') {
                            audio.pause();
                        }
                    }
                } else if (socketMessage.command === 'handleWIT' || socketMessage.command === 'handleCWF') {
                    lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.index].className = socketMessage.data.className;
                    lessonItems[socketMessage.data.i].wordsArr[socketMessage.data.index].val = socketMessage.data.val;

                    if (socketMessage.data.className === l.WIT_successful) {
                        const variant = random(studentAnswerSuccessSounds.length);
                        studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                    } else {
                        studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                    }
                } else if (socketMessage.command === 'handleChangeEditor') {
                    let currentVal = JSON.stringify(socketMessage.data.currentVal || lessonItems[socketMessage.data.i].currentVal).replaceAll('u0001', 'n');
                    currentVal = JSON.parse(currentVal) || '';
                    lessonItems[socketMessage.data.i].currentVal = currentVal;
                    lessonItems[socketMessage.data.i].editorState = EditorState.createWithContent(ContentState.createFromText(currentVal));
                }
            } catch (e) { }

            this.setState({ tabs });
        }

        if (prevProps.whiteboard.color !== this.props.whiteboard.color
            || prevProps.whiteboard.width !== this.props.whiteboard.width
        ) {
            this.props.socket.send(JSON.stringify({
                command: 'dialogChangeColor',
                data: this.props.whiteboard,
                val: this.prepareInfo(),
            }));
        }

        if (prevProps.whiteboard.clear !== this.props.whiteboard.clear) {
            this.props.socket.send(JSON.stringify({
                command: 'dialogWhiteboardClear',
                data: this.props.whiteboard,
                val: this.prepareInfo(),
            }));
        }
    }

    getItemCreateFunction(el, i = null, isRender, activeTabIndex) {
        const isPresentation = this.isPresentation;
        switch (el.name) {
            case 'DAD1':
                if (el.type === 'special3') {
                    return (isRender ? this.renderDAD1(el, i, activeTabIndex) : this.okHandlerDAD1Special);
                }
                return (isRender ? this.renderDAD1(el, i, activeTabIndex) : this.okHandlerDAD1);
            case 'DAD2':
                return (isRender ? this.renderDAD2(el, i, activeTabIndex) : this.okHandlerDAD2);
            case 'Editor':
                return (isRender ? this.renderEditor(el, i, activeTabIndex) : () => null);
            case 'TeachersNote':
                return (isRender ? this.renderEditorTeachersNote(el, i, activeTabIndex) : () => null);
            case 'WIG':
                return (isRender ? this.renderWIG(el, i, activeTabIndex) : this.okHandlerWIG);
            case 'WIT':
                return (isRender ? this.renderWIT(el, i, activeTabIndex) : this.okHandlerWIG);
            case 'CWF':
                return (isRender ? this.renderCWF(el, i, activeTabIndex) : this.okHandlerWIG);
            case 'IMG':
                return (isRender ? this.renderIMG(el, i, activeTabIndex) : this.renderIMG);
            case 'Slider':
                return (isRender ? this.renderSlider(el, i, activeTabIndex) : this.renderSlider);
            case 'Summer':
                return (isRender ? this.renderSummer(el, i, activeTabIndex) : this.renderSummer);
            case 'ImgGrid':
                return (isRender ? this.renderImgGrid(el, i, activeTabIndex) : this.renderImgGrid);
            case 'DnDGrid':
                return (isRender ? (isPresentation ? this.renderDnDGrid(el, i, activeTabIndex) : this.renderImgGrid(el, i, activeTabIndex, true)) : this.renderDnDGrid);
            case 'DnDGrid2':
                return (isRender ? (isPresentation ? this.renderDnDGrid2(el, i, activeTabIndex) : this.renderImgGrid(el, i, activeTabIndex, true, true)) : this.renderDnDGrid);
            case 'TrueLies':
                return (isRender ? this.renderTrueLies(el, i, activeTabIndex, isPresentation) : this.renderTrueLies);
            case 'testQuestion':
                return (isRender ? this.renderTestQuestion(el, i, activeTabIndex) : this.renderTestQuestion);
            case 'ButtonL':
                return (isRender ? this.renderButtonL(el, i, activeTabIndex) : this.renderButtonL);
            case 'HorizontalLine':
                return (isRender ? this.HorizontalLine(el, i, activeTabIndex) : this.HorizontalLine);
            case 'Youtube':
                return (isRender ? this.renderYoutube(el, i, activeTabIndex) : this.renderYoutube);
            case 'Audio':
                return (isRender ? this.renderAudio(el, i, activeTabIndex) : this.renderAudio);
            case 'PDF':
                return (isRender ? this.renderPDF(el, i, activeTabIndex) : this.renderPDF);
            default:
                return (isRender ? (<div />) : () => {
                });
        }
    }

    onChangeEditor = (editorState, i, activeTabIndex) => {
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        lessonItems[i].editorState = editorState;
        lessonItems[i].currentVal = editorState.getCurrentContent().getPlainText('\u0001');

        this.setState({ tabs });

        this.state.socket.send(JSON.stringify({
            command: 'handleChangeEditor',
            data: {
                i,
                currentVal: lessonItems[i].currentVal,
            },
            val: this.prepareInfo(),
        }));
    };

    componentDidMount() {
        if (this.isPresentation) {
            this.state.tabs.forEach((tab, index) => {
                tab.tabPanel.lessonItems.forEach((el, i) => {
                    el.itemCreateFunction(el, i, index);
                });
            });
        }

        if (this.props.isHomework) {
            setTimeout(() => {
                this.LessonEditorComponentRef.current.scrollTo({ top: 0 });
            }, 0);
        }
    }

    handleInputChange = event => {
        event.persist();

        const { name, value } = event.target;

        this.setState(() => ({
            [name]: value,
        }));
    };

    handleLinkChange = (event, i, activeTabIndex) => {
        event.persist();

        const { value } = event.target;
        const { tabs } = this.state;
        tabs[activeTabIndex].tabPanel.lessonItems[i].link = value;

        this.setState({ tabs });
    };

    handleDADChange = (event, i, activeTabIndex) => {
        event.persist();

        const { value } = event.target;
        const { tabs } = this.state;
        tabs[activeTabIndex].tabPanel.lessonItems[i].text = value;

        this.setState({ tabs });
    };

    handleTrueLiesChange = (event, i, activeTabIndex, itemIndex) => {
        event.persist();

        const { value } = event.target;
        const { tabs } = this.state;
        tabs[activeTabIndex].tabPanel.lessonItems[i].items[itemIndex].question = value;

        this.setState({ tabs });
    };

    handleTestQuestionChange = (event, i, activeTabIndex) => {
        event.persist();

        const { value } = event.target;
        const { tabs } = this.state;
        tabs[activeTabIndex].tabPanel.lessonItems[i].question = value;

        this.setState({ tabs });
    };

    handleTestQuestionVariantChange = (event, i, activeTabIndex, itemIndex) => {
        event.persist();

        const { value } = event.target;
        const { tabs } = this.state;
        tabs[activeTabIndex].tabPanel.lessonItems[i].variants[itemIndex].value = value;

        this.setState({ tabs });
    };

    okHandlerDAD1 = (el, i, activeTabIndex) => {
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        let { text } = el;
        let clue = [];

        if (el.type === 'special2') {
            let splitArr = text.split(']');

            for (let i = 0; i < splitArr.length; i++) {

                if (splitArr[i]) {
                    clue[i] = splitArr[i].split('[').join(' -> ');
                }
            }
        } else if (el.type === 'simple') {
            clue = text;
        }

        text = text.replace('\n', '');

        text = text.split('[');
        let resText = [], resDustbin = [], resBox = [];

        text.forEach((el, i) => {
            if (el.includes(']')) {
                el = el.split(']');
                resDustbin.push({ accepts: ['1'], lastDroppedItem: null, i: el[0] });
                resBox.push({ name: el[0], type: '1', i });
                resText.push(el[1]);
            } else {
                resText.push(el);
            }
        });

        resBox = switchArrayElements(resBox, i);
        lessonItems[i].resText = resText;
        lessonItems[i].resDustbin = resDustbin;
        lessonItems[i].resBox = resBox;
        lessonItems[i].isText = true;
        lessonItems[i].clue = clue;

        this.setState({ tabs });
    };

    okHandlerDAD2 = (el, i, activeTabIndex) => {
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        let { text } = el;

        const clue = (el.type === 'special1') ? text.split('|').join(' ') : text;
        text = (el.type === 'special1') ? text.split('|') : text.split('');
        const resDustbin = [];
        let resBox = [];

        text.forEach((el, i) => {
            resDustbin.push({ accepts: ['2'], lastDroppedItem: null, i: el });
            resBox.push({ name: el, type: '2', i });
        });

        resBox = switchArrayElements(resBox, i);

        lessonItems[i].resDustbin = resDustbin;
        lessonItems[i].resBox = resBox;
        lessonItems[i].isText = true;
        lessonItems[i].clue = clue;

        this.setState({ tabs });
    };

    okHandlerWIG = (el, i, activeTabIndex) => {
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        let text = el.editorState.getCurrentContent().getPlainText('\u0001').split('[');
        const textArr = [], wordsArr = [];
        const clue = [];
        let answerVar = '';

        if (el.name === 'WIG') {
            answerVar = 'text';
        } else if (el.name === 'WIT' || el.name === 'CWF') {
            answerVar = 'rightWord';
        }

        text.forEach((el, i) => {
            if (el.includes(']')) {
                el = el.split(']');
                textArr.push(el[1]);

                const infinitiveStart = el[0].indexOf('('), infinitiveEnd = el[0].indexOf(')');

                let infinitive = '';
                if (infinitiveStart !== -1 && infinitiveEnd !== -1) {
                    infinitive = el[0].substring(infinitiveStart + 1, infinitiveEnd) + ';';
                    el[0] = el[0].substring(infinitiveEnd + 2, el[0].length).trim();
                }

                let rightWord = '';
                el[0].split(';').forEach(elem => {
                    if (elem[0] === '{' && elem[elem.length - 1] === '}') {
                        rightWord = elem.replace('}', '').replace('{', '');
                    }
                });

                el[0] = el[0].replace('}', '').replace('{', '');
                wordsArr.push({
                    text: infinitive.trim() + el[0],
                    className: '',
                    val: infinitive.replace(';', ''),
                    rightWord
                });
            } else {
                if (el.includes('<')) {
                    el = el.split('<');
                    textArr.push(el[0]);
                } else {
                    textArr.push(el);
                }
            }
        });

        for (let i = 0; i < wordsArr.length; i++) {
            clue[i] = `${i + 1} -> ${wordsArr[i][answerVar]}`;
        }

        lessonItems[i].isRenderCode = true;
        lessonItems[i].textArr = textArr;
        lessonItems[i].wordsArr = wordsArr;
        lessonItems[i].className = '';
        lessonItems[i].clue = clue;

        if (!lessonItems[i].uuid) lessonItems[i].uuid = v1();

        this.setState({ tabs });
    };

    renderInputText(i, value, handleChange, activeTabIndex, itemIndex, placeholder = '', multiline = false, label = 'text') {
        return (
            <div className="col-12 dad2_render">
                <TextField
                    placeholder={placeholder}
                    InputLabelProps={{ shrink: true }}
                    required
                    name="text"
                    value={value}
                    onChange={event => handleChange(event, i, activeTabIndex, itemIndex)}
                    label={label}
                    fullWidth
                    multiline={multiline}
                    margin="normal"
                    className="mt-0 mb-4"
                />
            </div>
        );
    }

    renderDnDGrid2 = (el, i) => {
        const gridRowsCnt = el?.rows?.length || 1;
        const resDustbin = [];
        let resBox = [];
        let j = 0;
        const clue = [];
        el.rows.map((row, rowIndex) => {
            let rowClue = '';

            row.map((item, columnIndex, columnArr) => {
                if (item && item.text) {
                    item.text.split('|').map((el, i) => {
                        if (i === 0) {
                            resDustbin.push({accepts: ['DnDGrid2'], lastDroppedItem: null, i: el, imgSrc: item.src});

                            rowClue += `${rowIndex + 1}x${columnIndex + 1} -> ${el}`;

                            if (columnIndex + 1 !== columnArr.length) {
                                rowClue += ', ';
                            }
                        }

                        resBox.push({name: el, type: 'DnDGrid2', i: j++});
                    });
                }
            });

            clue.push(rowClue);
        });
        resBox = switchArrayElements(resBox, i);

        el.resDustbin = resDustbin;
        el.resBox = resBox;

        const { heightPriority, heightInVh, widthInPercent, uuid } = el;

        return (
            <div className="DnDGridContainer">
                {this.props.userInfo.userInfo.type === 'teacher' && this.props.demoLesson ?
                    <TeacherHelpInfo info={clue.map((elem) => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
                {el.resDustbin && el.resBox &&
                    <>
                        <DnDGrid
                            updateHomeworkTasksInformation={this.updateHomeworkTasksInformation}
                            updateHomeworkErrors={this.updateHomeworkErrors}
                            isHomework={this.props.isHomework}
                            isHomeworkFinish={this.state.isHomeworkFinish}
                            uuid={uuid}
                            gridRowsCnt={gridRowsCnt}
                            heightPriority={heightPriority}
                            heightInVh={heightInVh}
                            widthInPercent={widthInPercent}
                            resDustbin={resDustbin}
                            resBox={resBox}
                            socket={this.state.socket}
                            socketMessage={this.props.socketMessage}
                            val={this.prepareInfo()}
                        />
                        {myVarIsMobileDevice &&
                            <Preview generator={generatePreview}/>
                        }
                    </>}
            </div>
        );
    };

    renderDnDGrid = (el, i) => {
        const gridRowsCnt = el?.rows?.length || 1;
        const resDustbin = [];
        let resBox = [];
        let j = 0;
        const clue = [];
        el.rows.map((row, rowIndex) => {
            let rowClue = '';

            row.map((item, columnIndex, columnArr) => {
                resDustbin.push({ accepts: ['DnDGrid'], lastDroppedItem: null, i: item.text, imgSrc: item.src });
                resBox.push({ name: item.text, type: 'DnDGrid', i: j++ });
                rowClue += `${rowIndex + 1}x${columnIndex + 1} -> ${item.text}`;

                if (columnIndex + 1 !== columnArr.length) {
                    rowClue +=', ';
                }
            });

            clue.push(rowClue);
        });
        resBox = switchArrayElements(resBox, i);

        el.resDustbin = resDustbin;
        el.resBox = resBox;

        const { heightPriority, heightInVh, widthInPercent, uuid } = el;

        return (
            <div className="DnDGridContainer">
                {this.props.userInfo.userInfo.type === 'teacher' && this.props.demoLesson ?
                    <TeacherHelpInfo info={clue.map((elem) => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
                {el.resDustbin && el.resBox &&
                    <>
                        <DnDGrid
                            updateHomeworkTasksInformation={this.updateHomeworkTasksInformation}
                            updateHomeworkErrors={this.updateHomeworkErrors}
                            isHomework={this.props.isHomework}
                            isHomeworkFinish={this.state.isHomeworkFinish}
                            uuid={uuid}
                            gridRowsCnt={gridRowsCnt}
                            heightPriority={heightPriority}
                            heightInVh={heightInVh}
                            widthInPercent={widthInPercent}
                            resDustbin={resDustbin}
                            resBox={resBox}
                            socket={this.state.socket}
                            socketMessage={this.props.socketMessage}
                            val={this.prepareInfo()}
                        />
                        {myVarIsMobileDevice &&
                            <Preview generator={generatePreview}/>
                        }
                    </>}
            </div>
        );
    };

    renderImgGrid = (el, i, activeTabIndex, isDnD = false, isDnD2 = false) => {
        const { heightPriority, heightInVh, widthInPercent  } = el;
        const { tabs } = this.state;
        let rows = el.rows.length;
        let columns = el.rows[0].length; //Т.к. они должны иметь одинаковый размер

        return (
            <div className="WIG" key={`tab_${activeTabIndex}_item_${i}`}>
                <div>
                    {this.isNotPresentation &&
                        <>
                            <ImgsSizeHelper
                                parentThis={this}
                                activeTabIndex={activeTabIndex}
                                activeExerciseIndex={i}
                                tabs={tabs}
                                exerciseItem={el}
                            />
                            <div className={l.row}>
                                <div className={l.WIT_button}>
                                    <FormControl variant="outlined" >
                                        <InputLabel id="rows-label">Rows</InputLabel>
                                        <Select onChange={(e) => this.updateGridSize(el, e.target.value, columns, i)}
                                                labelId="rows-label" value={rows} label="Rows">
                                            {Array.from({ length: this.state.gridMaxRowsCount }).map((elem, i) =>
                                                <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className={l.WIT_button}>
                                    <FormControl variant="outlined" className={l.WIT_button}>
                                        <InputLabel id="columns-label">Columns</InputLabel>
                                        <Select onChange={(e) => this.updateGridSize(el, rows, e.target.value, i)}
                                                labelId="columns-label" value={columns} label="Columns">
                                            {Array.from({ length: this.state.gridMaxColumnsCount }).map((elem, i) =>
                                                <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <br /> <br />
                            </div>
                        </>
                    }
                    {el.rows.map(row =>
                        <Grid container direction="row" justify="center" alignItems="center"
                              spacing={el.gridData.spacing}
                        >
                            {row.map((item, j) =>
                                <Grid item xs container
                                      style={{justifyContent: ((row.length > 2  || row.length === 1) ?
                                              'center' : (j % 2 === 0 ? 'right' : 'left'))}}
                                >
                                    <Paper className="paper"
                                           style={{alignItems: ((row.length > 2  || row.length === 1) ?
                                                   'center' : (j % 2 === 0 ? 'flex-end' : 'flex-start'))}}
                                    >
                                        <StyledImgGrid component="img" src={item.src}
                                                       style={heightPriority ? {height: heightInVh + 'vh'} : {width: widthInPercent + '%'}}
                                        />
                                        {this.isNotPresentation &&
                                            <div>
                                                <label htmlFor="file-upload" className="custom-file-upload"
                                                       style={{border: '1px solid #ccc', display: 'inline-block', padding: '6px 12px', cursor: 'pointer'}}
                                                >Select</label>
                                                <input id="file-upload" type="file" name="file"
                                                       style={{ display: 'none' }}
                                                       accept="image/png, image/gif, image/jpeg"
                                                       onChange={e => this.setState({ selectedFile: e.target.files[0] })}
                                                />
                                                {isDnD &&
                                                    <input type="text" name="text"
                                                           placeholder={isDnD2 ? 'true_variant|wrong1|wrong2...' : 'enter text'}
                                                           value={item.text}
                                                           onChange={e => {
                                                               const maxInputLength = isDnD2 ?
                                                                   (GRID_IMG_INPUT_MAX_LETTERS * 2) : GRID_IMG_INPUT_MAX_LETTERS
                                                               if (e.target.value.length <= maxInputLength) {
                                                                   item.text = e.target.value;
                                                                   this.setState({ tabs });
                                                               }
                                                           }}
                                                    />
                                                }
                                                <Button onClick={() => {
                                                    const { selectedFile } = this.state;
                                                    const formData = new FormData();

                                                    document.getElementById('file-upload').value = '';
                                                    formData.append('file', selectedFile);
                                                    formData.append('name', 'test');
                                                    callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                                        if (res && res.data && res.data.length && res.data.fileName) {
                                                            item.src = res.data.fileName.substr(config.filePathSubstLength,);
                                                            this.setState({ tabs, selectedFile: null });
                                                        }
                                                    }).catch(err => {
                                                        console.log(err)
                                                    });
                                                }}>Upload</Button>
                                            </div>}
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </div>
                <br />
            </div>
        );
    };

    renderButtonL = (el, i, activeTabIndex) => {
        const {text, link} = el;
        return (
            <div className={l.WIT_example} key={`tab_${activeTabIndex}_item_${i}`}>
                {this.isNotPresentation &&
                    <div>
                        {this.renderInputText(i, text, this.handleDADChange, activeTabIndex, 0, 'Enter the text')}
                        {this.renderInputText(i, link, this.handleLinkChange, activeTabIndex, 0, 'Enter the link')}
                    </div>}
                <Button onClick={() => window.open(link, '_blank')}
                        style={{ minWidth: '60px' }} color="primary" variant="contained">{text ? text : 'Button'}</Button>
            </div>
        );
    };

    HorizontalLine = () => (<div style={{width: '100%', height: '1px', background: 'lightgray'}}/>);

    renderYoutube = (el, i, activeTabIndex) =>{
        // const player = VideoPlayer(el, i, activeTabIndex,
        //     {handleLinkChange: this.handleLinkChange,
        //      userInfo: this.props.userInfo,
        //      isPresentation: this.isPresentation,
        //      sendMessage: this.sendMessageToSocket
        //     });
        const { tabs } = this.state;
        // tabs[activeTabIndex].tabPanel.lessonItems[i].youtubePlayerRef = player;

        return (<VideoPlayer            
            el={el}
            i={i}
            activeTabIndex={activeTabIndex}
            options={{handleLinkChange: this.handleLinkChange,
                      userInfo: this.props.userInfo,
                      isPresentation: this.isPresentation,
                      sendMessage: this.sendMessageToSocket,
                      isHomework: this.props.isHomework,
                      isPresentation: this.isPresentation
                    }}
            socketMessage = {this.props.socketMessage}
            onReady = {ref => {tabs[activeTabIndex].tabPanel.lessonItems[i].youtubePlayerRef = ref}}
        />);
    };

    sendMessageToSocket = (i, massage, time)=> {
        this.state.socket.send(JSON.stringify({
            command: 'handleYoutubePlayer',
            data: { i, YoutubePlayerAction: massage, youtubePlayerCurrentTime: time},
            val: this.prepareInfo(),
        }));
    };

    renderAudio = (el, i, activeTabIndex) => {
        const { isHomework } = this.props;
        const { src } = el;
        const audio = document.getElementById(`audio_${activeTabIndex}_item_${i}`);
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        lessonItems[i].audioPlayerId = `audio_${activeTabIndex}_item_${i}`;

        return (
            <div className={l.WIT_example} key={`tab_${activeTabIndex}_item_${i}`}>
                {this.isNotPresentation && !src &&
                    <div>
                        <div className={l.WIT_button}>
                            <Input type="file" name="file"
                                   onChange={e => this.setState({ selectedFile: e.target.files[0] })}
                            />
                        </div>
                        <div className={l.WIT_button}>
                            <Button onClick={() => {
                                const { selectedFile } = this.state;
                                const formData = new FormData();

                                formData.append('name', 'mp3.mp3');
                                formData.append('fileType', 'mp3');
                                formData.append('file', selectedFile);

                                callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                    if (res && res.data && res.data.length && res.data.fileName) {
                                        lessonItems[i].src = res.data.fileName.substr(config.filePathSubstLength);

                                        this.setState({ tabs });
                                    }
                                }).catch(err => {
                                    console.log(err)
                                });
                            }}>Upload</Button>
                        </div>
                    </div>}
                {src &&
                    <div className={"YouTubePlayerContainer" +
                        ((['student'].includes(this.props.userInfo.userInfo.type) && !isHomework) ? " YouTubePlayerContainerPointerEvent" : " ")}
                    >
                        <audio
                            controls
                            id={`audio_${activeTabIndex}_item_${i}`}
                            onSeeking={(event)=>{tabs[activeTabIndex].tabPanel.lessonItems[i].currentAudioTime = event.target.currentTime}}
                        >
                            <source
                                src={src}
                                type="audio/ogg; codecs=vorbis"
                            />
                        </audio>
                    </div>
                }

                {!['student'].includes(this.props.userInfo.userInfo.type) &&
                    <div className="YouTubePlayerContainer">
                        <Button onClick={() => {
                            audio.play();

                            this.state.socket.send(JSON.stringify({
                                command: 'handleAudioPlayer',
                                data: {
                                    i,
                                    AudioPlayerAction: 'playAudio',
                                    currentAudioTime: this.state.tabs[activeTabIndex].tabPanel.lessonItems[i].currentAudioTime,
                                },
                                val: this.prepareInfo(),
                            }));
                        }}
                        >Старт</Button>
                        <Button onClick={() => {
                            audio.pause();

                            this.state.socket.send(JSON.stringify({
                                command: 'handleAudioPlayer',
                                data: { i, AudioPlayerAction: 'pauseAudio' },
                                val: this.prepareInfo(),
                            }));
                        }}
                        >Стоп</Button>
                    </div>}
            </div>
        );
    };

    renderIMG = (el, i, activeTabIndex) => {
        const { src, whiteboard, heightPriority, heightInVh, widthInPercent, isDrawing } = el;
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        return (
            <div key={`tab_${activeTabIndex}_item_${i}`}>
                {this.isNotPresentation && <ImgsSizeHelper
                    parentThis={this}
                    activeTabIndex={activeTabIndex}
                    activeExerciseIndex={i}
                    tabs={tabs}
                    exerciseItem={el}
                />}
                {this.isNotPresentation && <>
                    <Switch
                        onChange={() => {
                            lessonItems[i].isDrawing = !isDrawing;
                            this.setState(tabs);
                        }}
                        checked={isDrawing}
                    /> Можно рисовать</>}
                {this.isNotPresentation && !src &&
                    <div>
                        <div className={l.WIT_button}>
                            <Input type="file" name="file"
                                   onChange={e => this.setState({ selectedFile: e.target.files[0] })}
                            />
                        </div>
                        <div className={l.WIT_button}>
                            <Button onClick={() => {
                                const { selectedFile } = this.state;
                                const formData = new FormData();

                                formData.append('file', selectedFile);
                                formData.append('name', 'test');
                                callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                    if (res && res.data && res.data.length && res.data.fileName) {
                                        lessonItems[i].src = res.data.fileName.substr(config.filePathSubstLength);

                                        this.setState({ tabs });
                                    }
                                }).catch(err => {
                                    console.log(err)
                                });
                            }}>Upload</Button>
                        </div>
                    </div>}
                {src &&
                    <div className="canvasImgContainer"
                         style={heightPriority ? { } : { width: widthInPercent + '%' }}
                         ref={el.canvasImgContainerRef}
                    >
                        {this.isPresentation && isDrawing &&
                            <P5Wrapper sketch={whiteboard} />
                        }
                        <img src={src}
                             style={heightPriority ? { height: heightInVh + 'vh' } : { width: '100%' }}
                        />
                        {this.isPresentation && isDrawing &&
                            <div className="canvasImgContainerDrawIcon"
                                 onClick={() => this.props.dialogSetImgWhiteboard(!this.props.showImgWhiteboard)}
                            >
                                <img className={t.right_panel_img} src={IconWhiteboard} alt="whiteboard"/>
                            </div>
                        }
                    </div>
                }
                <br />
            </div>
        );
    };

    renderPDF = (el, i, activeTabIndex) => {
        const { src, heightInVh, widthInPercent } = el;
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        return (
            <div key={`tab_${activeTabIndex}_item_${i}`}>
                {this.isNotPresentation && <PdfSizeHelper
                    parentThis={this}
                    activeTabIndex={activeTabIndex}
                    activeExerciseIndex={i}
                    tabs={tabs}
                    exerciseItem={el}
                />}
                {this.isNotPresentation && !src &&
                    <div>
                        <div className={l.WIT_button}>
                            <Input type="file" name="file"
                                   onChange={e => this.setState({ selectedFile: e.target.files[0] })}
                            />
                        </div>
                        <div className={l.WIT_button}>
                            <Button onClick={() => {
                                const { selectedFile } = this.state;
                                const formData = new FormData();

                                formData.append('name', 'pdf.pdf');
                                formData.append('fileType', 'pdf');
                                formData.append('file', selectedFile);

                                callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                    if (res && res.data && res.data.length && res.data.fileName) {
                                        lessonItems[i].src = res.data.fileName.substr(config.filePathSubstLength);

                                        this.setState({ tabs });
                                    }
                                }).catch(err => {
                                    console.log(err)
                                });
                            }}>Upload</Button>
                        </div>
                    </div>}
                {src &&
                    <div
                        className="canvasImgContainer"
                        style={{ height: heightInVh + 'vh', width: widthInPercent + '%' }}
                    >
                        <iframe src={src} style={{ height: '100%', width: '100%' }}></iframe>
                    </div>
                }
                <br />
            </div>
        );
    };

    renderSlider = (el, i, activeTabIndex) => {
        const { items, heightPriority, heightInVh, widthInPercent  } = el;
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        let o = 1000;

        return (
            <div className={l.slider} key={i}>
                {this.isNotPresentation &&
                    <div>
                        <ImgsSizeHelper
                            parentThis={this}
                            activeTabIndex={activeTabIndex}
                            activeExerciseIndex={i}
                            tabs={tabs}
                            exerciseItem={el}
                        />
                        <div className={l.WIT_button}>
                            <Input type="file" name="files"
                                   inputProps={{ multiple: true }}
                                   onChange={e => this.setState({ selectedFiles: e.target.files })}
                            />
                        </div>
                        <div className={l.WIT_button}>
                            <Button onClick={
                                () => {
                                    const { selectedFiles } = this.state;

                                    for (let j = 0; j < selectedFiles.length; j++) {
                                        const formData = new FormData();

                                        formData.append('file', selectedFiles[j]);
                                        formData.append('name', 'test');
                                        callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                            if (res && res.data && res.data.length && res.data.fileName) {
                                                items.push({ id: o++, url: res.data.fileName.substr(config.filePathSubstLength,) });
                                                lessonItems[i].items = items;

                                                this.setState({ tabs });
                                            }
                                        }).catch(() => {});
                                    }
                                }
                            }
                            >{this.state.selectedFiles ? 'Upload' : ''}
                            </Button>
                        </div>
                    </div>}
                <div className={l.carousel}>
                    <Carousel // pagination={false}
                        ref={ref => {
                            if (this.isNotPresentation) return;
                            el.carouselRef = ref;
                        }
                        }
                        onChange={(currentItem, pageIndex) =>
                            this.state.socket.send(JSON.stringify({
                                command: 'handleSimpleSlider',
                                data: { i, pageIndex },
                                val: this.prepareInfo(),
                            }))}
                    >
                        {items.map(item =>
                            <div key={item.id} className={l.img_block_img}>
                                <img src={item.url}
                                     style={heightPriority ? {height: heightInVh + 'vh'} : {width: widthInPercent + '%'}}
                                />
                            </div>)}
                    </Carousel>
                </div>
                <br />
            </div>
        );
    };

    renderSummer = (/*el, i, activeTabIndex*/) => {
        return (<></>);

        // const { showStudents } = el;
        // const { text } = el;
        // const { tabs } = this.state;
        //
        // const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        // if (!lessonItems[i]) return (<></>);
        //
        // let localStorageObject = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : '';
        // let { supervisor, admin, teacher } = JSON.parse(localStorageObject.userInfo.viewPermission);
        //
        // return (
        //     <div className={l.dad2_example} key={i}>
        //         {this.isNotPresentation &&
        //             <div>
        //                 <TextField // variant="outlined"
        //                     id="standard-multiline-flexible"
        //                     label="How did I spend my summer"
        //                     multiline
        //                     fullWidth
        //                     margin="normal"
        //                     name='Summer'
        //                     value={text}
        //                     onChange={e => this.handleDADChange(e, i, activeTabIndex)}
        //                 />
        //                 <div className={l.WIT_button}>
        //                     <Button>Done</Button>
        //                 </div>
        //             </div>}
        //
        //         {/*teacher presentation*/}
        //         {this.isPresentation && (supervisor || admin || teacher) &&
        //         <>
        //             <div className={l.wit_exercise_title}>How I spend my summer</div>
        //             <div className={l.summer_present}>
        //                 <div>
        //                     {this.state.tabs[activeTabIndex].tabPanel.lessonItems[i].text}
        //                 </div>
        //                 <div className={l.summer_teacher}>
        //                     <br/>
        //                     <h3 className={l.summer_teacher_header}>answers</h3>
        //                     {studentsData.map(s=>
        //                         <div key={s.id}>
        //                             <i>{s.name}</i>
        //                             <div>{s.text}</div>
        //                             <br/>
        //                         </div>)}
        //                     <Button className={l.summer_teacher_btn}
        //                         onClick={() => {
        //                             lessonItems[i].showStudents = !lessonItems[i].showStudents;
        //                             this.setState(tabs);
        //                         }}
        //                     >{!(lessonItems[i].showStudents) && 'Show' || 'Hide'}
        //                     </Button>
        //                 </div>
        //             </div>
        //         </>
        //         }
        //
        //         {/*student presentation*/}
        //         {this.isPresentation && (!teacher) && showStudents &&
        //             <div className={l.carousel}>
        //                 <div>
        //                     {this.state.tabs[activeTabIndex].tabPanel.lessonItems[i].text}
        //                 </div>
        //                 <div>
        //                     <br />
        //                     <h3>answers</h3>
        //                     {studentsData.map(s =>
        //                         <div key={s.id}>
        //                             <i>{s.name}</i>
        //                             <div>{s.text}</div>
        //                             <br />
        //                         </div>)}
        //                 </div>
        //             </div>
        //         }
        //         {this.isPresentation && (!teacher) &&
        //             <div>
        //                 <TextField
        //                     id="standard-multiline-flexible"
        //                     label="How did I spend my summer"
        //                     variant="outlined"
        //                     multiline
        //                     fullWidth
        //                     margin="normal"
        //                     name='Summer'
        //                     value={text}
        //                     onChange={e => this.handleDADChange(e, i, activeTabIndex)}
        //                 />
        //             </div>
        //         }
        //         <br />
        //     </div>
        // );
    };

    renderExerciseTitle = (el, activeTabIndex, i) => (
        <>
            {this.isPresentation ? el.customTitle :
                <input className="ExerciseContextInput"
                       value={el.customTitle}
                       onChange={e => {
                           const { tabs } = this.state;
                           tabs[activeTabIndex].tabPanel.lessonItems[i].customTitle = e.target.value;
                           this.setState(tabs);
                       }}
                />
            }
        </>
    );

    okHandlerDAD1Special = (el, i, activeTabIndex) => { // [деревья]клен|ольха|сосна[города]Донецк|Ангарск|Киев
        const { tabs } = this.state;

        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
        if (!lessonItems[i]) return (<></>);

        let { text } = el;

        text = text.split(']');

        const dustbin1 = text[0] ? text[0].substring(1) : '';
        const dustbinBefore1 = text[1] ? text[1].split('[') : [];
        const dustbinBefore2 = text[2];
        const dustbin2 = dustbinBefore1[1];
        text = dustbinBefore1[0] + '|' + dustbinBefore2;

        const resDustbin = [
            { name: dustbin1, accepts: ['n11'], DroppedItems: [],
                i: dustbinBefore1[0] ? dustbinBefore1[0].split('|') : [] },
            { name: dustbin2, accepts: ['n11'], DroppedItems: [],
                i: dustbinBefore2 ? dustbinBefore2.split('|') : [] },
        ];
        let clue = [];

        for (let i = 0; i < resDustbin.length; i++) {
            clue[i] = `${resDustbin[i].name} -> (${resDustbin[i].i.join(', ')})`;
        }

        let resBox = [];
        text = text.split('|');
        text.forEach((el, i) => {
            resBox.push({ name: el, type: 'n11', i });
        });
        resBox = switchArrayElements(resBox, i);

        lessonItems[i].resDustbin = resDustbin;
        lessonItems[i].resBox = resBox;
        lessonItems[i].isText = true;
        lessonItems[i].clue = clue;

        this.setState({ tabs });
    };

    renderDAD1(el, i, activeTabIndex) {
        const { text, resText, resDustbin, resBox, isText, uniqId, clue } = el;

        let renderClue = null;
        let placeholder = (el.type === 'special3') ? '[t1]a|b|c[t2]d|f|g' : 'I[lie]in[london]';
        if (el.type === 'special2') {
            placeholder = 'phrase_1_path_1[phrase_1_path_2]phrase_2_path_1[phrase_2_path_2]';
        }

        if (this.props.userInfo.userInfo.type === 'teacher' && clue && el.type !== 'simple') {
            renderClue = <TeacherHelpInfo info={clue.map(elem => <div className={l.exercise_clue}>{elem}</div>)} />;
        } else if (this.props.userInfo.userInfo.type === 'teacher' && clue && el.type === 'simple') {
            renderClue = <TeacherHelpInfo info={<div className={l.exercise_clue}>{clue}</div>} />
        }

        return (
            <div key={`tab_${activeTabIndex}_item_${i}`}>
                {this.isNotPresentation &&
                    <div>
                        {this.renderInputText(i, text, this.handleDADChange, activeTabIndex, 0, placeholder, true)}
                        {(el.type !== 'special3') && <Button onClick={() => this.okHandlerDAD1(el, i, activeTabIndex)} color="primary">Ok</Button>}
                        {(el.type === 'special3') && <Button onClick={() => this.okHandlerDAD1Special(el, i, activeTabIndex)} color="primary">Ok</Button>}
                        <br /> <br />
                    </div>}
                {isText &&
                    <div className={l.dad2_example}>
                        <div className={l.wit_exercise_title}>
                            {this.renderExerciseTitle(el, activeTabIndex, i)}
                        </div>
                        {this.props.demoLesson ? renderClue : null}
                        <>
                            {(el.type === 'simple') &&
                                <Container
                                    updateHomeworkErrors={this.updateHomeworkErrors}
                                    updateHomeworkTasksInformation={this.updateHomeworkTasksInformation}
                                    isHomework={this.props.isHomework}
                                    isHomeworkFinish={this.state.isHomeworkFinish}
                                    uniqId={uniqId}
                                    resDustbin={resDustbin}
                                    resBox={resBox}
                                    resText={resText}
                                    socket={this.state.socket}
                                    socketMessage={this.props.socketMessage}
                                    val={this.prepareInfo()}
                                />}
                            {(el.type === 'special2') &&
                                <ContainerSpecial
                                    updateHomeworkErrors={this.updateHomeworkErrors}
                                    updateHomeworkTasksInformation={this.updateHomeworkTasksInformation}
                                    isHomework={this.props.isHomework}
                                    isHomeworkFinish={this.state.isHomeworkFinish}
                                    uniqId={uniqId}
                                    resDustbin={resDustbin}
                                    resBox={resBox}
                                    resText={resText}
                                    socket={this.state.socket}
                                    socketMessage={this.props.socketMessage}
                                    val= {this.prepareInfo()}
                                />}
                            {(el.type === 'special3') &&
                                <ContainerFor2Columns
                                    updateHomeworkErrors={this.updateHomeworkErrors}
                                    updateHomeworkTasksInformation={this.updateHomeworkTasksInformation}
                                    isHomework={this.props.isHomework}
                                    isHomeworkFinish={this.state.isHomeworkFinish}
                                    uniqId={uniqId}
                                    resDustbin={resDustbin}
                                    resBox={resBox}
                                    resText={resText}
                                    socket={this.state.socket}
                                    socketMessage={this.props.socketMessage}
                                    val= {this.prepareInfo()}
                                />}
                            {myVarIsMobileDevice &&
                                <Preview generator={generatePreview}/>
                            }
                        </>
                    </div>}
            </div>
        );
    }

    renderTrueLies = (el, i, activeTabIndex) => {
        const isPresentation = this.isPresentation;
        const isNotPresentation = this.isNotPresentation;
        const { items } = el;
        const { isHomework } = this.props;
        const { tabs, isHomeworkFinish, homeworkTasksInformation } = this.state;

        let clue = [];

        for (let i = 0; i < items.length; i++) {
            clue[i] = `${items[i].question} -> ${items[i].isCorrect}`;
        }

        if (!homeworkTasksInformation[el.uuid]) {
            this.updateHomeworkTasksInformation(el.uuid, items.length);
        }

        return (
            <div className={l.dad2_example}>
                {this.props.userInfo.userInfo.type === 'teacher' && this.props.demoLesson ?
                    <TeacherHelpInfo info={clue.map((elem) => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
                <div className={l.carousel}>
                    <Carousel
                        ref={ref => {
                            if (this.isNotPresentation) return;
                            el.carouselRef = ref;
                        }}
                        onChange={(currentItem, pageIndex) =>
                            this.state.socket.send(JSON.stringify({
                                command: 'handleTrueFalseSlider',
                                data: { i, pageIndex },
                                val: this.prepareInfo(),
                            }))}
                    >
                        {items.map((item, itemIndex) =>
                            <Paper>
                                {isPresentation ? <h2  style={{margin: '0 auto'}}>{item.question}</h2> : this.renderInputText(i, item.question, this.handleTrueLiesChange, activeTabIndex, itemIndex, '', false, 'Question')}
                                <div className={`${l.carousel_buttons} ${isPresentation ? l.carousel_buttons_presentation : l.carousel_buttons_admin}`}>
                                    <div className={ (isNotPresentation ? (item.isCorrect ? 'test_question_item_correct' : '' ) : '') +
                                        (isPresentation ? (l.test_question_item_true_false + ' ') : '') +
                                        ((!isHomework || isHomeworkFinish) && typeof item.studentAnswer === 'boolean' && item.studentAnswer === true && item.isCorrect ? 'test_question_item_correct' : '') +
                                        ((!isHomework || isHomeworkFinish) && typeof item.studentAnswer === 'boolean' && item.studentAnswer === true && !item.isCorrect ? 'test_question_item_incorrect' : '') +
                                        (isHomework && !isHomeworkFinish && typeof item.studentAnswer === 'boolean' && item.studentAnswer === true ? 'test_question_item_homework' : '')
                                    }
                                         onClick={isPresentation ? () => {
                                             const studentAnswer = tabs[activeTabIndex].tabPanel.lessonItems[i].items[itemIndex].studentAnswer;

                                             if (typeof studentAnswer === 'string') {
                                                 if (!item.isCorrect) {
                                                     this.updateHomeworkErrors(el.uuid, 1);
                                                 }
                                             } else if (!studentAnswer) {
                                                 if (item.isCorrect) {
                                                     this.updateHomeworkErrors(el.uuid, -1);
                                                 } else {
                                                     this.updateHomeworkErrors(el.uuid, 1);
                                                 }
                                             }

                                             tabs[activeTabIndex].tabPanel.lessonItems[i].items[itemIndex].studentAnswer = true;
                                             this.setState({ tabs });

                                             this.updateHomeworkTasksInformation(el.uuid);

                                             this.state.socket.send(JSON.stringify({
                                                 command: 'sendTruesLiesAnswer',
                                                 data: {
                                                     activeTabIndex: activeTabIndex,
                                                     lessonItemIndex: i,
                                                     itemIndex: itemIndex,
                                                     answer: true,
                                                 },
                                                 val: this.prepareInfo(),
                                             }));

                                             if (item.isCorrect) {
                                                 const variant = random(studentAnswerSuccessSounds.length);
                                                 studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                             } else {
                                                 studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                             }
                                         } : () => {
                                             tabs[activeTabIndex].tabPanel.lessonItems[i].items[itemIndex].isCorrect = true;
                                             this.setState({ tabs });
                                         }}
                                    >TRUE</div>
                                    <div className={ (isNotPresentation ? (!item.isCorrect ? 'test_question_item_correct' : '' ) : '') +
                                        (isPresentation ? (l.test_question_item_true_false + ' ') : '') +
                                        ((!isHomework || isHomeworkFinish ) && typeof item.studentAnswer === 'boolean' && item.studentAnswer === false && !item.isCorrect ? 'test_question_item_correct' : '') +
                                        ((!isHomework || isHomeworkFinish ) && typeof item.studentAnswer === 'boolean' && item.studentAnswer === false && item.isCorrect ? 'test_question_item_incorrect' : '') +
                                        (isHomework && !isHomeworkFinish && typeof item.studentAnswer === 'boolean' && item.studentAnswer === false ? 'test_question_item_homework' : '')
                                    }
                                         onClick={isPresentation ? () => {
                                             const studentAnswer = tabs[activeTabIndex].tabPanel.lessonItems[i].items[itemIndex].studentAnswer;

                                             if (typeof studentAnswer === 'string') {
                                                 if (item.isCorrect) {
                                                     this.updateHomeworkErrors(el.uuid, 1);
                                                 }
                                             } else if (studentAnswer) {
                                                 if (!item.isCorrect) {
                                                     this.updateHomeworkErrors(el.uuid, -1);
                                                 } else {
                                                     this.updateHomeworkErrors(el.uuid, 1);
                                                 }
                                             }

                                             tabs[activeTabIndex].tabPanel.lessonItems[i].items[itemIndex].studentAnswer = false;
                                             this.setState({ tabs });

                                             this.updateHomeworkTasksInformation(el.uuid);

                                             this.state.socket.send(JSON.stringify({
                                                 command: 'sendTruesLiesAnswer',
                                                 data: {
                                                     activeTabIndex: activeTabIndex,
                                                     lessonItemIndex: i,
                                                     itemIndex: itemIndex,
                                                     answer: false
                                                 },
                                                 val: this.prepareInfo(),
                                             }));

                                             if (item.isCorrect) {
                                                 studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                             } else {
                                                 const variant = random(studentAnswerSuccessSounds.length);
                                                 studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                             }
                                         } : () => {
                                             tabs[activeTabIndex].tabPanel.lessonItems[i].items[itemIndex].isCorrect = false;
                                             this.setState({ tabs });
                                         }}
                                    >FALSE</div>
                                    {!isPresentation &&
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => {
                                                this.deleteTrueLiesItem(activeTabIndex, i, itemIndex)
                                            }}
                                        >Delete question</Button>}
                                </div>
                            </Paper>)}
                    </Carousel>
                </div>

                {!isPresentation && <div>
                    <Button variant='contained' color='primary'
                            onClick={() => {
                                this.addTrueLiesItem(activeTabIndex, i)
                            }}
                    >Add question</Button></div>
                }
            </div>
        )
    }

    renderTestQuestion = (el, i, activeTabIndex) => {
        const isPresentation = this.isPresentation;
        const { tabs, isHomeworkFinish, homeworkTasksInformation } = this.state;
        const { isHomework } = this.props;

        const checkStudentAnswer = (studentAnswer, variants) => {
            if (isHomework && !isHomeworkFinish) return false;

            let correctAnswerExist = false;
            let isCorrect = true;

            for (let i = 0; i < variants.length; i++) {
                if (variants[i].isCorrect) {
                    correctAnswerExist = true;
                    break;
                }
            }

            if (correctAnswerExist && typeof studentAnswer === 'number') {
                if (!variants[studentAnswer] || !variants[studentAnswer].isCorrect) {
                    isCorrect = false;
                }
            } else if (correctAnswerExist) {
                isCorrect = false;
            }

            return isCorrect;
        };

        const clue = [];
        for (let i = 0; i < el.variants.length; i++) {
            if (el.variants[i].isCorrect) {
                clue[i] = `${el.question} -> ${el.variants[i].value}`;
            }
        }

        if (!homeworkTasksInformation[el.uuid]) {
            this.updateHomeworkTasksInformation(el.uuid, 1);
        }

        return (
            <div className={l.dad2_example}>
                <div>
                    <div className={l.wit_exercise_title}>
                        {this.renderExerciseTitle(el, activeTabIndex, i)}
                    </div>
                    {isPresentation ? <>
                            {this.props.userInfo.userInfo.type === 'teacher' && this.props.demoLesson ?
                                <TeacherHelpInfo info={clue.map((elem) => <div className={l.exercise_clue}>{elem} /></div>)} /> : null}
                            <h2 style={{textAlign: 'center'}}>{el.question}</h2>
                        </> :
                        this.renderInputText(i, el.question, this.handleTestQuestionChange, activeTabIndex, '', '', false, 'Question')}

                    {el.variants.map((item, itemIndex) =>
                        <div className={
                            (isPresentation ? (l.test_question_item + ' ') : '') +
                            (((!isHomework || isHomeworkFinish) && typeof el.studentAnswer === 'number' && itemIndex === el.studentAnswer && item.isCorrect) ? 'test_question_item_correct' : '') +
                            (((!isHomework || isHomeworkFinish) && typeof el.studentAnswer === 'number' && itemIndex === el.studentAnswer && !item.isCorrect) ? 'test_question_item_incorrect' : '') +
                            ((isHomework && !isHomeworkFinish && typeof el.studentAnswer === 'number' && itemIndex === el.studentAnswer) ? 'test_question_item_homework' : '')
                        }
                             onClick={isPresentation ? () => {
                                 if (!checkStudentAnswer(el.studentAnswer, el.variants)) {
                                     tabs[activeTabIndex].tabPanel.lessonItems[i].studentAnswer = itemIndex;
                                     tabs[activeTabIndex].tabPanel.lessonItems[i].isSubmit = true;
                                     this.setState({ tabs });

                                     const isCorrect = (itemIndex === el.studentAnswer && item.isCorrect);

                                     this.state.socket.send(JSON.stringify({
                                         command: 'sendTestQuestionAnswer',
                                         data: {
                                             activeTabIndex: activeTabIndex,
                                             lessonItemIndex: i,
                                             answer: tabs[activeTabIndex].tabPanel.lessonItems[i].studentAnswer,
                                             isSubmit: true,
                                             isCorrect,
                                         },
                                         val: this.prepareInfo(),
                                     }));

                                     this.updateHomeworkTasksInformation(el.uuid);

                                     if (isCorrect) {
                                         this.updateHomeworkErrors(el.uuid, -1, true);
                                         const variant = random(studentAnswerSuccessSounds.length);
                                         studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                     } else {
                                         this.updateHomeworkErrors(el.uuid, 1, true);
                                         studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                     }
                                 }
                             } : () => {
                                 tabs[activeTabIndex].tabPanel.lessonItems[i].variants.forEach(variant => {
                                     variant.isCorrect = false;
                                 })
                                 tabs[activeTabIndex].tabPanel.lessonItems[i].variants[itemIndex].isCorrect = !item.isCorrect;
                                 this.setState({ tabs });
                             }}>
                            {isPresentation ?
                                <span>{item.value}</span> :
                                this.renderInputText(i, item.value, this.handleTestQuestionVariantChange, activeTabIndex, itemIndex, '', false, 'Variant')
                            }
                            <div>
                                {!isPresentation && <>
                                    <Button variant='contained'
                                            style={{backgroundColor: isPresentation ? '' : (item.isCorrect ? 'green' : '')}}
                                    >
                                        {isPresentation ? lang('Select') : item.isCorrect ? lang('Correct') : lang('Incorrect')}
                                    </Button>
                                    <Button variant='contained' color='primary'
                                            onClick={() => {
                                                this.deleteTestQuestionVariant(activeTabIndex, i, itemIndex)
                                            }}
                                    >Delete variant</Button>
                                </>}
                            </div>
                        </div>
                    )}
                    {!isPresentation &&
                        <div className={l.WIT_button_center}>
                            <Button variant='contained' color='primary'
                                    onClick={() => { this.addTestQuestionVariant(activeTabIndex, i) }}
                            >Add variant</Button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderDAD2(el, i, activeTabIndex) {
        const { text, resDustbin, resBox, isText, uniqId, clue } = el;

        const placeholder = (el.type === 'special1') ? 'I|live|in|London' : '';

        return (
            <div key={`tab_${activeTabIndex}_item_${i}`}
                 className={(el.type === 'special1') ? ' SpecialDad2 ' : ''}
            >
                {this.isNotPresentation &&
                    <div>
                        {this.renderInputText(i, text, this.handleDADChange, activeTabIndex, 0, placeholder)}
                        <Button onClick={() => this.okHandlerDAD2(el, i, activeTabIndex)} color="primary">Ok</Button>
                        <br /> <br />
                    </div>}
                {isText &&
                    <div className={l.dad2_example}>
                        <div className={l.wit_exercise_title}>
                            {this.renderExerciseTitle(el, activeTabIndex, i)}
                        </div>
                        {this.props.userInfo.userInfo.type === 'teacher' && this.props.demoLesson ?
                            <TeacherHelpInfo info={<div className={l.exercise_clue}>{clue}</div>} /> : null}
                        <>
                            <Container2
                                updateHomeworkErrors={this.updateHomeworkErrors}
                                updateHomeworkTasksInformation={this.updateHomeworkTasksInformation}
                                isHomework={this.props.isHomework}
                                isHomeworkFinish={this.state.isHomeworkFinish}
                                uniqId={uniqId}
                                resDustbin={resDustbin}
                                resBox={resBox}
                                socket={this.state.socket}
                                socketMessage={this.props.socketMessage}
                                val={this.prepareInfo()}
                            />
                            {myVarIsMobileDevice &&
                                <Preview generator={generatePreview}/>
                            }
                        </>
                    </div>}
                {/*<br/>*/}
            </div>
        );
    }

    renderEditor(el, i, activeTabIndex) {
        return (
            <div key={`tab_${activeTabIndex}_item_${i}`}>
                <Editor
                    editorState={el.editorState}

                    onChange={e => this.onChangeEditor(e, i, activeTabIndex)}
                    placeholder="Enter text..."
                />
                {/*<EditorV2*/}
                {/*    editorState={el.editorState}*/}
                {/*    wrapperClassName="demo-wrapper"*/}
                {/*    editorClassName="demo-editor"*/}
                {/*    onEditorStateChange={e => this.onChangeEditor(e, i, activeTabIndex)}*/}
                {/*    placeholder="Enter text..."*/}
                {/*/>*/}
                <br />
            </div>
        );
    }

    renderEditorTeachersNote(el, i, activeTabIndex) {
        if (['student'].includes(this.props.userInfo.userInfo.type)) {
            return (<></>);
        }

        return (
            <div className="TeachersNote" key={`tab_${activeTabIndex}_item_${i}`}>
                <div className="TeachersNoteHeader">Teacher's Note</div>
                <Editor placeholder="Enter text..."
                        editorState={el.editorState}
                        onChange={e => this.onChangeEditor(e, i, activeTabIndex)}
                /> <br />
            </div>
        );
    }

    renderWIG(el, i, activeTabIndex) {
        const { isHomework } = this.props;
        const { isHomeworkFinish, homeworkTasksInformation } = this.state;
        const { textArr, wordsArr, isRenderCode, clue } = el;

        if (!homeworkTasksInformation[el.uuid] && Array.isArray(wordsArr)) {
            this.updateHomeworkTasksInformation(el.uuid, wordsArr.length);
        }

        return (
            <div key={`tab_${activeTabIndex}_item_${i}`}>
                {this.isNotPresentation &&
                    <div className={l.WIT_formlesson}>
                        <Editor
                            editorState={el.editorState}
                            onChange={e => this.onChangeEditor(e, i, activeTabIndex)}
                            placeholder="I [live] in London"
                        />
                        <div className={l.WIG_button}>
                            <Button onClick={() => this.okHandlerWIG(el, i, activeTabIndex)} color="primary">Ok</Button>
                        </div>
                    </div>}
                {isRenderCode &&
                    <div className={l.WIT_example}>
                        <div className={l.wit_exercise_title}>
                            {this.renderExerciseTitle(el, activeTabIndex, i)}
                        </div>
                        {(this.props.userInfo.userInfo.type === 'teacher' && clue && this.props.demoLesson) ?
                            <TeacherHelpInfo info={clue.map(elem => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
                        <div className={l.wit_body}>
                            {textArr.map((elem, index) => {
                                return (
                                    <React.Fragment key={elem}>
                                        {this.prepareLineBreakText(elem)}
                                        {wordsArr[index] &&
                                            <div style={{display: 'inline-block'}}>
                                                <ReactCodeInput
                                                    // Uncaught TypeError: Cannot read properties of undefined (reading 'wordsArr')
                                                    specialClassName={
                                                        ((!isHomework || isHomeworkFinish) && this.state.tabs[activeTabIndex].tabPanel.lessonItems[i] &&
                                                            this.state.tabs[activeTabIndex].tabPanel.lessonItems[i].wordsArr) ?

                                                            this.state.tabs[activeTabIndex].tabPanel.lessonItems[i].wordsArr[index].className : ''}
                                                    type="text" className=""
                                                    fields={wordsArr[index].text.length}
                                                    values={wordsArr[index].wigVal || []}
                                                    onComplete={result => {
                                                        const { tabs } = this.state;

                                                        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                                                        if (!lessonItems[i]) return (<></>);

                                                        const wigClassname = (result === wordsArr[index].text) ? ' WIG-successful' : ' WIG-error';
                                                        lessonItems[i].wordsArr[index].className = wigClassname;

                                                        if (wigClassname === ' WIG-successful') {
                                                            const variant = random(studentAnswerSuccessSounds.length);
                                                            studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                                        } else {
                                                            this.updateHomeworkErrors(el.uuid, 1);
                                                            studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                                        }

                                                        this.state.socket.send(JSON.stringify({
                                                            command: 'handleWig',
                                                            data: {i, wordsArrIndex: index, className: wigClassname},
                                                            val: this.prepareInfo(),
                                                        }));

                                                        this.setState({tabs});
                                                    }}
                                                    onChange={wigArr => {
                                                        const { tabs } = this.state;

                                                        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                                                        if (!lessonItems[i]) return (<></>);

                                                        const trueAnswerTextLength = lessonItems[i].wordsArr[index].text.length;
                                                        const previousAnswerLength = lessonItems[i].wordsArr[index].wigVal ?
                                                            lessonItems[i].wordsArr[index].wigVal.join('').length : 0;
                                                        const currentAnswerLength = wigArr.join('').length;

                                                        if (previousAnswerLength < trueAnswerTextLength && currentAnswerLength === trueAnswerTextLength) {
                                                            this.updateHomeworkWigInformation(el.uuid, 1);
                                                        } else if (previousAnswerLength === trueAnswerTextLength && currentAnswerLength < trueAnswerTextLength){
                                                            this.updateHomeworkWigInformation(el.uuid, -1);
                                                        }

                                                        if (lessonItems[i].wordsArr[index].className.trim() === 'WIG-error') {
                                                            this.updateHomeworkErrors(el.uuid, -1);
                                                        }

                                                        lessonItems[i].wordsArr[index].wigVal = wigArr || [];
                                                        lessonItems[i].wordsArr[index].className = '';

                                                        this.setState({ tabs });
                                                        this.state.socket.send(JSON.stringify({
                                                            command: 'handleWig',
                                                            data: {i, wigVal: wigArr, wordsArrIndex: index},
                                                            val: this.prepareInfo(),
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        }
                                    </React.Fragment>
                                );
                            })} </div></div>}
            </div>
        );
    }

    renderWIT(el, i, activeTabIndex) {
        const { isHomework } = this.props;
        const { isHomeworkFinish, homeworkTasksInformation } = this.state;
        const { textArr, wordsArr, isRenderCode, clue } = el;

        if (!homeworkTasksInformation[el.uuid] && Array.isArray(wordsArr)) {
            this.updateHomeworkTasksInformation(el.uuid, wordsArr.length);
        }

        return (
            <div key={`tab_${activeTabIndex}_item_${i}`}>
                {this.isNotPresentation &&
                    <div className={l.WIT_formlesson}>
                        <Editor
                            editorState={el.editorState}
                            onChange={e => this.onChangeEditor(e, i, activeTabIndex)}
                            placeholder="I [{know};knows;knew] it"
                        />
                        <div className={l.WIT_button}>
                            <Button onClick={() => this.okHandlerWIG(el, i, activeTabIndex)} color="primary">Ok</Button>
                        </div>
                    </div>}

                {isRenderCode && <div className={l.WIT_example}>
                    <div className={l.wit_exercise_title}>
                        {this.renderExerciseTitle(el, activeTabIndex, i)}
                    </div>
                    {(this.props.userInfo.userInfo.type === 'teacher' && clue && this.props.demoLesson) ?
                        <TeacherHelpInfo info={clue.map(elem => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
                    <div className={l.wit_body}>
                        {textArr.map((elem, index) => {
                            return (
                                <React.Fragment key={elem} >
                                    {this.prepareLineBreakText(elem)}
                                    {wordsArr[index] &&
                                        <div className={((!isHomework || isHomeworkFinish) ? wordsArr[index].className : '') +
                                            ' ' + l.WIT_selected_data  + ' ' + l.WIT_Input}>
                                            <Select
                                                disabled={(!isHomework || isHomeworkFinish) && (wordsArr[index].className === l.WIT_successful)}
                                                disableUnderline
                                                MenuProps={dropdownMenuProps}
                                                value={wordsArr[index].val}
                                                onChange={event => {
                                                    const { tabs } = this.state;
                                                    const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                                                    if (!lessonItems[i]) return (<></>);

                                                    const className = (event.target.value === wordsArr[index].rightWord) ?
                                                        l.WIT_successful : l.WIT_error;

                                                    if (!lessonItems[i].wordsArr[index].className) {
                                                        this.updateHomeworkTasksInformation(el.uuid);
                                                    }

                                                    if (!lessonItems[i].wordsArr[index].className || lessonItems[i].wordsArr[index].className === l.WIT_successful) {

                                                        if (className === l.WIT_error) {
                                                            this.updateHomeworkErrors(el.uuid, 1);
                                                        }
                                                    }

                                                    if (lessonItems[i].wordsArr[index].className === l.WIT_error) {
                                                        if (className === l.WIT_successful) {
                                                            this.updateHomeworkErrors(el.uuid, -1);
                                                        }
                                                    }

                                                    lessonItems[i].wordsArr[index].className = className;
                                                    lessonItems[i].wordsArr[index].val = event.target.value;

                                                    this.setState({ tabs });

                                                    this.state.socket.send(JSON.stringify({
                                                        command: 'handleWIT',
                                                        data: {
                                                            i,
                                                            className,
                                                            val: event.target.value,
                                                            index
                                                        },
                                                        val: this.prepareInfo(),
                                                    }));

                                                    if (className === l.WIT_successful) {
                                                        const variant = random(studentAnswerSuccessSounds.length);
                                                        studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                                    } else {
                                                        studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                                    }
                                                }}
                                                input={(<Input />)}
                                            >
                                                {wordsArr[index].text.split(';').map(item => (
                                                    <MenuItem key={item} value={item}> {item} </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    }
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>}
            </div>
        );
    }

    prepareLineBreakText = text => {
        const elems = text.split('\u0001');

        if (elems.length <= 1) {
            return text;
        }

        let res = '';

        for (let i = 0; i < elems.length; i++) {
            if (i < elems.length - 1) {
                res = res + elems[i] + '<br />';
            } else {
                res = res + elems[i];
            }
        }

        return (
            <span dangerouslySetInnerHTML={{ __html: res }} />
        );
    }

    renderCWF(el, i, activeTabIndex) {
        const { isHomework } = this.props;
        const { isHomeworkFinish, homeworkTasksInformation } = this.state;
        const { textArr, wordsArr, isRenderCode, clue } = el;

        if (!homeworkTasksInformation[el.uuid] && Array.isArray(wordsArr)) {
            this.updateHomeworkTasksInformation(el.uuid, wordsArr.length);
        }

        return (
            <div key={`tab_${activeTabIndex}_item_${i}`}>
                {this.isNotPresentation &&
                    <div className={l.WIT_formlesson}>
                        <Editor
                            editorState={el.editorState}
                            onChange={e => this.onChangeEditor(e, i, activeTabIndex)}
                            placeholder="I [(be);{was};been;are] there"
                        />
                        <div className={l.WIT_button}>
                            <Button onClick={() => this.okHandlerWIG(el, i, activeTabIndex)} color="primary">Ok</Button>
                        </div>
                    </div>}
                {isRenderCode &&
                    <div className={l.WIT_example}>
                        <div className={l.wit_exercise_title}>
                            {this.renderExerciseTitle(el, activeTabIndex, i)}
                        </div>
                        {(this.props.userInfo.userInfo.type === 'teacher' && clue && this.props.demoLesson) ?
                            <TeacherHelpInfo info={clue.map(elem => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
                        <div className={l.wit_body}>
                            {textArr.map((elem, index) => {
                                return (
                                    <React.Fragment key={elem}>
                                        {this.prepareLineBreakText(elem)}
                                        {wordsArr[index] &&
                                            <div className={((!isHomework || isHomeworkFinish) ? wordsArr[index].className : '') +
                                                ' ' + l.WIT_selected_data   + ' ' + l.WIT_Input}>
                                                <Select
                                                    disabled={(!isHomework || isHomeworkFinish) && (wordsArr[index].className === l.WIT_successful)}
                                                    disableUnderline
                                                    MenuProps={dropdownMenuProps}
                                                    value={wordsArr[index].val}
                                                    onChange={event => {
                                                        const {tabs} = this.state;
                                                        const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                                                        if (!lessonItems[i]) return (<></>);

                                                        const className = (event.target.value === wordsArr[index].rightWord) ?
                                                            l.WIT_successful : l.WIT_error;

                                                        if (!lessonItems[i].wordsArr[index].className) {
                                                            this.updateHomeworkTasksInformation(el.uuid);
                                                        }

                                                        if (!lessonItems[i].wordsArr[index].className || lessonItems[i].wordsArr[index].className === l.WIT_successful) {

                                                            if (className === l.WIT_error) {
                                                                this.updateHomeworkErrors(el.uuid, 1);
                                                            }
                                                        }

                                                        if (lessonItems[i].wordsArr[index].className === l.WIT_error) {
                                                            if (className === l.WIT_successful) {
                                                                this.updateHomeworkErrors(el.uuid, -1);
                                                            }
                                                        }

                                                        lessonItems[i].wordsArr[index].className = className;
                                                        lessonItems[i].wordsArr[index].val = event.target.value;

                                                        this.state.socket.send(JSON.stringify({
                                                            command: 'handleCWF',
                                                            data: {i, className, index, val: event.target.value},
                                                            val: this.prepareInfo(),
                                                        }));
                                                        this.setState({tabs});

                                                        if (className === l.WIT_successful) {
                                                            const variant = random(studentAnswerSuccessSounds.length);
                                                            studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                                        } else {
                                                            studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                                        }
                                                    }}
                                                    input={(<Input classes={{}}/>)}
                                                >
                                                    {wordsArr[index].text.split(';').map(item => (
                                                        <MenuItem key={item} value={item}> {item} </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        }
                                    </React.Fragment>
                                );
                            })}</div>
                    </div>}
            </div>
        );
    }

    renderTabs() {
        const activeTabNumber = this.state.activeTabNumber;
        const activeTabIndex = activeTabNumber - 1;

        let disabledStyles = {};

        if ((this.props.userInfo.userInfo.type !== 'teacher' && this.props.userInfo.userInfo.type !== 'admin'
                && this.props.userInfo.userInfo.type !== 'supervisor'
            ) &&
            this.state.isUserHaveNotControl) {
            disabledStyles = { pointerEvents: 'none' };
            const appDivInputs = document.querySelectorAll('.App input');

            for (let i = 0; i < appDivInputs.length; i++) {
                appDivInputs[i].disabled = 'disabled';
            }
        }

        return (
            <TabContext value={activeTabNumber.toString()}>
                <div className={l.tab_header}>
                    <StyledAppBar position="static">
                        {!!['admin', 'teacher', 'supervisor'].includes(this.props.userInfo.userInfo.type) &&
                            <TabList variant="scrollable" scrollButtons="auto" aria-label="simple tabs example"
                                     onChange={this.tabHandleChange}
                            >
                                {this.state.tabs.map((tabItem, i) =>
                                    <StyledTab indicatorColor="primary"
                                               textColor="primary"
                                               value={tabItem.tab.value.toString()}
                                               label={ this.isPresentation ? tabItem.tab.label :
                                                   <input className="TabContextInput"
                                                          value={tabItem.tab.label}
                                                          onChange={e => {
                                                              const { tabs } = this.state;
                                                              tabs[i].tab.label = e.target.value;
                                                              this.setState(tabs);
                                                          }}
                                                   />
                                               }
                                    />
                                )}
                            </TabList>}
                        {!!['student'].includes(this.props.userInfo.userInfo.type) &&
                            <div className={"lessonEditorHeaderForStudent" + (this.props.isHomework ? " lessonEditorHeaderForStudentHomework" : "")}>
                                {this.state.lessonName}
                            </div>}
                    </StyledAppBar>
                </div>
                {this.state.tabs.map((tabItem, i) =>
                    <TabPanel
                        ref={this.LessonEditorComponentRef}
                        value={tabItem.tabPanel.value.toString()}
                    >
                        {(this.props.isHomework && this.state.isHomeworkFinish) ? this.props.renderHomeworkStudentMark() : (<></>)}
                        {!['student'].includes(this.props.userInfo.userInfo.type) && this.isPresentation && !i &&
                            <div className={l.summary_block}>
                                <div className={l.summary_header}>{lang('Summary of lesson')}</div>
                                <div> <br/>
                                    <div><b>{lang('Level: ')}</b>{this.state.courseInfo}</div>
                                    <div><b>{lang('Topic: ')}</b>{this.state.lessonName}</div>
                                    <div><b>{lang('№ of lesson: ')}</b>{this.state.numberOfLesson}</div>
                                    <div><b>{lang('Time of class: ')}</b>{this.state.timeOfClass}</div>
                                    <div><b>{lang('Key words: ')}</b>{this.state.keyWords}</div>
                                    <div><b>{lang('Grammar: ')}</b>{this.state.Grammar}</div>
                                </div>
                            </div>}
                        <div>
                            {tabItem.tabPanel.lessonItems.map((el, j) => {
                                return (
                                    <div style={ el?.name === 'PDF' ? {} : disabledStyles}>
                                        { this.isNotPresentation && <>
                                            <Button color='primary' onClick={() => {
                                                const { tabs } = this.state;
                                                let currentTab = tabs[i].tabPanel.lessonItems;
                                                currentTab.splice(j, 1);
                                                this.setState(tabs);
                                            }}>Delete</Button>
                                            {j > 0 &&
                                                <Button color='primary' onClick={() => {
                                                    const { tabs } = this.state;
                                                    let currentTab = tabs[i].tabPanel.lessonItems;
                                                    [currentTab[j - 1], currentTab[j]] = [currentTab[j], currentTab[j - 1]];
                                                    this.setState(tabs);
                                                }}>Move up</Button>}
                                            {j < tabItem.tabPanel.lessonItems.length - 1 &&
                                                <Button color='primary' onClick={() => {
                                                    const { tabs } = this.state;
                                                    let currentTab = tabs[i].tabPanel.lessonItems;
                                                    [currentTab[j + 1], currentTab[j]] = [currentTab[j], currentTab[j + 1]];
                                                    this.setState(tabs);
                                                }}>Move down</Button>}
                                        </>}
                                        {(i === activeTabIndex) && this.getItemCreateFunction(el, j, true, activeTabIndex)}
                                    </div>
                                );
                            })}
                            {(this.props.isHomework && !this.state.isHomeworkFinish)  &&
                                <div className="homeworkOkButton scale2"
                                     onClick={() => {
                                         const  { homeworkTasksInformation, homeworkErrors, fewTasksCompletedMessage } = this.state

                                         if (fewTasksCompletedMessage) return;

                                         try {
                                             let allTasksCnt = 0;
                                             let executedTasksCnt = 0;

                                             for (const [key, value] of Object.entries(homeworkTasksInformation)) {
                                                 allTasksCnt += value.numberOfResponses;
                                                 executedTasksCnt += value.currentResponsesCnt;
                                             }

                                             if ((executedTasksCnt / allTasksCnt) < this.props.homeworkEnoughAnswers) {
                                                 return this.setState({ fewTasksCompletedMessage: true });
                                             }

                                         } catch (e) {}

                                         this.setState({
                                             isHomeworkFinish: true,
                                             isUserHaveNotControl: true,
                                         });

                                         this.props.homeworkOnButtonOk(
                                             homeworkTasksInformation,
                                             homeworkErrors
                                         );

                                         this.LessonEditorComponentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                                     }}
                                >
                                    {!this.state.fewTasksCompletedMessage &&
                                        <>
                                            <div>{lang("ЗАВЕРШИТЬ ДОМАШНЕЕ ЗАДАНИЕ")}</div>
                                            <div>{lang("И ПРОВЕРИТЬ ПРАВИЛЬНОСТЬ ОТВЕТОВ")}</div>
                                        </>}
                                    {this.state.fewTasksCompletedMessage &&
                                        <>
                                            <div>{lang("ПОЖАЛУЙСТА,")}</div>
                                            <div>{lang("ВЫПОЛНИТЕ ВСЕ ЗАДАНИЯ")}</div>
                                        </>}
                                </div>
                            }
                        </div>
                    </TabPanel>
                )}
            </TabContext>
        );
    }

    onControlledDrag = (e, position) => {
        const { x, y } = position;
        this.setState({ controlledPosition: { x, y } });
        this.state.socket.send(JSON.stringify({
            command: 'onControlledDrag',
            data: { sketchId: 1, position: { x, y } },
            val: this.prepareInfo(),
        }));
    };

    tabHandleChange = (e, value) => {
        if (!['admin', 'teacher', 'supervisor'].includes(this.props.userInfo.userInfo.type)) return;

        const { tabs } = this.state;
        const lessonItems = tabs[value - 1];

        if (!lessonItems) value = 1;

        this.setState({ activeTabNumber: value });


        this.state.socket.send(JSON.stringify({
            command: 'dialogChangeTab',
            data: value,
            val: this.prepareInfo(),
        }));

        this.state.socket.send(JSON.stringify({
            command: 'setVirtualClassInfo',
            val: {
                ...this.prepareInfo(),
                vCParam: 'tabNum',
                vCValue: value,
            }
        }));
    };

    addTab = () => {
        this.setState(prevState => {
            const tabs = prevState.tabs;
            const tabCount = tabs.length + 1;
            const label = `Additional tab ${tabCount}`;

            tabs.push({
                tab: {label: label, value: tabCount},
                tabPanel: {value: tabCount, lessonItems: []}
            });

            return {
                selectItemsDialogVisible: true,
                activeTabNumber: tabs.length,
                tabs: tabs
            }
        });
    };

    deleteTab = () => {
        this.setState(prevState => {
            const { tabs, activeTabNumber } = prevState;

            if (tabs.length <= 1) { alert('You cannot delete last tab'); return; }

            const activeTabIndex = activeTabNumber - 1;
            const newTabs = [];
            let tabValue = 1;
            let newActiveTabNumber = 1;

            for (let i = 0; i < tabs.length; i++) {
                if (i !== activeTabIndex) {
                    tabs[i].tab.value = tabValue;
                    tabs[i].tabPanel.value = tabValue;
                    newTabs.push(tabs[i]);
                    tabValue++;
                }
            }

            if (activeTabNumber > 1) {
                newActiveTabNumber = activeTabNumber - 1;
            }

            return {
                selectItemsDialogVisible: true,
                activeTabNumber: newActiveTabNumber,
                tabs: newTabs
            }
        });
    }

    updateGridSize = (el, rows, columns, elIndex) => {
        this.setState(prevState => {
            const { tabs, activeTabNumber } = prevState;
            const activeTabIndex = activeTabNumber - 1;

            if (rows < 1 || columns < 1) {
                tabs[activeTabIndex].tabPanel.lessonItems[elIndex].rows = [];
                this.setState({ tabs });
                return;
            }

            const newRows = [];

            for (let i = 0; i < rows; i++) {
                if (el.rows[i]) {
                    newRows[i] = el.rows[i];
                } else {
                    newRows[i] = [];
                }

                if (columns >= newRows[i].length) {
                    for (let j = 0; j < columns; j++) {
                        if (el.rows[i] && typeof el.rows[i][j] === 'object') {
                            newRows[i][j] = el.rows[i][j];
                        } else {
                            newRows[i][j] = {
                                src: this.state.imageStubLink,
                                uuid: v1()
                            };
                        }
                    }
                } else {
                    newRows[i].splice(columns, el.rows[i].length);
                }
            }

            tabs[activeTabIndex].tabPanel.lessonItems[elIndex].rows = newRows;
            return { tabs };
        });
    }

    addTrueLiesItem = (activeTabIndex, elementIndex) => {
        this.setState(prevState => {
            const tabs = prevState.tabs;

            tabs[activeTabIndex].tabPanel.lessonItems[elementIndex].items.push({
                question: 'Test question',
                isCorrect: false,
                studentAnswer: ''
            });

            return { tabs: tabs }
        });
    }

    deleteTrueLiesItem = (activeTabIndex, elementIndex, elementItemIndex) => {
        this.setState(prevState => {
            const tabs = prevState.tabs;

            if (tabs[activeTabIndex].tabPanel.lessonItems[elementIndex].items.length <= 1) {
                alert(lang('Cannot delete last element'));
                return;
            }

            tabs[activeTabIndex].tabPanel.lessonItems[elementIndex].items.splice(elementItemIndex, 1);
            return { tabs: tabs }
        });
    }

    addTestQuestionVariant = (activeTabIndex, elementIndex) => {
        this.setState(prevState => {
            const tabs = prevState.tabs;

            tabs[activeTabIndex].tabPanel.lessonItems[elementIndex].variants.push({
                isCorrect: false,
                value: 'Test variant'
            });

            return { tabs: tabs };
        });
    }

    deleteTestQuestionVariant = (activeTabIndex, elementIndex, elementItemIndex) => {
        this.setState(prevState => {
            const tabs = prevState.tabs;

            if (tabs[activeTabIndex].tabPanel.lessonItems[elementIndex].variants.length <= 1) {
                alert(lang('Cannot delete last variant'));
                return;
            }

            tabs[activeTabIndex].tabPanel.lessonItems[elementIndex].variants.splice(elementItemIndex, 1);
            return { tabs: tabs };
        });
    }

    getRangeObject = (win) => {
        win = win || window;
        if (win.getSelection) {
            try {
                return win.getSelection();
            } catch (e) { }
        }
        return null;
    }

    selectedObjectValue = () => {
        const word = this.state.selectedWordToTranslate
        const spaceNumber = word.indexOf(' ');
        if ((word.length >= 2) && (spaceNumber === -1)) {
            this.props.selectedWordToTranslate(word);
            this.props.openDict();
        } else if (spaceNumber !== 0) {
            this.props.selectedWordToTranslate(word.substring(0, spaceNumber))
            this.props.openDict()
        }
    }

    selectedObjectCoordinates = () => {
        const scrollTop = this.props.lessonScrollTop
        const range = this.getRangeObject().toString();
        if (range.length >= 2) {
            const oRange = this.getRangeObject().getRangeAt(0);
            const oRect = oRange.getBoundingClientRect();

            this.setState({
                selectedObj: {
                    display: 'flex',
                    top: `${oRect.top - oRect.height  + scrollTop}`,
                    left: `${oRect.left - 370}`
                },
                selectedWordToTranslate: range,
            });
        } else {
            this.setState({
                selectedObj: {
                    display: 'none',
                    top: '',
                    left: '',
                },
            });
        }
    }

    startSelectedObj = () => setTimeout(this.selectedObjectCoordinates, 200);

    render() {
        const { lessonName, numberOfLesson, timeOfClass, keyWords, Grammar,
            lessonItems, selectItemsDialogVisible, sketch, tabs } = this.state;
        const selectedObjStyle = {
            display: this.state.selectedObj.display,
            top: this.state.selectedObj.top + 'px',
            left: this.state.selectedObj.left + 'px',
        }
        if (this.state.isRerend) return (<></>);

        if ((this.props.userInfo.userInfo.type !== 'teacher' && this.props.userInfo.userInfo.type !== 'admin'
            && this.props.userInfo.userInfo.type !== 'supervisor'
        ) && this.state.isUserHaveNotControl) {

            const appDivInputs = document.querySelectorAll('.App input');
            for (let i = 0; i < appDivInputs.length; i++) {
                appDivInputs[i].disabled = 'disabled';
            }
        }

        const renderLessonSummaryInput = (valName, label) => (
            <TextField label={label} fullWidth margin="normal" className="mt-0 mb-4"
                       InputLabelProps={{shrink: true}} required value={this.state[valName]}
                       onChange={t => this.setState({[valName]: t.target.value})}
            />
        );

        return (
            <DndProvider backend={myVarIsMobileDevice ? TouchBackend : HTML5Backend}>
                <div className={!this.props.isHomework ? "App AppShadow" : "HomeworkApp"}
                     onMouseUp={this.startSelectedObj}>
                    <div className='translate_word_search' style={selectedObjStyle}>
                        <div onClick={this.selectedObjectValue}>
                            <img className="right_panel_img" src={IconDictionary} alt="dictionary"/>
                        </div>
                    </div>
                    {this.isPresentation && this.props.whiteboard && this.props.whiteboard.show &&
                        <div className={'whiteBoardDiv no-cursor' + (this.props.whiteboard && this.props.whiteboard.showBg ? ' whiteBoardDivWithoutBg' : '')}>
                            <div onClick={e => e.stopPropagation()}>
                                <P5Wrapper sketch={sketch} />
                            </div>
                        </div>}
                    {this.isNotPresentation &&
                        <div className="col-12">
                            {renderLessonSummaryInput('lessonCourseId', 'Id курса')}
                            {renderLessonSummaryInput('lessonName', 'Lesson Name')}
                            {renderLessonSummaryInput('numberOfLesson', '№ of lesson')}
                            {renderLessonSummaryInput('timeOfClass', 'Time of class')}
                            {renderLessonSummaryInput('keyWords', 'Key words')}
                            {renderLessonSummaryInput('Grammar', 'Grammar')}
                        </div>}

                    {this.isNotPresentation &&
                        <div className={l.addtabs_button_panel}>
                            <Button color='primary' onClick={this.addTab}>Add tab</Button>
                            <Button color='primary' onClick={this.deleteTab}>Delete tab</Button>
                        </div>}

                    <div>
                        {this.isNotPresentation &&
                            <div className={l.selectitems_button}>
                                <Button color='primary'
                                        onClick={() => this.setState({ selectItemsDialogVisible: !selectItemsDialogVisible })}>
                                    Select items</Button>
                            </div>
                        }
                        {this.isNotPresentation && selectItemsDialogVisible &&
                            <div className={l.select_items_visible_block}>
                                <Button variant="outlined" color="primary"
                                        onClick={
                                            () => {
                                                const { tabs, activeTabNumber } = this.state;
                                                tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                    name: 'DAD1',
                                                    type: 'simple',
                                                    text: '',
                                                    resText: [],
                                                    resDustbin: [],
                                                    resBox: [],
                                                    isText: false,
                                                    uniqId: v1(),
                                                    customTitle: 'Make the sentence',
                                                });
                                                this.setState({ tabs });
                                            }
                                        }
                                >DAD1</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DAD2',
                                                text: '',
                                                resDustbin: [],
                                                resBox: [],
                                                isText: false,
                                                uniqId: v1(),
                                                customTitle: 'Make the word using the letters:\n',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >DAD2</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DAD2',
                                                type: 'special1',
                                                text: '',
                                                resDustbin: [],
                                                resBox: [],
                                                isText: false,
                                                uniqId: v1(),
                                                customTitle: 'Make the sentence:',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Sentence from words</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DAD1',
                                                type: 'special2',
                                                text: '',
                                                resText: [],
                                                resDustbin: [],
                                                resBox: [],
                                                isText: false,
                                                uniqId: v1(),
                                                customTitle: 'Make the sentence (type 2):',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Phrase to phrase</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={
                                            () => {
                                                const { tabs, activeTabNumber } = this.state;
                                                tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                    name: 'DAD1',
                                                    type: 'special3',
                                                    text: '',
                                                    resText: [],
                                                    resDustbin: [],
                                                    resBox: [],
                                                    isText: false,
                                                    uniqId: v1(),
                                                    customTitle: 'Sort the words',
                                                });
                                                this.setState({ tabs });
                                            }
                                        }
                                >Words and 2 columns</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Editor',
                                                editorState: EditorState.createEmpty(),
                                                currentVal: ''
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Editor</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'TeachersNote',
                                                editorState: EditorState.createEmpty(),
                                                currentVal: ''
                                            });
                                            this.setState({tabs});
                                        }}
                                >Teacher's Note</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'WIG',
                                                editorState: EditorState.createEmpty(),
                                                isRenderCode: false,
                                                currentVal: '',
                                                customTitle: 'Complete the sentences',
                                                uuid: v1(),
                                            });
                                            this.setState({tabs});
                                        }}
                                >Put word in gaps</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'WIT',
                                                editorState: EditorState.createEmpty(),
                                                isRenderCode: false,
                                                currentVal: '',
                                                customTitle: 'Read the text and complete the sentences',
                                                uuid: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Select word in text</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'CWF',
                                                editorState: EditorState.createEmpty(),
                                                isRenderCode: false,
                                                currentVal: '',
                                                customTitle: 'Read the text and complete the sentences',
                                                uuid: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Choose the correct word form</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'IMG',
                                                src: '',
                                                whiteboard: true,
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                                uuid: v1(),
                                                isDrawing: false,
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Insert img</Button>
                                <Button
                                    color="primary" variant="outlined"
                                    onClick={
                                        () => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Slider',
                                                src: '',
                                                items: [],
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                            });
                                            this.setState({ tabs });
                                        }
                                    }
                                >Insert Slider</Button>
                                <Button
                                    color="primary" variant="outlined"
                                    onClick={
                                        () => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Summer',
                                                text: '',
                                                showStudents: false,
                                                items: [],
                                                uuid: v1(),
                                            });
                                            this.setState({ tabs });
                                        }
                                    }
                                >How  did  you spend  your  summer</Button>
                                <Button
                                    color="primary" variant="outlined"
                                    onClick={
                                        () => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'ImgGrid',
                                                gridData: { spacing: 3 },
                                                rows: [[{
                                                    src: this.state.imageStubLink,
                                                    uuid: v1(),
                                                }]],
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                            });
                                            this.setState({tabs });
                                        }
                                    }
                                >Image grid</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={
                                            () => {
                                                const { tabs, activeTabNumber } = this.state;
                                                tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                    name: 'TrueLies',
                                                    items: [{
                                                        question: 'Test question',
                                                        isCorrect: false,
                                                        studentAnswer: ''
                                                    }],
                                                    uuid: v1(),
                                                    customTitle: 'Answer the question',
                                                });
                                                this.setState({ tabs });
                                            }
                                        }
                                >True false slider</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'testQuestion',
                                                question: 'Test question',
                                                studentAnswer: [],
                                                isSubmit: false,
                                                variants: [
                                                    { isCorrect: true, value: 'Test variant' },
                                                    { isCorrect: false, value: 'Test variant'}
                                                ],
                                                uuid: v1(),
                                                customTitle: 'Answer the question',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Test question</Button>
                                <Button
                                    color="primary" variant="outlined"
                                    onClick={
                                        () => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DnDGrid',
                                                gridData: { spacing: 3 },
                                                rows: [[{
                                                    src: this.state.imageStubLink,
                                                    uuid: v1(),
                                                }]],
                                                uuid: v1(),
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                            });
                                            this.setState({ tabs });
                                        }
                                    }
                                >DnD Img grid</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'DnDGrid2',
                                                gridData: { spacing: 3 },
                                                rows: [[{
                                                    src: this.state.imageStubLink,
                                                    uuid: v1(),
                                                }]],
                                                uuid: v1(),
                                                heightPriority: true,
                                                widthPriority: false,
                                                heightInVh: 40,
                                                widthInPercent: 100,
                                            });
                                            this.setState({ tabs });
                                        }}
                                >DnD Img grid 2</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'ButtonL',
                                                text: '',
                                                link: '',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Button</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'HorizontalLine',
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Line</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Youtube',
                                                link: '',
                                                youtubePlayerRef: null,
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Video</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;
                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'Audio',
                                                src: '',
                                                link: '',
                                                uniqId: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Audio</Button>
                                <Button color="primary" variant="outlined"
                                        onClick={() => {
                                            const { tabs, activeTabNumber } = this.state;

                                            tabs[activeTabNumber - 1].tabPanel.lessonItems.push({
                                                name: 'PDF',
                                                src: '',
                                                heightInVh: 50,
                                                widthInPercent: 50,
                                                uuid: v1(),
                                            });
                                            this.setState({ tabs });
                                        }}
                                >Insert pdf</Button>
                            </div>}
                    </div>
                    {this.renderTabs(this.state.tabs)}

                    {this.isNotPresentation &&
                        <div className={l.create_lesson_button}>
                            <Button color="primary"
                                    onClick={() => {
                                        const { lesson: lessonInfo } = this.props;
                                        const { lessonCourseId: courseId } = this.state;
                                        if (!courseId) return;

                                        let cloneTabs = tabs.map(tab =>{
                                            return {
                                                tab: {...tab.tab},
                                                tabPanel: {
                                                    value: tab.tabPanel.value,
                                                    lessonItems: tab.tabPanel.lessonItems.map(item=>{
                                                        let el = {...item};
                                                        if (el.editorState) {
                                                            el.editorState = {};
                                                        }
                                                        if (el.canvasImgContainerRef){
                                                            el.canvasImgContainerRef = {}
                                                        }
                                                        return el;
                                                    })
                                                }
                                            }
                                        });
                                        const tabsForSave = JSON.parse(JSON.stringify(cloneTabs));

                                        const apiMethod = lessonInfo ? 'dashboard/updateLesson' : 'dashboard/addLesson';

                                        callApi(apiMethod, 'post', {
                                            lessonId: lessonInfo && lessonInfo.id || 0,
                                            courseId,
                                            "name": lessonName,
                                            "page": { tabs: tabsForSave, lessonName, numberOfLesson, timeOfClass, keyWords, Grammar },
                                            "startDate": 1603792547, "endDate": 1603802547,
                                        }).then(res => {
                                            if (res && res.data.ok) {
                                                lessonInfo ? alert('Успешно обновлен') : alert('Успешно создан');
                                            } else { alert('Error_Editor_1'); }
                                        }).catch(e => {
                                            console.log('Error_Editor_2', e)
                                            alert('Error_Editor_2') ;
                                        });

                                        // let lessons = JSON.parse(localStorage.getItem('lessons')) || {};
                                        // lessons[lessonName] = { lessonName, lessonItems, tabsForSave };

                                        // if (lessonName) {
                                        //     localStorage.setItem('lessons', JSON.stringify(lessons))
                                        // }
                                    }}
                            >{this.props.lesson ? 'Изменить урок' : 'Создать урок'}</Button>
                        </div>
                    }
                </div>
            </DndProvider>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        dialogSetWhiteboard,
        dialogSetImgWhiteboard,
        selectedWordToTranslate,
        openDict
    },
    dispatch
);

const mapStateToProps = state => (
    {
        whiteboard: state.dialog.whiteboard,
        userInfo: state.auth.userInfo,
        lessonScrollTop: state.dictionary.lessonScrollTop,
        showImgWhiteboard: state.dialog.showImgWhiteboard,
        demoLesson: state.settings.demoLesson,
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonEditor));
