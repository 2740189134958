import React from 'react';

import {Alert} from "reactstrap";

const FormDialog = ({ alertMessage }) => {
    return (
        <div className="row max-width-600">
            <div className="col-md-12">
                <Alert className="shadow-lg bg-danger text-white">
                    {alertMessage ?
                        <ul className="errors">
                            <li>{alertMessage}</li>
                        </ul> : ''}
                </Alert>
            </div>
        </div>
    );
};

export default FormDialog;