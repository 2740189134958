import React, {useEffect, useState} from "react";

import { DnDGrid } from '../DnDGrid/Container';
import { switchArrayElements } from '../helpers/arrayForRandomSimulation';
import TeacherHelpInfo from "./common/TeacherHelpInfo";
import l from '../main/LessonEditor.module.scss';

const DnDGrid2 = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [gridRowsCnt, setGridRowsCnt] = useState(el?.rows?.length || 1);
    const [rows, setRows] = useState(el.rows || []);
    const [resDustbin, setResDustbin] = useState(el?.resDustbin || []);
    const [resBox, setResBox] = useState(el?.resBox || []);
    const [clue, setClue] = useState(el?.clue || []);
    const [heightPriority, setHeightPriority] = useState(el.heightPriority);
    const [heightInVh, setHeightInVh] = useState(el.heightInVh);
    const [widthInPercent, setWidthInPercent] = useState(el.widthInPercent);
    const [uuid, setUuid] = useState(el.uuid);

    useEffect(() => {
        options.changeElement({resDustbin, resBox, clue}, i, activeTabIndex);        
    },[resDustbin, resBox, clue])

    const init = () => {
        const dustbin = [];
        const box = [];
        let j = 0;
        const clue = [];
        rows.map((row, rowIndex) => {
            let rowClue = '';
    
            row.map((item, columnIndex, columnArr) => {
                if (item && item.text) {
                    item.text.split('|').map((element, i) => {
                        if (i === 0) {
                            dustbin.push({accepts: ['DnDGrid2'], lastDroppedItem: null, i: element, imgSrc: item.src});
    
                            clue += `${rowIndex + 1}x${columnIndex + 1} -> ${element}`;
    
                            if (columnIndex + 1 !== columnArr.length) {
                                rowClue += ', ';
                            }
                        }
    
                        box.push({name: element, type: 'DnDGrid2', i: j++});
                    });
                }
            });
    
            clue.push(rowClue);
        });
        setResDustbin(dustbin);
        box = switchArrayElements(box, i);
        setResBox(box);
        setClue(clue);
    }

    useEffect(() => {
        init();
    }, [rows]);

    return (
        <div className="DnDGridContainer">
            {options.userInfo.userInfo.type === 'teacher' && options.demoLesson ?
                <TeacherHelpInfo info={clue.map((elem) => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
            {resDustbin && resBox &&
                <>
                    <DnDGrid
                        updateHomeworkTasksInformation={options.updateHomeworkTasksInformation}
                        updateHomeworkErrors={options.updateHomeworkErrors}
                        isHomework={options.isHomework}
                        isHomeworkFinish={options.isHomeworkFinish}
                        uuid={uuid}
                        gridRowsCnt={gridRowsCnt}
                        heightPriority={heightPriority}
                        heightInVh={heightInVh}
                        widthInPercent={widthInPercent}
                        resDustbin={resDustbin}
                        resBox={resBox}
                        socket={options.socket}
                        socketMessage={socketMessage}
                        val={{
                            vCId: options.virtualClassId,
                            userEmail: options.userInfo.userInfo.email,
                            userType: options.userInfo.userInfo.type,
                        }}
                    />
                    {myVarIsMobileDevice &&
                        <Preview generator={generatePreview}/>
                    }
                </>}
        </div>
    );
};

export default DnDGrid2;