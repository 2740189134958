import Earth from '../../../../assets/images/levels/earth.png';
import Sphere from '../../../../assets/images/levels/sphere.png';
import Attraction from '../../../../assets/images/levels/attraction.png';
import Orbit from '../../../../assets/images/levels/orbit.png';
import ArtificialSputnik from '../../../../assets/images/levels/artificial sputnik.png';
import Astronaut from '../../../../assets/images/levels/astronaut.png';
import Sputnik from '../../../../assets/images/levels/sputnik.png';
import Comet from '../../../../assets/images/levels/comet.png';
import Meteor from '../../../../assets/images/levels/meteor.png';
import Ufo from '../../../../assets/images/levels/ufo.png';
import Mercury from '../../../../assets/images/levels/mercury.png';
import Mars from '../../../../assets/images/levels/mars.png';
import Venus from '../../../../assets/images/levels/venus.png';
import Neptune from '../../../../assets/images/levels/neptune.png';
import Uranus from '../../../../assets/images/levels/uranus.png';
import Saturn from '../../../../assets/images/levels/saturn.png';
import Jupiter from '../../../../assets/images/levels/jupiter.png';
import Star from '../../../../assets/images/levels/star.png';
import Supernova from '../../../../assets/images/levels/supernova.png';
import Galaxy from '../../../../assets/images/levels/galaxy.png';
import Epic from '../../../../assets/images/levels/epic.png';

import lang from "../../../../utils/lang";


export const LevelsData = [
    {
      levelName: lang('ЗЕМЛЯ'),
      level: 0,
      img: Earth,
    },
    {
      levelName: lang('АТМОСФЕРА'),
      level: 1,
      img: Sphere,
    },
    {
      levelName: lang('ПРИТЯЖЕНИЕ'),
      level: 3,
      img: Attraction,
    },
    {
      levelName: lang('ОРБИТА'),
      level: 5,
      img: Orbit,
    },
    {
      levelName: lang('СПУТНИК'),
      level: 10,
      img: ArtificialSputnik,
    },
    {
      levelName: lang('АСТРОНАВТ'),
      level: 15,
      img: Astronaut,
    },
    {
      levelName: lang('ЛУНА'),
      level: 20,
      img: Sputnik,
    },
    {
      levelName: lang('КОМЕТА'),
      level: 25,
      certificate: true,
      certificateText: lang('Бронзовый сертификат о прохождении курса уроков'),
      img: Comet,
    },
    {
      levelName: lang('МЕТЕОР'),
      level: 30,
      img: Meteor,
    },
    {
      levelName: lang('НЛО'),
      level: 35,
      img: Ufo,
    },
    {
      levelName: lang('МЕРКУРИЙ'),
      level: 40,
      img: Mercury,
    },
    {
      levelName: lang('МАРС'),
      level: 50,
      certificate: true,
      certificateText: lang('Серебряный сертификат о прохождении курса уроков'),
      img: Mars,
    },
    {
      levelName: lang('ВЕНЕРА'),
      level: 60,
      img: Venus,
    },
    {
      levelName: lang('НЕПТУН'),
      level: 70,
      img: Neptune,
    },
    {
      levelName: lang('УРАН'),
      level: 80,
      img: Uranus,
    },
    {
      levelName: lang('САТУРН'),
      level: 90,
      img: Saturn,
    },
    {
      levelName: lang('ЮПИТЕР'),
      level: 100,
      certificate: true,
      certificateText: lang('Золотой сертификат о прохождении курса уроков'),
      img: Jupiter,
    },
    {
      levelName: lang('ЗВЕЗДА'),
      level: 125,
      img: Star,
    },
    {
      levelName: lang('СВЕРХНОВАЯ'),
      level: 150,
      certificate: true,
      certificateText: lang('Платиновый сертификат о прохождении курса уроков'),
      img: Supernova,
    },
    {
      levelName: lang('ГАЛАКТИКА'),
      level: 175,
      img: Galaxy,
    },
    {
      levelName: lang('ЭПИК'),
      level: 200,
      certificate: true,
      certificateText: lang('Эпический сертификат о прохождении курса уроков'),
      img: Epic,
    },
]