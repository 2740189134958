import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import App from '../components/App';
import {toggleCollapsedNav, updateWindowWidth, switchLanguage, setInitUrl} from '../actions';

const mapStateToProps = state => ({
    locale: state.settings.locale,
    username: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    initURL: state.auth.initURL
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        toggleCollapsedNav,
        updateWindowWidth,
        switchLanguage,
        setInitUrl
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(App);
