import React, { useState } from 'react';
import Button from '@material-ui/core/Button/Button';

import lang from "../../../../utils/lang";

const TeacherHelpInfo = ({info}) => {
    const [ isHelpInfoOpen, setIsHelpInfoOpen ] = useState(false);

    return (
        <div className="">
            <Button onClick={() => {setIsHelpInfoOpen(!isHelpInfoOpen)}} className="TeacherHelpInfoButton"
                    color="primary" variant="contained">
                {isHelpInfoOpen ? lang('Скрыть ответ') :  lang('Показать ответ')}
            </Button>
            <div style={{display: (isHelpInfoOpen ? 'block' : 'none')}}>{info}</div>
        </div>
    );
}

export default TeacherHelpInfo;
