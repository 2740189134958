import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Footer from './Footer';
import Brand from './brand/Index';

import Config from '../config';
import { useDispatch } from "react-redux";

const MainContent = () => {
    const dispatch = useDispatch();

    function targetScroll(event) {
        dispatch({type:'LESSON_SCROLL_TOP', payload: event.target.scrollTop})
    }

    return (
        <main className="app-main-content-wrapper" onScroll={targetScroll}>
            <div className="app-main-content">
                <div className="app-wrapper">
                    <Switch>
                        <Route path={ Config.path + "/brand"} component={Brand}/>
                        <Redirect to={ Config.path + "/"} push/>
                    </Switch>
                </div>
            </div>
            <Footer/>
        </main>
    );
}


export default MainContent;
