import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as types from '../constants/exitFromLesson';
import imgBall1 from '../assets/images/lf30_editor_4MVvEX.json';
import imgBall2 from '../assets/images/57147-dandev-front-form.json';
import imgBall3 from '../assets/images/5283-mood-bubble.json';
import callApi from '../utils/call-api';
import config from '../config';
import lessonEndSound from '../../src/assets/sounds/lesson_end.mp3';
import history from '../utils/history';
import LessonCheckDialog from './virtualLesson/LessonCheckDialog';
import lang from '../utils/lang';
// import Lottie from 'react-lottie';

const lessonEndAudio = new Audio();
lessonEndAudio.src = lessonEndSound;

const ExitFromLesson = ({ lessonsArr, vCId }) => {
    const userType = useSelector(state => state.auth.userInfo.userInfo.type);
    const userEmail = useSelector(state => state.auth.userInfo.userInfo.email);
    const userId = useSelector(state => state.auth.userInfo.userInfo.id);
    const [studentLessonGrade, setStudentLessonGrade] = useState();
    const [activeLessonComment, setActiveLessonComment] = useState(false);
    const [isOpenLessonCheckDialog, setIsOpenLessonCheckDialog] = useState(false);
    // const [teacherComment, setTeacherComment] = useState('');
    const [classRoomAnswerDisplay, setClassRoomAnswerDisplay] = useState('none');
    // const [parallaxStyle, setParallaxStyle] = useState('rotateX(0deg) rotateY(0deg)');
    const activeExitFromLesson = useSelector(state => state.exitFromLesson.exitFromLesson);
    const dispatch = useDispatch();
    const [previousMessage, setPreviousMessage] = useState('');
    const socketMessage = useSelector(state => state.dialog.socketMessage);
    const socket = useSelector(state => state.settings.socket);
    const virtualClass = useSelector(state => state.settings.lessonIdent);
    const [Data, setData] = useState([]);
    const [storage] = useState(JSON.parse(localStorage.getItem('interestingFacts')));
    const [factDescription, setFactDescription] = useState();
    const [factDescriptionRu, setFactDescriptionRu] = useState();
    const [factImgName, setFactImgName] = useState();
    const [display, setDisplay] = useState('none');
    const [lottieDisplay, setLottieDisplay] = useState('none');
    const [commentDisplay, setCommentDisplay] =useState('none');
    const [displayOpacity, setDisplayOpacity] = useState(0);
    const [transitionMs] = useState('300ms');
    const [displayMarginTop, setDisplayMarginTop] = useState(-350);
    const [displayBackgroundColor, setDisplayBackgroundColor] = useState('hsla(0, 0%, 100%, 0.5)');
    const [lottieOpacity, setLottieOpacity] = useState(0);
    const [endLessonMargin, setEndLessonMargin] = useState(-600);


    useEffect(() => {
        const fetchData = async () => {
            const result = await callApi('dashboard/getVirtualLessonEndInformation', 'post', {});
            const filesData =  result && result.data && result.data.informationBlockData || [];
            const prepareData = filesData.map(el => {
                if (el.isEnabled) {
                    return el
                }
            });
            setData(prepareData);
        };

        fetchData();
    }, []);


    const imgBall1Options = {
        loop: true,
        autoplay: true,
        animationData: imgBall1,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const imgBall2Options = {
        loop: true,
        autoplay: true,
        animationData: imgBall2,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const imgBall3Options = {
        loop: true,
        autoplay: true,
        animationData: imgBall3,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    if ((previousMessage !== JSON.stringify(socketMessage)) && (socketMessage.command === 'exitFromClassRoom')) {
        setActiveLessonComment(socketMessage.data);
        dispatch({type: types.CLOSE_EXIT_FROM_LESSON, payload: socketMessage.data});
        setPreviousMessage(JSON.stringify(socketMessage));
        lessonEndAudio.play(); lessonEndAudio.volume = global.soundsVolume;
    }


    function closeExitFromClassRoom() {
        dispatch({type: types.CLOSE_EXIT_FROM_LESSON, payload: false});
        if (activeLessonComment) {
            setActiveLessonComment(false);
        }

        return history.push('/');
    }

    function closeExitFromClassDialog() {
        dispatch({type: types.CLOSE_EXIT_FROM_LESSON, payload: false});
        if (activeLessonComment) {
            setActiveLessonComment(false);
        }
    }

    function mouseMove(event) {
        const currentTarget = event.currentTarget.getBoundingClientRect();
        const divWidth = event.currentTarget.clientWidth;
        const divHeight = event.currentTarget.clientHeight;
        const pageY = (event.pageX - Math.trunc(currentTarget.left) - (divWidth / 2)) / 100;
        const pageX = (event.pageY - Math.trunc(currentTarget.top) - (divHeight / 2)) / 100;
        // setParallaxStyle(`rotateX(${pageX * 3}deg) rotateY(${-pageY * 1.2}deg)`)
    }

    function sendCommentLesson() {
        console.log(userType)
        console.log('send comment lesson')
    }

    function giveHomeWorkForLesson() {
        console.log('give home work')
    }

    function notGiveHomeWorkForLesson() {
        console.log('not give home work')
    }

    function closeEndLessonPanel() {
        // socket.send(JSON.stringify({
        //     command: 'finishLesson',
        //     val: {
        //         vCId: virtualClass && virtualClass.id,
        //         userEmail,
        //         userType,
        //         teacherComment,
        //     }
        // }));

        closeExitFromClassRoom();
    }

    function rateVirtualLesson() {
        callApi('student/rateVirtualLesson', 'post', {
            virtualLessonId: virtualClass && virtualClass.id,
            id: userId,
            markValue: studentLessonGrade === 'Fail' ? 1 : (studentLessonGrade === 'Normal' ? 2 : 3),
        });

        closeExitFromClassRoom();
    }

    function studentsGrade(value) {
        setStudentLessonGrade(value)
    }

    useEffect(() => {
        function getFact() {
            if (storage && storage.length < Data?.length) {
                storage.push(storage.length);
                localStorage.setItem('interestingFacts', JSON.stringify(storage));
                if (storage?.length && Data[storage.length - 1]) {
                    setFactDescription(Data[storage.length - 1].description);
                    setFactDescriptionRu(Data[storage.length - 1].descriptionRu) ;
                }

                if (storage?.length && Data[storage.length - 1] && Data[storage.length - 1].systemFileId) {
                    setFactImgName(Data[storage.length - 1].systemFileId?.name);
                }
            } else if (storage && storage.length === Data.length && Data[0]) {

                if (Data[0]) {
                    setFactDescription(Data[0].description);
                    setFactDescriptionRu(Data[0].descriptionRu);
                }

                if (Data[0] && Data[0].systemFileId) {
                    setFactImgName(Data[0].systemFileId?.name)
                }

                localStorage.setItem('interestingFacts', JSON.stringify([0]))
            } else if (Data.length) {
                if (Data[0]) {
                    setFactDescription(Data[0].description);
                    setFactDescriptionRu(Data[0].descriptionRu);
                }

                if (Data[0] && Data[0].systemFileId) {
                    setFactImgName(Data[0].systemFileId.name);
                }


                localStorage.setItem('interestingFacts', JSON.stringify([0]));
            }
        }
        getFact()
    }, [Data])

    const viewEndLessonComment = () => {
        return (
            <div className='end_lesson_container'
                 style={{ display: commentDisplay}}>
                <div className='end_lesson_main'
                    // style={{transform: parallaxStyle}}
                >
                    <div className='interesting_text'>
                        <div className="interestingFactsHeader">{lang("Интересные факты об английском языке")}</div>
                        <hr/>
                        <div className='interesting_text_content'>
                            <div className='interesting_text_img' style={{backgroundImage: `url(${config.imgUrlFromBD + factImgName})`}}>
                            </div>
                            <div className='interesting_facts'>
                                {factDescription}
                                <hr/>
                                {factDescriptionRu}
                            </div>
                        </div>
                    </div>
                    {/*{userType !== 'student' ?*/}
                    {/*    <div className='comment_recommendations'>*/}
                    {/*        <span style={{fontSize: '15px'}}>*/}
                    {/*            Оставьте ваш коментарий об уроке для студентов и их родителей(обязательно).</span>*/}
                    {/*        <br/>*/}
                    {/*        <div className='recommendations_text'>*/}
                    {/*            <div>Минимум 150 символов, маскимум 500 сиволов<br/></div>*/}
                    {/*            Вы так же можете сделать это через свой личный кабинет в течении 12 часов после окончания урока*/}
                    {/*        </div>*/}
                    {/*    </div> :*/}
                    {/*    <></>*/}
                    {/*}*/}
                    {/*{userType !== 'student' ? <div className='comment_container'>*/}
                    {/*<textarea*/}
                    {/*    id='textarea_end_lesson'*/}
                    {/*    name="text"*/}
                    {/*    cols="10"*/}
                    {/*    rows="14"*/}
                    {/*    value={teacherComment}*/}
                    {/*    onChange={e => setTeacherComment(e.target.value)}*/}
                    {/*/>*/}
                    {/*</div> : null}*/}
                    {userType !== 'student' ?
                        <div style={{display: 'none'}} className='homework_panel'>
                            <div className='button_end_lesson give_homework' onClick={giveHomeWorkForLesson}>{lang("Назначить домашнюю работу")}</div>
                            <div className='button_end_lesson not_give_homework' onClick={notGiveHomeWorkForLesson}>{lang("Не назначать домашнюю работу")}</div>
                        </div>
                        :
                        <>
                            <div className="rateThisActivity">{lang("Оцените это занятие")}</div>
                            <div className='end_lesson_grades'>
                                <div className={studentLessonGrade !== 'Fail' ?
                                    'end_lesson_grade black_white_grade' : 'end_lesson_grade'}
                                     onClick={()=>studentsGrade('Fail')}>
                                    <div className='end_lesson_grade_img end_lesson_grade_one'/>
                                    <div className={studentLessonGrade && studentLessonGrade !== 'Fail' ? 'end_lesson_grade_text' : null}>
                                        {lang("КАТАСТРОФА")}
                                    </div>
                                </div>
                                <div className={studentLessonGrade !== 'Normal' ?
                                    'end_lesson_grade black_white_grade' : 'end_lesson_grade'}
                                     onClick={()=>studentsGrade('Normal')}>
                                    <div className='end_lesson_grade_img end_lesson_grade_two'/>
                                    <div className={studentLessonGrade && studentLessonGrade !== 'Normal' ? 'end_lesson_grade_text' : null}>
                                        {lang("НОРМАЛЬНО")}
                                    </div>

                                </div>
                                <div className={studentLessonGrade !== 'Epic' ?
                                    'end_lesson_grade black_white_grade' : 'end_lesson_grade'}
                                     onClick={()=>studentsGrade('Epic')}>
                                    <div className='end_lesson_grade_img end_lesson_grade_three'/>
                                    <div className={studentLessonGrade && studentLessonGrade !== 'Epic' ? 'end_lesson_grade_text' : null}>
                                        {lang("ЭПИК")}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        {userType !== 'student' ?
                            <div className='button_end_lesson close_end_lesson'
                                 onClick={closeEndLessonPanel}>{lang("Завершить")}</div>
                            :
                            <div className='button_end_lesson close_end_lesson close_end_lesson_student'
                                 onClick={rateVirtualLesson}>{lang("Отправить оценку и закрыть")}</div>
                        }

                        {userType !== 'student' ?
                            <div className='button_end_lesson close_end_lesson'
                                 style={{ marginLeft: '2%' }}
                                 onClick={() => setIsOpenLessonCheckDialog(true)}>{lang("Задать домашнюю работу")}</div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const openExitClassRoomComment = () => {
        if (['admin', 'teacher'].includes(userType) && virtualClass) {
            callApi('teacher/completeVL', 'post', {
                virtualLessonId: virtualClass.id,
            }).then( res => {
                if (res && res.data.ok) {
                } else {
                    alert(lang('Не удалось завершить занятие'));
                }
            }).catch(() => {
                alert(lang('Не удалось завершить занятие'));
            });

            setActiveLessonComment(true);
            socket.send(JSON.stringify({
                command: 'exitFromClassRoom',
                data: true,
                val: {vCId: virtualClass.id, userEmail: userEmail, userType: userType}
            }));

            socket.send(JSON.stringify({
                command: 'finishLesson',
                val: {
                    vCId: virtualClass && virtualClass.id,
                    userEmail,
                    userType,
                    // teacherComment,
                }
            }));
        }
    }

    const exitClassRoomAnswer = () =>{
        return (
            <div style={{display: classRoomAnswerDisplay}}>
                {['admin', 'teacher'].includes(userType) ?
                    <div className='exit_from_lesson' style={{marginTop: displayMarginTop}}>
                        <div className='exit_from_lesson_text'>
                            {lang("Вы уверены, что ваше занятие окончено?")}
                        </div>
                        <div className='exit_from_lesson_yes exit_from_lesson_button'
                             onClick={openExitClassRoomComment}>
                            {lang("Да,")} <br/>{lang("занятие окончено")}
                        </div>
                        <div className='exit_from_lesson_no exit_from_lesson_button'
                             onClick={closeExitFromClassDialog}>
                            {lang("Нет,")} <br/>{lang("вернуться в виртуальный класс")}
                        </div>
                    </div>
                    : null}
            </div>
        )
    }

    const lottieImg = () => {
        return (
            <div style={{opacity: lottieOpacity, transition: transitionMs, display: lottieDisplay}}>
                <div className="ExitLessonBackgroundContainer">
                    <div className="ExitLessonBackgroundBottomLeft"/>
                    <div className="ExitLessonBackgroundBottomCenter"/>
                    <div className="ExitLessonBackgroundBottomRight"/>
                    <div className="ExitLessonBackgroundTopLeft"/>
                    <div className="ExitLessonBackgroundBottomAll"/>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (activeExitFromLesson) {
            setDisplay('flex');
            setTimeout(setDisplayOpacity, 100, 1);
            if (activeLessonComment) {
                setTimeout(setClassRoomAnswerDisplay, 10, 'none');
                setTimeout(setLottieDisplay, 100, 'flex');
                setTimeout(setCommentDisplay, 100, 'flex');
                setTimeout(setDisplayBackgroundColor, 100, '#fff');
                setTimeout(setLottieOpacity, 300, 1);
                setTimeout(setEndLessonMargin, 600, 200);
                setTimeout(setEndLessonMargin, 800, 100);
            } else {
                setTimeout(setClassRoomAnswerDisplay, 200, 'flex');
                setTimeout(setDisplayMarginTop, 400, 100);
                setTimeout(setDisplayMarginTop, 600, 20);
            }
        } else {
            setTimeout(setEndLessonMargin, 10, 200);
            setTimeout(setEndLessonMargin, 210, -600);
            setTimeout(setDisplayMarginTop, 10, 100);
            setTimeout(setDisplayMarginTop, 210, -350);
            setTimeout(setLottieOpacity, 510, 0);
            setTimeout(setDisplayOpacity, 510, 0);
            setTimeout(setCommentDisplay, 710, 'none');
            setTimeout(setLottieDisplay, 710, 'none');
            setTimeout(setDisplay, 710, 'none');
        }
    }, [activeExitFromLesson, activeLessonComment])


    const dialogSelectLesson = homeworkId => {
        callApi('dashboard/updateVlHomeworkInfo', 'POST', {
            homeworkId,
            id: vCId,
        }).then(res => {
            if (res && res.data.ok) {
                setIsOpenLessonCheckDialog(false);
            } else {
                alert(lang("Возникла ошибка"));
            }
        }).catch(e => {
            _(e);
        });
    }

    return (
        <div style={{display: display}}>
            <LessonCheckDialog
                dialogSelectLesson={dialogSelectLesson}
                HideDialog={() => setIsOpenLessonCheckDialog(false)}
                open={isOpenLessonCheckDialog}
                lessonsArr={lessonsArr.filter(el => !!el.isForHomework)}
                selectionType="homework"
            />

            <div className='exit_from_lesson_container'
                 style={{opacity: displayOpacity, transition: transitionMs, backgroundColor: displayBackgroundColor}}
                 onMouseMove={mouseMove}>
                {lottieImg()}
                {exitClassRoomAnswer()}
                {viewEndLessonComment()}
            </div>
        </div>
    )
}

export default ExitFromLesson;