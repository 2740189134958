import {injectIntl} from 'react-intl';
import Form from './Form';

class Add extends Form {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            formAction: 'brand/not-cpa',
            formType: 'add',
            formTitle: 'audit.addDomain',
            formDialogTitleId: 'audit.addDomain',
            formDialogContentId: 'audit.domainAddedSuccessfully',
            applyTextId: 'button.createAndContinue',
            saveTextId: 'button.create',
            saveMethod: 'post'
        };
    }

    closeDialog() {
        this.setState({
            dialogVisible: false,
            domain: {
                value: '',
                isValid: true
            },
            comment: {
                value: '',
                isValid: true
            },
            pattern: {
                value: '',
                isValid: true
            },
            isEnable: true
        });
    }
}

export default injectIntl(Add);
