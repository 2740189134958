import studentSuccessAnswerSound1 from '../../../assets/sounds/student_answer_success_1.mp3';
import studentSuccessAnswerSound2 from '../../../assets/sounds/student_answer_success_2.mp3';
import studentSuccessAnswerSound3 from '../../../assets/sounds/student_answer_success_3.mp3';
import studentSuccessAnswerSound4 from '../../../assets/sounds/student_answer_success_4.mp3';
import studentAnswerWrongSound from '../../../assets/sounds/student_answer_wrong.mp3';

const studentSuccessAnswerAudio1 = new Audio();
studentSuccessAnswerAudio1.src = studentSuccessAnswerSound1;

const studentSuccessAnswerAudio2 = new Audio();
studentSuccessAnswerAudio2.src = studentSuccessAnswerSound2;

const studentSuccessAnswerAudio3 = new Audio();
studentSuccessAnswerAudio3.src = studentSuccessAnswerSound3;

const studentSuccessAnswerAudio4 = new Audio();
studentSuccessAnswerAudio4.src = studentSuccessAnswerSound4;

export const studentAnswerWrongAudio = new Audio();
studentAnswerWrongAudio.src = studentAnswerWrongSound;

export const studentAnswerSuccessSounds = [
    studentSuccessAnswerAudio1, studentSuccessAnswerAudio2,
    studentSuccessAnswerAudio3, studentSuccessAnswerAudio4
];
