import React, {useState} from 'react';
// import Lottie from 'react-lottie';
import callApi from "../../../../utils/call-api";
import {v4} from '../../../../utils/uuid';
import './AddLottie.css';

const AddLottie = ({setActiveAdd, setActiveList}) => {
    const [selectedFile, setSelectedFile] = useState({});
    const [selectedObjName, setSelectedObjName] = useState('');
    const [selectedObjId, setSelectedObjId] = useState('');
    const [selectedObjDescription, setSelectedObjDescription] = useState('');
    const [enterObjName, setEnterObjName] = useState(false);
    const [showToAll, setShowToAll] = useState(false);
    const [showPrivat, setShowPrivat] = useState(false);
    const textarea = document.getElementById('lottie_upload_textarea');

    const lang = textId => textId;

    async function saveLottie() {

        if(selectedObjName) {
            const formData = new FormData();
            let fileId = v4();

            if(!Object.keys(selectedFile).length) {
                alert(lang('Please select upload file'));

                return;
            }

            if (selectedObjDescription) {
                formData.append('description', selectedObjDescription);
            }

            if (selectedObjId) {
                fileId = selectedObjId;
            }
        

            formData.append('type', 'lottie');
            formData.append('fileId', fileId);
            formData.append('name', selectedObjName);
            formData.append('isCommon', showToAll);
            formData.append('isIndividual', showPrivat);
            formData.append('file', selectedFile.image);

            await callApi('dashboard/uploadFileAdmin', 'post', formData).then(res => {
    
                if (res && res.data.ok) {
                    console.log(res.data.message);
                    alert(lang(res.data.message));
                } else {
                    alert(lang(res.data.message));
                }
            }).catch(err => {
                alert(lang('Cannot upload file'));
                console.log(err);
            });

            setEnterObjName(false);
            setSelectedObjName('');
            setSelectedObjId('');
            textarea.value = '';
            setActiveAdd(false);
            setActiveList(true);
        } else {
            setEnterObjName(true);
        }
    }

    function uploadLottieCancel() {
        setActiveAdd(false);
        setActiveList(true);
    }

    const imgUploadOptions = {
        loop: true,
        autoplay: true,
        animationData: selectedFile.image,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
            <div>
                {/* <Lottie
                options={imgUploadOptions}
                width={250}
                height={250}
            />*/}
                <div className='lottie_upload_file'>
                    Загрузите файл: <input onChange={e => setSelectedFile({image: e.target.files[0]})} name="userfile" type="file" accept='.jpeg, .jpg, .json'/>
                </div>
                <div className='lottie_upload_name'>
                    <p>Введите имя файла:</p>
                    <input
                        type="text"
                        placeholder={enterObjName && selectedObjName.length === 0 ? 'Введите имя' : null}
                        className={enterObjName && selectedObjName.length === 0 ? 'lottie_upload_input' : null}
                        value={selectedObjName}
                        defaultValue=''
                        onInput={e => setSelectedObjName(e.target.value)}/>
                </div>
                <div className='lottie_upload_name'>
                    <p>Введите id файла:</p>
                    <input
                        type="text"
                        value={selectedObjId}
                        defaultValue=''
                        onInput={e => setSelectedObjId(e.target.value)}/>
                </div>
                <div className='lottie_upload_description'>
                    <p>Введите описание файла:</p>
                    <div className='lottie_upload_text'>
                        <textarea id="lottie_upload_textarea" cols="30" rows="10" onInput={e => setSelectedObjDescription(e.target.value)}></textarea>
                    </div>

                </div>
                <div className='check_lottie_show'>
                    <div>
                        <input type="checkbox" onClick={e => setShowToAll(e.target.checked)}/>
                        <span>Показывать всем</span>
                    </div>
                    <div>
                        <input type="checkbox" onClick={e => setShowPrivat(e.target.checked)}/>
                        <span>Показывать индивидуально</span>
                    </div>
                </div>
                <button className='lottie_upload_button' onClick={saveLottie}>Сохранить</button>
                <button className='lottie_upload_button' onClick={uploadLottieCancel}>Отмена</button>
            </div>
    )
}

export default AddLottie;