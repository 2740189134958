/* global localStorage */
import * as types from '../constants/brand';

const initialState = {
    landingBiddersTablePage: JSON.parse(localStorage.getItem(types.LANDING_BIDDERS_TABLE_PAGE) || null) || 0,
    landingBiddersTablePageSize: JSON.parse(localStorage.getItem(types.LANDING_BIDDERS_TABLE_PAGE_SIZE) || null) || 10,
    landingBiddersTableSorting: JSON.parse(localStorage.getItem(types.LANDING_BIDDERS_TABLE_SORTING) || null) || [{columnName: 'domain', direction: 'asc'}],

    whiteDomainsTablePage: JSON.parse(localStorage.getItem(types.WHITE_DOMAINS_TABLE_PAGE) || null) || 0,
    whiteDomainsTablePageSize: JSON.parse(localStorage.getItem(types.WHITE_DOMAINS_TABLE_PAGE_SIZE) || null) || 10,
    whiteDomainsTableSorting: JSON.parse(localStorage.getItem(types.WHITE_DOMAINS_TABLE_SORTING) || null) || [{columnName: 'domain', direction: 'asc'}],

    notCpaTablePage: JSON.parse(localStorage.getItem(types.NOT_CPA_TABLE_PAGE) || null) || 0,
    notCpaTablePageSize: JSON.parse(localStorage.getItem(types.NOT_CPA_TABLE_PAGE_SIZE) || null) || 10,
    notCpaTableSorting: JSON.parse(localStorage.getItem(types.NOT_CPA_TABLE_SORTING) || null) || [{columnName: 'domain', direction: 'asc'}],

    tasksTablePage: JSON.parse(localStorage.getItem(types.LESSONS_TABLE_PAGE) || null) || 0,
    tasksTablePageSize: JSON.parse(localStorage.getItem(types.LESSONS_TABLE_PAGE_SIZE) || null) || 10,
    tasksTableSorting: JSON.parse(localStorage.getItem(types.LESSONS_TABLE_SORTING) || null) || [{columnName: 'id', direction: 'asc'}],

    reportTablePage: JSON.parse(localStorage.getItem(types.REPORT_TABLE_PAGE) || null) || 0,
    reportTablePageSize: JSON.parse(localStorage.getItem(types.REPORT_TABLE_PAGE_SIZE) || null) || 10,
    reportTableSorting: JSON.parse(localStorage.getItem(types.REPORT_TABLE_SORTING) || null) || [{columnName: 'createdAt', direction: 'desc'}],
    isRoutesShow: JSON.parse(localStorage.getItem(types.REPORT_TABLE_SHOW_ROUTES) || null) || false
};

export default function brand(state = initialState, action) {
    switch (action.type) {
        case types.LANDING_BIDDERS_TABLE_PAGE:
            return {
                ...state,
                landingBiddersTablePage: action.payload.value
            };

        case types.LANDING_BIDDERS_TABLE_PAGE_SIZE:
            return {
                ...state,
                landingBiddersTablePageSize: action.payload.value
            };

        case types.LANDING_BIDDERS_TABLE_SORTING:
            return {
                ...state,
                landingBiddersTableSorting: action.payload.value
            };

        case types.WHITE_DOMAINS_TABLE_PAGE:
            return {
                ...state,
                whiteDomainsTablePage: action.payload.value
            };

        case types.WHITE_DOMAINS_TABLE_PAGE_SIZE:
            return {
                ...state,
                whiteDomainsTablePageSize: action.payload.value
            };

        case types.WHITE_DOMAINS_TABLE_SORTING:
            return {
                ...state,
                whiteDomainsTableSorting: action.payload.value
            };

        case types.NOT_CPA_TABLE_PAGE:
            return {
                ...state,
                notCpaTablePage: action.payload.value
            };

        case types.NOT_CPA_TABLE_PAGE_SIZE:
            return {
                ...state,
                notCpaTablePageSize: action.payload.value
            };

        case types.NOT_CPA_TABLE_SORTING:
            return {
                ...state,
                notCpaTableSorting: action.payload.value
            };

        case types.LESSONS_TABLE_PAGE:
            return {
                ...state,
                tasksTablePage: action.payload.value
            };

        case types.LESSONS_TABLE_PAGE_SIZE:
            return {
                ...state,
                tasksTablePageSize: action.payload.value
            };

        case types.LESSONS_TABLE_SORTING:
            return {
                ...state,
                tasksTableSorting: action.payload.value
            };

        case types.REPORT_TABLE_PAGE:
            return {
                ...state,
                reportTablePage: action.payload.value
            };

        case types.REPORT_TABLE_PAGE_SIZE:
            return {
                ...state,
                reportTablePageSize: action.payload.value
            };

        case types.REPORT_TABLE_SORTING:
            return {
                ...state,
                reportTableSorting: action.payload.value
            };
        case types.REPORT_TABLE_SHOW_ROUTES:
            return {
                ...state,
                isRoutesShow: action.payload.value
            };

        default:
            return state;
    }
}
