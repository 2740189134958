import React from "react";
import s from "./../SuperAdmin.module.css";

class French extends React.Component {

    initTogglesState = {
        isActiveDropdown: false,
        isActiveToggleTeacher1: false,
        isActiveToggleStudent1: false,
        isActiveToggleContact1: false,
        isActiveToggleTeacher2: false,
        isActiveToggleStudent2: false,
        isActiveToggleContact2: false,
        isActiveToggleTeacher3: false,
        isActiveToggleStudent3: false,
        isActiveToggleContact3: false,
        isActiveToggleCourses1: false,
        isActiveToggleCourses2: false,
        isActiveToggleCourses3: false,
    };

    state = {...this.initTogglesState };


    ToggleDropdown = () => {
        this.setState({isActiveDropdown: !this.state.isActiveDropdown})
    }

    ToggleWindow = name => {
        this.setState({...this.initTogglesState, [name]: !this.state[name]})
    }


    render() {


        return (



            <div className={s.admins_list}>
                <div className={s.admins_list_item}>
                    <div className={s.name}>Сергей Сергеев</div>
                    <div className={s.admins_item_right_block}>


                        <div className={s.supervised_teachers}>
                            <div className={s.supervised_teachers_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleTeacher1')}>
                                <div className={s.supervised_teachers_list}>Supervised by (1/1)</div>
                                <div
                                    className={this.state.isActiveToggleTeacher1 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>
                            </div>

                            <div
                                className={this.state.isActiveToggleTeacher1 === true ? s.dropdown_visible + ' ' + s.dropdown_teacher : s.dropdown_teacher_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}>+ Add
                                    the
                                    new supervisor
                                </div>

                                <div className={s.dropdown_teacher_link}>Иван Иванов (01.01.2020)</div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Максим
                                    Максимов
                                    (01.01.2020 - 01.06.2020)
                                </div>
                            </div>
                        </div>


                        <div className={s.supervised_students}>
                            <div className={s.supervised_student_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleStudent1')}>
                                <div className={s.supervised_students_list}>Students (3/2)</div>
                                <div
                                    className={this.state.isActiveToggleStudent1 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>

                            </div>
                            <div
                                className={this.state.isActiveToggleStudent1 === true ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}>+ Add
                                    the
                                    new student
                                </div>
                                <div className={s.dropdown_teacher_link}>Иван Иванов
                                    (01.01.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Анна Сидорова (03.08.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Михаил Михайлов (01.09.2020)
                                </div>


                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Артем
                                    Артемьев
                                    (01.03.2020-04.05.2020)
                                </div>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Андрей
                                    Андреев
                                    (01.01.2019-01.01.2020)
                                </div>

                            </div>
                        </div>


                        <div className={s.supervised_students}>
                            <div className={s.supervised_student_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleCourses1')}>
                                <div className={s.supervised_students_list}>Courses (3/1)</div>
                                <div
                                    className={this.state.isActiveToggleCourses1 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>

                            </div>
                            <div
                                className={this.state.isActiveToggleCourses1 === true ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}>+ Add
                                    the
                                    new school
                                </div>
                                <div className={s.dropdown_teacher_link}>Kids
                                    (01.01.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Junior (03.03.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Chinese (05.05.2020)
                                </div>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Pro
                                    (01.01.2019-01.02.2020)
                                </div>
                            </div>
                        </div>

                        <div className={s.date}>01.09.2020</div>

                        <div className={s.contact}>
                            <div className={s.supervised_student_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleContact1')}>
                                <div className={s.supervised_students_list}>Contact</div>
                                <div
                                    className={this.state.isActiveToggleContact1 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>

                            </div>
                            <div
                                className={this.state.isActiveToggleContact1 === true ? s.dropdown_visible + ' ' + s.dropdown_contact : s.dropdown_student_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571c32195480adaa0d37_chat-2.png"></img>
                                    <div>CHAT</div>
                                </div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09586a2ae5446fc1a024d9_envelope.png"></img>
                                    <div>kopaneva@mail.ru</div>
                                </div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                    <div>+7 984 111 11 11</div>
                                </div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                    <div>+7 984 111 22 33 (Mother)</div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                <div className={s.devide_line}></div>

                <div className={s.admins_list_item}>
                    <div className={s.name}>Анна Копанева</div>
                    <div className={s.admins_item_right_block}>


                        <div className={s.supervised_teachers}>
                            <div className={s.supervised_teachers_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleTeacher2')}>
                                <div className={s.supervised_teachers_list}>Supervised by (1/1)</div>
                                <div
                                    className={this.state.isActiveToggleTeacher2 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>
                            </div>

                            <div
                                className={this.state.isActiveToggleTeacher2 === true ? s.dropdown_visible + ' ' + s.dropdown_teacher : s.dropdown_teacher_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}>+ Add
                                    the
                                    new supervisor
                                </div>

                                <div className={s.dropdown_teacher_link}>Иван Иванов (01.01.2020)</div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Максим
                                    Максимов
                                    (01.01.2020 - 01.06.2020)
                                </div>
                            </div>
                        </div>


                        <div className={s.supervised_students}>
                            <div className={s.supervised_student_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleStudent2')}>
                                <div className={s.supervised_students_list}>Students (3/2)</div>
                                <div
                                    className={this.state.isActiveToggleStudent2 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>

                            </div>
                            <div
                                className={this.state.isActiveToggleStudent2 === true ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}>+ Add
                                    the
                                    new student
                                </div>
                                <div className={s.dropdown_teacher_link}>Иван Иванов
                                    (01.01.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Анна Сидорова (03.08.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Михаил Михайлов (01.09.2020)
                                </div>


                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Артем
                                    Артемьев
                                    (01.03.2020-04.05.2020)
                                </div>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Андрей
                                    Андреев
                                    (01.01.2019-01.01.2020)
                                </div>

                            </div>
                        </div>


                        <div className={s.supervised_students}>
                            <div className={s.supervised_student_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleCourses2')}>
                                <div className={s.supervised_students_list}>Courses (3/1)</div>
                                <div
                                    className={this.state.isActiveToggleCourses2 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>

                            </div>
                            <div
                                className={this.state.isActiveToggleCourses2 === true ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}>+ Add
                                    the
                                    new school
                                </div>
                                <div className={s.dropdown_teacher_link}>Kids
                                    (01.01.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Junior (03.03.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Chinese (05.05.2020)
                                </div>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Pro
                                    (01.01.2019-01.02.2020)
                                </div>
                            </div>
                        </div>

                        <div className={s.date}>01.09.2020</div>

                        <div className={s.contact}>
                            <div className={s.supervised_student_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleContact2')}>
                                <div className={s.supervised_students_list}>Contact</div>
                                <div
                                    className={this.state.isActiveToggleContact2 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>

                            </div>
                            <div
                                className={this.state.isActiveToggleContact2 === true ? s.dropdown_visible + ' ' + s.dropdown_contact : s.dropdown_student_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571c32195480adaa0d37_chat-2.png"></img>
                                    <div>CHAT</div>
                                </div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09586a2ae5446fc1a024d9_envelope.png"></img>
                                    <div>kopaneva@mail.ru</div>
                                </div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                    <div>+7 984 111 11 11</div>
                                </div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                    <div>+7 984 111 22 33 (Mother)</div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                <div className={s.devide_line}></div>
                <div className={s.admins_list_item}>
                    <div className={s.name}>Корнилов Даниил</div>
                    <div className={s.admins_item_right_block}>


                        <div className={s.supervised_teachers}>
                            <div className={s.supervised_teachers_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleTeacher3')}>
                                <div className={s.supervised_teachers_list}>Supervised by (1/1)</div>
                                <div
                                    className={this.state.isActiveToggleTeacher3 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>
                            </div>

                            <div
                                className={this.state.isActiveToggleTeacher3 === true ? s.dropdown_visible + ' ' + s.dropdown_teacher : s.dropdown_teacher_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}>+ Add
                                    the
                                    new supervisor
                                </div>

                                <div className={s.dropdown_teacher_link}>Иван Иванов (01.01.2020)</div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Максим
                                    Максимов
                                    (01.01.2020 - 01.06.2020)
                                </div>
                            </div>
                        </div>


                        <div className={s.supervised_students}>
                            <div className={s.supervised_student_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleStudent3')}>
                                <div className={s.supervised_students_list}>Students (3/2)</div>
                                <div
                                    className={this.state.isActiveToggleStudent3 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>

                            </div>
                            <div
                                className={this.state.isActiveToggleStudent3 === true ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}>+ Add
                                    the
                                    new student
                                </div>
                                <div className={s.dropdown_teacher_link}>Иван Иванов
                                    (01.01.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Анна Сидорова (03.08.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Михаил Михайлов (01.09.2020)
                                </div>


                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Артем
                                    Артемьев
                                    (01.03.2020-04.05.2020)
                                </div>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Андрей
                                    Андреев
                                    (01.01.2019-01.01.2020)
                                </div>

                            </div>
                        </div>


                        <div className={s.supervised_students}>
                            <div className={s.supervised_student_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleCourses3')}>
                                <div className={s.supervised_students_list}>Courses (3/1)</div>
                                <div
                                    className={this.state.isActiveToggleCourses3 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>

                            </div>
                            <div
                                className={this.state.isActiveToggleCourses3 === true ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}>+ Add
                                    the
                                    new school
                                </div>
                                <div className={s.dropdown_teacher_link}>Kids
                                    (01.01.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Junior (03.03.2020)
                                </div>
                                <div className={s.dropdown_teacher_link}>Chinese (05.05.2020)
                                </div>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>Pro
                                    (01.01.2019-01.02.2020)
                                </div>
                            </div>
                        </div>

                        <div className={s.date}>01.09.2020</div>

                        <div className={s.contact}>
                            <div className={s.supervised_student_title}
                                 onClick={()=>this.ToggleWindow('isActiveToggleContact3')}>
                                <div className={s.supervised_students_list}>Contact</div>
                                <div
                                    className={this.state.isActiveToggleContact3 !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>

                            </div>
                            <div
                                className={this.state.isActiveToggleContact3 === true ? s.dropdown_visible + ' ' + s.dropdown_contact : s.dropdown_student_hidden}>
                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571c32195480adaa0d37_chat-2.png"></img>
                                    <div>CHAT</div>
                                </div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09586a2ae5446fc1a024d9_envelope.png"></img>
                                    <div>kopaneva@mail.ru</div>
                                </div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                    <div>+7 984 111 11 11</div>
                                </div>

                                <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                    <img className={s.contact_img}
                                         src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                    <div>+7 984 111 22 33 (Mother)</div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                <div className={s.devide_line}></div>

            </div>


        );
    }
}

export default French;