import React, { useEffect, useState } from "react";
import './AddInterestingFacts.css'
import callApi from "../../../../utils/call-api";


const AddInterestingFacts = ({ setActiveList, setActiveAdd }) => {
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [getCourses, setGetCourses] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState({});
    // const [selectedLevel, setSelectedLevel] = useState('');
    const [selectedActive, setSelectedActive] = useState('');
    const [show, setShow] = useState(false);
    const [enterObjName, setEnterObjName] = useState(false);
    const [enterTypeName, setEnterTypeName] = useState(false);
    const [selectedObjName, setSelectedObjName] = useState('');
    const [selectedTypeName, setSelectedTypeName] = useState('');
    const [selectedFile, setSelectedFile] = useState({});


    const lang = textId => textId;

    function selected(value) {

        if (selectedActive) {
            setSelectedActive('');
        } else {
            setSelectedActive(value);
        }
    }

    async function saveInterestingFacts() {

        if (selectedObjName && selectedTypeName) {
            const formData = new FormData();
            const factsText = document.getElementById('facts_text');
            const factsTranslate = document.getElementById('facts_translate');

            if (!factsText.value) {
                alert(lang('Please enter a fact description'));

                return;
            }

            if (!factsTranslate.value) {
                alert(lang('Please enter a fact translate description'));

                return;
            }

            if(!Object.keys(selectedFile).length) {
                alert(lang('Please select upload file for current fact'));

                return;
            }

            if(!Object.keys(selectedLanguage).length) {
                alert(lang('Please choose language'));

                return;
            }

            if(Object.keys(selectedCourses).length) {
                formData.append('courseId', selectedCourses.id);
            }

            formData.append('name', selectedObjName);
            formData.append('type', selectedTypeName);
            formData.append('description', factsTranslate.value);
            formData.append('descriptionRu', factsText.value);
            formData.append('languageId', selectedLanguage.languageId);
            formData.append('isEnabled', show);
            formData.append('file', selectedFile.image);

            await callApi('dashboard/createVirtualLessonEndInformation', 'post', formData).then(res => {
    
                if (res && res.data.ok) {
                    console.log(res.data.message);
                    alert(lang(res.data.message));
                } else {
                    alert(lang(res.data.message));
                }
            }).catch(err => {
                alert(lang('Cannot add intersting fact'));
                console.log(err);
            });

            //Тут должна быть очистка всех элементов полагаю
            setEnterObjName(false);
            setEnterTypeName(false);
            // factsTranslate.value = '';
            // factsText.value = '';
        } else {
            setEnterObjName(true);
            setEnterTypeName(true);
        }
    }

    useEffect(() => {
        async function getCourses() {
            const courses = [];

            await callApi('dashboard/getCourses', 'post').then(res => {

                if (res && res.data.ok) {
                    res.data.courses.forEach(el => {
                        courses.push(el);
                    });
                } else {
                    alert(lang(res.data.message));
                }
            }).catch(err => {
                alert(lang('Receive courses data error'));
                console.log('err', err);
            });

            setGetCourses(courses);
        }

        getCourses();
    }, []);

    function showSelected() {
        const languageArr = [
            { languageId: 2, language: 'english' },
            { languageId: 4, language: 'french' },
        ];

        return (
            <div className='show_selected_container'>
                {selectedActive === 'language' ? languageArr.map(item => {

                    return (
                        <div className='show_selected_line' onClick={() => {
                            setSelectedLanguage(item);
                            setSelectedActive('');
                        }}>{item.language}</div>
                    )
                }) : null}
                {selectedActive === 'courses' ? getCourses.map(item => {

                    return (
                        <div className='show_selected_line' onClick={() => {
                            setSelectedCourses(item);
                            setSelectedActive('');
                        }}>{`${item.name}(${item.type})`}</div>
                    )
                }) : null}
            </div>
        )
    }


    return (
        <React.Fragment>
            <div className='facts_header'>
                <div className='facts_header_selects'>
                    <div className={selectedActive === 'language' ? 'selected_container active' : 'selected_container'}
                        onClick={() => selected('language')}>
                        <div className='selected_language'>{selectedLanguage.language ? selectedLanguage.language : 'Select language'}</div>
                        <div className={selectedActive === 'language' ? 'selected_arrow active' : 'selected_arrow'}>></div>
                    </div>
                    {selectedActive === 'language' ? showSelected() : null}
                </div>
                <div className='facts_header_selects'>
                    <div className={selectedActive === 'courses' ? 'selected_container active' : 'selected_container'}
                        onClick={() => selected('courses')}>
                        <div className='selected_language'>{selectedCourses.name ? `${selectedCourses.name}(${selectedCourses.type})` : 'Select courses'}</div>
                        <div className={selectedActive === 'courses' ? 'selected_arrow active' : 'selected_arrow'}>></div>
                    </div>
                    {selectedActive === 'courses' ? showSelected() : null}
                </div>
            </div>
            <div className='lottie_upload_file'>
                Загрузите файл:
                <input name="file" type="file" onChange={e => setSelectedFile({ image: e.target.files[0] })} />
            </div>
            <div className='lottie_upload_name'>
                <p>Введите название факта:</p>
                <input
                    type="text"
                    placeholder={enterObjName && selectedObjName.length === 0 ? 'Введите имя' : null}
                    className={enterObjName && selectedObjName.length === 0 ? 'lottie_upload_input' : null}
                    value={selectedObjName}
                    defaultValue=''
                    onInput={e => setSelectedObjName(e.target.value)} />
            </div>
            <div className='lottie_upload_name'>
                <p>Введите тип факта:</p>
                <input
                    type="text"
                    placeholder={enterTypeName && selectedTypeName.length === 0 ? 'Введите тип' : null}
                    className={enterTypeName && selectedTypeName.length === 0 ? 'lottie_upload_input' : null}
                    value={selectedTypeName}
                    defaultValue=''
                    onInput={e => setSelectedTypeName(e.target.value)} 
                />
            </div>
            <div className='facts_textarea'>
                <p>Введите интересный факт о выбраном языке:</p>
                <textarea id="facts_text" cols="30" rows="10"></textarea>
            </div>
            <div className='facts_textarea'>
                <p>Введите его перевод:</p>
                <textarea id="facts_translate" cols="30" rows="10"></textarea>
            </div>
            <div style={{ marginLeft: '10px' }}>
                <input type="checkbox" onClick={e => {
                    e.target.checked ? setShow(true) : setShow(false)
                }} />
                <span>Показывать</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className='facts_button' onClick={saveInterestingFacts}>Сохранить</div>
                <div className='facts_button' onClick={() => {
                    setActiveAdd(false);
                    setActiveList(true);
                }}>Отменить</div>
            </div>

        </React.Fragment>
    );

}

export default AddInterestingFacts;
