import axios from 'axios';
import config from '../config';

function getLeng(){
    let lang = 'en';
    const locale = JSON.parse(localStorage.getItem('locale') || null);
    if (locale && locale.locale) {
        lang = locale.locale;
    }
    return lang;
};

function axiosGET(endpoint, params, headers){
    return axios({
        method: 'GET',
        url: `${config.API_URI}/${endpoint}`,
        headers: headers,
        params: params
    });
};

function axiosPOST(endpoint, payload, headers){
    return axios({
        method: 'POST',
        url: `${config.API_URI}/${endpoint}`,
        headers: headers,
        data: payload
    });
};

export default function callApi(endpoint, options, payload, contentType = 'application/json') {
    const user = JSON.parse(localStorage.getItem('user') || null);
    let token = '';

    if (user) {
        token = user.token;
    }

    const authHeaders = token ? {Authorization: token} : {};
    const headers = {
        'Content-Type': contentType,
        'X-Requested-With': 'XMLHttpRequest',
        'X-Fraudzeroider-Language': getLeng(),
        ...authHeaders
    }
    if (options.toUpperCase() === "GET"){
        return axiosGET(endpoint, payload, headers);
    } else {
        return axiosPOST(endpoint, payload, headers);
    }
    
}

export function getRecordId() {
    const url = new URL(window.location.href);

    return url.searchParams.get('id');
}
