import React, {useEffect, useState} from 'react';
import './sticks.scss'
import * as types from '../../constants/dialog';
import * as typesLike from '../../constants/likes';
import * as typesSettings from '../../constants/settings';
import * as typesTimer from '../../constants/timerLesson';
import {useDispatch, useSelector} from 'react-redux';
import Lottie from 'react-lottie';
import config from '../../config'
import callApi from '../../utils/call-api';

import stickerSentSound from '../../../src/assets/sounds/sticker_sent.mp3';
import studentRocketSound from '../../../src/assets/sounds/student_rocket.mp3';
import { v1 } from '../../utils/uuid';

const stickerSentAudio = new Audio();
stickerSentAudio.src = stickerSentSound;

const studentRocketAudio = new Audio();
studentRocketAudio.src = studentRocketSound;

const TIME_START = 300;
const TIME_GEN_END = 4300;

const Sticks = (
    {
        generalActive,
        setGeneralActive,
        socket,
        socketMessage,
        userInfo,
        virtualClassId,
        timerActive,
        setTimerActive,
        studentsGradesActive,
        setStudentsGradesActive,
    }) => {

    const [imgActive, setImgActive] = useState(false);
    const [gradeActive, setGradeActive] = useState(false);
    const [startGradesActive, setStartGradesActive] = useState(false);
    const [stickActive, setStickActive] = useState({stickName: '', userName: ''});
    const [gradeDataActive, setGradeDataActive] = useState({grade: '', userName: ''});
    const [previousMessage, setPreviousMessage] = useState('');
    const dispatch = useDispatch();
    const timerArr = ['00:05', '00:10', '00:25', '00:50', '01:40'];
    const [socketStudentsGrades, setSocketStudentsGrades] = useState();
    const [changeGradeToStudent, setChangeGradeToStudent] = useState(JSON.parse(localStorage.getItem('classRoom')));
    const [topPxTimer, setTopPxTimer] = useState(-315);
    const [topPxGenStick, setTopPxGenStick] = useState(-700);
    const [topPxLikesStick, setTopPxLikesStick] = useState(-700);
    const [topPxGenSticksImg, setTopPxGenSticksImg] = useState(-420);
    const [topPxStudentGrade, setTopPxStudentGrade] = useState(-200);
    const [topPxStudentGrades, setTopPxStudentGrades] = useState(0);
    const [topPxShowStudentGrades, setTopPxShowStudentGrades] = useState(0);
    const [displayTimer, setDisplayTimer] = useState('none');
    const [displayGenStick, setDisplayGenStick] = useState('none');
    const [displayLikesStick, setDisplayLikesStick] = useState('flex');
    const [displayGenSticksImg, setDisplayGenSticksImg] = useState('none');
    const [displayStudentGrade, setDisplayStudentGrade] = useState('none');
    const [displayStudentGrades, setDisplayStudentGrades] = useState('none');
    const [displayShowStudentGrades, setDisplayShowStudentGrades] = useState('none');
    const [transitionMs] = useState('200ms');
    const [dataGen, setDataGen] = useState([]);
    const [dataLikes, setDataLikes] = useState([]);
    const [stickWidth, setStickWidth] = useState(90);
    const [stickHeight, setStickHeight] = useState(42);
    const [likesWidth, setLikesWidth] = useState(115);
    const [likesHeight, setLikesHeight] = useState(42);
    const openCloseLikes = useSelector(state => state.likes.openCloseLikes);
    const participantEmail = useSelector(state => state.likes.participantEmail);
    const participantName = useSelector(state => state.likes.participantName);
    const demoGrade = useSelector(state => state.settings.demoGrade);
    const demoLesson = useSelector(state => state.settings.demoLesson);


    useEffect(() => {
        const fetchData = async () => {
            const result = await callApi('dashboard/getSystemFilesAdmin', 'post', {});
            const filesData =  result && result.data && result.data.filesData || [];
            const prepareDataGen = filesData.map(el => {
                if (el.isCommon && el.isVisible) {
                    return el
                }
            });
            const prepareDataLikes = filesData.map(el => {
                if (el.isIndividual && el.isVisible) {
                    return el
                }
            });
            setDataGen(prepareDataGen);
            setDataLikes(prepareDataLikes);
        };

        fetchData();
    }, []);


    useEffect(() => {
        setChangeGradeToStudent(JSON.parse(localStorage.getItem('classRoom')))
    }, [localStorage.classRoom])

    if ((previousMessage !== JSON.stringify(socketMessage)) && (socketMessage.command === 'giveStickerParticipant')) {
        showStickImg(socketMessage.data.stickerType, socketMessage.data.participantName);

        setPreviousMessage(JSON.stringify(socketMessage));

        stickerSentAudio.play(); stickerSentAudio.volume = global.soundsVolume;
    }

    if ((previousMessage !== JSON.stringify(socketMessage)) && (socketMessage.command === 'giveGradeParticipant')) {
        if (socketMessage.data && socketMessage.data.participantEmail  === userInfo.userInfo.email) {
            showGrade(socketMessage.data.gradeParticipant);
        } else if (socketMessage.data.email) {
            showGrade(socketMessage.data.gradeParticipant, socketMessage.data.name);
        }
        setPreviousMessage(JSON.stringify(socketMessage));

        studentRocketAudio.play(); studentRocketAudio.volume = global.soundsVolume;
    }

    if ((previousMessage !== JSON.stringify(socketMessage)) && (socketMessage.command === 'showGradesToStudent')) {
        startShowGrades(socketMessage.data)
        setPreviousMessage(JSON.stringify(socketMessage));
    }


    const urlImg = () => {
        if (dataGen) {
            return (
                dataGen.map(item => {
                    if (item && item.fileId === stickActive.stickName) {
                        const name = item.filePath;
                        const arrName = name.split('/');
                        const index = arrName.length - 1;

                        return (
                            <div className='img_stick'>
                                {stickActive.userName && <div className='student_name'>{stickActive.userName}</div>}
                                {/*<div>{item.name}</div>*/}
                                <Lottie
                                    options={{
                                        loop: false,
                                        autoplay: true,
                                        path: config.lottieUrlFromBD + arrName[index],
                                    }}
                                    width={315}
                                    height={250}
                                    eventListeners={[
                                        {
                                            eventName: 'complete',
                                            callback: () => setImgActive(false),
                                        },
                                    ]}
                                />
                            </div>
                        )
                    }
                })
            )
        }
    }

    const gradeImg = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'row'}} className={!demoLesson?  'demo_lesson_grade': ' '}>
                <div
                    className={(['admin', 'teacher'].includes(userInfo.userInfo.type) ? demoGrade >= 1 : gradeDataActive.grade >= 1) ? 'grade_delivered grade_rocket' : 'grade_not_delivered grade_rocket'}></div>
                <div
                    className={(['admin', 'teacher'].includes(userInfo.userInfo.type) ? demoGrade >= 2 : gradeDataActive.grade >= 2) ? 'grade_delivered grade_rocket' : 'grade_not_delivered grade_rocket'}></div>
                <div
                    className={(['admin', 'teacher'].includes(userInfo.userInfo.type) ? demoGrade >= 3 : gradeDataActive.grade >= 3) ? 'grade_delivered grade_rocket' : 'grade_not_delivered grade_rocket'}></div>
                <div
                    className={(['admin', 'teacher'].includes(userInfo.userInfo.type) ? demoGrade >= 4 : gradeDataActive.grade >= 4) ? 'grade_delivered grade_rocket' : 'grade_not_delivered grade_rocket'}></div>
                <div
                    className={(['admin', 'teacher'].includes(userInfo.userInfo.type) ? demoGrade >= 5 : gradeDataActive.grade >= 5) ? 'grade_delivered grade_rocket' : 'grade_not_delivered grade_rocket'}></div>
            </div>
        )
    }


    function showStickImg(stickName, userName = '') {
        setTimeout(function () {
            setImgActive(true)
        }, TIME_START);
        setTimeout(function () {
            setImgActive(false)
        }, TIME_GEN_END);
        if (generalActive) {
            setGeneralActive(false);
        }
        setStickActive({stickName, userName});
    }

    function showGrade(grade, userName = '') {
        setTimeout(function () {
            setGradeActive(true)
        }, TIME_START);
        setTimeout(function () {
            setGradeActive(false)
        }, TIME_GEN_END);
        setGradeDataActive({grade, userName});
        if (socketMessage.data && socketMessage.data.participantEmail === userInfo.userInfo.email) {
            dispatch({type: types.TAKE_GRADE, payload: grade})
        }
    }

    function showGradeDemo() {
        setTimeout(function () {
            setGradeActive(true)
        }, TIME_START);
        setTimeout(function () {
            setGradeActive(false)
        }, TIME_GEN_END);
    }

    useEffect(()=>{
        if (demoGrade !== 0) {
            showGradeDemo();
        }
    },[demoGrade])

    function startShowGrades(grades) {
        setTimeout(function () {
            setStartGradesActive(true)
        }, TIME_START);
        setTimeout(function () {
            setStartGradesActive(false)
        }, TIME_GEN_END);
        setSocketStudentsGrades(grades);
    }

    function stickEmitToSocket(stickerType) {
        socket.send(JSON.stringify({
            command: 'giveStickerParticipant',
            data: {
                uniqId: v1(),
                stickerType,
            },
            val: {
                vCId: virtualClassId,
                userEmail: userInfo.userInfo.email,
                userType: userInfo.userInfo.type,
            }
        }));
    }

    function likesEmitToSocket(stickerType) {
        socket.send(JSON.stringify({
            command: 'giveStickerParticipant',
            data: { uniqId: v1(), participantEmail, participantName, stickerType },
            val: {
                vCId: virtualClassId,
                userEmail: userInfo.userInfo.email,
                userType: userInfo.userInfo.type,
            }
        }));
    }

    useEffect(() => {
        if (generalActive) {
            setDisplayGenStick('flex');
            if (topPxGenStick < 0) {
                setTimeout(setTopPxGenStick, 10, 80);
            } else if (topPxGenStick > 15 && topPxGenStick <= 80) {
                setTimeout(setTopPxGenStick, 300, 15);
            }
        } else {
            if (topPxGenStick === 15) {
                setTimeout(setTopPxGenStick, 10, 80);
            } else {
                setTimeout(setTopPxGenStick, 300, -700);
            }
            setTimeout(setDisplayGenStick, 500, 'none');
        }
    }, [topPxGenStick, generalActive])

    useEffect(() => {
        const column = Math.ceil(dataGen.length / 15);
        const maxHeight = Math.ceil((dataGen.length - 45) / 3);
        if (column === 1) {
            setStickHeight(dataGen.length * 42)
        } else if (column > 1 && column <= 3) {
            setStickWidth(column * 90)
            setStickHeight(630)
        } else if (column > 3) {
            setStickWidth(280)
            setStickHeight(630 + (maxHeight * 42))
        }
    })

    const generalsStick = () => {
        return (
            <React.Fragment>
                {['admin', 'teacher'].includes(userInfo.userInfo.type) && dataGen ?
                    <div className='general_stick_container' style={{display: displayGenStick}}>
                        <div className='general_stick' style={{top: topPxGenStick, transition: transitionMs}}>
                            <div className='general_stick_table' style={{width: stickWidth}}>
                                {dataGen.map(item => {
                                    if (item && item.name && item.name.length > 7) {
                                        return (
                                            <div key={item.fileId}
                                                 className='stick_button sticks many_letters'
                                                 title={item.name}
                                                 onClick={() => stickEmitToSocket(item.fileId)}>
                                                <div style={{width: '65px', overflow: 'hidden'}}>
                                                    {item.name}
                                                </div>
                                            </div>
                                        )
                                    } else if (item) {
                                        return (
                                            <div key={item.fileId}
                                                 className='stick_button sticks'
                                                 onClick={() => stickEmitToSocket(item.fileId)}>{item.name}</div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    : null
                }
            </React.Fragment>
        )
    }

    useEffect(() => {
        if (openCloseLikes) {
            setDisplayLikesStick('flex');
            if (topPxLikesStick < 0) {
                setTimeout(setTopPxLikesStick, 10, 80);
            } else if (topPxLikesStick > 15 && topPxLikesStick <= 80) {
                setTimeout(setTopPxLikesStick, 300, 15);
            }
        } else {
            if (topPxLikesStick === 15) {
                setTimeout(setTopPxLikesStick, 10, 80);
            } else {
                setTimeout(setTopPxLikesStick, 300, -700);
            }
            setTimeout(setDisplayLikesStick, 500, 'none');
        }
    }, [topPxLikesStick, openCloseLikes])

    useEffect(() => {
        const column = Math.ceil(dataLikes.length / 14);
        const maxHeight = Math.ceil((dataLikes.length - 42) / 3);
        if (column === 1) {
            setLikesHeight(dataLikes.length * 42)
        } else if (column > 1 && column <= 3) {
            setLikesWidth(column * 115)
            setLikesHeight(588)
        } else if (column > 3) {
            setLikesWidth(340)
            setLikesHeight(588 + (maxHeight * 42))
        }
    })

    const likes = () => {
        return (
            <React.Fragment>
                {['admin', 'teacher'].includes(userInfo.userInfo.type) && dataLikes ?
                    <div className='general_stick_container likes_container' style={{display: displayLikesStick}}>
                        <div className='general_stick general_likes' style={{top: topPxLikesStick, transition: transitionMs}}>
                            <div className='general_stick_table' style={{width: likesWidth}}>
                                {dataLikes.map(item => {
                                    if (item && item.name && item.name.length > 7) {
                                        return (
                                            <div key={item.fileId}
                                                 className='stick_button sticks many_letters likes'
                                                 title={item.name}
                                                 onClick={() => {
                                                     likesEmitToSocket(item.fileId);
                                                     dispatch({type: typesLike.CLOSE_LIKES, payload: false});
                                                 }}>
                                                <div style={{width: '89px', overflow: 'hidden'}}>
                                                    {item.name}
                                                </div>
                                            </div>
                                        )
                                    } else if (item) {
                                        return (
                                            <div key={item.fileId}
                                                 className='stick_button sticks likes'
                                                 onClick={() => {
                                                     likesEmitToSocket(item.fileId);
                                                     dispatch({type: typesLike.CLOSE_LIKES, payload: false});
                                                 }}>{item.name}</div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    : null
                }
            </React.Fragment>
        )
    }

    useEffect(() => {
        if (imgActive) {
            setDisplayGenSticksImg('flex');
            if (topPxGenSticksImg < 0) {
                setTimeout(setTopPxGenSticksImg, 100, 60);
            } else if (topPxGenSticksImg > 15 && topPxGenSticksImg <= 60) {
                setTimeout(setTopPxGenSticksImg, 450, 15);
            }
        } else {
            if (topPxGenSticksImg === 15) {
                setTimeout(setTopPxGenSticksImg, 10, 60);
            } else {
                setTimeout(setTopPxGenSticksImg, 300, -420);
            }
            setTimeout(setDisplayGenSticksImg, 500, 'none');
        }
    }, [topPxGenSticksImg, imgActive])

    const viewStick = () => {
        return (
            <div className='img_stick_container_main' style={{display: displayGenSticksImg}}>
                <div className='img_stick_container' style={{top: topPxGenSticksImg, transition: transitionMs}}>
                    {urlImg()}
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (gradeActive) {
            setDisplayStudentGrade('flex');
            if (topPxStudentGrade < 0) {
                setTimeout(setTopPxStudentGrade, 50, 60);
            } else if (topPxStudentGrade > 15 && topPxStudentGrade <= 60) {
                setTimeout(setTopPxStudentGrade, 350, 15);
            }
        } else {
            if (topPxStudentGrade === 15) {
                setTimeout(setTopPxStudentGrade, 10, 60);
            } else {
                setTimeout(setTopPxStudentGrade, 300, -200);
            }
            setTimeout(setDisplayStudentGrade, 500, 'none');
        }
    }, [topPxStudentGrade, gradeActive])


    const showStudentGrade = () => {
        return (
            <React.Fragment>
                {['student'].includes(userInfo.userInfo.type) ?
                    <div className='grade_container_main' style={{display: displayStudentGrade}}>
                        <div className='grade_container' style={{top: topPxStudentGrade, transition: transitionMs}}>
                            {gradeDataActive.userName && <div className='student_name'>{gradeDataActive.userName}</div>}
                            {gradeImg()}
                        </div>
                    </div>
                    : null
                }
                {['admin', 'teacher'].includes(userInfo.userInfo.type) ?
                    <div className='grade_container_main' style={{display: displayStudentGrade}}>
                        <div className='grade_container' style={{top: topPxStudentGrade, transition: transitionMs}}>
                            {demoLesson && <div className='student_name'>{'Student Name'}</div>}
                            {gradeImg()}
                        </div>
                    </div>
                    : null
                }
            </React.Fragment>
        )
    }

    function giveTimeToTimer(value) {
        const timeArr = value.split(':')
        const timerTime = Number(timeArr[0] * 60) + Number(timeArr[1])
        socket.send(JSON.stringify({
            command: 'giveTimeToTimer',
            data: {
                timerTime,
            },
            val: {
                vCId: virtualClassId,
                userEmail: userInfo.userInfo.email,
                userType: userInfo.userInfo.type,
            }
        }));
        dispatch({type: typesTimer.CHANGE_TIMER_TIME, payload: timerTime})
    }

    useEffect(() => {
        if (timerActive) {
            setDisplayTimer('flex');
            if (topPxTimer < 0) {
                setTimeout(setTopPxTimer, 50, 60);
            } else if (topPxTimer > 15 && topPxTimer <= 60) {
                setTimeout(setTopPxTimer, 350, 15);
            }
        } else {
            if (topPxTimer === 15) {
                setTimeout(setTopPxTimer, 10, 60);
            } else {
                setTimeout(setTopPxTimer, 300, -315);
            }
            setTimeout(setDisplayTimer, 500, 'none');
        }
    }, [topPxTimer, timerActive])


    const viewTimer = () => {
        return (
            <div className='timer_container' style={{display: displayTimer}}>
                <div className='timer_container_body' style={{top: topPxTimer, transition: transitionMs}}>
                    {timerArr.map(item =>  (
                        <div key={timerArr.indexOf(item)} className='timer_button' onClick={() => {
                            setTimerActive(false);

                            giveTimeToTimer(item)
                        }}>{item}</div>)
                    )}
                </div>
            </div>
        )
    }

    function sendGradesToStudents() {
        if(changeGradeToStudent) {
            socket.send(JSON.stringify({
                command: 'showGradesToStudent',
                data: {
                    ...changeGradeToStudent,
                },
                val: {
                    vCId: virtualClassId,
                    userEmail: userInfo.userInfo.email,
                    userType: userInfo.userInfo.type,
                }
            }));
        }
    }

    function changeGrade(participantEmail, grade, name) {
        const indexStudentStorage = changeGradeToStudent[1].grades.findIndex(item => item.email === participantEmail);
        const changeGradesArr = changeGradeToStudent;
        if (indexStudentStorage !== -1) {
            changeGradesArr[1].grades[indexStudentStorage].grade = grade;
            localStorage.setItem('classRoom', JSON.stringify(changeGradesArr));
            socket.send(JSON.stringify({
                command: 'giveGradeParticipant',
                data: {participantEmail, gradeParticipant: grade, name},
                val: {
                    vCId: virtualClassId,
                    userEmail: userInfo.userInfo.email,
                    userType: userInfo.userInfo.type,
                }
            }))
        }
    }


    useEffect(() => {
        if (startGradesActive && ['student'].includes(userInfo.userInfo.type)) {
            if (topPxStudentGrades === 0) {
                const height = socketStudentsGrades[1].grades.length * 95 + 100
                setTimeout(setTopPxStudentGrades, 2, -height)
            } else if (topPxStudentGrades < 0) {
                setTimeout(setDisplayStudentGrades, 50, 'flex');
                setTimeout(setTopPxStudentGrades, 100, 60);
            } else if (topPxStudentGrades > 15 && topPxStudentGrades <= 60) {
                setTimeout(setTopPxStudentGrades, 350, 15);
            }
        } else if (socketStudentsGrades) {
            if (topPxStudentGrades === 15) {
                setTimeout(setTopPxStudentGrades, 50, 60);
            } else {
                const height = socketStudentsGrades[1].grades.length * 95 + 100
                setTimeout(setTopPxStudentGrades, 350, -height);
            }
            setTimeout(setDisplayStudentGrades, 550, 'none');
        }
    }, [topPxStudentGrades, startGradesActive]);

    const studentsGrades = () => {
        const height = (socketStudentsGrades[1].grades.length + 1) * 100;
        return (
            <div className='students_grades_container_main' style={{display: displayStudentGrades, height: height}}>
                {['student'].includes(userInfo.userInfo.type) ?
                    <div className='students_grades_container' style={{top: topPxStudentGrades, transition: transitionMs}}>
                        {socketStudentsGrades[1].grades.map(item=>{
                            const arrName = item.name.split(' ')
                            return (
                                <div style={{display: 'flex', flexDirection: 'row', borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}}>
                                    <div className='grades_avatar'>avatar</div>
                                    <div>
                                        <div className='grades_name'>{arrName[0]}</div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <div
                                                className={item.grade >= 1 ?
                                                    'grade_delivered grade_rocket classRoomGrades' :
                                                    'grade_not_delivered grade_rocket classRoomGrades'}></div>
                                            <div
                                                className={item.grade >= 2 ?
                                                    'grade_delivered grade_rocket classRoomGrades' :
                                                    'grade_not_delivered grade_rocket classRoomGrades'}></div>
                                            <div
                                                className={item.grade >= 3 ?
                                                    'grade_delivered grade_rocket classRoomGrades' :
                                                    'grade_not_delivered grade_rocket classRoomGrades'}></div>
                                            <div
                                                className={item.grade >= 4 ?
                                                    'grade_delivered grade_rocket classRoomGrades' :
                                                    'grade_not_delivered grade_rocket classRoomGrades'}></div>
                                            <div
                                                className={item.grade >= 5 ?
                                                    'grade_delivered grade_rocket classRoomGrades' :
                                                    'grade_not_delivered grade_rocket classRoomGrades'}></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    : null}
            </div>
        )
    }

    useEffect(() => {
        if (studentsGradesActive && ['admin', 'teacher'].includes(userInfo.userInfo.type)) {
            if (topPxShowStudentGrades === 0) {
                if (changeGradeToStudent) {
                    const height = changeGradeToStudent[1].grades.length * 91 + 115;
                    setTimeout(setTopPxShowStudentGrades, 2, -height);
                } else {
                    setTimeout(setTopPxShowStudentGrades, 2, -250);
                }
            } else if (topPxShowStudentGrades < 0) {
                setTimeout(setDisplayShowStudentGrades, 50, 'flex');
                setTimeout(setTopPxShowStudentGrades, 100, 60);
            } else if (topPxShowStudentGrades > 15 && topPxShowStudentGrades <= 60) {
                setTimeout(setTopPxShowStudentGrades, 350, 15);
            }
        } else if (['admin', 'teacher'].includes(userInfo.userInfo.type)) {
            if (topPxShowStudentGrades === 15) {
                setTimeout(setTopPxShowStudentGrades, 50, 60);
            } else if (topPxShowStudentGrades === 60){
                if (changeGradeToStudent) {
                    const height = changeGradeToStudent[1].grades.length * 91 + 115;
                    setTimeout(setTopPxShowStudentGrades, 350, -height);
                } else {
                    setTimeout(setTopPxShowStudentGrades, 350, -250);
                }
            }
            setTimeout(setDisplayShowStudentGrades, 550, 'none');
        }
    }, [topPxShowStudentGrades, studentsGradesActive])


    const showStudentsGrades = () => {
        let height
        if (changeGradeToStudent) {
            height = (changeGradeToStudent[1].grades.length + 2) * 100;
        } else {
            height = 200;
        }
        return (
            <div className='students_grades_container_main'
                 style={{display: displayShowStudentGrades, height: height}}>
                {!!['admin', 'teacher'].includes(userInfo.userInfo.type) &&
                    <div className='students_grades_container'
                         style={{top: topPxShowStudentGrades, transition: transitionMs}}>
                        {changeGradeToStudent ?
                            <div>
                                {changeGradeToStudent[1].grades.map(item => {
                                    const arrName = item.name.split(' ')
                                    return (
                                        <div key={changeGradeToStudent[1].grades.indexOf(item)} style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                                        }}>
                                            <div className='grades_avatar'>avatar</div>
                                            <div>
                                                <div className='grades_name'>{arrName[0]}</div>
                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <div
                                                        className={item.grade >= 1 ?
                                                            'grade_delivered grade_rocket classRoomGrades' :
                                                            'grade_not_delivered grade_rocket classRoomGrades'}
                                                        onClick={() => changeGrade(item.email, 1, item.name)}
                                                    ></div>
                                                    <div
                                                        className={item.grade >= 2 ?
                                                            'grade_delivered grade_rocket classRoomGrades' :
                                                            'grade_not_delivered grade_rocket classRoomGrades'
                                                        }
                                                        onClick={() => changeGrade(item.email, 2, item.name)}
                                                    ></div>
                                                    <div
                                                        className={item.grade >= 3 ?
                                                            'grade_delivered grade_rocket classRoomGrades' :
                                                            'grade_not_delivered grade_rocket classRoomGrades'}
                                                        onClick={() => changeGrade(item.email, 3, item.name)}
                                                    ></div>
                                                    <div
                                                        className={item.grade >= 4 ?
                                                            'grade_delivered grade_rocket classRoomGrades' :
                                                            'grade_not_delivered grade_rocket classRoomGrades'}
                                                        onClick={() => changeGrade(item.email, 4, item.name)}
                                                    ></div>
                                                    <div
                                                        className={item.grade >= 5 ?
                                                            'grade_delivered grade_rocket classRoomGrades' :
                                                            'grade_not_delivered grade_rocket classRoomGrades'}
                                                        onClick={() => changeGrade(item.email, 5, item.name)}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div> : null}
                        <div className='grades_view_students' onClick={sendGradesToStudents}>Show to the students</div>
                        <div className='dictionary_close_btn' style={{marginBottom: '-26px', marginTop: '12px'}} onClick={() => setStudentsGradesActive(false)}/>
                    </div>
                }
            </div>
        )
    }


    return (
        <div onClick={e => e.stopPropagation()}>
            {['admin', 'teacher'].includes(userInfo.userInfo.type) ? generalsStick() : null}
            {['admin', 'teacher'].includes(userInfo.userInfo.type) ? likes() : null}
            {viewStick()}
            {showStudentGrade()}
            {['admin', 'teacher'].includes(userInfo.userInfo.type) ? viewTimer() : null}
            {['admin', 'teacher'].includes(userInfo.userInfo.type) ? showStudentsGrades() : null}
            {socketStudentsGrades && ['student'].includes(userInfo.userInfo.type) ? studentsGrades() : null}
        </div>
    )
}

export default Sticks;