import React from "react";
import s from "./../SuperAdmin.module.css";
import Navlink from "react-router-dom/NavLink";
import {Route} from "react-router-dom";
import Select from "react-select";
import callApi from "../../../../utils/call-api";
import Button from '@material-ui/core/Button';
import config from "../../../../config";

const Path = "/admin/superadmin/students/groups_tab/";

class GroupsTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeAddTeachUser: null,
            activeAddTeachSupervisor: {},
            activeAddTeachSupervisorLabel: {},
            activeAddStudUser: null,
            activeAddStudSupervisor: {},

            successTeacherAdd: false,
            activeToggleAddTeacher: false,
            activeStudentAdd: false,
            activeToggleAddStudent: false,
            activeAddTeacher: {},
            actualTeacher: null,
            actualStudent: null,

            groups: Object.assign({}, this.props.studentsGroups),
            isActiveDropdown: false,
            search: this.props.search,
            searchArr: [],
            groupsByCourse: Object.assign({}, this.props.studentsGroups),
            isActiveAddGroup: this.props.addGroup,
            courseName: null,
            name: '',
            courses: [],
        };
    }

    componentDidMount() {
        this.SortBy();
        this.handleSearchSubmit();
        this.makeSelectList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {courses: coursesList} = this.props;

        if (this.state.courses.length === 0 && coursesList) {
            const courses = coursesList.map(course => {
                return {
                    value: course.id,
                    name: course.name + ' ' + course.type,
                    label: course.name + ' ' + course.type,
                }
            });

            this.setState({courses})
        }

        if (prevProps.groups && this.props.groups) {
            for (let i = 0; i < this.props.groups.length; i++) {
                const group = prevProps.groups.find(pg => pg.id === this.props.groups[i].id);

                if (!group) {
                    this.setState({
                        groups: this.props.groups,
                        groupsByCourse: {all: this.props.groups}
                    });
                    break;
                }
            }
        }

    }

    // searching
    handleSearchSubmit = () => {
        let url = window.location.pathname;
        let urlComponent =
            (window.location.pathname === '/admin/superadmin/students/groups_tab' || window.location.pathname === '/admin/superadmin/students/groups_tab/')
                ? 'all'
                : url.replace(Path, '');
        let groupsList = (this.state.groupsByCourse[urlComponent]);
        let searchArr = [];

        if (this.state.search !== '') {
            groupsList.forEach(i => {
                let objName = i.name;
                let a = objName.toLowerCase().indexOf(this.state.search.toLowerCase());

                if (a !== -1) {
                    searchArr.push(i);
                }
            })
        }

        if (searchArr.length > 0) {
            this.setState(function (state) {
                    return {
                        groupsByCourse: Object.assign({},
                            state.groupsByCourse, {
                                [urlComponent]: searchArr
                            })
                    }
                },
                () => {
                });
        } else {
            this.setState({groupsByCourse: this.state.groups});
        }
    };

    SelectCourseName = () => {
        const {courses} = this.state;

        return (
            <Select required
                className={s.select_course_name} defaultValue={courses[0]}
                name="courseName" options={courses}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={e => this.setState({courseName: e.value})}
            />);
    };

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    makeSelectList = () => {
        let groups = this.state.groups.all;
        let teachers = this.props.teachers;
        let students = this.props.students;

        if (!!groups) {
            groups.map(group => {
                // учителя еще не относящиеся к данной группе для возможности добавления
                // в селекте add
                const selectListTeacher = [];

                teachers.forEach(t => {
                    if (!group.teachers.active.find(c => c.id === t.id)) {
                        selectListTeacher.push(t);
                    }
                })

                group.selectListTeacher = selectListTeacher.map(teacher => ({
                    value: teacher.id,
                    label: teacher.name + ' ' + teacher.surname,
                }));
                this.setState({groups: {all: group.selectListTeacher}})

                const {activeAddTeacher} = this.state;
                group.actualTeacher = group.selectListTeacher[0] ||
                    group.selectListTeacher.find(el => el.value === activeAddTeacher) ||
                    {};

                this.setState({groups: {all: group.actualTeacher}})

                const selectListStudents = [];

                students.forEach(t => {
                    if (!group.students.active.find(c => c.id === t.id)) {
                        selectListStudents.push(t);
                    }
                })

                group.selectListStudents = selectListStudents.map(student => ({
                    value: student.id,
                    label: student.name + ' ' + student.surname,
                }));

                this.setState({groups: {all: group.selectListStudents}})

                group.actualStudent = group.selectListStudents.find(el => el.value === activeAddTeacher) ||
                    group.selectListStudents[0] || {};

                this.setState({groups: {all: group.actualStudent}})
            })
        }
    }

    AddAdmin = (event) => {
        event.preventDefault();

        let groupsList = (this.state.groupsByCourse);
        let courseName = this.state.courseName;

        let dateNow = new Date();
        let dd = dateNow.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = dateNow.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let date = dd + "." + mm + "." + dateNow.getFullYear();

        courseName.map(courseName => {
            groupsList[courseName].unshift({
                name: this.state.name,
                date: date,
                teachers: {
                    active: [],
                    inactive: []
                },
                students: {
                    active: [],
                    inactive: []
                }
            });
            groupsList.all.unshift({
                name: this.state.name,
                date: date,
                teachers: {
                    active: [],
                    inactive: []
                },
                students: {
                    active: [],
                    inactive: []
                }
            });
        })

        this.setState({groupsByCourse: groupsList});
        this.setState({groups: groupsList});
        this.ToggleAddGroup();
    }

    //SORTING
    SortBy = () => {
        let filter = this.props.filterForSort;

        if (filter === 'name' || filter === 'date' || filter === 'courses') {
            let url = window.location.pathname;
            let newGroups = this.state.groupsByCourse;
            let [, , , , , courseN] = url.split('/');

            if (courseN === undefined || courseN === '') {
                courseN = 'all'
            }

            if (filter === "courses") {
                filter = 'name';
            }

            newGroups[courseN].sort((a, b) => (
                a[filter] > b[filter]
            ))
            this.setState({groupsByCourse: newGroups});
            this.ToggleDropdown();
        }
    }

    ToggleAddGroup = () => {
        this.setState({isActiveAddGroup: !this.state.isActiveAddGroup})
    }

    ToggleDropdown = () => {
        this.setState({isActiveDropdown: !this.state.isActiveDropdown})
    }

    ToggleWindow = name => {
        this.setState({...this.initTogglesState, [name]: !this.state[name]})
    }

    RenderAddGroup = () => {
        if (this.state.isActiveAddGroup) {

            return (
                <div className={s.form_add}>
                    <div>
                        <div>{this.SelectCourseName()}</div>
                        <div className={s.form_add + " " + s.left}>
                            <input type="text" placeholder="Имя группы" name="name" required
                                   value={this.state.name}
                                   onChange={this.handleInputChange}/>
                        </div>
                        <div className={s.form_add_submit}>
                            <button className={s.form_add_submit} type="submit" value="Submit"
                                    onClick={() => {
                                        callApi('dashboard/addGroup', 'post', {
                                            courseId: this.state.courseName,
                                            name: this.state.name,
                                        }).then(res => {
                                            if (res && res.data.ok) {
                                                this.setState({isActiveToggleSubmitCourse: true});
                                                setTimeout(() => this.setState({isActiveToggleSubmitCourse: false}), 4000);

                                                this.AddAdmin();
                                            }
                                        }).catch(err => {
                                        });
                                    }}
                            >
                                Add Group
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    };

    RenderButtons = (groups) => {

        if (groups) {

            return (
                <div className={s.tabs_menu}>
                    <Button
                        disabled={this.props.getGroupsRequestData.isFirstPage}
                        key='groupsPrevButton'
                        className={s.nav_tabs}
                        onClick={() => {
                            console.log('Prev');
                            this.props.getGroupsData({
                                ...this.props.getGroupsRequestData,
                                skip: this.props.getGroupsRequestData.skip - config.defaultSkipStepValue
                            })
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        disabled={this.props.getGroupsRequestData.isLastPage}
                        key='groupsNextButton'
                        className={s.nav_tabs}
                        onClick={() => {
                            // console.log(this.props.getGroupsRequestData);
                            console.log('Next');
                            this.props.getGroupsData({
                                ...this.props.getGroupsRequestData,
                                skip: this.props.getGroupsRequestData.skip + config.defaultSkipStepValue
                            })
                        }}
                    >
                        Next
                    </Button>
                </div>
            )
        }
    };

    RenderMsg = (trigger, text) => {
        return (
            <div style={{display: (trigger) ? 'block' : 'none'}}>
                <div className={s.success_add_sup}>
                    {text}
                </div>
            </div>
        )
    };

    RenderCourses = () => {
        const groups = this.state.groupsByCourse;

        return (
            <div>

                {this.RenderAddGroup()}
                {this.RenderMsg(this.state.successTeacherAdd, 'Teacher успешно добавлен')}
                {this.RenderMsg(this.state.successStudAdd, 'Student успешно добавлен')}

                <div className={s.tabs_menu}>
                    {Object.keys(groups).map(courseName => {
                        return (courseName === 'All' || courseName === 'all')
                            ?
                            (<Navlink exact to={Path} activeClassName={s.active_tabs} className={s.nav_tabs}>
                                {courseName}
                            </Navlink>)
                            :
                            (<Navlink to={Path + courseName} activeClassName={s.active_tabs} className={s.nav_tabs}>
                                {courseName}
                            </Navlink>)
                    })}
                    <br/>
                </div>

                {Object.keys(groups).map(courseName => {

                    return (courseName === 'All' || courseName === 'all') ?
                        <Route exact path={Path} component={() => this.RenderGroups(courseName)}/> :
                        <Route exact path={Path + courseName} component={() => this.RenderGroups(courseName)}/>
                })}
                {this.RenderButtons(groups)}
            </div>
        );
    };

    RenderGroups = (courseName) => {
        const groups = this.state.groupsByCourse[courseName] || [];
        let {students, teachers} = this.props;
        const {activeAddTeacher, activeAddTeachSupervisor, activeAddStudUser, activeAddStudSupervisor} = this.state;
        students = students ? students : [{}];
        teachers = teachers ? teachers : [{}];

        return (
            <div className={s.admins_list}>
                {Object.keys(groups).map(groupKey => {
                    let actualTeachers = (groups[groupKey].actualTeacher) ? groups[groupKey].actualTeacher : {};
                    let actualStudents = (groups[groupKey].actualStudent) ? groups[groupKey].actualStudent : {};
                    // console.log('groups[groupKey].selectListTeacher', groups[groupKey].selectListTeacher);

                    return (
                        <div key={groupKey}>
                            <div className={s.admins_list_item}>
                                <div className={s.name}>
                                    {groups[groupKey].name}
                                </div>
                                <div className={s.admins_item_right_block}>
                                    <div className={s.supervised_teachers}>
                                        <div className={s.supervised_teachers_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleTeacher' + Number(groupKey))}>
                                            <div className={s.supervised_teachers_list}>
                                                Teachers
                                                ({groups[groupKey].teachers.active.length + "/" + groups[groupKey].teachers.inactive.length})
                                            </div>
                                            <div
                                                className={
                                                    this.state['isActiveToggleTeacher' + Number(groupKey)] !== true ?
                                                        s.icon_dropdown_toggle :
                                                        s.un_icon_dropdown_toggle
                                                }
                                            >
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                this.state['isActiveToggleTeacher' + Number(groupKey)] === true ?
                                                    s.dropdown_visible + ' ' + s.dropdown_teacher :
                                                    s.dropdown_teacher_hidden
                                            }
                                        >
                                            <div
                                                style={{display: (this.state.activeToggleAddTeacher) ? 'none' : 'block'}}
                                                className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                                onClick={() => this.setState({
                                                    activeAddTeacher: groups[groupKey].id,
                                                    activeToggleAddTeacher: true
                                                })}
                                            >
                                                + Add the new teacher
                                            </div>
                                            <div style={{
                                                display: (this.state.activeToggleAddTeacher && this.state.activeAddTeacher === groups[groupKey].id)
                                                    // (this.state.activeAddTeacher !== groups[groupKey].id)
                                                    ? 'block' : 'none'
                                            }}>
                                                <div className={s.dropdown_add_sup}>
                                                    <div className={s.dropdown_select}>
                                                        <Select
                                                            required
                                                            defaultValue={{
                                                                value: actualTeachers.value,
                                                                label: actualTeachers.label
                                                            }}
                                                            name="courseName"
                                                            options={groups[groupKey].selectListTeacher}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            // activeAddTeachSupervisor id supervisor
                                                            // activeAddTeachSupervisorLabel name supervisor
                                                            onChange={el => this.setState({
                                                                    activeAddTeachSupervisor: el.value,
                                                                    activeAddTeachSupervisorLabel: el.label
                                                                },
                                                                () => {
                                                                    console.log(el, 'el')
                                                                }
                                                            )}
                                                            placeholder="Select level"
                                                        />
                                                    </div>
                                                    <div className={s.dropdown_buttons}>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={() => {
                                                                let teachers = groups[groupKey].teachers;
                                                                let pushData = {
                                                                    id: activeAddTeachSupervisor,
                                                                    name: this.state.activeAddTeachSupervisorLabel
                                                                };

                                                                if (!!activeAddTeachSupervisor && !!this.state.activeAddTeachSupervisorLabel) {
                                                                    callApi('dashboard/updateGroup', 'post', {
                                                                        groupId: this.state.activeAddTeacher,
                                                                        teachers,
                                                                    }).then(res => {
                                                                        if (res && res.data.ok) {
                                                                            teachers.active.push(pushData);
                                                                            this.setState({groups: {all: groupKey.teachers}});
                                                                            this.setState({
                                                                                isActiveToggleSubmitCourse: true,
                                                                                successTeacherAdd: true
                                                                            });
                                                                            setTimeout(() => this.setState({successTeacherAdd: false}), 2000);
                                                                        }
                                                                    }).catch(err => {
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            Add
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    activeAddSupUser: false,
                                                                    activeToggleAddTeacher: false,
                                                                })
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            {groups[groupKey].teachers.active.map(t =>
                                                <div className={s.dropdown_teacher_link} key={t.id}>
                                                    {t.name} {t.surname}
                                                </div>
                                            )}
                                            {groups[groupKey].teachers.inactive.map(t =>
                                                <div key={t.id}
                                                     className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}>
                                                    {t.name} {t.surname}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={s.supervised_students}>
                                        <div
                                            className={s.supervised_student_title}
                                            onClick={() => this.ToggleWindow('isActiveToggleStudent' + Number(groupKey))}
                                        >
                                            <div className={s.supervised_students_list}>
                                                Students (
                                                {groups[groupKey].students.active.length + "/" + groups[groupKey].students.inactive.length}
                                                )
                                            </div>
                                            <div
                                                className={
                                                    this.state['isActiveToggleStudent' + Number(groupKey)] !== true ?
                                                        s.icon_dropdown_toggle :
                                                        s.un_icon_dropdown_toggle
                                                }
                                            >
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                this.state['isActiveToggleStudent' + Number(groupKey)] === true ?
                                                    s.dropdown_visible + ' ' + s.dropdown_student :
                                                    s.dropdown_student_hidden
                                            }
                                        >
                                            <div
                                                style={{display: (this.state.activeToggleAddStudent) ? 'none' : 'block'}}
                                                className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                                onClick={() => this.setState({
                                                    activeAddStudent: groups[groupKey].id,
                                                    activeToggleAddStudent: true
                                                })}
                                            >
                                                + Add the new student

                                            </div>
                                            <div
                                                style={{display: (this.state.activeToggleAddStudent) ? 'block' : 'none'}}>
                                                <div className={s.dropdown_add_sup}>
                                                    <div className={s.dropdown_select}>
                                                        <Select
                                                            required
                                                            defaultValue={{
                                                                value: actualStudents.value,
                                                                label: actualStudents.label
                                                            }}
                                                            name="courseName"
                                                            options={groups[groupKey].selectListStudents}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            // activeAddTeachSupervisor id supervisor
                                                            // activeAddTeachSupervisorLabel name supervisor
                                                            onChange={el => this.setState({
                                                                    activeAddStudSupervisor: el.value,
                                                                    activeAddStudSupervisorLabel: el.label
                                                                },
                                                                () => {
                                                                    console.log(el, 'el')
                                                                }
                                                            )}
                                                            placeholder="Select level"
                                                        />
                                                    </div>
                                                    <div className={s.dropdown_buttons}>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={() => {
                                                                let students = groups[groupKey].students;
                                                                let pushData = {
                                                                    id: activeAddStudSupervisor,
                                                                    name: this.state.activeAddStudSupervisorLabel
                                                                };

                                                                students.active.push(pushData);
                                                                callApi('dashboard/updateGroup', 'post', {
                                                                    groupId: this.state.activeAddStudent,
                                                                    students,
                                                                }).then(res => {
                                                                    if (res && res.data.ok) {
                                                                        this.setState({groups: {all: groupKey.teachers}});
                                                                        this.setState({
                                                                            isActiveToggleSubmitCourse: true,
                                                                            successStudAdd: true
                                                                        });
                                                                        setTimeout(() => this.setState({successStudAdd: false}), 2000);
                                                                    }
                                                                }).catch(err => {
                                                                });
                                                            }}
                                                        >
                                                            Add
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    activeAddSupUser: false,
                                                                    activeToggleAddStudent: false,
                                                                })
                                                            }}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            {groups[groupKey].students.active.map(t =>
                                                <div key={t.id} className={s.dropdown_teacher_link}>
                                                    {t.name} {t.surname}
                                                </div>
                                            )}
                                            {groups[groupKey].students.inactive.map(t =>
                                                <div
                                                    key={t.id}
                                                    className={s.dropdown_teacher_link + ' ' + s.dropdown_link_red}
                                                >
                                                    {t.name} {t.surname}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={s.date}>{groups[groupKey].date}</div>
                                    <div
                                        className={s.delete_group}
                                        onClick={() => {
                                            const groups = this.state.groupsByCourse;
                                            // console.log('groups', groups)
                                            // console.log('courseName', courseName)
                                            // console.log('groupKey', groupKey)
                                            callApi('dashboard/deleteGroup', 'post', {
                                                groupId: groups[courseName][groupKey].id,
                                            }).then(res => {

                                                if (res.data.ok) {
                                                    groups[courseName].splice(groupKey, 1);
                                                    this.setState({groups});
                                                } else {
                                                    alert('server error');
                                                }
                                            }).catch(err => {
                                            });
                                        }}>
                                        Delete
                                    </div>
                                </div>
                            </div>
                            <div className={s.devide_line}></div>
                        </div>
                    )
                })}
            </div>
        );
    }

    render() {

        return (
            <div>
                {this.RenderCourses()}
            </div>
        );
    }
}

export default GroupsTab;
