import React from "react";
import s from "../SuperAdmin.module.css";
import Button from '@material-ui/core/Button';
import Navlink from "react-router-dom/NavLink";
import {Route} from "react-router-dom";
import Select from 'react-select';
import callApi from "../../../../utils/call-api";

const Path = "/admin/superadmin/teachers/";

class Teachers extends React.Component {
    initTogglesState = {
        isActiveSorting: false,
        isActiveAddForm: false,
        isActiveToggleTeacher1: false,
        isActiveToggleStudent1: false,
        isActiveToggleContact1: false,
        isActiveToggleTeacher2: false,
        isActiveToggleStudent2: false,
        isActiveToggleContact2: false,
        isActiveToggleTeacher0: false,
        isActiveToggleStudent0: false,
        isActiveToggleContact0: false,
        isActiveToggleCourses1: false,
        isActiveToggleCourses2: false,
        isActiveToggleCourses0: false,
    };

    formAddData = {
        courseNameSelected: ["kids",], //[{ name: "kids", value: "kids", label: "kids" }],
        name: '',
        email: '',
        phone1: '',
        phone2: '',
    };

    state = {
        activeAddSupUser: null,
        activeAddSupSupervisor: null,
        activeAddStudent: null,
        activeAddCourse: null,
        activeToggleAddSupSupervisor: false,
        activeToggleAddStudent: false,
        activeToggleAddCourse: false,
        teachersByCourseList: { all: [] },
        isActiveSorting: false,
        ...this.formAddData,
        search: '',
        searchArr: [],
        teachers: { all:[] },
        teachersByCourse: { all:[] },
        isActiveAddTeacher: false,
        selectedDemo: {},
        successSupervAdd: false,
        successTeacherAdd: false,
    };

    componentDidMount() {
        // this.SortBy();
        // this.handleSearchSubmit();

        let students, supervisorsTeachers, teachersCourses, courses, teachers, supervisors, demos, teachersUsers;

        callApi('dashboard/getCourses', 'POST').then(res => {
            if (res && res.data.ok) {
                courses = res.data.courses;
            }
        }).catch(() => {
        });

        callApi('dashboard/getUsers', 'get').then(res => {
            if (res && res.data.ok) {
                students = res.data.students.rows;
                teachers = res.data.teachers.rows;
                supervisors = res.data.supervisors.rows;
                demos = res.data.demos.rows;
                teachersUsers = res.data.teachersUsers;
                teachersCourses = res.data.teachersCourses;
            }

        }).catch(() => {
        });

        callApi('dashboard/getSupervisorsUsers', 'POST').then(res => {
            if (res && res.data.ok) {
                supervisorsTeachers = res.data.supervisorsUsers;

            }
        }).catch(() => {
        });

        callApi('dashboard/getUsersCourses', 'get').then(res => {
            const pF = () => {
                const data = [];

                teachers.forEach(teacher => {
                    const teacherInfo = {
                        name: teacher.name,
                        surname: teacher.surname,
                        mail: teacher.email,
                        phone1: teacher.phoneNumber,
                        phone2: teacher.phoneNumber, id: teacher.id,
                        courses: {active: [], inactive: []},
                        supervisors: {active: [], inactive: []},
                        students: {active: [], inactive: []},
                        date: this.getDateFunc(teacher.creationDate),
                    };

                    teachersUsers.forEach(st => {
                        if (st.teacherId === teacher.id ) {
                            const a = students.find(el => el.id === st.userId);
                            if (a && a.isActive) {
                                teacherInfo.students.active.push(a.name + ' ' + a.surname)
                            } else if (a && !a.isActive) {
                                teacherInfo.students.inactive.push(a.name + ' ' + a.surname)
                            }
                        }
                    });

                    teachersCourses.forEach(tc => {
                        if (tc.teacherId === teacher.id) {
                            const course = courses.find(el => el.id === tc.courseId)
                            if (course) {
                                teacherInfo.courses.active.push(`${course.name} [${course.type}] ${course.languageId}`);
                            }
                        }
                    });

                    // добавляем учителю супервизоров
                    supervisorsTeachers.forEach(st => {
                        if (st.userId === teacher.id) {
                            const supervisor = supervisors.find(c => c.id === st.supervisorId);
                            if (supervisor) {
                                teacherInfo.supervisors.active.push(`${supervisor.name} [${supervisor.email}] `);
                            }
                        }
                    });

                    // супервайзеры еще не относящиеся к данному учителю для возможности добавления
                    // в селекте add supervisor
                    const selectListSup = [];
                    supervisors.forEach(sv => {
                        if (!supervisorsTeachers.find(c => c.supervisorId === sv.id && c.userId === teacher.id)) {
                            selectListSup.push(sv);
                        }
                    });
                    teacherInfo.selectListSup = selectListSup.map(supervisor => ({
                        value: supervisor.id,
                        label: supervisor.name + ' ' + supervisor.surname,
                    }));

                    // студенты еще не относящиеся к данному учителю для возможности добавления
                    // в селекте add student
                    const selectListStudent = [];
                    students.forEach(s => {
                        if (!teacherInfo.students.active.includes(s.name + ' ' + s.surname)) {
                            selectListStudent.push(s)
                        }
                    })

                    teacherInfo.selectListStudent = selectListStudent.map(student => ({
                        value: student.id,
                        label: student.name + ' ' + student.surname,
                    }));

                    // курсы еще не относящиеся к данному учителю для возможности добавления
                    // в селекте add the new school
                    const selectListCourses = [];
                    courses.forEach(c => {
                        if (!teacherInfo.courses.active.includes(c.name)) {
                            selectListCourses.push(c)
                        }
                    })
                    teacherInfo.selectListCourses = selectListCourses.map(course => ({
                        value: course.id,
                        label: course.name,
                    }));



                    data.push(teacherInfo);
                });

                this.setState({
                        teachersByCourseList: { all: data },
                        supervisors,
                        courses,
                        students,
                        teachersByCourse: { all: data },
                        teachers,
                        demos,
                    },
                    ()=>{console.log(this.state.supervisors , 'state');}
                );

            };

            if (teachers && supervisorsTeachers && teachersCourses && courses) {
                pF();
            } else {
                setTimeout(() => pF(), 1000);
            }
        }).catch(() => {
        });
    }

    selectDemoUser = () => {
        const demos = this.state.students || [];
        const {selectedDemo} = this.state;
        const select = demos.map(el => ({...el, value: el.id, label: el.email + ' (' + el.name + ')'}));

        return (<Select required className={s.select_course_name} defaultValue={selectedDemo}
                        name="courseName" options={select}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={selectedDemo => this.setState({
                            selectedDemo,
                            name: selectedDemo.name,
                            email: selectedDemo.email,
                            phone1: selectedDemo.phoneNumber || ''
                        })}
        />);
    };

    getDateFunc = timestamp => {
        let t = (!!timestamp) ? new Date(timestamp * 1000) : new Date();
        let dd = t.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = t.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        return dd + "." + mm + "." + t.getFullYear();
    }

    RenderAddTeacher = () => {
        if (this.state.isActiveAddTeacher) {
            return (
                <div className={this.state.isActiveAddForm !== false ? s.form_add : s.dropdown_hidden_content}>

                    <form onSubmit={this.AddTeacher}>

                        <div>{this.selectDemoUser()}</div>
                        <div className={s.form_add + " " + s.left}>
                            <input type="text" placeholder="Имя" name="name" required
                                   value={this.state.name}
                                   onChange={this.handleInputChange}/>
                            <input type="email" placeholder="email" name="email" required value={this.state.email}
                                   onChange={this.handleInputChange}/>
                        </div>
                        <div className={s.form_add + " " + s.right}>
                            <input type="text" placeholder="phone number" name="phone1" required
                                   value={this.state.phone1}
                                   onChange={this.handleInputChange}/>
                            <input type="text" placeholder="second phone number" name="phone2"
                                   value={this.state.phone2}
                                   onChange={this.handleInputChange}/>
                        </div>
                        <div className={s.form_add_submit}>
                            <button className={s.form_add_submit} type="submit" value="Submit">Add Teacher</button>
                        </div>
                    </form>

                </div>
            )
        }
    }

    // searching
    handleSearchSubmit = (event) => {
        this.setState({search: event.target.value}, () => {
            this.handle(event)
        });
        event.preventDefault();
    }
    handle = () => {
        let url = window.location.pathname;
        let urlComponent =
            (window.location.pathname === '/admin/superadmin/teachers' || window.location.pathname === '/admin/superadmin/teachers/')
                ? 'all'
                : url.replace(Path, '');

        let teachersList = (this.state.teachersByCourseList[urlComponent]);


        let searchArr = [];
        if (this.state.search !== '') {
            teachersList.forEach(i => {
                let objName = i.name;
                let a = objName.toLowerCase().indexOf(this.state.search.toLowerCase());
                if (a !== -1) {
                    searchArr.push(i);
                }

            })
        }

        if (searchArr.length > 0) {

            this.setState(function (state) {
                return {
                    teachersByCourseList: Object.assign({},
                        state.teachersByCourseList, {
                            [urlComponent]: searchArr
                        })
                }
            }, () => {
                console.log(this.state.teachersByCourseList);
            });
        } else {
            this.setState({teachersByCourseList: this.state.teachersByCourse});
        }
    }

    ClearSearch = () => {
        this.setState({teachersByCourseList: this.state.teachersByCourse});
    }

    // add teacher
    SelectCourseName = () => {
        const teachers = this.state.teachersByCourse;
        let courseNameSelect = [];
        Object.keys(teachers).map(coursename => {
            (coursename !== 'all' && coursename !== 'All' && coursename !== '')
            &&
            courseNameSelect.push({name: coursename, value: coursename, label: coursename},);

        })
        return (<Select required className={s.select_course_name} defaultValue={[courseNameSelect[0]]} isMulti
                        name="courseName" options={courseNameSelect}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleSelectInputChange}
        />);
    }

    handleSelectInputChange = (event) => {
        let courseNameSelected = [];
        Object.values(event).map(j => {
            courseNameSelected.push(j.name)
        });
        courseNameSelected.length === 0 && courseNameSelected.push('kids');
        this.setState({courseNameSelected: courseNameSelected});
    }

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }


    AddTeacher = (event) => {
        event.preventDefault();
        const {selectedDemo, phone1, name} = this.state;

        callApi('dashboard/updateUsers', 'post', {
            id: selectedDemo.id,
            typeId: 2,
            name,
            phoneNumber: phone1
        }).then(res => {
            if (res && res.data.ok) {
                const teacherInfo = {name, mail: selectedDemo.email,
                    date: this.getDateFunc(),
                    phone1: selectedDemo.phoneNumber, phone2: selectedDemo.phoneNumber,
                    id: selectedDemo.id,
                    courses: {active: [], inactive: []},
                    supervisors: {active: [], inactive: []},
                    students: {active: [], inactive: []},
                    selectListSup: {value: '', label: ''},
                    selectListStudent: {value: '', label: ''},
                    selectListCourses: {value: '', label: ''}
                };

                const {teachersByCourseList}  = this.state;

                this.setState({teachersByCourseList});
                this.setState({teachers: teachersByCourseList});
                this.ToggleAddTeacher();
                this.setState({successTeacherAdd: true})
                setTimeout(() => this.setState({successTeacherAdd: false}), 2000);

            }
        }).catch(err => {
            console.log(err);
        });
    };

    // sorting
    SortBy = (filter) => {

        let url = window.location.pathname;
        let urlComponent =
            (window.location.pathname === '/admin/superadmin/teachers' || window.location.pathname === '/admin/superadmin/teachers/')
                ? 'all'
                : url.replace(Path, '');
        let newTeachers = this.state.teachersByCourseList;
        // let newTeachers = this.state.teachers;

        if (filter === "students") {
            newTeachers[urlComponent].sort((a, b) => (
                a.students.active.length < b.students.active.length
            ))
        } else if (filter === "studentsLow") {
            newTeachers[urlComponent].sort((a, b) => (
                a.students.active.length > b.students.active.length
            ))
        } else if (filter === "courses") {
            newTeachers[urlComponent].sort((a, b) => (
                a.courses.active.length < b.courses.active.length
            ))
        } else if (filter === "coursesLow") {
            newTeachers[urlComponent].sort((a, b) => (
                a.courses.active.length > b.courses.active.length
            ))
        } else {
            newTeachers[urlComponent].sort((a, b) => (
                a[filter] > b[filter]
            ))
        }
        this.setState({teachersByCourseList: newTeachers});
        this.ToggleSorting();
    }

    // toggles
    ToggleAddTeacher = () => {
        this.setState({isActiveAddTeacher: !this.state.isActiveAddTeacher})
    }

    ToggleSorting = () => {
        this.setState({isActiveSorting: !this.state.isActiveSorting})
    }

    ToggleWindow = name => {
        this.setState({...this.initTogglesState, [name]: !this.state[name]})
    }

    RenderMap = (arr, mapkey, style = '') => {
        // TODO
        return (
            Object.keys(arr).map(mapkey => (
                <div key={mapkey} className={s.dropdown_teacher_link + " " + style}>
                    {arr[mapkey]}
                </div>
            ))
        );
    };

    RenderCourses = () => {
        const teachers = this.state.teachersByCourseList;

        return (
            <div>

                <div className={s.instrument_bar}>
                    <div className={s.dropdown} onClick={this.ToggleSorting}>
                        <div className={s.dropdown_toggle}>
                            SORT BY
                        </div>
                        <div
                            className={this.state.isActiveSorting !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}>
                        </div>
                    </div>
                    <div
                        className={this.state.isActiveSorting === true ? s.dropdown_visible_content : s.dropdown_hidden_content}>
                        <div className={s.dropdown_link}
                             onClick={() => {
                                 this.SortBy('name')
                             }}
                        >Name
                        </div>
                        <div className={s.dropdown_link}
                             onClick={() => {
                                 this.SortBy('date')
                             }}
                        >Date added
                        </div>
                        <div className={s.sort_item}>
                            <div>Number of active students</div>
                            <div className={s.sort_img_wrap}>
                                <div className={s.high + ' ' + s.dropdown_link} onClick={() => {
                                    this.SortBy('students')
                                }}></div>
                                <div className={s.low + ' ' + s.dropdown_link} onClick={() => {
                                    this.SortBy('studentsLow')
                                }}></div>
                            </div>
                        </div>
                        <div className={s.sort_item}>
                            <div>Number of courses</div>
                            <div className={s.sort_img_wrap}>
                                <div className={s.high + ' ' + s.dropdown_link} onClick={() => {
                                    this.SortBy('courses')
                                }}></div>
                                <div className={s.low + ' ' + s.dropdown_link} onClick={() => {
                                    this.SortBy('coursesLow')
                                }}></div>
                            </div>
                        </div>
                    </div>

                    <div className={s.right_block_instrument_bar}>
                        <div className={s.invite_admin_btn} onClick={() => {
                            this.ToggleAddTeacher()
                        }}>
                            <div className={s.add_icon}></div>
                            <div className={s.invite_admin_btn_txt}>Invite new TEACHER </div>
                        </div>
                        <div className={s.search_btn}>
                            <div className={s.search_icon}></div>
                            <div className={s.search_input}>
                                <input type="text" name="search" placeholder="Search" value={this.state.search}
                                       onChange={this.handleSearchSubmit}
                                />
                            </div>
                            <div onClick={() => {
                                this.setState({search: ''}, () => {
                                    this.ClearSearch()
                                })
                            }}
                                 className={s.search_btn_txt}>x
                            </div>
                        </div>
                    </div>
                </div>

                {this.RenderAddTeacher()}

                <div style={{
                    display: (this.state.successSupervAdd)
                        ? 'block' : 'none'
                }}>
                    <div className={s.success_add_sup}>
                        Supervisor успешно добавлен
                    </div>

                </div>

                <div style={{
                    display: (this.state.successTeacherAdd)
                        ? 'block' : 'none'
                }}>
                    <div className={s.success_add_sup}>
                        Teacher успешно добавлен
                    </div>
                </div>

                <div className={s.tabs_menu}>
                    {Object.keys(teachers).map(courseName => {
                        return (courseName === 'All' || courseName === 'all')
                            ?
                            (<Navlink exact to="/admin/superadmin/teachers"
                                      activeClassName={s.active_tabs} className={s.nav_tabs}>
                                {courseName}
                            </Navlink>)
                            :
                            (<Navlink to={"/admin/superadmin/teachers/" + courseName}
                                      activeClassName={s.active_tabs} className={s.nav_tabs}>
                                {courseName}
                            </Navlink>)
                    })}
                </div>

                {Object.keys(teachers).map(courseName => {
                    return (courseName === 'All' || courseName === 'all')
                        ?
                        <Route exact path={Path} component={() => this.RenderTeachers(courseName)}/>
                        :
                        <Route exact path={Path + courseName} component={() => this.RenderTeachers(courseName)}/>
                })}
            </div>
        );
    };

    RenderTeachers = (courseName) => {

        let teachers = this.state.teachersByCourseList[courseName];
        const {supervisors} = this.state;
        const {activeAddSupSupervisor} = this.state;
        const {activeAddStudent} = this.state;
        const {activeAddCourse} = this.state;

        return (
            <div className={s.admins_list}>
                {Object.keys(teachers).map(teacherKey => {
                    // Дефолтные значения для селектов
                    let actualSupervisor = teachers[teacherKey].selectListSup.find(el => el.value === activeAddSupSupervisor)
                        || teachers[teacherKey].selectListSup[0] || {};

                    const actualStudent = teachers[teacherKey].selectListStudent.find(el => el.value === activeAddStudent)
                        || teachers[teacherKey].selectListStudent[0] || {};

                    const actualCourse = teachers[teacherKey].selectListCourses.find(el => el.value === activeAddCourse)
                        || teachers[teacherKey].selectListCourses[0] || {};
                    console.log('teachers[teacherKey]', teachers[teacherKey])

                    return(
                        <div key={teacherKey}>
                            <div className={s.admins_list_item}>

                                <div className={s.name}>
                                    {teachers[teacherKey].name} {teachers[teacherKey].surname}
                                </div>

                                <div className={s.admins_item_right_block}>
                                    <div className={s.supervised_teachers}>
                                        <div className={s.supervised_teachers_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleTeacher' + Number(teacherKey))}>
                                            <div className={s.supervised_teachers_list}>
                                                Supervised by (
                                                {teachers[teacherKey].supervisors.active.length + "/" + teachers[teacherKey].supervisors.inactive.length}
                                                )
                                            </div>
                                            <div
                                                className={this.state['isActiveToggleTeacher' + Number(teacherKey)] !== true
                                                    ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}> </div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleTeacher' + Number(teacherKey)] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_teacher : s.dropdown_teacher_hidden}>

                                            <div
                                                style={{
                                                    display: (this.state.activeToggleAddSupSupervisor)
                                                        ? 'none' : 'block'
                                                }}

                                                className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                                onClick={() => this.setState({
                                                    activeAddSupUser: teachers[teacherKey].id,
                                                    activeToggleAddSupSupervisor: true
                                                })}
                                            >
                                                + Add the new supervisor
                                            </div>

                                            <div style={{
                                                display: (this.state.activeAddSupUser !== teachers[teacherKey].id
                                                    && !this.state.activeToggleAddSupSupervisor)
                                                    ? 'none' : 'block'
                                            }}>

                                                <div className={s.dropdown_add_sup}>
                                                    <div className={s.dropdown_select}>
                                                        <Select required
                                                                defaultValue={{
                                                                    value: actualSupervisor.value,
                                                                    label: actualSupervisor.label
                                                                }}
                                                                name="courseName"
                                                                options={teachers[teacherKey].selectListSup}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={el => this.setState({activeAddSupSupervisor: el.value})}
                                                                placeholder="Select level"
                                                        />
                                                    </div>

                                                    <div className={s.dropdown_buttons}>
                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    callApi('dashboard/addSupervisorsUsers', 'post', {
                                                                        supervisorId: this.state.activeAddSupSupervisor,
                                                                        userId: this.state.activeAddSupUser,
                                                                    }).then(res => {
                                                                        if (res && res.data.ok) {
                                                                            this.setState({isActiveToggleSubmitCourse: true});
                                                                            let teachersByCourseListMod = this.state.teachersByCourseList;

                                                                            teachersByCourseListMod.all.map(el => {
                                                                                    if (el.id === this.state.activeAddSupUser) {
                                                                                        let findone = supervisors.find(el => el.id === this.state.activeAddSupSupervisor);
                                                                                        let unshift = `${findone.name} [${findone.email}]`;
                                                                                        el.supervisors.active.unshift(unshift);
                                                                                    }
                                                                                }
                                                                            );

                                                                            this.setState({teachersByCourseList: teachersByCourseListMod,
                                                                                successSupervAdd: true});
                                                                            setTimeout(() => this.setState({successSupervAdd: false}), 2000);

                                                                            this.setState({
                                                                                activeAddSupUser: false,
                                                                                activeToggleAddSupSupervisor: false,
                                                                            })

                                                                        }
                                                                    }).catch(err => {
                                                                    });
                                                                }}
                                                        >
                                                            Add
                                                        </Button>

                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        activeAddSupUser: false,
                                                                        activeToggleAddSupSupervisor: false,
                                                                    })
                                                                }}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.RenderMap(teachers[teacherKey].supervisors.active, 'activeTeachersKey')}
                                            {this.RenderMap(teachers[teacherKey].supervisors.inactive, 'inactiveTeachersKey', s.dropdown_link_red)}
                                        </div>
                                    </div>

                                    <div className={s.supervised_students}>
                                        <div className={s.supervised_student_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleStudent' + Number(teacherKey))}>
                                            <div className={s.supervised_students_list}>
                                                Students (
                                                {teachers[teacherKey].students.active.length + "/" + teachers[teacherKey].students.inactive.length}
                                                )
                                            </div>
                                            <div
                                                className={this.state['isActiveToggleStudent' + Number(teacherKey)] !== true
                                                    ? s.icon_dropdown_toggle
                                                    : s.un_icon_dropdown_toggle}>
                                            </div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleStudent' + Number(teacherKey)] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>

                                            <div
                                                style={{
                                                    display: (this.state.activeToggleAddStudent)
                                                        ? 'none' : 'block'
                                                }}

                                                className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                                onClick={() => this.setState({
                                                    activeAddSupUser: teachers[teacherKey].id,
                                                    activeToggleAddStudent: true
                                                })}
                                            >
                                                + Add the new student
                                            </div>

                                            <div style={{
                                                display: (this.state.activeAddSupUser !== teachers[teacherKey].id
                                                    && !this.state.activeToggleAddStudent)
                                                    ? 'none' : 'block'
                                            }}>

                                                <div className={s.dropdown_add_sup}>
                                                    <div className={s.dropdown_select}>
                                                        <Select required
                                                                defaultValue={{
                                                                    value: actualStudent.value,
                                                                    label: actualStudent.label
                                                                }}
                                                                name="courseName"
                                                                options={teachers[teacherKey].selectListStudent}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={el => this.setState({activeAddStudent: el.value})}
                                                                placeholder="Select level"
                                                        />
                                                    </div>

                                                    <div className={s.dropdown_buttons}>
                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    callApi('dashboard/addTeachersUsers', 'post', {
                                                                        teacherId: teachers[teacherKey].id,
                                                                        userId: this.state.activeAddStudent,
                                                                    }).then(res => {
                                                                        if (res && res.data.ok) {
                                                                            this.setState({isActiveToggleSubmitCourse: true});
                                                                            setTimeout(() => this.setState({isActiveToggleSubmitCourse: false}), 4000);
                                                                        }
                                                                    }).catch(() => {
                                                                    });
                                                                }}
                                                        >
                                                            Add
                                                        </Button>

                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        activeAddSupUser: false,
                                                                        activeToggleAddStudent: false,
                                                                    })
                                                                }}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.RenderMap(teachers[teacherKey].students.active, 'activeStudentKey')}
                                            {this.RenderMap(teachers[teacherKey].students.inactive, 'inactiveStudentKey', s.dropdown_link_red)}
                                        </div>
                                    </div>

                                    <div className={s.supervised_students}>
                                        <div className={s.supervised_student_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleCourses' + Number(teacherKey))}>
                                            <div className={s.supervised_students_list}>
                                                Courses (
                                                {teachers[teacherKey].courses.active.length + "/" + teachers[teacherKey].courses.inactive.length}
                                                )
                                            </div>
                                            <div
                                                className={this.state['isActiveToggleCourses' + Number(teacherKey)] !== true
                                                    ? s.icon_dropdown_toggle
                                                    : s.un_icon_dropdown_toggle}>
                                            </div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleCourses' + Number(teacherKey)] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>

                                            <div
                                                style={{
                                                    display: (this.state.activeToggleAddCourse)
                                                        ? 'none' : 'block'
                                                }}

                                                className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                                onClick={() => this.setState({
                                                    activeAddSupUser: teachers[teacherKey].id,
                                                    activeToggleAddSupCourse: true
                                                })}
                                            >
                                                + Add the new course
                                            </div>


                                            <div style={{
                                                display: (this.state.activeAddSupUser !== teachers[teacherKey].id
                                                    && !this.state.activeToggleAddCourse)
                                                    ? 'none' : 'block'
                                            }}>

                                                <div className={s.dropdown_add_sup}>
                                                    <div className={s.dropdown_select}>
                                                        <Select required
                                                                defaultValue={{
                                                                    value: actualCourse.value,
                                                                    label: actualCourse.label
                                                                }}
                                                                name="courseName"
                                                                options={teachers[teacherKey].selectListCourses}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={el => this.setState({activeAddCourse: el.value})}
                                                                placeholder="Select level"
                                                        />
                                                    </div>

                                                    <div className={s.dropdown_buttons}>
                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    callApi('dashboard/addTeachersCourses', 'post', {
                                                                        teacherId: teachers[teacherKey].id,
                                                                        courseId: activeAddCourse,
                                                                        creationDate: Math.floor((new Date()).getTime() / 1000),
                                                                    }).then(res => {
                                                                        if (res && res.data.ok) {
                                                                            this.setState({isActiveToggleSubmitCourse: true});
                                                                            setTimeout(() => this.setState({isActiveToggleSubmitCourse: false}), 4000);
                                                                        }
                                                                    }).catch(err => { });
                                                                }}
                                                        >
                                                            Add
                                                        </Button>

                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        activeAddSupUser: false,
                                                                        activeToggleAddCourse: false,
                                                                    })
                                                                }}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.RenderMap(teachers[teacherKey].courses.active, 'activeCourseKey')}
                                            {this.RenderMap(teachers[teacherKey].courses.inactive, 'inactiveCourseKey', s.dropdown_link_red)}
                                        </div>
                                    </div>

                                    <div className={s.date}>{teachers[teacherKey].date}</div>

                                    <div className={s.contact}>
                                        <div className={s.supervised_student_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleContact' + Number(teacherKey))}>
                                            <div className={s.supervised_students_list}>Contact</div>
                                            <div
                                                className={this.state['isActiveToggleContact' + Number(teacherKey)] !== true
                                                    ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}> </div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleContact' + Number(teacherKey)] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_contact : s.dropdown_student_hidden}>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}
                                                 onClick={() => {
                                                     callApi('dashboard/deleteUser', 'post', {
                                                         userId: teachers[teacherKey].id,
                                                         email: teachers[teacherKey].mail,
                                                     }).then(res => {
                                                         if (res && res.data.ok) {
                                                             alert ('User deleted');
                                                         } else {
                                                             alert ('User not deleted');
                                                         }
                                                     }).catch(() => {
                                                         alert ('User not deleted');
                                                     });
                                                 }}
                                            >
                                                Delete user
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571c32195480adaa0d37_chat-2.png"/>
                                                <div>CHAT</div>
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09586a2ae5446fc1a024d9_envelope.png"/>
                                                <div>{teachers[teacherKey].mail}</div>
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"/>
                                                <div>{teachers[teacherKey].phone1}</div>
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"/>
                                                <div>{teachers[teacherKey].phone2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.devide_line}></div>
                        </div>
                    )})}
            </div>
        );
    };

    render() {
        return (
            <div>
                <div className={s.content_admin}>
                    {this.RenderCourses()}
                </div>
            </div>
        );
    }
}

export default Teachers;
