import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import s from "../SuperAdmin.module.css";
import callApi from '../../../../utils/call-api';
import CoursItem from './CoursItem';
import CoursLevelItem from './CoursLevelItem';


const CoursList = ({lang, homework, onSelectedCourse}) => {
    const [language, setLanguage] = useState(lang);
    const [isHomework, setIsHomework] = useState(homework||false);
    const [courses, setCourses] = useState([]);
    const [selectedCourseGroup, setSelectedCourseGroup] = useState(null);
    const [isAddNewCourse, setisAddNewCourse] = useState(false);
    const [newCourse, setNewCourse] = useState({});

    useEffect(() => {
        setLanguage(lang);
        getCourses();
    }, [lang]);

    useEffect(() => {
        setIsHomework(homework);
        getCourses();
    }, [homework]);

    useEffect(() => {
        updateNewCourseName(selectedCourseGroup);
    },[selectedCourseGroup])

    useEffect(() => {
        if(selectedCourseGroup) {
            const selectedCourseClone = courses.find(el => el.name === selectedCourseGroup.name);
            setSelectedCourseGroup(selectedCourseClone);
        }
    },[courses])

    const getCourses = async () => {
        callApi('dashboard/courses', 'GET', {languageId: language.id, isForHomework: isHomework})
        .then(data=>data.data.coursesData)
        .then(data=>{
            return data.reduce((acc, course) => {
                const courseInArray = acc.find(el => el.name === course.name);
                if (courseInArray) {
                    courseInArray.levels.push(course);                    
                } else {
                    acc.push({
                       name: course.name,
                       language: course.language,
                       isForHomework: course.isForHomework,
                       levels: [course]
                    })
                }
                return acc;
            }, []);
        })
        .then(data=>setCourses(data))
        .catch(err=>{
            console.log(err);
            setCourses([]);
        });
    };

    const ToggleCourses = (course) => {
        if(selectedCourseGroup && selectedCourseGroup.name === course.name) {
            setSelectedCourseGroup(null);
        }else{
            setSelectedCourseGroup(course);
            updateNewCourseName();
        }
    };

    const selectLevel = (level) => {
        onSelectedCourse(level);
    };

    const addNewCourseClick = () => {
        setisAddNewCourse(!isAddNewCourse);
        if (!isAddNewCourse) {
            setNewCourse({
                name: selectedCourseGroup ? selectedCourseGroup.name : '',
                level: '',
                language: language
            });
        }
    };

    const updateNewCourseName = (course)=>{
        if(course && newCourse) { 
            if (course.name != newCourse.name) {
                let newCourseCopy = {...newCourse};
                newCourseCopy.name = course.name;
                setNewCourse(newCourseCopy);
            }
        }
    }

    const onChangeLevelName = (e)=>{
        let newCourseCopy = {...newCourse};
        newCourseCopy.level = e.target.value;
        setNewCourse(newCourseCopy);
    };

    const onChangeCourseName = (e)=>{
        let newCourseCopy = {...newCourse};
        newCourseCopy.name = e.target.value;
        setNewCourse(newCourseCopy);
    };

    const submitCourse = () => {
        if (newCourse && newCourse.name && newCourse.level) {
            callApi('dashboard/addCourse', 'post', {
                name: newCourse.name,
                languageId: newCourse.language.id,
                type: newCourse.level,
                isForHomework: isHomework,
            }).then(res => {
                if (res && res.data.ok) {
                    setisAddNewCourse(false);
                    getCourses();                    
                }
            }).catch(err => _(err));
        }
    };

    return (
        <div>
            <div>
                <div className={s.courses_wrap}>
                    {courses.length === 0 && <div className={s.no_courses}>No Courses</div>}
                    {courses.length > 0 && courses.map(course => (
                        <CoursItem item={{courseKey: course.name , ...course}} selected={selectedCourseGroup&& selectedCourseGroup.name===course.name } ToggleCourses={ToggleCourses}/>
                    ))}
                </div>
                {selectedCourseGroup && (
                    <div className={s.courses_levels_item}>
                        {selectedCourseGroup.levels.map(level => (
                            <CoursLevelItem item={level} homework={selectedCourseGroup.isForHomework} onClick={(level) => selectLevel(level)}></CoursLevelItem>
                        ))}
                    </div>
                )}
                <div className={s.new_course_wrap}>
                    <div className={s.create_new_course_button}>
                        <div className={s.create_new_course_text} onClick={addNewCourseClick}>
                            Create new course
                        </div>
                    </div>
           
                {isAddNewCourse && (
                    <div
                        className={isAddNewCourse !== false ? s.form_new_course : s.hidden_level}>
                        <form action="" className={s.form_level}>
                            <TextField
                                inputProps={{
                                    readOnly: Boolean(selectedCourseGroup)
                                }}
                                label="Name of the new course"
                                value={newCourse.name}
                                fullWidth
                                onChange={onChangeCourseName}
                            /> <br/>
                            <TextField
                                label="Name of the new level"
                                value={newCourse.type}
                                fullWidth
                                onChange={onChangeLevelName}
                            /> <br/>
                            <TextField
                                inputProps={{
                                    readOnly: true                            
                                }}
                                label="Language"
                                value={newCourse?newCourse.language.name: ""}
                                fullWidth
                                enebled
                            /> <br/>
                   
                            <input type="button" value="Done" onClick={submitCourse}/>
                        </form>
                        <br/>
                    </div>
                )}
                </div>
            </div>
        </div>
    );
};

export default CoursList;