import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button/Button';
import MenuItem from '@material-ui/core/MenuItem';
import IconSpeech from '../assets/images/icons/speech bubble.png';
import UserInfo from './UserInfo';
import {bindActionCreators} from 'redux';
import SideNavContent from './SideNavContent';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from '../constants';
import {toggleCollapsedNav, updateWindowWidth, setLessonIdent, switchLanguage} from '../actions/settings';
import { openLikes, closeLikes, participantEmail } from '../actions/likes';
import comparingValuesInSymbol from '../utils/comparing-symbol-values';
import callApi from '../utils/call-api';
import { connect as twilioConnect, createLocalTracks, isSupported } from 'twilio-video';
import './SideNav.scss';
import config from '../config';
import lang from '../utils/lang';

import studentConnectSound from '../../src/assets/sounds/lesson_connection_student.wav';
import teacherConnectSound from '../../src/assets/sounds/lesson_connection_teacher.mp3';
import lessonEndSound from '../../src/assets/sounds/lesson_end.mp3';


const LESSON_ENTER_MIT_TIME_FOR_STUDENT = 3 * 60; // 3 min
const LESSON_ENTER_MIT_TIME_FOR_TEACHER = 3 * 60; // 10 vin

const COUNT_TRY_GET_DEVICES = 5;
const COUNT_TRY_GET_ROOM = 5;
const COUNT_TRY_RE_JOIN_ROOM = 5;
const WAIT_TO_GET_DEVICES_MILLISECONDS = 10*1000;
const WAIT_TO_GET_ROOM_MILLISECONDS = 3*1000;
const WAIT_TO_REJOIN_GET_ROOM_MILLISECONDS = 3*1000;
const VIDEO_WIDTH = 342;


class SideNav extends React.PureComponent {
    vCId = this.props.vCId;
    numberOfAttemptsGetRoom =0;
    numberOfAttemptsReJoinRoom = 0;
    teacherMediaContainer = [];
    studentMediaContainer = [];
    state = {
        message: "инициализация",
        roomSid: null,
        userType: null,
        userEmail: null,

        showSettings: false,
        roomConnectionStatus: false,
        // joinRoomDisabled: false,
        // joinRoomDisabledViaRequest: false,
        availableLocalTracks: { audio: false, video: false },
        selectedLocalTracks: { audio: false, video: false },
        videoPreviewDisabled: false,
        error: '',
        room: '',
        grade: [],
        mediaContainerLayout: 'media-container',
        defaultStudentLocalMediaContainer: 'local-media-student-container',
        defaultTeacherLocalMediaContainer: 'local-media-teacher-container',
        isLocalParticipantMuted: false,
        isLocalParticipantMutedByTeacher: false,
        isLocalParticipantVideoDisabled: false,
        isLocalParticipantVideoDisabledByTeacher: false,
        activeParticipantFlexOrder: 1,
        participantSelfFlexOrder: 2,
        participantFlexOrder: 3,
        isUserHaveNotControl: true, //Can user pass tests, need logic in component in future (https://stackoverflow.com/questions/639815/how-to-disable-all-div-content)
        rocketsCount: config.maxMarkValue, //Rockets count = mark value
        audio: {},
        currentDominantSpeakerSid: null,
        activeStudentSid: null,
        studentWithControleSid: null,
    };

    onToggleCollapsedNav = e => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    updateViewStudentWithControle = participantSid => {
        const participantDiv = document.getElementById(participantSid);

        if (participantDiv) {
            if (participantDiv.classList.contains('viewStudentWithControle')) {
                participantDiv.classList.remove('viewStudentWithControle');
                this.setState({studentWithControleSid: null});
            } else {
                const oldParticipantDiv = document.getElementById(this.state.studentWithControleSid);
                if (oldParticipantDiv) {
                    oldParticipantDiv.classList.remove('viewStudentWithControle');
                }

                this.setState({studentWithControleSid: participantSid});
                participantDiv.classList.add('viewStudentWithControle');
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps.socket ||!prevProps.socket.onopen) && (this.props.socket && this.props.socket.onopen)) {
            this.props.socket.addEventListener("message", e => {
                try {
                    const data = JSON.parse(e.data);

                    if (data.command === 'roomLessonKeyUpdate') {
                        if (this.state.roomSid !== data.data.roomSid) {
                            this.setState({roomSid: data.data.roomSid});
                            if (this.state.roomConnectionStatus) {
                                this.disconnected(this.state.room);
                                this.joinRoom();
                            }
                        }
                    }                    

                    if (data.command === 'muteRemoteParticipant' && !this.state.isLocalParticipantMuted) {
                        const participantSid = data.data.participantSid;
                        const changeMuteStatusButton = document.getElementById('changeMuteStatusButton');

                        if (this.state.room &&
                            this.state.room.localParticipant.sid === participantSid &&
                            this.state.room.localParticipant.audioTracks.size) {

                            if (this.state.isLocalParticipantMutedByTeacher) {
                                this.unmuteLocalParticipantByTeacher();

                                changeMuteStatusButton.disabled = false;
                                changeMuteStatusButton.style.visibility = 'visible';
                            } else {
                                this.muteLocalParticipantByTeacher();

                                changeMuteStatusButton.disabled = true;
                                changeMuteStatusButton.style.visibility = 'hidden';
                            }
                        }
                    }

                    if (data.command === 'disableVideoRemoteParticipant' && !this.state.isLocalParticipantVideoDisabled) {
                        const participantSid = data.data.participantSid;
                        const disableVideoStatusButton = document.getElementById('disableVideoStatusButton');

                        if (this.state.room &&
                            this.state.room.localParticipant.sid === participantSid &&
                            this.state.room.localParticipant.videoTracks.size) {

                            if (this.state.isLocalParticipantVideoDisabledByTeacher) {
                                this.enableVideoLocalParticipantByTeacher();

                                disableVideoStatusButton.disabled = false;
                                disableVideoStatusButton.style.visibility = 'visible';
                            } else {
                                this.disableVideoLocalParticipantByTeacher();

                                disableVideoStatusButton.disabled = true;
                                disableVideoStatusButton.style.visibility = 'hidden';
                            }
                        }
                    }

                    if (data.command === 'finishLesson' && this.state.room) {
                        // this.lessonConducted();
                        this.completeRoom(data && data.teacherComment || '');

                        try{
                            if (this.state.audio.lessonEnd) {
                            this.state.audio.lessonEnd.play();
                            }
                        }
                        catch(e){
                            console.log(e);
                        }
                    }

                    if (data.command === 'giveControlRemoteParticipant') {
                        this.updateViewStudentWithControle(data?.data?.participantSid);
                    }

                    if (data.command === 'makeParticipantActive') {
                        const participantSid = data?.data?.participantSid;
                        const userEmail = data?.data?.userEmail;

                        if (this.state.userEmail !== userEmail) return;

                        const participantDiv = document.getElementById(participantSid);

                        if (participantDiv && +participantDiv.style.order !== this.state.activeParticipantFlexOrder) {
                            participantDiv.style.order = this.state.activeParticipantFlexOrder;
                            participantDiv.classList.toggle('participant-full-width');
                        } else if (participantDiv && +participantDiv.style.order === this.state.activeParticipantFlexOrder) {

                            if (participantSid === this.state.room.localParticipant.sid) {
                                participantDiv.style.order = this.state.participantSelfFlexOrder;
                            } else {
                                participantDiv.style.order = this.state.participantFlexOrder;
                            }

                            participantDiv.classList.toggle('participant-full-width');
                        }
                    }
                } catch (e) {
                }
            });
        }
    }

    async componentDidMount() {
        const storage = JSON.parse(localStorage.getItem('user') || null);

        if (storage && storage.userInfo) {
            if (storage.userInfo.type) {
                this.setState({ userType: storage.userInfo.type });
            }

            if (storage.userInfo.email) {
                this.setState({ userEmail: storage.userInfo.email });
            }
        }

        window.addEventListener('resize', () => {
            this.props.updateWindowWidth(window.innerWidth);
        });
        //this.setAudioFiles();

        await Promise.all([
            this.getDevicesList(),
            // this.getRoom(),
            this.setAudioFiles()
        ]);
    }
    
    openCloseSetings = ()=>{
        this.setState({showSettings: !this.state.showSettings});
    }

    renderDivisesList = () => {
        let showSettings = (!this.state.roomConnectionStatus)||this.state.showSettings;
        return (
            <div>
                <Button
                    className="enterIntoRoom"
                    variant='contained'
                    color='primary'
                    onClick={this.openCloseSetings}
                >{"Настройки"}</Button>
            {/* <div className={"navIconsDiv"  + (this.state.showSettings ? ' navIconsDivOpen' : '')}
                                     onClick={this.openCloseSetings}>
                                        Настройки
                                    <img className="right_panel_img" src={IconSpeech} alt="chat"/>
                                </div> */}
            <div style={{"display":showSettings? "contents" : "none"}}>
                {this.state.availableLocalTracks.video.length ?
                    <div className="cameraAndMicrophone">
                        <h3>{lang('Camera')}</h3>
                        <div id='local-media'></div>
                        <Select
                            labelId="rows-label"
                            value={this.state.selectedLocalTracks.video || 0}
                            label="Camera"
                            disabled={this.state.videoPreviewDisabled}
                            onChange={(e) => {
                                this.setState(prevState => {
                                    return {
                                        selectedLocalTracks: {
                                            ...prevState.selectedLocalTracks,
                                            video: e.target.value
                                        }
                                    }
                                });
                                if (this.state.roomConnectionStatus){
                                    this.joinRoom();
                                }
                            }
                            }>
                                {this.state.availableLocalTracks.video.map((elem, i) => <MenuItem key={i} value={i}>{elem.label}</MenuItem>)}
                        </Select>
                    </div>:
                        <div>
                            <h3>{lang('Camera not found')}</h3>
                        </div>
                    
                }
                {this.state.availableLocalTracks.audio.length ? 
                    <div class="cameraAndMicrophone">
                        <h3>{lang('Microphone')}</h3>
                        <div id='local-audio-media'></div>
                        <Select
                            labelId="rows-label"
                            value={this.state.selectedLocalTracks.audio || 0}
                            label="Microphone"
                            disabled={this.state.videoPreviewDisabled}
                            onChange={(e) => {
                                this.setState(prevState => {
                                    return {
                                        selectedLocalTracks: {
                                            ...prevState.selectedLocalTracks,
                                            audio: e.target.value
                                        }
                                    }
                                });
                                if (this.state.roomConnectionStatus){
                                    this.joinRoom();
                                }
                            }}
                        >
                            {this.state.availableLocalTracks.audio.map((elem, i) => <MenuItem key={i} value={i}>{elem.label}</MenuItem>)}
                        </Select>
                    </div> :
                    <div>
                        <h3>{lang('Microphone not found')}</h3>
                    </div>
                }
                <Button
                    className="enterIntoRoom"
                    variant='contained'
                    color='primary'
                    onClick={this.getLocalTracks}
                >{"Обновить"}</Button>
                {/* {this.rendegShowStatusMessage()} */}
                {this.renderJoinBotton()}
                </div>
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}>
                    <div id ={this.state.mediaContainerLayout} className='students-container' > </div>
                    {/* <div id={this.state.defaultTeacherLocalMediaContainer} className='teacher-container'> </div>
                    <div id={this.state.defaultStudentLocalMediaContainer} className='students-container'> </div> */}
                </div>
            </div>
        );
    }

    rendegShowStatusMessage(){
        return ( 
        <div>
            <h3>{this.state.message}</h3>
        </div>);

    }

    renderJoinBotton() {
        return (
            <div>
                <Button
                    className="enterIntoRoom"
                    variant='contained'
                    color='primary'
                    onClick={async ()=>{await this.joinRoom();}}
                >{"Подключится"}
                </Button>
            </div>
        );
    }
   
    initRoom = async()=>{
        if(!this.getRoomInretvalId){
            this.getRoomInretvalId = setInterval(()=>{
                if (this.numberOfAttemptsGetRoom< COUNT_TRY_GET_ROOM){
                    this.numberOfAttemptsGetRoom++;
                    this.getRoom();
                } else {
                    this.numberOfAttemptsGetRoom = 0;
                    clearInterval(this.getRoomInretvalId);
                    this.getRoomInretvalId = null;
                    this.setState({
                        message: "Не получилось создать комнату",
                        roomSid: null
                    })
                }
            }, WAIT_TO_GET_ROOM_MILLISECONDS);
        }
    }

    getRoom = async() =>{
        await callApi('twilio/getVideoRoom','get', { virtualLessonId: this.vCId, forceCreate: (this.numberOfAttemptsReJoinRoom+1) == COUNT_TRY_GET_ROOM})
        .then(res => {
            if (res && res.data.ok && res.data.roomSid){
                this.setState({
                    roomSid: res.data.roomSid,
                    message: 'Комната создана'
                },()=>{
                    // отправить сообщение что номер получин номер комнаты
                    this.props.socket.send(JSON.stringify({
                        command: 'roomLessonKeyUpdate',
                        data: { roomSid: res.data.roomSid },
                        val: {
                            vCId: this.vCId,
                            userEmail: this.state.userEmail,
                            userType: this.state.userType
                        }
                    }));
                });
                if (this.getRoomInretvalId){
                    this.numberOfAttemptsGetRoom = 0;
                    clearInterval(this.getRoomInretvalId);
                    this.getRoomInretvalId = null;
                }
            } else {
                this.setState({message: res.data.message});
            }
        })
        .catch(err=>{
            this.initRoom();
            this.setState({message: err});
        });
    }

    getDevices = async () =>{
        return await navigator.mediaDevices.enumerateDevices()
        .then(devices =>{
            const videoInput = devices.filter(device => device.kind === 'videoinput');
            const audioInput = devices.filter(device => device.kind === 'audioinput');
            const localTracks = { audio: false, video: false };
            if (videoInput) {
                localTracks.video = videoInput;
            }

            if (audioInput) {
                localTracks.audio = audioInput;
            }
            return localTracks;
        })
    }

    reGetDevices = async () => {
        if(!this.getGevicesInterval){
            this.getGevicesInterval = setInterval(()=>{
                if (this.numberOfAttemptsGetDevices< COUNT_TRY_GET_DEVICES){
                    this.numberOfAttemptsGetDevices++;
                    this.getRoom();
                } else {
                    this.numberOfAttemptsGetDevices = 0;
                    clearInterval(this.getGevicesInterval);
                    this.getGevicesInterval = null;
                    this.setState({
                        message: "Не получилось получить список устройств"
                    })
                }
            }, WAIT_TO_GET_DEVICES_MILLISECONDS);
        }        
    }

    getDevicesList = async() => {
        await this.getDevices()
        .then(localTracks=>{
            if (localTracks){
                if (this.getGevicesInterval){
                    this.numberOfAttemptsGetDevices = 0;
                    clearInterval(this.getGevicesInterval);
                    this.getGevicesInterval = null;
                }
                this.setState({
                    availableLocalTracks: localTracks,
                    selectedLocalTracks: {
                        audio: localTracks.audio.length ? 0 : false,
                        video: localTracks.video.length ? 0 : false
                    },
                    message: `Списки устройств получено`
                });
            }
        })
        .catch(error=>{
            this.setState({message: `Возникла ошибка ${error}`});
            this.reGetDevices();
        });
        if (!(this.state.availableLocalTracks.audio&&this.state.availableLocalTracks.video)){
            this.setState({message: `Устройства не найдены попробуйте чуть позже`});
        }
    }

    reJoinRoom = async () => {
        if(!this.getJoinRoomInretvalId){
            this.getJoinRoomInretvalId = setInterval(()=>{
                if (this.numberOfAttemptsReJoinRoom< COUNT_TRY_RE_JOIN_ROOM){
                    this.numberOfAttemptsReJoinRoom++;
                    this.joinRoom();
                } else {
                    this.numberOfAttemptsReJoinRoom = 0;
                    clearInterval(this.getJoinRoomInretvalId);
                    this.getJoinRoomInretvalId = null;
                    this.setState({
                        message: "Не получилось соединится с комнату",
                    })
                }
            }, WAIT_TO_REJOIN_GET_ROOM_MILLISECONDS);
        }        
    }

    joinRoom = async () => {
        let tracks;
        createLocalTracks({
            audio: typeof this.state.selectedLocalTracks.audio === 'boolean' ? this.state.selectedLocalTracks.audio : { ...this.state.availableLocalTracks.audio[this.state.selectedLocalTracks.audio] },
            video: typeof this.state.selectedLocalTracks.video === 'boolean' ? this.state.selectedLocalTracks.video : {
                ...this.state.availableLocalTracks.video[this.state.selectedLocalTracks.video],
                width: VIDEO_WIDTH
            }
        })
        .then(t=> tracks = t)
        .then(()=>this.getRoom())
        .then(async () => {
            return  await callApi('twilio/createVideoToken', 'post', {roomSid: this.state.roomSid})
            .then(res=>{
                if (res && res.data.ok) {
                    return res.data.token;
                } else {
                    this.setState({ error: lang(res.data.message) });
                    throw new Error("res.data.message");
                }
            });
        })
        .then(async twilioToken => {
            await twilioConnect(
                twilioToken,
                {
                    name: this.state.roomSid,
                    tracks: tracks,
                    dominantSpeaker: true
                    // logLevel: 'debug'
                }
            )
            .then(room => {
                if (this.getJoinRoomInretvalId){
                    this.numberOfAttemptsReJoinRoom = 0;
                    clearInterval(this.getJoinRoomInretvalId);
                    this.getJoinRoomInretvalId = null;
                }
                // Listen to the "beforeunload" event on window to leave the Room when the tab/browser is being closed.
                window.addEventListener('beforeunload', () => room.disconnect());
    
                // iOS Safari does not emit the "beforeunload" event on window. Use "pagehide" instead.
                window.addEventListener('pagehide', () => room.disconnect());
    
                if (this.state.userType === 'teacher') {
                    room.participantsStickersData = {};
                    room.participantsGradesData = {};
                }
    
                room.on('disconnected', room => this.disconnected(room));
 
                // Добавление подключившихся пользователей в контейнер
                room.on('participantConnected', participant => this.participantConnected(room, participant));
                
                room.on('participantDisconnected', participant => this.participantDisconnected(room, participant));
    
                room.on('dominantSpeakerChanged', participant => this.dominantSpeakerChanged(room, participant));
                this.setState({
                    room: room,
                    roomConnectionStatus: true
                });
                this.prepereMediainfo(tracks);
                this.renderVideo();
            })
        })
        .catch(err => {
            try {
                callApi('twilio/logVideoException', "post",{vlId: this.vCId, message: err.message})
                .catch(e=>{console.log(e)});
                tracks.forEach(track => track.stop());
            } catch (e) {
                console.log(e);
            }
            if (err.message === "Room not found"){
                this.getRoom();
            }
            this.reJoinRoom();
        });
    }

    prepereMediainfo(tracks){
        if(this.state.room){
            const room = this.state.room;
            const storage = JSON.parse(localStorage.getItem('user') || null);
            const local = {
                email: storage.userInfo.email,
                name: storage.userInfo.name,
                type: storage.userInfo.type,
                sid: room.localParticipant.sid,
                isLocal: true,
                tracks: tracks
            };
            let studentsParticipants = [];
            let teacherParticipants = [];
            if (room.participants.size) {
                room.participants.forEach(item=>{
                    const participantIdentity = JSON.parse(item.identity);
                    participantIdentity.sid = item.sid
                    participantIdentity.tracks = item.tracks;
                    participantIdentity.isLocal = false;
                    participantIdentity.participant = item;
                    if (participantIdentity.type === "student"){
                        studentsParticipants.push(participantIdentity);
                    }
                    if (participantIdentity.type === "teacher"){
                        teacherParticipants.push(participantIdentity);
                    }
                });
            }
            if (storage.userInfo.type === "student"){
                studentsParticipants.push(local);
            } else{
                teacherParticipants.push(local);
            }
            this.teacherMediaContainer = teacherParticipants;
            this.studentMediaContainer = studentsParticipants;
            return {teacherParticipants, studentsParticipants};
        }
    }

    renderMediaItem(mediaInfo, itemSizeClass) {
        const div = document.createElement('div');
        div.id = mediaInfo.sid;
        div.style.order = this.state.participantSelfFlexOrder;
        // div.style.style.marginBottom = "avto";
        div.className = itemSizeClass;
        const span = document.createElement('span');
        span.innerText = mediaInfo.name;
        div.appendChild(span);
        
        if(mediaInfo.isLocal) {
            const userMenuDiv = document.createElement('div');
            userMenuDiv.className = 'user_menu_slide user_menu_slide_left';

            const muteLocalParticipantButton = document.createElement('button');
            muteLocalParticipantButton.id = 'changeMuteStatusButton';
            muteLocalParticipantButton.className = 'sound_button';
            muteLocalParticipantButton.onclick = () => {
                this.state.isLocalParticipantMuted ? this.unmuteLocalParticipant() : this.muteLocalParticipant();
                muteLocalParticipantButton.classList.toggle('sound_button');
                muteLocalParticipantButton.classList.toggle('sound_button_disable');
            };

            userMenuDiv.appendChild(muteLocalParticipantButton);

            const disableVideoLocalParticipantButton = document.createElement('button');
            disableVideoLocalParticipantButton.id = 'disableVideoStatusButton';
            disableVideoLocalParticipantButton.className = 'video_button';
            disableVideoLocalParticipantButton.onclick = () => {
                this.state.isLocalParticipantVideoDisabled ? this.enableVideoLocalParticipant() : this.disableVideoLocalParticipant();
                disableVideoLocalParticipantButton.classList.toggle('video_button');
                disableVideoLocalParticipantButton.classList.toggle('video_button_disable');
            };
            userMenuDiv.appendChild(disableVideoLocalParticipantButton);
            mediaInfo.tracks.forEach(track => {
                div.appendChild(track.attach());
            });
            if (this.state.userType === 'teacher'){
                div.appendChild(userMenuDiv);
            }
        } else {
            if (mediaInfo.participant) {
                mediaInfo.participant.on("trackSubscribed", track=> div.appendChild(track.attach()));
           }
        }
        

        if (this.state.userType === 'teacher' && !mediaInfo.isLocal) {
            const userMenuDiv = document.createElement('div');
            userMenuDiv.className = 'user_menu_slide user_menu_slide_left';

            const room = this.state.room;
            room.participantsStickersData[mediaInfo.sid] = { isOpen: false };
            room.participantsGradesData[mediaInfo.sid] = { isOpen: false };
            
            const muteRemoteParticipantButton = document.createElement('button');
            muteRemoteParticipantButton.className = 'sound_button';
            muteRemoteParticipantButton.onclick = () => {
                this.muteRemoteParticipant(mediaInfo.sid);
                muteRemoteParticipantButton.classList.toggle('sound_button');
                muteRemoteParticipantButton.classList.toggle('sound_button_disable');
            };
            userMenuDiv.appendChild(muteRemoteParticipantButton);
            
            const disableVideoRemoteParticipantButton = document.createElement('button');
            disableVideoRemoteParticipantButton.className = 'video_button';
            disableVideoRemoteParticipantButton.onclick = () => {
                this.disableVideoRemoteParticipant(mediaInfo.sid);
                disableVideoRemoteParticipantButton.classList.toggle('video_button');
                disableVideoRemoteParticipantButton.classList.toggle('video_button_disable');
            };
            userMenuDiv.appendChild(disableVideoRemoteParticipantButton);

            const activeRemoteParticipantButton = document.createElement('button');
            activeRemoteParticipantButton.className = 'active_participant_button';
            activeRemoteParticipantButton.onclick = () => {
                this.makeParticipantActive(mediaInfo.sid);
                activeRemoteParticipantButton.classList.toggle('active_participant_button');
                activeRemoteParticipantButton.classList.toggle('active_participant_button_disable');
            };
            userMenuDiv.appendChild(activeRemoteParticipantButton);
            div.appendChild(userMenuDiv);

            const userMenuDivRight = document.createElement('div');
            userMenuDivRight.className = 'user_menu_slide user_menu_slide_right';
            
            const animationButton = document.createElement('button');
            animationButton.className = 'animation_button';
            animationButton.onclick = () => {
                this.openAnimationsRemoteParticipant(mediaInfo.sid);
                this.openCloseLikes(mediaInfo.sid, mediaInfo.email, mediaInfo.name);
                animationButton.classList.toggle('animation_button');
                animationButton.classList.toggle('animation_button_disable');
            };
            userMenuDivRight.appendChild(animationButton);
            
            const controlButton = document.createElement('button');
            controlButton.className = 'control_button';
            controlButton.onclick = () => {
                this.giveControlRemoteParticipant(mediaInfo.email, mediaInfo.sid);
                controlButton.classList.toggle('control_button');
                controlButton.classList.toggle('control_button_disable');
                
                let newActiveStudentSid = mediaInfo.sid;
                
                if (this.state.activeStudentSid && (this.state.activeStudentSid !== newActiveStudentSid)) {
                    const activeStudentElem = document.getElementById(this.state.activeStudentSid);
                    const activeStudentButton = activeStudentElem.getElementsByClassName('control_button_disable')[0];
                    
                    if (activeStudentButton) {
                        activeStudentButton.classList.toggle('control_button');
                        activeStudentButton.classList.toggle('control_button_disable');
                    }
                } else if (this.state.activeStudentSid && (this.state.activeStudentSid === newActiveStudentSid)) {
                    newActiveStudentSid = null;
                }
                
                this.setState({activeStudentSid: newActiveStudentSid});
            };
            
            userMenuDivRight.appendChild(controlButton);
            const gradeButton = document.createElement('button');
            gradeButton.className = 'grade_button';
            gradeButton.onclick = () => {
                this.openGradeParticipant(mediaInfo.sid);
                gradeButton.classList.toggle('grade_button');
                gradeButton.classList.toggle('grade_button_disable');
            };
            userMenuDivRight.appendChild(gradeButton);
            
            const gradeDiv = document.createElement('div');
            gradeDiv.className = 'participant_grades';
            gradeDiv.style.visibility = 'hidden';
            
            for (let i = 0; i < this.state.rocketsCount; i++) {
                const grade = document.createElement('div');
                grade.className = 'grade_number grade_not_delivered';
                grade.addEventListener("click", () => {
                    this.giveGradeParticipant(mediaInfo, i + 1, mediaInfo.sid);
                });
                gradeDiv.appendChild(grade);
            }

            userMenuDivRight.appendChild(gradeDiv);
            div.appendChild(userMenuDivRight);
        }

        // div.appendChild(userMenuDiv);

        return div;
    };

    renderVideo() {
        const mediaContainerLayout = document.getElementById(this.state.mediaContainerLayout);
        // const teacherMediaContainer = document.getElementById(this.state.defaultTeacherLocalMediaContainer);
        // const studentMediaContainer = document.getElementById(this.state.defaultStudentLocalMediaContainer);

        this.removeMediaItems(mediaContainerLayout, this.teacherMediaContainer);
        this.removeMediaItems(mediaContainerLayout, this.studentMediaContainer);

        this.addMediaItems(mediaContainerLayout, this.teacherMediaContainer);
        this.addMediaItems(mediaContainerLayout, this.studentMediaContainer);
    };

    removeMediaItems(mediaContainer, mediaInfoArray) {
        Object.values(mediaContainer.childNodes)
        .filter(item=>item.id)
        .filter(item=>!mediaInfoArray.find(el=>el.sid === item.id))
        .forEach(item=> item.remove());
    }

    addMediaItems(mediaContainer, mediaInfoArray){
        const sizeClass = mediaInfoArray.length > 1 ? 'participant' : 'participant_big';
        const mediaInContainer = Object.values(mediaContainer.childNodes).filter(item=>item.id);
        mediaInfoArray
        .sort((i1,i2)=>{
            if (i1.isLocal){
                return -1;
            }else return 0;
        })
        .forEach((item, index)=>{
            const el = mediaInContainer.find(el=>el.id === item.sid);
            if (el){
                const className = this.getMediaSizeClass(mediaInfoArray, index);
                el.classList.remove(className==='participant' ? 'participant_big': 'participant');
                el.classList.add(className);
                
            } else {
                mediaContainer.appendChild(this.renderMediaItem(item, this.getMediaSizeClass(mediaInfoArray, index)));
            }
            
        });
    }

    getMediaSizeClass(mediaInfoArray, index){
        const height = window.innerHeight - 265 - 40;
        if (mediaInfoArray[index].type === "teacher"){
            return "participant_big";
        }
        if ((260*mediaInfoArray.length) < height) {
            return "participant_big";
        }
        if ((260+(Math.floor((mediaInfoArray.length-1)/2) + (mediaInfoArray.length-1)%2)*135) < height){
            return index==0?"participant_big":"participant"
        }
        return "participant";
    }

    completeRoom = async (teacherComment) => {
        console.log('Complete room');

        //Not need promise.all
        await this.state.room.disconnect();
        await callApi('twilio/completeVideoRoom', 'post', {
            virtualLessonId: this.vCId,
            teacherComment,
        }).then(async res => {

            if (res && res.data.ok) {
                this.state.room.localParticipant.tracks.forEach(publication => {
                    const attachedElements = publication.track.detach();
                    attachedElements.forEach(element => element.remove());
                });

                this.setState(prevState => {

                    return {
                        room: '',
                        roomConnectionStatus: false,
                        // roomParticipantsLocalStatus: {},
                        roomSid: prevState.roomSid                        
                    }
                });

                // await this.finishLesson();
            } else {
                this.setState({ error: res.data.message });
            }
        }).catch(err => {
            this.setState({ error: lang('Cannot complete video room') });
        });
    };

    setAudioFiles = async () => {
        try {
            const studentConnectAudio = new Audio();
            studentConnectAudio.src = studentConnectSound;
            
            const teacherConnectAudio = new Audio();
            teacherConnectAudio.src = teacherConnectSound;

            const lessonEndAudio = new Audio();
            lessonEndAudio.src = lessonEndSound;
            lessonEndAudio.volume = global.soundsVolume;

            this.setState({
                audio: {
                    studentConnect: studentConnectAudio,
                    teacherConnect: teacherConnectAudio,
                    lessonEnd: lessonEndAudio
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    muteLocalParticipant = async () => {
        this.state.room.localParticipant.audioTracks.forEach(publication => {
            publication.track.disable();
        });

        await this.setState({ isLocalParticipantMuted: true });

        console.log(this.state.isLocalParticipantMuted, 'muteButton');
    };

    unmuteLocalParticipant = async () => {
        this.state.room.localParticipant.audioTracks.forEach(publication => {
            publication.track.enable();
        });

        await this.setState({ isLocalParticipantMuted: false });

        console.log(this.state.isLocalParticipantMuted, 'unmuteButton');
    };

    muteLocalParticipantByTeacher = async () => {
        this.state.room.localParticipant.audioTracks.forEach(publication => {
            publication.track.disable();
        });

        await this.setState({ isLocalParticipantMutedByTeacher: true });

        console.log(this.state.isLocalParticipantMutedByTeacher, 'muteButtonByTeacher');
    };

    unmuteLocalParticipantByTeacher = async () => {
        this.state.room.localParticipant.audioTracks.forEach(publication => {
            publication.track.enable();
        });

        await this.setState({ isLocalParticipantMutedByTeacher: false });

        console.log(this.state.isLocalParticipantMutedByTeacher, 'unmuteButtonByTeacher');
    };

    disableVideoLocalParticipant = async () => {
        this.state.room.localParticipant.videoTracks.forEach(publication => {
            publication.track.disable();
        });

        await this.setState({ isLocalParticipantVideoDisabled: true });

        console.log(this.state.isLocalParticipantVideoDisabled, 'disableVideoButton');
    };

    enableVideoLocalParticipant = async () => {
        this.state.room.localParticipant.videoTracks.forEach(publication => {
            publication.track.enable();
        });

        await this.setState({ isLocalParticipantVideoDisabled: false });

        console.log(this.state.isLocalParticipantVideoDisabled, 'enableVideoButton');
    };

    disableVideoLocalParticipantByTeacher = async () => {
        this.state.room.localParticipant.videoTracks.forEach(publication => {
            publication.track.disable();
        });

        await this.setState({ isLocalParticipantVideoDisabledByTeacher: true });

        console.log(this.state.isLocalParticipantVideoDisabledByTeacher, 'disableVideoButtonByTeacher');
    };

    enableVideoLocalParticipantByTeacher = async () => {
        this.state.room.localParticipant.videoTracks.forEach(publication => {
            publication.track.enable();
        });

        await this.setState({ isLocalParticipantVideoDisabledByTeacher: false });

        console.log(this.state.isLocalParticipantVideoDisabledByTeacher, 'enableVideoButtonByTeacher');
    };

    muteRemoteParticipant = async (participantSid) => {
        this.props.socket.send(JSON.stringify({
            command: 'muteRemoteParticipant',
            data: { participantSid: participantSid },
            val: {
                vCId: this.vCId,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));
        this.hideParticipantWindows(participantSid);
    };

    disableVideoRemoteParticipant = async (participantSid) => {
        this.props.socket.send(JSON.stringify({
            command: 'disableVideoRemoteParticipant',
            data: { participantSid },
            val: {
                vCId: this.vCId,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));
        this.hideParticipantWindows(participantSid);
    };

    makeParticipantActive = async (participantSid) => {
        this.props.socket.send(JSON.stringify({
            command: 'makeParticipantActive',
            data: { participantSid, userEmail: this.state.userEmail},
            val: {
                vCId: this.vCId,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));
        this.hideParticipantWindows(participantSid);
    };

    giveControlRemoteParticipant = async (participantEmail, participantSid) => {
        this.props.socket.send(JSON.stringify({
            command: 'giveControlRemoteParticipant',
            data: { participantEmail, participantSid },
            val: {
                vCId: this.vCId,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));

        this.hideParticipantWindows();
        this.updateViewStudentWithControle(participantSid);
    };

    openCloseLikes = async(participantSid, participantEmail, participantName) => {
        if (this.props.openCloseLikes) {
            this.props.closeLikes();
        } else {
            this.props.openLikes();
            this.props.participantEmail(participantEmail, participantName);
        }
    }

    giveGradeParticipant = async (participant, grade, participantSid) => {
        const participantName = participant.name;
        const participantEmail = participant.email;
        const participantDiv = document.getElementById(participantSid);
        const gradeDiv = participantDiv.getElementsByClassName('participant_grades')[0];
        const classRoomIdToStorage = this.vCId;
        const classRoomFromStorage = JSON.parse(localStorage.getItem('classRoom'));
        const storageGrades = [];


        if (classRoomFromStorage && classRoomFromStorage[0].classRoomId === classRoomIdToStorage) {
            const indexStudentStorage = classRoomFromStorage[1].grades.findIndex(item => item.email === participantEmail);
            if (indexStudentStorage !== -1) {
                classRoomFromStorage[1].grades[indexStudentStorage].grade = grade
                localStorage.setItem('classRoom', JSON.stringify(classRoomFromStorage))
            } else {
                classRoomFromStorage[1].grades.push({
                    email: participantEmail,
                    grade,
                    name: participantName,
                    participantSid
                })
                localStorage.setItem('classRoom', JSON.stringify(classRoomFromStorage))
            }
        } else {
            storageGrades.push(
                {classRoomId: classRoomIdToStorage},
                {grades: [{
                        email: participantEmail,
                        grade,
                        name: participantName,
                        participantSid
                    }]})
            localStorage.setItem('classRoom', JSON.stringify(storageGrades))
        }

        this.props.socket.send(JSON.stringify({
            command: 'giveGradeParticipant',
            data: {participantEmail, gradeParticipant: grade, name: participantName},
            val: {
                vCId: this.vCId,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));


        for (let i = 0; i < gradeDiv.children.length; i++) {
            if (classRoomFromStorage) {
                const indexParticipant = classRoomFromStorage[1].grades.findIndex(item => item.email === participantEmail);
                if((indexParticipant !== -1) && (i < classRoomFromStorage[1].grades[indexParticipant].grade)) {
                    gradeDiv.children[i].classList.remove('grade_not_delivered');
                    gradeDiv.children[i].classList.add('grade_delivered');
                } else {
                    gradeDiv.children[i].classList.remove('grade_delivered');
                    gradeDiv.children[i].classList.add('grade_not_delivered');
                }
            } else {
                const indexParticipant = storageGrades[1].grades.findIndex(item => item.email === participantEmail);
                if((indexParticipant !== -1) && (i < storageGrades[1].grades[indexParticipant].grade)) {
                    gradeDiv.children[i].classList.remove('grade_not_delivered');
                    gradeDiv.children[i].classList.add('grade_delivered');
                } else {
                    gradeDiv.children[i].classList.remove('grade_delivered');
                    gradeDiv.children[i].classList.add('grade_not_delivered');
                }
            }
        }

        this.hideParticipantWindows();

        await callApi('teacher/rateStudentVirtualLesson', 'post', {
            virtualLessonId: this.vCId,
            email: participantEmail,
            value: grade
        }).then(async res => {

            if (res && res.data.ok) {
                console.log(lang('Student rated successfully'));
            } else {
                console.log(res.data.message);
            }
        }).catch(() => {
            console.log(lang('Cannot rate student'));
        });
    }

    finishLesson = async () => {
        this.props.socket.send(JSON.stringify({
            command: 'finishLesson',
            val: {
                vCId: this.vCId,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));
    };

    openAnimationsRemoteParticipant = async (participantSid) => {
        const participantAnimationMenuData = Object.assign({}, this.state.room.participantsStickersData[participantSid]);
        participantAnimationMenuData.isOpen = !participantAnimationMenuData.isOpen;
        const participantDiv = document.getElementById(participantSid);
        const animationButton = participantDiv.getElementsByClassName('animation_button_disable')[0];

        this.hideParticipantWindows();

        this.setState(prevState => {
            const roomData = prevState.room;
            roomData.participantsStickersData[participantSid].isOpen = participantAnimationMenuData.isOpen;

            return { room: roomData };
        });

        if (animationButton) {
            animationButton.classList.toggle('animation_button');
            animationButton.classList.toggle('animation_button_disable');
        }

        console.log('openAnimationsRemoteParticipant', this.state.room);
    };

    openGradeParticipant = async (participantSid) => {
        const participantGradeMenuData = Object.assign({}, this.state.room.participantsGradesData[participantSid]);
        participantGradeMenuData.isOpen = !participantGradeMenuData.isOpen;
        const participantDiv = document.getElementById(participantSid);
        const gradeButton = participantDiv.getElementsByClassName('grade_button_disable')[0];

        this.hideParticipantWindows();

        this.setState(prevState => {
            const roomData = prevState.room;
            roomData.participantsGradesData[participantSid].isOpen = participantGradeMenuData.isOpen;

            return { room: roomData };
        });

        const gradeDiv = participantDiv.getElementsByClassName('participant_grades')[0];

        if (gradeButton) {
            gradeButton.classList.toggle('grade_button');
            gradeButton.classList.toggle('grade_button_disable');
        }

        if (!this.state.room.participantsGradesData[participantSid].isOpen) {
            gradeDiv.style.visibility = 'hidden';
        } else {
            gradeDiv.style.visibility = 'visible';
        }
    }

    hideParticipantWindows = () => {
        const roomData = this.state.room;

        for (const participantSid in roomData.participantsStickersData) {
            roomData.participantsStickersData[participantSid].isOpen = false;
            const participantDiv = document.getElementById(participantSid);
            const animationButton = participantDiv.getElementsByClassName('animation_button_disable')[0];

            if (animationButton) {
                animationButton.classList.toggle('animation_button');
                animationButton.classList.toggle('animation_button_disable');
            }
        }

        for (const participantSid in roomData.participantsGradesData) {
            roomData.participantsGradesData[participantSid].isOpen = false;
            const participantDiv = document.getElementById(participantSid);
            const gradeDiv = participantDiv.getElementsByClassName('participant_grades')[0];
            gradeDiv.style.visibility = 'hidden';
            const gradeButton = participantDiv.getElementsByClassName('grade_button_disable')[0];

            if (gradeButton) {
                gradeButton.classList.toggle('grade_button');
                gradeButton.classList.toggle('grade_button_disable');
            }
        }

        this.setState({ room: roomData });
    }

    disconnected = (room)=>{
        //Отвязать локальные медиа элементы(камера, микрофон и т.д.)
        room.localParticipant.tracks.forEach(publication => {
            publication.track.stop();
            const attachedElements = publication.track.detach();
            attachedElements.forEach(element => element.remove());
        });

        //Отвязать удаленные медиа элементы
        room.participants.forEach(participant => {
            const pDiv = document.getElementById(participant.sid);
            if (pDiv) {
                pDiv.remove();
            }
        });
    };

    participantConnected = (room, participant)=> {
        const participantIdentity = JSON.parse(participant.identity);
        participantIdentity.isLocal = false;
        participantIdentity.tracks = participant.tracks;
        participantIdentity.participant = participant;
        participantIdentity.sid = participant.sid;
        try {
            let audioConnect;
            if (participantIdentity.type === 'teacher') {
                this.teacherMediaContainer.push(participantIdentity);
                audioConnect = this.state.audio.teacherConnect;            
            } else if (participantIdentity.type === 'student') {
                this.studentMediaContainer.push(participantIdentity);
                audioConnect = this.state.audio.studentConnect;
            }
            if (audioConnect) {
                audioConnect.play();
                audioConnect.volume = global.soundsVolume;
            }
        } catch(e) {
            console.log(e);
        }
        this.renderVideo();
        
        this.setState({ room: room });
    };


    participantDisconnected = (room, participant) => {
        console.log(participant, 'Disconnected participant');
        // if (room.participantsStickersData && room.participantsStickersData[participant.sid]) {
        if (this.state.userType === 'teacher') {
            delete room.participantsStickersData[participant.sid];
            delete room.participantsGradesData[participant.sid];
        }
        const participantIdentity = JSON.parse(participant.identity);
        let mediaInfoArray;
        if (participantIdentity.type === 'teacher') {
           mediaInfoArray = this.teacherMediaContainer;
        } else if (participantIdentity.type === 'student') {            
            mediaInfoArray = this.studentMediaContainer;            
        }
        
        mediaInfoArray = mediaInfoArray.filter(item => item.sid !=participant.sid);
        if (participantIdentity.type === 'teacher') {
            this.teacherMediaContainer = mediaInfoArray;
         } else if (participantIdentity.type === 'student') {            
            this.studentMediaContainer = mediaInfoArray;            
         }
        this.renderVideo();
        this.setState({ room: room });
    };

    dominantSpeakerChanged = (room, participant) => {
        console.log('dominantSpeakerChanged');
        console.log('The new dominant speaker in the Room is:', participant);
        const newDominantSpeakerSid = (participant && participant.sid) || null;
        const currentDominantSpeakerSid = this.state.currentDominantSpeakerSid;
        
        if (currentDominantSpeakerSid) {
            const currentDominantSpeakerElem = document.getElementById(currentDominantSpeakerSid);
            if (currentDominantSpeakerElem) {
                currentDominantSpeakerElem.classList.remove('dominantSpeaker');   
            }
        }
        if (newDominantSpeakerSid) {
            const newDominantSpeakerElem = document.getElementById(newDominantSpeakerSid);
            if (newDominantSpeakerElem) {
                newDominantSpeakerElem.classList.add('dominantSpeaker');
            }
        }
        this.setState({
            currentDominantSpeakerSid: newDominantSpeakerSid, 
            room: room
        });
    }

    render() {
        const { navCollapsed, drawerType, width } = this.props;
        const drawerStyle = comparingValuesInSymbol(drawerType, FIXED_DRAWER) ? 'd-xl-flex' : comparingValuesInSymbol(drawerType, COLLAPSED_DRAWER) ? '' : 'd-flex';
        let type = 'permanent';

        return (
            <div className={`app-sidebar d-none ${drawerStyle}`}>
                <Drawer
                    className="app-sidebar-content"
                    variant={type}
                    open={type.includes('temporary') ? navCollapsed : true}
                    onClose={this.onToggleCollapsedNav}
                    classes={{
                        paper: 'side-nav'
                    }}>
                    {this.renderDivisesList()}
                  
                </Drawer>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        toggleCollapsedNav,
        updateWindowWidth,
        setLessonIdent,
        openLikes,
        closeLikes,
        participantEmail,
    },
    dispatch
);

const mapStateToProps = ({ settings, likes }) => ({
    navCollapsed: settings.navCollapsed,
    width: settings.width,
    drawerType: settings.drawerType,
    navigationStyle: settings.navigationStyle,
    socket: settings.socket,
    openCloseLikes: likes.openCloseLikes,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNav));
