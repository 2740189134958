const HOME_PAGE = 'homePage';
const HOME_WORK_PAGE = 'homeWorkPage';
const HOME_DICTIONARY = 'homeDictionary';
const SKILLS_LEVELS_CLASS = 'skillsLevelsClass';
const SKILLS_LEVELS_LESSON = 'skillsLevelsLesson';


export function Declination(number, page) {
    const cases = [2, 0, 1, 1, 1, 2];
    if ((page === HOME_PAGE) || (page === SKILLS_LEVELS_CLASS)) {
        const txt = ['занятие', 'занятия', 'занятий'];
        return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    } else if (page === HOME_WORK_PAGE) {
        const txt = ['домашнюю работу', 'домашних работы', 'домашних робот'];
        return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    } else if (page === HOME_DICTIONARY) {
        const txt = [' слово', ' слова', ' слов'];
        return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    } else if (page === SKILLS_LEVELS_LESSON) {
        const txt = ['урок', 'укрока', 'уроков'];
        return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }
}
