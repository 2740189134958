import React from 'react'
import { DropTarget } from 'react-dnd';

export const Dustbin = ({
    isHomework,
    isHomeworkFinish,
    dustbinName,
    isOver,
    canDrop,
    connectDropTarget,
    lastDroppedItem,
    customWidth,
}) => {
    const isActive = isOver && canDrop;
    let backgroundColor = '#fff';
    let border = '1px dashed #000';
    let color = '#000';
    let pointerEvents = '';

    if (isActive) {
        backgroundColor = '#92ddf6';
    } else if (canDrop) {
        backgroundColor = '#d3ecf5';
    }

    if (lastDroppedItem && lastDroppedItem.name !== dustbinName) {
        border = '1px solid #f00';
    } else if (lastDroppedItem && lastDroppedItem.name === dustbinName) {
        backgroundColor = '#00b3ff';
        border = '1px solid #00b3ff';
        color = '#fff';
        pointerEvents = !isHomework ? 'none': '';
    }

    if (isHomework && !isHomeworkFinish) {
        border = '1px dashed #4db2fa';
        backgroundColor =  '#fff';
        color = '#000';
    }

    return connectDropTarget(
        <span style={{ backgroundColor, border, color, pointerEvents }}
            className={'Dad1Dustbin' + (customWidth ? ' Dad1DustbinCustomWidth': '')}
        >
            {lastDroppedItem ? (
                <span>{lastDroppedItem.name}</span>
            ) : (<span>&ensp;</span>)}
        </span>,
    )
}
export default DropTarget(
    (props) => props.accepts,
    {
        drop(props, monitor) {
            props.onDrop(monitor.getItem())
        },
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
)(Dustbin);
