import React, {useEffect, useState} from "react";

import Switch from '@material-ui/core/Switch/Switch';
import Button from '@material-ui/core/Button/Button';
import Input from '@material-ui/core/Input';
import P5Wrapper from 'react-p5-wrapper';
import Radio from "@material-ui/core/Radio/Radio";

import IconWhiteboard from '../../../assets/images/icons/whiteboard.svg';
import l from '../main/LessonEditor.module.scss';
import t from '../../admin/Teacher/Teacher.module.scss';
import callApi from '../../../utils/call-api';
import config from '../../../config';

const IMG = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [src, setSrc] = useState(el.src);
    // const [whiteboard, setWhiteboard] = useState(el.whiteboard);
    const [heightPriority, setHeightPriority] = useState(el.heightPriority);
    const [widthPriority, setWidthPriority] = useState(el.widthPriority);
    const [heightInVh, setHeightInVh] = useState(el.heightInVh);
    const [widthInPercent, setWidthInPercent] = useState(el.widthInPercent);
    const [isDrawing, setIsDrawing] = useState(el.isDrawing);
    const [selectedFile, setSelectedFile] = useState("");
    
    // const [isHomework]  = useState(options.isHomework);
    const [isPresentation]  = useState(options.isPresentation);

    useEffect(() => {
        options.changeElement({src, heightPriority, widthPriority, heightInVh, widthInPercent, isDrawing}, i, activeTabIndex);
    }, [src, heightPriority, widthPriority, heightInVh, widthInPercent, isDrawing]);
    
    return (
        <div key={`tab_${activeTabIndex}_item_${i}`}>
            {!isPresentation && 
            <>
                Height in VH<Radio name={`img${activeTabIndex}${i}`}
                    checked={!!heightPriority}
                    onChange={() => {
                        setHeightPriority(!heightPriority);
                        setWidthPriority(!widthPriority);
                    }}
                />
                <Input type="number"
                    value={heightInVh}
                    onChange={e => {
                        if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                            setHeightInVh(e.target.value);
                        }
                    }}
                />
                Width in %<Radio name={`img${activeTabIndex}${i}`}
                    checked={!!widthPriority}
                    onChange={() => {
                        setHeightPriority(!heightPriority);
                        setWidthPriority(!widthPriority);
                    }}
                />
                <Input type="number"
                    value={widthInPercent}
                    onChange={e => {                   
                        if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                            setWidthInPercent(e.target.value);
                        }
                    }}
                />
            </>
            }
            {!isPresentation && <>
                <Switch
                    onChange={() => {
                        setIsDrawing(!isDrawing);
                    }}
                    checked={isDrawing}
                /> Можно рисовать</>}
            {!isPresentation && !src &&
                <div>
                    <div className={l.WIT_button}>
                        <Input type="file" name="file"
                               onChange={e => setSelectedFile( e.target.files[0]) }
                        />
                    </div>
                    <div className={l.WIT_button}>
                        <Button onClick={() => {
                            
                            const formData = new FormData();

                            formData.append('file', selectedFile);
                            formData.append('name', 'test');
                            callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                if (res && res.data && res.data.length && res.data.fileName) {
                                    setSrc(res.data.fileName.substr(res.data.fileName.indexOf("imgs")));
                                }
                            }).catch(err => {
                                console.log(err)
                            });
                        }}>Upload</Button>
                    </div>
                </div>}
            {src &&
                <div className="canvasImgContainer"
                     style={heightPriority ? { } : { width: widthInPercent + '%' }}
                     ref={el.canvasImgContainerRef}
                >
                    {isPresentation && isDrawing &&
                        <P5Wrapper sketch={el.whiteboard} />
                    }
                    <img src={src}
                         style={heightPriority ? { height: heightInVh + 'vh' } : { width: '100%' }}
                    />
                    {isPresentation && isDrawing &&
                        <div className="canvasImgContainerDrawIcon"
                             onClick={() => options.dialogSetImgWhiteboard(!options.ReactshowImgWhiteboard)}
                        >
                            <img className={t.right_panel_img} src={IconWhiteboard} alt="whiteboard"/>
                        </div>
                    }
                </div>
            }
            <br />
        </div>
    );
};

export default IMG;