
import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';

const renderInputText =(i, value, handleChange, activeTabIndex, itemIndex, placeholder = '', multiline = false, label = 'text') =>{
    return (
        <div className="col-12 dad2_render">
            <TextField
                placeholder={placeholder}
                InputLabelProps={{ shrink: true }}
                required
                name="text"
                value={value}
                onChange={event => handleChange(event, i, activeTabIndex, itemIndex)}
                label={label}
                fullWidth
                multiline={multiline}
                margin="normal"
                className="mt-0 mb-4"
            />
        </div>
    );
};

export default renderInputText;