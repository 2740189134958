import React, {useState, useRef, useEffect} from 'react';
import './chat.scss';
import { Scrollbars } from 'react-custom-scrollbars';
// import ArrowImg from '../../assets/images/arrow_123.png';

import newChatMessageSound from '../../../src/assets/sounds/new_message.mp3';
import { useSelector } from "react-redux";

const newChatMessageAudio = new Audio();
newChatMessageAudio.src = newChatMessageSound;

const Chat = ({active, socket, userInfo, virtualClassId, socketMessage, mouseMoveStatusFunc}) => {
    const [previousMessage, setPreviousMessage] = useState('');
    // const [activeGroupMenu, setActiveGroupMenu] = useState(false);
    const [activeStudent, setActiveStudent] = useState('Group');
    // const [activeMessage, setActiveMessage] = useState([]);
    // const [Student, setStudent] = useState([]);
    const [activeCompanionMenu, setActiveCompanionMenu] = useState(false);
    const [activeStudentPanel, setActiveStudentPanel] = useState(true);
    const [activeInfoMenu, setActiveInfoMenu] = useState(false);
    // const [inputActive, setInputActive] = useState('');
    const [searchList, setSearchList] = useState([]);
    const [topPxChat, setTopPxChat] = useState(-630);
    const [leftPxChatPanel, setLeftPxChatPanel] = useState(-78);
    // const [leftChatPanelWidth, setLeftChatPanelWidth] = useState(70);
    const [displayChat, setDisplayChat] = useState('none');
    const [transitionMs] = useState('200ms');
    const windowInnerWidth = useSelector(state => state.settings.width);
    const [listMessage, setListMessage] = useState([
        {
            id: 'Group',
            firstName: 'Group',
            lastName: 'Chat',
            color: `#eb34fd`,
            students: [],
        },
    ])

    const myName = {
        firstName: 'Антон',
        lastName: 'Власов',
        id: 2
    }

    const dateNow = new Date();
    const arrMessage = listMessage;
    const textarea = document.getElementById('chat_textarea');
    const scrollbar = useRef(null);

    // const listStudent = [
    //     {
    //         id: 5,
    //         firstName: 'Вася',
    //         lastName: 'Антонов',
    //         color: `#1f1fff`,
    //     },
    // ]

    function recordMessage() {
        // setActiveMessage(listMessage.map(item => {
        //     return item.messages
        // }));
    }

    if ((previousMessage !== JSON.stringify(socketMessage)) && (socketMessage.command === 'sendClassChatMessage')) {
        const indexGroup = listMessage[0].students.findIndex(item => item.id === socketMessage.data.userName);
        if (indexGroup !== -1) {
            arrMessage[0].students[indexGroup].messages.push(
                {time: new Date(), text: socketMessage.data.text, read: 'no'}
            );
        } else {
            const userName = socketMessage.data.userName.split(' ');
            arrMessage[0].students.push(
                {
                    id: socketMessage.data.from,
                    firstName: userName[0],
                    lastName: userName[1],
                    color: `#1f1fff`,
                    messages: [
                        {
                            time: new Date(),
                            text: socketMessage.data.text,
                            read: 'no'
                        },
                    ]
                },
            );
        }
        setPreviousMessage(JSON.stringify(socketMessage));
        newChatMessageAudio.play(); newChatMessageAudio.volume = global.soundsVolume;
    }


    function sendMessage() {
        const index = listMessage.findIndex(item => item.id === activeStudent);
        // const indexGroup = listMessage[0].students.findIndex(item => item.id === myName.id)
        const textarea = document.getElementById('chat_textarea');
        if (textarea.value) {
            if (activeStudent || (activeStudent === 0)) {
                if (activeStudent === 'Group') {
                    socket.send(JSON.stringify({
                        command: 'sendClassChatMessage',

                        data: {
                            messageType: 'group',//Насколько я знаю это параметр использоваться не будет, по крайней мере пока что, уточни у Миши
                            time: dateNow,//время будет учтено на сервере
                            read: 'no',//разве такой параметр стоит пробрасывать через сокет, уточни у Миши
                            userName: userInfo.userInfo.name,

                            //Требуемые параметры
                            type: 'text',//message type(text, image)
                            text: textarea.value,//текст сообщения(тут совпало)
                            // additionalData: 'type string' //в случае, если type -> image

                            //Параметры, которые могут присутствовать
                            from: userInfo.username,//from и userName полагаю у тебя одно и тоже (userInfo.username - Это почта, так как имена могут совпадать)
                        },
                        val: {
                            vCId: virtualClassId,
                            userEmail: userInfo.userInfo.email,
                            userType: userInfo.userInfo.type,
                        }
                    }));
                } else {

                    /*                       socket.send(JSON.stringify({
                                               command: 'sendClassChatMessage',
                                               data: {
                                                   messageType: 'private',
                                                   from: userInfo.username,
                                                   to: activeStudent,
                                                   time: dateNow,
                                                   text: textarea.value,
                                                   read: 'no',
                                                   userName: userInfo.userInfo.name,
                                               },
                                               val: {
                                                   userEmail: userInfo.userInfo.email,
                                                   userType: userInfo.userInfo.type,
                                               }
                                           }));
                   */

                    arrMessage[index].messages.push(
                        {
                            time: dateNow,
                            firstName: myName.firstName,
                            lastName: myName.lastName,
                            text: textarea.value,
                            read: 'no'
                        }
                    )
                }

            }
            recordMessage();
            textarea.value = '';
            setTimeout(scrollToBottom, 800);
        }
    }


    function onKeyPressDown(event) {
        const index = listMessage.findIndex(item => item.id === activeStudent);
        if (event.key === 'Enter') {
            if (activeStudent || (activeStudent === 0)) {
                if (activeStudent === 'Group') {
                    socket.send(JSON.stringify({
                        command: 'sendClassChatMessage',

                        data: {
                            messageType: 'group',//Насколько я знаю это параметр использоваться не будет, по крайней мере пока что, уточни у Миши
                            time: dateNow,//время будет учтено на сервере
                            read: 'no',//разве такой параметр стоит пробрасывать через сокет, уточни у Миши
                            userName: userInfo.userInfo.name,

                            //Требуемые параметры
                            type: 'text',//message type(text, image)
                            text: textarea.value,//текст сообщения(тут совпало)
                            // additionalData: 'type string' //в случае, если type -> image

                            //Параметры, которые могут присутствовать
                            from: userInfo.username,//from и userName полагаю у тебя одно и тоже (userInfo.username - Это почта, так как имена могут совпадать)
                        },
                        val: {
                            vCId: virtualClassId,
                            userEmail: userInfo.userInfo.email,
                            userType: userInfo.userInfo.type,
                        }
                    }));
                } else {
                    arrMessage[index].messages.push(
                        {time: dateNow, firstName: myName.firstName, lastName: myName.lastName, text: textarea.value}
                    )
                }
            }
            recordMessage()
        }
    }

    function onKeyPressUp(event) {
        if (event.key === 'Enter') {
            textarea.value = '';
            scrollToBottom()
        }
    }

    const opt = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }


    function messageShow() {
        const now = dateNow.toLocaleString('en', opt);
        const arrayMessageSort = [];
        const arrayMessageSortGroup = [];
        const arrDate = [];
        const arrDateGroup = [];
        const index = listMessage.findIndex(item => item.id === activeStudent);
        if (activeStudent === 'Group') {
            listMessage[index].students.map((item) => {
                item.messages.forEach(element => arrayMessageSortGroup.push({
                    firstName: item.firstName,
                    lastName: item.lastName,
                    data: element.time,
                    message: element.text,
                    id: item.id
                }))
            })
        } else if (activeStudent || (activeStudent === 0)) {
            listMessage[index].messages.forEach(element => arrayMessageSort.push({
                firstName: element.firstName,
                lastName: element.lastName,
                data: element.time,
                message: element.text
            }))
        }

        arrayMessageSort.sort(function (a, b) {
            const dateA = new Date(a.data);
            const dateB = new Date(b.data);
            return dateA - dateB
        })

        arrayMessageSortGroup.sort(function (a, b) {
            const dateA = new Date(a.data);
            const dateB = new Date(b.data);
            return dateA - dateB
        })

        arrayMessageSort.forEach(item => {
            const year = item.data;
            const date = year.toLocaleString('en', opt)
            if (arrDate.length === 0) {
                arrDate.push({
                    date: date,
                    id: arrDate.length,
                    message: [{
                        firstName: item.firstName,
                        lastName: item.lastName,
                        time: year,
                        message: item.message
                    }]
                })
            } else {
                if (arrDate[arrDate.length - 1].date === date) {
                    arrDate[arrDate.length - 1].message.push({
                        firstName: item.firstName,
                        lastName: item.lastName,
                        time: year,
                        message: item.message
                    })
                } else {
                    arrDate.push({
                            date: date,
                            id: arrDate.length,
                            message: [{
                                firstName: item.firstName,
                                lastName: item.lastName,
                                time: year,
                                message: item.message
                            }]
                        }
                    )
                }
            }
        })

        arrayMessageSortGroup.forEach(item => {
            const year = item.data;
            const date = year.toLocaleString('en', opt)
            if (arrDateGroup.length === 0) {
                arrDateGroup.push({
                    date: date,
                    id: arrDateGroup.length,
                    message: [{
                        firstName: item.firstName,
                        lastName: item.lastName,
                        time: year,
                        message: item.message,
                        id: item.id
                    }]
                })
            } else {
                if (arrDateGroup[arrDateGroup.length - 1].date === date) {
                    arrDateGroup[arrDateGroup.length - 1].message.push({
                        firstName: item.firstName,
                        lastName: item.lastName,
                        time: year,
                        message: item.message,
                        id: item.id
                    })
                } else {
                    arrDateGroup.push({
                            date: date,
                            id: arrDate.length,
                            message: [{
                                firstName: item.firstName,
                                lastName: item.lastName,
                                time: year,
                                message: item.message,
                                id: item.id
                            }]
                        }
                    )
                }
            }
        })


        if (activeStudent === 'Group') {
            setTimeout(scrollToBottom, 300);
            return (
                arrDateGroup.map(item => {
                    return (
                        <div className='message_container'>

                            <div key={item.id} className='message_date'>
                                <div className='date_line'></div>
                                <div className='date'>
                                    {(now === item.date) ? "Сегодня" : item.date}
                                </div>
                                <div className='date_line right'></div>
                            </div>
                            {item.message.map((item2) => {
                                if ((userInfo.username === item2.id) && (item2.message)) {
                                    return (
                                        <div className='message_student my'>
                                            <div className='message_avatar'>
                                                {/*{item2.firstName.substring(0, 1) + ' ' + item2.lastName.substring(0, 1)}*/}
                                            </div>
                                            <div key={item2.date} className='message my'>
                                                <div className='message_student_name my'>
                                                    {/* {item2.firstName + ' ' + item2.lastName.substring(0, 1) + '.'}*/}
                                                    {item2.firstName}
                                                </div>
                                                <div className='message_student_text my'>
                                                    {item2.message}
                                                </div>
                                            </div>
                                        </div>
                                    )

                                } else {
                                    return (
                                        <div key={item2.date} className='message_student'>
                                            <div className='message_avatar'>
                                                {/*img*/}
                                            </div>
                                            <div className='message'>
                                                <div className='message_student_name'>
                                                    {/*  {item2.firstName + ' ' + item2.lastName.substring(0, 1) + '.'}*/}
                                                    {item2.firstName}
                                                </div>
                                                <div className='message_student_text'>{item2.message}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    )
                })
            )
        } else {
            return (
                arrDate.map(item => {
                    return (
                        <div className='message_container'>
                            <div key={item.id} className='message_date'>
                                <div className='date_line'></div>
                                <div className='date'>
                                    {(now === item.date) ? "Сегодня" : item.date}
                                </div>
                                <div className='date_line right'></div>
                            </div>
                            {item.message.map((item2) => {
                                if ((item2.firstName === myName.firstName) && (item2.message)) {
                                    if (item2.lastName === myName.lastName) {
                                        return (
                                            <div className='message_student my'>
                                                <div className='message_avatar'>
                                                    {item2.firstName.substring(0, 1) + ' ' + item2.lastName.substring(0, 1)}
                                                </div>
                                                <div key={item2.date} className='message my'>
                                                    <div className='message_student_name my'>
                                                        {item2.firstName + ' ' + item2.lastName.substring(0, 1) + '.'}
                                                    </div>
                                                    <div className='message_student_text my'>
                                                        {item2.message}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                } else {
                                    return (
                                        <div key={item2.date} className='message_student'>
                                            <div className='message_avatar'>
                                                {item2.firstName.substring(0, 1) + ' ' + item2.lastName.substring(0, 1)}
                                            </div>
                                            <div className='message'>
                                                <div className='message_student_name'>
                                                    {item2.firstName + ' ' + item2.lastName.substring(0, 1) + '.'}
                                                </div>
                                                <div className='message_student_text'>{item2.message}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    )
                })
            )
        }
    }

    // const studentColor = () => {
    //     const random = Math.round(Math.random() * 100);
    //     if (random <= 15) {
    //         return (`#1f1fff`)
    //     } else if ((random > 15) && (random <= 30)) {
    //         return (`#008000`)
    //     } else if ((random > 30) && (random <= 45)) {
    //         return (`#363636`)
    //     } else if ((random > 45) && (random <= 60)) {
    //         return (`#ff1515`)
    //     } else if ((random > 60) && (random <= 75)) {
    //         return (`#e0e003`)
    //     } else if (random > 75) {
    //         return (`#ffa500`)
    //     }
    // }

    // const studentFirstName = () => {
    //     const random = Math.round(Math.random() * 100);
    //     if (random <= 15) {
    //         return ('Данил')
    //     } else if ((random > 15) && (random <= 30)) {
    //         return ('Евгений')
    //     } else if ((random > 30) && (random <= 45)) {
    //         return ('Роман')
    //     } else if ((random > 45) && (random <= 60)) {
    //         return ('Михаил')
    //     } else if ((random > 60) && (random <= 75)) {
    //         return ('Костя')
    //     } else if (random > 75) {
    //         return ('Валентин')
    //     }
    // }

    // const studentLastName = () => {
    //     const random = Math.round(Math.random() * 100);
    //     if (random <= 15) {
    //         return ('Иванов')
    //     } else if ((random > 15) && (random <= 30)) {
    //         return ('Смирнов')
    //     } else if ((random > 30) && (random <= 45)) {
    //         return ('Кузнецов')
    //     } else if ((random > 45) && (random <= 60)) {
    //         return ('Попов')
    //     } else if ((random > 60) && (random <= 75)) {
    //         return ('Соколов')
    //     } else if (random > 75) {
    //         return ('Зайцев')
    //     }
    // }

    function recordStudent() {
        setStudent(listMessage.map(item => {
            return item.id
        }))
    }

    function activePanel() {
        if (activeStudentPanel === false) {
            setActiveStudentPanel(true)
        } else {
            setActiveStudentPanel(false)
        }
        setActiveInfoMenu(false)
    }

    function companionMenu() {
        if (activeCompanionMenu === false) {
            setActiveCompanionMenu(true)
        } else {
            setActiveCompanionMenu(false)
        }
    }

    function activeCompanion() {
        const index = listMessage.findIndex(item => item.id === activeStudent);
        if (activeStudent || (activeStudent === 0)) {
            return (
                <React.Fragment>
                    <div className='active_avatar' style={{
                        // backgroundColor: listMessage[index].color
                    }}>
                        {/*{listMessage[index].firstName.substring(0, 1)}{listMessage[index].lastName.substring(0, 1)}*/}
                    </div>
                    <div className='active_companion'>
                        {listMessage[index].firstName + ' ' + listMessage[index].lastName}
                    </div>
                   {/* <div className='active_button button_companion' onClick={companionMenu}>
                        <div style={{margin: '-10px 0 0'}}>...</div>
                    </div>*/}
                </React.Fragment>
            )
        }
    }

    function infoMenu(id) {
        if (activeInfoMenu || (activeInfoMenu === 0)) {
            setActiveInfoMenu(false)
        } else {
            setActiveInfoMenu(id)
        }
    }

    function deleteStudent(id) {
        const index = listMessage.findIndex(item => item.id === id);
        if (index === -1) {
            const index = listMessage.findIndex(item => item.id === activeStudent);
            listMessage.splice(index, 1)
            setActiveStudent(false)
            setActiveCompanionMenu(false)
        } else {
            listMessage.splice(index, 1)
            setActiveStudent(false)
        }
        setActiveInfoMenu(false)
    }


    function numberMessage(id) {
        const arrGroupMassive = listMessage[0].students;
        const index = listMessage.findIndex(item => item.id === id);
        let arr = 0;
        let arrGroup = 0;
        if (id === 'Group') {
            if (activeStudent !== 'Group') {
                listMessage[index].students.map(item => {
                    if (userInfo.username === item.id) {
                    } else {
                        item.messages.map((item2) => {
                            if (item2.read === 'no') {
                                arrGroup += 1
                            }
                        })
                    }
                })
            } else {
                arrGroupMassive.map(item => {
                    item.messages.map(item2 => {
                        if (item2.read === 'no') {
                            item2.read = 'yes'
                        }
                    })
                })
            }
        } else {
            listMessage[index].messages.map(item => {
                if (myName.firstName === item.firstName) {
                    if (myName.lastName === item.lastName) {
                    }
                } else {
                    if (item.read === 'no') {
                        arr += 1
                    }
                }
            })
        }
        if (id === 'Group') {
            return arrGroup
        } else {
            return arr
        }
    }

    function reading(id) {
        const index = listMessage.findIndex(item => item.id === id);
        const arrGroup = listMessage[0].students;
        if (id === 'Group') {
            arrGroup.map(item => {
                if (userInfo.username === item.id) {
                } else {
                    item.messages.map((item2) => {
                        if (item2.read === 'no') {
                            item2.read = 'yes'
                        }
                    })
                }
            })
        } else {
            arrMessage[index].messages.map((item) => {
                item.read = 'yes'
            })
        }
    }

    const studentList =
        listMessage.map(item => {
            if (item.id === 'Group') {
                return (
                    <div key={item.id} className='info_container'>
                        <div
                            onClick={() => {
                                setActiveStudent(item.id);
                                setActiveGroupMenu(false);
                                reading(item.id);
                            }}
                            className={(activeStudent === item.id) ? 'student_avatar on' : 'student_avatar'}
                            style={{backgroundColor: item.color}}
                        >
                            <div style={{width: '30px', height: '70px', position: 'absolute', left: '0'}}>
                                <div
                                    className={numberMessage(item.id) ? 'not_read' : null}>{numberMessage(item.id)}</div>
                            </div>
                            {item.firstName.substring(0, 1)}{item.lastName.substring(0, 1)}
                        </div>
                        <div className='info_student'>
                            <div className='info_name'>
                                {item.firstName + ' ' + item.lastName}
                            </div>
                            {/*<div className='active_button info_button' onClick={() => infoMenu(item.id)}>...</div>
                            <div
                                className={(activeInfoMenu === item.id) ? 'chat_menu active_info_menu on' : "active_info_menu"}>
                                <div className='companion_menu_line'>Pin</div>
                                <div className='companion_menu_line'>Mark as read</div>
                                <div className='companion_menu_line unread'>Mark as unread</div>
                                <div className='companion_menu_line'>User card</div>
                                <div className='companion_menu_line'>Share</div>
                                <div className='companion_menu_line' onClick={() => deleteStudent(item.id)}>Delete</div>
                            </div>*/}
                        </div>
                    </div>
                )
            } else {
                return (
                    <div key={item.id} className='info_container'>
                        <div
                            onClick={() => {
                                setActiveStudent(item.id);
                                setActiveGroupMenu(false);
                                reading(item.id);
                            }}
                            className={(activeStudent === item.id) ? 'student_avatar on' : 'student_avatar'}
                            style={{backgroundColor: item.color}}
                        >
                            <div style={{width: '30px', height: '70px', position: 'absolute', left: '0'}}>
                                <div className={numberMessage(item.id) ? 'not_read' : null}>
                                    {numberMessage(item.id)}
                                </div>
                            </div>
                            {item.firstName.substring(0, 1)}{item.lastName.substring(0, 1)}
                        </div>
                        <div className='info_student'>
                            <div className='info_name'>
                                {item.firstName + ' ' + item.lastName.substring(0, 1) + '.'}
                            </div>
                            {/*<div className='active_button info_button' onClick={() => infoMenu(item.id)}>...</div>
                            <div className={(activeInfoMenu === item.id) ? 'chat_menu active_info_menu on' : "active_info_menu"}>
                                <div className='companion_menu_line'>Pin</div>
                                <div className='companion_menu_line'>Mark as read</div>
                                <div className='companion_menu_line unread'>Mark as unread</div>
                                <div className='companion_menu_line'>User card</div>
                                <div className='companion_menu_line'>Share</div>
                                <div className='companion_menu_line' onClick={() => deleteStudent(item.id)}>Delete</div>
                            </div>*/}
                        </div>
                    </div>
                )
            }
        })

    const scrollToBottom = () => {
        if (!scrollbar || !scrollbar.current) {
            return;
        }
        scrollbar.current.scrollToBottom();
    }

    // function searchStudent(value) {
    //     const filterStudentFirstName = listStudent.filter(item => {
    //         return (
    //             item.firstName.toLowerCase().includes(value.toLowerCase())
    //         )
    //     });
    //     const filterStudentLastName = listStudent.filter(item => {
    //         return (
    //             item.lastName.toLowerCase().includes(value.toLowerCase())
    //         )
    //     });
    //     if (value.length >= 2) {
    //         if (filterStudentLastName.length >= 1) {
    //             setSearchList(filterStudentLastName)
    //         } else {
    //             setSearchList(filterStudentFirstName)
    //         }
    //     }
    // }

    // const showSearchStudent = searchList.map(item => {
    //     return (
    //         <div key={item.id} className='search_list' onClick={() => {
    //             addStudent(item.id, item.firstName, item.lastName, item.color)
    //             setInputActive('')
    //         }}>
    //             {item.firstName + ' ' + item.lastName}
    //         </div>
    //     )
    // })

    function addStudent(id, firstName, lastName, color) {
        const arr = listMessage;
        const StudentId = listMessage.map(item => {
            return (
                item.id
            )
        });
        const findId = StudentId.includes(id);
        if (findId === false) {
            arr.push(
                {
                    id: id,
                    firstName: firstName,
                    lastName: lastName,
                    color: color,
                    messages: []
                })
        }
        recordStudent()
    }

    useEffect(() => {
        if (active) {
            setDisplayChat('flex');
            if (topPxChat < 0) {
                setTimeout(setTopPxChat, 50, 60);
            } else if (topPxChat > 15 && topPxChat <= 60) {
                setTimeout(setTopPxChat, 350, 15);
            }
        } else {
            if (topPxChat === 15) {
                setTimeout(setTopPxChat, 50, 60);
            } else {
                setTimeout(setTopPxChat, 350, -650);
            }
            setTimeout(setDisplayChat, 550, 'none');
        }
    }, [topPxChat, active])

    useEffect(() => {
        if (activeStudentPanel) {
            if (windowInnerWidth <= 1200) {
                if (leftPxChatPanel === -283 || leftPxChatPanel === -233) {
                    setTimeout(setLeftPxChatPanel, 50, -260);
                    setTimeout(setLeftPxChatPanel, 350, -78);
                    setTimeout(setLeftChatPanelWidth, 350, 70);
                }
            } else {
                if (leftPxChatPanel === -283 || leftPxChatPanel === -233) {
                    setTimeout(setLeftPxChatPanel, 50, -310);
                    setTimeout(setLeftPxChatPanel, 350, -78);
                    setTimeout(setLeftChatPanelWidth, 350, 70);
                }
            }
        } else {
            if (windowInnerWidth <= 1200) {
                if (leftPxChatPanel === -78) {
                    setTimeout(setLeftPxChatPanel, 50, -260);
                    setTimeout(setLeftChatPanelWidth, 50, 225);
                    setTimeout(setLeftPxChatPanel, 250, -233);
                }
            } else {
                if (leftPxChatPanel === -78 || leftPxChatPanel === -233) {
                    setTimeout(setLeftPxChatPanel, 50, -310);
                    setTimeout(setLeftChatPanelWidth, 50, 275);
                    setTimeout(setLeftPxChatPanel, 250, -283);
                }
            }
        }
    }, [leftPxChatPanel, activeStudentPanel, windowInnerWidth])

    return (
        <div className='chat_container_main'
             style={{display: displayChat}}
            onMouseDown={() => {
                mouseMoveStatusFunc(true)
            }}
             onMouseEnter={() => {
                 mouseMoveStatusFunc(false)
             }}
             onClick={() => {
                 mouseMoveStatusFunc(false)
             }}
        >
            <div className="chat_container" style={{top: topPxChat, transition: transitionMs}}>

                {/*<div*/}
                {/*    className='chat_students_container'*/}
                {/*    style={{left: leftPxChatPanel, width: leftChatPanelWidth, transition: transitionMs}}*/}
                {/*>*/}
                {/*    <div className='chat_students'>*/}
                {/*        <div className='student_header'>*/}
                {/*            <div className={activeStudentPanel ? 'student_open_search on' : 'student_open_search'}*/}
                {/*                 onClick={activePanel}>*/}
                {/*                <img src={ArrowImg} />*/}
                {/*            </div>*/}
                {/*            <div className={inputActive ? 'autocompleteChat on' : 'autocompleteChat'}>*/}
                {/*                {showSearchStudent}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <Scrollbars>*/}
                {/*            <div style={{padding: '5px 0 5px 3px'}}>*/}
                {/*                {studentList}*/}
                {/*            </div>*/}
                {/*        </Scrollbars>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={activeCompanionMenu ? 'chat_menu active_companion_menu on' : 'active_companion_menu'}>
                    <div className='companion_menu_line'>Pin</div>
                    <div className='companion_menu_line'>User card</div>
                    <div className='companion_menu_line'>Share</div>
                    <div className='companion_menu_line' onClick={() => deleteStudent()}>Delete</div>
                </div>
                <div className='chat_header'>{activeCompanion()}</div>
                <div className='chat_messages'>
                    <Scrollbars
                        ref={scrollbar}
                    >
                        <div className='chat_scroll'>
                            {messageShow()}
                        </div>
                    </Scrollbars>
                </div>
                <div className='chat_write'>
                <textarea
                    id='chat_textarea'
                    name="text"
                    cols="10"
                    rows="14"
                    placeholder='Введите сообщение...'
                    onKeyDown={onKeyPressDown}
                    onKeyUp={onKeyPressUp}
                > </textarea>
                    <button style={{opacity: 1}} onClick={sendMessage}>SEND</button>
                </div>
            </div>
        </div>
    )
}

export default Chat;