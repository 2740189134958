import React from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import callApi from '../../../../utils/call-api';
import CancelClassesDialog from '../cancelClassesDialog/CancelClassesDialog';
import { SMALL_LESSON_LENGTH } from './TimeTableHelper';
import { v1 } from '../../../../utils/uuid';
import config from "../../../../config";

const lang = textId => textId;
const MIN_TIME_LESSON = 25;
const MAX_TIME_LESSON = 50;

class TimeTableDialog extends React.Component {
    state = {
        isOpen: false,
        userGroupInfo: null,
        isShowBannerNaw: false,
        isCancelVLSubmitMode: false,
        avatarLink: `${config.imageStubGeneratorUrl}/50x50.png`,
    };
    wrapperRef = React.createRef();

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            document.removeEventListener("mousedown", this.handleClickOutside);
            this.setState({ isOpen: false, isShowBannerNaw: false });
        }
    }

    componentDidMount() {
        this.prepareUserGroupInfo();
    }

    prepareUserGroupInfo = () => {
        const { virtualLessonInfo } = this.props;

        if (!virtualLessonInfo?.id) return;
        if (virtualLessonInfo?.students?.length < 2) return;

            this.props.setGroupInfo(virtualLessonInfo.id, {});
            const userGroupInfo = {};
            const userGroupStudentsData = {};
            this.setState({ userGroupInfo, userGroupStudentsData });

        // callApi('dashboard/getGroupsByStudents', 'POST', {
        //     virtualLessonId: virtualLessonInfo.id,
        // }).then(res => {
        //     const result = res?.data;
        //     if (!result?.ok) return;

        //     const userGroupInfo = result.groupData;
        //     const userGroupStudentsData = result.studentsData;

        //     this.props.setGroupInfo(virtualLessonInfo.id, {});
        //     this.setState({ userGroupInfo, userGroupStudentsData });
        // });
    }

    render() {
        const { isLeftLesson, virtualLessonInfo, teachersScheduleData, lessonPreview, canUpdate,
                studentInfo = {}, isTeacherSchedule } = this.props;
        const { isOpen, userGroupInfo, userGroupStudentsData, isShowBannerNaw, isCancelVLSubmitMode } = this.state;

        const teacherInfo = (teachersScheduleData || []).find(el => (el && el.id === virtualLessonInfo.teacherId)) || {};
        const lessonLengthInMinutes = Math.floor((virtualLessonInfo.endDate - virtualLessonInfo.startDate) / 60)
        === 30 ? MIN_TIME_LESSON : MAX_TIME_LESSON;
        const isIndividualLesson = (virtualLessonInfo?.students?.length === 1);

        return (
            <div className={"TimeTableContextDialogWrapper" +
                ((lessonLengthInMinutes > SMALL_LESSON_LENGTH) ?  ' TimeTableContextDialogWrapperBig' : '') +
                (!isLeftLesson ? '' : ' TimeTableGridRowCellInfoRed') +
                (!virtualLessonInfo.isConducted ? '' : ' TimeTableGridRowCellInfoYellow') +
                (!virtualLessonInfo.isConducted && !isLeftLesson ? ' TimeTableGridRowCellInfoBlue' : '')
            }
                 ref={this.wrapperRef}
                 onClick={() => {
                     const { isOpen } = this.state;
                     if (isOpen) {
                         this.setState({ isOpen: !isOpen, isShowBannerNaw: false });
                         document.removeEventListener("mousedown", this.handleClickOutside);
                     } else {
                         this.setState({ isOpen: !isOpen });
                         document.addEventListener("mousedown", this.handleClickOutside);
                     }
                 }}
            >
                {lessonPreview}
                {isOpen &&
                <div className={"TimeTableContextDialog" +
                    (!isLeftLesson ? '' : ' TimeTableContextDialogRed') +
                    (!virtualLessonInfo.isConducted ? '' : ' TimeTableContextDialogYellow') +
                    (!virtualLessonInfo.isConducted && !isLeftLesson ? ' TimeTableContextDialogBlue' : '')
                }
                     onClick={e => e.stopPropagation()}
                >
                    {!isCancelVLSubmitMode && <>
                    <div className={"TimeTableContextDialogHeader" +
                        (!isLeftLesson ? '' : ' TimeTableContextDialogHeaderRed') +
                        (!virtualLessonInfo.isConducted ? '' : ' TimeTableContextDialogHeaderYellow') +
                        (!virtualLessonInfo.isConducted && !isLeftLesson ? ' TimeTableContextDialogHeaderBlue' : '')
                    }
                    >
                        {(isLeftLesson || virtualLessonInfo.isCanceledByTeacher) &&
                        <div>
                            {!virtualLessonInfo.isCanceledByTeacher ? 'ОТМЕНЕНО УЧЕНИКОМ' : 'ОТМЕНЕНО УЧИТЕЛЕМ'}
                        </div>}

                        {virtualLessonInfo.isConducted &&
                        <div>
                            {(isIndividualLesson) ?
                                'ИНДИВИДУАЛЬНОЕ VIP ЗАНЯТИЕ УСПЕШНО ЗАВЕРШЕНО' :
                                'ГРУППОВОЕ ЗАНЯТИЕ УСПЕШНО ЗАВЕРШЕНО'}
                        </div>}

                        {!virtualLessonInfo.isConducted && !isLeftLesson &&
                        <div>
                            {(isIndividualLesson) ?
                                'ИНДИВИДУАЛЬНОЕ VIP ЗАНЯТИЕ УСПЕШНО ЗАБРОНИРОВАННО' :
                                'ГРУППОВОЕ ЗАНЯТИЕ УСПЕШНО ЗАБРОНИРОВАННО'}
                        </div>}
                    </div>

                    <div className="TimeTableContextDialogContent">
                        <div>
                            <div className="TimeTableContextDialogContentBanner scale"
                                onClick={() => this.setState({isShowBannerNaw: !isShowBannerNaw})}
                            >
                                {(isIndividualLesson) && <>
                                    <div className="TimeTableContextDialogContentBannerLeft">
                                        <img className={(studentInfo?.avatar || teacherInfo?.avatar) ? "teacher_photo" : "teacher_photo_img"}
                                             src={(isTeacherSchedule ? studentInfo.avatar : teacherInfo.avatar) || this.state.avatarLink}
                                             alt={lang('')}
                                        />
                                    </div>

                                    <div className="TimeTableContextDialogContentBannerRight">
                                        {!isTeacherSchedule &&
                                            <div style={{fontWeight: 600}}>Учитель:</div>
                                        }
                                        <div>
                                            {isTeacherSchedule ?
                                                (studentInfo?.name + (typeof studentInfo?.surname === 'string'?
                                                    ` ${studentInfo?.surname.substring(0, 1)}`: ''
                                                )) :
                                                (teacherInfo?.name + (typeof teacherInfo.surname === 'string'?
                                                    ` ${teacherInfo.surname.substring(0, 1)}`: ''
                                                ))
                                            }
                                        </div>
                                    </div>
                                </>}

                                {(virtualLessonInfo.students.length > 1) &&
                                <div className="TimeTableContextDialogContentBannerRight" style={{width: '100%'}}>
                                    <div style={{fontWeight: 600}}>Группа:</div>
                                    <div>
                                        {userGroupInfo?.name ?
                                            (userGroupInfo.name + (typeof userGroupInfo.surname === 'string' ?
                                                ` ${userGroupInfo.surname.substring(0, 1)}` : '')
                                            ) :
                                            'Не определена'}
                                    </div>
                                </div>}
                            </div>

                            {Array.isArray(userGroupStudentsData) && isShowBannerNaw &&
                            <div className="TimeTableContextDialogContentBannerNaw">
                                {!isTeacherSchedule &&
                                    <div className="TimeTableContextDialogContentBanner scale">
                                    <div className="TimeTableContextDialogContentBannerLeft">
                                        <img className={teacherInfo.avatar ? "teacher_photo" : "teacher_photo_img"}
                                             src={teacherInfo.avatar || this.state.avatarLink}
                                             alt={lang('')}
                                        />
                                    </div>
                                    <div className="TimeTableContextDialogContentBannerRight">
                                        <div style={{fontWeight: 600}}>Учитель:</div>
                                        <div>
                                            {teacherInfo.name} {(typeof teacherInfo.surname === 'string'?
                                                teacherInfo.surname.substring(0, 1) : ''
                                            )}
                                        </div>
                                    </div>
                                </div>}
                                {userGroupStudentsData.map(elem => (
                                    <div className="TimeTableContextDialogContentBanner scale" key={v1()}>
                                        <div className="TimeTableContextDialogContentBannerLeft">
                                            <img className={elem.avatar ? "teacher_photo" : "teacher_photo_img"}
                                                src={elem.avatar || this.state.avatarLink}
                                                alt={lang('')}
                                            />
                                        </div>

                                        <div className="TimeTableContextDialogContentBannerRight">
                                            {elem.name} {(typeof elem.surname === 'string' ?
                                                elem.surname.substring(0, 1) : ''
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>}
                        </div>

                        <div className="TimeTableContextDialogContentHeader">
                            Время:
                        </div>
                        <div className="TimeTableContextDialogContentInfo">
                            {moment(virtualLessonInfo.startDate * 1000).format('DD.MM.YYYY')}<br />
                            {moment(virtualLessonInfo.startDate * 1000).format('HH:mm')}
                        </div>

                        <div className="TimeTableContextDialogContentHeader">
                            Продолжительность занятия:
                        </div>
                        <div className="TimeTableContextDialogContentInfo">
                            {lessonLengthInMinutes} минут
                        </div>

                        {/*<div className="TimeTableContextDialogContentHeader">
                            Предполагаемая тема:
                        </div>
                        <div className="TimeTableContextDialogContentInfo">
                            {virtualLessonInfo.courseName + ', ' + virtualLessonInfo.name}
                        </div>*/}

                        {!virtualLessonInfo.isConducted && !isLeftLesson && canUpdate && isIndividualLesson &&
                        <div className="next_class_info_items">
                            <div className='countdown'>
                                <div className='teacher_review_title'>До начала урока:</div>
                                <div className='text_item'>
                                    {`~${moment.duration(virtualLessonInfo.startDate - (Date.now() / 1000), 'seconds')
                                        .format(`dd ${lang('д.')} hh ${lang('ч.')} mm ${lang('м.')}`)}`}
                                </div>
                                { !(isTeacherSchedule && virtualLessonInfo?.students?.length > 1) &&
                                    <div className='cancel_lesson_btn'
                                    onClick={() => this.setState({ isCancelVLSubmitMode: true })}
                                >
                                    <span className='cancel_lesson_txt'>{lang('Отменить занятие')}</span>
                                </div>}
                            </div>
                        </div>}
                    </div></>}
                    {isCancelVLSubmitMode &&
                        <CancelClassesDialog
                            // isIndividualLesson={isIndividualLesson}
                            onCancel={() => this.setState({ isCancelVLSubmitMode: false })}
                            onSuccess={() => {
                                if (isTeacherSchedule) {
                                    return  callApi('teacher/cancelLesson', 'post', {
                                        virtualLessonId: virtualLessonInfo?.id,
                                        students: virtualLessonInfo?.students || [],
                                    }).then( res => {
                                        const result = res?.data;
                                        if (result?.ok) {
                                            this.setState({ isCancelVLSubmitMode: false });
                                            this.props.updateSchedule();
                                        }
                                    }).catch(() => alert('Ошибка. Обратитесь к администратору'));
                                }

                                return callApi(
                                    (virtualLessonInfo.students.length < 2) ?
                                        'student/leaveIndividualVirtualLesson' : 'student/leaveLesson',
                                    'post',
                                    {
                                        virtualLessonId: virtualLessonInfo?.id,
                                        individualClassType: (lessonLengthInMinutes > SMALL_LESSON_LENGTH) ?
                                            'individualClass' : 'individualShortClass',
                                    }).then(res => {
                                    const result = res?.data;
                                    if (result?.ok) {
                                        const teacherScheduleData = teachersScheduleData.find(el =>
                                            (el && el.teacherId === teacherInfo.teacherId));

                                        const teacherReservation = JSON.parse(teacherScheduleData.reservation) || {};

                                        if (teacherReservation[virtualLessonInfo.startDate]) {
                                            delete teacherReservation[virtualLessonInfo.startDate]
                                        }

                                        // TODO Временно отключено!!!!!!!!!!!!!
                                        // callApi('dashboard/reserveTeacherTime', 'post', {
                                        //     reservation: teacherReservation,
                                        //     teacherId: virtualLessonInfo.teacherId,
                                        // }).then(() => {
                                        // }).catch(() => {});

                                        this.setState({ isCancelVLSubmitMode: false });
                                        this.props.updateSchedule();
                                    } else {
                                        alert('Ошибка. Обратитесь к администратору');
                                    }
                                }).catch(() => alert('Ошибка. Обратитесь к администратору'));
                            }}
                        />
                    }
                </div>}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

const mapStateToProps = () => (
    {}
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeTableDialog));
