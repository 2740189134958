import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Breadcrumb} from 'reactstrap';

const getDisplayString = (str, count = 3) => str.split('-').map(i => i.charAt(0).toUpperCase() + i.slice(1)).slice(0, count).join(' ');

const getUrlString = (path, sub) => `/${path.split(sub)[0]}${sub}`;

const ContainerHeader = ({title, match}) => {
    const path = match.path.substr(1);
    const subPath = path.split('/');
    return (
        <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
            <h2 className="title mb-3 mb-sm-0">{title}</h2>

            <Breadcrumb className="mb-0" tag="nav">
                {subPath.map((sub, index) => ((subPath.length === index + 1)
                    ? (
                        <span
                            className="breadcrumb-item active"
                            key={index}
                        >
                            {getDisplayString(sub)}
                        </span>
                    )
                    : (
                        <Link
                            className="breadcrumb-item"
                            key={index}
                            to={getUrlString(path, sub)}
                        >
                            {getDisplayString(sub)}
                        </Link>
                    )
                ))}
            </Breadcrumb>
        </div>
    );
};

ContainerHeader.propTypes = {
    title: PropTypes.object,
    match: PropTypes.object
};

export default ContainerHeader;

