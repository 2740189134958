import React from 'react';
import Input from "@material-ui/core/Input";

class PdfSizeHelper extends React.Component {
    render() {
        const { parentThis, activeExerciseIndex, exerciseItem, activeTabIndex } = this.props;
        const { heightInVh, widthInPercent} = exerciseItem;

        return(
            <>
                {'Height in VH: '}
                <Input type="number"
                       value={heightInVh}
                       onChange={e => {
                           const { tabs } = parentThis.state;
                           const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                           if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                               lessonItems[activeExerciseIndex].heightInVh = e.target.value;
                               parentThis.setState({ tabs });
                           }
                       }}
                />
                {'Width in %: '}
                <Input type="number"
                       value={widthInPercent}
                       onChange={e => {
                           const { tabs } = parentThis.state;
                           const lessonItems = tabs[activeTabIndex].tabPanel.lessonItems;
                           if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                               lessonItems[activeExerciseIndex].widthInPercent = e.target.value;
                               parentThis.setState({ tabs });
                           }
                       }}
                />
            </>
        );
    }
}

export default PdfSizeHelper;
