import React from 'react';
import callApi from '../../../../utils/call-api';
import TimeTable from '../../common/timeTable/TimeTable';
import './AdminCalendar.scss';
import Paper from '@material-ui/core/Paper';
import {
    Grid,
    PagingPanel,
    SearchPanel,
    Table,
    TableHeaderRow,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SearchState,
    SortingState,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid';

export default class UsersCalendars extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowTimeTable: false,
            allStudentsData: [],
            subscriptionsData: {},
            userSubscriptionsId: null,
            calendarUserType: 'teacher',
            currentPage: 1,
            searchValue: '',
            sorting: [{ columnName: 'id', direction: 'desc' }],
            usersColumns: [
                { name: 'id', title: 'id'},
                { name: 'name', title: 'Name' },
                { name: 'userType', title: 'User type' },
            ],
            usersRows: [],
        }
    }

    componentDidMount() {
        callApi('dashboard/main', 'GET').then(res => {
            if (res && res.data.ok) {
                const usersRows = res.data.allUsersData.map(el => {
                    let userType;

                    switch (el.typeId) {
                        case 1:
                            userType = 'admin';
                            break;
                        case 2:
                            userType = 'teacher';
                            break;
                        case 4:
                            userType = 'student';
                            break;
                        default:
                            userType = 'any';
                            break;
                    }

                    return {
                      id: el.id,
                      name: el.name + ' ' + el.surname,
                      userType,
                    };
                })

                this.setState({
                    usersRows,
                    allStudentsData: res.data.allUsersData.filter(el => el.typeId === 4),
                });
            }
        });

    }

    getUserSubscriptions() {
        if (this.state.calendarUserType === 'teacher') {
            return this.setState({
                isShowTimeTable: true,
            });
        }

        callApi('dashboard/getUserSubscriptions', 'POST', {
            userId: this.state.userSubscriptionsId,
        }).then(res => {
            if (res && res.data.ok) {
                const subscriptionsData = res.data.subscriptionsData ? res.data.subscriptionsData : {};

                this.setState({
                    subscriptionsData,
                    isShowTimeTable: true,
                });
            } else {
                _(res.data.message);
            }
        }).catch(e => {
            _(e);
        });
    }

    TableRow = ({ row, ...restProps }) => {

        return (
            <Table.Row
                {...restProps}
                className="datatable-cell"
                onClick={() => {
                    this.setState({
                        userSubscriptionsId: row.id,
                        calendarUserType: row.userType,
                        isShowTimeTable: false,
                    }, () => this.getUserSubscriptions());
                }}
            />
        );
    }

    render() {
        const { subscriptionsData, userSubscriptionsId, calendarUserType, isShowTimeTable } = this.state;

        return (
            <>
                <Paper >
                <Grid
                    rows={this.state.usersRows}
                    columns={this.state.usersColumns}
                >
                    <SearchState
                        value={this.state.searchValue}
                        onValueChange={searchValue => this.setState({ searchValue })}
                    />
                    <IntegratedFiltering />
                    <SortingState
                        sorting={this.state.sorting}
                        onSortingChange={sorting => this.setState({ sorting })}
                    />
                    <IntegratedSorting />
                    <PagingState
                        currentPage={this.state.currentPage}
                        onCurrentPageChange={currentPage => this.setState({ currentPage })}
                        pageSize={15}
                    />
                    <IntegratedPaging />
                    <Table
                        rowComponent={this.TableRow}
                    />

                    <TableHeaderRow showSortingControls />
                    <TableColumnVisibility
                        defaultHiddenColumnNames={[]}
                        emptyMessageComponent = {() => null}
                    />
                    <Toolbar />
                    <PagingPanel
                        // pageSizes={[10, 15, 25, 50]}
                        messages={this.pagingPanelMessages}
                    />
                    <SearchPanel messages={this.searchPanelMessages} />
                </Grid>
                </Paper>
                {isShowTimeTable && <div className="AdminCalendar">
                    <TimeTable
                        calendarUserId={userSubscriptionsId}
                        closeModal={() => this.setState({
                            isShowTimeTable: false,
                            userSubscriptionsId: null,
                        })}
                        allStudentsData={this.state.allStudentsData}
                        isShowTimeTable={isShowTimeTable}
                        subscriptionsData={subscriptionsData}
                        timeTableType={calendarUserType}
                    />
                </div>}
            </>
        );
    }
}
