import React from 'react';
import {Route, Router, Switch, Redirect} from 'react-router-dom';
import history from '../utils/history';
import PrivateRoute from '../containers/PrivateRoute';
// import SignIn from '../containers/SignIn';
// import SignUp from '../containers/SignUp';
import ResetStep1 from '../containers/ResetStep1';
import ResetStep2 from '../containers/ResetStep2';
import Confirm from '../containers/Confirm';
import VirtualClass from './VirtualClass';
import Student from './admin/Student/Student';
import Teacher from './admin/Teacher/Teacher';
import TeacherV2 from './admin/TeacherV2/Teacher';
import Config from '../config'
import SuperAdmin from './admin/SuperAdmin/SuperAdmin';
import Auth from '../components/auth/Auth';
import PassRecovery from './auth/PassRecovery';
import PassChange from '../components/auth/PassChange';

const AppRoutes = () => (
    <Router history={history}>
        <Switch>
            <Redirect exact from={Config.path} to={Config.path + "/enter"} push/>
            <Route exact path={Config.path + "/enter"} component={Auth}/>
            <Route exact path={Config.path + "/pass/recovery"} component={PassRecovery}/>
            <Route exact path={Config.path + "/pass/change"} component={PassChange}/>
            {/*<Route exact path={Config.path + "/signin"} component={SignIn}/>*/}
            {/*<Route exact path={Config.path + "/signup"} component={SignUp}/>*/}
            <Route exact path={Config.path + "/resetstep1"} component={ResetStep1}/>
            <Route exact path={Config.path + "/resetstep2"} component={ResetStep2}/>
            <Route exact path={Config.path + "/confirm"} component={Confirm}/>
            <PrivateRoute path={Config.path + "/admin/student"} component={Student}/>
            <PrivateRoute path={Config.path + "/admin/teacherv2"} component={Teacher}/>
            <PrivateRoute path={Config.path + "/admin/teacher"} component={TeacherV2}/>
            <PrivateRoute path={Config.path + "/admin/superadmin"} component={SuperAdmin}/>
            <PrivateRoute path={Config.path + "/"} component={VirtualClass}/>
            <Redirect to={Config.path + "/admin/superadmin"}/>
        </Switch>
    </Router>
);

export default AppRoutes;
