import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import asyncComponent from '../../../utils/asyncComponent';

const Router = (props) => {
    const {path} = props.match;
    return (
        <div>
            <Switch>
                <Route exact path={`${path}`} component={asyncComponent(() => import('./Container'))}/>
                <Redirect to={`${path}`} push/>
            </Switch>
        </div>
    );
};

export default Router;