import React from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import callApi from '../../../../utils/call-api';

const SHORT_LESSON = '25'; // min
const LONG_LESSON = '50'; // min

class TimeTableCreateLessonDialog extends React.Component {
    state = {
        isOpen: false,
        userGroupInfo: null,
        isShowBannerNaw: false,
        activeBannerKey: '',
        selectFirstVariant: false,
        selectSecondVariant: false,
    };
    wrapperRef = React.createRef();

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            document.removeEventListener("mousedown", this.handleClickOutside);
            this.setState({
                isOpen: false,
                isShowBannerNaw: false,
                selectFirstVariant: false,
                selectSecondVariant: false,
            });
        }
    }

    componentDidMount() {
        this.prepareUserGroupInfo();
    }

    prepareUserGroupInfo = () => {
        const { virtualLessonInfo } = this.props;

        if (!virtualLessonInfo?.id) return;
        if (virtualLessonInfo?.students?.length < 2) return;

        callApi('dashboard/getGroupsByStudents', 'POST', {
            virtualLessonId: virtualLessonInfo.id,
        }).then(res => {
            const result = res?.data;
            if (!result?.ok) return;

            const userGroupInfo = result.groupData;
            const userGroupStudentsData = result.studentsData;

            this.props.setGroupInfo(virtualLessonInfo.id, userGroupInfo);
            this.setState({ userGroupInfo, userGroupStudentsData });
        });
    }

    render() {
        const { virtualLessonInfo, nextVirtualLessonInfo, userCourseId, teachersScheduleData, userInfo, subscriptionsData } = this.props;
        const { isOpen, isShowBannerNaw, selectFirstVariant, selectSecondVariant } = this.state;

        return (
            <div className="TimeTableContextDialogWrapper TimeTableGridRowCellInfoGreen"
                 ref={this.wrapperRef}
                 onClick={() => {
                     const { isOpen } = this.state;
                     if (isOpen) {
                         this.setState({ isOpen: !isOpen, isShowBannerNaw: false });
                         document.removeEventListener("mousedown", this.handleClickOutside);
                     } else {
                         this.setState({ isOpen: !isOpen });
                         document.addEventListener("mousedown", this.handleClickOutside);
                     }
                 }}
            >
                {virtualLessonInfo.length === 1 ?
                    (virtualLessonInfo[0].name + (typeof virtualLessonInfo[0]?.surname === 'string'?
                        ` ${virtualLessonInfo[0]?.surname.substring(0, 1)}`: ''
                    )) :
                    virtualLessonInfo.length}
                {isOpen &&
                <div className={"TimeTableContextDialog TimeTableContextDialogGreen"}
                     onClick={e => e.stopPropagation()}
                >
                    <div className="TimeTableContextDialogContent">
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            {!!Array.isArray(virtualLessonInfo) && virtualLessonInfo.map(teacherInfo => {
                                const isNextPeriodSchedule = !!nextVirtualLessonInfo.find(el =>
                                    (el && el.teacherId === teacherInfo.teacherId));

                                return (
                                    <div key={teacherInfo.teacherId}>
                                        <div className="TimeTableContextDialogContentBanner scale"
                                             onClick={() => this.setState({
                                                 isShowBannerNaw: !isShowBannerNaw,
                                                 activeBannerKey: teacherInfo.name,
                                             })}
                                        >
                                            <div className="TimeTableContextDialogContentBannerLeft">
                                                <img className={teacherInfo.avatar ? "teacher_photo" : "teacher_photo_img"}
                                                     src={teacherInfo.avatar || this.state.avatarLink}
                                                     alt={''}
                                                />
                                            </div>
                                            <div className="TimeTableContextDialogContentBannerRight">
                                                <div style={{fontWeight: 600}}>Учитель:</div>
                                                <div>
                                                    {teacherInfo.name} {(typeof teacherInfo.surname === 'string' ?
                                                        teacherInfo.surname.substring(0, 1) : ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {isShowBannerNaw && teacherInfo.name === this.state.activeBannerKey &&
                                        <div className="TimeTableContextDialogContentBannerNaw TimeTableContextDialogContentBannerNawGreen">
                                            <div className="TimeTableContextDialogContentBanner scale">
                                                <div className="TimeTableContextDialogContentBannerLeft">
                                                    <img className={teacherInfo.avatar ? "teacher_photo" : "teacher_photo_img"}
                                                         src={teacherInfo.avatar || this.state.avatarLink}
                                                         alt={''}
                                                    />
                                                </div>
                                                <div className="TimeTableContextDialogContentBannerRight">
                                                    <div style={{fontWeight: 600}}>Учитель:</div>
                                                    <div>
                                                        {teacherInfo.name} {(typeof teacherInfo.surname === 'string' ?
                                                            teacherInfo.surname.substring(0, 1) : ''
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="TimeTableContextDialogContentHeader">
                                                Время:
                                            </div>
                                            <div className="TimeTableContextDialogContentInfo">
                                                {moment(teacherInfo.startDate * 1000).format('DD.MM.YYYY')}<br />
                                                {moment(teacherInfo.startDate * 1000).format('HH:mm')}
                                            </div>

                                            <div className="TimeTablePayVariants">
                                                <div  className={"TimeTablePayVariant scale" +
                                                    (selectFirstVariant ? " TimeTablePayVariantActive" : "") +
                                                    (!subscriptionsData.individualShortClassCount ? " TimeTablePayVariantDisable" : "")
                                                }
                                                    onClick={() => this.setState({
                                                        selectFirstVariant: true,
                                                        selectSecondVariant: false,
                                                    })}
                                                >
                                                    <div>{SHORT_LESSON}</div>
                                                    <div>минут</div>
                                                </div>
                                                {isNextPeriodSchedule &&
                                                <div className={"TimeTablePayVariant scale" +
                                                    (selectSecondVariant ? " TimeTablePayVariantActive" : "") +
                                                    (!subscriptionsData.individualClassCount ? " TimeTablePayVariantDisable" : "")
                                                }
                                                     onClick={() => this.setState({
                                                         selectFirstVariant: false,
                                                         selectSecondVariant: true,
                                                     })}
                                                >
                                                    <div>{LONG_LESSON}</div>
                                                    <div>минут</div>
                                                </div>}
                                            </div>

                                            <div className={"TimeTablePayVariantsButton scale" +
                                                ((selectFirstVariant || selectSecondVariant) ? " TimeTablePayVariantsButtonActive" : "")
                                                }
                                                 onClick={() => {
                                                     callApi('student/addIndividualVirtualLesson', 'post', {
                                                         courseId: userCourseId,
                                                         teacherId: teacherInfo.teacherId,
                                                         startDate: teacherInfo.startDate,
                                                         type: selectFirstVariant ? 'individualShort' : 'individual',
                                                         endDate: selectFirstVariant ?
                                                             teacherInfo.endDate : teacherInfo.endDate + (30 * 60), // plus 30 min
                                                     }).then(res => {
                                                         const result = res?.data;
                                                         if (result?.ok) {
                                                             const teacherScheduleData = teachersScheduleData.find(el =>
                                                                 (el && el.teacherId === teacherInfo.teacherId));

                                                             const teacherReservation = JSON.parse(teacherScheduleData.reservation) || {};

                                                             teacherReservation[teacherInfo.startDate] = {
                                                                 startDate: teacherInfo.startDate,
                                                                 endDate: teacherInfo.endDate,
                                                                 userEmail: userInfo?.email || userInfo?.userInfo?.email
                                                             };

                                                             callApi('dashboard/reserveTeacherTime', 'post', {
                                                                 reservation: teacherReservation,
                                                                 teacherId: teacherInfo.teacherId,
                                                             }).then(() => {
                                                             }).catch(() => {});

                                                             this.props.updateSchedule();

                                                         } else {
                                                             alert('Ошибка. Обратитесь к администратору');
                                                         }
                                                     }).catch(() => alert('Ошибка. Обратитесь к администратору'));
                                                 }}
                                            >
                                                ЗАБРОНИРОВАТЬ ЗАНЯТИЕ
                                            </div>
                                        </div>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

const mapStateToProps = state => (
    {
        userInfo: state.auth.userInfo,
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeTableCreateLessonDialog));
