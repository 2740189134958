/* global window */
/* global localStorage */
import * as types from '../constants';

const currentLocale = JSON.parse(localStorage.getItem('locale') || null);

const params = new URLSearchParams(document.location.search);
const id = params.get('id');

const initialState = {
    navCollapsed: false,
    drawerType: types.FIXED_DRAWER,
    width: window.innerWidth,
    navigationStyle: types.VERTICAL_NAVIGATION,
    horizontalNavPosition: types.INSIDE_THE_HEADER,
    locale: currentLocale ? {
        languageId: currentLocale.languageId,
        locale: currentLocale.locale,
        name: currentLocale.name,
        icon: currentLocale.icon
    } : {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us'
    },
    globalErrorDialogVisible: 'no',
    globalErrorDialogMessage: '',

    // lesson
    lessonIdent: null,
    socket: { send: () => null },
    lessonsArr: [],
    usersInfo: {},
    // demoLesson: true,
    demoLesson: !(
        (id == '3535') || (id == '3661') || (id == '3662') || (id == '3663') || (id == '3664') || (id == '3665')
        || (id == '4424') || (id == '4425') || (id == '4426') || (id == '4427') || (id == '4428') || (id == '4429')|| (id == '4430') || (id == '4431')
        || (id == '10992') || (id == '10993') || (id == '10994') || (id == '10995') || (id == '10996') || (id == '10997') || (id == '10998')
        || (id == '10999') || (id == '11000') || (id == '11001') || (id == '11002') || (id == '11003') || (id == '11004') || (id == '11005')
        || (id == '11006') || (id == '11007') || (id == '11008') || (id == '11009') || (id == '11010') || (id == '11011') || (id == '11012')
    ),
    demoGrade: 0,
};

export default function settings(state = initialState, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                navCollapsed: false
            };
        case types.SET_LESSONS_ARR:
            return {
                ...state,
                lessonsArr: action.payload
            };
        case types.TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.isNavCollapsed
            };
        case types.SET_LESSON_IDENT:
            return {
                ...state,
                lessonIdent: action.lessonIdent
            };
        case types.DRAWER_TYPE:
            return {
                ...state,
                drawerType: action.drawerType
            };
        case types.WINDOW_WIDTH:
            return {
                ...state,
                width: action.width
            };
        case types.SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload
            };
        case types.CHANGE_NAVIGATION_STYLE:
            return {
                ...state,
                navigationStyle: action.payload
            };
        case types.HORIZONTAL_MENU_POSITION:
            return {
                ...state,
                horizontalNavPosition: action.payload
            };
        case types.DISPLAY_ERROR_MESSAGE:
            return {
                ...state,
                globalErrorDialogVisible: action.message.visible,
                globalErrorDialogMessage: action.message.message
            };
        case types.SET_LESSON_SOCKET:
            return {
                ...state,
                socket: action.payload
            };
        case types.SET_USERS_INFO:
            return {
                ...state,
                usersInfo: action.payload
            };
        case types.DEMO_LESSON:
            return {
                ...state,
                demoLesson: action.payload
            };
        case types.DEMO_GRADE:
            return {
                ...state,
                demoGrade: action.payload
            };
        default:
            return state;
    }
}