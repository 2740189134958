import * as types from '../constants/homeWork';

const localShowCounter = JSON.parse(localStorage.getItem('showCounter') || true);

const initialState = {
    showCounter: localShowCounter,
}

export default function counter (state = initialState, action) {
    switch (action.type) {
        case types.OPEN_COUNTER:
            return {
                ...state,
                showCounter: action.payload,
            };
        case types.CLOSE_COUNTER:
            return {
                ...state,
                showCounter: action.payload,
            };
        default:
            return state;
    }
};