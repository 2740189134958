import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button/Button';
import MenuItem from '@material-ui/core/MenuItem';
import UserInfo from './UserInfo';
import {bindActionCreators} from 'redux';
import SideNavContent from './SideNavContent';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from '../constants';
import {toggleCollapsedNav, updateWindowWidth, setLessonIdent, switchLanguage} from '../actions/settings';
import { openLikes, closeLikes, participantEmail } from '../actions/likes';
import comparingValuesInSymbol from '../utils/comparing-symbol-values';
import callApi from '../utils/call-api';
import { connect as twilioConnect, createLocalTracks, isSupported } from 'twilio-video';
import './SideNav.scss';
import config from '../config';
import lang from '../utils/lang';

import studentConnectSound from '../../src/assets/sounds/lesson_connection_student.wav';
import teacherConnectSound from '../../src/assets/sounds/lesson_connection_teacher.mp3';
import lessonEndSound from '../../src/assets/sounds/lesson_end.mp3';


const LESSON_ENTER_MIT_TIME_FOR_STUDENT = 3 * 60; // 3 min
const LESSON_ENTER_MIT_TIME_FOR_TEACHER = 3 * 60; // 10 vin

class SideNav extends React.PureComponent {
    state = {
        userType: null,
        userEmail: null,
        activeStudentIndex: 0,
        roomConnectionStatus: false,
        joinRoomDisabled: false,
        joinRoomDisabledViaRequest: false,
        availableLocalTracks: { audio: false, video: false },
        selectedLocalTracks: { audio: false, video: false },
        videoPreviewDisabled: false,
        // sideNavHeight: window.innerHeight,
        defaultVideoWidth: 342,
        defaultVideoHeight: 260,
        defaultLittleVideoHeight: 135,
        videoWidth: 342,
        teacherAndButtonHeight: 250, //~250px = teacher + button height in current design
        littleWindowsPerRow: 2,
        lessonData: '',
        error: '',
        twilioToken: '',
        room: '',
        grade: [],
        defaultStudentLocalMediaContainer: 'local-media-student-container',
        defaultTeacherLocalMediaContainer: 'local-media-teacher-container',
        isLocalParticipantMuted: false,
        isLocalParticipantMutedByTeacher: false,
        isLocalParticipantVideoDisabled: false,
        isLocalParticipantVideoDisabledByTeacher: false,
        activeParticipantFlexOrder: 1,
        participantSelfFlexOrder: 2,
        participantFlexOrder: 3,
        isUserHaveNotControl: true, //Can user pass tests, need logic in component in future (https://stackoverflow.com/questions/639815/how-to-disable-all-div-content)
        rocketsCount: config.maxMarkValue, //Rockets count = mark value
        lottieFiles: [],
        audio: {},
        currentDominantSpeakerSid: null,
        activeStudentSid: null,
        studentWithControleSid: null,
    };

    onToggleCollapsedNav = e => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    updateViewStudentWithControle = participantSid => {
        const participantDiv = document.getElementById(participantSid);

        if (participantDiv) {
            if (participantDiv.classList.contains('viewStudentWithControle')) {
                participantDiv.classList.remove('viewStudentWithControle');
                this.setState({studentWithControleSid: null});
            } else {
                const oldParticipantDiv = document.getElementById(this.state.studentWithControleSid);
                if (oldParticipantDiv) {
                    oldParticipantDiv.classList.remove('viewStudentWithControle');
                }

                this.setState({studentWithControleSid: participantSid});
                participantDiv.classList.add('viewStudentWithControle');
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps.socket ||!prevProps.socket.onopen) && (this.props.socket && this.props.socket.onopen)) {
            this.props.socket.addEventListener("message", e => {
                try {
                    const data = JSON.parse(e.data);

                    if (data.command === 'roomLessonKeyUpdate' && this.state.isRoomNotFound) {
                        return this.props.updateSideNav();
                    }

                    if (data.command === 'roomLessonKeyUpdate' && !this.state.lessonData.lessonKey) {
                        this.setState(prevState => {
                            return {
                                lessonData: {
                                    ...prevState.lessonData,
                                    lessonKey: data.data && data.data.lessonKey || null,
                                }
                            }
                        });
                    }

                    if (data.command === 'muteRemoteParticipant' && !this.state.isLocalParticipantMuted) {
                        const participantSid = data.data.participantSid;
                        const changeMuteStatusButton = document.getElementById('changeMuteStatusButton');

                        if (this.state.room &&
                            this.state.room.localParticipant.sid === participantSid &&
                            this.state.room.localParticipant.audioTracks.size) {

                            if (this.state.isLocalParticipantMutedByTeacher) {
                                this.unmuteLocalParticipantByTeacher();

                                changeMuteStatusButton.disabled = false;
                                changeMuteStatusButton.style.visibility = 'visible';
                            } else {
                                this.muteLocalParticipantByTeacher();

                                changeMuteStatusButton.disabled = true;
                                changeMuteStatusButton.style.visibility = 'hidden';
                            }
                        }
                    }

                    if (data.command === 'disableVideoRemoteParticipant' && !this.state.isLocalParticipantVideoDisabled) {
                        const participantSid = data.data.participantSid;
                        const disableVideoStatusButton = document.getElementById('disableVideoStatusButton');

                        if (this.state.room &&
                            this.state.room.localParticipant.sid === participantSid &&
                            this.state.room.localParticipant.videoTracks.size) {

                            if (this.state.isLocalParticipantVideoDisabledByTeacher) {
                                this.enableVideoLocalParticipantByTeacher();

                                disableVideoStatusButton.disabled = false;
                                disableVideoStatusButton.style.visibility = 'visible';
                            } else {
                                this.disableVideoLocalParticipantByTeacher();

                                disableVideoStatusButton.disabled = true;
                                disableVideoStatusButton.style.visibility = 'hidden';
                            }
                        }
                    }

                    if (data.command === 'finishLesson' && this.state.room) {
                        // this.lessonConducted();
                        this.completeRoom(data && data.teacherComment || '');

                        if (this.state.audio.lessonEnd) {
                            // this.state.audio.lessonEnd.play();
                        }
                    }

                    if (data.command === 'giveControlRemoteParticipant') {
                        this.updateViewStudentWithControle(data?.data?.participantSid);
                    }

                    if (data.command === 'makeParticipantActive') {
                        const participantSid = data?.data?.participantSid;
                        const userEmail = data?.data?.userEmail;

                        if (this.state.userEmail !== userEmail) return;

                        const participantDiv = document.getElementById(participantSid);

                        if (participantDiv && +participantDiv.style.order !== this.state.activeParticipantFlexOrder) {
                            participantDiv.style.order = this.state.activeParticipantFlexOrder;
                            participantDiv.classList.toggle('participant-full-width');
                        } else if (participantDiv && +participantDiv.style.order === this.state.activeParticipantFlexOrder) {

                            if (participantSid === this.state.room.localParticipant.sid) {
                                participantDiv.style.order = this.state.participantSelfFlexOrder;
                            } else {
                                participantDiv.style.order = this.state.participantFlexOrder;
                            }

                            participantDiv.classList.toggle('participant-full-width');
                        }
                    }
                } catch (e) {
                }
            });
        }
    }

    async componentDidMount() {
        const storage = JSON.parse(localStorage.getItem('user') || null);

        if (storage && storage.userInfo) {
            if (storage.userInfo.type) {
                this.setState({ userType: storage.userInfo.type });
            }

            if (storage.userInfo.email) {
                this.setState({ userEmail: storage.userInfo.email });
            }
        }

        window.addEventListener('resize', () => {
            this.props.updateWindowWidth(window.innerWidth);
        });

        await Promise.all([
            this.getLessonData(),
            this.getLocalTracks(),
            this.getLottieFiles(),
            this.setAudioFiles()
        ]);
    }

    renderVideo = () => {
        //Check if browser supported video library
        // if (!isSupported) {
        //     return (
        //         <div style={{ width: '350px', height: `${window.innerHeight}px`, border: 'none' }}>
        //             <h2>Not supported by current browser</h2>
        //         </div>
        //     )
        // }

        return (
            <div
                className={"videoContainer" +
                    ((this.state?.lessonData?.students?.length <= 1) ? " videoContainerVIPLesson" : "")
                }
            >
                {this.state.lessonData.isConducted ?
                    <div>
                        <h3>{lang('Lesson is conducted')}</h3>
                    </div> :
                    <div>
                        {this.state.error ?
                            <div>
                                <h3>{this.state.error + '.'}</h3>
                                {this.renderJoinRoom('updateVLRoom')}
                            </div> :
                            <div>
                                {this.state.roomConnectionStatus ?
                                    <div
                                        style={{
                                            display: "flex",
                                            height: "100vh",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div id={this.state.defaultTeacherLocalMediaContainer} className='teacher-container'> </div>
                                        <div id={this.state.defaultStudentLocalMediaContainer} className='students-container'> </div>
                                        {/*{(this.state.userType === 'admin' || this.state.userType === 'supervisor' || this.state.userType === 'teacher') ?*/}
                                        {/*    <Button*/}
                                        {/*        style={{*/}
                                        {/*            backgroundColor: '#00b3ff',*/}
                                        {/*            borderRadius: '10px',*/}
                                        {/*            marginTop: '5px',*/}
                                        {/*            marginLeft: '5px',*/}
                                        {/*        }}*/}
                                        {/*        variant='contained'*/}
                                        {/*        color='primary'*/}
                                        {/*        onClick={() => this.completeRoom()}*/}
                                        {/*    >*/}
                                        {/*        {lang('Finish lesson')}*/}
                                        {/*    </Button> :*/}
                                        {/*    // <Button*/}
                                        {/*    //     variant='contained'*/}
                                        {/*    //     color='primary'*/}
                                        {/*    //     onClick={() => this.leaveRoom()}*/}
                                        {/*    // >*/}
                                        {/*    //     {lang('Leave room')}*/}
                                        {/*    // </Button>*/}
                                        {/*    <></>}*/}
                                    </div> :
                                    <div>
                                        {this.state.availableLocalTracks.video.length ?
                                            <div className="cameraAndMicrophone">
                                                <h3>{lang('Camera')}</h3>
                                                <div id='local-media'></div>
                                                <Select
                                                    labelId="rows-label"
                                                    value={this.state.selectedLocalTracks.video || 0}
                                                    label="Camera"
                                                    disabled={this.state.videoPreviewDisabled}
                                                    onChange={(e) => this.setState(prevState => {

                                                        return {
                                                            selectedLocalTracks: {
                                                                ...prevState.selectedLocalTracks,
                                                                video: e.target.value
                                                            }
                                                        }
                                                    })}
                                                >
                                                    {this.state.availableLocalTracks.video.map((elem, i) =>
                                                        <MenuItem key={i} value={i}>{elem.label}</MenuItem>
                                                    )}
                                                </Select>
                                                {this.state.availableLocalTracks.audio.length ? <div>
                                                        <h3>{lang('Microphone')}</h3>
                                                        <div id='local-audio-media'></div>
                                                        <Select
                                                            labelId="rows-label"
                                                            value={this.state.selectedLocalTracks.audio || 0}
                                                            label="Microphone"
                                                            disabled={this.state.videoPreviewDisabled}
                                                            onChange={(e) => this.setState(prevState => {

                                                                return {
                                                                    selectedLocalTracks: {
                                                                        ...prevState.selectedLocalTracks,
                                                                        audio: e.target.value
                                                                    }
                                                                }
                                                            })}
                                                        >
                                                            {this.state.availableLocalTracks.audio.map((elem, i) =>
                                                                <MenuItem key={i} value={i}>{elem.label}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </div> :
                                                    <div>
                                                        <h3>{lang('Microphone not found')}</h3>
                                                    </div>}
                                                {/*<Button*/}
                                                {/*    style={{*/}
                                                {/*        backgroundColor: '#00b3ff',*/}
                                                {/*        borderRadius: '10px',*/}
                                                {/*        marginTop: '5px',*/}
                                                {/*        marginLeft: '5px',*/}
                                                {/*    }}*/}
                                                {/*    variant='contained'*/}
                                                {/*    color='primary'*/}
                                                {/*    disabled={this.state.videoPreviewDisabled}*/}
                                                {/*    onClick={() => this.videoPreview()}*/}
                                                {/*>*/}
                                                {/*    {lang('Preview')}*/}
                                                {/*</Button>*/}
                                            </div> :
                                            <div>
                                                <h3>{lang('Camera not found')}</h3>
                                            </div>
                                        }
                                        {
                                            this.renderJoinRoom()
                                        }
                                    </div>
                                }
                            </div>}
                    </div>}
            </div>
        )
    }

    getLessonData = async () => {
        const virtualLessonId = new URLSearchParams(this.props.location.search).get('id');

        await callApi('other/getVl', 'post', { virtualLessonId: virtualLessonId }).then(res => {
            if (res && res.data.ok) {
                const lessonData = res.data.virtualLessonData;
                const currentDate = Math.floor((new Date()).getTime() / 1000);
                const classRoomStorage = JSON.parse(localStorage.getItem('classRoom'));

                this.setState({ lessonData: lessonData }, () => {
                    if (!this.state.lessonData.lessonKey) {
                        const lessonEnterMinTime = (this.state.userType === 'student') ?
                            LESSON_ENTER_MIT_TIME_FOR_STUDENT : LESSON_ENTER_MIT_TIME_FOR_TEACHER;

                        if (lessonData.startDate - lessonEnterMinTime <= currentDate ) {
                            this.createRoom();
                        } else {
                            setTimeout(() => {
                                if (!this.state.lessonData.lessonKey) {
                                    this.createRoom();
                                }
                            },
                     (lessonData.startDate - lessonEnterMinTime - currentDate) * 1000)
                        }
                    }
                });


                // if (currentDate < lessonData.startDate) {
                //     this.setState({error: lang('Lesson not started')});
                // }
                //
                // if (currentDate >= lessonData.endDate) {
                //     this.setState({error: lang('Lesson already conducted')});
                // }

                if(classRoomStorage && (classRoomStorage[0].classRoomId !== lessonData.id)) {
                    localStorage.removeItem('classRoom')
                }

                this.props.setLessonIdent(lessonData);

            } else {
                this.setState({ error: lang(res.data.message) });
            }
        }).catch(err => {
            console.log('err', err);
            this.setState({ error: lang('Virtual lesson with current id not found, not enable or passed') });
        });
    }

    getLocalTracks = async () => {
        // navigator.permissions.query({name: 'microphone'})
        //     .then((permissionObj) => {
        //         console.log(permissionObj.state);
        //     })
        //     .catch((error) => {
        //         console.log('Got error :', error);
        //     })
        //
        // navigator.permissions.query({name: 'camera'})
        //     .then((permissionObj) => {
        //         console.log(permissionObj.state);
        //     })
        //     .catch((error) => {
        //         console.log('Got error :', error);
        //     })

        try {
            const devices = await navigator.mediaDevices.enumerateDevices().then(devices => devices);
            const localTracks = { audio: false, video: false };
            const videoInput = devices.filter(device => device.kind === 'videoinput');
            const audioInput = devices.filter(device => device.kind === 'audioinput');

            if (videoInput) {
                localTracks.video = videoInput;
            }

            if (audioInput) {
                localTracks.audio = audioInput;
            }

            this.setState({
                availableLocalTracks: localTracks,
                selectedLocalTracks: {
                    audio: localTracks.audio.length ? 0 : false,
                    video: localTracks.video.length ? 0 : false
                }
            });
        } catch (e) {
            _(e);
        }
    }

    videoPreview = () => {
        this.setState({ videoPreviewDisabled: true });
        //Пока оставлю
        // createLocalVideoTrack({
        //     ...this.state.availableLocalTracks.video[this.state.selectedLocalTracks.video],
        //     // deviceId: {exact: this.state.availableLocalTracks.video[this.state.selectedLocalTracks.video].deviceId},
        //     width: this.state.defaultVideoWidth,
        //     audio: true
        // }).then(track => {
        //     const localMediaContainer = document.getElementById('local-media');
        //     localMediaContainer.appendChild(track.attach());
        // });

        createLocalTracks({
            video: {
                ...this.state.availableLocalTracks.video[this.state.selectedLocalTracks.video],
                width: this.state.defaultVideoWidth
            },
            audio: typeof this.state.selectedLocalTracks.audio !== 'boolean' ? { ...this.state.availableLocalTracks.audio[this.state.selectedLocalTracks.audio] } : false
        }).then(tracks => {
            const localMediaContainer = document.getElementById('local-media');
            tracks.forEach(track => localMediaContainer.appendChild(track.attach()))
        });
    }

    renderJoinRoom = (command = '') => {
        let onClickFunction = async () => {};
        let text = lang('Войти EpicLang Studio');

        if (command === 'updateVLRoom' && this.state.userType === 'student') {
            this.setState({
                joinRoomDisabled: true,
                isRoomNotFound: true,
                error: 'Возникла ошибка, подождите немного',
            });
        } else if (command === 'updateVLRoom' &&
            (this.state.userType === 'teacher' || this.state.userType === 'admin' || this.state.userType === 'supervisor')) {
            this.setState({ joinRoomDisabled: false });
            onClickFunction = async () => await this.createRoom(true);
            text = lang('Создать комнату');
        } else if (!this.state.joinRoomDisabledViaRequest) {
            if (this.state.lessonData.lessonKey) {
                //Join
                this.setState({ joinRoomDisabled: false });
                onClickFunction = async () => await this.joinRoom();
            } else if (this.state.userType === 'teacher' || this.state.userType === 'admin' || this.state.userType === 'supervisor') {
                //Create
                this.setState({ joinRoomDisabled: false });
                onClickFunction = async () => await this.createRoom();
                text = lang('Создать комнату');
            } else {
                //Disabled
                this.setState({ joinRoomDisabled: true });
            }
        }

        return (
            <Button
                className="enterIntoRoom"
                variant='contained'
                color='primary'
                disabled={this.state.joinRoomDisabled}
                onClick={onClickFunction}
            >{text}</Button>
        )
    }

    createRoom = async (updateVLRoom = false) => {
        console.log('sssssssss1')
        this.setState({
            joinRoomDisabled: true,
            joinRoomDisabledViaRequest: true
        });

        let lessonType = 'go';
        let maxParticipants = 2;
        try {
            if (this.state.lessonData&&this.state.lessonData.students&&this.state.lessonData.students.length > 1) {
                lessonType = 'group';
                maxParticipants = this.state.lessonData.students.length + 1;//+ teacher
            }
        } catch(err){
            console.log(err);
        }

        await callApi('twilio/createVideoRoom', 'post', {
            updateVLRoom,
            virtualLessonId: this.state.lessonData.id,
            type: lessonType,
            maxParticipants,
            roomParams: {
                maxParticipants,
            }
        }).then(res => {
            if (res && res.data.ok) {
                if (updateVLRoom) {
                    this.props.updateSideNav();
                }

                this.setState(prevState => {
                    return {
                        lessonData: {
                            ...prevState.lessonData,
                            lessonKey: res.data.roomSid,
                        }
                    }
                });

                this.props.socket.send(JSON.stringify({
                    command: 'roomLessonKeyUpdate',
                    data: { lessonKey:  res.data.roomSid},
                    val: {
                        vCId: this.state.lessonData.id,
                        userEmail: this.state.userEmail,
                        userType: this.state.userType
                    }
                }));
            } else {
                this.setState({ error: lang(res.data.message) });
            }
        }).catch(err => {
            this.setState({ error: lang('Cannot create video room') });
        });

        this.setState({
            joinRoomDisabled: false,
            joinRoomDisabledViaRequest: false
        });
    }

    joinRoom = async () => {

        // this.state.audio.studentConnect.play(); this.state.audio.studentConnect.volume = global.soundsVolume;

        this.setState({
            joinRoomDisabled: true,
            joinRoomDisabledViaRequest: true
        });

        const [tracks] = await Promise.all([
            createLocalTracks({
                audio: typeof this.state.selectedLocalTracks.audio === 'boolean' ? this.state.selectedLocalTracks.audio : { ...this.state.availableLocalTracks.audio[this.state.selectedLocalTracks.audio] },
                video: typeof this.state.selectedLocalTracks.video === 'boolean' ? this.state.selectedLocalTracks.video : {
                    ...this.state.availableLocalTracks.video[this.state.selectedLocalTracks.video],
                    width: this.state.videoWidth
                }
            }).catch(e => this.setState({ error: lang('Device error') })),
            callApi('twilio/createVideoToken', 'post', {
                virtualLessonId: this.state.lessonData.id,
            }).then(res => {

                if (res && res.data.ok) {
                    this.setState({ twilioToken: res.data.token });
                } else {
                    this.setState({ error: lang(res.data.message) });
                }
            }).catch(() => {
                this.setState({ error: lang('Cannot join to video room') });
            })
        ]);

        await twilioConnect(
            this.state.twilioToken,
            {
                name: this.state.lessonData.lessonKey,
                tracks: tracks,
                dominantSpeaker: true
                // logLevel: 'debug'
            }
        )
            .then(room => {
                // Listen to the "beforeunload" event on window to leave the Room when the tab/browser is being closed.
                window.addEventListener('beforeunload', () => room.disconnect());

                // iOS Safari does not emit the "beforeunload" event on window. Use "pagehide" instead.
                window.addEventListener('pagehide', () => room.disconnect());

                if (this.state.userType === 'teacher') {
                    room.participantsStickersData = {};
                    room.participantsGradesData = {};
                }

                room.on('disconnected', room => {
                    //Отвязать локальные медиа элементы(камера, микрофон и т.д.)
                    room.localParticipant.tracks.forEach(publication => {
                        publication.track.stop();

                        const attachedElements = publication.track.detach();

                        attachedElements.forEach(element => element.remove());
                    });

                    //Отвязать удаленные медиа элементы
                    room.participants.forEach(participant => {
                        const pDiv = document.getElementById(participant.sid);

                        if (pDiv) {
                            pDiv.remove();
                        }
                    });
                });

                // Добавление подключившихся пользователей в контейнер
                room.on('participantConnected', participant => {
                    console.log(participant, 'remoteParticipant');

                    const participantIdentity = JSON.parse(participant.identity);
                    let bigWindowsCount = this.getBigWindowsCount(window.innerHeight, this.state.teacherAndButtonHeight,
                        this.state.defaultVideoHeight, this.state.defaultLittleVideoHeight,
                        this.state.littleWindowsPerRow, room.participants.size);
                    let mediaContainerId = this.state.defaultStudentLocalMediaContainer;

                    if (participantIdentity.type === 'teacher') {
                        mediaContainerId = this.state.defaultTeacherLocalMediaContainer;

                        if (this.state.audio.teacherConnect) {
                            this.state.audio.teacherConnect.play(); this.state.audio.teacherConnect.volume = global.soundsVolume;
                        }
                    } else if (participantIdentity.type === 'student') {
                        
                        if (this.state.audio.studentConnect) {
                            this.state.audio.studentConnect.play(); this.state.audio.studentConnect.volume = global.soundsVolume;
                        }
                    }

                    const localMediaContainer = document.getElementById(mediaContainerId);
                    const div = document.createElement('div');
                    div.id = participant.sid;
                    div.style.order = this.state.participantFlexOrder;

                    if (participantIdentity.type === 'student' && bigWindowsCount) {
                        // div.className = 'participant_big';
                        div.className = 'participant';
                    } else {
                        div.className = 'participant';
                    }

                    if (this.state.lessonData.students && this.state.lessonData.students.length === 1) {
                        div.className = 'participant_big';
                    }

                    const span = document.createElement('span');
                    span.innerText = participantIdentity.name;

                    localMediaContainer.appendChild(div);

                    participant.tracks.forEach(publication => {

                        if (publication.isSubscribed) {
                            const track = publication.track;
                            div.appendChild(track.attach());
                        }
                    });

                    div.appendChild(span);

                    if (this.state.userType === 'teacher') {
                        room.participantsStickersData[participant.sid] = { isOpen: false };
                        room.participantsGradesData[participant.sid] = { isOpen: false };
                        const userMenuDiv = document.createElement('div');
                        userMenuDiv.className = 'user_menu_slide user_menu_slide_left';

                        const muteRemoteParticipantButton = document.createElement('button');
                        muteRemoteParticipantButton.className = 'sound_button';
                        muteRemoteParticipantButton.onclick = () => {
                            this.muteRemoteParticipant(participant.sid)
                            muteRemoteParticipantButton.classList.toggle('sound_button');
                            muteRemoteParticipantButton.classList.toggle('sound_button_disable');
                        };

                        userMenuDiv.appendChild(muteRemoteParticipantButton);

                        const disableVideoRemoteParticipantButton = document.createElement('button');
                        disableVideoRemoteParticipantButton.className = 'video_button';
                        disableVideoRemoteParticipantButton.onclick = () => {
                            this.disableVideoRemoteParticipant(participant.sid);
                            disableVideoRemoteParticipantButton.classList.toggle('video_button');
                            disableVideoRemoteParticipantButton.classList.toggle('video_button_disable');
                        };

                        userMenuDiv.appendChild(disableVideoRemoteParticipantButton);

                        const activeRemoteParticipantButton = document.createElement('button');
                        activeRemoteParticipantButton.className = 'active_participant_button';
                        activeRemoteParticipantButton.onclick = () => {
                            this.makeParticipantActive(participant.sid);
                            activeRemoteParticipantButton.classList.toggle('active_participant_button');
                            activeRemoteParticipantButton.classList.toggle('active_participant_button_disable');
                        };

                        userMenuDiv.appendChild(activeRemoteParticipantButton);

                        const userMenuDivRight = document.createElement('div');
                        userMenuDivRight.className = 'user_menu_slide user_menu_slide_right';

                        const animationButton = document.createElement('button');
                        animationButton.className = 'animation_button';
                        animationButton.onclick = () => {
                            this.openAnimationsRemoteParticipant(participant.sid);
                            this.openCloseLikes(participant.sid, participantIdentity.email, participantIdentity.name);
                            animationButton.classList.toggle('animation_button');
                            animationButton.classList.toggle('animation_button_disable');
                        };

                        userMenuDivRight.appendChild(animationButton);

                        const controlButton = document.createElement('button');
                        controlButton.className = 'control_button';
                        controlButton.onclick = () => {
                            this.giveControlRemoteParticipant(participantIdentity.email, participant.sid);
                            controlButton.classList.toggle('control_button');
                            controlButton.classList.toggle('control_button_disable');

                            let newActiveStudentSid = participant.sid;

                            if (this.state.activeStudentSid && (this.state.activeStudentSid !== newActiveStudentSid)) {
                                const activeStudentElem = document.getElementById(this.state.activeStudentSid);
                                const activeStudentButton = activeStudentElem.getElementsByClassName('control_button_disable')[0];

                                if (activeStudentButton) {
                                    activeStudentButton.classList.toggle('control_button');
                                    activeStudentButton.classList.toggle('control_button_disable');
                                }
                                
                            } else if (this.state.activeStudentSid && (this.state.activeStudentSid === newActiveStudentSid)) {
                                newActiveStudentSid = null;
                            }

                            this.setState({activeStudentSid: newActiveStudentSid});
                        };

                        userMenuDivRight.appendChild(controlButton);

                        const gradeButton = document.createElement('button');
                        gradeButton.className = 'grade_button';
                        gradeButton.onclick = () => {
                            this.openGradeParticipant(participant.sid);
                            gradeButton.classList.toggle('grade_button');
                            gradeButton.classList.toggle('grade_button_disable');
                        };

                        userMenuDivRight.appendChild(gradeButton);

                        const gradeDiv = document.createElement('div');
                        gradeDiv.className = 'participant_grades';
                        gradeDiv.style.visibility = 'hidden';

                        for (let i = 0; i < this.state.rocketsCount; i++) {
                            const grade = document.createElement('div');
                            grade.className = 'grade_number grade_not_delivered';
                            grade.addEventListener("click", () => {
                                this.giveGradeParticipant(participantIdentity, i + 1, participant.sid);
                            });
                            gradeDiv.appendChild(grade);
                        }

                        userMenuDivRight.appendChild(gradeDiv);
                        div.appendChild(userMenuDiv);
                        div.appendChild(userMenuDivRight);
                    }

                    participant.on('trackSubscribed', track => {
                        div.appendChild(track.attach());
                    });

                    let index = 0;

                    //Начиная с n элемента уменьшаю окна, 
                    //т.к. тут они только добавляются
                    room.participants.forEach(participant => {

                        if (index >= bigWindowsCount) {
                            const elem = document.getElementById(participant.sid);
                            elem.classList.remove('participant_big');
                            elem.classList.add('participant');
                        }

                        index++;
                    });

                    this.setState({ room: room });
                });

                room.on('participantDisconnected', participant => {
                    console.log(participant, 'Disconnected participant');

                    const pDiv = document.getElementById(participant.sid);

                    if (pDiv) {
                        pDiv.remove();
                    }

                    // if (room.participantsStickersData && room.participantsStickersData[participant.sid]) {
                    if (this.state.userType === 'teacher') {
                        delete room.participantsStickersData[participant.sid];
                        delete room.participantsGradesData[participant.sid];
                    }

                    let bigWindowsCount = this.getBigWindowsCount(window.innerHeight, this.state.teacherAndButtonHeight,
                        this.state.defaultVideoHeight, this.state.defaultLittleVideoHeight,
                        this.state.littleWindowsPerRow, room.participants.size);
                    const participantIdentity = JSON.parse(participant.identity);

                    if (participantIdentity.type !== 'teacher') {
                        let index = 0;
                        //Начиная с n элемента увеличиваю окна, 
                        //т.к. тут они только удаляются
                        room.participants.forEach(participant => {

                            // if (index < bigWindowsCount) {
                                // const elem = document.getElementById(participant.sid);
                                // elem.classList.remove('participant');
                                // elem.classList.add('participant_big');
                            // }

                            if (this.state.lessonData.students && this.state.lessonData.students.length === 1) {
                                const elem = document.getElementById(participant.sid);
                                elem.classList.remove('participant');
                                elem.classList.add('participant_big');
                            }

                            index++;
                        });
                    }

                    this.setState({ room: room });
                });

                room.on('dominantSpeakerChanged', participant => {
                    console.log('dominantSpeakerChanged')
                    console.log('The new dominant speaker in the Room is:', participant);
                    
                    const newDominantSpeakerSid = (participant && participant.sid) || null;
                    const currentDominantSpeakerSid = this.state.currentDominantSpeakerSid;

                    if (currentDominantSpeakerSid) {
                        const currentDominantSpeakerElem = document.getElementById(currentDominantSpeakerSid);

                        if (currentDominantSpeakerElem) {
                            currentDominantSpeakerElem.classList.remove('dominantSpeaker');   
                        }
                    }

                    if (newDominantSpeakerSid) {
                        const newDominantSpeakerElem = document.getElementById(newDominantSpeakerSid);

                        if (newDominantSpeakerElem) {
                            newDominantSpeakerElem.classList.add('dominantSpeaker');   
                        }
                    }
                    
                    this.setState({
                        currentDominantSpeakerSid: newDominantSpeakerSid, 
                        room: room 
                    });
                });

                this.setState({
                    room: room,
                    roomConnectionStatus: true
                });
                
                let bigWindowsCount = this.getBigWindowsCount(window.innerHeight, this.state.teacherAndButtonHeight,
                    this.state.defaultVideoHeight, this.state.defaultLittleVideoHeight,
                    this.state.littleWindowsPerRow, room.participants.size);
                let mediaContainerId = this.state.defaultStudentLocalMediaContainer;

                if (this.state.userType === 'teacher') {
                    mediaContainerId = this.state.defaultTeacherLocalMediaContainer;
                }

                const localMediaContainer = document.getElementById(mediaContainerId);
                const div = document.createElement('div');
                div.id = room.localParticipant.sid;
                div.style.order = this.state.participantSelfFlexOrder;

                if (this.state.userType === 'student' && bigWindowsCount) {
                    // div.className = 'participant_big';
                    div.className = 'participant';
                } else {
                    div.className = 'participant';
                }

                if (this.state.lessonData.students && this.state.lessonData.students.length === 1) {
                    div.className = 'participant_big';
                }

                const storage = JSON.parse(localStorage.getItem('user') || null);
                const span = document.createElement('span');
                span.innerText = storage.userInfo.name;
                div.appendChild(span);

                const userMenuDiv = document.createElement('div');
                userMenuDiv.className = 'user_menu_slide user_menu_slide_left';

                const muteLocalParticipantButton = document.createElement('button');
                muteLocalParticipantButton.id = 'changeMuteStatusButton';
                muteLocalParticipantButton.className = 'sound_button';
                muteLocalParticipantButton.onclick = () => {
                    this.state.isLocalParticipantMuted ? this.unmuteLocalParticipant() : this.muteLocalParticipant();
                    muteLocalParticipantButton.classList.toggle('sound_button');
                    muteLocalParticipantButton.classList.toggle('sound_button_disable');
                };

                userMenuDiv.appendChild(muteLocalParticipantButton);

                const disableVideoLocalParticipantButton = document.createElement('button');
                disableVideoLocalParticipantButton.id = 'disableVideoStatusButton';
                disableVideoLocalParticipantButton.className = 'video_button';
                disableVideoLocalParticipantButton.onclick = () => {
                    this.state.isLocalParticipantVideoDisabled ? this.enableVideoLocalParticipant() : this.disableVideoLocalParticipant();
                    disableVideoLocalParticipantButton.classList.toggle('video_button');
                    disableVideoLocalParticipantButton.classList.toggle('video_button_disable');
                };

                userMenuDiv.appendChild(disableVideoLocalParticipantButton);
                div.appendChild(userMenuDiv);
                localMediaContainer.appendChild(div);

                tracks.forEach(track => {
                    div.appendChild(track.attach());
                });

                if (room.participants.size) {
                    let index = 0;

                    if (this.state.userType !== 'teacher' && bigWindowsCount > 0) {
                        bigWindowsCount--;
                    }

                    room.participants.forEach(participant => {//index === key(string like uuid), not number
                        const participantIdentity = JSON.parse(participant.identity);
                        let mediaContainerId = this.state.defaultStudentLocalMediaContainer;

                        if (participantIdentity.type === 'teacher') {
                            mediaContainerId = this.state.defaultTeacherLocalMediaContainer;
                        }

                        const participantDiv = document.getElementById(mediaContainerId);
                        const partDiv = document.createElement('div');
                        partDiv.id = participant.sid;
                        partDiv.style.order = this.state.participantFlexOrder;

                        if (participantIdentity.type === 'student' && index < bigWindowsCount) {
                            // partDiv.className = 'participant_big';
                            partDiv.className = 'participant';
                        } else {
                            partDiv.className = 'participant';
                        }

                        if (this.state.lessonData.students && this.state.lessonData.students.length === 1) {
                            partDiv.className = 'participant_big';
                        }

                        if (participantIdentity.type !== 'teacher') {
                            index++;   
                        }

                        const span = document.createElement('span');
                        span.innerText = participantIdentity.name;

                        participantDiv.appendChild(partDiv);

                        participant.tracks.forEach(publication => {

                            if (publication.track) {
                                partDiv.appendChild(publication.track.attach());
                            }
                        });

                        partDiv.appendChild(span);

                        if (this.state.userType === 'teacher') {
                            room.participantsStickersData[participant.sid] = { isOpen: false };
                            room.participantsGradesData[participant.sid] = { isOpen: false };
                            const userMenuDiv = document.createElement('div');
                            userMenuDiv.className = 'user_menu_slide user_menu_slide_left';

                            const muteRemoteParticipantButton = document.createElement('button');
                            muteRemoteParticipantButton.className = 'sound_button';
                            muteRemoteParticipantButton.onclick = () => {
                                this.muteRemoteParticipant(participant.sid);
                                muteRemoteParticipantButton.classList.toggle('sound_button');
                                muteRemoteParticipantButton.classList.toggle('sound_button_disable');
                            };

                            userMenuDiv.appendChild(muteRemoteParticipantButton);

                            const disableVideoRemoteParticipantButton = document.createElement('button');
                            disableVideoRemoteParticipantButton.className = 'video_button';
                            disableVideoRemoteParticipantButton.onclick = () => {
                                this.disableVideoRemoteParticipant(participant.sid);
                                disableVideoRemoteParticipantButton.classList.toggle('video_button');
                                disableVideoRemoteParticipantButton.classList.toggle('video_button_disable');
                            };

                            userMenuDiv.appendChild(disableVideoRemoteParticipantButton);

                            const activeRemoteParticipantButton = document.createElement('button');
                            activeRemoteParticipantButton.className = 'active_participant_button';
                            activeRemoteParticipantButton.onclick = () => {
                                this.makeParticipantActive(participant.sid);
                                activeRemoteParticipantButton.classList.toggle('active_participant_button');
                                activeRemoteParticipantButton.classList.toggle('active_participant_button_disable');
                            };

                            userMenuDiv.appendChild(activeRemoteParticipantButton);

                            const userMenuDivRight = document.createElement('div');
                            userMenuDivRight.className = 'user_menu_slide user_menu_slide_right';

                            const animationButton = document.createElement('button');
                            animationButton.className = 'animation_button';
                            animationButton.onclick = () => {
                                this.openAnimationsRemoteParticipant(participant.sid);
                                this.openCloseLikes(participant.sid, participantIdentity.email, participantIdentity.name);
                                animationButton.classList.toggle('animation_button');
                                animationButton.classList.toggle('animation_button_disable');
                            };

                            userMenuDivRight.appendChild(animationButton);

                            const controlButton = document.createElement('button');
                            controlButton.className = 'control_button';
                            controlButton.onclick = () => {
                                this.giveControlRemoteParticipant(participantIdentity.email, participant.sid);
                                controlButton.classList.toggle('control_button');
                                controlButton.classList.toggle('control_button_disable');
    
                                let newActiveStudentSid = participant.sid;

                                if (this.state.activeStudentSid && (this.state.activeStudentSid !== newActiveStudentSid)) {
                                    const activeStudentElem = document.getElementById(this.state.activeStudentSid);
                                    const activeStudentButton = activeStudentElem.getElementsByClassName('control_button_disable')[0];
    
                                    if (activeStudentButton) {
                                        activeStudentButton.classList.toggle('control_button');
                                        activeStudentButton.classList.toggle('control_button_disable');
                                    }

                                } else if (this.state.activeStudentSid && (this.state.activeStudentSid === newActiveStudentSid)) {
                                    newActiveStudentSid = null;
                                }
    
                                this.setState({activeStudentSid: newActiveStudentSid});
                            };

                            userMenuDivRight.appendChild(controlButton);

                            const gradeButton = document.createElement('button');
                            gradeButton.className = 'grade_button';
                            gradeButton.onclick = () => {
                                this.openGradeParticipant(participant.sid);
                                gradeButton.classList.toggle('grade_button');
                                gradeButton.classList.toggle('grade_button_disable');
                            };

                            userMenuDivRight.appendChild(gradeButton);

                            const gradeDiv = document.createElement('div');
                            gradeDiv.className = 'participant_grades';
                            gradeDiv.style.visibility = 'hidden';

                            for (let i = 0; i < this.state.rocketsCount; i++) {
                                const grade = document.createElement('div');
                                grade.className = 'grade_number grade_not_delivered';
                                grade.addEventListener("click", () => {
                                    this.giveGradeParticipant(participantIdentity, i + 1, participant.sid);
                                });
                                gradeDiv.appendChild(grade);
                            }

                            userMenuDivRight.appendChild(gradeDiv);
                            partDiv.appendChild(userMenuDiv);
                            partDiv.appendChild(userMenuDivRight);
                        }

                        participant.on('trackSubscribed', track => {
                            partDiv.appendChild(track.attach());
                        });
                    });
                }
            })
            .catch(e => {
                this.setState({ error: e.message });

                try {
                    tracks.forEach(track => track.stop());
                } catch (e) {
                    console.log(e);
                }
            });

        this.setState({
            joinRoomDisabled: false,
            joinRoomDisabledViaRequest: false
        });
    }

    completeRoom = async (teacherComment) => {
        console.log('Complete room');

        this.setState({
            joinRoomDisabled: true,
            joinRoomDisabledViaRequest: true
        });

        //Not need promise.all
        await this.state.room.disconnect();
        await callApi('twilio/completeVideoRoom', 'post', {
            virtualLessonId: this.state.lessonData.id,
            teacherComment,
        }).then(async res => {

            if (res && res.data.ok) {
                this.state.room.localParticipant.tracks.forEach(publication => {
                    const attachedElements = publication.track.detach();
                    attachedElements.forEach(element => element.remove());
                });

                this.setState(prevState => {

                    return {
                        room: '',
                        roomConnectionStatus: false,
                        // roomParticipantsLocalStatus: {},
                        lessonData: {
                            ...prevState.lessonData,
                            isConducted: true
                        }
                    }
                });

                // await this.finishLesson();
            } else {
                this.setState({ error: res.data.message });
            }
        }).catch(err => {
            this.setState({ error: lang('Cannot complete video room') });
        });

        this.setState({
            joinRoomDisabled: false,
            joinRoomDisabledViaRequest: false
        });
    };

    leaveRoom = async () => {
        await this.state.room.disconnect();

        this.setState({
            room: '',
            roomConnectionStatus: false,
            // roomParticipantsLocalStatus: {}
        });
    };

    lessonConducted = async () => {
        this.state.room.localParticipant.tracks.forEach(publication => {
            const attachedElements = publication.track.detach();
            attachedElements.forEach(element => element.remove());
        });

        await this.setState(prevState => {

            return {
                room: '',
                roomConnectionStatus: false,
                // roomParticipantsLocalStatus: {},
                lessonData: {
                    ...prevState.lessonData,
                    isConducted: true
                }
            }
        });

        console.log(this.state.lessonData, this.state.roomConnectionStatus, 'lessonConducted');
    };

    getLottieFiles = async () => {
        await callApi('dashboard/getSystemFilesAdmin', 'post', {
            type: 'lottie'
        }).then(async res => {

            if (res && res.data.ok) {
                this.setState({lottieFiles: res.data.filesData});
            } else {
                this.setState({ error: res.data.message });
            }
        }).catch(err => {
            console.log('Cannot get lottie files');
        });
    }

    setAudioFiles = async () => {
        try {
            const studentConnectAudio = new Audio();
            studentConnectAudio.src = studentConnectSound;
            
            const teacherConnectAudio = new Audio();
            teacherConnectAudio.src = teacherConnectSound;

            const lessonEndAudio = new Audio();
            lessonEndAudio.src = lessonEndSound;
            lessonEndAudio.volume = global.soundsVolume;

            this.setState({
                audio: {
                    studentConnect: studentConnectAudio,
                    teacherConnect: teacherConnectAudio,
                    lessonEnd: lessonEndAudio
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    muteLocalParticipant = async () => {
        this.state.room.localParticipant.audioTracks.forEach(publication => {
            publication.track.disable();
        });

        await this.setState({ isLocalParticipantMuted: true });

        console.log(this.state.isLocalParticipantMuted, 'muteButton');
    };

    unmuteLocalParticipant = async () => {
        this.state.room.localParticipant.audioTracks.forEach(publication => {
            publication.track.enable();
        });

        await this.setState({ isLocalParticipantMuted: false });

        console.log(this.state.isLocalParticipantMuted, 'unmuteButton');
    };

    muteLocalParticipantByTeacher = async () => {
        this.state.room.localParticipant.audioTracks.forEach(publication => {
            publication.track.disable();
        });

        await this.setState({ isLocalParticipantMutedByTeacher: true });

        console.log(this.state.isLocalParticipantMutedByTeacher, 'muteButtonByTeacher');
    };

    unmuteLocalParticipantByTeacher = async () => {
        this.state.room.localParticipant.audioTracks.forEach(publication => {
            publication.track.enable();
        });

        await this.setState({ isLocalParticipantMutedByTeacher: false });

        console.log(this.state.isLocalParticipantMutedByTeacher, 'unmuteButtonByTeacher');
    };

    disableVideoLocalParticipant = async () => {
        this.state.room.localParticipant.videoTracks.forEach(publication => {
            publication.track.disable();
        });

        await this.setState({ isLocalParticipantVideoDisabled: true });

        console.log(this.state.isLocalParticipantVideoDisabled, 'disableVideoButton');
    };

    enableVideoLocalParticipant = async () => {
        this.state.room.localParticipant.videoTracks.forEach(publication => {
            publication.track.enable();
        });

        await this.setState({ isLocalParticipantVideoDisabled: false });

        console.log(this.state.isLocalParticipantVideoDisabled, 'enableVideoButton');
    };

    disableVideoLocalParticipantByTeacher = async () => {
        this.state.room.localParticipant.videoTracks.forEach(publication => {
            publication.track.disable();
        });

        await this.setState({ isLocalParticipantVideoDisabledByTeacher: true });

        console.log(this.state.isLocalParticipantVideoDisabledByTeacher, 'disableVideoButtonByTeacher');
    };

    enableVideoLocalParticipantByTeacher = async () => {
        this.state.room.localParticipant.videoTracks.forEach(publication => {
            publication.track.enable();
        });

        await this.setState({ isLocalParticipantVideoDisabledByTeacher: false });

        console.log(this.state.isLocalParticipantVideoDisabledByTeacher, 'enableVideoButtonByTeacher');
    };

    muteRemoteParticipant = async (participantSid) => {
        this.props.socket.send(JSON.stringify({
            command: 'muteRemoteParticipant',
            data: { participantSid: participantSid },
            val: {
                vCId: this.state.lessonData.id,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));
        this.hideParticipantWindows(participantSid);
    };

    disableVideoRemoteParticipant = async (participantSid) => {
        this.props.socket.send(JSON.stringify({
            command: 'disableVideoRemoteParticipant',
            data: { participantSid },
            val: {
                vCId: this.state.lessonData.id,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));
        this.hideParticipantWindows(participantSid);
    };

    makeParticipantActive = async (participantSid) => {
        this.props.socket.send(JSON.stringify({
            command: 'makeParticipantActive',
            data: { participantSid, userEmail: this.state.userEmail},
            val: {
                vCId: this.state.lessonData.id,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));
        this.hideParticipantWindows(participantSid);
    };

    giveControlRemoteParticipant = async (participantEmail, participantSid) => {
        this.props.socket.send(JSON.stringify({
            command: 'giveControlRemoteParticipant',
            data: { participantEmail, participantSid },
            val: {
                vCId: this.state.lessonData.id,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));

        this.hideParticipantWindows();
        this.updateViewStudentWithControle(participantSid);
    };

    openCloseLikes = async(participantSid, participantEmail, participantName) => {
        if (this.props.openCloseLikes) {
            this.props.closeLikes();
        } else {
            this.props.openLikes();
            this.props.participantEmail(participantEmail, participantName);
        }
    }

    giveGradeParticipant = async (participant, grade, participantSid) => {
        const participantName = participant.name;
        const participantEmail = participant.email;
        const participantDiv = document.getElementById(participantSid);
        const gradeDiv = participantDiv.getElementsByClassName('participant_grades')[0];
        const classRoomIdToStorage = this.state.lessonData.id;
        const classRoomFromStorage = JSON.parse(localStorage.getItem('classRoom'));
        const storageGrades = [];


        if (classRoomFromStorage && classRoomFromStorage[0].classRoomId === classRoomIdToStorage) {
            const indexStudentStorage = classRoomFromStorage[1].grades.findIndex(item => item.email === participantEmail);
            if (indexStudentStorage !== -1) {
                classRoomFromStorage[1].grades[indexStudentStorage].grade = grade
                localStorage.setItem('classRoom', JSON.stringify(classRoomFromStorage))
            } else {
                classRoomFromStorage[1].grades.push({
                    email: participantEmail,
                    grade,
                    name: participantName,
                    participantSid
                })
                localStorage.setItem('classRoom', JSON.stringify(classRoomFromStorage))
            }
        } else {
            storageGrades.push(
                {classRoomId: classRoomIdToStorage},
                {grades: [{
                        email: participantEmail,
                        grade,
                        name: participantName,
                        participantSid
                    }]})
            localStorage.setItem('classRoom', JSON.stringify(storageGrades))
        }

        this.props.socket.send(JSON.stringify({
            command: 'giveGradeParticipant',
            data: {participantEmail, gradeParticipant: grade, name: participantName},
            val: {
                vCId: this.state.lessonData.id,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));


        for (let i = 0; i < gradeDiv.children.length; i++) {
            if (classRoomFromStorage) {
                const indexParticipant = classRoomFromStorage[1].grades.findIndex(item => item.email === participantEmail);
                if((indexParticipant !== -1) && (i < classRoomFromStorage[1].grades[indexParticipant].grade)) {
                    gradeDiv.children[i].classList.remove('grade_not_delivered');
                    gradeDiv.children[i].classList.add('grade_delivered');
                } else {
                    gradeDiv.children[i].classList.remove('grade_delivered');
                    gradeDiv.children[i].classList.add('grade_not_delivered');
                }
            } else {
                const indexParticipant = storageGrades[1].grades.findIndex(item => item.email === participantEmail);
                if((indexParticipant !== -1) && (i < storageGrades[1].grades[indexParticipant].grade)) {
                    gradeDiv.children[i].classList.remove('grade_not_delivered');
                    gradeDiv.children[i].classList.add('grade_delivered');
                } else {
                    gradeDiv.children[i].classList.remove('grade_delivered');
                    gradeDiv.children[i].classList.add('grade_not_delivered');
                }
            }
        }

        this.hideParticipantWindows();

        await callApi('teacher/rateStudentVirtualLesson', 'post', {
            virtualLessonId: this.state.lessonData.id,
            email: participantEmail,
            value: grade
        }).then(async res => {

            if (res && res.data.ok) {
                console.log(lang('Student rated successfully'));
            } else {
                console.log(res.data.message);
            }
        }).catch(() => {
            console.log(lang('Cannot rate student'));
        });
    }

    finishLesson = async () => {
        this.props.socket.send(JSON.stringify({
            command: 'finishLesson',
            val: {
                vCId: this.state.lessonData.id,
                userEmail: this.state.userEmail,
                userType: this.state.userType
            }
        }));
    };

    openAnimationsRemoteParticipant = async (participantSid) => {
        const participantAnimationMenuData = Object.assign({}, this.state.room.participantsStickersData[participantSid]);
        participantAnimationMenuData.isOpen = !participantAnimationMenuData.isOpen;
        const participantDiv = document.getElementById(participantSid);
        const animationButton = participantDiv.getElementsByClassName('animation_button_disable')[0];

        this.hideParticipantWindows();

        this.setState(prevState => {
            const roomData = prevState.room;
            roomData.participantsStickersData[participantSid].isOpen = participantAnimationMenuData.isOpen;

            return { room: roomData };
        });

        if (animationButton) {
            animationButton.classList.toggle('animation_button');
            animationButton.classList.toggle('animation_button_disable');
        }

        console.log('openAnimationsRemoteParticipant', this.state.room);
    };

    openGradeParticipant = async (participantSid) => {
        const participantGradeMenuData = Object.assign({}, this.state.room.participantsGradesData[participantSid]);
        participantGradeMenuData.isOpen = !participantGradeMenuData.isOpen;
        const participantDiv = document.getElementById(participantSid);
        const gradeButton = participantDiv.getElementsByClassName('grade_button_disable')[0];

        this.hideParticipantWindows();

        this.setState(prevState => {
            const roomData = prevState.room;
            roomData.participantsGradesData[participantSid].isOpen = participantGradeMenuData.isOpen;

            return { room: roomData };
        });

        const gradeDiv = participantDiv.getElementsByClassName('participant_grades')[0];

        if (gradeButton) {
            gradeButton.classList.toggle('grade_button');
            gradeButton.classList.toggle('grade_button_disable');
        }

        if (!this.state.room.participantsGradesData[participantSid].isOpen) {
            gradeDiv.style.visibility = 'hidden';
        } else {
            gradeDiv.style.visibility = 'visible';
        }
    }

    hideParticipantWindows = () => {
        const roomData = this.state.room;

        for (const participantSid in roomData.participantsStickersData) {
            roomData.participantsStickersData[participantSid].isOpen = false;
            const participantDiv = document.getElementById(participantSid);
            const animationButton = participantDiv.getElementsByClassName('animation_button_disable')[0];

            if (animationButton) {
                animationButton.classList.toggle('animation_button');
                animationButton.classList.toggle('animation_button_disable');
            }
        }

        for (const participantSid in roomData.participantsGradesData) {
            roomData.participantsGradesData[participantSid].isOpen = false;
            const participantDiv = document.getElementById(participantSid);
            const gradeDiv = participantDiv.getElementsByClassName('participant_grades')[0];
            gradeDiv.style.visibility = 'hidden';
            const gradeButton = participantDiv.getElementsByClassName('grade_button_disable')[0];

            if (gradeButton) {
                gradeButton.classList.toggle('grade_button');
                gradeButton.classList.toggle('grade_button_disable');
            }
        }

        this.setState({ room: roomData });
    }

    getBigWindowsCount = (sideNavHeight, teacherAndButtonHeight, defaultVideoHeight,
                          defaultLittleVideoHeight, littleWindowsPerRow, participantsCount) => {
        const STUDENTS_CONTAINERS_PADDING = 6; // px
        const TOP_AND_BOTTOM_PADDING = 30; // px
        const studentsHeight = sideNavHeight - teacherAndButtonHeight -
            (participantsCount * STUDENTS_CONTAINERS_PADDING + TOP_AND_BOTTOM_PADDING);
        const maxBigWindowsCount = Math.trunc(studentsHeight / defaultVideoHeight);
        let bigWindowsCount = maxBigWindowsCount;

        if (maxBigWindowsCount <= participantsCount) {
            for (let i = 0; i < maxBigWindowsCount; i++) {
                const littleWindowsHeight = studentsHeight - bigWindowsCount * defaultVideoHeight;
                const littleWindowHeight = Math.ceil(littleWindowsHeight / Math.ceil((participantsCount - bigWindowsCount) / littleWindowsPerRow));

                if (littleWindowHeight >= defaultLittleVideoHeight) {
                    break;
                }

                bigWindowsCount--;

                if (bigWindowsCount <= 0) {
                    break;
                }
            }
        }

        return bigWindowsCount;
    };

    render() {
        const { navCollapsed, drawerType, width } = this.props;
        const drawerStyle = comparingValuesInSymbol(drawerType, FIXED_DRAWER) ? 'd-xl-flex' : comparingValuesInSymbol(drawerType, COLLAPSED_DRAWER) ? '' : 'd-flex';
        let type = 'permanent';

        // if (comparingValuesInSymbol(drawerType, COLLAPSED_DRAWER) || (comparingValuesInSymbol(drawerType, FIXED_DRAWER) && width < 1200)) {
        //     type = 'temporary';
        // }

        return (
            <div className={`app-sidebar d-none ${drawerStyle}`}>
                <Drawer
                    className="app-sidebar-content"
                    variant={type}
                    open={type.includes('temporary') ? navCollapsed : true}
                    onClose={this.onToggleCollapsedNav}
                    classes={{
                        paper: 'side-nav'
                    }}>
                    {this.props.location.pathname !== '/brand/vl' && <UserInfo />}
                    {this.props.location.pathname !== '/brand/vl' && <SideNavContent />}
                    {this.props.location.pathname === '/brand/vl' && this.renderVideo()}
                </Drawer>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        toggleCollapsedNav,
        updateWindowWidth,
        setLessonIdent,
        openLikes,
        closeLikes,
        participantEmail,
    },
    dispatch
);

const mapStateToProps = ({ settings, likes }) => ({
    navCollapsed: settings.navCollapsed,
    width: settings.width,
    drawerType: settings.drawerType,
    navigationStyle: settings.navigationStyle,
    socket: settings.socket,
    openCloseLikes: likes.openCloseLikes,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNav));
