import lang from '../../../../utils/lang'

const BUY_CLASSES_OBJ = {
    groupLevel1: [
        /** KIDS*/
        {
            isActualAge: true,
            show: true,
            name: 'EpicLang Kids',
            text1: lang('Для детей'),
            text2: lang('(7-10 лет)'),
            iconUrlClass: 'epic_logo_kids',
            ageFrom: 7,
            ageTo: 10,
            groupLevel2: [
                {
                    show: false,
                    text1: lang('Мини-группы'),
                    groupLevel3: []
                },
                {
                    show: true,
                    text1: lang('Индивидуальные занятия'),
                    groupLevel3: [
                        {
                            show: true,
                            text1: '25',
                            text2: lang('минут'),
                            BuyClassesItems: [
                                {
                                    itemId: 13,
                                    itemName: '25__5 Start',
                                    HeaderBig: lang('Стартовый '),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '5',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 25 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('3 000 руб'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('600 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: '',
                                },
                                {
                                    itemId: 14,
                                    itemName: '25__10 Profi',
                                    HeaderBig: lang('Продвинутый'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '10',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 25 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('5 000 руб.'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('500 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 16%'),
                                },
                                {
                                    itemId: 15,
                                    itemName: '25_25 Legend',
                                    HeaderBig: lang('Легендарный'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: lang('Хит'),
                                    CenterHeader: '25',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 25 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('11 250 руб'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('450 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 25%'),
                                },
                                {
                                    itemId: 16,
                                    itemName: '25__50 Epic',
                                    HeaderBig: lang('Эпический'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '50',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 25 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('20 000 руб'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('400 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 33%'),
                                },
                            ]
                        },
                        {
                            show: false,
                            text1: '50',
                            text2: lang('минут'),
                            BuyClassesItems: [

                                {
                                    itemId: 17,
                                    itemName: '50__5 Start',
                                    HeaderBig: lang('Стартовый '),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '5',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 50 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('5 000 руб.'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('1000 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: '',
                                },
                                {
                                    itemId: 18,
                                    itemName: '50__10 Profi',
                                    HeaderBig: lang('Продвинутый'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '10',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 50 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('8 000 руб'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('800 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 20%'),
                                },
                                {
                                    itemId: 19,
                                    itemName: '50__25 Legend',
                                    HeaderBig: lang('Легендарный'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: lang('Хит'),
                                    CenterHeader: '25',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 50 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('18 750 руб.'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('750 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 25%'),
                                },
                                {
                                    itemId: 20,
                                    itemName: '50__50 Epic',
                                    HeaderBig: lang('Эпический'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '50',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 50 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('35 000 руб.'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('700 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 30%'),
                                },
                            ]
                        }
                    ]
                },
            ]
        },
    ]
};

export default BUY_CLASSES_OBJ;
