import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { resetStep1, showAuthLoader, hideAuthLoader, showAuthMessage, hideAuthMessage } from '../actions';
import ResetStep1 from '../components/ResetStep1';

const mapStateToProps = state => ({
    dialog: state.dialog.dialog,
    showMessage: state.auth.showMessage,
    alertTitle: state.auth.alertTitle,
    alertMessage: state.auth.alertMessage,
    loader: state.auth.loader,
});

const mapDispatchToProps = dispatch => 
    bindActionCreators(
        {
            resetStep1,
            showAuthLoader,
            hideAuthLoader,
            showAuthMessage,
            hideAuthMessage
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ResetStep1);