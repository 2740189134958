import React, {useEffect, useState} from "react";

import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';

import TeacherHelpInfo from "./common/TeacherHelpInfo";
import l from '../main/LessonEditor.module.scss';
import lang from "../../../utils/lang";

const TestQuestion = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [isPresentation, setIsPresentation] = useState(options.isPresentation);
    const [isHomework, setIsHomework] = useState(options.isHomework);
    const [isHomeworkFinish, setIsHomeworkFinish] = useState(options.isHomeworkFinish);
    const [homeworkTasksInformation, setHomeworkTasksInformation] = useState(options.homeworkTasksInformation);
    
    const [variants, setVariants] = useState(el.variants||[]);
    const [question, setQuestion] = useState(el.question);
    const [uniqId] = useState(el.uniqId);
    const [fewTasksCompletedMessage, setFewTasksCompletedMessage] = useState(false);
    const [customTitle, setCustomTitle] = useState(el.customTitle);
    const [studentAnswer, setStudentAnswer] = useState(el.studentAnswer||-1);
    const [isSubmit, setIsSubmit] = useState(false);

    const [clue, setClue] = useState([]);
    
    useEffect(() => {
        options.changeElement({variants, question, customTitle, studentAnswer}, i, activeTabIndex);
    }, [variants, question, customTitle, studentAnswer]);

    const checkStudentAnswer = (studentAnswer) => {
        if (isHomework && !isHomeworkFinish) return false;

        let correctAnswerExist = false;
        let isCorrect = true;

        for (let i = 0; i < variants.length; i++) {
            if (variants[i].isCorrect) {
                correctAnswerExist = true;
                break;
            }
        }

        if (correctAnswerExist && typeof studentAnswer === 'number') {
            if (!variants[studentAnswer] || !variants[studentAnswer].isCorrect) {
                isCorrect = false;
            }
        } else if (correctAnswerExist) {
            isCorrect = false;
        }

        return isCorrect;
    };

    const defaultClue =()=>{
        let c = [];
        for (let i = 0; i < variants.length; i++) {
            c[i] = `${question} -> ${variants[i].value}`;
        }
        return c;
    }

    const updateHomeworkTasksInformation = (uniqId, numberOfResponses = 0) => {
        try {
            if (numberOfResponses ) {
                homeworkTasksInformation[uniqId] = {
                    numberOfResponses,
                    currentResponsesCnt: 0,
                };
            } else {
                if (homeworkTasksInformation[uniqId] && (homeworkTasksInformation[uniqId].currentResponsesCnt < homeworkTasksInformation[uniqId].numberOfResponses)) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt++;
                }
            }
        } catch (e) {}
        setHomeworkTasksInformation(homeworkTasksInformation);
        setFewTasksCompletedMessage(false);
    };

    const renderExerciseTitle = () => (
        <>
            {isPresentation ? customTitle :
                <input className="ExerciseContextInput"
                       value={customTitle}
                       onChange={e => {
                           setCustomTitle(e.target.value);
                       }}
                />
            }
        </>
    );

    useEffect(() => {
        if (!homeworkTasksInformation[el.uuid]) {
            updateHomeworkTasksInformation(el.uuid, 1);
        }
        setClue(defaultClue());
    },[])

    const deleteTestQuestionVariant = (elementItemIndex) => {
        if (variants.length <= 1) {
            alert(lang('Cannot delete last variant'));
            return;
        }
        variants.splice(elementItemIndex, 1);
        setVariants([...variants]);
    }

    const addTestQuestionVariant = () => {
        variants.push({isCorrect: false, value: 'Test variant'});
        setVariants([...variants]);        
    }

    return (
        <div className={l.dad2_example}>
            <div>
                <div className={l.wit_exercise_title}>
                    {renderExerciseTitle()}
                </div>
                {isPresentation ? <>
                        {options.userInfo.userInfo.type === 'teacher' && options.demoLesson ?
                            <TeacherHelpInfo info={clue.map((elem) => <div className={l.exercise_clue}>{elem} </div>)} /> : null}
                        <h2 style={{textAlign: 'center'}}>
                            {el.question}
                        </h2>
                    </> :
                    <div className="col-12 dad2_render">
                        <TextField
                            placeholder={""}
                            InputLabelProps={{ shrink: true }}
                            required
                            name="text"
                            value={question}
                            onChange={event => setQuestion(event.target.value)}
                            label={"Question"}
                            fullWidth
                            multiline={false}
                            margin="normal"
                            className="mt-0 mb-4"
                        />
                    </div>
                }
                {variants.map((item, itemIndex) =>
                    <div className={
                        (isPresentation ? (l.test_question_item + ' ') : '') +
                        (((!isHomework || isHomeworkFinish) && typeof studentAnswer === 'number' && itemIndex === studentAnswer && item.isCorrect) ? 'test_question_item_correct' : '') +
                        (((!isHomework || isHomeworkFinish) && typeof studentAnswer === 'number' && itemIndex === studentAnswer && !item.isCorrect) ? 'test_question_item_incorrect' : '') +
                        ((isHomework && !isHomeworkFinish && typeof studentAnswer === 'number' && itemIndex === studentAnswer) ? 'test_question_item_homework' : '')
                    }
                         onClick={isPresentation ? () => {
                             if (!checkStudentAnswer(studentAnswer)) {
                                setStudentAnswer(itemIndex);
                                setIsSubmit(true);
                                

                                const isCorrect = (itemIndex === studentAnswer && item.isCorrect);

                                options.sendMessage({
                                    command: 'sendTestQuestionAnswer',
                                    data: {
                                        activeTabIndex: activeTabIndex,
                                        lessonItemIndex: i,
                                        answer: tabs[activeTabIndex].tabPanel.lessonItems[i].studentAnswer,
                                        isSubmit: true,
                                        isCorrect,
                                    }
                                });

                                updateHomeworkTasksInformation(uuid);

                                if (isCorrect) {
                                    options.updateHomeworkErrors(uuid, -1, true);
                                    const variant = random(studentAnswerSuccessSounds.length);
                                    studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                } else {
                                    options.updateHomeworkErrors(uuid, 1, true);
                                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                }
                             }
                         } : () => {
                            variants.forEach(variant => {
                                 variant.isCorrect = false;
                            })
                            variants[itemIndex].isCorrect = !item.isCorrect;
                            setVariants([...variants]);
                         }}>
                        {isPresentation ?
                            <span>{item.value}</span> :
                            <div className="col-12 dad2_render">
                                <TextField
                                    placeholder={""}
                                    InputLabelProps={{ shrink: true }}
                                    required
                                    name="text"
                                    value={item.value}
                                    onChange={event => {
                                        item.value = event.target.value;
                                        setVariants([...variants]);
                                    }}
                                    label={"Variant"}
                                    fullWidth
                                    multiline={false}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />
                            </div>
                        }
                        <div>
                            {!isPresentation && <>
                                <Button variant='contained'
                                        style={{backgroundColor: isPresentation ? '' : (item.isCorrect ? 'green' : '')}}
                                >
                                    {isPresentation ? lang('Select') : item.isCorrect ? lang('Correct') : lang('Incorrect')}
                                </Button>
                                <Button variant='contained' color='primary'
                                        onClick={() => deleteTestQuestionVariant(itemIndex)}
                                >Delete variant</Button>
                            </>}
                        </div>
                    </div>
                )}
                {!isPresentation &&
                    <div className={l.WIT_button_center}>
                        <Button variant='contained' color='primary'
                                onClick={() => { addTestQuestionVariant() }}
                        >Add variant</Button>
                    </div>
                }
            </div>
        </div>
    );
}

export default TestQuestion;