import React, { useState, useEffect} from 'react';
import { Button } from '@mui/material';
import "./ObjectDialog.scss";

const YesNoDialog=({title, onOk, onCansel})=>{
    const [text, setText] = useState(title);

    useEffect(() => {
      setText(title)  
    }, [title]);

    return (<>
        <div className='object-dialog-contener'>
            <div className='object-dialog'>
                <div className='object-title'>{text}</div>
                <div >
                    <Button onClick={onOk}>OK</Button>
                    <Button onClick={onCansel}>Cansel</Button>
                </div>
            </div>
        </div>
    </>);
}


export default YesNoDialog;