import React from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Homework.scss';
import ModalCloseButton from '../closeButton/ModalCloseButton';
import Classes from '../../Student/Classes';
import history from '../../../../utils/history';
import mark_30 from './imgs/30.png';
import mark_50 from './imgs/50.png';
import mark_65 from './imgs/65.png';
import mark_80 from './imgs/80.png';
import mark_95 from './imgs/95.png';
import mark_100 from './imgs/100.png';
import callApi from '../../../../utils/call-api';
import LessonEditor from '../../../lessonEditor/main/LessonEditor';
import {hideCounter, visibleCounter} from '../../../../actions';
import {Declination} from '../../../Declination';
import IntlMessages from '../../../../utils/IntlMessages';
import lang from '../../../../utils/lang';

const HOME_WORK_PAGE = 'homeWorkPage';
const LESSON_EDITOR_TIME = 300;

const getMarkInfo = markVal => {
    let markImg;
    let markText;

    switch (true) {
        case (markVal < 100 && markVal >= 95):
            markImg = mark_95;
            markText = lang('ОТЛИЧНО');
            break;
        case (markVal < 95 && markVal >= 80):
            markImg = mark_80;
            markText = lang('ХОРОШО');
            break;
        case (markVal < 80 && markVal >= 65):
            markImg = mark_65;
            markText = lang('СРЕДНЕНЬКО');
            break;
        case (markVal < 65 && markVal >= 50):
            markImg = mark_50;
            markText = lang('ПЛОХО');
            break;
        case (markVal < 50):
            markImg = mark_30;
            markText = lang('УЖАСНО');
            break;
        default:
            markImg = mark_100;
            markText = lang('ЭПИЧНО');
            break;
    }

    return {
        markImg,
        markText,
    };
}

const HOMEWORK_ENOUGH_ANSWERS = 0.9;

class Homework extends React.Component {
    constructor(props) {
        super(props);

        global.soundsVolume = 0.0;

        this.init();
    }

    init = () => {
        this.state = {
            homeworkStudentMark: -1,
            studentErrors: -1,
            isShowLessonEditor: false,
            lessonEditor: false,
            vLInformation: null,
            lessonData: null,
            homeworkDataIncomplete: this.props.homeworkDataIncomplete,
            homeworkDataComplete: this.props.homeworkDataComplete,
            isFirstShow: 0, // не позволяет рендерить, пока не нажато первый раз на кнопку в меню для показа календаря
        }
    }

    onMadeHomeworkClick = vLInformation => {
        this.setState({
            isShowLessonEditor: false,
            vLInformation: null,
        });

        callApi('dashboard/getLessons', 'POST', {
            lessonId: vLInformation.homeworkId,
        }).then(res => {
            if (res && res.data.ok) {
                this.setState({
                    lessonData: res.data.lessonData,
                    isShowLessonEditor: true,
                    vLInformation,
                });
                setTimeout(() => {
                    this.setState({
                        lessonEditor: true,
                    });
                }, LESSON_EDITOR_TIME)
            } else {
                _(res.data.message);
            }
        }).catch(e => {
            _(e);
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isShowHomework !== this.props.isShowHomework && !this.state.isFirstShow) {
            this.setState({ isFirstShow: true });
        }

        if (prevProps.homeworkDataIncomplete.length !== this.props.homeworkDataIncomplete.length) {
            this.setState({ homeworkDataIncomplete: this.props.homeworkDataIncomplete });
        }

        if (prevProps.homeworkDataComplete.length !== this.props.homeworkDataComplete.length) {
            this.setState({ homeworkDataComplete: this.props.homeworkDataComplete });
        }
    }

    showHideCounter() {
        if (this.props.showCounter) {
            this.props.hideCounter();
        } else {
            this.props.visibleCounter();
        }
    }

    renderHomeworkHeader = () => {
        const { homeworkDataIncomplete, homeworkDataComplete } = this.state;

        const totalAssignedHomeworks = homeworkDataIncomplete.length + homeworkDataComplete.length;
        let completedBy100Points = 0;

        homeworkDataComplete.forEach(el => {
            if (el.homeworkStudentMark === 100) {
                completedBy100Points++;
            }
        });

        return (
            <div className="HomeworkHeader">
                <div className="stat-left-block">
                    <div className="stat-left-block-header">
                        <IntlMessages id="Всего вам было" /><br />
                        <IntlMessages id="назначено" />
                    </div>
                    <div className="stat-left-block-num">
                        {totalAssignedHomeworks}
                    </div>
                    <div className="stat-left-block-footer">
                        {lang(Declination(totalAssignedHomeworks, HOME_WORK_PAGE))}
                    </div>
                </div>
                <div className="stat-left-block">
                    <div className="stat-left-block-header">
                        <IntlMessages id="Вами" /><br />
                        <IntlMessages id="выполнено" />
                    </div>
                    <div className="stat-left-block-num">
                        {homeworkDataComplete.length}
                    </div>
                    <div className="stat-left-block-footer">
                        <IntlMessages id="домашних работ" />
                    </div>
                </div>
                <div className="stat-left-block">
                    <div className="stat-left-block-header">
                        <IntlMessages id="На 100 баллов" /><br />
                        <IntlMessages id="вы выполнили" />
                    </div>
                    <div className="stat-left-block-num">
                        {completedBy100Points}
                    </div>
                    <div className="stat-left-block-footer">
                        <IntlMessages id="домашних работ" />
                    </div>
                </div>
                <div className="stat-left-block">
                    <div className="stat-left-block-header">
                        <IntlMessages id="Счётчик" /><br />
                        <IntlMessages id="невыполненных" />
                    </div>

                        <div>
                            <div className="onoffswitch">
                                <input type="checkbox"
                                       name="onoffswitch"
                                       className="onoffswitch-checkbox"
                                       id="myonoffswitch"
                                       defaultChecked={this.props.showCounter}
                                       onClick={()=> this.showHideCounter()}
                                       tabIndex="0" />
                                <label className="onoffswitch-label" htmlFor="myonoffswitch">
                                    <span className="onoffswitch-inner"></span>
                                    <span className="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>

                    <div className="stat-left-block-footer">
                        <IntlMessages id="домашних работ" />
                    </div>
                </div>
            </div>
        );
    }

    homeworkOnButtonOk = (homeworkTasksInformation, homeworkErrors) => {
        const { vLInformation, homeworkDataIncomplete, homeworkDataComplete } = this.state;
        const { userId } = this.props;

        try {
            let allTasksCnt = 0;
            let executedTasksCnt = 0;
            let errorsCnt = 0;

            for (const [key, value] of Object.entries(homeworkTasksInformation)) {
                allTasksCnt += value.numberOfResponses;
                executedTasksCnt += value.currentResponsesCnt;
                errorsCnt += (typeof homeworkErrors[key] === 'number' ?
                    (homeworkErrors[key] <= value.currentResponsesCnt ? homeworkErrors[key] : value.currentResponsesCnt)
                    : 0);
            }

            const truAnswers = executedTasksCnt - errorsCnt;
            const homeworkStudentMark = allTasksCnt === 0 ? 100: Math.round((truAnswers * 100) / allTasksCnt);

            let { homeworkStudentsMarks } = vLInformation;

            if (typeof homeworkStudentsMarks !== 'object') {
                homeworkStudentsMarks = { students: [] };
            } else if (!Array.isArray(homeworkStudentsMarks.students)) {
                homeworkStudentsMarks.students = [];
            }

            const studentMarkIndex = homeworkStudentsMarks.students.findIndex(el => (el.id === userId && el.homeworkId === vLInformation.homeworkId));

            if (studentMarkIndex === -1) {
                homeworkStudentsMarks.students.push({
                    id: userId,
                    mark: homeworkStudentMark,
                    homeworkId: vLInformation.homeworkId,
                });
            } else {
                homeworkStudentsMarks.students[studentMarkIndex].mark = homeworkStudentMark;
            }

            const homeworkDataIncompleteCurrentIndex = homeworkDataIncomplete.findIndex(el => el.id === vLInformation.id);

            if (homeworkDataIncompleteCurrentIndex > -1) {
                const homeworkCompleteRow = homeworkDataIncomplete[homeworkDataIncompleteCurrentIndex];
                homeworkCompleteRow.homeworkStudentsMarks = homeworkStudentsMarks;
                homeworkCompleteRow.homeworkStudentMark = homeworkStudentMark;

                homeworkDataComplete.unshift(homeworkCompleteRow);
                homeworkDataIncomplete.splice(homeworkDataIncompleteCurrentIndex, 1);
            }

            this.setState({
                homeworkStudentMark,
                studentErrors: errorsCnt,
            });

            callApi('dashboard/updateVlHomeworkInfo', 'POST', {
                homeworkStudentsMarks,
                id: vLInformation.id,
            }).then(res => {
                if (res && res.data.ok) {
                    // if (typeof this.props.updateStudent === 'function') {
                    //     this.props.updateStudent();
                    // }
                } else {
                    _(res.data.message);
                }
            }).catch(e => {
                _(e);
            });

        } catch (e) {}
    };

    renderHomeworkStudentMark = () => {
        const { homeworkStudentMark, studentErrors } = this.state;
        const { markImg, markText } = getMarkInfo(homeworkStudentMark);

        return (
            <>
                {(homeworkStudentMark >= 0) &&
                    <div className="HomeworkContentMark">
                        <div className="HomeworkContentMarkImg">
                            <img src={markImg} alt=''/>
                        </div>
                        <div className="HomeworkContentMarkText">
                            <div>{markText}!</div>
                            <div>{lang("Вы допустили ошибок:")} {studentErrors}</div>
                            <div>{lang("Вы набрали баллов:")} {homeworkStudentMark} {lang("из 100")}</div>
                        </div>
                    </div>
                }
            </>
        )
    };

    render() {
        const { isShowHomework } = this.props;
        const { isFirstShow, isShowLessonEditor, lessonData , lessonEditor} = this.state;

        return (
            <div className="CustomJumpModal CustomJumpModalHomework HomeworkContainer">
                <div className={'CustomJumpModalBody HomeworkContainerBody HomeworkContainerBodyHeight' +
                    (isShowHomework ? ' CustomJumpModalBodyShow' : '')}
                     style={{ display: (isFirstShow ? 'flex' : 'none') }}
                >
                    <ModalCloseButton
                        closeModal={this.props.closeModal}
                    />
                    <div className="HomeworkContent">
                        <div className={lessonEditor ? 'HomeworkContentContainer customsMarginOn'  :
                            'HomeworkContentContainer'} >
                            {
                                <div className='HomeworkContainerClasses'>
                                    {this.renderHomeworkHeader()}
                                    <div className="schedule">
                                        <Classes
                                            updateStudent={() => {
                                                history.push(global.authUrl);
                                            }}
                                            conductedLessons={this.state.homeworkDataIncomplete}
                                            isOpen
                                            isIncompleteHomework
                                            lessonsCount={this.state.homeworkDataIncomplete.length}
                                            isForHomework
                                            getMarkInfo={getMarkInfo}
                                            onMadeHomeworkClick={this.onMadeHomeworkClick}
                                        />
                                        <Classes
                                            updateStudent={() => {
                                                history.push(global.authUrl);
                                            }}
                                            conductedLessons={this.state.homeworkDataComplete}
                                            isOpen={false}
                                            lessonsCount={this.state.homeworkDataComplete.length}
                                            isForHomework
                                            getMarkInfo={getMarkInfo}
                                            onMadeHomeworkClick={this.onMadeHomeworkClick}
                                        />
                                    </div>
                                </div>
                            }

                            {isShowLessonEditor &&
                                <div>
                                    <div className="homeworkBackButton"
                                         onClick={() => {
                                             this.setState({
                                                 lessonData: null,
                                                 lessonEditor: false,
                                                 homeworkStudentMark: -1,
                                                 studentErrors: -1,
                                             });
                                             setTimeout(() => {
                                                 this.setState({
                                                     isShowLessonEditor: false,
                                                 });
                                             }, LESSON_EDITOR_TIME)
                                         }
                                             }
                                    >{lang("Назад")}</div>
                                    <LessonEditor
                                        virtualClassId={null}
                                        lesson={lessonData}
                                        coursesRaw={[]}
                                        lessonType="presentation"
                                        socketMessage={{}}
                                        whiteboard={this.props.whiteboard}
                                        isHomework
                                        homeworkOnButtonOk={this.homeworkOnButtonOk}
                                        homeworkEnoughAnswers={HOMEWORK_ENOUGH_ANSWERS}
                                        renderHomeworkStudentMark={this.renderHomeworkStudentMark}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        visibleCounter,
        hideCounter,
    },
    dispatch
);

const mapStateToProps = state => ({
    whiteboard: state.dialog.whiteboard,
    showCounter: state.homeWork.showCounter,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Homework));
