import React from "react";
import s from "./../SuperAdmin.module.css";
import Navlink from "react-router-dom/NavLink";
import {Route} from "react-router-dom";
import GroupsTab from "./GroupsTab";
import StudentsTab from "./StudentsTab";
import callApi from "../../../../utils/call-api";
import config from "../../../../config";
import Folders from "../Folders/Folders";
import Select from "react-select";
import {connect} from "react-redux";

class Students extends React.Component {
    state = {
        ...this.initTogglesState,
        filterForSort: {'': ''},
        test: {test: 'test'},
        addGroup: false,
        addStudent: false,
        search: '',
        isActiveFolders: false,
        getGroupsRequestData: {
            skip: config.defaultSkipValue,
            limit: config.defaultLimitValue,
            isFirstPage: true,
            isLastPage: false
        },
        getUsersRequestData: {
            skip: config.defaultSkipValue,
            limit: config.defaultLimitValue,
            isFirstPage: true,
            isLastPage: false
        },
        foldersList: this.props.foldersData,
        selectedFolders: [],
        onFocus: false,
    };

    async componentDidMount() {
        await Promise.all([
            this.getUsersData(this.state.getUsersRequestData),
            this.getGroupsData(this.state.getGroupsRequestData)
        ]);
    }

    getUsersData = async (requestOptions) => {
        const data = [];
        const getUsersRequestData = {};
        let students = [];
        let supervisorsStudents = [];
        let studentsCourses = [];
        let courses = [];
        let teachers = [];
        let supervisors = [];
        let demos = [];
        let groups = [];

        await Promise.all([
            callApi(
                'dashboard/getUsers',
                'GET',
                requestOptions || this.state.getUsersRequestData
            ).then(res => {

                if (res && res.data.ok) {
                    students = res.data.students.rows;
                    teachers = res.data.teachers.rows;
                    supervisors = res.data.supervisors.rows;
                    demos = res.data.demos.rows;

                    if (typeof this.skipValidate(requestOptions.skip) === 'number') {
                        getUsersRequestData.skip = requestOptions.skip;
                        getUsersRequestData.isFirstPage = requestOptions.skip === config.defaultSkipValue;
                    }

                    getUsersRequestData.limit = this.limitValidate(requestOptions.limit);
                    getUsersRequestData.isLastPage =
                        groups.length < getUsersRequestData.limit ||
                        groups.length < this.state.getGroupsRequestData.limit;
                }
            }).catch(err => {
            }),
            callApi(
                'dashboard/getGroups',
                'POST',
                this.state.getGroupsRequestData
            ).then(res => {

                if (res && res.data.ok) {
                    groups = res.data.groups;
                }
            }).catch(() => {}),
            callApi('dashboard/getCourses', 'POST').then(res => {

                if (res && res.data.ok) {
                    courses = res.data.courses;
                }
            }).catch(() => {}),
            callApi('dashboard/getSupervisorsUsers', 'POST').then(res => {

                if (res && res.data.ok) {
                    supervisorsStudents = res.data.supervisorsUsers;
                }
            }).catch(() => {}),
            callApi('dashboard/getUsersCourses', 'get').then(res => {

                if (res && res.data.ok) {
                    studentsCourses = res.data.usersCourses;
                }
            }).catch(() => {})
        ]);

        students.forEach(student => {
            const studentInfo = {
                name: student.name,
                surname: student.surname,
                mail: student.email,
                phone1: student.phoneNumber,
                phone2: student.phoneNumber,
                id: student.id,
                courses: {active: [], inactive: []},
                supervisors: {active: [], inactive: []},
                groups: {active: [], inactive: []},
                date: this.getDateFunc(student.creationDate),
                isActivatedByAdmin: student.isActivatedByAdmin,
            };

            groups.forEach(gr => {
                gr.students.active.forEach(st => {
                    if (st.id === student.id) {
                        studentInfo.groups.active.push(gr.name)
                    }
                });
            });
            studentsCourses.forEach(sc => {
                if (sc.userId === student.id) {
                    const course = courses.find(c => c.id === sc.courseId);

                    if (course) {
                        studentInfo.courses.active.push(`Name: ${course.name} type: ${course.type}`);
                    }
                }
            });
            supervisorsStudents.forEach(ss => {

                if (ss.userId === student.id) {
                    const supervisor = supervisors.find(c => c.id === ss.supervisorId);

                    if (supervisor) {
                        studentInfo.supervisors.active.push(`${supervisor.name} [${supervisor.email}] `);
                    }
                }
            });
            data.push(studentInfo);
        });

        this.setState((prevState) => {

            return {
                studentsByCourseList: {
                    activeCourses: { all: data.filter(el => !!el.isActivatedByAdmin) },
                    inactiveCourses: { all: data.filter(el => !el.isActivatedByAdmin) }
                },
                supervisors,
                courses,
                students,
                teachers,
                demos,
                getUsersRequestData: {
                    ...prevState.getUsersRequestData,
                    ...getUsersRequestData
                }
            }
        });
    }

    getGroupsData = async (requestOptions) => {
        const gData = [];
        const getGroupsRequestData = {};
        let groups = [];

        await callApi(
            'dashboard/getGroups',
            'POST',
            requestOptions || this.state.getGroupsRequestData
        ).then(res => {

            if (res && res.data.ok) {
                groups = res.data.groups;

                if (typeof this.skipValidate(requestOptions.skip) === 'number') {
                    getGroupsRequestData.skip = requestOptions.skip;
                    getGroupsRequestData.isFirstPage = requestOptions.skip === config.defaultSkipValue;
                }

                getGroupsRequestData.limit = this.limitValidate(requestOptions.limit);
                getGroupsRequestData.isLastPage =
                    groups.length < getGroupsRequestData.limit ||
                    groups.length < this.state.getGroupsRequestData.limit;
            }
        }).catch(err => {
        });

        groups.forEach(group => {
            gData.push({
                id: group.id,
                name: group.name,
                date: this.getDateFunc(group.creationDate),
                courseId: group.courseId,
                teachers: group.teachers,
                students: group.students
            });
        });
        this.setState((prevState) => {
            // console.log(requestOptions);
            // console.log(getGroupsRequestData)
            return {
                studentsGroups: {all: gData},
                groups,
                getGroupsRequestData: {
                    ...prevState.getGroupsRequestData,
                    ...getGroupsRequestData
                }
            }
        });
    }

    getDateFunc = (timestamp) => {
        const t = (!!timestamp) ? new Date(timestamp * 1000) : new Date();
        let dd = t.getDate();

        if (dd < 10) {
            dd = '0' + dd
        }

        let mm = t.getMonth() + 1;

        if (mm < 10) {
            mm = '0' + mm
        }

        return dd + '.' + mm + '.' + t.getFullYear();
    }

    skipValidate = (skip) => {

        if (typeof skip !== 'number' || !Number.isInteger(skip) || skip < 0) {

            return false;
        }

        return skip;
    }

    limitValidate = (limit) => {

        if (typeof limit === 'number' && Number.isInteger(limit)) {

            if (limit <= 0) {
                limit = config.defaultLimitValue;
            } else if (limit > config.maxLimitValue) {
                limit = config.maxLimitValue;
            }
        } else {
            limit = config.defaultLimitValue;
        }

        return limit;
    }

    // searching
    handleSearchSubmit = (event) => {
        this.setState({search: event.target.value}, () => {
            console.log(this.state.search)
        });
        event.preventDefault();
    };

    ClearSearch = () => {
        this.setState({search: ''});
    };

    //TOGGLES
    ToggleDropdown = () => {
        this.setState({isActiveDropdown: !this.state.isActiveDropdown})
    };

    UpdateFromTabs = (urlStatus, urlComponent = 'all') => {
        this.setState({urlStatus: urlStatus});
        this.setState({urlComponent: urlComponent});
    };

    UpdateTest = (value) => {
        this.setState({test: value})
        console.log(this.state.test)
    };

    UpdateFilterForSort = (filterForSort) => {
        this.setState({filterForSort: filterForSort});
        this.ToggleDropdown();
    };

    FoldersOpenClose = () => {
        this.setState({isActiveFolders: !this.state.isActiveFolders})
    }

    render() {

        return (
            <div className={s.content_admin}>
                <div className={s.instrument_bar}>
                    <div className={s.dropdown} onClick={this.ToggleDropdown}>
                        <div className={s.dropdown_toggle}>
                            SORT BY
                        </div>
                        <div
                            className={this.state.isActiveDropdown !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}>
                        </div>
                    </div>
                    <div className={s.dropdown} onClick={this.FoldersOpenClose}>
                        <div className={s.dropdown_toggle  + ' ' + s.folders_button}>
                            FOLDERS
                        </div>
                    </div>
                    <Folders
                        isActive={this.state.isActiveFolders}
                        openClose={this.FoldersOpenClose}
                        from={'students'}
                        studentsByCourseList={this.state.studentsByCourseList}
                    />
                    <div
                        className={this.state.isActiveDropdown === true ? s.dropdown_visible_content : s.dropdown_hidden_content}>
                        <div className={s.dropdown_link} onClick={() => this.UpdateFilterForSort('name')}>
                            Name
                        </div>
                        <div className={s.dropdown_link} onClick={() => this.UpdateFilterForSort('date')}>
                            Date added
                        </div>
                        <div className={s.dropdown_link} onClick={() => this.UpdateFilterForSort('courses')}>
                            Number of active courses
                        </div>
                    </div>
                    <div className={s.right_block_instrument_bar}>
                        <Navlink to="/admin/superadmin/students/groups_tab">
                            <div
                                className={s.invite_admin_btn}
                                onClick={() => {
                                    this.setState({addGroup: !this.state.addGroup});
                                }}
                            >
                                <div className={s.add_new_group}></div>
                                <div className={s.invite_admin_btn_txt}>
                                    Create new group
                                </div>
                            </div>
                        </Navlink>
                        <Navlink to="/admin/superadmin/students/students_tab/active_courses">
                            <div
                                className={s.invite_admin_btn} onClick={() => {
                                this.setState({addStudent: !this.state.addStudent});
                            }}
                            >
                                <div className={s.add_icon}></div>
                                <div className={s.invite_admin_btn_txt}>Invite new student</div>
                            </div>
                        </Navlink>
                        <div className={s.search_btn}>
                            <div className={s.search_icon}></div>
                            <div className={s.search_input}>
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    value={this.state.search}
                                    onChange={this.handleSearchSubmit}
                                />
                            </div>
                            <div
                                className={s.search_btn_txt}
                                onClick={() => {
                                    this.setState({search: ''}, () => {
                                        this.ClearSearch()
                                    })
                                }}
                            >
                                x
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.students_select}>
                    <Select
                        isMulti
                        onChange={e => this.setState({...this.state, selectedFolders: e})}
                        name="students"
                        options={this.state.foldersList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
                <div className={s.tabs_menu + ' ' + s.tabs_menu_width}>
                    <Navlink
                        to="/admin/superadmin/students/students_tab"
                        activeClassName={s.active_tabs}
                        className={s.nav_tabs}
                    >
                        Students
                    </Navlink>
                    <Navlink
                        to="/admin/superadmin/students/groups_tab"
                        activeClassName={s.active_tabs}
                        className={s.nav_tabs}
                    >
                        Groups
                    </Navlink>
                </div>
                <Route
                    path="/admin/superadmin/students/students_tab"
                    render={() =>
                        <StudentsTab
                            filterForSort={this.state.filterForSort}
                            addStudent={this.state.addStudent}
                            search={this.state.search}
                            studentsByCourseList={this.state.studentsByCourseList}
                            supervisors={this.state.supervisors}
                            demos={this.state.demos}
                            getUsersRequestData={this.state.getUsersRequestData}
                            getUsersData={this.getUsersData}
                            selectedFolders={this.state.selectedFolders || []}
                        />
                    }
                />
                <Route
                    path="/admin/superadmin/students/groups_tab"
                    render={() =>
                        <GroupsTab
                            UpdateTest={this.UpdateTest}
                            filterForSort={this.state.filterForSort}
                            addGroup={this.state.addGroup}
                            search={this.state.search}
                            groups={this.state.groups}
                            courses={this.state.courses}
                            studentsGroups={this.state.studentsGroups}
                            teachers={this.state.teachers}
                            students={this.state.students}
                            getGroupsRequestData={this.state.getGroupsRequestData}
                            getGroupsData={this.getGroupsData}
                        />
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = ({ folders }) => ({
    foldersData: folders.foldersData,
});

export default connect(mapStateToProps)(Students);
