export const PHONE_REGEXP = /^[0-9+]{1,32}$/;

export function validateEmail(email) {
    if (typeof email !== 'string') return false;

    if (email.length < 5) return false;

    if (!email.includes('@')) return false;

    if (!email.includes('.')) return false;

    return true;
}

export function validatePhone(phone) {
    if (!PHONE_REGEXP.test(phone)) return false;

    if (phone.length > 30) return false;

    return true;
}