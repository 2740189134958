import React, {useEffect, useState} from "react";

import { Editor, EditorState, ContentState } from 'draft-js';
import Button from '@material-ui/core/Button/Button';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';


import l from '../main/LessonEditor.module.scss';
import { studentAnswerWrongAudio, studentAnswerSuccessSounds } from '../helpers/SoundsHelper';
import TeacherHelpInfo from "./common/TeacherHelpInfo";
import random from '../../../utils/generator/numbers';
import { set } from "immutable";


const dropdownMenuProps = {
    padding: { padding: '0' },
    disablePadding: true,
    getContentAnchorEl: null,
    anchorOrigin: { vertical: "bottom", horizontal: "left" },
};

const Wit = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [name, setName] = useState(el.name);
    const [textArr, setTextArr] = useState(el.textArr);
    const [wordsArr, setWordsArr] = useState(el.wordsArr);
    const [isRenderCode, setIsRenderCode] = useState(el.isRenderCode);
    const [clue, setClue] = useState(el.clue);
    const [customTitle, setCustomTitle] = useState(el.customTitle);
    const [editorState, setEditorState] = useState(el.editorState);
    const [currentVal, setCurrentValue] = useState(el.currentVal);
    const [uniqId, setUniqId] = useState(el.uniqId);
    
    const [isHomework, setIsHomework] = useState(options.isHomework); 
    const [isPresentation, setIsPresentation] = useState(options.isPresentation);
    const [isHomeworkFinish, setIsHomeworkFinish] = useState(options.isHomeworkFinish);
    const [homeworkTasksInformation, setHomeworkTasksInformation] = useState(options.homeworkTasksInformation);
    const [fewTasksCompletedMessage, setFewTasksCompletedMessage] = useState(options.fewTasksCompletedMessage);

    useEffect(() => {
        options.changeElement({editorState, currentVal, isRenderCode, textArr, wordsArr, clue, customTitle}, i, activeTabIndex);
    }, [textArr, wordsArr, isRenderCode, clue, customTitle, editorState, currentVal]);

    useEffect(() => {
        if (socketMessage&& socketMessage.data.uniqId === uniqId) {
            if (socketMessage.command === 'handleChangeEditor') {
                let val = JSON.stringify(socketMessage.data.currentVal || currentVal).replaceAll('u0001', 'n');
                    val = JSON.parse(val) || '';
                    setCurrentVal(val);
                    setEditorState(ContentState.createFromText(val));
            }
            if (socketMessage.command === 'handleWig') {
                wordsArr[socketMessage.data.index].className = socketMessage.data.className;
                wordsArr[socketMessage.data.index].val = socketMessage.data.val;
                setWordsArr([...wordsArr]);
                if (socketMessage.data.className === l.WIT_successful) {
                    const variant = random(studentAnswerSuccessSounds.length);
                    studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                } else {
                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                }
            }
        }
    },[socketMessage]);

    const updateHomeworkTasksInformation = (uniqId, numberOfResponses = 0) => {
        try {
            if (numberOfResponses ) {
                homeworkTasksInformation[uniqId] = {
                    numberOfResponses,
                    currentResponsesCnt: 0,
                };
            } else {
                if (homeworkTasksInformation[uniqId] && (homeworkTasksInformation[uniqId].currentResponsesCnt < homeworkTasksInformation[uniqId].numberOfResponses)) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt++;
                }
            }
        } catch (e) {}

        setHomeworkTasksInformation(homeworkTasksInformation);
        setFewTasksCompletedMessage(false);
    };

    const onChangeEditor = (editorState) => {
        setEditorState(editorState);
        setCurrentValue(editorState.getCurrentContent().getPlainText('\u0001'));
        options.sendMessage({
            command: 'handleChangeEditor',
            data: {
                uniqId,
                i,
                currentVal: currentVal,
            }
        });
    };

    const okHandlerWIG = () => {
        let text = editorState.getCurrentContent().getPlainText('\u0001').split('[');
        let textArr = [], wordsArr = [];
        let clue = [];
        let answerVar = '';

        if (name === 'WIG') {
            answerVar = 'text';
        } else if (name === 'WIT' || name === 'CWF') {
            answerVar = 'rightWord';
        }

        text.forEach((item, i) => {
            if (item.includes(']')) {
                item = item.split(']');
                textArr.push(item[1]);

                const infinitiveStart = item[0].indexOf('('), infinitiveEnd = item[0].indexOf(')');

                let infinitive = '';
                if (infinitiveStart !== -1 && infinitiveEnd !== -1) {
                    infinitive = item[0].substring(infinitiveStart + 1, infinitiveEnd) + ';';
                    item[0] = item[0].substring(infinitiveEnd + 2, item[0].length).trim();
                }

                let rightWord = '';
                item[0].split(';').forEach(elem => {
                    if (elem[0] === '{' && elem[elem.length - 1] === '}') {
                        rightWord = elem.replace('}', '').replace('{', '');
                    }
                });

                item[0] = item[0].replace('}', '').replace('{', '');
                wordsArr.push({
                    text: infinitive.trim() + item[0],
                    className: '',
                    val: infinitive.replace(';', ''),
                    rightWord
                });
            } else {
                if (item.includes('<')) {
                    item = item.split('<');
                    textArr.push(item[0]);
                } else {
                    textArr.push(item);
                }
            }
        });

        for (let i = 0; i < wordsArr.length; i++) {
            clue[i] = `${i + 1} -> ${wordsArr[i][answerVar]}`;
        }

        setIsRenderCode(true);
        setTextArr(textArr);
        setWordsArr(wordsArr);
        //setClassName('');
        setClue(clue);
    };

    const renderExerciseTitle = () => (
        <>
            {isPresentation ? customTitle :
                <input className="ExerciseContextInput"
                       value={customTitle}
                       onChange={e => {
                            setCustomTitle(e.target.value);
                       }}
                />
            }
        </>
    );

    const prepareLineBreakText = text => {
        const elems = text.split('\u0001');

        if (elems.length <= 1) {
            return text;
        }

        let res = '';

        for (let i = 0; i < elems.length; i++) {
            if (i < elems.length - 1) {
                res = res + elems[i] + '<br />';
            } else {
                res = res + elems[i];
            }
        }

        return (
            <span dangerouslySetInnerHTML={{ __html: res }} />
        );
    }
    


    if (!homeworkTasksInformation[uniqId] && Array.isArray(wordsArr)) {
        updateHomeworkTasksInformation(uniqId, wordsArr.length);
    }

    return (
        <div key={`tab_${activeTabIndex}_item_${i}`}>
            {!isPresentation &&
                <div className={l.WIT_formlesson}>
                    <Editor
                        editorState={editorState}
                        onChange={onChangeEditor}
                        placeholder="I [{know};knows;knew] it"
                    />
                    <div className={l.WIT_button}>
                        <Button onClick={() => okHandlerWIG()} color="primary">Ok</Button>
                    </div>
                </div>}

            {isRenderCode && <div className={l.WIT_example}>
                <div className={l.wit_exercise_title}>
                    {renderExerciseTitle()}
                </div>
                {(options.userInfo.userInfo.type === 'teacher' && clue && options.demoLesson) ?
                    <TeacherHelpInfo info={clue.map(elem => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
                <div className={l.wit_body}>
                    {textArr.map((elem, index) => {
                        return (
                            <React.Fragment key={elem} >
                                {prepareLineBreakText(elem)}
                                {wordsArr[index] &&
                                    <div className={((!isHomework || isHomeworkFinish) ? wordsArr[index].className : '') +
                                        ' ' + l.WIT_selected_data  + ' ' + l.WIT_Input}>
                                        <Select
                                            disabled={(!isHomework || isHomeworkFinish) && (wordsArr[index].className === l.WIT_successful)}
                                            disableUnderline
                                            MenuProps={dropdownMenuProps}
                                            value={wordsArr[index].val}
                                            onChange={event => {
                                                const className = (event.target.value === wordsArr[index].rightWord) ?
                                                    l.WIT_successful : l.WIT_error;

                                                if (!wordsArr[index].className) {
                                                    updateHomeworkTasksInformation(uniqId);
                                                }

                                                if (!wordsArr[index].className || wordsArr[index].className === l.WIT_successful) {

                                                    if (className === l.WIT_error) {
                                                        options.updateHomeworkErrors(uniqId, 1);
                                                    }
                                                }

                                                if (wordsArr[index].className === l.WIT_error) {
                                                    if (className === l.WIT_successful) {
                                                        options.updateHomeworkErrors(uniqId, -1);
                                                    }
                                                }

                                                wordsArr[index].className = className;
                                                wordsArr[index].val = event.target.value;

                                                setWordsArr([...wordsArr]);
                                                const message = {command: 'handleWIT',
                                                    data: {
                                                        uniqId,
                                                        i,
                                                        className,
                                                        val: event.target.value,
                                                        index
                                                    }
                                                };
                                                options.sendMessage(message);

                                                if (className === l.WIT_successful) {
                                                    const variant = random(studentAnswerSuccessSounds.length);
                                                    studentAnswerSuccessSounds[variant].play(); 
                                                    studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                                } else {
                                                    studentAnswerWrongAudio.play(); 
                                                    studentAnswerWrongAudio.volume = global.soundsVolume;
                                                }
                                            }}
                                            input={(<Input />)}
                                        >
                                            {wordsArr[index].text.split(';').map(item => (
                                                <MenuItem key={item} value={item}> {item} </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                }
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>}
        </div>
    );
}

export default Wit;
