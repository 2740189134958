import React from 'react';
import PropTypes from 'prop-types';
import {Switch, Route, Redirect} from 'react-router-dom';
import asyncComponent from '../../../utils/asyncComponent';

const Router = props => {
    const {path} = props.match;
    return (
        <Switch>
            <Route exact path={`${path}`} component={asyncComponent(() => import('./Container'))}/>
            <Redirect to={`${path}`} push/>
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.object
};

export default Router;
