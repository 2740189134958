import React, {useEffect, useState} from "react";
import Carousel from 'react-elastic-carousel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';

import TeacherHelpInfo from "./common/TeacherHelpInfo";
import l from '../main/LessonEditor.module.scss';


const renderTrueLies = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [isPresentation] = useState(options.isPresentation);
    const [uniqId] = useState(el.uniqId);
    
    const [items ,setItems] = useState(el.items||[]);
    const [isHomework] = useState(options.isHomework);
    const [isHomeworkFinish] = useState(options.isHomeworkFinish);
    const [homeworkTasksInformation, setHomeworkTasksInformation] = useState(options.homeworkTasksInformation);
    const [clue, setClue] =useState(()=>{
        let c = [];
        for (let i = 0; i < items.length; i++) {
            c[i] = `${items[i].question} -> ${items[i].isCorrect}`; 
        }
        return c;
    });
    const [uuid, setUuid] = useState(el.uuid);
    const [fewTasksCompletedMessage, setFewTasksCompletedMessage] = useState(false);

    useEffect(() => {
        options.changeElement({items, clue}, i, activeTabIndex);
    }, [items, clue]);

    useEffect(() => {
        setItems(el.items);
        setUuid(el.uuid);
        setClue(()=>{
            let c = [];
        for (let i = 0; i < items.length; i++) {
            c[i] = `${items[i].question} -> ${items[i].isCorrect}`; 
        }
        return c;
        });
    }, [el]);

    useEffect(() => {
        if (socketMessage.data.uniqId === uniqId) {
            if (socketMessage.command === 'handleTrueFalseSlider') {
                // if (this.isPresentation && lessonItems[socketMessage.data.i] && lessonItems[socketMessage.data.i].carouselRef) {
                //     lessonItems[socketMessage.data.i].carouselRef.goTo(Number(socketMessage.data.pageIndex));
                // }
            }
            if (socketMessage.command === 'sendTruesLiesAnswer') {
                const { tabs } = this.state;
                        items[socketMessage.data.itemIndex].studentAnswer = socketMessage.data.answer;

                        const trueFalseSlider = items[data.data.itemIndex];

                        if (typeof trueFalseSlider.studentAnswer === 'boolean') {
                            if (trueFalseSlider.studentAnswer === true) {
                                if (trueFalseSlider.isCorrect) {
                                    const variant = random(studentAnswerSuccessSounds.length);
                                    studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                } else {
                                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                }
                            } else {
                                if (trueFalseSlider.isCorrect) {
                                    studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                } else {
                                    const variant = random(studentAnswerSuccessSounds.length);
                                    studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                }
                            }
                        }
            }
        }
        
    },[socketMessage]);
    

    const updateHomeworkTasksInformation = (uniqId, numberOfResponses = 0) => {
        try {
            if (numberOfResponses ) {
                homeworkTasksInformation[uniqId] = {
                    numberOfResponses,
                    currentResponsesCnt: 0,
                };
            } else {
                if (homeworkTasksInformation[uniqId] && (homeworkTasksInformation[uniqId].currentResponsesCnt < homeworkTasksInformation[uniqId].numberOfResponses)) {
                    homeworkTasksInformation[uniqId].currentResponsesCnt++;
                }
            }
        } catch (e) {}

        setHomeworkTasksInformation(homeworkTasksInformation);
        setFewTasksCompletedMessage(false);
    };
    
    if (!homeworkTasksInformation[uuid]) {
        updateHomeworkTasksInformation(uuid, items.length);
    }

    return (
        <div className={l.dad2_example}>
            {options.userInfo.userInfo.type === 'teacher' && options.demoLesson ?
                <TeacherHelpInfo info={clue.map((elem) => <div className={l.exercise_clue}>{elem}</div>)} /> : null}
            <div className={l.carousel}>
                <Carousel
                    ref={ref => {
                        if (!isPresentation) return;
                        items.carouselRef = ref;
                    }}
                    onChange={(currentItem, pageIndex) =>
                        options.sendMessage({
                            command: 'handleTrueFalseSlider',
                            data: {uniqId, i, pageIndex }
                        })
                    }
                >
                    {items.map((item, itemIndex) =>
                        <Paper>
                            {isPresentation ? <h2  style={{margin: '0 auto'}}>{item.question}</h2> : 
                                <div className="col-12 dad2_render">
                                    <TextField
                                        placeholder={itemIndex}
                                        InputLabelProps={{ shrink: true }}
                                        required
                                        name="text"
                                        value={item.question}
                                        onChange={event => {
                                            item.question = event.target.value;
                                            setItems([...items]);
                                        }}
                                        label={'Question'}
                                        fullWidth
                                        multiline={false}
                                        margin="normal"
                                        className="mt-0 mb-4"
                                    />
                            </div>
                                // this.renderInputText(i, item.question, this.handleTrueLiesChange, activeTabIndex, itemIndex, '', false, 'Question')
                            }
                            <div className={`${l.carousel_buttons} ${isPresentation ? l.carousel_buttons_presentation : l.carousel_buttons_admin}`}>
                                <div className={ (!isPresentation ? (item.isCorrect ? 'test_question_item_correct' : '' ) : '') +
                                    (isPresentation ? (l.test_question_item_true_false + ' ') : '') +
                                    ((!isHomework || isHomeworkFinish) && typeof item.studentAnswer === 'boolean' && item.studentAnswer === true && item.isCorrect ? 'test_question_item_correct' : '') +
                                    ((!isHomework || isHomeworkFinish) && typeof item.studentAnswer === 'boolean' && item.studentAnswer === true && !item.isCorrect ? 'test_question_item_incorrect' : '') +
                                    (isHomework && !isHomeworkFinish && typeof item.studentAnswer === 'boolean' && item.studentAnswer === true ? 'test_question_item_homework' : '')
                                }
                                     onClick={isPresentation ? () => {
                                        const studentAnswer = items[itemIndex].studentAnswer;

                                        if (typeof studentAnswer === 'string') {
                                            if (!item.isCorrect) {
                                                options.updateHomeworkErrors(uuid, 1);
                                            }
                                        } else if (!studentAnswer) {
                                            if (item.isCorrect) {
                                                options.updateHomeworkErrors(uuid, -1);
                                            } else {
                                                options.updateHomeworkErrors(uuid, 1);
                                            }
                                        }

                                        items[itemIndex].studentAnswer = true;
                                        updateHomeworkTasksInformation(uuid);

                                        options.sendMessage({
                                            command: 'sendTruesLiesAnswer',
                                            data: {
                                                uniqId,
                                                activeTabIndex: activeTabIndex,
                                                lessonItemIndex: i,
                                                itemIndex: itemIndex,
                                                answer: true,
                                            }
                                        });

                                        if (item.isCorrect) {
                                            const variant = random(studentAnswerSuccessSounds.length);
                                            studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                        } else {
                                            studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                        }
                                    } : () => {
                                        items[itemIndex].isCorrect = true;
                                        setItems([...items]);                                        
                                    }}
                                >TRUE</div>
                                <div className={ (!isPresentation ? (!item.isCorrect ? 'test_question_item_correct' : '' ) : '') +
                                    (isPresentation ? (l.test_question_item_true_false + ' ') : '') +
                                    ((!isHomework || isHomeworkFinish ) && typeof item.studentAnswer === 'boolean' && item.studentAnswer === false && !item.isCorrect ? 'test_question_item_correct' : '') +
                                    ((!isHomework || isHomeworkFinish ) && typeof item.studentAnswer === 'boolean' && item.studentAnswer === false && item.isCorrect ? 'test_question_item_incorrect' : '') +
                                    (isHomework && !isHomeworkFinish && typeof item.studentAnswer === 'boolean' && item.studentAnswer === false ? 'test_question_item_homework' : '')
                                }
                                    onClick={isPresentation ? () => {
                                        const studentAnswer = items[itemIndex].studentAnswer;

                                        if (typeof studentAnswer === 'string') {
                                            if (item.isCorrect) {
                                               options.updateHomeworkErrors(uuid, 1);
                                            }
                                        } else if (studentAnswer) {
                                            if (!item.isCorrect) {
                                               options.updateHomeworkErrors(uuid, -1);
                                            } else {
                                                options.updateHomeworkErrors(uuid, 1);
                                            }
                                        }

                                        items[itemIndex].studentAnswer = false;
                                        

                                        updateHomeworkTasksInformation(uuid);

                                        options.sendMessage({
                                            command: 'sendTruesLiesAnswer',
                                            data: {
                                                uniqId,
                                                activeTabIndex: activeTabIndex,
                                                lessonItemIndex: i,
                                                itemIndex: itemIndex,
                                                answer: false
                                            }
                                        });

                                        if (item.isCorrect) {
                                            studentAnswerWrongAudio.play(); studentAnswerWrongAudio.volume = global.soundsVolume;
                                        } else {
                                            const variant = random(studentAnswerSuccessSounds.length);
                                            studentAnswerSuccessSounds[variant].play(); studentAnswerSuccessSounds[variant].volume = global.soundsVolume;
                                        }
                                     } : () => {
                                        items[itemIndex].isCorrect = false;
                                        setItems([...items]);
                                     }}
                                >FALSE</div>
                                {!isPresentation && 
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => {
                                            this.deleteTrueLiesItem(activeTabIndex, i, itemIndex)
                                        }}
                                    >Delete question</Button>}
                            </div>
                        </Paper>)}
                </Carousel>
            </div>

            {!isPresentation && <div>
                <Button variant='contained' color='primary'
                        onClick={() => {
                            items.push({
                                question: 'Test question',
                                isCorrect: false,
                                studentAnswer: ''
                            });
                            setItems([...items]);
                        }}
                >Add question</Button></div>
            }
        </div>
    )
}

export default renderTrueLies;
