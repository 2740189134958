import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { logout } from '../actions/auth';

import IntlMessages from '../utils/IntlMessages';

class UserInfo extends React.Component {
    state = {
        anchorEl: null,
        open: false
    }

    handleClick = event => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    }

    handleRequestClose = () => {
        this.setState({
            open: false
        });
    }

    render() {
        const storage = JSON.parse(localStorage.getItem('user') || null);
        let username = '';
        if (storage && storage.username) {
            username = storage.username;
        }

        return (
            <div className="user-profile d-flex flex-row align-items-center">
                <Avatar
                    alt="..."
                    src="https://via.placeholder.com/150x150"
                    className="user-avatar" />
                <div className="user-detail">
                    <h4 className="user-name" onClick={this.handleClick}>{username} <i
                        className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
                    </h4>
                </div>
                <Menu className="user-info"
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      open={this.state.open}
                      onClose={this.handleRequestClose}
                      PaperProps={{
                          style: {
                              width: 180
                          }
                      }}>
                    {/* <MenuItem onClick={this.handleRequestClose}>
                        <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
                        <IntlMessages id="popup.profile"/>
                    </MenuItem>
                    <MenuItem onClick={this.handleRequestClose}>
                        <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
                        <IntlMessages id="popup.setting"/>
                    </MenuItem> */}
                    <MenuItem onClick={() => {
                        this.handleRequestClose();
                        this.props.logout();
                    }}>
                        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

                        <IntlMessages id="popup.logout"/>
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({
    locale: settings.locale
});

export default connect(mapStateToProps, { logout })(UserInfo);
