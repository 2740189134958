export const LANDING_BIDDERS_TABLE_PAGE = 'LANDING_BIDDERS_TABLE_PAGE';
export const LANDING_BIDDERS_TABLE_PAGE_SIZE = 'LANDING_BIDDERS_TABLE_PAGE_SIZE';
export const LANDING_BIDDERS_TABLE_SORTING = 'LANDING_BIDDERS_TABLE_SORTING';

export const WHITE_DOMAINS_TABLE_PAGE = 'WHITE_DOMAINS_TABLE_PAGE';
export const WHITE_DOMAINS_TABLE_PAGE_SIZE = 'WHITE_DOMAINS_TABLE_PAGE_SIZE';
export const WHITE_DOMAINS_TABLE_SORTING = 'WHITE_DOMAINS_TABLE_SORTING';

export const NOT_CPA_TABLE_PAGE = 'NOT_CPA_TABLE_PAGE';
export const NOT_CPA_TABLE_PAGE_SIZE = 'NOT_CPA_TABLE_PAGE_SIZE';
export const NOT_CPA_TABLE_SORTING = 'NOT_CPA_TABLE_SORTING';

export const LESSONS_TABLE_PAGE = 'LESSONS_TABLE_PAGE';
export const LESSONS_TABLE_PAGE_SIZE = 'LESSONS_TABLE_PAGE_SIZE';
export const LESSONS_TABLE_SORTING = 'LESSONS_TABLE_SORTING';

export const REPORT_TABLE_PAGE = 'REPORT_TABLE_PAGE';
export const REPORT_TABLE_PAGE_SIZE = 'REPORT_TABLE_PAGE_SIZE';
export const REPORT_TABLE_SORTING = 'REPORT_TABLE_SORTING';
export const REPORT_TABLE_SHOW_ROUTES = 'REPORT_TABLE_SHOW_ROUTES';