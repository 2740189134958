import React, {useEffect, useState} from 'react';

import Button from '@material-ui/core/Button/Button';
import Input from '@material-ui/core/Input';
import Radio from "@material-ui/core/Radio/Radio";
import Carousel from 'react-elastic-carousel';

import l from '../main/LessonEditor.module.scss';


const Slider = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [items, setItems] = useState(el.items||[]);
    const [carouselRef, setCarouselRef] = useState(el.carouselRef);
    const [uniqId] = useState(el.uniqId);
    const [heightPriority, setHeightPriority] = useState(!!el.heightPriority);
    const [heightInVh, setHeightInVh] = useState(el.heightInVh||0);
    const [widthInPercent, setWidthInPercent] = useState(el.widthInPercent||0);
    const [widthPriority, setWidthPriority] = useState(!!el.widthPriority);
    const [isPresentation, setIsPresentation] = useState(options.isPresentation);

    const [selectedFiles , setSelectedFiles] = useState(el.selectedFiles||[]);

    useEffect(() => {
        options.changeElement({items, heightPriority, heightInVh, widthInPercent, widthPriority, selectedFiles}, i, activeTabIndex);
    }, [items, heightPriority, heightInVh, widthInPercent, widthPriority, selectedFiles]);

    useEffect(() => {
        if (isPresentation && carouselRef && socketMessage.command === 'handleSimpleSlider' && socketMessage.data.uniqId === uniqId) {
            carouselRef.goTo(Number(socketMessage.data.pageIndex));
        }
    },[socketMessage]);
    
    if (!items) return (<></>);

    let o = 1000;

    return (
        <div className={l.slider} key={i}>
            {!isPresentation &&
                <div>
                    <>
                        Height in VH<Radio name={`img${activeTabIndex}${i}`}
                            checked={!!heightPriority}
                            onChange={() => {
                                setHeightPriority(!heightPriority);
                                setWidthPriority(!widthPriority);
                            }}
                        />
                        <Input type="number"
                            value={heightInVh}
                            onChange={e => {
                                if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                                        setHeightInVh(e.target.value);
                                }
                            }}
                        />
                        Width in %<Radio name={`img${activeTabIndex}${i}`}
                            checked={!!widthPriority}
                            onChange={() => {
                                setHeightPriority(!heightPriority);
                                setWidthPriority(!widthPriority);
                            }}
                        />
                        <Input type="number"
                            value={widthInPercent}
                            onChange={e => {                   
                                if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                                    setWidthInPercent(e.target.value);
                                }
                            }}
                        />
                    </>

                    <div className={l.WIT_button}>
                        <Input type="file" name="files"
                               inputProps={{ multiple: true }}
                               onChange={e => setSelectedFiles(e.target.files)}
                        />
                    </div>
                    <div className={l.WIT_button}>
                        <Button onClick={
                            () => {
                                for (let j = 0; j < selectedFiles.length; j++) {
                                    const formData = new FormData();

                                    formData.append('file', selectedFiles[j]);
                                    formData.append('name', 'test');
                                    callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                        if (res && res.data && res.data.length && res.data.fileName) {
                                            items.push({ id: o++, url: res.data.fileName.substr(config.filePathSubstLength,) });
                                            setItems([...items]);
                                        }
                                    }).catch(() => {});
                                }
                            }
                        }
                        >
                            {selectedFiles ? 'Upload' : ''}
                        </Button>
                    </div>
                </div>}
            <div className={l.carousel}>
                <Carousel // pagination={false}
                    ref={ref => {
                        if (!isPresentation) return;
                        setCarouselRef(ref);
                    }
                    }
                    onChange={(currentItem, pageIndex) =>
                        options.sendMessage({
                            command: 'handleSimpleSlider',
                            data: {uniqId, i, pageIndex }
                        })}
                >
                    {items.map(item =>
                        <div key={item.id} className={l.img_block_img}>
                            <img src={item.url}
                                 style={heightPriority ? {height: heightInVh + 'vh'} : {width: widthInPercent + '%'}}
                            />
                        </div>)}
                </Carousel>
            </div>
            <br />
        </div>
    );
};

export default Slider;