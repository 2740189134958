import {combineReducers} from 'redux';
import audit from './audit';
import auth from './auth';
import dialog from './dialog';
import services from './services';
import settings from './settings';
import brand from './brand';
import dictionary from "./dictionary";
import likes from './likes'
import exitFromLesson from "./exitFromLesson";
import timerLesson from "./timerLesson";
import homeWork from "./homeWork";
import folders from "./folders";

export default combineReducers({
    audit,
    auth,
    dialog,
    services,
    settings,
    brand,
    dictionary,
    exitFromLesson,
    timerLesson,
    likes,
    homeWork,
    folders,
});
