    /* global window localStorage */

import axios, {CancelToken} from 'axios';
import config from '../config';

export class CancelAjaxRequest extends Error {}

export class NetworkError extends Error {}

export function cancelToken() {
    return CancelToken;
}

export default function callApi(endpoint, options, payload, source = CancelToken.source()) {
    const user = JSON.parse(localStorage.getItem('user') || null);
    const token = user ? user.token : '';

    const authHeaders = token
        ? {
            Authorization: token
        }
        : {};

    let lang = 'en';
    const locale = JSON.parse(localStorage.getItem('locale') || null);
    if (locale && locale.locale) {
        lang = locale.locale;
    }

    return axios({
        method: `${options}`,
        url: `${config.API_URI}/${endpoint}`,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-Fraudzeroider-Language': lang,
            ...authHeaders
        },
        data: {
            ...payload
        },
        cancelToken: source.token
    }).then(res => {
        console.log('endpoint', endpoint);
        let {data} = res;
        if (data.limit) {
            data = data.data;
        }
        const {className} = res.data;

        // isNotAuthenticated(className);
        if (className) {
            return Promise.reject({className});
        }
        if (endpoint.indexOf('brand/report') !== -1) { // TODO: измениь этот участок
            return res.data;
        }
        return data;
    }).catch(err => {
        if (err.message === 'Network Error') {
            throw new NetworkError('Network Error');
        }
        if (err.message instanceof CancelAjaxRequest) {
            throw new CancelAjaxRequest('ajax request canceled by user');
        } else if (err.response.status === 401) {
            localStorage.removeItem('user');
            window.location.href = global.authUrl;
        } else {
            return Promise.reject(err.response);
        }
    });
}

/**
 * @return {string|null}
 */
export function getRecordId() {
    return (new URL(window.location.href)).searchParams.get('id');
}
