import React from 'react';
import moment from 'moment';
import 'moment-duration-format';
import Pagination from '@material-ui/lab/Pagination';
import {Box} from '@material-ui/core';
import CommentDialog from './CommentDialog';
import callApi from '../../../utils/call-api';
import {v1} from '../../../utils/uuid';
import config from '../../../config'
import t from './Teacher.module.scss';

const lang = textId => textId;
const classTypes = {
    next: 'Ближайшее занятие',
    future: 'Следующие занятия',
    conducted: 'Завершённые занятия'
};
let classType = '';

class Classes extends React.Component {
    state = {
        dialogVisible: false,
        cancelWindowVisible: true,
        isActiveFL: false,
        avatarLink: `${config.imageStubGeneratorUrl}/50x50.png`,
        defaultSkipValue: config.defaultSkipValue,
        defaultLimitValue: 20,
        currentPage: 1,
        lessonsCount: 0,
        lessonsData: [],
        commentLength: config.defaultCommentLength,
        selectedLessonId: '',
        selectedLessonTeacherComment: ''
    };

    componentDidMount() {
        this.setState({
            isActiveFL: this.props.isOpen
        })
    }

    ToggleLessonsMenu = () => {
        this.setState({isActiveFL: !this.state.isActiveFL})
    }

    getLessonsData = (e) => {
        const options = {
            skip: this.state.defaultSkipValue + this.state.defaultLimitValue * (+e.target.innerText - 1),
            limit: this.state.defaultLimitValue
        };
        const pageNumber = +e.target.innerText;

        if (this.props.futureLessonsData) {
            options.type = 'future';
        } else if (this.props.conductedLessons) {
            options.type = 'conducted';
        } else {
            console.log(lang('Wrong lessons type'));

            return;
        }

        callApi('teacher/getLessons', 'POST', options).then(res => {

            if (res && res.data.ok) {
                for (let i = 0; i < res.data.studentsData.length; i++) {

                    for (let j = 0; j < res.data.lessonsData.length; j++) {
                        const lessonStudentIndex = res.data.lessonsData[j].students.indexOf(res.data.studentsData[i].id);

                        if (lessonStudentIndex !== -1) {
                            res.data.lessonsData[j].students[lessonStudentIndex] = res.data.studentsData[i];
                        }
                    }
                }

                this.setState({
                    lessonsData: res.data.lessonsData,
                    currentPage: pageNumber,
                    lessonsCount: res.data.lessonsCount
                });
            } else {
                console.log(res.data.message);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    changeLessonEnableStatus = (lessonId) => {
        callApi('teacher/changeFutureVirtualLessonEnableStatus', 'POST', {id: lessonId}).then(res => {

            if (res && res.data.ok) {

                this.setState((prevState, props) => {
                    let lessonsData = Object.assign({}, prevState.lessonsData);

                    if (!lessonsData || !lessonsData.length) {
                        lessonsData = props.nextLessonData || props.futureLessonsData || props.conductedLessons;
                    }

                    for (let i = 0; i < lessonsData.length; i++) {

                        if (lessonsData[i].id === lessonId) {
                            lessonsData[i].isEnable = !lessonsData[i].isEnable;
                        }
                    }

                    return {lessonsData: lessonsData}
                });
            } else {
                console.log(res.data.message);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    addLessonComment = (lessonId, commentText) => {
        const text = commentText.trim();
        const inputError = document.getElementById('addLessonCommentInputError');
        inputError.innerHTML = '';
        inputError.style.color = '';

        if (!text) {
            inputError.innerHTML = lang('Введите комментарий');
        } else if (text.length > this.state.commentLength) {
            inputError.innerHTML = lang('Комментарий слишком велик');
        } else {
            callApi('teacher/addVirtualLessonComment', 'POST', {
                virtualLessonId: lessonId,
                text: text
            }).then(res => {

                if (res && res.data.ok) {
                    inputError.style.color = '#3f51b5';
                    inputError.innerHTML = lang('Ваш комментарий успешно добавлен');
                } else {
                    inputError.innerHTML = lang('Не удалось добавить комментарий');

                    console.log(res.data.message);
                }
            }).catch(e => {
                console.log(e);
            });
        }
    }

    activateDialog = (e) => {
        this.setState({
            dialogVisible: true,
            selectedLessonId: e.target.dataset.lessonId,
            selectedLessonTeacherComment: e.target.dataset.lessonTeacherComment
        })
    }

    deactivateDialog = () => {
        const inputError = document.getElementById('addLessonCommentInputError');
        inputError.innerHTML = '';
        inputError.style.color = '';

        this.setState({dialogVisible: false})
    }

    render() {
        let lessonsData = [];
        let lessonsCount = this.state.lessonsCount ? this.state.lessonsCount : this.props.lessonsCount;

        if (this.props.nextLessonData) {
            classType = classTypes.next;
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.nextLessonData;
        } else if (this.props.futureLessonsData) {
            classType = classTypes.future;
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.futureLessonsData;
        } else {
            classType = classTypes.conducted;
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.conductedLessons;
        }

        return (
            <div>
                <CommentDialog
                    dialogVisible={this.state.dialogVisible}
                    closeDialog={this.deactivateDialog}
                    addLessonComment={this.addLessonComment}
                    lessonId={this.state.selectedLessonId}
                    comment={this.state.selectedLessonTeacherComment}
                />
                <div className={t.finished_classes}>
                    <div data-w-id={v1()}
                         onClick={this.ToggleLessonsMenu}
                         className={t.finished_classes_title}>
                        <div className={t.finished_class_txt}>
                            {lang(classType)}
                        </div>
                    </div>
                    <div
                        className={this.state.isActiveFL === true ? t.finished_visible_block : t.finished_hidden_block}>
                        {
                            lessonsData.length ?
                                <div className={t.further_class_info}>
                                    {
                                        lessonsData.map((lessonData, index) => {
                                            if (!lessonData) return (<></>);
                                            return (
                                            <div
                                                key={v1()}
                                                className={!lessonData.isConducted && !lessonData.isEnable ?
                                                    t.canceled_lesson_blackout : null}
                                            >
                                                <div className={t.next_class_info_items}>
                                                    <div className={t.class_type}>
                                                        <div className={t.text_title}>
                                                            {lang('Тип занятия')}:
                                                            <br/>
                                                        </div>
                                                        <div className={t.text_item}>
                                                            {lessonData.students.length > 1 ? lang('Мини-группа') : lang('VIP-занятие')}
                                                            <br/>
                                                            {lessonData.students.length > 1 ? `${lang('на')} ${lessonData.students.length} ${lang('человек')}` : null}
                                                        </div>
                                                    </div>
                                                    <div className={t.class_subject}>
                                                        <div className={t.text_title}>
                                                            {lang('Предполагаемая тема')}:
                                                            <br/>
                                                        </div>
                                                        <div className={t.text_item}>
                                                            {lessonData.name}
                                                            <br/>
                                                            {lessonData.lessonName}
                                                        </div>
                                                    </div>
                                                    <div className={t.class_duration}>
                                                        <div className={t.text_title}>
                                                            {lang('Продолжительность')}
                                                            <br/>
                                                            {lang('занятия')}:
                                                            <br/>
                                                        </div>
                                                        <div className={t.text_item}>
                                                            {`${moment.duration(lessonData.endDate - lessonData.startDate, 'seconds').format("m")} ${lang('минут')}`}
                                                        </div>
                                                    </div>
                                                    <div className={t.class_review}>
                                                        <div className={t.text_title}>
                                                            {lang('Дата начала')}:
                                                        </div>
                                                        <div className={t.text_item}>
                                                            {moment(lessonData.startDate * 1000).format('MMMM Do YYYY, h:mm:ss a')}
                                                        </div>
                                                    </div>
                                                    {(() => {
                                                        const timeUntilLesson = lessonData.startDate * 1000 - Date.now();
console.log('timeUntilLesson', timeUntilLesson)
                                                            if (this.props.conductedLessons) {

                                                                return (
                                                                    <div
                                                                        className={`${t.comment} ${t.comment_review_title}`}
                                                                        data-lesson-id={lessonData.id}
                                                                        data-lesson-teacher-comment={lessonData.teacherComment}
                                                                        onClick={this.activateDialog}
                                                                    >
                                                                        {lang('Написать')}
                                                                        <br/>
                                                                        {lang('комментарий')}
                                                                    </div>
                                                                )
                                                            } else if (timeUntilLesson > 10 * 60 * 1000 || !lessonData.isEnable) {
                                                                return (
                                                                    <div
                                                                        data-w-id={v1()}
                                                                        className={t.countdown}
                                                                        onClick={() => {
                                                                            this.changeLessonEnableStatus(lessonData.id)
                                                                        }}
                                                                    >
                                                                        <div className={t.teacher_review_title}>
                                                                            {lang('До начала урока')}:
                                                                        </div>
                                                                        <div className={t.text_item}>
                                                                            ~{moment.duration(timeUntilLesson / 1000, 'seconds').format(`dd ${lang('д.')} hh ${lang('ч.')} mm ${lang('м.')}`)}
                                                                        </div>
                                                                        <div
                                                                            className={t.cancel_lesson_btn}
                                                                            onClick={() => this.setState({cancelWindowVisible: false})}
                                                                        >
                                                                <span className={t.cancel_lesson_txt}>
                                                                    {lessonData.isEnable ? lang('Отменить занятие') : lang('Активировать')}
                                                                </span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else {

                                                                return (
                                                                    <div
                                                                        className={`${t.comment} ${t.comment_review_title}`}
                                                                        onClick={() => {window.location.href=`/brand/vl?id=${lessonData.id}`}}
                                                                    >
                                                                        {lang('Войти')}
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    )()}
                                                </div>
                                                <div className={t.students_list}>
                                                    {lessonData.students.map(student => (
                                                        <div key={v1()} className={t.student_item}>
                                                            <div>
                                                                <img
                                                                    className={t.student_photo}
                                                                    src={student.avatar || this.state.avatarLink}
                                                                    alt={lang('Avatar')}
                                                                ></img>
                                                            </div>
                                                            <div className={t.student_name}>
                                                                <div className={t.text_item}>
                                                                    {student.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {
                                                    index !== lessonsData.length - 1 ?
                                                        <div className={t.line}></div> :
                                                        null
                                                }
                                            </div>
                                        )})
                                    }
                                </div> :
                                null
                        }
                        {
                            classType !== classTypes.next && this.props.lessonsCount > this.state.defaultLimitValue ?
                                <Box display="flex" justifyContent="center">
                                    <Pagination
                                        count={Math.ceil(lessonsCount / this.state.defaultLimitValue)}
                                        page={this.state.currentPage}
                                        onChange={this.getLessonsData}
                                    />
                                </Box> :
                                null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Classes;
