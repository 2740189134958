
import React, {useEffect, useState} from "react";

import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import "./LessonComponent.scss"

const PUNCTUATION_MARKS =[" ", ",", "-", ";", ":", ".", "?", "!"];

const HieroglyphAndTransktiption = ({el, i, activeTabIndex, options, socketMessage}) => {
    const [hieroglyphs, setHieroglyph] =  useState(el.hieroglyphs);
    const [transktiption, setTransktiption] =  useState(el.transktiption);
    const [isPresentation, setIsPresentation] = useState(options.isPresentation);
    const [showTransktiption, setShowTransktiption] = useState(true);
    const [isText, setIsText] = useState(false);

    useEffect(() => {
        options.changeElement({hieroglyphs, transktiption, showTransktiption}, i, activeTabIndex);
    }, [hieroglyphs, transktiption, showTransktiption]);

    useEffect(() => {
        if (isPresentation && socketMessage.command === 'changeShowTransktiption' && socketMessage.data.uniqId === el.uniqId) {
            setShowTransktiption(socketMessage.data.showTransktiption);
        }
    }, [socketMessage]);

    const getHieroglyphsWithTransktiption = () => {
        const hieroglyphsCount = hieroglyphs.split("").filter(item => !PUNCTUATION_MARKS.includes(item)).length;
        const transktiptionCount = transktiption.split(" ").length;
        
        if (hieroglyphsCount === transktiptionCount) {
            let transktiptionIndex = 0;
            const transktiptionsList = transktiption.split(" ");
            return hieroglyphs.split("")
            .map(item => {
                if (!PUNCTUATION_MARKS.includes(item)) {
                    transktiptionIndex ++;
                    return  {
                        hieroglyph: item,
                        transktiption: transktiptionsList[transktiptionIndex-1]
                    }
                } else {
                    return  {
                        hieroglyph: item,
                        transktiption: ""
                    }
                }
            })
        }
    }

    const getTransktiption= (s)=>{
        console.log (showTransktiption);
        return showTransktiption ? s: "";
    }

    const changeShowTransktiption = (vall) => {
        setShowTransktiption(vall.target.checked);
        options.sendMessage({
            command: 'changeShowTransktiption',
            data: {uniqId, showTransktiption: vall.target.checked}
        })
    }

    return (
        <>
        {!isPresentation &&
            <> 
                <div className="col-12 dad2_render">
                    <TextField
                        placeholder={""}
                        InputLabelProps={{ shrink: true }}
                        required
                        name="text"
                        value={hieroglyphs}
                        onChange={event => setHieroglyph(event.target.value)}
                        label={"Иероглифы"}
                        fullWidth
                        multiline={false}
                        margin="normal"
                        className="mt-0 mb-4"
                    />
                </div>
                <div className="col-12 dad2_render">
                    <TextField
                        placeholder={""}
                        InputLabelProps={{ shrink: true }}
                        required
                        name="text"
                        value={transktiption}
                        onChange={event => setTransktiption(event.target.value)}
                        label={"Транскрипция"}
                        fullWidth
                        multiline={false}
                        margin="normal"
                        className="mt-0 mb-4"
                    />
                </div>
                
                <Button onClick={() => setIsText(true)} color="primary">Ok</Button>
            </>
        }
        {(isPresentation || isText) &&
            <article class="domain">
                {options.userInfo.userInfo.type != 'student' && <FormControlLabel control={<Checkbox checked={showTransktiption} onChange={changeShowTransktiption} />} label="Показать транскрипцию" />}
                <section class="frame">
                    {getHieroglyphsWithTransktiption().map(item=>{
                        return (
                            <div class="block">
                                <span class="spellig">{getTransktiption(item.transktiption)}</span>
                                <span class="symbol">{item.hieroglyph}</span>
                            </div>
                        );
                    })}
                </section>
            </article>
        }    
        </>
    );
};

export default HieroglyphAndTransktiption;