import React from "react";
import s from "./../SuperAdmin.module.css";
import Navlink from "react-router-dom/NavLink";
import {Route} from "react-router-dom";
import Select from "react-select";
import callApi from "../../../../utils/call-api";
import Button from '@material-ui/core/Button';
import config from "../../../../config";

const Path = "/admin/superadmin/students/students_tab/";

class StudentsTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addUserSubscriptionsEmail: '',
            newStudentPassword: '',
            groupClassCount: 0,
            individualShortClassCount: 0,
            individualClassCount: 0,
            activityKeysCount: 0,
            activeAddSupUser: null,
            activeAddSupSupervisor: null,
            activeToggleAddSupSupervisor: false,
            studentsByCourseList: Object.assign({}, this.props.studentsByCourseList || {
                activeCourses: {all: []},

                inactiveCourses: {all: []}
            }),
            isActiveDropdown: false,
            formAddData: {
                courseNameSelected: ["kids",], //[{ name: "kids", value: "kids", label: "kids" }],
                name: '',
                email: '',
                phone1: '',
                phone2: '',
            },
            search: this.props.search,
            searchArr: [],
            students: Object.assign({}, this.props.studentsByCourseList || {
                activeCourses: {all: []},
                inactiveCourses: {all: []}
            }),
            isActiveAddStudent: this.props.addStudent,
            selectedDemo: {},
            successSupervAdd: false,
            successStudentAdd: false,
            selectedStudentList: [],
        };
    }


    componentDidMount() {
        this.SortBy();
        this.handleSearchSubmit();
        this.makeSelectList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // if (prevProps.studentsByCourseList && this.props.studentsByCourseList) {
        //     for (let i = 0; i < this.props.studentsByCourseList.length; i++) {
        //         const student = prevProps.studentsByCourseList.find(pg => pg.id === this.props.studentsByCourseList[i].id);
        //
        //         if (!student) {
        //             this.setState({
        //                 studentsByCourseList: this.props.studentsByCourseList //возможно другой формат
        //             });
        //             break;
        //         }
        //     }
        // }
        if (this.props.selectedFolders && this.props.selectedFolders !== prevProps.selectedFolders) {
            const studentsList = [];
            this.props.selectedFolders.map(item =>{
                item.value.map(item2 =>{
                    const index = studentsList.findIndex(e => e.id === item2.value.id);
                    if(index < 0) {
                        studentsList.push(item2.value);
                    }
                })
            })
            this.setState({...this.state,  selectedStudentList: studentsList});
        }
    }

    makeSelectList = () => {
        let students = this.state.students.activeCourses.all;
        let {supervisors} = this.props;

        if (!!students && !!supervisors) {
            students.map(student => {

                const selectListSup = [];

                supervisors.forEach(sup => {

                    if (!student.supervisors.active.includes(`${sup.name} [${sup.email}] `)) {
                        selectListSup.push(sup);
                    }
                })

                student.selectListSup = selectListSup.map(st => ({
                    value: st.id,
                    label: `${st.name} [${st.email}]`,
                }));
                // console.log(student.selectListSup, 'selectListSup');
                this.setState({students: {activeCourses: {all: student.selectListSup}}})

                const {activeAddSupUser} = this.state;
                student.actualSup = student.selectListSup[0] || {};

                this.setState({students: {activeCourses: {all: student.actualSup}}})

            })
            console.log(this.state, 'state');
        }

    }

    getDateFunc = (timestamp) => {
        const t = (!!timestamp) ? new Date(timestamp * 1000) : new Date();
        let dd = t.getDate();

        if (dd < 10) {
            dd = '0' + dd
        }

        let mm = t.getMonth() + 1;

        if (mm < 10) {
            mm = '0' + mm
        }

        return dd + '.' + mm + '.' + t.getFullYear();
    }

    // searching
    handleSearchSubmit = (event) => {
        let url = window.location.pathname;

        if (url.indexOf('/active_courses')) {
            let urlComponent =
                (window.location.pathname === '/admin/superadmin/students/students_tab/active_courses' ||
                    window.location.pathname === '/admin/superadmin/students/students_tab/active_courses/')
                    ? 'all'
                    : url.replace(Path + 'active_courses/', '');

            let studentsList = (this.state.studentsByCourseList.activeCourses[urlComponent]);
            let searchArr = [];

            if (this.state.search !== '') {
                studentsList.forEach(i => {
                    let objName = i.name;
                    let a = objName.toLowerCase().indexOf(this.state.search.toLowerCase());

                    if (a !== -1) {
                        searchArr.push(i);
                    }

                })
            }

            if (searchArr.length > 0) {
                let studentsByCourseList = {
                    activeCourses: {
                        ...this.state.studentsByCourseList.activeCourses,
                        [urlComponent]: searchArr
                    }
                }

                this.setState({studentsByCourseList})
            } else {
                this.setState({studentsByCourseList: this.state.students});
            }
        }
    };

    selectDemoUser = () => {
        const demos = this.props.demos || [];
        const {selectedDemo} = this.state;
        const select = demos.map(el => ({...el, value: el.id, label: el.email + ' (' + el.name + ')'}));

        return (
            <Select
                required
                className={s.select_course_name}
                defaultValue={selectedDemo}
                name="courseName" options={select}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={selectedDemo => this.setState({
                    selectedDemo,
                    name: selectedDemo.name,
                    email: selectedDemo.email,
                    phone1: selectedDemo.phoneNumber || ''
                })}
            />
        );
    };

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    AddStudent = (event) => {
        event.preventDefault();

        const {selectedDemo, phone1, name} = this.state;

        callApi('dashboard/updateUsers', 'post', {
            id: selectedDemo.id,
            typeId: 4,
            name,

            phoneNumber: phone1
        }).then(res => {
            if (res && res.data.ok) {
                const studentInfo = {
                    name, mail: selectedDemo.email,
                    date: this.getDateFunc(),
                    phone1: selectedDemo.phoneNumber, phone2: selectedDemo.phoneNumber,
                    id: selectedDemo.id,
                    courses: {active: [], inactive: []},
                    supervisors: {active: [], inactive: []},
                    groups: {active: [], inactive: []},
                };

                const {studentsByCourseList} = this.state;
                // studentsByCourseList.activeCourses.all.unshift(studentInfo);

                this.setState({studentsByCourseList});
                this.setState({students: studentsByCourseList});
                this.ToggleAddStudent();
                this.setState({successStudentAdd: true})
                setTimeout(() => this.setState({successStudentAdd: false}), 2000);
            }
        }).catch(err => {
            console.log('err', err)
        });
    };

    //SORTING
    SortBy = () => {
        let filter = this.props.filterForSort;

        if (filter === 'name' || filter === 'date' || filter === 'courses') {
            let url = window.location.pathname;
            let newStudents = this.state.studentsByCourseList;
            let [, , , , , studStatus, courseN] = url.split('/');

            if (courseN == undefined || courseN == '') {
                courseN = 'all'

            }

            let StObj = studStatus.replace('_courses', 'Courses');

            if (filter === "courses") {
                newStudents[StObj][courseN].sort((a, b) => (
                    a.courses.active.length > b.courses.active.length
                ))
            } else {
                newStudents[StObj][courseN].sort((a, b) => (
                    a[filter] > b[filter]
                ))
            }

            this.setState({studentsByCourseList: newStudents});
            this.ToggleDropdown();
        }
    }

    ToggleAddStudent = () => {
        this.setState({isActiveAddStudent: !this.state.isActiveAddStudent})
    }

    initialToggleState = () => {
        let initDynamicArray = [];
        let initDynamicObj = {};
        Object.keys(this.state.studentsByCourseList.activeCourses).map(courseName => {
            Object.keys(this.state.studentsByCourseList.activeCourses[courseName]).forEach(i => {
                initDynamicObj[courseName + 'isActiveToggleSupervisor' + i] = false;
                initDynamicObj[courseName + 'isActiveToggleCourse' + i] = false;
                initDynamicObj[courseName + 'isActiveToggleGroup' + i] = false;
                initDynamicObj[courseName + 'isActiveToggleContact' + i] = false;
            });
        });

        return initDynamicObj;
    };

    ToggleDropdown = () => {
        this.setState({isActiveDropdown: !this.state.isActiveDropdown})
    };

    ToggleWindow = name => {
        this.setState({...this.initTogglesState, [name]: !this.state[name]})
    };

    RenderAddStudent = () => {
        if (this.state.isActiveAddStudent) {
            return (
                <div className={s.form_add}>
                    <form onSubmit={this.AddStudent}>
                        <div>{this.selectDemoUser()}</div>
                        <div className={s.form_add + " " + s.left}>
                            <input type="text" placeholder="Имя" name="name" required
                                   value={this.state.name}
                                   onChange={this.handleInputChange}/>
                            {/*<input type="email" placeholder="email" name="email" required value={this.state.email}*/}
                            {/*       onChange={this.handleInputChange}/>*/}
                        </div>
                        <div className={s.form_add + " " + s.right}>
                            <input type="text" placeholder="phone number" name="phone1" required
                                   value={this.state.phone1}
                                   onChange={this.handleInputChange}/>
                            {/*<input type="text" placeholder="second phone number" name="phone2" value={this.state.phone2}*/}
                            {/*       onChange={this.handleInputChange}/>*/}
                        </div>
                        <div className={s.form_add_submit}>
                            <button className={s.form_add_submit} type="submit" value="Submit">Add Student</button>
                        </div>
                    </form>
                </div>
            )
        }
    };

    RenderMap = (arr, mapkey, style = '') => {

        return (
            Object.keys(arr).map(mapkey => (
                <div key={mapkey} className={s.dropdown_teacher_link + " " + style}>
                    {arr[mapkey]}
                </div>
            ))
        );
    };

    RenderCourses = (status) => {
        const studentsByCourseList = this.state.studentsByCourseList[status];
        const url = status.replace("Courses", "_courses");

        return (
            <div>
                <div className={s.tabs_menu}>
                    {/* FOR ALL made in render */}

                    {/*<Navlink exact to={"/admin/superadmin/students/students_tab/" + url + "/"}*/}
                    {/*         activeClassName={s.active_tabs} className={s.nav_tabs}>*/}
                    {/*    All*/}
                    {/*</Navlink>*/}

                    {Object.keys(studentsByCourseList).map(courseName => {
                        // {/* FOR ALL made in render */}

                        //  (
                        //     <Navlink to={"/admin/superadmin/students/students_tab/" + url + "/" + courseName}
                        //              activeClassName={s.active_tabs} className={s.nav_tabs}>
                        //         {courseName}
                        //     </Navlink>
                        // ))}

                        return (courseName === 'All' || courseName === 'all')
                            ?
                            (<Navlink exact to={"/admin/superadmin/students/students_tab/" + url + "/"}
                                      activeClassName={s.active_tabs} className={s.nav_tabs}>
                                {courseName}
                            </Navlink>)
                            :
                            (<Navlink to={"/admin/superadmin/students/students_tab/" + url + "/" + courseName}
                                      activeClassName={s.active_tabs} className={s.nav_tabs}>
                                {courseName}
                            </Navlink>)
                    })}
                </div>
                {Object.keys(studentsByCourseList).map(courseName => {

                    return (courseName === 'All' || courseName === 'all')
                        ?
                        <Route exact path={"/admin/superadmin/students/students_tab/" + url + "/"}
                               component={() => this.RenderStudents(status, courseName)}/>
                        :
                        <Route exact path={"/admin/superadmin/students/students_tab/" + url + "/" + courseName}
                               component={() => this.RenderStudents(status, courseName)}/>
                })}
                {this.RenderButtons(studentsByCourseList)}
            </div>
        )
    };

    RenderStudents = (status, courseName) => {//let students = this.state.studentsByCourseList[status][courseName];
        let students = [];
        if (this.state.selectedStudentList.length === 0) {
            students = this.state.studentsByCourseList[status][courseName];
        } else {
            students = this.state.selectedStudentList;
        }
        const {supervisors} = this.props;
        const { addUserSubscriptionsEmail } = this.state;

        return (
            <div className={s.admins_list}>
                {Object.keys(students).map(studentsKey => {
                    return (
                        <div key={studentsKey}>
                            <div className={s.admins_list_item}>
                                <div className={s.name}>
                                    {students[studentsKey].name + ' ' + students[studentsKey].surname}
                                </div>

                                <div className="studentAddSubscriptionContainer"
                                     onClick={() => {
                                         if (this.state.addUserSubscriptionsEmail === students[studentsKey].mail) {
                                             this.setState({ addUserSubscriptionsEmail: '' });
                                         } else {
                                             this.setState({ addUserSubscriptionsEmail: students[studentsKey].mail });
                                         }
                                     }}
                                >
                                    {students[studentsKey].mail}

                                    <div className={"studentAddSubscription " +
                                        (students[studentsKey].mail === addUserSubscriptionsEmail ? "studentAddSubscriptionActive": "")
                                    }
                                         onClick={e => {
                                             e.preventDefault();
                                             e.stopPropagation();
                                         }}
                                    >
                                        <div>Для <span style={{fontWeight: 600}}>{students[studentsKey].mail}</span></div>
                                        <br />
                                        <Button variant="outlined" color="primary"
                                                onClick={() => {
                                                    callApi('dashboard/changeActiveStudentsStatus', 'post', {
                                                        isActivatedByAdmin: true,
                                                        userId: students[studentsKey].id,
                                                    }).then(res => {
                                                        if (res && res.ok) {
                                                            alert('Успешно перемещен');
                                                        }
                                                    });
                                                }}
                                        >Переместить в активные</Button>
                                        <br />
                                        <div>Новый пароль:</div>
                                        <input type="text" placeholder="Пароль студента"
                                               value={this.state.newStudentPassword}
                                               onChange={e => this.setState({ newStudentPassword: e.target.value })}
                                        />
                                        <Button variant="outlined" color="primary"
                                            onClick={() => {
                                                callApi('auth/passAdminChange', 'post', {
                                                    password: this.state.newStudentPassword,
                                                    userId: students[studentsKey].id,
                                                }).then(res => {
                                                    if (res && res.data.ok) {
                                                        alert('Успешно изменен');
                                                    }
                                                });
                                            }}
                                        >Сменить пароль</Button>
                                        <br />
                                        <div>Групповые занятия:</div>
                                        <input type="text" placeholder="Групповые занятия"
                                               value={this.state.groupClassCount}
                                               onChange={e => this.setState({ groupClassCount: e.target.value })}
                                        />
                                        <div>Индивидуальные короткие занятия:</div>
                                        <input type="text" placeholder="Индивидуальные короткие занятия"
                                               value={this.state.individualShortClassCount}
                                               onChange={e => this.setState({ individualShortClassCount: e.target.value })}
                                        />
                                        <div>Индивидуальные занятия:</div>
                                        <input type="text" placeholder="Индивидуальные занятия"
                                               value={this.state.individualClassCount}
                                               onChange={e => this.setState({ individualClassCount: e.target.value })}
                                        />
                                        <div>Активити ключи:</div>
                                        <input type="text" placeholder="Активити ключи"
                                               value={this.state.activityKeysCount}
                                               onChange={e => this.setState({ activityKeysCount: e.target.value })}
                                        />
                                        <br />
                                        <Button variant="outlined" color="primary"
                                                onClick={() => {
                                                    const {
                                                        groupClassCount,
                                                        individualShortClassCount,
                                                        individualClassCount,
                                                        activityKeysCount,
                                                    } = this.state;

                                                    callApi('dashboard/addUserSubscriptionsByAdmin', 'post', {
                                                        userId: students[studentsKey].id,
                                                        groupClassCount,
                                                        individualShortClassCount,
                                                        individualClassCount,
                                                        activityKeysCount,
                                                    }).then(res => {
                                                        if (res && res.data.ok) {
                                                            alert('Успешно добавлено');
                                                        }
                                                    });
                                                }}
                                        >
                                            Добавить уроки
                                        </Button>
                                    </div>
                                </div>

                                <div className={s.admins_item_right_block}>
                                    <div className={s.supervised_teachers}>
                                        <div className={s.supervised_teachers_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleSupervisor' + Number(studentsKey))}>
                                            <div className={s.supervised_teachers_list}>
                                                Supervised by (
                                                {students[studentsKey].supervisors.active.length + "/" + students[studentsKey].supervisors.inactive.length}
                                                )
                                            </div>
                                            <div
                                                className={this.state['isActiveToggleSupervisor' + Number(studentsKey)] !== true
                                                    ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleSupervisor' + Number(studentsKey)] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_teacher : s.dropdown_teacher_hidden}>

                                            <div
                                                style={{
                                                    display: (this.state.activeToggleAddSupSupervisor)
                                                        ? 'none' : 'block'
                                                }}

                                                className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                                onClick={() => this.setState({
                                                    activeAddSupUser: students[studentsKey].id,
                                                    activeToggleAddSupSupervisor: true
                                                })}
                                            >
                                                + Add the new supervisor
                                            </div>

                                            <div style={{
                                                display: (this.state.activeAddSupUser !== students[studentsKey].id
                                                    && !this.state.activeToggleAddSupSupervisor)
                                                    ? 'none' : 'block'
                                            }}>

                                                <div className={s.dropdown_add_sup}>
                                                    <div className={s.dropdown_select}>
                                                        <Select required
                                                            // defaultValue={{
                                                            //     value: actualSupervisor.value,
                                                            //     label: actualSupervisor.label
                                                            // }}
                                                                name="courseName"
                                                                options={students[studentsKey].selectListSup}

                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={el => this.setState({activeAddSupSupervisor: el.value})}
                                                                placeholder="Select level"
                                                        />
                                                    </div>

                                                    <div className={s.dropdown_buttons}>
                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {


                                                                    callApi('dashboard/addSupervisorsUsers', 'post', {
                                                                        supervisorId: this.state.activeAddSupSupervisor,
                                                                        userId: this.state.activeAddSupUser,
                                                                    }).then(res => {
                                                                        if (res && res.data.ok) {
                                                                            this.setState({isActiveToggleSubmitCourse: true});
                                                                            setTimeout(() => this.setState({isActiveToggleSubmitCourse: false}), 4000);

                                                                            let studentsMod = this.state.studentsByCourseList;
                                                                            studentsMod[status][courseName].map(el => {
                                                                                    if (el.id === this.state.activeAddSupUser) {
                                                                                        let findone = supervisors.find(el => el.id === this.state.activeAddSupSupervisor);
                                                                                        let unshift = `${findone.name} [${findone.email}]`;
                                                                                        el.supervisors.active.unshift(unshift);
                                                                                    }
                                                                                }
                                                                            )

                                                                            this.setState({studentsByCourseList: studentsMod});
                                                                            this.setState({successSupervAdd: true})
                                                                            setTimeout(() => this.setState({successSupervAdd: false}), 2000);
                                                                        }
                                                                    }).catch(err => {
                                                                    });
                                                                }}
                                                        >
                                                            Add
                                                        </Button>

                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        activeAddSupUser: false,
                                                                        activeToggleAddSupSupervisor: false,
                                                                    })
                                                                }}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>


                                            </div>

                                            {/*{console.log("groups[groupKey].teachers.active", students[studentsKey].supervisors.active )}*/}

                                            {this.RenderMap(students[studentsKey].supervisors.active, 'activeSupervisorKey')}
                                            {this.RenderMap(students[studentsKey].supervisors.inactive, 'inactiveSupervisorKey', s.dropdown_link_red)}

                                        </div>
                                    </div>


                                    <div className={s.supervised_students}>
                                        <div className={s.supervised_student_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleCourse' + Number(studentsKey))}>
                                            <div className={s.supervised_students_list}>
                                                Courses (
                                                {students[studentsKey].courses.active.length + "/" + students[studentsKey].courses.inactive.length}
                                                )
                                            </div>
                                            <div
                                                className={this.state['isActiveToggleCourse' + Number(studentsKey)] !== true
                                                    ? s.icon_dropdown_toggle
                                                    : s.un_icon_dropdown_toggle}>
                                            </div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleCourse' + Number(studentsKey)] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>

                                            {
                                                (students[studentsKey].courses.active.length !== 0)
                                                    ? this.RenderMap(students[studentsKey].courses.active, 'activeGroupKey')
                                                    : <div className={s.dropdown_teacher_link}
                                                           style={{margin: "0 auto", fontWeight: "bold"}}>
                                                        no active courses
                                                    </div>
                                            }
                                            {
                                                (students[studentsKey].courses.inactive.length !== 0)
                                                    ?
                                                    this.RenderMap(students[studentsKey].courses.inactive, 'inactiveGroupKey', s.dropdown_link_red)
                                                    : <div className={s.dropdown_teacher_red}
                                                           style={{margin: "0 auto", fontWeight: "bold"}}>
                                                        no inactive courses
                                                    </div>
                                            }

                                            {/*{this.RenderMap(students[studentsKey].courses.active, 'activeCourseKey')}*/}
                                            {/*{this.RenderMap(students[studentsKey].courses.inactive, 'inactiveCourseKey', s.dropdown_link_red)}*/}

                                        </div>
                                    </div>

                                    <div className={s.supervised_students}>
                                        <div className={s.supervised_student_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleGroup' + Number(studentsKey))}>
                                            <div className={s.supervised_students_list}>
                                                Groups (
                                                {students[studentsKey].groups.active.length + "/" + students[studentsKey].groups.inactive.length}
                                                )
                                            </div>
                                            <div
                                                className={this.state['isActiveToggleGroup' + Number(studentsKey)] !== true
                                                    ? s.icon_dropdown_toggle
                                                    : s.un_icon_dropdown_toggle}>
                                            </div>
                                        </div>
                                        <div className={this.state['isActiveToggleGroup' + Number(studentsKey)] === true
                                            ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>

                                            {
                                                (students[studentsKey].groups.active.length !== 0)
                                                    ? this.RenderMap(students[studentsKey].groups.active, 'activeGroupKey')
                                                    : <div className={s.dropdown_teacher_link}
                                                           style={{margin: "0 auto", fontWeight: "bold"}}>
                                                        no active groups
                                                    </div>
                                            }
                                            {
                                                (students[studentsKey].groups.inactive.length !== 0)
                                                    ?
                                                    this.RenderMap(students[studentsKey].groups.inactive, 'inactiveGroupKey', s.dropdown_link_red)
                                                    : <div className={s.dropdown_teacher_red}
                                                           style={{margin: "0 auto", fontWeight: "bold"}}>
                                                        no inactive groups
                                                    </div>
                                            }
                                            {/*{this.RenderMap(students[studentsKey].groups.inactive, 'inactiveGroupKey', s.dropdown_link_red)}*/}

                                        </div>
                                    </div>

                                    <div className={s.date}>{students[studentsKey].date}</div>

                                    <div className={s.contact}>
                                        <div className={s.supervised_student_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleContact' + Number(studentsKey))}>
                                            <div className={s.supervised_students_list}>Contact</div>
                                            <div
                                                className={this.state['isActiveToggleContact' + Number(studentsKey)] !== true
                                                    ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleContact' + Number(studentsKey)] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_contact : s.dropdown_student_hidden}
                                        >
                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}
                                                 onClick={() => {
                                                     callApi('dashboard/deleteUser', 'post', {
                                                         userId: students[studentsKey].id,
                                                         email: students[studentsKey].mail,
                                                     }).then(res => {
                                                         if (res && res.data.ok) {
                                                             alert ('User deleted');
                                                         } else {
                                                             alert ('User not deleted');
                                                         }
                                                     }).catch(() => {
                                                         alert ('User not deleted');
                                                     });
                                                 }}
                                            >
                                                Delete user
                                            </div>
                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571c32195480adaa0d37_chat-2.png"></img>
                                                <div>CHAT</div>
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09586a2ae5446fc1a024d9_envelope.png"></img>
                                                <div>{students[studentsKey].mail}</div>
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                                <div>{students[studentsKey].phone1}</div>
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                                <div>{students[studentsKey].phone2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.devide_line}></div>
                        </div>
                    )
                })}
            </div>
        );
    };

    RenderButtons = (users) => {

        if (users) {

            return (
                <div className={s.tabs_menu}>
                    <Button
                        disabled={this.props.getUsersRequestData.isFirstPage}
                        key='usersPrevButton'
                        className={s.nav_tabs}
                        onClick={() => {
                            console.log('Prev');
                            this.props.getUsersData({
                                ...this.props.getUsersRequestData,
                                skip: this.props.getUsersRequestData.skip - config.defaultSkipStepValue
                            })
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        disabled={this.props.getUsersRequestData.isLastPage}
                        key='usersNextButton'
                        className={s.nav_tabs}
                        onClick={() => {
                            // console.log(this.props.getUsersRequestData);
                            console.log('Next');
                            this.props.getUsersData({
                                ...this.props.getUsersRequestData,
                                skip: this.props.getUsersRequestData.skip + config.defaultSkipStepValue
                            })
                        }}
                    >
                        Next
                    </Button>
                </div>
            )
        }
    };

    render() {

        return (
            <div>
                <div className={s.tabs_menu}>
                    <Navlink exact to="/admin/superadmin/students/students_tab/active_courses"
                             activeClassName={s.active_tabs} className={s.nav_tabs}>
                        Active courses
                    </Navlink>
                    <Navlink to="/admin/superadmin/students/students_tab/inactive_courses"
                             activeClassName={s.active_tabs} className={s.nav_tabs}>
                        Inactive courses
                    </Navlink>
                </div>

                {this.RenderAddStudent()}

                <div style={{display: (this.state.successSupervAdd) ? 'block' : 'none'}}>
                    <div className={s.success_add_sup}>
                        Supervisor успешно добавлен
                    </div>
                </div>
                <div style={{display: (this.state.successStudentAdd) ? 'block' : 'none'}}>
                    <div className={s.success_add_sup}>
                        Student успешно добавлен
                    </div>
                </div>
                <Route exact path={"/admin/superadmin/students/students_tab/active_courses"}
                       render={() => this.RenderCourses('activeCourses')}/>
                <Route exact path={"/admin/superadmin/students/students_tab/inactive_courses"}
                       render={() => this.RenderCourses('inactiveCourses')}/>

            </div>
        );
    }
}

export default StudentsTab;

