import React from "react";
import s from "../SuperAdmin.module.css";


const adminsListAll = [
    {
        adminId: 0,
        name: "Анна Копанева",
        teachers:
            {
                active: ["Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)", "Петр Петров (01.03.2020)", "Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)",],
                inactive: ["Максим Максимов (04.04.2020 - 06.06.2020)"]
            },

        students:
            {
                active: ["Максим Максимов (03.08.2020)", "Петр Петров (03.08.2020)", "Артем Артемьев (01.03.2020 - 04.05.2020)",],
                inactive: ["Андрей Андреев (01.01.2019 - 01.01.2020)"]
            },
        date: "01.09.2020",
        mail: "kopaneva@mail.ru",
        phone1: "+7 984 111 11 11",
        phone2: "+7 984 111 22 33 (Mother)",
    },
    {
        adminId: 1,
        name: "Даниил Корнилов Kornilov",
        teachers:
            {
                active: ["Иван Иванов (01.01.2020)",],
                inactive: ["Максим Максимов (04.04.2020 - 06.06.2020)"]
            },
        students:
            {
                active: ["Максим Максимов (03.08.2020)", "Петр Петров (03.08.2020)",],
                inactive: ["Артем Артемьев (01.03.2020 - 04.05.2020)", "Андрей Андреев (01.01.2019 - 01.01.2020)"]
            },
        date: "01.12.2020",
        mail: "kornilov@mail.ru",
        phone1: "+7 984 111 11 11",
        phone2: "+7 984 111 22 33 (Father)",
    },
    {
        adminId: 2,
        name: "Артём Артёмьев",
        teachers:
            {
                active: ["Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)",
                    "Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)",
                    "Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)",
                    "Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)",
                    "Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)",],

                inactive: ["Максим Максимов (04.04.2020 - 06.06.2020)"]
            },
        students:
            {
                active: ["Максим Максимов (03.08.2020)", "Петр Петров (03.08.2020)",],
                inactive: ["Артем Артемьев (01.03.2020 - 04.05.2020)", "Андрей Андреев (01.01.2019 - 01.01.2020)"]
            },

        date: "01.06.2020",
        mail: "artem@mail.ru",
        phone1: "+7 984 111 11 11",
        phone2: "+7 984 111 22 33 (Brother)",
    },
]

class Admins extends React.Component {

    initTogglesState = {
        isActiveDropdown: false,
        isActiveAddAdmin: false,
        isActiveAddTeacher: false,
        isActiveAddStudent: false,

        // isActiveToggleTeacher1: false,
        // isActiveToggleStudent1: false,
        // isActiveToggleContact1: false,
        // isActiveToggleTeacher2: false,
        // isActiveToggleStudent2: false,
        // isActiveToggleContact2: false,
        // isActiveToggleTeacher0: false,
        // isActiveToggleStudent0: false,
        // isActiveToggleContact0: false,
    };

    formAddAdmin = {
        name: '',
        email: '',
        phone1: '',
        phone2: '',
    };

    formAddTeacher = {
        teacherName: '',
        teacherEmail: '',
        teacherPhone1: '',
        teacherPhone2: '',
    };

    formAddStudent = {
        studentName: '',
        studentEmail: '',
        studentPhone1: '',
        studentPhone2: '',
    };


    state = {
        adminsList: Object.assign({}, adminsListAll), ...this.initTogglesState,
        ...this.formAddAdmin, ...this.formAddTeacher, ...this.formAddStudent,
        search: '', searchArr: [],
        admins: Object.assign({}, adminsListAll),
        dynamicInitObj: {},
        warningMassage: '',
    };

     componentDidMount() {
        this.A();
        // console.log(this.state.dynamicInitObj1);
    }

    // test dynamic inition
    A = () => {
        let dynamicInitObj = {};
        let a = Object.values(this.state.admins);
        a.forEach(j => {
            // dynamicInitObj.push(
            //     { ['isActiveToggleTeacher' + j.adminId]: false},
            //     { ['isActiveToggleStudent' + j.adminId]: false},
            //     { ['isActiveToggleContact' + j.adminId]: false},
            //     )

            // this.setState({dynamicInitObj: {['isActiveToggleTeacher' + j.adminId]: false}})
            dynamicInitObj['isActiveToggleTeacher' + j.adminId] = false;
            dynamicInitObj['isActiveToggleStudent' + j.adminId] = false;
            dynamicInitObj['isActiveToggleContact' + j.adminId] = false;
        });
        this.setState({...this.state.dynamicInitObj, dynamicInitObj}, () => {
            // console.log(this.state.dynamicInitObj)
        });
    }

    // SEARCHING
    handleSearchSubmit = (event) => {
        this.setState({search: event.target.value}, () => {
            this.handle(event)
        });
        event.preventDefault();
    }

    handle = (event) => {
        let adminsList = Object.values(this.state.admins);
        // console.log(adminsList);
        let searchArr = [];
        if (this.state.search !== '') {
            adminsList.forEach(i => {
                let objName = i.name;
                let a = objName.toLowerCase().indexOf(this.state.search.toLowerCase());
                if (a !== -1) {
                    searchArr.push(i);
                }
                // for searching in all text fields
                // (Object.values(i)).forEach(j => {
                //
                //     if (typeof j === "string") {
                //         let a = j.toLowerCase().indexOf(this.state.search.toLowerCase());
                //         // console.log(a);
                //         if (a !== -1) {
                //             searchArr.push(i);
                //         }
                //     }
                // })
            })
        }
        if (searchArr.length > 0) {
            this.setState({admins: searchArr});
        } else {
            this.setState({admins: this.state.adminsList});
        }
    }

    ClearSearch = () => {
        this.setState({admins: this.state.adminsList});
    }

    // ADD ADMIN

    RenderFormAddAdmin = () => {
        return (
            <form onSubmit={this.AddAdmin}>
                <div className={s.form_add + " " + s.left}>
                    <input type="text" placeholder="Имя" name="name" required
                           value={this.state.name}
                           onChange={this.handleInputChange}/>
                    <input type="email" placeholder="email" name="email" required value={this.state.email}
                           onChange={this.handleInputChange}/>
                </div>
                <div className={s.form_add + " " + s.right}>
                    <input type="text" placeholder="phone number" name="phone1" required
                           value={this.state.phone1}
                           onChange={this.handleInputChange}/>
                    <input type="text" placeholder="second phone number" name="phone2" value={this.state.phone2}
                           onChange={this.handleInputChange}/>
                </div>
                <div className={s.form_add_submit}>
                    <button className={s.form_add_submit} type="submit" value="Submit">Add Admin</button>
                </div>
            </form>
        );
    }

    AddAdmin = (event) => {
        this.setState({warningMassage: ''});
        // warningMsg т.к стейт не успевает измениться
        let warningMsg = false;
        event.preventDefault();
        let adminsList = Object.values(this.state.adminsList);
        let dateNow = new Date();
        let dd = dateNow.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = dateNow.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let date = dd + "." + mm + "." + dateNow.getFullYear();

        Object.keys(this.state.adminsList).map(i => {
            if (this.state.adminsList[i].name === this.state.name) {
                this.setState({warningMassage: 'Администратор с таким именем уже существует'});
                warningMsg = true;
            }
        })
                    
                    if (!warningMsg) {
                        adminsList.unshift({
                            name: this.state.name,
                            mail: this.state.email,
                            phone1: this.state.phone1,
                            phone2: this.state.phone2,
                            date: date,
                            teachers:
                                {
                                    active: [],
                                    inactive: []
                                },
                            students:
                                {
                                    active: [],
                                    inactive: []
                                },
                            adminId: +new Date,
                        });
                        this.setState({adminsList: adminsList});
                        this.setState({admins: adminsList});
                        this.ToggleAddAdmin();
                    }
    }

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    // add teachers

    RenderFormAddTeacher = () => {
        return (
            <form onSubmit={this.AddTeacher}>
                <div className={s.form_add + " " + s.left}>
                    <input type="text" placeholder="Имя" name="teacherName" required
                           value={this.state.teacherName}
                           onChange={this.handleInputChange}/>
                    <input type="email" placeholder="email" name="email"
                        // value={this.state.teacherEmail}
                           onChange={this.handleInputChange}/>
                </div>
                <div className={s.form_add + " " + s.right}>
                    <input type="text" placeholder="phone number" name="phone1"
                        // value={this.state.teacherPhone1}
                           onChange={this.handleInputChange}/>
                    <input type="text" placeholder="second phone number" name="phone2"
                        // value={this.state.teacherPhone2}
                           onChange={this.handleInputChange}/>
                </div>
                <div className={s.form_add_submit}>
                    <button className={s.form_add_submit} type="submit" value="Submit">Add Teacher</button>
                </div>
            </form>
        );
    }


    AddTeacher = (event) => {
        let adminsList = Object.values(this.state.adminsList);
        Object.keys(this.state.adminsList).map(i => {
                if (this.state.adminIdForAddingTeacher === adminsList[i].adminId) {
                    event.preventDefault();
                    let dateNow = new Date();
                    let dd = dateNow.getDate();
                    if (dd < 10) dd = '0' + dd;
                    let mm = dateNow.getMonth() + 1;
                    if (mm < 10) mm = '0' + mm;
                    let date = dd + "." + mm + "." + dateNow.getFullYear();

                    adminsList[i].teachers.active.unshift(
                        this.state.teacherName + ' (' + date + ')'
                    );
                    this.setState({adminsList: adminsList});
                    this.setState({admins: adminsList});
                    this.ToggleAddTeacher();
                    this.ToggleWindow(this.state['isActiveToggleTeacher' + adminsList[i].adminId]);
                }
            }
        );
    }


    // add students

    RenderFormAddStudent = () => {
        return (
            <form onSubmit={this.AddStudent}>
                <div className={s.form_add + " " + s.left}>
                    <input type="text" placeholder="Имя" name="studentName" required
                           value={this.state.studentName}
                           onChange={this.handleInputChange}/>
                    <input type="email" placeholder="email" name="studentEmail"
                           value={this.state.studentEmail}
                           onChange={this.handleInputChange}/>
                </div>
                <div className={s.form_add + " " + s.right}>
                    <input type="text" placeholder="phone number" name="studentPhone1"
                           value={this.state.studentPhone1}
                           onChange={this.handleInputChange}/>
                    <input type="text" placeholder="second phone number" name="studentPhone2"
                           value={this.state.studentPhone2}
                           onChange={this.handleInputChange}/>
                </div>
                <div className={s.form_add_submit}>
                    <button className={s.form_add_submit} type="submit" value="Submit">Add Student</button>
                </div>
            </form>
        );
    }

    AddStudent = (event) => {
        let adminsList = Object.values(this.state.adminsList);
        Object.keys(this.state.adminsList).map(i => {
                if (this.state.adminIdForAddingStudent === adminsList[i].adminId) {
                    event.preventDefault();
                    let dateNow = new Date();
                    let dd = dateNow.getDate();
                    if (dd < 10) dd = '0' + dd;
                    let mm = dateNow.getMonth() + 1;
                    if (mm < 10) mm = '0' + mm;
                    let date = dd + "." + mm + "." + dateNow.getFullYear();

                    adminsList[i].students.active.unshift(
                        this.state.studentName + ' (' + date + ')'
                    );
                    this.setState({adminsList: adminsList});
                    this.setState({admins: adminsList});
                    this.ToggleAddStudent();
                    this.ToggleWindow(this.state['isActiveToggleStudent' + adminsList[i].adminId]);
                }
            }
        );
    }


    // SORTING
    SortBy = (filter) => {
        let newAdmins = Object.values(this.state.admins);
        if (filter === "teachers") {
            newAdmins.sort((a, b) => (
                a.teachers.active.length < b.teachers.active.length
            ))
        } else if (filter === "teachersLow") {
            newAdmins.sort((a, b) => (
                a.teachers.active.length > b.teachers.active.length
            ))
        } else if (filter === "students") {
            newAdmins.sort((a, b) => (
                a.students.active.length < b.students.active.length
            ))
        } else if (filter === "studentsLow") {
            newAdmins.sort((a, b) => (
                a.students.active.length > b.students.active.length
            ))
        } else {
            newAdmins.sort((a, b) => (
                a[filter] > b[filter]
            ))
        }
        this.setState({admins: newAdmins});
        this.ToggleDropdown();
    }

    // TOGGLES
    ToggleAddAdmin = () => {
        this.setState({isActiveAddAdmin: !this.state.isActiveAddAdmin})
    }

    ToggleAddTeacher = () => {
        this.setState({isActiveAddTeacher: !this.state.isActiveAddTeacher})

    }


    ToggleAddStudent = () => {
        this.setState({isActiveAddStudent: !this.state.isActiveAddStudent})
    }


    ToggleDropdown = () => {
        this.setState({isActiveDropdown: !this.state.isActiveDropdown})
    }

    ToggleWindow = name => {
        this.setState({...this.dynamicInitObj, [name]: !this.state[name]})
    }

    // Rendering
    RenderMap = (arr, mapkey, style = '') => {
        return (
            Object.keys(arr).map(mapkey => (
                <div key={mapkey} className={s.dropdown_teacher_link + " " + style}>
                    {arr[mapkey]}
                </div>
            ))

        );
    };

    RenderAdmins = () => {
        const admins = this.state.admins;


        return (

            <div className={s.admins_list}>
                {Object.keys(admins).map(adminKey => (
                    <div key={adminKey}>
                        <div className={s.admins_list_item}>

                            <div className={s.name}>
                                {admins[adminKey].name}

                            </div>

                            <div className={s.admins_item_right_block}>

                                <div className={s.supervised_teachers}>
                                    <div className={s.supervised_teachers_title}
                                         onClick={() => {
                                             this.ToggleWindow('isActiveToggleTeacher' + admins[adminKey].adminId);
                                             this.setState({adminIdForAddingTeacher: admins[adminKey].adminId});
                                         }}>
                                        <div className={s.supervised_teachers_list}>
                                            Supervised teachers (
                                            {admins[adminKey].teachers.active.length + "/" + admins[adminKey].teachers.inactive.length}
                                            )
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleTeacher' + admins[adminKey].adminId] !== true
                                                ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>
                                    </div>
                                    <div
                                        className={this.state['isActiveToggleTeacher' + admins[adminKey].adminId] === true
                                            ? s.dropdown_visible + ' ' + s.dropdown_teacher : s.dropdown_teacher_hidden}>
                                        <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                             onClick={() => {
                                                 this.ToggleAddTeacher()
                                             }}>
                                            + Add the new teacher
                                        </div>
                                        {this.RenderMap(admins[adminKey].teachers.active, 'activeTeachersKey')}
                                        {this.RenderMap(admins[adminKey].teachers.inactive, 'inactiveTeachersKey', s.dropdown_link_red)}

                                    </div>
                                </div>


                                <div className={s.supervised_students}>
                                    <div className={s.supervised_student_title}
                                         onClick={() => {
                                             this.ToggleWindow('isActiveToggleStudent' + admins[adminKey].adminId);
                                             this.setState({adminIdForAddingStudent: admins[adminKey].adminId});
                                         }}>
                                        <div className={s.supervised_students_list}>
                                            Supervised students (
                                            {admins[adminKey].students.active.length + "/" + admins[adminKey].students.inactive.length}
                                            )
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleStudent' + admins[adminKey].adminId] !== true
                                                ? s.icon_dropdown_toggle
                                                : s.un_icon_dropdown_toggle}>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state['isActiveToggleStudent' + admins[adminKey].adminId] === true
                                            ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>
                                        <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                             onClick={() => {
                                                 this.ToggleAddStudent()
                                             }}>
                                            + Add the new student
                                        </div>

                                        {this.RenderMap(admins[adminKey].students.active, 'activeStudentKey')}
                                        {this.RenderMap(admins[adminKey].students.inactive, 'inactiveStudentKey', s.dropdown_link_red)}

                                    </div>
                                </div>

                                <div className={s.date}>{admins[adminKey].date}</div>

                                <div className={s.contact}>
                                    <div className={s.supervised_student_title}
                                         onClick={() => this.ToggleWindow('isActiveToggleContact' + admins[adminKey].adminId)}>
                                        <div className={s.supervised_students_list}>Contact</div>
                                        <div
                                            className={this.state['isActiveToggleContact' + admins[adminKey].adminId] !== true
                                                ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>
                                    </div>
                                    <div
                                        className={this.state['isActiveToggleContact' + admins[adminKey].adminId] === true
                                            ? s.dropdown_visible + ' ' + s.dropdown_contact : s.dropdown_student_hidden}>
                                        <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                            <img className={s.contact_img}
                                                 src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571c32195480adaa0d37_chat-2.png"></img>
                                            <div>CHAT</div>
                                        </div>

                                        <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                            <img className={s.contact_img}
                                                 src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09586a2ae5446fc1a024d9_envelope.png"></img>
                                            <div>{admins[adminKey].mail}</div>
                                        </div>

                                        <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                            <img className={s.contact_img}
                                                 src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                            <div>{admins[adminKey].phone1}</div>
                                        </div>

                                        <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                            <img className={s.contact_img}
                                                 src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                            <div>{admins[adminKey].phone2}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.devide_line}></div>
                    </div>
                ))}
            </div>
        );
    };


    render() {

        return (
            <div className={s.content_admin}>

                <div className={s.instrument_bar}>
                    <div className={s.dropdown}

                         onClick={() => {
                             this.ToggleDropdown()
                         }}>

                        <div className={s.dropdown_toggle}>
                            SORT BY

                        </div>

                        <div
                            className={this.state.isActiveDropdown !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}>
                        </div>
                    </div>


                    <div
                        className={this.state.isActiveDropdown === true ? s.dropdown_visible_content : s.dropdown_hidden_content}>
                        <div className={s.dropdown_link}

                             onClick={() => {
                                 this.SortBy('name')
                             }}>Name
                        </div>
                        <div className={s.dropdown_link}
                             onClick={() => {
                                 this.SortBy('date')
                             }}>Date added
                        </div>

                        <div className={s.sort_item}>
                            <div>Number of supervised teachers</div>
                            <div className={s.sort_img_wrap}>
                                <div className={s.high + ' ' + s.dropdown_link}
                                     onClick={() => {
                                         this.SortBy('teachers')
                                     }}></div>
                                <div className={s.low + ' ' + s.dropdown_link}
                                     onClick={() => {
                                         this.SortBy('teachersLow')
                                     }}
                                ></div>
                            </div>
                        </div>
                        <div className={s.sort_item}>s.search_btn
                            <div>Number of supervised students</div>
                            <div className={s.sort_img_wrap}>
                                <div className={s.high + ' ' + s.dropdown_link}

                                     onClick={() => {
                                         this.SortBy('students')
                                     }}></div>
                                <div className={s.low + ' ' + s.dropdown_link}
                                     onClick={() => {
                                         this.SortBy('studentsLow')
                                     }}

                                ></div>
                            </div>
                        </div>

                    </div>

                    <div className={s.right_block_instrument_bar}>
                        <div className={s.invite_admin_btn} onClick={() => {
                            this.ToggleAddAdmin();
                        }}>
                            <div className={s.add_icon}></div>
                            <div className={s.invite_admin_btn_txt}>Invite new ADMIN</div>
                        </div>
                        <div className={s.search_btn}>
                            <div className={s.search_icon}></div>
                            {/*<form>*/}
                            <div className={s.search_input}>
                                <input type="text" name="search" placeholder="Search"
                                       value={this.state.search}
                                       onChange={this.handleSearchSubmit}
                                />
                            </div>
                            {/*</form>*/}
                            <div onClick={() => {
                                this.setState({search: ''}, () => {
                                    this.ClearSearch()
                                })
                            }}
                                 className={s.search_btn_txt}>x
                            </div>
                        </div>
                    </div>
                </div>

                <div className={(this.state.warningMassage !== '' && this.state.isActiveAddAdmin !== false) ? s.warning_msg : s.dropdown_hidden_content}>
                    <div>
                    {this.state.warningMassage}
                    </div>
                </div>

                <div className={this.state.isActiveAddAdmin !== false ? s.form_add : s.dropdown_hidden_content}>
                    {this.RenderFormAddAdmin()}
                </div>


                <div className={this.state.isActiveAddTeacher !== false ? s.form_add : s.dropdown_hidden_content}>
                    {this.RenderFormAddTeacher()}
                </div>

                <div className={this.state.isActiveAddStudent !== false ? s.form_add : s.dropdown_hidden_content}>
                    {this.RenderFormAddStudent()}
                </div>

                {this.RenderAdmins()}
            </div>

        );
    }
}

export default Admins;