import React, {useEffect, useState} from "react";

import { Editor, EditorState, ContentState } from 'draft-js';


const EditorComponent = ({el, i, activeTabIndex, options, socketMessage}) => {

    const [editorState, setEditorState] =  useState(el.editorState);
    const [currentVal, setCurrentVal] = useState(el.currentVal);
    const [uniqId, setUniqId] = useState(el.uniqId);
    useEffect(() => {
        options.changeElement({editorState, currentVal}, i, activeTabIndex);
    }, [editorState, currentVal]);

    const onChangeEditor = (editorState) => {
        setEditorState(editorState);
        setCurrentVal(editorState.getCurrentContent().getPlainText('\u0001'));
        options.sendMessage({command: 'handleChangeEditor',
            data: {
                uniqId,
                i,
                currentVal: currentVal,
            }
        });
    }

    return (
        <div key={`tab_${activeTabIndex}_item_${i}`}>
            <Editor
                editorState={editorState}

                onChange={onChangeEditor}
                placeholder="Enter text..."
            />
            <br />
        </div>
    );
}

export default EditorComponent;