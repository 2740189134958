export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const RESET_STEP1_REQUEST = 'RESET_STEP1_REQUEST';
export const RESET_STEP1_SUCCESS = 'RESET_STEP1_SUCCESS';
export const RESET_STEP1_FAILURE = 'RESET_STEP1_FAILURE';

export const RESET_STEP2_REQUEST = 'RESET_STEP2_REQUEST';
export const RESET_STEP2_SUCCESS = 'RESET_STEP2_SUCCESS';
export const RESET_STEP2_FAILURE = 'RESET_STEP2_FAILURE';

export const RECIEVE_AUTH_REQUEST = 'RECIEVE_AUTH_REQUEST';
export const RECIEVE_AUTH_SUCCESS = 'RECIEVE_AUTH_SUCCESS';
export const RECIEVE_AUTH_FAILURE = 'RECIEVE_AUTH_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const INIT_URL = 'INIT_URL';
export const UPDATE_USERINFO = 'UPDATE_USERINFO';
