import lang from '../../../../utils/lang'

const BUY_CLASSES_OBJ = {
    groupLevel1: [
        /** KIDS*/
        {
            isActualAge: true,
            show: true,
            name: 'EpicLang Kids',
            text1: lang('Для детей'),
            text2: lang('(7-10 лет)'),
            iconUrlClass: 'epic_logo_kids',
            ageFrom: 7,
            ageTo: 10,
            groupLevel2: [
                {
                    show: false,
                    text1: lang('Мини-группы'),
                    groupLevel3: []
                },
                {
                    show: true,
                    text1: lang('Индивидуальные занятия'),
                    groupLevel3: [
                        {
                            show: true,
                            text1: '25',
                            text2: lang('минут'),
                            BuyClassesItems: [
                                {
                                    itemId: 31,
                                    itemName: '25__5__NEW Start',
                                    HeaderBig: lang('Стартовый '),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '5',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 25 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('3 500 руб'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('700 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: '',
                                },
                                {
                                    itemId: 32,
                                    itemName: '25__10__NEW Profi',
                                    HeaderBig: lang('Продвинутый'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '10',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 25 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('5 500 руб.'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('550 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 21%'),
                                },
                                {
                                    itemId: 33,
                                    itemName: '25_25__NEW Legend',
                                    HeaderBig: lang('Легендарный'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: lang('Хит'),
                                    CenterHeader: '25',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 25 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('12 500 руб'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('500 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 28%'),
                                },
                                {
                                    itemId: 34,
                                    itemName: '25__50__NEW Epic',
                                    HeaderBig: lang('Эпический'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '50',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 25 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('24 000 руб'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('480 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 32%'),
                                },
                            ]
                        },
                        {
                            show: false,
                            text1: '50',
                            text2: lang('минут'),
                            BuyClassesItems: [

                                {
                                    itemId: 27,
                                    itemName: '50__5__NEW Start',
                                    HeaderBig: lang('Стартовый '),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '5',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 50 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('6 000 руб.'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('1200 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: '',
                                },
                                {
                                    itemId: 28,
                                    itemName: '50__10__NEW Profi',
                                    HeaderBig: lang('Продвинутый'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '10',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 50 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('9 000 руб'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('900 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 25%'),
                                },
                                {
                                    itemId: 29,
                                    itemName: '50__25__NEW Legend',
                                    HeaderBig: lang('Легендарный'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: lang('Хит'),
                                    CenterHeader: '25',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 50 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('21 250 руб.'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('850 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 29%'),
                                },
                                {
                                    itemId: 30,
                                    itemName: '50__50__NEW Epic',
                                    HeaderBig: lang('Эпический'),
                                    HeaderSmall: lang('пакет'),
                                    TopRightText: '',
                                    CenterHeader: '50',
                                    CenterInfo: `${lang('индивидуальных')}<br />VIP<br />${lang('занятий')}`,
                                    CenterComment: lang('(по 50 минут)'),
                                    CenterPlusHeader: '',
                                    CenterPlusInfo: '',
                                    CenterFooter1: lang('ОБЩАЯ СТОИМОСТЬ:'),
                                    CenterFooter2: lang('41 500 руб.'),
                                    FooterTopText: lang('Стоимость каждого занятия:'),
                                    FooterPrice: lang('830 руб'),
                                    FooterButton: lang('ПРИОБРЕСТИ'),
                                    FooterButtonTooltip: '',
                                    FooterBottomRed: lang('Экономия 30%'),
                                },
                            ]
                        }
                    ]
                },
            ]
        },
    ]
};

export default BUY_CLASSES_OBJ;
