import React from 'react';
import MaterialTextField from '@material-ui/core/TextField';

export default function TextField({ value, label, type='text', handleInputChange}) {
    return (
        <MaterialTextField
            required
            type={type}
            label={label}
            value={value}
            fullWidth
            onChange={e => handleInputChange(e.target.value)}
            margin="normal"
            className="mt-1 my-sm-3 authInput"
            // error={!password.isValid}
        />
    );
}