// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_table__r0rUr {
    border-collapse: collapse;
    border: none;
    width: 100%;
    table-layout: fixed;
}

.Table_tableRowHeader__xjNtk {
    background-color: transparent;
    transition: all 0.25s ease;
    border-radius: 10px;
}

.Table_tableHeader__nlk-f {
    background-color: #f1f1f1;
    padding: 12px;
    font-weight: 500;
    text-align: left;
    font-size: 14px;
    color: #2c3e50;
}

.Table_tableHeader__nlk-f:first-child {
    border-top-left-radius: 15px;
}

.Table_tableHeader__nlk-f:last-child {
    border-top-right-radius: 15px;
}

.Table_tableRowItems__EEDpR {
    cursor: auto;
}

.Table_tableRowItems__EEDpR:nth-child(odd) {
    background-color: #f9f9f9;
}

.Table_tableCell__N5SqQ {
    padding: 12px;
    font-size: 14px;
    color: grey;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.Table_tableCellName__GnbYK {
    cursor: pointer;
    width: 200px;
}

.Table_tableCellLanguage__vW0em {
    width: 40px;
}

.Table_tableCellNameFacts__-grJd {
    cursor: pointer;
    width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/SuperAdmin/Lottie/TableComponents/Table.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,eAAe;IACf,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,YAAY;AAChB","sourcesContent":[".table {\r\n    border-collapse: collapse;\r\n    border: none;\r\n    width: 100%;\r\n    table-layout: fixed;\r\n}\r\n\r\n.tableRowHeader {\r\n    background-color: transparent;\r\n    transition: all 0.25s ease;\r\n    border-radius: 10px;\r\n}\r\n\r\n.tableHeader {\r\n    background-color: #f1f1f1;\r\n    padding: 12px;\r\n    font-weight: 500;\r\n    text-align: left;\r\n    font-size: 14px;\r\n    color: #2c3e50;\r\n}\r\n\r\n.tableHeader:first-child {\r\n    border-top-left-radius: 15px;\r\n}\r\n\r\n.tableHeader:last-child {\r\n    border-top-right-radius: 15px;\r\n}\r\n\r\n.tableRowItems {\r\n    cursor: auto;\r\n}\r\n\r\n.tableRowItems:nth-child(odd) {\r\n    background-color: #f9f9f9;\r\n}\r\n\r\n.tableCell {\r\n    padding: 12px;\r\n    font-size: 14px;\r\n    color: grey;\r\n    width: 100%;\r\n    overflow: hidden;\r\n    white-space: nowrap;\r\n}\r\n\r\n.tableCellName {\r\n    cursor: pointer;\r\n    width: 200px;\r\n}\r\n\r\n.tableCellLanguage {\r\n    width: 40px;\r\n}\r\n\r\n.tableCellNameFacts {\r\n    cursor: pointer;\r\n    width: 100px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Table_table__r0rUr`,
	"tableRowHeader": `Table_tableRowHeader__xjNtk`,
	"tableHeader": `Table_tableHeader__nlk-f`,
	"tableRowItems": `Table_tableRowItems__EEDpR`,
	"tableCell": `Table_tableCell__N5SqQ`,
	"tableCellName": `Table_tableCellName__GnbYK`,
	"tableCellLanguage": `Table_tableCellLanguage__vW0em`,
	"tableCellNameFacts": `Table_tableCellNameFacts__-grJd`
};
export default ___CSS_LOADER_EXPORT___;
