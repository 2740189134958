import React from "react";
import s from "./../SuperAdmin.module.css";
import Navlink from "react-router-dom/NavLink";
import {BrowserRouter, Route} from "react-router-dom";
import Kids from "./Kids";
import Pro from "./Pro";
import Junior from "./Junior";
import Chinese from "./Chinese";
import French from "./French";
import All from "./All";



const Path = "/admin/superadmin/demo/";

class Demo extends React.Component {

    initTogglesState = {
        isActiveDropdown: false,
        isActiveToggleTeacher1: false,
        isActiveToggleStudent1: false,
        isActiveToggleContact1: false,
        isActiveToggleTeacher2: false,
        isActiveToggleStudent2: false,
        isActiveToggleContact2: false,
        isActiveToggleTeacher3: false,
        isActiveToggleStudent3: false,
        isActiveToggleContact3: false,
        isActiveToggleCourses1: false,
        isActiveToggleCourses2: false,
        isActiveToggleCourses3: false,
        isActiveToggleScheduled: false,
        isActiveToggleScheduledFinished: false,
    };

    state = {...this.initTogglesState };


    ToggleDropdown = () => {
        this.setState({isActiveDropdown: !this.state.isActiveDropdown})
    }




    render() {


        return (
                <div className={s.content_admin}>

                    <div className={s.instrument_bar}>
                        <div className={s.dropdown} onClick={this.ToggleDropdown}>
                            <div className={s.dropdown_toggle}>
                                SORT BY
                            </div>
                            <div
                                className={this.state.isActiveDropdown !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}>
                            </div>
                        </div>
                        <div
                            className={this.state.isActiveDropdown === true ? s.dropdown_visible_content : s.dropdown_hidden_content}>
                            <div className={s.dropdown_link}>Name</div>
                            <div className={s.dropdown_link}>Date added</div>
                            <div className={s.sort_item}>
                                <div>Number of supervised teachers</div>
                                <div className={s.sort_img_wrap}>
                                    <div className={s.high + ' ' + s.dropdown_link}></div>
                                    <div className={s.low + ' ' + s.dropdown_link}></div>
                                </div>
                            </div>
                            <div className={s.sort_item}>
                                <div>Number of supervised students</div>
                                <div className={s.sort_img_wrap}>
                                    <div className={s.high + ' ' + s.dropdown_link}></div>
                                    <div className={s.low + ' ' + s.dropdown_link}></div>
                                </div>
                            </div>

                        </div>

                        <div className={s.right_block_instrument_bar}>
                            <div className={s.invite_admin_btn}>
                                <div className={s.add_icon}></div>
                                <div className={s.invite_admin_btn_txt}>Invite new TEACHER</div>
                            </div>
                            <div className={s.search_btn}>
                                <div className={s.search_icon}></div>
                                <div className={s.search_btn_txt}>Search</div>
                            </div>
                        </div>
                    </div>

                    <div className={s.tabs_menu}>
                        <Navlink exact to="/admin/superadmin/demo" activeClassName={s.active_tabs} className={s.nav_tabs}>
                            All
                        </Navlink>
                        <Navlink  to="/admin/superadmin/demo/kids" activeClassName={s.active_tabs} className={s.nav_tabs}>
                            Kids
                        </Navlink>
                        <Navlink  to="/admin/superadmin/demo/junior" activeClassName={s.active_tabs} className={s.nav_tabs}>
                           Junior
                        </Navlink>
                        <Navlink  to="/admin/superadmin/demo/pro" activeClassName={s.active_tabs} className={s.nav_tabs}>
                            PRO
                        </Navlink>
                        <Navlink  to="/admin/superadmin/demo/chinese" activeClassName={s.active_tabs} className={s.nav_tabs}>
                            Chinese
                        </Navlink>
                        <Navlink  to="/admin/superadmin/demo/french" activeClassName={s.active_tabs} className={s.nav_tabs}>
                            French
                        </Navlink>


                    </div>

                    <Route exact path={Path} component={All}/>
                    <Route exact path={Path + 'kids'} component={Kids}/>
                    <Route exact path={Path + 'junior'} component={Junior}/>
                    <Route exact path={Path + 'pro'} component={Pro}/>
                    <Route exact path={Path + 'chinese'} component={Chinese}/>
                    <Route exact path={Path + 'french'} component={French}/>




                </div>
        );
    }
}

export default Demo;