/* global alert */
import React from 'react';
import PropTypes from 'prop-types';
import {CancelAjaxRequest, NetworkError} from '../utils/call-api-new';

class Fz extends React.Component {
    ErrorHundler(error) {
        console.log(error, error.status);
        this.setState({
            loading: false
        });

        if (error instanceof NetworkError) {
            this.props.displayErrorMessage({visible: 'yes', message: 'Network error'});
            // alert(this.props.globalErrorDialogVisible)
            // alert(this.props.intl.formatMessage({id: 'error.NetworkError'}));
            this.setState({
                dialogVisible: true,
                formDialogContentId: 'error.NetworkError'
            });
            return Promise.resolve(true);
        }

        if (error instanceof CancelAjaxRequest) {
            return Promise.resolve(true);
        }

        if (error && error.data && error.data.errors) {
            const validationErrors = [...new Set(error.data.errors
                .filter(i => i.type === 'validation')
                .map(i => this.props.intl.formatMessage({
                    id: `error.${i.path}`,
                    defaultMessage: this.props.intl.formatMessage({id: 'error.unknownError'})
                })))];
            if (validationErrors.length) {
                return this.setState({
                    alertVisible: true,
                    alertMessage: `${this.props.intl.formatMessage({id: 'error.formValidationFailed'})}: ${validationErrors.join(', ')}`
                });
            }
        }

        if (error && error.status === 404) {
            return this.setState({
                alertVisible: true,
                alertMessage: this.props.intl.formatMessage({id: 'error.notFound'})
            });
        }

        if (error && error.status && error.status.toString()[0] === '5') {
            // return alert(this.props.intl.formatMessage({id: 'error.serverError'}));
            return console.log(this.props.intl.formatMessage({id: 'error.serverError'}));
        }

        return console.log(this.props.intl.formatMessage({id: 'error.unknownError'}));
    }

    // eslint-disable-next-line class-methods-use-this
    isRegex(value) {
        try {
            // noinspection JSUnusedLocalSymbols
            const re = new RegExp(value); // eslint-disable-line no-unused-vars
        } catch (err) {
            return false;
        }

        return value.length >= 4;
    }
}

Fz.propTypes = {
    displayErrorMessage: PropTypes.func,
    intl: PropTypes.object
};

export default Fz;
