import * as types from '../constants/audit';
import * as dialog from '../constants/dialog';
import callApi from '../utils/call-api';
import handleErrors from '../utils/handle-errors';

export function addDomain(data) {
    const {domain, comment} = data;

    return dispatch => {
        dispatch({
            type: types.ADD_DOMAIN_REQUEST
        });

        return callApi('my/white-domains', 'post', {
            domain,
            comment
        }).then(res => {
            const {code} = res.data;
            console.log(res.data, code);
            if (code === 200) {
                const errors = handleErrors(res);
                dispatch({
                    type: types.SHOW_MESSAGE,
                    payload: errors
                });
            } else {
                dispatch({
                    type: types.ADD_DOMAIN_SUCCESS,
                    payload: data
                });
                dispatch({
                    type: dialog.SHOW_DIALOG
                });
            }
        }).catch(error => {
            dispatch({
                type: types.ADD_DOMAIN_FAILURE,
                payload: error
            });
        });
    };
}

export function editDomain(id) {
    return dispatch => {
        dispatch({
            type: types.EDIT_DOMAIN_REQUEST
        });

        return callApi(`my/white-domains/${id}`, 'get')
            .then(res => {
                dispatch({
                    type: types.EDIT_DOMAIN_SUCCESS,
                    payload: res
                });

                const {domain, comment} = res.data;
                if (domain) {
                    document.querySelector('input[name=domain]').value = domain;
                }
                if (comment) {
                    document.querySelector('input[name=comment]').value = comment;
                }
            }).catch(error => {
                dispatch({
                    type: types.EDIT_DOMAIN_FAILURE,
                    payload: error
                });
            });
    };
}

export function updateDomain(data) {
    const {domain, comment, id} = data;

    return dispatch => {
        dispatch({
            type: types.UPDATE_DOMAIN_REQUEST
        });

        return callApi(`my/white-domains/${id}`, 'put', {
            domain: domain.value,
            comment: comment.value
        }).then(res => {
            const {code} = res.data;
            if (code === 200) {
                const errors = handleErrors(res);
                dispatch({
                    type: types.SHOW_MESSAGE,
                    payload: errors
                });
            } else {
                dispatch({
                    type: types.UPDATE_DOMAIN_SUCCESS,
                    payload: domain.value
                });
                dispatch({
                    type: dialog.SHOW_DIALOG
                });
            }
        }).catch(error => {
            dispatch({
                type: types.UPDATE_DOMAIN_FAILURE,
                payload: error
            });
        });
    };
}

export function showAuditMessage(message) {
    return {
        type: types.SHOW_MESSAGE,
        payload: message
    };
}

export function hideAuditMessage() {
    return {
        type: types.HIDE_MESSAGE
    };
}
