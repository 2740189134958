import React, {useEffect, useState} from 'react';
import s from "../SuperAdmin.module.css";
import './AddLottie.css';
import Table from './TableComponents/index';
import AddLottie from './AddLottie';
import EditLottie from './EditLottie';
import callApi from "../../../../utils/call-api";




const LottieList = () => {
    const [activeAdd, setActiveAdd] = useState(false);
    const [activeEdit, setActiveEdit] = useState( false);
    const [activeEditLottie, setActiveEditLottie] = useState( false);
    const [activeList, setActiveList] = useState(true);
    const [searchData, setSearchData] = useState();
    const [sortLottie, setSortLottie] = useState();
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await callApi('dashboard/getSystemFilesAdmin', 'post', {});
            const filesData =  result && result.data && result.data.filesData || [];
            const prepareData = filesData.map(el => {
                return el
            });

            setData(prepareData);
        };

        fetchData();
    }, []);

    function searchLottie(event) {
        const value = event.target.value;
        const search = data.filter(item => {
            return (
                item.name.toLowerCase().includes(value.toLowerCase())
            )
        })
        setSearchData(search)
    }

    if (sortLottie === true) {
        data.sort(function (a, b) {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })
    } else if (sortLottie === false) {
        data.sort(function (a, b) {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA > nameB) {
                return -1
            }
            if (nameA < nameB) {
                return 1
            }
            return 0
        })
    }


    return (
        <div className={s.content_admin}>
            {activeAdd ? <AddLottie setActiveAdd={setActiveAdd} setActiveList={setActiveList} /> : null}
            {activeEdit ? <EditLottie setActiveEdit={setActiveEdit} setActiveList={setActiveList} activeEditLottie={activeEditLottie}/> : null}
            {activeList ?
                <React.Fragment>
                    <div className='lottie_list_header'>
                        <div className='lottie_list_add' onClick={() => {
                            setActiveAdd(true);
                            setActiveList(false);
                        }}>Add  file</div>
                        <div>
                            <input
                                type='text'
                                className='lottie_input'
                                placeholder="Search"
                                onInput={searchLottie}
                            />
                        </div>
                    </div>
                    <div>
                        <Table
                            data={searchData ? searchData : data}
                            rowsPerPage={10} sort={sortLottie}
                            setSort={setSortLottie}
                            setActiveEdit={setActiveEdit}
                            setActiveList={setActiveList}
                            setActiveEditLottie={setActiveEditLottie}
                        />
                    </div>
                </React.Fragment>
                : null}
        </div>
    )
}

export default LottieList;