import React from "react";
import t from "./Teacher.module.css";
import Navlink from "react-router-dom/NavLink";

class FurtherClasses extends React.Component {
    state = {
        dialogVisible: false,
        cancelWindowVisible: true,
        isActive: false,
        isActiveFL: false,

    };


    ToggleFurtherLessonsMenu = () => {
        this.setState({isActive: !this.state.isActive})
    }

    ToggleFinishedLessonsMenu = () => {
        this.setState({isActiveFL: !this.state.isActiveFL})
    }

    activateDialog() {
        this.setState({dialogVisible: true})
    }

    deactivateDialog() {
        this.setState({dialogVisible: false})
    }

    render() {


        return (

            <div className={t.further_classes}>
                <div data-w-id="4152261c-19cc-9ad1-f6a7-6edea690284b"
                     onClick={this.ToggleFurtherLessonsMenu}
                     className={t.next_classes_title}>
                    <div className={t.next_class_txt}>
                        Следующие занятия
                    </div>
                </div>

                <div
                     className={this.state.isActive === true ? t.further_visible_block : t.further_hidden_block}>

                    <div className={t.further_class_info}>
                        <div className={t.next_class_info_items}>

                            <div className={t.class_type}>
                                <div className={t.text_title}>
                                    Тип занятия:<br/>
                                </div>
                                <div className={t.text_item}>
                                    Мини-группа
                                    <br/>
                                    на 5 человек
                                </div>
                            </div>

                            <div className={t.class_subject}>
                                <div className={t.text_title}>
                                    Предполагаемая тема:<br/>
                                </div>
                                <div className={t.text_item}>
                                    Beginners
                                    <br/>
                                    Unit 3, Lesson 5
                                </div>
                            </div>

                            <div className={t.class_duration}>
                                <div className={t.text_title}>
                                    Продолжительность<br/>
                                    занятия:<br/>
                                </div>
                                <div className={t.text_item}>
                                    30 минут
                                </div>
                            </div>
                            <div className={t.class_review}>
                                <div className={t.text_title}>
                                    Время:
                                </div>
                                <div className={t.text_item}>
                                    1.09.2020<br/>
                                    15:00 (MSK)
                                </div>
                            </div>
                            <div data-w-id="04cc779e-ddbd-0d65-2be8-5fe9e2f2afc1" className={t.countdown}>
                                <div className={t.teacher_review_title}>
                                    Обратный отсчёт:
                                </div>
                                <div className={t.text_item}>
                                    01 : 22 : 33
                                </div>
                                <div className={t.cancel_lesson_btn}
                                     onClick={() => this.setState({cancelWindowVisible: false})}
                                >
                                        <span className={t.cancel_lesson_txt}>
                                            Отменить занятие
                                        </span>
                                </div>
                            </div>



                        </div>
                        <div className={t.students_list}>
                            <div className={t.student_item}>
                                <div className={t.student_photo}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Nastya
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo2}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Софья
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo3}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Mike
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo4}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Юля
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo5}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Дима
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={t.line}> </div>

                        <div className={t.next_class_info_items}>

                            <div className={t.class_type}>
                                <div className={t.text_title}>
                                    Тип занятия:<br/>
                                </div>
                                <div className={t.text_item}>
                                    VIP
                                    <br/>
                                    на 5 человек
                                </div>
                            </div>

                            <div className={t.class_subject}>
                                <div className={t.text_title}>
                                    Предполагаемая тема:<br/>
                                </div>
                                <div className={t.text_item}>
                                    Beginners
                                    <br/>
                                    Unit 3, Lesson 7
                                </div>
                            </div>

                            <div className={t.class_duration}>
                                <div className={t.text_title}>
                                    Продолжительность<br/>
                                    занятия:<br/>
                                </div>
                                <div className={t.text_item}>
                                    60 минут
                                </div>
                            </div>
                            <div className={t.class_review}>
                                <div className={t.text_title}>
                                    Время:
                                </div>
                                <div className={t.text_item}>
                                    1.09.2020<br/>
                                    15:00 (MSK)
                                </div>
                            </div>
                            <div data-w-id="04cc779e-ddbd-0d65-2be8-5fe9e2f2afc1" className={t.countdown}>
                                <div className={t.teacher_review_title}>
                                    Обратный отсчёт:
                                </div>
                                <div className={t.text_item}>
                                    01 : 22 : 33
                                </div>
                                <div className={t.cancel_lesson_btn}
                                     onClick={() => this.setState({cancelWindowVisible: false})}
                                >
                                        <span className={t.cancel_lesson_txt}>
                                            Отменить занятие
                                        </span>
                                </div>
                            </div>



                        </div>
                        <div className={t.students_list}>
                            <div className={t.student_item}>
                                <div className={t.student_photo2}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Софья
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={t.line}> </div>

                        <div className={t.next_class_info_items}>

                            <div className={t.class_type}>
                                <div className={t.text_title}>
                                    Тип занятия:<br/>
                                </div>
                                <div className={t.text_item}>
                                    Мини-группа
                                    <br/>
                                    на 5 человек
                                </div>
                            </div>

                            <div className={t.class_subject}>
                                <div className={t.text_title}>
                                    Предполагаемая тема:<br/>
                                </div>
                                <div className={t.text_item}>
                                    Beginners
                                    <br/>
                                    Unit 3, Lesson 8
                                </div>
                            </div>

                            <div className={t.class_duration}>
                                <div className={t.text_title}>
                                    Продолжительность<br/>
                                    занятия:<br/>
                                </div>
                                <div className={t.text_item}>
                                    30 минут
                                </div>
                            </div>
                            <div className={t.class_review}>
                                <div className={t.text_title}>
                                    Время:
                                </div>
                                <div className={t.text_item}>
                                    1.09.2020<br/>
                                    15:00 (MSK)
                                </div>
                            </div>
                            <div data-w-id="04cc779e-ddbd-0d65-2be8-5fe9e2f2afc1" className={t.countdown}>
                                <div className={t.teacher_review_title}>
                                    Обратный отсчёт:
                                </div>
                                <div className={t.text_item}>
                                    01 : 22 : 33
                                </div>
                                <div className={t.cancel_lesson_btn}
                                     onClick={() => this.setState({cancelWindowVisible: false})}
                                >
                                        <span className={t.cancel_lesson_txt}>
                                            Отменить занятие
                                        </span>
                                </div>
                            </div>



                        </div>
                        <div className={t.students_list}>
                            <div className={t.student_item}>
                                <div className={t.student_photo}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Nastya
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo2}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Софья
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo3}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Mike
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo4}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Юля
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo5}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Дима
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={t.line}> </div>

                        <div className={t.next_class_info_items}>

                            <div className={t.class_type}>
                                <div className={t.text_title}>
                                    Тип занятия:<br/>
                                </div>
                                <div className={t.text_item}>
                                    Мини-группа
                                    <br/>
                                    на 5 человек
                                </div>
                            </div>

                            <div className={t.class_subject}>
                                <div className={t.text_title}>
                                    Предполагаемая тема:<br/>
                                </div>
                                <div className={t.text_item}>
                                    Beginners
                                    <br/>
                                    Unit 3, Lesson 9
                                </div>
                            </div>

                            <div className={t.class_duration}>
                                <div className={t.text_title}>
                                    Продолжительность<br/>
                                    занятия:<br/>
                                </div>
                                <div className={t.text_item}>
                                    30 минут
                                </div>
                            </div>
                            <div className={t.class_review}>
                                <div className={t.text_title}>
                                    Время:
                                </div>
                                <div className={t.text_item}>
                                    1.09.2020<br/>
                                    15:00 (MSK)
                                </div>
                            </div>
                            <div data-w-id="04cc779e-ddbd-0d65-2be8-5fe9e2f2afc1" className={t.countdown}>
                                <div className={t.teacher_review_title}>
                                    Обратный отсчёт:
                                </div>
                                <div className={t.text_item}>
                                    01 : 22 : 33
                                </div>
                                <div className={t.cancel_lesson_btn}
                                     onClick={() => this.setState({cancelWindowVisible: false})}
                                >
                                        <span className={t.cancel_lesson_txt}>
                                            Отменить занятие
                                        </span>
                                </div>
                            </div>



                        </div>
                        <div className={t.students_list}>
                            <div className={t.student_item}>
                                <div className={t.student_photo}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Nastya
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo2}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Софья
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo3}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Mike
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo4}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Юля
                                    </div>
                                </div>
                            </div>
                            <div className={t.student_item}>
                                <div className={t.student_photo5}>

                                </div>
                                <div className={t.student_name}>
                                    <div className={t.text_item}>
                                        Дима
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                </div>
            </div>
        );
    }
}

export default FurtherClasses;