import React, {useState,useEffect} from 'react';
import s from "../SuperAdmin.module.css";
import '../Lottie/AddLottie.css';
import Table from './TableComponents/index';
import AddInterestingFacts from './AddInterestingFacts';
import EditInterestingFacts from './EditInterestingFacts';
import callApi from "../../../../utils/call-api";


const InterestingFactsList = () => {
    const [activeAdd, setActiveAdd] = useState(false);
    const [activeEdit, setActiveEdit] = useState( false);
    const [activeEditFacts, setActiveEditFacts] = useState( false);
    const [activeList, setActiveList] = useState(true);
    const [searchData, setSearchData] = useState();
    const [sortInterestingFacts, setSortInterestingFacts] = useState();
    const [sortTarget, setSortTarget] = useState();
    // const [DataFromServer, setDataFromServer] = useState([]);
    const [Data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await callApi('dashboard/getVirtualLessonEndInformation', 'post', {});
            const filesData =  result && result.data && result.data.informationBlockData || [];
            const prepareData = filesData.map(el => {
                return el
            });
            setData(prepareData);
        };

        fetchData();
    }, []);


    function searchInterestingFacts(event) {
        const value = event.target.value;
        const search = Data.filter(item => {
            return (
                item.description.toLowerCase().includes(value.toLowerCase())
            )
        })
        setSearchData(search)
    }

    if (sortInterestingFacts === true) {
        Data.sort(function (a, b) {
            const nameA = a[sortTarget].toLowerCase();
            const nameB = b[sortTarget].toLowerCase();
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })
    } else if (sortInterestingFacts === false) {
        Data.sort(function (a, b) {
            const nameA = a[sortTarget].toLowerCase();
            const nameB = b[sortTarget].toLowerCase();
            if (nameA > nameB) {
                return -1
            }
            if (nameA < nameB) {
                return 1
            }
            return 0
        })
    } else {
       // setData(DataFromServer);
    }


    return (
        <div className={s.content_admin}>
            {activeAdd ? <AddInterestingFacts setActiveAdd={setActiveAdd} setActiveList={setActiveList} /> : null}
            {activeEdit ? <EditInterestingFacts setActiveEdit={setActiveEdit} setActiveList={setActiveList} activeEditFacts={activeEditFacts}/> : null}
            {activeList ?
                <React.Fragment>
                    <div className='lottie_list_header'>
                        <div className='lottie_list_add' onClick={() => {
                            setActiveAdd(true);
                            setActiveList(false);
                        }}>Add fact</div>
                        <div>
                            <input
                                type='text'
                                className='lottie_input'
                                placeholder="Search"
                                onInput={searchInterestingFacts}
                            />
                        </div>
                    </div>
                    <div>
                        <Table
                            data={searchData ? searchData : Data}
                            rowsPerPage={10} sort={sortInterestingFacts}
                            setSort={setSortInterestingFacts}
                            setActiveEdit={setActiveEdit}
                            setActiveList={setActiveList}
                            setActiveEditFacts={setActiveEditFacts}
                            setSortTarget={setSortTarget}
                            sortTarget={sortTarget}
                        />
                    </div>
                </React.Fragment>
                : null}
        </div>
    )
}

export default InterestingFactsList;