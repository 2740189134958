import React from "react";
import { connect } from 'react-redux';
import s from "../SuperAdmin.module.css";
import Navlink from "react-router-dom/NavLink";
import { Route } from "react-router-dom";
import Select from 'react-select';
import callApi from '../../../../utils/call-api';
import history from '../../../../utils/history';
import TextField from '@material-ui/core/TextField';
import LessonEditor from '../../../lessonEditor/main/LessonEditor';

class Courses extends React.Component {
    state = {
        courses: {},
        isActiveToggleAddNewCourse: false,
        isActiveToggleSubmitCourse: false,
        isActiveToggleSubmitLevel: false,
        isActiveToggleAddNewLevel: false,
        disableLinks: false,

        newCourseLevel: '',
        newCourseLang: '',
        newCourseName: '',

        selectedCourseId: null,
        editLesson: null,
        showLessonEditor: true,
    };

    async componentDidMount() {
        const { isHomework } = this.props;

        const courses = {};
        await callApi('dashboard/getCourses', 'post').then(res => {
            if (res && res.data.ok) {
                res.data.courses.forEach(el => {
                    if (el.id < 28 && (this.props.userInfo?.username !== 'MihailTestAdmin@gmail.com')) {
                        return;
                    }
                    if (courses[el.name]) {
                        if (!courses[el.name].levels) {
                            courses[el.name].levels = [];
                        }

                        courses[el.name].levels.push({
                            id: el.id,
                            name: el.type,
                            isVisible: true,
                            lessons: el.lessons,
                        });
                    } else {
                        if ((isHomework && el.isForHomework) || (!isHomework && !el.isForHomework))
                        courses[el.name] = {
                            isForHomework: el.isForHomework,
                            name: el.name,
                            isVisible: false,
                            content: { levels: '', lessons: '', Teachers: '' },
                            levels: [{
                                id: el.id,
                                name: el.type,
                                isVisible: true,
                            }],
                        };
                    }
                });
            } else {
                alert('Error1');
            }
        }).catch(err => {
            alert('Error2');
            _('err', err);
        });

        //Т.к. если добавить в forEach, тогда вернется pending
        for (let courseKey in courses) {
            for (let i = 0; i < courses[courseKey].levels.length; i++) {
                courses[courseKey].levels[i].lessons = await callApi('dashboard/getLessons', 'POST', {
                    name: courseKey,
                    type: courses[courseKey].levels[i].name
                }).then(lessons => lessons.data.lessonsData[0].lessons).catch(err => {
                });
            }
        }

        _('coursescourses',courses)

        this.setState({ courses });
    }

    ToggleAddNewCourse = () => {
        this.setState({ isActiveToggleAddNewCourse: !this.state.isActiveToggleAddNewCourse });
    };

    ToggleSubmitCourse = () => {
        const { newCourseLevel, newCourseLang, newCourseName } = this.state;
        const { isHomework } = this.props;

        if (!newCourseLevel || !newCourseLang || !newCourseName) {
            return;
        }

        callApi('dashboard/addCourse', 'post', {
            name: newCourseName,
            languageId: newCourseLang,
            type: newCourseLevel,
            isForHomework: !!isHomework,
        }).then(res => {
            if (res && res.data.ok) {
                this.setState({isActiveToggleSubmitCourse: true});
                setTimeout(() => this.setState({isActiveToggleSubmitCourse: false}), 4000);
            }
        }).catch(err => _(err));
    };

    ToggleSubmitLevel = () => {
        this.setState({isActiveToggleSubmitLevel: !this.state.isActiveToggleSubmitLevel})
    };

    ToggleCourses = courseKey => {
        let { courses } = this.state; // Object { kids: {…}, junior: {…} }


        Object.keys(courses).forEach((index) => {
            if (index !== courseKey) {
                courses[index].isVisible = false;
            }
        });

        courses[courseKey].isVisible = !(courses[courseKey].isVisible);
        this.setState({courses});
    };

    RenderCourses = () => {
        const { courses } = this.state;
        _('adsfsgadff', courses)

        return (
            <div className={s.courses_wrap}>

                {Object.keys(courses).map(courseKey => (
                    <div
                        key={courseKey}
                        className={
                            courses[courseKey].isVisible !== false ?
                                s.course_item_blur + ' ' + s.course_item :
                                s.course_item
                        }
                        onClick={() => this.ToggleCourses(courseKey)}
                    >
                        <div className={s.course_title}>
                            {courses[courseKey].name}
                        </div>
                        <div>
                            {Object.keys(courses[courseKey].content).map(i => (
                                <div key={i} className="">
                                    {i + " : " + courses[courseKey].content[i]}
                                </div>
                            ))}
                        </div>
                        <div className={s.course_control_button}>
                            Manage Course
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    RenderLevels = () => {
        const  {courses } = this.state;
        const { isHomework } = this.props;

        return (
            <div>
                {Object.keys(courses).map(courseKey => (
                    <div key={courseKey}
                         className={courses[courseKey].isVisible !== false ? s.courses_levels_wrap : s.hidden_level}>
                        <div className={s.courses_levels_item}>
                            {courses[courseKey].levels.map(j => (
                                <Navlink
                                    to={(isHomework ? '/admin/superadmin/homeworks/' : '/admin/superadmin/courses/') +
                                        courses[courseKey].name + '/' + j.name.replace(' ', '_')}>
                                    <div key={j} className={s.levels} onClick={() => this.setState({disableLinks: true})}>
                                        <div>{j.name}</div>
                                        <div style={{ color: '#0df' }}>( {j.lessons.length} ) <small style={{ color: '#f0f' }}>(id: {j.id})</small></div>
                                    </div>
                                </Navlink>
                            ))}
                        </div>
                        {/*<div className={s.create_new_level_button}>*/}
                        {/*    <div className={s.create_new_level_button_txt} onClick={this.ToggleAddNewLevel}>Create new level </div>*/}
                        {/*</div>*/}
                        <div
                            className={this.state.isActiveToggleAddNewLevel !== false ? s.create_new_level_form : s.hidden_level}>
                            <form action="" className={s.form_level}>
                                <input type="text"
                                       className={this.state.isActiveToggleSubmitLevel !== true ? s.input_course_name : s.hidden_level}
                                       placeholder="Name of the new level" name="input_level_name"/>
                                <input type="button" value="Done"
                                       className={this.state.isActiveToggleSubmitLevel !== true ? s.submit_course_name : s.hidden_level}
                                       onClick={this.ToggleSubmitLevel}/>
                            </form>
                            <div
                                className={this.state.isActiveToggleSubmitLevel !== false ? s.form_done : s.hidden_level}>
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    RenderRoute = () => {
        const { courses, selectedCourseId } = this.state;
        const { isHomework } = this.props;

        return (
            <div>
                {Object.keys(courses).map(courseKey => (
                    <div key={courseKey}>
                        {courses[courseKey].levels.map((level, key) => (
                            <div key={level}>
                                <Route path={(isHomework ? '/admin/superadmin/homeworks/' : '/admin/superadmin/courses/') +
                                    courses[courseKey].name + "/" + level.name.replace(' ', '_')}
                                   component={() => (
                                       <div>
                                           <div className={s.levels_bar}>
                                               <div onClick={() => {
                                                   this.setState({disableLinks: false, selectedCourseId: null});
                                                   history.goBack();
                                               }}
                                                    className={s.back}>
                                                   <div className={s.back_img} />
                                                   Back
                                               </div>
                                               <div className={s.levels_title}>
                                                   {'' + this.capitalizeFirstLetter(courses[courseKey].name) + "->"}
                                                   {this.capitalizeFirstLetter(level.name)}
                                               </div>
                                               <div className={s.create_new_course_button_header}
                                                    onClick={() => {
                                                        this.setState({
                                                            selectedCourseId: level.id,
                                                            editLesson: null,
                                                            showLessonEditor: false,
                                                        }, () => this.setState({showLessonEditor: true}));
                                                    }}
                                               >Create new lesson</div>
                                           </div>
                                           {!selectedCourseId &&
                                           <div className={s.lessons_title}>
                                               {level.lessons
                                                   .sort((a, b) => b.id - a.id)
                                                   .map((lesson, lk) => (
                                                   <div key={lesson.id} className={s.lessonName_item}
                                                        style={{position: "relative"}}
                                                        onClick={() => this.setState({
                                                            selectedCourseId: level.id,
                                                            editLesson: lesson,
                                                        })}
                                                   > {lesson.name}
                                                       <div className="LessonNameItemRemoveLesson"
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                e.preventDefault();

                                                                callApi('dashboard/deleteLesson', 'post', {
                                                                    lessonId: lesson.id,
                                                                }).then(res => {
                                                                    if (res && res.data.ok) {
                                                                        alert('Успешно удален');
                                                                    }
                                                                });
                                                            }}
                                                       >
                                                            Del
                                                       </div>
                                                   </div>
                                               ))}
                                           </div>}
                                       </div>
                                   )}/>
                            </div>
                        ))
                        }
                    </div>
                ))}
            </div>
        );
    };

    SelectCourseLang = () => {
        const levels = [ {value: '2', label: 'English'}, {value: '2', label: 'French'} ];

        return (
            <Select required
                name="courseName" options={levels}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={el => this.setState({ newCourseLang: el.value })}
                placeholder="Select language"
            />
        );
    };

    render() {
        const { selectedCourseId, disableLinks, editLesson, showLessonEditor } = this.state;

        return (
            <div className={s.content_admin + ' ' + s.content_admin_flex}>
                <div
                    className={this.state.isActiveDropdown === true ? s.lesson_visible_content : s.lesson_hidden_content}>
                </div>
                {this.RenderRoute()}
                {!disableLinks && !selectedCourseId && this.RenderCourses()}
                {!disableLinks && !selectedCourseId && this.RenderLevels()}
                {showLessonEditor && selectedCourseId &&
                    <LessonEditor
                        courseId={selectedCourseId}
                        lesson={editLesson}
                    />
                }

                <div className={s.new_course_wrap}>
                    {!disableLinks && <div className={s.create_new_course_button}>
                        <div className={s.create_new_course_text} onClick={this.ToggleAddNewCourse}>Create new course
                        </div>
                    </div>}
                    {
                        this.state.isActiveToggleAddNewCourse && <div
                            className={this.state.isActiveToggleAddNewCourse !== false ? s.form_new_course : s.hidden_level}>
                            <form action="" className={s.form_level}>
                                <TextField
                                    label="Name of the new course"
                                    value={this.state.newCourseName}
                                    fullWidth
                                    onChange={e => {
                                        this.setState({ newCourseName: e.target.value })
                                    }}
                                /> <br/>
                                <TextField
                                    label="Name of the new level"
                                    value={this.state.newCourseLevel}
                                    fullWidth
                                    onChange={e => {
                                        this.setState({ newCourseLevel: e.target.value })
                                    }}
                                /> <br/>
                                <div style={{width: '300px'}}> {this.SelectCourseLang()} </div>
                                <br/>
                                <input type="button" value="Done" onClick={this.ToggleSubmitCourse}/>
                            </form>
                            <br/>
                            <div
                                className={this.state.isActiveToggleSubmitCourse !== false ? s.form_done : s.hidden_level}>
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    userInfo: auth.userInfo,
});

export default connect(mapStateToProps, {})(Courses);
