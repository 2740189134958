import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Classes from './Classes';
import callApi from "../../../utils/call-api";
import config from '../../../config';
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth';
import Lottie from 'react-lottie';
import logoutLottie from '../../../assets/lottie/sign_out.json';
import buyLottie from '../../../assets/lottie/buy.json';
// import dictionaryLottie from '../../../assets/lottie/dictionary.json';
import redDotLottie from '../../../assets/lottie/red_dot.json';
import dictionaryBook from '../../../assets/images/icons/dictionary_book.png';
import logo from '../../../assets/images/icons/white logo vert tn.png';
import './Student.scss';
import Homework from '../common/homework/Homework';
import SkillsLevels from '../common/skillsLevels/SkillsLevels';
import TimeTable from '../common/timeTable/TimeTable';
import EditProfile from '../common/editProfile/EditProfile';
import BuyClasses from '../common/buyClasses/BuyClasses';
// import AfterRegistrationModal from '../common/afterRegistrationModal/AfterRegistrationModal';
import {LevelsData} from '../../admin/common/skillsLevels/LevelsData';

import DnDWrapperForDict from '../../DnDWrapperForDict';
import { closeDict, openDict } from '../../../actions/dictionary';
import { bindActionCreators } from 'redux';
import Dialog from '../../../containers/Dialog';
import history from '../../../utils/history';
import {Declination} from '../../Declination';
import IntlMessages from '../../../utils/IntlMessages';
import lang from '../../../utils/lang';
// import {de} from "faker/lib/locales";


// import switchAccountLottie from '../../../assets/lottie/switch_account.json';
// import bellLottie from '../../../assets/lottie/bell.json';
// import chatLottie from '../../../assets/lottie/chat.json';
// import monitorLottie from '../../../assets/lottie/monitor.json';

const defaultLottieOptions = {
    loop: true,
    autoplay: false,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const MY_SPECIAL_TIMEOUT = 600;
const HOME_PAGE = 'homePage';
const HOME_DICTIONARY = 'homeDictionary';

class AdminStudent extends React.Component {
    state = {
        // isActive: false,
        // isActiveFL: false,

        dialogVisible: false,
        lessonsCount: 0,
        // currentMonthGroupLessonsCount: 0,
        currentMonthVipLessonsCount: 0,
        futureLessonsCount: 0,
        avatarLink: `${config.imageStubGeneratorUrl}/50x50.png`,
        defaultUsername: lang('Аноним'),
        unreadNewsCount: 1,
        homeworksCount: 1,
        conductedLessons: [],
        allTeacherInfo: [],
        homeworkDataIncomplete: [],
        homeworkDataComplete: [],
        futureLessonsData: [],
        nextLessonData: [],
        conductedLessonsIsOpen: 0,
        futureLessonsDataIsOpen: 0,
        nextLessonDataIsOpen: 1,
        lottiesParams: {
            switchAccountLottie: { isPaused: true },
            logoutLottie: { isPaused: true },
            bellLottie: { isPaused: true },
            buyLottie: { isPaused: true },
            chatLottie: { isPaused: true },
            dictionaryLottie: { isPaused: true },
            monitorLottie: { isPaused: true }
        },
        subscriptionsData: {},
        isShowEditProfile: false,
        isShowAfterRegistrationModal: false,
        stateModalsWindow: this.getInitStateModalWindow(),
    };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.stateModalsWindow.isShowHomework &&
            this.state.stateModalsWindow.isShowHomework !== prevState.stateModalsWindow.isShowHomework) {
            this.setState({
                conductedLessonsIsOpen: (this.state.conductedLessonsIsOpen + 1),
                futureLessonsDataIsOpen: (this.state.futureLessonsDataIsOpen + 1),
            });
        }
    }

    init = () => {
        callApi('auth/userInfo', 'GET').then(res => {
            if (res && res.data.ok) {
                this.setState({ userInfo: res.data.userInfo });
                return res.data.userInfo;
            } else {
                this.props.logout();
            }
        })
        .then(userInfo => {
            callApi('dashboard/getStudentSchedule', 'GET', { studentId: userInfo.id })
            .then(res => res.data.scheduleData)
            .then(scheduleData => {
                const conductedLessons = scheduleData.filter(item => item.isConducted);
                const futureLessonsData = scheduleData.filter(item => {
                        return (item.startDate > Date.now()/1000 - 3600) && !item.isConducted;
                    });
                this.setState({
                    conductedLessons: conductedLessons,
                    futureLessonsData: futureLessonsData, 
                    nextLessonData: futureLessonsData.length > 0 ? [futureLessonsData[0]] : [],
                    lessonsCount: scheduleData.length,
                    futureLessonsCount: conductedLessons.length + futureLessonsData.length
                });
            });
            callApi('content/homeWork', 'GET', { studentId: userInfo.id })
            .then(res => res.data.homeWorks)
            .then(homeWorks => {
                const homeworkDataComplete = homeWorks.filter(item=>item.homeworkStudentMark);
                const homeworkDataIncomplete = homeWorks.filter(item=>item.homeworkStudentMark===null||item.homeworkStudentMark===0);;
                this.setState({
                    homeworkDataIncomplete: homeworkDataIncomplete,
                    homeworkDataComplete: homeworkDataComplete,
                })
            });
            callApi('dashboard/getUserSubscriptions', 'POST').then(res => {
                if (res && res.data.ok) {
                    const subscriptionsData = res.data.subscriptionsData ? res.data.subscriptionsData : {};
                    subscriptionsData.groupClassCount = subscriptionsData.groupClassCount ? subscriptionsData.groupClassCount : 0;
                    subscriptionsData.individualClassCount = subscriptionsData.individualClassCount ? subscriptionsData.individualClassCount : 0;
                    subscriptionsData.individualShortClassCount = subscriptionsData.individualShortClassCount ? subscriptionsData.individualShortClassCount : 0;
                    if (typeof(subscriptionsData?.groupClassCount) === 'number' &&
                        typeof(subscriptionsData?.individualClassCount) === 'number' &&
                        typeof(subscriptionsData?.individualShortClassCount) === 'number') {

                            let individualClassCount = subscriptionsData.individualClassCount + subscriptionsData.individualShortClassCount;
                            let groupClassCount = subscriptionsData.groupClassCount;

                            let futureLessonsData = this.state.futureLessonsData;
                            let nextLessonData =  this.state.nextLessonData;

                            futureLessonsData.concat(nextLessonData).forEach(el => {
                                if (el?.isIndividual) {
                                    individualClassCount += 1;
                                } else {
                                    groupClassCount += 1;
                                }
                            });

                            const allClassCount = individualClassCount + groupClassCount;

                            this.setState({
                                allClassCount,
                                individualClassCount,
                                groupClassCount,
                            });
                        }
                    }
            }).catch(e => {
                this.props.logout();
            });
            
        });
 
    }

    ToggleFurtherLessonsMenu = () => {
        this.setState({ isActive: !this.state.isActive })
    };

    ToggleFinishedLessonsMenu = () => {
        this.setState({ isActiveFL: !this.state.isActiveFL })
    };

    setLottieParam(lottieName, param, value) {

        if (typeof lottieName !== 'string' || typeof param !== 'string' || value === undefined) {

            return;
        }

        this.setState(prevState => {
            const lottiesParams = { ...prevState.lottiesParams };
            lottiesParams[lottieName][param] = value;

            return {
                lottiesParams: { ...prevState.lottiesParams }
            }
        })
    }

    closeOpenTimeHomeBuy(item) {
        const currentState = this.state.stateModalsWindow[item];
        this.setState({
            stateModalsWindow: this.getInitStateModalWindow(),
        });

        let setTimeoutTimer = 0;

        const { stateModalsWindow } = this.state;

        for (let i in stateModalsWindow) {
            if (stateModalsWindow[i]) {
                setTimeoutTimer = MY_SPECIAL_TIMEOUT;
            }
        }

        if (!currentState) {
            setTimeout(() => {
                this.setState({
                    stateModalsWindow: {
                        ...this.state.stateModalsWindow,
                        [item]: true,
                    },
                });
            }, setTimeoutTimer);
        }
    }

    getInitStateModalWindow() {
        const obj = {
            isShowHomework: false,
            isShowTimeTable: false,
            isShowSkillsLevels: false,
            isShowBuyClasses: false,
            isDictionary: false,
        }

        return obj;
    }

    levelDetection(number, txt) {

        return (
            <>
                {LevelsData.map(item =>{
                    const index = LevelsData.indexOf(item);
                    const nextIndex = index + 1 > 20 ? 20 : index + 1;
                    const lvl = item.level;
                    const nextLvl = LevelsData[nextIndex].level;

                    if (number === 0 && lvl === 0) {
                        return txt === 'lvl' ? item.levelName :
                            (
                                <div className='lvlItemImg'>
                                    <img src={item.img} alt=""/>
                                </div>
                            )
                    } else if (number >= lvl && number < nextLvl) {
                        return txt === 'lvl' ? item.levelName :
                            (
                                <div className='lvlItemImg'>
                                    <img src={item.img} alt=""/>
                                </div>
                            )
                    } else if (lvl === 200 && number >= 200) {
                        return txt === 'img' ?
                            (
                                <div className='lvlItemImg'>
                                    <img src={item.img} alt=""/>
                                </div>
                            ) : item.levelName
                    }
                })}
            </>
        )
    }

    render() {
        const storage = JSON.parse(localStorage.getItem('user') || null);
        const numberWords = this.props.wordsCount;
        let avatarLink = this.state.avatarLink;
        let username = this.state.defaultUsername;
        const subscriptionsData = this.state.subscriptionsData;
        const { homeworkDataIncomplete, allClassCount, individualClassCount, groupClassCount } = this.state;
        _(this.state.stateModalsWindow.isShowSkillsLevels)

        if (storage) {

            if (storage.username) {
                username = storage.username;
            }

            if (storage.avatarLink) {
                avatarLink = storage.avatarLink;
            }
        }

        return (
            <div className="student">
                <DndProvider backend={HTML5Backend}>
                    <DnDWrapperForDict/>
                </DndProvider>
                {/*<AfterRegistrationModal*/}
                {/*    closeModal={() => this.setState({ isShowAfterRegistrationModal: false })}*/}
                {/*    isShowAfterRegistrationModal={this.state.isShowAfterRegistrationModal}*/}
                {/*    // updateStudent={() => this.init()}*/}
                {/*/>*/}
                <BuyClasses
                    closeModal={() => this.setState({
                        stateModalsWindow: {
                            ...this.state.stateModalsWindow,
                            isShowBuyClasses: false,
                        }
                    })}
                    isShowBuyClasses={this.state.stateModalsWindow.isShowBuyClasses}
                    // updateStudent={() => this.init()}
                />
                <Homework
                    updateStudent={() => this.init()}
                    closeModal={() => this.setState({
                        stateModalsWindow: {
                            ...this.state.stateModalsWindow,
                            isShowHomework: false,
                        }
                    })}
                    isShowHomework={this.state.stateModalsWindow.isShowHomework}
                    subscriptionsData={subscriptionsData}
                    homeworkDataIncomplete={this.state.homeworkDataIncomplete}
                    homeworkDataComplete={this.state.homeworkDataComplete}
                    userId={this.state.userData ? this.state.userData.id : 0}
                />
                <SkillsLevels
                    lessonsCount={this.state.lessonsCount}
                    closeModal={() => this.setState({
                        stateModalsWindow: {
                            ...this.state.stateModalsWindow,
                            isShowSkillsLevels: false,
                        }
                    })}
                    isShowSkillsLevels={this.state.stateModalsWindow.isShowSkillsLevels}
                    subscriptionsData={subscriptionsData}
                    homeworkDataIncomplete={this.state.homeworkDataIncomplete}
                    homeworkDataComplete={this.state.homeworkDataComplete}
                    userId={this.state.userData ? this.state.userData.id : 0}
                />
                <TimeTable
                    closeModal={() => this.setState({
                        stateModalsWindow: {
                            ...this.state.stateModalsWindow,
                            isShowTimeTable: false,
                        }
                    })}
                    isShowTimeTable={this.state.stateModalsWindow.isShowTimeTable}
                    subscriptionsData={subscriptionsData}
                    allTeacherInfo={this.state.allTeacherInfo}
                />
                <EditProfile
                    closeModal={() => this.setState({ isShowEditProfile: false })}
                    isShowEditProfile={this.state.isShowEditProfile}
                    // updateStudent={() => this.init()}
                />
                <Dialog
                    HideDialog={() => this.setState({ isShowOkModalDialog: false })}
                    title={lang("Запись на групповые занятия")}
                    text={lang("Вы успешно подали заявку. Наш менеджер свяжется с вами в кратчайшие сроки")}
                />
                <div className='statistics'>
                    <div className='statistics-wrap-left'>
                        <div className="stat-left-block button_dictionary media-hide"
                             onClick={() => {this.closeOpenTimeHomeBuy('isShowSkillsLevels')}}>
                            <div className="stat-left-block-header">
                                <div><IntlMessages id="Ваш уровень:" /></div>
                                <div className='custom_margin_top'>{this.levelDetection(this.state.lessonsCount, 'lvl')}</div>
                            </div>
                            {this.levelDetection(this.state.lessonsCount, 'img')}
                        </div>
                        <div className='stat-left-block media-show'>
                            <div className="media-header"
                                 style={this.state.stateModalsWindow.isShowSkillsLevels ? {opacity: "1", color: '#00b3ff'}
                                     : {opacity: "0.5"}}
                                 onClick={() => {this.closeOpenTimeHomeBuy('isShowSkillsLevels')}}>
                                <div className='levels-img media-img-levels'></div>
                                <div >
                                    <IntlMessages id="Достижения" />
                                </div>
                            </div>
                        </div>
                        <div className="stat-left-block button_dictionary media-hide"
                             onClick={() => {
                                 if (!this.props.openCloseDict) {
                                     this.props.openDict();
                                 } else {
                                     this.props.closeDict();
                                 }
                             }}>
                            <div className="stat-left-block-header">
                                <IntlMessages id="В вашем словаре" /><br />
                                {numberWords + Declination(numberWords, HOME_DICTIONARY)}
                            </div>
                            <div className="stat-left-block-footer">
                                <img src={dictionaryBook} alt=""/>
                            </div>
                        </div>
                        <div className='stat-left-block media-show'>
                            <div className="media-header"
                                 style={this.state.stateModalsWindow.isDictionary ? {opacity: "1", color: '#00b3ff'}
                                     : {opacity: "0.5"}}
                                 onClick={() => {
                                     this.closeOpenTimeHomeBuy('isDictionary');
                                     if (!this.props.openCloseDict) {
                                         this.props.openDict();
                                     } else {
                                         this.props.closeDict();
                                     }
                                 }}>
                                <div className='levels-img media-img-dict'></div>
                                <div >
                                    <IntlMessages id="Словарь" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='statistics-wrap-middle'>
                        <div className="stat-group-block">
                            <div className="stat-group-block-part media-hide"
                                 onMouseEnter={() => this.setLottieParam('buyLottie', 'isPaused', false)}
                                 onMouseLeave={() => this.setLottieParam('buyLottie', 'isPaused', true)}
                                 onClick={() => this.closeOpenTimeHomeBuy('isShowBuyClasses')}
                            >
                                <div className="stat-group-block-row buy-row scale">
                                    <div className="stat-group-block-title buy-title">
                                        <IntlMessages id="Приобрести" />
                                        <br/>
                                        {/*{lang('занятия и Activity Keys')}*/}
                                        <IntlMessages id="занятия" />
                                    </div>
                                    <div className="stat-group-block-num buy-num">
                                        <div className="buy-lottie">
                                            <Lottie
                                                options={{
                                                    ...defaultLottieOptions,
                                                    animationData: buyLottie,
                                                    initialSegment: [8, 150],
                                                }}
                                                // isPaused={this.state.lottiesParams.buyLottie.isPaused}
                                                isStopped={this.state.lottiesParams.buyLottie.isPaused}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="stat-group-block-part">
                                <div className={"stat-group-block-row blue-border scale2 media-hide" +
                                    (this.state.stateModalsWindow.isShowTimeTable ? ' blue-table' : '')}>
                                    <div className="stat-group-block-title max-width"
                                         onClick={() => this.closeOpenTimeHomeBuy('isShowTimeTable')}
                                    >
                                        <IntlMessages id="Моё" />
                                        <br />
                                        <IntlMessages id="расписание" />
                                    </div>
                                </div>
                                <div className='stat-left-block media-show'>
                                    <div className="media-header"
                                         style={this.state.stateModalsWindow.isShowTimeTable ? {opacity: "1", color: '#00b3ff'}
                                             : {opacity: "0.5"}}
                                         onClick={() => this.closeOpenTimeHomeBuy('isShowTimeTable')}>
                                        <div className='levels-img media-img-calendar'></div>
                                        <div >
                                            <IntlMessages id="Расписание" />
                                        </div>
                                    </div>
                                </div>
                                <div className={"stat-group-block-row blue-border scale2 media-hide" +
                                    (this.state.stateModalsWindow.isShowHomework ? ' blue-table' : '')}>
                                    <div className="stat-group-block-title max-width"
                                         onClick={() => this.closeOpenTimeHomeBuy('isShowHomework')}
                                    >
                                        <IntlMessages id="Домашняя" />
                                        <br />
                                        <IntlMessages id="работа" />
                                    </div>
                                    {(this.props.showCounter && homeworkDataIncomplete.length) ?
                                        <div className='homework-counter'>
                                            {homeworkDataIncomplete.length}
                                        </div> :
                                        null
                                    }
                                </div>
                                <div className='stat-left-block media-show media-homework-counter'>
                                    <div className="media-header"
                                         onClick={() => this.closeOpenTimeHomeBuy('isShowHomework')}>
                                        <div className='media-homework-counter'>
                                            <div className='levels-img media-img-homework' style={this.state.stateModalsWindow.isShowHomework ? {opacity: "1", color: '#00b3ff'}
                                                : {opacity: "0.5"}}></div>
                                            {(this.props.showCounter && homeworkDataIncomplete.length) ?
                                                <div className='homework-counter'>
                                                    {homeworkDataIncomplete.length}
                                                </div> :
                                                null
                                            }
                                        </div>
                                        <div style={this.state.stateModalsWindow.isShowHomework ? {opacity: "1", color: '#00b3ff'}
                                            : {opacity: "0.5"}}>
                                            <IntlMessages id="Дом. работа" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='statistics-wrap-right'>
                        <div className="stat-left-block">
                            <div className="stat-left-block-header">
                                <IntlMessages id="У вас осталось" />
                            </div>
                            <div className="stat-left-block-num">
                                {allClassCount}
                            </div>
                            <div className="stat-left-block-footer">
                                {lang(Declination(allClassCount, HOME_PAGE))}
                            </div>
                        </div>
                        <div className="stat-group-block">
                            <div className="stat-group-block-row block-row-right">
                                <div className="stat-group-block-num">
                                    {groupClassCount}
                                </div>
                                <div className="stat-group-block-title">
                                    <IntlMessages id="Групповых" />
                                    <br />
                                    {lang(Declination(groupClassCount, HOME_PAGE))}

                                </div>
                            </div>
                            <div className="stat-group-block-row block-row-right">
                                <div className="stat-group-block-num">
                                    {individualClassCount}
                                </div>
                                <div className="stat-group-block-title">
                                    <IntlMessages id="VIP" />
                                    <br />
                                    {lang(Declination(individualClassCount, HOME_PAGE))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel left-panel user-data border-with-shadow media-hide"
                     onClick={() => this.setState({ isShowEditProfile: !this.state.isShowEditProfile })}
                >
                    <div className="panel-img-wrap left-panel-img-wrap" style={this.props.userInfo?.userInfo?.avatar ? {} : {background: "#00b3ff"}}>
                        <img className={this.props.userInfo?.userInfo?.avatar ? "id-photo" : "teacher_photo_img"}
                             src={this.props.userInfo?.userInfo?.avatar ?
                                 this.props.userInfo?.userInfo?.avatar :
                                 avatarLink}
                             alt={lang('Avatar')}
                        />
                    </div>
                    <div className="panel-nav left-panel-nav userImg">
                        {storage?.userInfo?.name || username}
                    </div>
                </div>
                <div className="panel user-data border-with-shadow media-show">
                    <div className="panel-img-wrap left-panel-img-wrap">
                        <div style={this.props.userInfo?.userInfo?.avatar ? {width: '90%', height:'90%'} : {background: "#00b3ff", width: '90%', height:'90%', borderRadius:'6px'}}>
                            <img className={this.props.userInfo?.userInfo?.avatar ? "id-photo" : "teacher_photo_img"}
                                 src={this.props.userInfo?.userInfo?.avatar ?
                                     this.props.userInfo?.userInfo?.avatar :
                                     avatarLink}
                                 alt={lang('Avatar')}
                            />
                        </div>
                    </div>
                    <div className="panel-nav left-panel-nav userImg">
                        <div className='media-logo media-show'>
                            <img src={logo} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="panel left-panel right-panel-logout border-with-shadow media-hide"
                     onClick={() => this.props.logout()}
                     onMouseEnter={() => this.setLottieParam('logoutLottie', 'isPaused', false)}
                     onMouseLeave={() => this.setLottieParam('logoutLottie', 'isPaused', true)}
                >
                    <div className="panel-img-wrap left-panel-img-wrap">
                        <div className="logout-lottie">
                            <Lottie
                                options={{
                                    ...defaultLottieOptions,
                                    animationData: logoutLottie
                                }}
                                isStopped={this.state.lottiesParams.logoutLottie.isPaused}
                            />
                        </div>
                    </div>
                    <div className="panel-nav left-panel-nav">
                        <IntlMessages id="Выйти из" />
                        <br />
                        <IntlMessages id="аккаунта" />
                    </div>
                </div>
                <div className="logo-wrap">
                    <div className="red-dot-lottie">
                        <Lottie
                            options={{
                                ...defaultLottieOptions,
                                autoplay: true,
                                animationData: redDotLottie
                            }}
                        />
                    </div>
                    <div className="logo-img"/>
                </div>

                {/*<div className="MobileFirstPage">*/}
                {/*    <div className='statistics-wrap-right'>*/}
                {/*        <div className="stat-left-block">*/}
                {/*            <div className="stat-left-block-header">*/}
                {/*                <IntlMessages id="У вас осталось" />*/}
                {/*            </div>*/}
                {/*            <div className="stat-left-block-num">*/}
                {/*                {allClassCount}*/}
                {/*            </div>*/}
                {/*            <div className="stat-left-block-footer">*/}
                {/*                {lang(Declination(allClassCount, HOME_PAGE))}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="stat-group-block">*/}
                {/*            <div className="stat-group-block-row block-row-right">*/}
                {/*                <div className="stat-group-block-num">*/}
                {/*                    {groupClassCount}*/}
                {/*                </div>*/}
                {/*                <div className="stat-group-block-title">*/}
                {/*                    <IntlMessages id="Групповых" />*/}
                {/*                    <br />*/}
                {/*                    {lang(Declination(groupClassCount, HOME_PAGE))}*/}

                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="stat-group-block-row block-row-right">*/}
                {/*                <div className="stat-group-block-num">*/}
                {/*                    {individualClassCount}*/}
                {/*                </div>*/}
                {/*                <div className="stat-group-block-title">*/}
                {/*                    <IntlMessages id="VIP" />*/}
                {/*                    <br />*/}
                {/*                    {lang(Declination(individualClassCount, HOME_PAGE))}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="schedule">
                    <Classes
                        updateStudent={() => {
                            history.push(global.authUrl);
                        }}
                        nextLessonData={this.state.nextLessonData}
                        isOpen={this.state.nextLessonDataIsOpen}
                        lessonsCount={this.state.nextLessonData.length}
                    />
                    <Classes
                        updateStudent={() => {
                            history.push(global.authUrl);
                        }}
                        futureLessonsData={this.state.futureLessonsData}
                        isOpen={this.state.futureLessonsDataIsOpen}
                        lessonsCount={this.state.futureLessonsCount}
                    />
                    <Classes
                        updateStudent={() => {
                            history.push(global.authUrl);
                        }}
                        conductedLessons={this.state.conductedLessons}
                        isOpen={this.state.conductedLessonsIsOpen}
                        lessonsCount={this.state.lessonsCount} //LessonsCount === conductedLessonsCount
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        openDict,
        closeDict,
        logout,
    },
    dispatch
);

const mapStateToProps = ({ dictionary, auth, homeWork }) => ({
    openCloseDict: dictionary.openCloseDict,
    userInfo: auth.userInfo,
    showCounter: homeWork.showCounter,
    wordsCount: dictionary.wordsCount,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminStudent);
