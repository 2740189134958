import React, {useEffect, useState} from "react";
import Button from '@material-ui/core/Button/Button';
import Input from '@material-ui/core/Input';
import Radio from "@material-ui/core/Radio/Radio";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';

import l from '../main/LessonEditor.module.scss';
import config from '../../../config';
import { v1 } from '../../../utils/uuid';

const StyledImgGrid = withStyles({
    root: { borderRadius: '50px', maxHeight: '50vh', maxWidth: '980px', boxShadow: '0px 2px 3px 0px rgba(34, 60, 80, 0.2)'}
})(CardMedia);

const ImgGrid = ({el, i, activeTabIndex, options, socketMessage, isDnD = false, isDnD2 = false}) => {
    const [heightPriority, setHeightPriority] = useState(el.heightPriority);
    const [heightInVh, setHeightInVh] = useState(el.heightInVh);
    const [widthInPercent, setWidthInPercent] = useState(el.widthInPercent);
    const [widthPriority , setWidthPriority] = useState(el.widthPriority);
    const [elRows, setElRows] = useState(el.rows);
    const [gridData, setGridData] = useState(el.gridData);
    const [elColumns, setElColumns] = useState(el.rows[0].length);
    const [isPresentation, setIsPresentation] = useState(options.isPresentation);
    const [gridMaxRowsCount, setGridMaxRowsCount] = useState(options.gridMaxRowsCount || 5);
    const [gridMaxColumnsCount, setGridMaxColumnsCount] = useState(options.gridMaxColumnsCount || 5);

    useEffect(() => {
        options.changeElement({heightPriority, heightInVh, widthInPercent, widthPriority, elRows}, i, activeTabIndex);
    }, [heightPriority, heightInVh, widthInPercent, widthPriority, elRows]);

    const updateGridSize = (rows, columns) => {
        
        if (rows < 1 || columns < 1) {
            setElRows([]);
            return;
        }

        const newRows = [];
        for (let i = 0; i < rows; i++) {
            if (elRows[i]) {
                newRows[i] = elRows[i];
            } else {
                newRows[i] = [];
            }

            if (columns >= newRows[i].length) {
                for (let j = 0; j < columns; j++) {
                    if (elRows[i] && typeof elRows[i][j] === 'object') {
                        newRows[i][j] = elRows[i][j];
                    } else {
                        newRows[i][j] = {
                            src: `${config.imageStubGeneratorUrl}/100x100.png`,
                            uuid: v1()
                        };
                    }
                }
            } else {
                newRows[i].splice(columns, elRows[i].length);
            }
        }
        setElRows([...newRows]);
        return;
    };
    

    return (
        <div className="WIG" key={`tab_${activeTabIndex}_item_${i}`}>
            <div>
                {!isPresentation &&
                    <>
                        <>
                            Height in VH<Radio name={`img${activeTabIndex}${i}`}
                                checked={!!heightPriority}
                                onChange={() => {
                                    setHeightPriority(!heightPriority);
                                    setWidthPriority(!widthPriority);
                                }}
                            />
                            <Input type="number"
                                value={heightInVh}
                                onChange={e => {
                                    if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                                        setHeightInVh(e.target.value);
                                    }
                                }}
                            />
                            Width in %<Radio name={`img${activeTabIndex}${i}`}
                                checked={!!widthPriority}
                                onChange={() => {
                                    setHeightPriority(!heightPriority);
                                    setWidthPriority(!widthPriority);
                                }}
                            />
                            <Input type="number"
                                value={widthInPercent}
                                onChange={e => {                   
                                    if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                                        setWidthInPercent(e.target.value);
                                    }
                                }}
                            />
                        </>
                        <div className={l.row}>
                            <div className={l.WIT_button}>
                                <FormControl variant="outlined" >
                                    <InputLabel id="rows-label">Rows</InputLabel>
                                    <Select onChange={(e) => updateGridSize(e.target.value, elColumns)}
                                            labelId="rows-label" value={elRows.length} label="Rows">
                                        {Array.from({ length: gridMaxRowsCount }).map((elem, i) =>
                                            <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className={l.WIT_button}>
                                <FormControl variant="outlined" className={l.WIT_button}>
                                    <InputLabel id="columns-label">Columns</InputLabel>
                                    <Select onChange={(e) => updateGridSize(elRows.length, e.target.value)}
                                            labelId="columns-label" value={elColumns} label="Columns">
                                        {Array.from({ length: gridMaxColumnsCount }).map((elem, i) =>
                                            <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                            <br /> <br />
                        </div>
                    </>
                }
                {elRows.map(row =>
                    <Grid container direction="row" justify="center" alignItems="center"
                          spacing={gridData.spacing}
                    >
                        {row.map((item, j) =>
                            <Grid item xs container
                                  style={{justifyContent: ((row.length > 2  || row.length === 1) ?
                                          'center' : (j % 2 === 0 ? 'right' : 'left'))}}
                            >
                                <Paper className="paper"
                                       style={{alignItems: ((row.length > 2  || row.length === 1) ?
                                               'center' : (j % 2 === 0 ? 'flex-end' : 'flex-start'))}}
                                >
                                    <StyledImgGrid component="img" src={item.src}
                                                   style={heightPriority ? {height: heightInVh + 'vh'} : {width: widthInPercent + '%'}}
                                    />
                                    {!isPresentation &&
                                        <div>
                                            <label htmlFor="file-upload" className="custom-file-upload"
                                                   style={{border: '1px solid #ccc', display: 'inline-block', padding: '6px 12px', cursor: 'pointer'}}
                                            >Select</label>
                                            <input id="file-upload" type="file" name="file"
                                                   style={{ display: 'none' }}
                                                   accept="image/png, image/gif, image/jpeg"
                                                   onChange={e =>  setSelectedFile(e.target.files[0])}
                                            />
                                            {isDnD &&
                                                <input type="text" name="text"
                                                       placeholder={isDnD2 ? 'true_variant|wrong1|wrong2...' : 'enter text'}
                                                       value={item.text}
                                                       onChange={e => {
                                                           const maxInputLength = isDnD2 ?
                                                               (GRID_IMG_INPUT_MAX_LETTERS * 2) : GRID_IMG_INPUT_MAX_LETTERS
                                                           if (e.target.value.length <= maxInputLength) {
                                                               item.text = e.target.value;
                                                               setElRows([...elRows]);
                                                           }
                                                       }}
                                                />
                                            }
                                            <Button onClick={() => {
                                                const { selectedFile } = this.state;
                                                const formData = new FormData();

                                                document.getElementById('file-upload').value = '';
                                                formData.append('file', selectedFile);
                                                formData.append('name', 'test');
                                                callApi('dashboard/uploadFile', 'post', formData, 'multipart/form-data').then(res => {
                                                    if (res && res.data && res.data.length && res.data.fileName) {
                                                        item.src = res.data.fileName.substr(config.filePathSubstLength,);
                                                        setElRows([...elRows]);
                                                    }
                                                }).catch(err => {
                                                    console.log(err)
                                                });
                                            }}>Upload</Button>
                                        </div>}
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                )}
            </div>
            <br />
        </div>
    );
};

export default ImgGrid;
