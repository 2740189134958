import simplify from 'simplify-js';

let myColor = '#000';
let myWidth = 10;

export default function draw (socket, uuid, val, withBackground = true,
                              getIsUserHaveNotControl = () => false,
                              getCanvasImgContainerRef = () => {}
) {
    return function sketch (p) {
        let queue = [];
        let queueToWss = [];

        const render = (myQ, isMouseUp = false) => {
            try {
                const queue_ = simplify(myQ, 5);

                for (let i = 0; i < queue_.length - 1; i++) {
                    if (queue_[i] && queue_[i + 1]) {
                        p.line(queue_[i].x, queue_[i].y, queue_[i + 1].x, queue_[i + 1].y);
                        queueToWss.push(queue_[i]);

                        if (!queue_[i + 2]) {
                            queueToWss.push(queue_[i +1]);
                        }
                    }
                }

                if (isMouseUp) {
                    p.sendmouse(queueToWss);
                    queueToWss = [];
                }
            } catch (e) {}
        };

        let color = '#000';
        let width = 10;
        let cv;

        p.setup = () => {
            // cv = p.createCanvas(p.windowWidth / 2, p.windowHeight / 2);
            cv = p.createCanvas(2000, 2000);

            // centerCanvas()
            cv.clear();

            if (withBackground) {
                cv.background(255, 255, 255);
            }

            if (typeof socket.addEventListener === 'function') {
                socket.addEventListener('message', e => {
                    try {
                        const data = JSON.parse(e.data);

                        if (data.command === 'draw' && data.data.uuid === uuid) {
                            p.stroke(data.data.color);

                            let canvasImgContainerWidthCurrent = getCanvasImgContainerRef();
                            canvasImgContainerWidthCurrent = (canvasImgContainerWidthCurrent && canvasImgContainerWidthCurrent.current) ?
                                canvasImgContainerWidthCurrent.current.clientWidth : 0;

                            const canvasImgContainerWidth = data.data.canvasImgContainerWidth;

                            if (Array.isArray(data.data.arr)) {
                                const prepareArr = simplify(data.data.arr, 5);

                                for (let i = 0; i < prepareArr.length - 1; i++) {
                                    if (prepareArr[i] && prepareArr[i + 1]) {

                                        let strokeWidth = data.data.strokeWidth;
                                        let mouseX = prepareArr[i].x;
                                        let mouseY = prepareArr[i].y;
                                        let pmouseX = prepareArr[i + 1].x;
                                        let pmouseY = prepareArr[i + 1].y;

                                        if (canvasImgContainerWidthCurrent > 0 && canvasImgContainerWidth > 0) {
                                            const scale = canvasImgContainerWidthCurrent / canvasImgContainerWidth;
                                            mouseX *= scale;
                                            mouseY *= scale;
                                            pmouseX *= scale;
                                            pmouseY *= scale;
                                            strokeWidth *= scale;
                                        }

                                        p.strokeWeight(strokeWidth);
                                        p.line(mouseX, mouseY, pmouseX, pmouseY);
                                    }
                                }
                            }
                        }

                        if (data.command === 'dialogChangeColor') {
                            color = data.data.color;
                            myColor = data.data.color;
                            width = data.data.width;
                            myWidth = data.data.width;
                        }

                        if (data.command === 'dialogWhiteboardClear') {
                            if (withBackground) {
                                cv.background(255, 255, 255);
                            } else {
                                cv.clear();
                            }
                        }

                    } catch (e) {}
                });
            }
        };

        // p.windowResized = () => {
        //     p.centerCanvas();
        //     p.resizeCanvas(p.windowWidth / 2, p.windowHeight / 2, false);
        // }

        p.centerCanvas = () => {
            try {
                const x = (p.windowWidth - p.width) / 2;
                const y = (p.windowHeight - p.height) / 2;
                cv.position(x, y);
            } catch (e) {}
        };

        p.mouseDragged = () => {
            try {
                if (!getIsUserHaveNotControl()) {
                    p.stroke(color);
                    p.strokeWeight(width);

                    if (queue.length < 21) {
                        queue.push({
                            x: (p.mouseX >> 0),
                            y: (p.mouseY >> 0),
                        });
                    } else {
                        render(queue);

                        const arrLen = queue.length - 1;
                        const a1 = queue[arrLen];
                        const a2 = queue[arrLen - 1];
                        queue = [a2, a1];
                    }
                }
            } catch (e) {}
        };

        p.mouseReleased = (event) => {
            render(queue, true)
            queue = [];
        }

        p.sendmouse = (arr) => {
            try {
                let canvasImgContainerWidth = getCanvasImgContainerRef();
                canvasImgContainerWidth = (canvasImgContainerWidth && canvasImgContainerWidth.current) ? canvasImgContainerWidth.current.clientWidth : 0;

                const data = {
                    canvasImgContainerWidth,
                    arr,
                    color: myColor,
                    strokeWidth: myWidth,
                    uuid,
                };

                if (typeof socket.send === 'function') {
                    socket.send(JSON.stringify({
                        command: 'draw',
                        data,
                        val: val,
                    }));
                }

            } catch (e) {}
        }
    }
}
