import React from "react";
import s from "../SuperAdmin.module.css";
import callApi from "../../../../utils/call-api";
import Select from 'react-select';
import Button from '@material-ui/core/Button';
// const adminsListAll = [
//     {
//         name: "Анна Копанева",
//         teachers:
//             {
//                 active: ["Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)",],
//                 inactive: ["Максим Максимов (04.04.2020 - 06.06.2020)"]
//             },
//         students:
//             {
//                 active: ["Максим Максимов (03.08.2020)", "Петр Петров (03.08.2020)", "Артем Артемьев (01.03.2020 - 04.05.2020)",],
//                 inactive: ["Андрей Андреев (01.01.2019 - 01.01.2020)"]
//             },
//         date: "01.09.2020",
//         mail: "kopaneva@mail.ru",
//         phone1: "+7 984 111 11 11",
//         phone2: "+7 984 111 22 33 (Mother)",
//     },
//     {
//         name: "Даниил Корнилов",
//         teachers:
//             {
//                 active: ["Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)",],
//                 inactive: ["Максим Максимов (04.04.2020 - 06.06.2020)"]
//             },
//         students:
//             {
//                 active: ["Максим Максимов (03.08.2020)", "Петр Петров (03.08.2020)",],
//                 inactive: ["Артем Артемьев (01.03.2020 - 04.05.2020)", "Андрей Андреев (01.01.2019 - 01.01.2020)"]
//             },
//         date: "01.10.2020",
//         mail: "kornilov@mail.ru",
//         phone1: "+7 984 111 11 11",
//         phone2: "+7 984 111 22 33 (Father)",
//     },
//     {
//         name: "Артём Артёмьев",
//         teachers:
//             {
//                 active: ["Иван Иванов (01.01.2020)", "Петр Петров (01.03.2020)",],
//                 inactive: ["Максим Максимов (04.04.2020 - 06.06.2020)"]
//             },
//         students:
//             {
//                 active: ["Максим Максимов (03.08.2020)", "Петр Петров (03.08.2020)",],
//                 inactive: ["Артем Артемьев (01.03.2020 - 04.05.2020)", "Андрей Андреев (01.01.2019 - 01.01.2020)"]
//             },
//         date: "01.11.2020",
//         mail: "artem@mail.ru",
//         phone1: "+7 984 111 11 11",
//         phone2: "+7 984 111 22 33 (Brother)",
//     },
// ]

class Supervisors extends React.Component {

    initTogglesState = {
        activeAddSupUser: null,
        activeAddSupSupervisor: null,
        activeAddStudent: null,
        activeAddCourse: null,
        activeToggleAddSupSupervisor: false,
        activeToggleAddStudent: false,
        activeToggleAddCourse: false,
        successSupervAdd: false,

        isActiveDropdown: false,
        isActiveAddAdmin: false,
        isActiveAddTeacher: false,
        isActiveAddStudent: false,


    };

    formAddAdmin = {
        name: '',
        email: '',
        phone1: '',
        phone2: '',
    };

    formAddTeacher = {
        teacherName: '',
        teacherEmail: '',
        teacherPhone1: '',
        teacherPhone2: '',
    };

    formAddStudent = {
        studentName: '',
        studentEmail: '',
        studentPhone1: '',
        studentPhone2: '',
    };

    state = {
        // adminsList: Object.assign({}, adminsListAll), ...this.initTogglesState,
        ...this.formAddAdmin, ...this.formAddTeacher, ...this.formAddStudent,
        search: '', searchArr: [],
        // admins: Object.assign({}, adminsListAll),
        dynamicInitObj: {},
        warningMassage: '',

        supervisorsByCourseList: {},
        supervisorsByCourse: {},
        selectedDemo: {},

        successSupervAdd: false,
    };


    componentDidMount() {

        // this.A();
        let students, supervisorsUsers, teachersCourses, courses, teachers, supervisors, demos;

        callApi('dashboard/getCourses', 'POST').then(res => {
            if (res && res.data.ok) {
                courses = res.data.courses;
            }
        }).catch(err => {
        });

        callApi('dashboard/getUsers', 'get').then(res => {
            if (res && res.data.ok) {
                students = res.data.students.rows;
                teachers = res.data.teachers.rows;
                supervisors = res.data.supervisors.rows;
                demos = res.data.demos.rows;
            }
            console.log(demos, 'demos');
        }).catch(err => {
        });


        callApi('dashboard/getSupervisorsUsers', 'POST').then(res => {
            if (res && res.data.ok) {
                supervisorsUsers = res.data.supervisorsUsers;

            }


            let supervisorsByCourseList = {all: []};

            const pF = () => {
                const data = [];

                supervisors.forEach(supervisor => {

                    const supervisedActiveStudents = [];
                    const supervisedInactiveStudents = [];

                    supervisorsUsers.forEach(sv => {
                        if (supervisor.id === sv.supervisorId) {
                            const supervisedStudent = students.find(s => sv.userId === s.id);
                            if (supervisedStudent) {
                                (supervisedStudent.isActive) ? supervisedActiveStudents.push(supervisedStudent) : supervisedInactiveStudents.push(supervisedStudent);
                            }
                        }
                    });

                    const supervisedActiveTeachers = [];
                    const supervisedInactiveTeachers = [];
                    const selectListTeacher = [];
                    const selectListStudent = [];

                    supervisorsUsers.forEach(sv => {
                        if (supervisor.id === sv.supervisorId) {
                            const supervisedTeacher = teachers.find(t => sv.userId === t.id);
                            if (supervisedTeacher) {
                                (supervisedTeacher.isActive) ? supervisedActiveTeachers.push(supervisedTeacher) : supervisedInactiveTeachers.push(supervisedTeacher);
                            }
                        }
                    });

                    teachers.forEach(t => {

                        if (!supervisedActiveTeachers.find(s => s.name === t.name)) {
                            selectListTeacher.push(t);
                        }
                    })

                    let sti = selectListTeacher.map(s => ({
                        value: s.id,
                        label: s.name,
                    }))

                    students.forEach(st => {

                        if (!supervisedActiveStudents.find(s => s.name === st.name)) {
                            selectListStudent.push(st);
                        }
                    })

                    let ssi = selectListStudent.map(s => ({
                        value: s.id,
                        label: s.name,
                    }))


                    const supervisorInfo = {
                        name: supervisor.name, mail: supervisor.email, phone1: supervisor.phoneNumber,
                        phone2: supervisor.phoneNumber, id: supervisor.id,
                        students: {active: supervisedActiveStudents, inactive: supervisedInactiveStudents},
                        teachers: {active: supervisedActiveTeachers, inactive: supervisedInactiveTeachers},
                        date: this.getDateFunc(supervisor.creationDate),
                        selectListTeacher: sti,
                        selectListStudent: ssi,
                    };


                    data.push(supervisorInfo);
                });

                this.setState({
                        supervisorsByCourseList: {all: data},
                        supervisors,
                        courses,
                        students,
                        supervisorsByCourse: {all: data},
                        teachers,
                        demos,
                    },
                    () => {
                        console.log('state', this.state.supervisorsByCourseList);
                    }
                );

            };

            if (teachers && supervisorsUsers && teachersCourses && courses) {
                pF();
            } else {
                setTimeout(() => pF(), 1000);
            }
        }).catch(err => {
        });
    }

    makeSelectList = () => {
        let supervisors = this.state.supervisorsByCourseList;
        console.log('supervisors', supervisors);
        /*if (!!students && !!supervisors ) {
            students.map(student => {
// console.log('student', student);

                // учителя еще не относящиеся к данной группе для возможности добавления
                // в селекте add
                const selectListSup = [];
                supervisors.forEach(sup => {

                    if (!student.supervisors.active.includes(`${sup.name} [${sup.email}] `)) {
                        selectListSup.push(sup);
                    }
                })

                student.selectListSup = selectListSup.map(st => ({
                    value: st.id,
                    label: `${st.name} [${st.email}]`,
                }));
                console.log(student.selectListSup, 'selectListSup');
                this.setState({students: {activeCourses: {all: student.selectListSup}}})

                const {activeAddSupUser} = this.state;
                const actualSup =
                    // student.selectListSup.find(el => el.value === activeAddTeacher) ||
                    student.selectListSup[0] || {};
                student.actualSup = actualSup;
                this.setState({students: {activeCourses: {all: student.actualSup}}})

            })
            console.log(this.state, 'state');
        }*/

    }


    selectDemoUser = () => {
        const demos = this.state.students || [];
        const {selectedDemo} = this.state;
        const select = demos.map(el => ({...el, value: el.id, label: el.email + ' (' + el.name + ')'}));

        return (<Select required className={s.select_course_name} defaultValue={selectedDemo}
                        name="courseName" options={select}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={selectedDemo => this.setState({
                            selectedDemo,
                            name: selectedDemo.name,
                            email: selectedDemo.email,
                            phone1: selectedDemo.phoneNumber || ''
                        })}
        />);
    };

    getDateFunc = timestamp => {
        let t = (!!timestamp) ? new Date(timestamp * 1000) : new Date();
        let dd = t.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = t.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let date = dd + "." + mm + "." + t.getFullYear();
        return date;
    }


    // test dynamic inition
    /*A = () => {
        let dynamicInitObj = {};
        let a = Object.values(this.state.supervisorsByCourseList);
        a.forEach(j => {
            // dynamicInitObj.push(
            //     { ['isActiveToggleTeacher' + j.adminId]: false},
            //     { ['isActiveToggleStudent' + j.adminId]: false},
            //     { ['isActiveToggleContact' + j.adminId]: false},
            //     )

            // this.setState({dynamicInitObj: {['isActiveToggleTeacher' + j.adminId]: false}})
            dynamicInitObj['isActiveToggleTeacher' + j.adminId] = false;
            dynamicInitObj['isActiveToggleStudent' + j.adminId] = false;
            dynamicInitObj['isActiveToggleContact' + j.adminId] = false;
        });
        this.setState({...this.state.dynamicInitObj, dynamicInitObj}, () => {
            // console.log(this.state.dynamicInitObj)
        });
    }*/

    // SEARCHING
    handleSearchSubmit = (event) => {
        this.setState({search: event.target.value}, () => {
            this.handle(event)
        });
        event.preventDefault();
    }

    handle = (event) => {
        let adminsList = Object.values(this.state.supervisorsByCourseList);
        let searchArr = [];
        if (this.state.search !== '') {
            adminsList.forEach(i => {
                let objName = i.name;
                let a = objName.toLowerCase().indexOf(this.state.search.toLowerCase());
                if (a !== -1) {
                    searchArr.push(i);
                }
                // for searching in all text fields
                // (Object.values(i)).forEach(j => {
                //
                //     if (typeof j === "string") {
                //         let a = j.toLowerCase().indexOf(this.state.search.toLowerCase());
                //         // console.log(a);
                //         if (a !== -1) {
                //             searchArr.push(i);
                //         }
                //     }
                // })
            })
        }
        if (searchArr.length > 0) {
            this.setState({upervisorsByCourseList: searchArr});
        } else {
            this.setState({upervisorsByCourseList: this.state.supervisorsByCourseList});
        }
    }

    ClearSearch = () => {
        this.setState({supervisorsByCourseList: this.state.supervisorsByCourseList});
    }

    // ADD ADMIN

    RenderFormAddAdmin = () => {
        if (this.state.isActiveAddAdmin) {
            return (
                <div className={this.state.isActiveAddForm !== false ? s.form_add : s.dropdown_hidden_content}>
                    <form onSubmit={this.AddAdmin}>
                        <div>{this.selectDemoUser()}</div>
                        <div className={s.form_add + " " + s.left}>
                            <input type="text" placeholder="Имя" name="name" required
                                   value={this.state.name}
                                   onChange={this.handleInputChange}/>
                            <input type="email" placeholder="email" name="email" required value={this.state.email}
                                   onChange={this.handleInputChange}/>
                        </div>
                        <div className={s.form_add + " " + s.right}>
                            <input type="text" placeholder="phone number" name="phone1" required
                                   value={this.state.phone1}
                                   onChange={this.handleInputChange}/>
                            <input type="text" placeholder="second phone number" name="phone2" value={this.state.phone2}
                                   onChange={this.handleInputChange}/>
                        </div>
                        <div className={s.form_add_submit}>
                            <button className={s.form_add_submit} type="submit" value="Submit">Add Supervisor</button>
                        </div>
                    </form>
                </div>
            );
        }
    }

    AddAdmin = (event) => {
        event.preventDefault();
        const {selectedDemo, phone1, name} = this.state;
// debugger;
        callApi('dashboard/updateUsers', 'post', {
            id: selectedDemo.id,
            typeId: 3,
            name,
            phoneNumber: phone1
        }).then(res => {
            if (res && res.data.ok) {

                const supervisorInfo = {
                    name, mail: selectedDemo.email,
                    date: this.getDateFunc(),

                    phone1: selectedDemo.phoneNumber, phone2: selectedDemo.phoneNumber,
                    id: selectedDemo.id,
                    students: {active: [], inactive: []},
                    teachers: {active: [], inactive: []},
                };


                const {supervisorsByCourseList} = this.state;
                supervisorsByCourseList.all.unshift(supervisorInfo);

                this.setState({supervisorsByCourseList});
                this.ToggleAddAdmin();

                this.setState({successSupervAdd: true})
                setTimeout(() => this.setState({successSupervAdd: false}), 2000);
            }
        }).catch(err => {
        });
    };

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

// add teachers

    /* RenderFormAddTeacher = () => {
         return (
             <form onSubmit={this.AddTeacher}>
                 <div className={s.form_add + " " + s.left}>
                     <input type="text" placeholder="Имя" name="teacherName" required
                            value={this.state.teacherName}
                            onChange={this.handleInputChange}/>
                     <input type="email" placeholder="email" name="email"
                         // value={this.state.teacherEmail}
                            onChange={this.handleInputChange}/>
                 </div>
                 <div className={s.form_add + " " + s.right}>
                     <input type="text" placeholder="phone number" name="phone1"
                         // value={this.state.teacherPhone1}
                            onChange={this.handleInputChange}/>
                     <input type="text" placeholder="second phone number" name="phone2"
                         // value={this.state.teacherPhone2}
                            onChange={this.handleInputChange}/>
                 </div>
                 <div className={s.form_add_submit}>
                     <button className={s.form_add_submit} type="submit" value="Submit">Add Teacher</button>
                 </div>
             </form>
         );
     }*/


    /*  AddTeacher = (event) => {
          let adminsList = Object.values(this.state.adminsList);
          Object.keys(this.state.adminsList).map(i => {
                  if (this.state.adminIdForAddingTeacher === adminsList[i].adminId) {
                      event.preventDefault();
                      let dateNow = new Date();
                      let dd = dateNow.getDate();
                      if (dd < 10) dd = '0' + dd;
                      let mm = dateNow.getMonth() + 1;
                      if (mm < 10) mm = '0' + mm;
                      let date = dd + "." + mm + "." + dateNow.getFullYear();

                      adminsList[i].teachers.active.unshift(
                          this.state.teacherName + ' (' + date + ')'
                      );
                      this.setState({adminsList: adminsList});
                      this.setState({admins: adminsList});
                      this.ToggleAddTeacher();
                      this.ToggleWindow(this.state['isActiveToggleTeacher' + adminsList[i].adminId]);
                  }
              }
          );
      }*/


    // add students

    /* RenderFormAddStudent = () => {
         return (
             <form onSubmit={this.AddStudent}>
                 <div className={s.form_add + " " + s.left}>
                     <input type="text" placeholder="Имя" name="studentName" required
                            value={this.state.studentName}
                            onChange={this.handleInputChange}/>
                     <input type="email" placeholder="email" name="studentEmail"
                            value={this.state.studentEmail}
                            onChange={this.handleInputChange}/>
                 </div>
                 <div className={s.form_add + " " + s.right}>
                     <input type="text" placeholder="phone number" name="studentPhone1"
                            value={this.state.studentPhone1}
                            onChange={this.handleInputChange}/>
                     <input type="text" placeholder="second phone number" name="studentPhone2"
                            value={this.state.studentPhone2}
                            onChange={this.handleInputChange}/>
                 </div>
                 <div className={s.form_add_submit}>
                     <button className={s.form_add_submit} type="submit" value="Submit">Add Student</button>
                 </div>
             </form>
         );
     }

     AddStudent = (event) => {
         let adminsList = Object.values(this.state.adminsList);
         Object.keys(this.state.adminsList).map(i => {
                 if (this.state.adminIdForAddingStudent === adminsList[i].adminId) {
                     event.preventDefault();
                     let dateNow = new Date();
                     let dd = dateNow.getDate();
                     if (dd < 10) dd = '0' + dd;
                     let mm = dateNow.getMonth() + 1;
                     if (mm < 10) mm = '0' + mm;
                     let date = dd + "." + mm + "." + dateNow.getFullYear();

                     adminsList[i].students.active.unshift(
                         this.state.studentName + ' (' + date + ')'
                     );
                     this.setState({adminsList: adminsList});
                     this.setState({admins: adminsList});
                     this.ToggleAddStudent();
                     this.ToggleWindow(this.state['isActiveToggleStudent' + adminsList[i].adminId]);
                 }
             }
         );
     }*/


    // SORTING
    SortBy = (filter) => {
        let newAdmins = Object.values(this.state.admins);
        if (filter === "teachers") {
            newAdmins.sort((a, b) => (
                a.teachers.active.length < b.teachers.active.length
            ))
        } else if (filter === "teachersLow") {
            newAdmins.sort((a, b) => (
                a.teachers.active.length > b.teachers.active.length
            ))
        } else if (filter === "students") {
            newAdmins.sort((a, b) => (
                a.students.active.length < b.students.active.length
            ))
        } else if (filter === "studentsLow") {
            newAdmins.sort((a, b) => (
                a.students.active.length > b.students.active.length
            ))
        } else {
            newAdmins.sort((a, b) => (
                a[filter] > b[filter]
            ))
        }
        this.setState({admins: newAdmins});
        this.ToggleDropdown();
    }

    // TOGGLES
    ToggleAddAdmin = () => {
        this.setState({isActiveAddAdmin: !this.state.isActiveAddAdmin})
    }

    ToggleAddTeacher = () => {
        this.setState({isActiveAddTeacher: !this.state.isActiveAddTeacher})

    }


    ToggleAddStudent = () => {
        this.setState({isActiveAddStudent: !this.state.isActiveAddStudent})
    }


    ToggleDropdown = () => {
        this.setState({isActiveDropdown: !this.state.isActiveDropdown})
    }

    ToggleWindow = name => {
        this.setState({...this.dynamicInitObj, [name]: !this.state[name]})
    }


    RenderAdmins = () => {
        let admins = (this.state.supervisorsByCourseList.all) ? this.state.supervisorsByCourseList.all : [];


        return (

            <div className={s.admins_list}>
                {admins.map(adminKey => {


                    return (
                        <div key={adminKey}>
                            <div className={s.admins_list_item}>

                                <div className={s.name}>
                                    {`${adminKey.name} [${adminKey.mail}]`}

                                </div>

                                <div className={s.admins_item_right_block}>

                                    <div className={s.supervised_teachers}>
                                        <div className={s.supervised_teachers_title}
                                             onClick={() => {
                                                 this.ToggleWindow('isActiveToggleTeacher' + adminKey.id);
                                                 this.setState({adminIdForAddingTeacher: adminKey.id});
                                             }}>
                                            <div className={s.supervised_teachers_list}>
                                                Supervised teachers (
                                                {adminKey.teachers.active.length + "/" + adminKey.teachers.inactive.length}
                                                )
                                            </div>
                                            <div
                                                className={this.state['isActiveToggleTeacher' + adminKey.id] !== true
                                                    ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleTeacher' + adminKey.id] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_teacher : s.dropdown_teacher_hidden}>

                                            <div
                                                style={{
                                                    display: (this.state.activeToggleAddSupSupervisor)
                                                        ? 'none' : 'block'
                                                }}

                                                className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                                onClick={() => this.setState({
                                                    activeAddSupUser: adminKey.id,
                                                    activeToggleAddSupSupervisor: true
                                                })}
                                            >
                                                + Add the new teacher
                                            </div>

                                            <div style={{
                                                display: (this.state.activeAddSupUser !== adminKey.id
                                                    && !this.state.activeToggleAddSupSupervisor)
                                                    ? 'none' : 'block'
                                            }}>

                                                <div className={s.dropdown_add_sup}>
                                                    <div className={s.dropdown_select}>
                                                        <Select required
                                                                defaultValue={{
                                                                    // value: adminKey.selectListTeacher[0].value,
                                                                    // label: adminKey.selectListTeacher[0].label
                                                                }}
                                                                name="courseName"


                                                                options={adminKey.selectListTeacher}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={el => this.setState({activeAddSupSupervisor: el.value})}
                                                                placeholder="Select level"
                                                        />
                                                    </div>

                                                    <div className={s.dropdown_buttons}>
                                                        <Button variant="outlined" color="primary"
                                                            // onClick={
                                                            /* () => {
                                                             callApi('dashboard/addSupervisorsUsers', 'post', {
                                                                 supervisorId: this.state.activeAddSupSupervisor,
                                                                 userId: this.state.activeAddSupUser,
                                                             }).then(res => {
                                                                 if (res && res.data.ok) {

                                                                     this.setState({isActiveToggleSubmitCourse: true});

                                                                     let teachersByCourseListMod = this.state.teachersByCourseList;

                                                                     teachersByCourseListMod.all.map(el => {
                                                                             if (el.id === this.state.activeAddSupUser) {
                                                                                 let findone = supervisors.find(el => el.id === this.state.activeAddSupSupervisor);
                                                                                 let unshift = `${findone.name} [${findone.email}]`;
                                                                                 el.supervisors.active.unshift(unshift);
                                                                             }
                                                                         }
                                                                     )



                                                                     this.setState({teachersByCourseList: teachersByCourseListMod,
                                                                         successSupervAdd: true})
                                                                     setTimeout(() => this.setState({successSupervAdd: false}), 2000);

                                                                     this.setState({
                                                                         activeAddSupUser: false,
                                                                         activeToggleAddSupSupervisor: false,
                                                                     })

                                                                 }
                                                             }).catch(err => {
                                                             });
                                                         }*/
                                                            // }
                                                        >
                                                            Add
                                                        </Button>


                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        activeAddSupUser: false,
                                                                        activeToggleAddSupSupervisor: false,
                                                                    })
                                                                }}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>


                                            </div>


                                            {(adminKey.teachers.active).map(el => <div
                                                className={s.dropdown_teacher_link} key={el.id}>{el.name}</div>)}
                                            {(adminKey.teachers.inactive).map(el => <div
                                                className={s.dropdown_link_red + ' ' + s.dropdown_teacher_link}
                                                key={el.id}>
                                                {el.name}
                                            </div>)}

                                        </div>
                                    </div>


                                    <div className={s.supervised_students}>
                                        <div className={s.supervised_student_title}
                                             onClick={() => {
                                                 this.ToggleWindow('isActiveToggleStudent' + adminKey.id);
                                                 this.setState({idForAddingStudent: adminKey.id});
                                             }}>
                                            <div className={s.supervised_students_list}>
                                                Supervised students (
                                                {adminKey.students.active.length + "/" + adminKey.students.inactive.length}
                                                )
                                            </div>
                                            <div
                                                className={this.state['isActiveToggleStudent' + adminKey.id] !== true
                                                    ? s.icon_dropdown_toggle
                                                    : s.un_icon_dropdown_toggle}>
                                            </div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleStudent' + adminKey.id] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_student : s.dropdown_student_hidden}>
                                            <div
                                                style={{
                                                    display: (this.state.activeToggleAddSupSupervisor)
                                                        ? 'none' : 'block'
                                                }}

                                                className={s.dropdown_teacher_link + ' ' + s.dropdown_link_add_new}
                                                onClick={() => this.setState({
                                                    activeAddSupUser: adminKey.id,
                                                    activeToggleAddSupSupervisor: true
                                                })}
                                            >
                                                + Add the new student
                                            </div>

                                            <div style={{
                                                display: (this.state.activeAddSupUser !== adminKey.id
                                                    && !this.state.activeToggleAddSupSupervisor)
                                                    ? 'none' : 'block'
                                            }}>

                                                <div className={s.dropdown_add_sup}>
                                                    <div className={s.dropdown_select}>
                                                        <Select required
                                                                defaultValue={{
                                                                    // value: adminKey.selectListTeacher[0].value,
                                                                    // label: adminKey.selectListTeacher[0].label
                                                                }}
                                                                name="courseName"


                                                                options={adminKey.selectListStudent}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={el => this.setState({activeAddSupSupervisor: el.value})}
                                                                placeholder="Select level"
                                                        />
                                                    </div>

                                                    <div className={s.dropdown_buttons}>
                                                        <Button variant="outlined" color="primary"
                                                            // onClick={
                                                            /* () => {
                                                             callApi('dashboard/addSupervisorsUsers', 'post', {
                                                                 supervisorId: this.state.activeAddSupSupervisor,
                                                                 userId: this.state.activeAddSupUser,
                                                             }).then(res => {
                                                                 if (res && res.data.ok) {

                                                                     this.setState({isActiveToggleSubmitCourse: true});

                                                                     let teachersByCourseListMod = this.state.teachersByCourseList;

                                                                     teachersByCourseListMod.all.map(el => {
                                                                             if (el.id === this.state.activeAddSupUser) {
                                                                                 let findone = supervisors.find(el => el.id === this.state.activeAddSupSupervisor);
                                                                                 let unshift = `${findone.name} [${findone.email}]`;
                                                                                 el.supervisors.active.unshift(unshift);
                                                                             }
                                                                         }
                                                                     )



                                                                     this.setState({teachersByCourseList: teachersByCourseListMod,
                                                                         successSupervAdd: true})
                                                                     setTimeout(() => this.setState({successSupervAdd: false}), 2000);

                                                                     this.setState({
                                                                         activeAddSupUser: false,
                                                                         activeToggleAddSupSupervisor: false,
                                                                     })

                                                                 }
                                                             }).catch(err => {
                                                             });
                                                         }*/
                                                            // }
                                                        >
                                                            Add
                                                        </Button>


                                                        <Button variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        activeAddSupUser: false,
                                                                        activeToggleAddSupSupervisor: false,
                                                                    })
                                                                }}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>


                                            </div>


                                            {(adminKey.students.active).map(el => <div
                                                className={s.dropdown_teacher_link} key={el.id}>{el.name}</div>)}
                                            {(adminKey.students.inactive).map(el => <div
                                                className={s.dropdown_link_red + ' ' + s.dropdown_teacher_link}
                                                key={el.id}>
                                                {el.name}
                                            </div>)}

                                        </div>
                                    </div>

                                    <div className={s.date}>{adminKey.date}</div>

                                    <div className={s.contact}>
                                        <div className={s.supervised_student_title}
                                             onClick={() => this.ToggleWindow('isActiveToggleContact' + adminKey.id)}>
                                            <div className={s.supervised_students_list}>Contact</div>
                                            <div
                                                className={this.state['isActiveToggleContact' + adminKey.id] !== true
                                                    ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}></div>
                                        </div>
                                        <div
                                            className={this.state['isActiveToggleContact' + adminKey.id] === true
                                                ? s.dropdown_visible + ' ' + s.dropdown_contact : s.dropdown_student_hidden}>
                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571c32195480adaa0d37_chat-2.png"></img>
                                                <div>CHAT</div>
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09586a2ae5446fc1a024d9_envelope.png"></img>
                                                <div>{adminKey.mail}</div>
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                                <div>{adminKey.phone1}</div>
                                            </div>

                                            <div className={s.dropdown_teacher_link + ' ' + s.dropdown_link_contact}>
                                                <img className={s.contact_img}
                                                     src="https://uploads-ssl.webflow.com/5f08007d8d6a353b798ebafb/5f09571cfda67045bde4f065_phone-call.png"></img>
                                                <div>{adminKey.phone2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.devide_line}></div>
                        </div>
                    )
                })}
            </div>
        );
    }


    render() {

        return (
            <div className={s.content_admin}>

                <div className={s.instrument_bar}>
                    <div className={s.dropdown}

                         onClick={() => {
                             this.ToggleDropdown()
                         }}>

                        <div className={s.dropdown_toggle}>
                            SORT BY

                        </div>

                        <div
                            className={this.state.isActiveDropdown !== true ? s.icon_dropdown_toggle : s.un_icon_dropdown_toggle}>
                        </div>
                    </div>


                    <div
                        className={this.state.isActiveDropdown === true ? s.dropdown_visible_content : s.dropdown_hidden_content}>
                        <div className={s.dropdown_link}

                             onClick={() => {
                                 this.SortBy('name')
                             }}>Name
                        </div>
                        <div className={s.dropdown_link}
                             onClick={() => {
                                 this.SortBy('date')
                             }}>Date added
                        </div>

                        <div className={s.sort_item}>
                            <div>Number of supervised teachers</div>
                            <div className={s.sort_img_wrap}>
                                <div className={s.high + ' ' + s.dropdown_link}
                                     onClick={() => {
                                         this.SortBy('teachers')
                                     }}></div>
                                <div className={s.low + ' ' + s.dropdown_link}
                                     onClick={() => {
                                         this.SortBy('teachersLow')
                                     }}
                                ></div>
                            </div>
                        </div>
                        <div className={s.sort_item}>s.search_btn
                            <div>Number of supervised students</div>
                            <div className={s.sort_img_wrap}>
                                <div className={s.high + ' ' + s.dropdown_link}

                                     onClick={() => {
                                         this.SortBy('students')
                                     }}></div>
                                <div className={s.low + ' ' + s.dropdown_link}
                                     onClick={() => {
                                         this.SortBy('studentsLow')
                                     }}

                                ></div>
                            </div>
                        </div>

                    </div>

                    <div className={s.right_block_instrument_bar}>
                        <div className={s.invite_admin_btn} onClick={() => {
                            this.ToggleAddAdmin();
                        }}>
                            <div className={s.add_icon}></div>
                            <div className={s.invite_admin_btn_txt}>Invite new SUPERVISOR</div>
                        </div>
                        <div className={s.search_btn}>
                            <div className={s.search_icon}></div>
                            {/*<form>*/}
                            <div className={s.search_input}>
                                <input type="text" name="search" placeholder="Search"
                                       value={this.state.search}
                                       onChange={this.handleSearchSubmit}
                                />
                            </div>
                            {/*</form>*/}
                            <div onClick={() => {
                                this.setState({search: ''}, () => {
                                    this.ClearSearch()
                                })
                            }}
                                 className={s.search_btn_txt}>x
                            </div>
                        </div>
                    </div>
                </div>

                {this.RenderFormAddAdmin()}

                <div style={{
                    display: (this.state.successSupervAdd)
                        ? 'block' : 'none'
                }}>
                    <div className={s.success_add_sup}>
                        Supervisor успешно добавлен
                    </div>

                </div>

                {/*  <div className={this.state.isActiveAddTeacher !== false ? s.form_add : s.dropdown_hidden_content}>
                    {this.RenderFormAddTeacher()}
                </div>

                <div className={this.state.isActiveAddStudent !== false ? s.form_add : s.dropdown_hidden_content}>
                    {this.RenderFormAddStudent()}
                </div>
*/}
                {this.RenderAdmins()}
            </div>

        );
    }
}

export default Supervisors;
