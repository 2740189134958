import React, {useState }  from 'react';
import { useDrop, useDrag } from 'react-dnd';
import Chat from './chat/chat';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOM from "react-dom";

const coordinates = JSON.parse(localStorage.getItem('dndCoordinates') || null);

const DnDWrapperForChat = ({ socket, socketMessage, userInfo, lessonIdent, chatActive, innerWidth }) => {
    const [position, setPosition] = useState({
        left: coordinates && coordinates.chat && coordinates.chat.left || innerWidth / 2 - 300,
        top: coordinates && coordinates.chat && coordinates.chat.top || 20,
    });

    const [mouseMoveStatus, setMouseMoveStatus] = useState(false);

    const [, drop] = useDrop({
        accept: "chat",
        drop(item, monitor) {
            const delta = monitor.getDifferenceFromInitialOffset();
            const left = Math.round(item.left + delta.x);
            const top = Math.round(item.top + delta.y);

            setPosition({
                left: left,
                top: top
            });

            setMouseMoveStatus(false);
            // localStorage.setItem('dndCoordinates', JSON.stringify({chat: {left, top}}) || {});
            return undefined;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        item: { left: position.left, top: position.top, type: "chat" },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    const dragStyle = {
        position: "absolute",
        cursor: "move"
    };

    const renderModalContent = () => {
        return (
            <div>
                <div
                    ref={drag}
                    style={{
                        ...dragStyle,
                        left: position.left,
                        top: position.top
                    }}
                >
                    <Chat
                        mouseMoveStatusFunc={status => setMouseMoveStatus(status)}
                        active={chatActive}
                        socket={socket}
                        socketMessage={socketMessage}
                        userInfo={userInfo}
                        virtualClassId={(lessonIdent && lessonIdent.lessonId) ? lessonIdent.id : null}
                    />
                </div>
            </div>
        );
    };

    const renderModal = () => {
            return ReactDOM.createPortal(
                <div
                    ref={drop}
                    style={{
                        position: mouseMoveStatus ? "absolute" : "",
                        top: "0",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {renderModalContent()}
                </div>,
                document.getElementById("portal-dnd-chat")
            );
    };

    return (
        <div>
            {renderModal()}
        </div>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
    },
    dispatch
);

const mapStateToProps = ({ settings, dialog, auth }) => ({
    lessonIdent: settings.lessonIdent,
    userInfo: auth.userInfo,
    socket: settings.socket,
    socketMessage: dialog.socketMessage,
    innerWidth: settings.width,
});

export default connect(mapStateToProps, mapDispatchToProps)(DnDWrapperForChat);
