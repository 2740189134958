import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../homework/Homework.scss';
import './SkillsLevels.scss';
import ModalCloseButton from '../closeButton/ModalCloseButton';
import callApi from '../../../../utils/call-api';
import {Declination} from '../../../Declination';
import {LevelsData} from './LevelsData';
import Stars from '../../../../assets/images/levels/achevements stars.png';
import Certificate from '../../../../assets/images/levels/certificate.png';
import lang from '../../../../utils/lang';

const SKILLS_LEVELS_CLASS = 'skillsLevelsClass';
const SKILLS_LEVELS_LESSON = 'skillsLevelsLesson';





class SkillsLevels extends React.Component {
    constructor(props) {
        super(props);
        this.init();
    }

    init = () => {
        this.state = {
            homeworkStudentMark: -1,
            studentErrors: -1,
            isShowLessonEditor: false,
            vLInformation: null,
            lessonData: null,
            homeworkDataIncomplete: this.props.homeworkDataIncomplete,
            homeworkDataComplete: this.props.homeworkDataComplete,
            isFirstShow: 0, // не позволяет рендерить, пока не нажато первый раз на кнопку в меню для показа календаря
        }
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isShowSkillsLevels !== this.props.isShowSkillsLevels && !this.state.isFirstShow) {
            this.setState({ isFirstShow: true });
        }
    }

    levelDetection(number, txt) {

        return (
            <>
                {LevelsData.map(item =>{
                    const index = LevelsData.indexOf(item);
                    const nextIndex = index + 1 > 20 ? 20 : index + 1;
                    const lvl = item.level;
                    const nextLvl = LevelsData[nextIndex].level;
                    const nextLvlName = LevelsData[nextIndex].levelName;
                    if (number === 0 && lvl === 0) {
                        return txt === 'lvl' ? item.levelName : (txt === 'img' ?
                            (
                            <div className='lvlItemImg'>
                                <img src={item.img} alt=""/>
                            </div>
                            ) : nextLvlName)

                    } else if (number >= lvl && number < nextLvl) {
                        return txt === 'lvl' ? item.levelName : (txt === 'img' ?
                            (
                                <div className='lvlItemImg'>
                                    <img src={item.img} alt=""/>
                                </div>
                            ) : nextLvlName)
                    } else if (lvl === 200 && number >= 200) {
                        return txt === 'img' ?
                            (
                                <div className='lvlItemImg'>
                                    <img src={item.img} alt=""/>
                                </div>
                            ) : item.levelName
                    }
                })}
            </>
        )
    }

    progressView(){
        let diagramBox = document.querySelectorAll('.skillsProgress.resultSkill');
        diagramBox.forEach((box) => {

            let deg = (360 * box.dataset.percent / 100) + 180;
            if(box.dataset.percent >= 50){
                box.classList.add('over_50');
            }else{
                box.classList.remove('over_50');
            }
            box.querySelector('.piece.right').style.transform = 'rotate('+deg+'deg)';
        });
    }

    lessonsForNextLvl(number) {

        let leftLesson;

        LevelsData.map(item =>{
            const index = LevelsData.indexOf(item);
            const nextIndex = index + 1 > 20 ? 20 : index + 1;
            const lvl = item.level;
            const nextLvl = LevelsData[nextIndex].level;

            if (number === 0 && lvl === 0) {
                leftLesson = nextLvl;
            } else if (number >= lvl && number < nextLvl) {
                leftLesson = nextLvl - number;
            } else if (lvl === 200 && number >= 200) {
                leftLesson = 200;
            }
        })

        return leftLesson
    }

    leftPercent(number) {
        let leftPercent;

        LevelsData.map(item =>{
            const index = LevelsData.indexOf(item);
            const nextIndex = index + 1 > 20 ? 20 : index + 1;
            const lvl = item.level;
            const nextLvl = LevelsData[nextIndex].level;

            if (number === 0 && lvl === 0) {
                leftPercent = 0;
            } else if (number >= lvl && number < nextLvl) {
                leftPercent = 100 - (nextLvl - number) / (nextLvl - lvl) * 100;
            } else if (lvl === 200 && number >= 200) {
                leftPercent = 100;
            }
        })

        return leftPercent
    }

    grayContainer(lvl, count) {
        if (count <= lvl) {
            return 'customGrey'
        }
    }




    render() {
        const { isShowSkillsLevels } = this.props;
        const { isFirstShow } = this.state;
        const count = this.props.lessonsCount;
        const leftLessons = this.lessonsForNextLvl(count);

        this.progressView();
        return (
            <div className='CustomJumpModal CustomJumpModalHomework HomeworkContainer'>
                <div className={'CustomJumpModalBody HomeworkContainerBody SkillsWidth' +
                    (isShowSkillsLevels ? ' CustomJumpModalBodyShow' : '')}
                     style={{ display: (isFirstShow ? 'flex' : 'none') }}
                >
                    <ModalCloseButton
                        closeModal={this.props.closeModal}
                    />
                    <div className='SkillsLevelsContent'>

                        <div className='SkillsLevelsContainer'>
                            <div className='LevelsContentScroll'>
                                <div className='LevelsContent'>
                                    {LevelsData.map(item => {

                                        return (
                                            <>
                                                <div className={'lvlContainer ' + this.grayContainer(item.level, count)}>
                                                    <div className='lvlContainerImg'>
                                                        <img src={Stars} alt=""/>
                                                        <div className='lvlImg'>
                                                            <img className={item.level === 200 ? 'epicWidth' : null}
                                                                 src={item.img} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className='lvlContent'>
                                                        <div className={item.certificate ? 'lvlCertificate' : 'lvlCertificateHidden'}>
                                                            {item.certificate ?
                                                                <>
                                                                    <div className='lvlCertificateImg'>
                                                                        <img src={Certificate} alt=""/>
                                                                    </div>
                                                                    <div className='lvlCertificateText'>
                                                                        {item.certificateText}
                                                                    </div>
                                                                </>
                                                                : null}
                                                        </div>
                                                        <div className='lvlName'>{item.levelName}</div>
                                                    </div>
                                                    <div className='lvlExercise'>
                                                        <div className='lvlExerciseText'>
                                                            {item.level === 0 ?
                                                                <>
                                                                    <div>{lang('Регистрация')}</div>
                                                                    <div>{lang('на')}</div>
                                                                    <div>{lang('платформе')}</div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div>{lang('Закончить')}</div>
                                                                    <div className='stat-left-block-num'>{item.level}</div>
                                                                    <div>{Declination(item.level, SKILLS_LEVELS_LESSON)}</div>
                                                                </>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                        )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='SkillsLevelsHeader'>
                            <div className='LevelHeaderContainer'>
                                <div className='lvlItem'>
                                    <div>{lang('Ваш уровень:')}</div>
                                    <div>{this.levelDetection(count, 'lvl')}</div>
                                    {this.levelDetection(count, 'img')}
                                </div>
                                <div className='lvlRankingItem'>
                                    <div className='RankingItemText'>
                                        <div>{lang('До')}</div>
                                        <div>{lang('уровня')}</div>
                                        <div>{this.levelDetection(count, 'nextLvl')}</div>
                                    </div>
                                    <div className='RankingItemProgress'>
                                        <div className='skillsProgress resultSkill' data-percent={this.leftPercent(count)}>
                                            <div className='piece left'></div>
                                            <div className='piece right'></div>
                                            <div className='text'>
                                                <div>
                                                    {leftLessons}<br/>
                                                    {Declination(leftLessons, SKILLS_LEVELS_LESSON)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='lvlRanking'>
                                    <div className='lvlRankingText'>
                                        {lang('Всего завершено')}
                                    </div>
                                    <div className='lvlRankingNum'>
                                        {count}
                                    </div>
                                    <div className='lvlRankingFooter'>
                                        {Declination(count, SKILLS_LEVELS_CLASS)}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

const mapStateToProps = state => ({
    whiteboard: state.dialog.whiteboard,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SkillsLevels));
