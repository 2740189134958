import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ShowDialog, HideDialog} from '../actions';
import Dialog from '../components/Dialog';

const mapStateToProps = state => ({
    dialog: state.dialog.dialog,
    show: state.dialog.show,
    hide: state.dialog.hide
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ShowDialog,
        HideDialog
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
