import translates from '../lngProvider/locales/ru_RU.json';

const lang = (textId) => {

    if (translates[textId]) {
        return translates[textId];
    }

    return textId
}

export default lang;