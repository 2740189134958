import React from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    addDays,
    getDatesInWeeks,
    prepareTimeTable,
    monthsArrayRu,
    monthsArrayRuGenitive,
    weekDaysArrayEn,
    weekDaysArrayRu,
    weekDaysArrayEnDayIndex,
    getDaysBetweenTwoDates,
    formatCellTime,
    prepareTimeTableWorkHoursArr,
    LESSONS_HOUR_START,
    LESSONS_HOUR_END,
    TIME_PERIODS_IN_HOUR,
} from './TimeTableHelper';
import TimeTableDialog from './TimeTableDialog';
import TimeTableCreateLessonDialog from './TimeTableCreateLessonDialog';
import TimeTableCreateTeacherSchedule from './TimeTableCreateTeacherSchedule';
import './TimeTable.scss';
import callApi from '../../../../utils/call-api';
import ModalCloseButton from "../closeButton/ModalCloseButton";
import Select from 'react-select';
import IntlMessages from "../../../../utils/IntlMessages";
import lang from '../../../../utils/lang'

class TimeTable extends React.Component {
    constructor(props) {
        super(props);

        this.init();
    }

    init = () => {
        const currentDate = new Date();
        let TimeTableArray;

        const timeTableWorkHoursArr = prepareTimeTableWorkHoursArr();

        let lessonsHourStart = JSON.parse(localStorage.getItem('lessonsHourStart') || null);
        let lessonsHourEnd = JSON.parse(localStorage.getItem('lessonsHourEnd') || null);
        lessonsHourStart = lessonsHourStart ? lessonsHourStart : timeTableWorkHoursArr[0];
        lessonsHourEnd = lessonsHourEnd ? lessonsHourEnd : timeTableWorkHoursArr[timeTableWorkHoursArr.length - 1];

        if (this.props.TimeTableArray) {
            TimeTableArray = this.props.TimeTableArray;
        } else {
            TimeTableArray = prepareTimeTable(getDatesInWeeks(
                new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDay()),
                new Date(currentDate.getFullYear() + 2, currentDate.getMonth(), currentDate.getDay())
            ));
        }

        let todayWeekIndex = 0;

        for (let i = 0; i < TimeTableArray.length; ++i) {
            const begin = TimeTableArray[i].begin;
            const end = TimeTableArray[i].end;
            const cD = currentDate;

            if (begin.getTime() <= cD.getTime() &&
                end.getTime() >= cD.getTime()) {

                todayWeekIndex = i;
                break;
            }
        }
        const isFirstShow = !!this.props.calendarUserId;

        this.state = {
            timeTableWorkHoursArr, // массив выбора интервала времени, в котором будут отображаться строки в календаре
            lessonsHourStart, // время, с которого будут отображаться строки в календаре
            lessonsHourEnd,  // время, до которого будут отображаться строки в календаре
            TimeTableArray, // массив, содержащий календарь
            selectedWeekIndex: todayWeekIndex, // индекс строки массива той недели, которая выбрана
            teachersScheduleData: [], // массив с учителями, доступными данному ученику
            currentTeacherSchedule: [], // массив расписания текущего учителя
            currentDate, // текущая дата
            userGroupsCash: {}, // Объект с данными групп пользователей
            isFirstShow, // не позволяет рендерить, пока не нажато первый раз на кнопку в меню для показа календаря
            userCourseId: 0,
        }
    }

    prepareScheduleCell = (type, cellInfo, TimeTableArray, leftLessons = [], teacherInfo = {}) => {
        const timeTableArrayFirstWeekLastDay = TimeTableArray[0].end;
        const lessonStartDate = new Date(cellInfo.startDate * 1000);

        let isLongLesson = false;

        const daysBetweenTwoDates = getDaysBetweenTwoDates(
            timeTableArrayFirstWeekLastDay,
            lessonStartDate,
        );

        const lessonHoursStart = lessonStartDate.getHours();
        const lessonMinutesStart = lessonStartDate.getMinutes();

        if (cellInfo.endDate - cellInfo.startDate > 35 * 60) { // 35 min
            isLongLesson = true;
        }

        let indexInTimeTableTime = (lessonHoursStart - LESSONS_HOUR_START ) * TIME_PERIODS_IN_HOUR;
        if (lessonMinutesStart > 10) {
            ++indexInTimeTableTime;
        }

        if (indexInTimeTableTime <=  (LESSONS_HOUR_END - LESSONS_HOUR_START) * TIME_PERIODS_IN_HOUR) {
            let timeTableArrayIndex = 0;
            let timeTableDayIndex = lessonStartDate.getDay();
            if (daysBetweenTwoDates > 0 ) {
                timeTableArrayIndex = Math.floor(
                    (daysBetweenTwoDates + (lessonStartDate.getDay() === 0 ?
                        0 : weekDaysArrayEn.length - 1))
                    / weekDaysArrayEn.length);
            }

            if (TimeTableArray[timeTableArrayIndex] &&
                TimeTableArray[timeTableArrayIndex].timeTableTime &&
                TimeTableArray[timeTableArrayIndex].timeTableTime[indexInTimeTableTime]
            ) {
                const a = TimeTableArray[timeTableArrayIndex].timeTableTime[indexInTimeTableTime]
                    [weekDaysArrayEnDayIndex[timeTableDayIndex]];

                if (type === 'virtualLessonInfo') {
                    a.virtualLessonInfo = {...cellInfo, isLongLesson};
                    a.isVirtualLesson = true;
                    a.isLeftLesson = !!leftLessons.find(leftLesson => (leftLesson.id === cellInfo.id));
                } else if (type === 'schedule' && !a.isVirtualLesson) {
                    if (!a.virtualLessonInfo) {
                        a.virtualLessonInfo = [];
                    }

                    a.virtualLessonInfo.push({...teacherInfo, ...cellInfo});
                    delete a.virtualLessonInfo[a.virtualLessonInfo.length -1].schedule;
                    a.isSchedule = true;
                }
            }
        }
    }

    componentDidMount() {
        callApi('student/getUserCourses', 'get').then(res => {
            const result = res?.data;
            if (result?.ok) {
                const userCourseId = result?.userCourses[0]?.courseId; // TODO

                this.setState({ userCourseId });
            }
        }).catch(() => {});

        this.props.timeTableType === 'teacher' ?
            this.getTeacherSchedule() : this.getStudentSchedule();
    }

    getTeacherSchedule = () => {
        const { TimeTableArray } = this.state;
        const { calendarUserId = 0 } = this.props;

        callApi('dashboard/getTeacherSchedule', 'post', {
            teacherId: calendarUserId,
        }).then(res => {
            const result = res?.data;
            if (result?.ok) {

                let leftLessons = [];
                const activeLessons = result?.scheduleData?.virtualLessonsData || [];
                const teachersScheduleData = result?.scheduleData?.scheduleData || [];


                activeLessons.forEach(el => {
                    if ((el?.students?.length === 1 && el?.leftStudents?.length === 1) ||
                        el?.students?.length > 1 && el?.isCanceledByTeacher) {
                        leftLessons.push(el);
                    }
                });

                activeLessons.forEach(lesson => {
                    this.prepareScheduleCell('virtualLessonInfo', lesson, TimeTableArray, leftLessons);
                });

                teachersScheduleData.schedule.forEach(schedule => {
                    // TODO усложнить проверку для длинных уроков
                    if (!teachersScheduleData.reservation || !teachersScheduleData.reservation[schedule.startDate]) {
                        this.prepareScheduleCell('schedule', schedule, TimeTableArray, []);
                    }
                });

                this.setState({ TimeTableArray, currentTeacherSchedule: teachersScheduleData.schedule });
            }
        }).catch(() => {});
    }

    getStudentSchedule = () => {
        const { TimeTableArray } = this.state;
        const { calendarUserId = 0 } = this.props;

        callApi('dashboard/getStudentSchedule', 'post', {
            studentId: calendarUserId,
        }).then(res => {
            const result = res?.data;
            if (result?.ok) {
                const leftLessons = result?.scheduleData?.virtualLessonsData?.left || [];
                const activeLessons = result?.scheduleData?.virtualLessonsData?.active || [];
                const teachersScheduleData = result?.scheduleData?.teachersScheduleData || [];

                activeLessons.forEach(lesson => {
                    this.prepareScheduleCell('virtualLessonInfo', lesson, TimeTableArray, leftLessons);
                });

                teachersScheduleData.forEach(teacher => {
                    let teacherSchedule;
                    let teacherReservation;

                    try {
                        teacherSchedule = JSON.parse(teacher.schedule) || {};
                        teacherReservation = JSON.parse(teacher.reservation) || {};
                    } catch (e) {}

                    if (Array.isArray(teacherSchedule)) {
                        teacherSchedule.forEach( schedule => {
                            // TODO усложнить проверку для длинных уроков
                            if (!teacherReservation[schedule.startDate]) {
                                this.prepareScheduleCell('schedule', schedule, TimeTableArray, [], teacher);
                            }
                        });
                    }
                });

                this.setState({ TimeTableArray, teachersScheduleData });
            }
        }).catch(() => {});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isShowTimeTable !== this.props.isShowTimeTable && !this.state.isFirstShow) {
            this.setState({ isFirstShow: true });
        }
    }

    render() {
        const { isShowTimeTable, subscriptionsData, timeTableType, allStudentsData } = this.props;
        const { isFirstShow, selectedWeekIndex, TimeTableArray, currentDate, teachersScheduleData, userGroupsCash,
            userCourseId, currentTeacherSchedule, timeTableWorkHoursArr, lessonsHourStart, lessonsHourEnd } = this.state;
        const weekBeginDate = TimeTableArray[selectedWeekIndex]?.begin;
        const weekEndDate = TimeTableArray[selectedWeekIndex].end;
        const timeTableRows = TimeTableArray[selectedWeekIndex].timeTableTime;
        const isTeacherSchedule = (timeTableType === 'teacher');

        const myGetDate = day => (day < 10 ? '0' + day : day);
        const myGetMont = month => monthsArrayRu[month].substr(0, 3);

        return (
        <div className="CustomJumpModal TimeTableContainer">
            <div className={'CustomJumpModalBody TimeTableBody' +
                (isShowTimeTable ? ' CustomJumpModalBodyShow' : '')}
                style={{ display: (isFirstShow ? 'flex' : 'none') }}
            >
                <ModalCloseButton
                    closeModal={this.props.closeModal}
                />
                <div className="TimeTableWeekSelectorYear">
                    {weekBeginDate.getFullYear()}
                </div>
                <div className="TimeTableWorkHours">
                    <div className="TimeTableWorkHoursHeader">
                        <IntlMessages id="Рабочие часы" />
                    </div>
                    <div className="TimeTableWorkHoursSelects">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div className="TimeTableWorkHoursText">{lang("с")}</div>
                            <Select
                                defaultValue={lessonsHourStart}
                                options={timeTableWorkHoursArr}
                                className="TimeTableWorkHoursSelect"
                                classNamePrefix="select"
                                onChange={lessonsHourStart => {
                                    localStorage.setItem('lessonsHourStart', JSON.stringify(lessonsHourStart));
                                    this.setState({ lessonsHourStart });
                                }}
                                // defaultMenuIsOpen // menuIsOpen
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div className="TimeTableWorkHoursText">{lang("до")}</div>
                            <Select
                                defaultValue={lessonsHourEnd}
                                options={timeTableWorkHoursArr}
                                className="TimeTableWorkHoursSelect"
                                classNamePrefix="select"
                                onChange={lessonsHourEnd => {
                                    localStorage.setItem('lessonsHourEnd', JSON.stringify(lessonsHourEnd));
                                    this.setState({ lessonsHourEnd });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="TimeTableWeekSelector">
                    <div className="TimeTableWeekSelectorLeft"
                        onClick={() => {
                            if (selectedWeekIndex > 0) {
                                this.setState({ selectedWeekIndex: selectedWeekIndex - 1 });
                            }
                        }}
                    />
                    <div className="TimeTableWeekInfo">
                        {myGetMont(weekBeginDate.getMonth())}, {myGetDate(weekBeginDate.getDate())}&ensp;-&ensp;
                        {myGetMont(weekEndDate.getMonth())}, {myGetDate(weekEndDate.getDate())}
                    </div>
                    <div className="TimeTableWeekSelectorRight"
                         onClick={() => {
                             if (selectedWeekIndex < (TimeTableArray.length - 1)) {
                                 this.setState({ selectedWeekIndex: selectedWeekIndex + 1 });
                             }
                         }}
                    />
                </div>

                <div className="TimeTableGrid">
                    <div style={{display: 'flex'}}>
                        <div style={{visibility: 'hidden'}} className="TimeTableGridRowCellDays"/>
                        {weekDaysArrayRu.map((day, dayNumber) => {
                            const currDay = addDays((new Date(weekBeginDate)).getTime(), dayNumber);
                            return (
                                <div key={day} className="TimeTableGridRowCellDays">
                                    <div>{day},</div>
                                    <div>{currDay.getDate()}&ensp;{monthsArrayRuGenitive[currDay.getMonth()]}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {timeTableRows.map((timeTableRow, rowNum) => {
                        if (lessonsHourStart.value > Math.trunc(timeTableRow.timeStart)) return (<></>);
                        if (lessonsHourEnd.value < Math.trunc(timeTableRow.timeEnd)) return (<></>);

                        return (
                        <div className="TimeTableGridRow" key={timeTableRow.timeStart}>
                            <div className="TimeTableGridRowCellTime">
                                {formatCellTime(timeTableRow.timeStart)}&ensp;-&ensp;
                                {formatCellTime(timeTableRow.timeEnd)}
                            </div>
                            {weekDaysArrayEn.map((timeTableDay, dayNumber) => {
                                const isLeftLesson = timeTableRow[timeTableDay].isLeftLesson;
                                const virtualLessonInfo = timeTableRow[timeTableDay].virtualLessonInfo;
                                const isVirtualLesson = timeTableRow[timeTableDay].isVirtualLesson;
                                const isSchedule = timeTableRow[timeTableDay].isSchedule;

                                let nextVirtualLessonInfo = [];
                                if ((timeTableRows.length - 1) > rowNum) {
                                    if (!isLeftLesson && !isVirtualLesson &&
                                        timeTableRows[rowNum + 1][timeTableDay].isSchedule) {
                                        nextVirtualLessonInfo = timeTableRows[rowNum + 1][timeTableDay].virtualLessonInfo;
                                    }
                                }

                                let lessonPreview = virtualLessonInfo ? virtualLessonInfo?.lessonName : '';
                                let studentInfo = null;

                                if (virtualLessonInfo) {
                                    let flag = false;

                                    if (!isTeacherSchedule) {
                                        const teacherInfo = (this.props.allTeacherInfo || []).find(el =>
                                            (el && el.id === virtualLessonInfo?.teacherId));

                                        if (teacherInfo?.name) {

                                            lessonPreview = teacherInfo.name +
                                                (typeof teacherInfo.surname === 'string'?
                                                    ` ${teacherInfo.surname.substring(0, 1)}` : '');
                                            console.log('virtualLessonInfo', lessonPreview)
                                            flag = true;
                                        }
                                    }

                                    if (!flag) {
                                        const isIndividualLesson = (virtualLessonInfo?.students?.length === 1);

                                        if (!isIndividualLesson && userGroupsCash[virtualLessonInfo?.id]) {
                                            lessonPreview = userGroupsCash[virtualLessonInfo?.id].name;
                                        } else if (isIndividualLesson || !userGroupsCash[virtualLessonInfo?.id]) {
                                            const teacherInfo = (this.props.allTeacherInfo || []).find(el =>
                                                (el && el.id === virtualLessonInfo?.teacherId));
                                            if (teacherInfo?.name) {
                                                lessonPreview = teacherInfo.name +
                                                    (typeof teacherInfo.surname === 'string'?
                                                        ` ${teacherInfo.surname.substring(0, 1)}` : '');
                                            }
                                        }

                                        if (isIndividualLesson && isTeacherSchedule) {
                                            if (Array.isArray(allStudentsData)) {
                                                studentInfo = allStudentsData.find(el => (el.id === virtualLessonInfo?.students[0]));
                                                lessonPreview = studentInfo?.name ?
                                                    (studentInfo?.name + (typeof studentInfo.surname === 'string'?
                                                        ` ${studentInfo.surname.substring(0, 1)}` : '')) :
                                                    lessonPreview;
                                            }
                                        }
                                    }
                                }

                                let currentDay = currentDate.getDay();
                                currentDay = currentDay === 0 ? 6 : currentDay - 1;

                                let isCurrentWeek = false;
                                let isFormedWeek = false;
                                let isAfterCurrentTime = false;

                                if (currentDate.getTime() >= weekBeginDate.getTime() &&
                                    currentDate.getTime() <= weekEndDate.getTime()
                                ) {
                                    isCurrentWeek = true;
                                }

                                if (currentDate.getTime() >= weekEndDate.getTime()) {
                                    isFormedWeek = true;
                                }

                                if (currentDate.getHours() <= timeTableRow.timeStartObj.hour ||
                                    (
                                        currentDate.getHours() === timeTableRow.timeStartObj.hour &&
                                        currentDate.getMinutes()  < timeTableRow.timeStartObj.minute
                                    )
                                ) {
                                    isAfterCurrentTime = true;
                                }

                            return (
                                <div key={timeTableDay}
                                     className={"TimeTableGridRowCellInfo" +
                                     ((currentDay === dayNumber && isCurrentWeek) ?
                                     " TimeTableGridRowCellInfoCurrent" : "")
                                }
                                     style={{zIndex: 11111 - rowNum}}
                                >
                                    {isTeacherSchedule &&
                                        <TimeTableCreateTeacherSchedule
                                            dayNumber={dayNumber}
                                            hourNumber={rowNum}
                                            weekBeginDate={weekBeginDate}
                                            isSchedule={isSchedule}
                                            virtualLessonInfo={virtualLessonInfo}
                                            currentTeacherSchedule={currentTeacherSchedule}
                                            updateSchedule={() => {
                                                this.init();
                                                this.getTeacherSchedule();
                                            }}
                                            setGroupInfo={(lessonId, userGroupInfo) => this.setState({
                                                userGroupsCash: {...userGroupsCash, [lessonId]: userGroupInfo}
                                            })}
                                        />
                                    }

                                    {virtualLessonInfo && isVirtualLesson ?
                                        (<TimeTableDialog
                                            isTeacherSchedule={isTeacherSchedule}
                                            allStudentsData={allStudentsData}
                                            studentInfo={studentInfo}
                                            canUpdate={
                                                !isFormedWeek &&
                                                (currentDay < dayNumber || (currentDay === dayNumber && isAfterCurrentTime))
                                            }
                                            updateSchedule={() => {
                                                this.init();
                                                isTeacherSchedule ?
                                                    this.getTeacherSchedule() : this.getStudentSchedule();
                                            }}
                                            lessonPreview={lessonPreview}
                                            isLeftLesson={isLeftLesson}
                                            virtualLessonInfo={virtualLessonInfo}
                                            teachersScheduleData={this.props.allTeacherInfo}
                                            setGroupInfo={(lessonId, userGroupInfo) => this.setState({
                                                userGroupsCash: {...userGroupsCash, [lessonId]: userGroupInfo},
                                            })}
                                        />)
                                        : ''}

                                    {timeTableType !== 'teacher' && virtualLessonInfo && isSchedule && !isFormedWeek &&
                                        (currentDay < dayNumber || (currentDay === dayNumber && isAfterCurrentTime)) ?
                                        (<TimeTableCreateLessonDialog
                                            subscriptionsData={subscriptionsData}
                                            updateSchedule={() => {
                                                this.init();
                                                isTeacherSchedule ?
                                                    this.getTeacherSchedule() : this.getStudentSchedule();
                                            }}
                                            userCourseId={userCourseId}
                                            virtualLessonInfo={virtualLessonInfo}
                                            nextVirtualLessonInfo={nextVirtualLessonInfo}
                                            teachersScheduleData={teachersScheduleData}
                                            setGroupInfo={(lessonId, userGroupInfo) => this.setState({
                                                userGroupsCash: {...userGroupsCash, [lessonId]: userGroupInfo},
                                            })}
                                        />)
                                        : ''}
                                </div>
                            )})}
                        </div>
                    );
                    })}
                </div>
            </div>
        </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { },
    dispatch
);

const mapStateToProps = () => (
    {}
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeTable));
