import React from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './EditProfile.scss';
import callApi from '../../../../utils/call-api';
import Button from "@material-ui/core/Button/Button";
import config from "../../../../config";
import AvatarImageCropper from './AvatarImageCropper';
import history from "../../../../utils/history";
import { updateUserInfo } from '../../../../actions';
import ModalCloseButton from "../closeButton/ModalCloseButton";
import InfoDialog from "../InfoDialog";
import lang from '../../../../utils/lang';

class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFirstShow: false,
            selectedFile: null,
            userInfo: this.props.userInfo.userInfo,
            isAvatarUploadingMode: false,
            isPreviewLoad: false,
            isShowOkModalDialog: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isShowEditProfile !== this.props.isShowEditProfile && !this.state.isFirstShow) {
            this.setState({ isFirstShow: true });
        } else if (prevProps.isShowEditProfile && !this.props.isShowEditProfile) {
            const WINDOW_ANIMATION_PLAY_TIME = 600; // ms
            setTimeout(() => history.push('/'), WINDOW_ANIMATION_PLAY_TIME);
        }
    }

    renderInput = (value, name, label, comment = '', type= 'text') => {
        return (
            <div>
                <div><b>{label}:</b></div>
                {comment ?
                    <div><small>({comment})</small></div> :
                    <></>
                }

                <input
                    type={type}
                    className="modal_input"
                    name={name}
                    value={value}
                    onChange={this.handleInputChange}
                    placeholder={label}
                />
            </div>
        );
    }

    handleInputChange = event => {
        event.persist();
        const { userInfo } = this.state;
        const { name, value } = event.target;

        this.setState({ userInfo : {
            ...userInfo,
            [name]: value,
        }});
    };

    updateUserProfile = () => {
        const { userInfo } = this.state;
        const { avatar, name, surname, phoneNumber, address, age, gender } = userInfo;
        callApi('dashboard/updateUserProfile', 'post', {
            avatar,
            name,
            surname,
            phoneNumber,
            address,
            age,
            gender,
        }).then(res => {
            if (res && res.data.ok) {
                this.setState({
                    isPreviewLoad: false,
                    isAvatarUploadingMode: false,
                });

                const userInfo = JSON.parse(localStorage.getItem('user') || null);

                localStorage.setItem('user', JSON.stringify({
                    ...userInfo,
                    userInfo: this.state.userInfo,
                }));

                this.props.updateUserInfo({
                    ...userInfo,
                    userInfo: this.state.userInfo,
                });

            }
        }).catch(() => {
            this.setState({ isShowOkModalDialog: true });
        });
    }

     applyAvatar = file => {
        const formData = new FormData();
        formData.append('type', file.type);
        formData.append('name', file.name);
        formData.append('file', file);

        callApi('dashboard/uploadFile',
            'post',
            formData,
            'multipart/form-data'
        ).then(res => {
            if (res && res.data && res.data.length && res.data.fileName) {
                const { userInfo } = this.state;

                this.setState({
                    userInfo: {
                        ...userInfo,
                        avatar: res.data.fileName.substr(config.filePathSubstLength)
                    }
                }, () => {
                    this.updateUserProfile();
                });
            }
        }).catch(err => console.log(err));
    }

    render() {
        const { isShowEditProfile } = this.props;
        const { isFirstShow, userInfo, isAvatarUploadingMode, isPreviewLoad, isShowOkModalDialog } = this.state;

        const { avatar, name, surname, phoneNumber, address, age, /*gender */ } = userInfo;

        return (
            <>{isFirstShow &&
                <div className="CustomJumpModal EditProfileContainer">
                    <div className={'CustomJumpModalBody EditProfileBody' +
                        (isShowEditProfile ? ' CustomJumpModalBodyShow' : '')}
                         style={{ display: (isFirstShow ? 'flex' : 'none') }}
                    >
                        {isShowOkModalDialog && <InfoDialog
                            HideDialog={() => {
                                this.setState({ isShowOkModalDialog: false });
                            }}
                            title={lang("Редактирование профиля")}
                            text={lang("Возникла ошибка")}
                        />}
                        <ModalCloseButton
                            closeModal={this.props.closeModal}
                        />
                        <div className="EditProfileAvatar">
                            {avatar && !isAvatarUploadingMode &&
                                <img src={avatar} alt="Avatar"/>
                            }
                            {isAvatarUploadingMode &&
                            <AvatarImageCropper
                                className="AvatarImageCropper"
                                textClass="AvatarImageCropperText"
                                text={lang("Выберите фото")}
                                maxsize={5024 * 5024 * 5}
                                apply={this.applyAvatar}
                                cancel={() => this.setState({ isPreviewLoad: false })}
                                onPreviewLoad={() => this.setState({ isPreviewLoad: true })}
                                sliderChildrenDiv={{className: 'dno'}}
                            />}
                            <label htmlFor="uploadAvatar">
                                {!isPreviewLoad &&
                                <Button
                                    onClick={() => this.setState({
                                        isAvatarUploadingMode: !isAvatarUploadingMode,
                                    })}
                                >
                                    {!isAvatarUploadingMode ?
                                        (avatar ? lang("Сменить аватар") : lang("Загрузить аватар")) :
                                        lang("Отмена")
                                    }
                                </Button>}
                            </label>
                            {/*{!isPreviewLoad && !!(age && age >= 4) &&*/}
                            {/*    <div className="EditProfileAvatarAge">*/}
                            {/*        <div className={"EditProfileAvatarAgeImg "+*/}
                            {/*                ((age >= 4 && age <= 6) ? "epic_logo_baby" : "") +*/}
                            {/*                ((age >= 7 && age <= 10) ? "epic_logo_kids" : "") +*/}
                            {/*                ((age >= 11 && age <= 17) ? "epic_logo_junior" : "") +*/}
                            {/*                ((age >= 18) ? "epic_logo_pro" : "")*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*        <div className="EditProfileAvatarAgeText">*/}
                            {/*            {!!(age >= 4 && age <= 6) && "4-6 лет"}*/}
                            {/*            {!!(age >= 7 && age <= 10) && "7-10 лет"}*/}
                            {/*            {!!(age >= 11 && age <= 17) && "11-17 лет"}*/}
                            {/*            {(age >= 18) && "18+ лет"}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>

                        <div className="EditProfileInfo">
                            {this.renderInput(name, 'name', lang("Имя"), lang("Полные имя и фамилия будут доступны только администрации школы. Остальным будут видны только имя и первая буква фамилии") )}
                            {this.renderInput(surname, 'surname', lang("Фамилия"))}
                            {this.renderInput(phoneNumber, 'phoneNumber', lang("Номер телефона"))}
                            {this.renderInput(address, 'address', lang("Адрес"))}
                            {this.renderInput(age, 'age', lang("Возраст"), "", "number")}
                            {/*{this.renderInput(gender, 'gender', "Пол")}*/}
                            <Button
                                style={{
                                    marginLeft: '5px',
                                    marginTop: '5px',
                                    backgroundColor: '#00b3ff',
                                    borderRadius: '10px'
                                }}
                                variant='contained'
                                color='primary'
                                disabled={this.state.joinRoomDisabled}
                                onClick={() => this.updateUserProfile()}
                            >
                                СОХРАНИТЬ
                            </Button>
                        </div>
                    </div>
                </div>
            }</>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        updateUserInfo,
    },
    dispatch
);

const mapStateToProps = state => (
    {
        userInfo: state.auth.userInfo,
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfile));
