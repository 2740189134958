// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.domain {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 100vh;
}

.frame {
  width: fit-content;
  height: fit-content;
  margin: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px 30px 15px 30px;
}

.block {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.spellig {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 10px 0;
}

.symbol {
  font-size: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/lessonEditor/lessonComponent/LessonComponent.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AACA;EACI,SAAA;AAEJ;;AACA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;AAEJ;;AACA;EACI,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,4BAAA;AAEJ;;AACA;EACI,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;;AACA;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AAEJ;;AACA;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ","sourcesContent":["* {\n    box-sizing: border-box;\n  }\nbody {\n    margin: 0;\n}\n\n.domain {\n    display: flex;\n    justify-content: start;\n    align-items: start;\n    width: 100%;\n    height: 100vh;\n}\n\n.frame {\n    width: fit-content;\n    height: fit-content;\n    margin: 30px;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    padding: 30px 30px 15px 30px;\n}\n\n.block {\n    margin-bottom: 15px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.spellig {\n    font-size: 18px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin: 0 0 10px 0;\n}\n\n.symbol {\n    font-size: 50px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
