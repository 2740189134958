import appLocaleData from 'react-intl/locale-data/es';
import saMessages from '../locales/uk_UA.json';

const saLang = {
    messages: {
        ...saMessages
    },
    locale: 'es',
    data: appLocaleData
};

export default saLang;
