import IntlMessages from "../../utils/IntlMessages";
import AuthLogo from "./imgs/auth_logo.png";
import React, {useEffect, useState} from "react";
import lang from "../../utils/lang";
import TextField from "./TextField";
import {NavLink} from "react-router-dom";
import Config from "../../config";
import callApi from '../../utils/call-api';

const PassChange = () => {

    const [state, setState] = useState({
        isSending: false,
        validPass: false,
        enterPass: '',
        repeatPass: '',
        sendPass: false,
        isError: false,
    })

    useEffect(()=> {
        isValidPass();

    }, [state.repeatPass])

    const isValidPass = () => {

        if (state.enterPass === state.repeatPass && state.enterPass.length > 0) {
            setState({...state, validPass: true});
        } else {
            setState({...state, validPass: false});
        }
    }

    const sendPass = () => {
        if (state.isSending) return;
        setState({ ...state, isSending: true });

        const recoveryCode =  (new URL(window.location.href)).searchParams.get('id');

        callApi('auth/passRecoveryChange', 'post', {
            password: state.enterPass,
            recoveryCode,
        })
            .then(res => {
                if (res && res.data && res.data.ok) {
                    setState({ ...state, sendPass: true,  isError: false, isSending: false });
                } else {
                    setState({ ...state, sendPass: true,  isError: true, isSending: false });
                }
            })
            .catch(() => {
                setState({ ...state, sendPass: true,  isError: true, isSending: false });
            });
    }

    const afterSendPassMessage = state.isError ?
        'Не удалось изменить пароль' :
        'Пароль успешно изменен'

    return (
        <div className="AuthComponentContainer">
            <div className="AuthComponent">
                <div className="AuthComponentHeader"
                >
                    <div style={{position: 'absolute'}}><IntlMessages id="Смена пароля" /></div>
                </div>
                <div className="AuthComponentLogo">
                    <img alt="LOGO" src={AuthLogo}/>
                </div>

                <div className="AuthComponentPath">
                    <div className={"AuthComponentPathSignin"}>

                        <div className="AuthComponentPathForm AuthComponentPathFormSignin">
                            {
                                state.sendPass ? null :
                                    <>
                                        <TextField
                                            type="password"
                                            label={lang("Введите пароль")}
                                            value={state.enterPass}
                                            handleInputChange={val => setState({...state, enterPass: val})}
                                        />

                                        <TextField
                                            type="password"
                                            label={lang("Повторите пароль")}
                                            value={state.repeatPass}
                                            handleInputChange={val => setState({...state, repeatPass: val})}
                                        />
                                    </>
                            }

                            <div className="AuthComponentPathSigninLinks">
                                {state.sendPass ?
                                    <div>
                                        <div className="afterSendPassMessage">{lang(afterSendPassMessage)}</div>
                                        <div
                                            className="AuthComponentPathSigninLinksButton"
                                        >
                                            <NavLink style={{textDecoration: 'none', color: 'white'}}
                                                     to={Config.path + '/enter'}>
                                                <IntlMessages id="Вернуться назад"/>
                                            </NavLink>
                                        </div>
                                    </div>
                                    :
                                    <div
                                        className="AuthComponentPathSigninLinksButton"
                                        style={state.validPass ? {} : { pointerEvents: 'none', filter: 'grayscale(90%)' }}
                                        onClick={()=> sendPass()}
                                    >
                                        <IntlMessages id="Сменить пароль"/>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="AuthComponentPathSigninRocket"/>
                    </div>

                </div>
                <div className="AuthComponentSiteName"
                     onClick={() => {
                         window.open('https://www.epiclang.com');
                     }}
                >
                    www.epiclang.com
                </div>
            </div>
        </div>
    )
}

export default PassChange;