
import React, { useState, useEffect } from 'react';
import s from "../SuperAdmin.module.css";

const CoursItem = ({item,selected, ToggleCourses}) => {
    const [value, setValue] = useState(item);
    const [isSelectd, setIsSelectd] = useState(selected);

    useEffect(() => setIsSelectd(selected), [selected]);
    useEffect(() => setValue(item), [item]);
    
    const onClick = () => {
        ToggleCourses(value)
    };

    return (
        <div
            key={value.courseKey}
            className={isSelectd ? s.course_item_blur + ' ' + s.course_item : s.course_item}
            onClick={onClick}
        >
            <div className={s.course_title}>
                {value.name}
            </div>
            <div onClick={() => ToggleCourses(value)}>
                <dive className=""> {`levels: ${value.levels.length}`}</dive>
                <dive className=""> {`lessons: ${value.levels.reduce((res, item)=>{
                    return res+item.lessonCount;
                    },0)}`}</dive>
            </div>
            <div className={s.course_control_button}>
                Manage Course
            </div>
        </div>
    );        
};  

export default CoursItem;