import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '../containers/Dialog';
import LogoBrand from '../components/LogoBrand';
import { Alert } from 'reactstrap';

import IntlMessages from "../utils/IntlMessages";

class ResetStep2 extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);   
    }

    static propTypes = {
        resetStep2: PropTypes.func.isRequired
    }

    state = {
        password: {
            value: '',
            isValid: true
        },
        token: {
            value: ''
        }
    }

    handleInputChange(event) {
        event.persist();

        const { name, value } = event.target;

        this.setState(prevState => ({
            [name]: {
                ...prevState[name],
                value
            }
        }));
    }

    handleSubmit(event) {
        event.preventDefault();

        const { password } = event.target;
        const token = this.state.token;

        this.props.showAuthLoader();
        this.props.resetStep2(
            token,
            password.value
        );
    }

     componentDidMount() {
         let url = new URL(window.location.href);
         let token = url.searchParams.get("token");
         this.setState({
            token: token
        });
    }

    render() {
        const {
            loader,
            showMessage, 
            alertTitle,
            dialog,
            alertMessage
        } = this.props;
        const { password } = this.state;

        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                       <LogoBrand alt="FraudZeroider" />
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1><IntlMessages id="appModule.reset" /><span className="app-module-step text-muted"><IntlMessages id="appModule.step2" /></span></h1>
                        </div>

                        {showMessage && (
                            <Alert className="shadow-lg bg-danger text-white">
                                <p style={{ marginBottom: 0 }}>
                                    {alertTitle}
                                </p>
                                { alertMessage ?
                                    <ul className="auth-errors">
                                        <li>{alertMessage}</li>
                                    </ul> : ''}
                            </Alert>
                        )}

                        <div className="app-login-form">
                            <form onSubmit={this.handleSubmit}>
                                <fieldset>
                                    <TextField
                                        required
                                        type="password"
                                        name="password"
                                        label={<IntlMessages id="appModule.password" />}
                                        value={password.value}
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                        error={!password.isValid}
                                    />

                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button type="submit"
                                                variant="contained"
                                                color="primary">
                                            <IntlMessages id="button.save" />
                                        </Button>

                                        <Link to="/signup">
                                            <IntlMessages id="signIn.signUp" />
                                        </Link>
                                    </div>       

                                </fieldset>
                            </form>
                        </div>
                    </div>

                </div>

                {loader && (
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                )}

                {dialog && <Dialog title={<IntlMessages id="appModule.changePassword" />} text={<IntlMessages id="appModule.yourPasswordHasBeenChanged" />} />}

            </div>
        );
    }
    
}

export default ResetStep2;