import * as types from '../constants/timerLesson';

const initialState = {
    timerTime: 0,
}

export default function timerLesson (state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_TIMER_TIME:
            return {
                ...state,
                timerTime: action.payload,
            };
        default:
            return state;
    }
};