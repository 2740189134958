import React,{useEffect, useState} from "react";
import Button from '@material-ui/core/Button/Button';
import { Preview } from 'react-dnd-preview';
import TextField from '@material-ui/core/TextField';

import TeacherHelpInfo from "./common/TeacherHelpInfo";
import { switchArrayElements } from '../helpers/arrayForRandomSimulation';
import { Container2 } from '../n2/Container';
import l from '../main/LessonEditor.module.scss';

const Dad2 = ({el, i, activeTabIndex, options, socketMessage}) => {

    const [text, setText] = useState(el.text||''); //text: '',
    const [resDustbin, setResDustbin] = useState(el.resDustbin||[]); //resDustbin: [],
    const [resBox, setResBox] = useState(el.resBox||[]); //resBox: [],
    const [isText, setIsText] =useState(el.istext||false);
    const [uniqId, setUniqId] =useState(el.uniqId);
    const [customTitle, setCustomTitle] = useState(el.customTitle);
    const [type, setType] =useState(el.type);
    const [clue, setClue] = useState("");

    const [isPresentation, setIsPresentation] = useState(options.isPresentation);

    useEffect(()=>{
        options.changeElement({text, resDustbin, resBox, isText,  customTitle, clue}, i, activeTabIndex)
    }, [text, resDustbin, resBox, isText,  customTitle, clue]);

    const getPlaceholder = ()=>{
        return type==='special1' ?  'I|live|in|London' : '';
    };

    const okHandlerDAD2 = () => {
        

        const clue = (type === 'special1') ? text.split('|').join(' ') : text;
        const textArray = (type === 'special1') ? text.split('|') : text.split('');
        let resDustbin = [];
        let resBox = [];

        textArray.forEach((el, i) => {
            resDustbin.push({ accepts: ['2'], lastDroppedItem: null, i: el });
            resBox.push({ name: el, type: '2', i });
        });

        resBox = switchArrayElements(resBox, i);

        setResDustbin(resDustbin);
        setResBox(resBox);
        setIsText(true);
        setClue(clue);
    };

    const renderExerciseTitle = (el, activeTabIndex, i) => (
        <>
            {isPresentation ? customTitle :
                <input className="ExerciseContextInput"
                       value={customTitle}
                       onChange={e => { setCustomTitle(e.target.value);
                       }}
                />
            }
        </>
    );

    return (
        <div key={`tab_${activeTabIndex}_item_${i}`}
             className={(type === 'special1') ? ' SpecialDad2 ' : ''}
        >
            {!isPresentation &&
                <div>
                    <div className="col-12 dad2_render">
                        <TextField
                            placeholder={getPlaceholder()}
                            InputLabelProps={{ shrink: true }}
                            required
                            name="text"
                            value={text}
                            onChange={event => setText(event.target.value)}
                            label={"text"}
                            fullWidth
                            multiline={false}
                            margin="normal"
                            className="mt-0 mb-4"
                        />
                    </div>
                    <Button onClick={okHandlerDAD2} color="primary">Ok</Button>
                    <br /> <br />
                </div>}
            {isText &&
                <div className={l.dad2_example}>
                    <div className={l.wit_exercise_title}>
                        {renderExerciseTitle()}
                    </div>
                    {options.userInfo.userInfo.type === 'teacher' && options.demoLesson ?
                        <TeacherHelpInfo info={<div className={l.exercise_clue}>{clue}</div>} /> : null}
                    <>
                        <Container2
                            updateHomeworkErrors={options.updateHomeworkErrors}
                            updateHomeworkTasksInformation={options.updateHomeworkTasksInformation}
                            isHomework={options.isHomework}
                            isHomeworkFinish={options.isHomeworkFinish}
                            uniqId={uniqId}
                            resDustbin={resDustbin}
                            resBox={resBox}
                            socket={options.socket}
                            socketMessage={socketMessage}
                            val={{
                                vCId: options.virtualClassId,
                                userEmail: options.userInfo.userInfo.email,
                                userType: options.userInfo.userInfo.type,
                            }}
                        />
                        {myVarIsMobileDevice &&
                            <Preview generator={generatePreview}/>
                        }
                    </>
                </div>}
            {/*<br/>*/}
        </div>
    );
}

export default  Dad2;