/* global window */
import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'redux-logger';
import rootReducer from '../reducers';

export default function configureStore() {
    if (process.env.NODE_ENV === 'production') {
        return createStore(rootReducer, applyMiddleware(thunkMiddleware));
    }

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({serialize: true})
        : compose;


    return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware))
    );
}
