import { combineReducers } from 'redux';
import * as types from '../constants';

const initialState = {
    isFetching: {
        signup: false,
        signin: false,
        logout: false,
        resetStep1: false,
        resetStep2: false,
        confirm: false,
        recieveAuth: false
    },
    errors: {
        auth: null
    }
};

export const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        case types.SIGNUP_REQUEST:
            return { ...state, signup: false };
        case types.LOGIN_REQUEST:
            return { ...state, login: false };
        case types.LOGOUT_REQUEST:
            return { ...state, logout: false };
        case types.RESET_STEP1_REQUEST:
            return { ...state, resetStep1: false };
        case types.RESET_STEP2_REQUEST:
            return { ...state, resetStep2: false };
        case types.VERIFY_REQUEST:
            return { ...state, confirm: false };
        case types.RECIEVE_AUTH_REQUEST:
            return { ...state, recieveAuth: true };
        
        case types.SIGNUP_SUCCESS:
        case types.SIGNUP_FAILURE:
            return { ...state, signup: false };
        case types.LOGIN_SUCCESS:
        case types.LOGIN_FAILURE:
            return { ...state, login: false };
        case types.LOGOUT_SUCCESS:
        case types.LOGOUT_FAILURE:
            return { ...state, logout: false };
        case types.RESET_STEP1_SUCCESS:
        case types.RESET_STEP1_FAILURE:
            return { ...state, resetStep1: false };
        case types.RESET_STEP2_SUCCESS:
        case types.RESET_STEP2_FAILURE:
            return { ...state, resetStep2: false };
        case types.VERIFY_SUCCESS:
        case types.VERIFY_FAILURE:
            return { ...state, confirm: false };
        case types.RECIEVE_AUTH_SUCCESS:
        case types.RECIEVE_AUTH_FAILURE:
            return { ...state, recieveAuth: false };
        default:
            return state;
    }
};

export const errors = (state = initialState.errors, action) => {
    switch (action.type) {
        case types.SIGNUP_FAILURE:
        case types.LOGIN_FAILURE:
        case types.LOGOUT_FAILURE:
            return { ...state, auth: action.payload };
        
        case types.SIGNUP_SUCCESS:
        case types.LOGIN_SUCCESS:
        case types.LOGOUT_SUCCESS:
            return { ...state, auth: null };
        default: 
            return state;
    }
};

export default combineReducers({
    isFetching,
    // errors
});