import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { confirm, showAuthLoader, hideAuthLoader, showAuthMessage, hideAuthMessage } from '../actions';
import Confirm from '../components/Confirm';

const mapStateToProps = state => ({
    dialog: state.dialog.dialog,
    showMessage: state.auth.showMessage,
    alertTitle: state.auth.alertTitle,
    alertMessage: state.auth.alertMessage,
    loader: state.auth.loader,
});

const mapDispatchToProps = dispatch => 
    bindActionCreators(
        {
            confirm,
            showAuthLoader,
            hideAuthLoader,
            showAuthMessage,
            hideAuthMessage
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);