export const LESSONS_HOUR_START = 0;
export const LESSONS_HOUR_END = 24;
export const TIME_PERIODS_IN_HOUR = 2;
export const SMALL_LESSON_LENGTH = 30; // minute
import lang from '../../../../utils/lang';

export const monthsArrayRu = [
    lang('Январь'),
    lang('Февраль'),
    lang('Март'),
    lang('Апрель'),
    lang('Май'),
    lang('Июнь'),
    lang('Июль'),
    lang('Август'),
    lang('Сентябрь'),
    lang('Октябрь'),
    lang('Ноябрь'),
    lang('Декабрь')
];

export const monthsArrayRuGenitive = [
    lang('Января'),
    lang('Февраля'),
    lang('Марта'),
    lang('Апреля'),
    lang('Мая'),
    lang('Июня'),
    lang('Июля'),
    lang('Августа'),
    lang('Сентября'),
    lang('Октября'),
    lang('Ноября'),
    lang('Декабря')
];

export const weekDaysArrayEn = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

export const weekDaysArrayEnDayIndex = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];

export const weekDaysArrayRu = [
    lang('Понедельник'),
    lang('Вторник'),
    lang('Среда'),
    lang('Четверг'),
    lang('Пятница'),
    lang('Суббота'),
    lang('Воскресенье')
];

/**
@timeTableArray =
[
 {
    begin: '01.02.2022',
    end: '07.02.2022',
    timeTableTime: [
        {
            timeStart: 8.0, // 08:00
            timeEnd: 8.5, // 08:30
            Monday: {dayName: 'Понедельник},
            Tuesday: {dayName: 'Вторник},
        },
        {
            timeStart: 8.5, // 08:30
            timeEnd: 9.0, // 09:00
            Monday: {dayName: 'Понедельник},
            Tuesday: {dayName: 'Вторник},
        }
    ],
 },
 {
    begin: '08.02.2022',
    end: '15.02.2022',
    timeTableTime: [
        {
            timeStart: 8.0, // 08:00
            timeEnd: 8.5, // 08:30
            Monday: {dayName: 'Понедельник},
            Tuesday: {dayName: 'Вторник},
        },
        {
            timeStart: 8.5, // 08:30
            timeEnd: 9.0, // 09:00
            Monday: {dayName: 'Понедельник},
            Tuesday: {dayName: 'Вторник},
        }
    ],
 },
 ...
]
 */

export const prepareTimeTable = (timeTableArray, lessonsHourStart = LESSONS_HOUR_START, lessonsHourEnd = LESSONS_HOUR_END) => {
    const weekDaysArrayForCurrentLang = weekDaysArrayRu;

    timeTableArray.forEach(row => {
        row.timeTableTime = [];

        let minute = 0;

        for (let i = 0; i < (lessonsHourEnd - lessonsHourStart) * TIME_PERIODS_IN_HOUR; ++i) {
            row.timeTableTime.push({
                timeStart: lessonsHourStart + i * (1 / TIME_PERIODS_IN_HOUR),
                timeEnd: lessonsHourStart + (i + 1) * (1 / TIME_PERIODS_IN_HOUR),
                timeStartObj: {
                    hour: lessonsHourStart + Math.floor(i / TIME_PERIODS_IN_HOUR),
                    minute,
                }
            });

            minute = minute ? 0 : SMALL_LESSON_LENGTH;

            weekDaysArrayEn.forEach((dayName, dayNum) => {
                row.timeTableTime[i][dayName] = {dayName: weekDaysArrayForCurrentLang[dayNum]};
            })
        }
    });

    return timeTableArray;
}

export const addDays = function (currentDate, days) {
    let date = new Date(currentDate);
    date.setDate(date.getDate() + days);
    return date;
};

export const getDatesInWeeks = (startDate, endDate) => {
    let dates = [];

    let currentDate = startDate;

    currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1);

    while (currentDate <= endDate) {
        let endWeekDate = addDays(currentDate, 6);
        dates.push({
            begin: currentDate,
            end: endWeekDate
        });
        currentDate = addDays(currentDate, 7);
    }

    return dates;
};

export const getDaysBetweenTwoDates = (dateOne, dateTwo) =>{
    let difference = dateTwo.getTime() - dateOne.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
}

export const formatCellTime = time => {
    const timeString = time + '';
    if (timeString.length === 4) {
        return (timeString.substring(0, 2) + ':30');
    } else if (timeString.length === 3) {
        return ('0' + timeString.substring(0, 1) + ':30');
    } else if (timeString.length === 2) {
        return (timeString + ':00');
    } else if (timeString.length === 1) {
        return ('0' + timeString + ':00');
    }

    return timeString;
}

export const prepareTimeTableWorkHoursArr = () => {
    const timeTableWorkHoursArr = [];

    for (let i = 0; i < LESSONS_HOUR_END; i++) {
        let label = i + '';
        if (label.length === 1) label = '0' + label;
        label = label + ' : 00';

        timeTableWorkHoursArr.push({
            value: i,
            label,
        });
    }

    return timeTableWorkHoursArr;
}