import './Folders.scss'
import React, {useEffect, useState} from 'react';
import { FoldersData as fD } from './FoldersData';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import * as types from '../../../../constants/folders';
import callApi from '../../../../utils/call-api';

let FoldersData = fD;

const Folders = ({isActive, openClose, from, studentsByCourseList}) => {

    const [state, setState] = useState({
        isAddActive: false,
        isEditActive: false,
        inputIncorrect: false,
        editIndex: null,
        folderName: '',
        editName: '',
        studentsActive: [],
        selectValue: [],
        defaultValue: [],
        delete: [],
    });
    const dispatch = useDispatch();
    const foldersData = useSelector(state => state.folders.foldersData);


    function setUsersFolders(folders) {
        callApi('dashboard/setUsersFolders', 'POST', {
            folders
        }).then(res => {

        }).catch(() => {});
    }

    useEffect(() => {
        callApi('dashboard/getUsersFolders', 'GET', {}).then(res => {
            if (res && res.data && res.data.usersFolders) {
                FoldersData = res.data.usersFolders;

                localStorage.setItem('foldersStudents', JSON.stringify(FoldersData));
                dispatch({type: types.CHANGE_FOLDERS, payload: FoldersData});
            }
        }).catch(() => {});

    }, []);

    useEffect(() => {
        const studentsList = []
        if (studentsByCourseList && studentsByCourseList.activeCourses && studentsByCourseList.activeCourses.all) {
            studentsByCourseList.activeCourses.all.map(item => {
                studentsList.push({
                    value: item,
                    label: `${item.name} ${item.surname}`
                })
            })
        }
        setState({...state, studentsActive: studentsList});
    }, [studentsByCourseList])

    function createFolder() {
        const index = FoldersData.findIndex(e => e.label === state.folderName);

        if (state.folderName.length > 1 && from === 'students' && index === -1) {
            FoldersData.push({value: state.selectValue, label: state.folderName});
            localStorage.setItem('foldersStudents', JSON.stringify(FoldersData));
            setState({...state, isAddActive: false, folderName: '', selectValue: [], inputIncorrect: false});
            dispatch({type: types.CHANGE_FOLDERS, payload: FoldersData});

            setUsersFolders(FoldersData);
        } else {
            setState({...state, inputIncorrect: true});
        }
    }

    function deleteFolder(index) {
        FoldersData.splice(index, 1);
        localStorage.setItem('foldersStudents', JSON.stringify(FoldersData));
        dispatch({type: types.CHANGE_FOLDERS, payload: FoldersData});
        setState({...state, delete: FoldersData});
        setUsersFolders(FoldersData);
    }

    function saveEdit() {
        const index = FoldersData.findIndex(e => e.label === state.editName);

        if (index === state.editIndex || index === -1 || index === 0) {
            setState({...state, isEditActive : !state.isEditActive, inputIncorrect: false});
            FoldersData[state.editIndex].label = state.editName;
            FoldersData[state.editIndex].value = state.defaultValue;
            localStorage.setItem('foldersStudents', JSON.stringify(FoldersData));
            dispatch({type: types.CHANGE_FOLDERS, payload: FoldersData});
            setUsersFolders(FoldersData);
        } else {
            setState({...state, inputIncorrect: true});
        }
    }

    function editFolder(index) {
        const defValue = [];

        if (foldersData[index].value) {
            foldersData[index].value.map(item =>{
                const indexItem = state.studentsActive.findIndex(e => e.value.id === item.value.id);
                defValue.push(state.studentsActive[indexItem]);
            })
        }

        setState({
            ...state,
            isEditActive: !state.isEditActive,
            editName: FoldersData[index].label,
            editIndex: index,
            defaultValue: defValue,
        });
    }

    return (
        <div className={isActive ? 'folders_container_visible' : 'folders_hidden'}>
            {state.isEditActive || state.isAddActive ? null :
                <div
                    className="ModalCloseButton folder_close_button"
                    onClick={()=> {
                        setState({...state, isAddActive: false});
                        openClose();
                    }}
                ></div>
            }
            <div className='folders_body'>
                <div className='folders_button_add' onClick={()=> setState({...state, isAddActive : !state.isAddActive})}>ADD</div>
                <div className='list_container'>
                    <div className='folders_list'>
                        {foldersData && foldersData.length > 0 ?
                            foldersData.map(item =>{
                                const index = foldersData.indexOf(item)
                                return (
                                    <>
                                        <div key={item.label} className='folder_container'>
                                            <div className='folder'>
                                                <div className='folder_edit folder_buttons'
                                                     onClick={()=> editFolder(index)}
                                                ></div>
                                                <div className='folder_body'>{item.label}</div>
                                                <div className='folder_delete folder_buttons'
                                                     onClick={()=> deleteFolder(index)}
                                                ></div>
                                            </div>
                                        </div>

                                    </>
                                )
                            })
                            : null
                        }
                    </div>
                </div>
            </div>

            <div className={state.isAddActive ? 'folders_add_container' : 'folders_hidden'}>
                <div className='folders_add_name'>
                    <div className='add_header'>
                        <div>folder name: </div>
                        <input
                            className={state.inputIncorrect ? 'input_red' : null}
                            type="text"
                            value={state.folderName}
                            onInput={(event)=> setState({...state, folderName: event.target.value})}
                        />
                        <div className='add_button button_red'
                             onClick={()=> setState({
                                 ...state, isAddActive : !state.isAddActive,
                                 inputIncorrect: false,
                                 folderName: '',
                             })}
                        >Cancel</div>
                        <div className='add_button button_blue'
                             onClick={()=> createFolder()}
                        >Create</div>
                    </div>
                    <div>
                        <Select
                            isMulti
                            value={state.selectValue}
                            onChange={e => setState({...state, selectValue: e})}
                            name="students"
                            options={state.studentsActive}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                </div>
            </div>
            <div className={state.isEditActive ? 'folders_add_container' : 'folders_hidden'}>
                <div className='folders_add_name'>
                    <div className='add_header'>
                        <div>folder name:</div>
                        <input
                            className={state.inputIncorrect ? 'input_red' : null}
                            type="text"
                            value={state.editName}
                            onInput={(event)=> setState({...state, editName: event.target.value})}
                        />
                        <div className='add_button button_red'
                             onClick={()=> setState({...state, isEditActive : !state.isEditActive, inputIncorrect: false})}
                        >Cancel</div>
                        <div className='add_button button_blue'
                             onClick={()=> saveEdit()}
                        >Save</div>
                    </div>
                    <div>
                        <Select
                            value={[...state.defaultValue]}
                            isMulti
                            onChange={e => setState({...state, defaultValue: e})}
                            name="students"
                            options={state.studentsActive}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Folders;