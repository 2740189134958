import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import LandingBiddersRouter from './landingBidder/Router';
import NotCpaRouter from './notCpa/Router';
import LessonsRouter from './lessons/Router';
import Report from './report/Router';
import Settings from './settings/Router';
import LessonIndex from '../virtualLesson/LessonIndex';
import Config from '../../config';


const Index = ({match}) => {
    const {path} = match;

    return (
        <Switch>
            <Redirect exact from={Config.path + "/brand"} to={Config.path + "/"} push/>
            {/*<Route exact path={`${path}/report`} component={Report}/>*/}
            {/*<Route path={`${path}/landing-bidders`} component={LandingBiddersRouter}/>*/}
            {/*<Route path={`${path}/not-cpa`} component={NotCpaRouter}/>*/}
            {/*<Route path={`${path}/lessons`} component={LessonsRouter}/>*/}
            {/*<Route path={`${path}/settings`} component={Settings}/>*/}
            <Route path={`${path}/vl`} component={LessonIndex}/>
            <Redirect to={`${path}`} push/>
        </Switch>
    );
};

export default Index;
