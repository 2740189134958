export * from './auth';
export * from './audit';
export * from './dialog';
export * from './services';
export * from './settings';
export * from './brand';
export * from './dictionary';
export * from './likes';
export * from './homeWork';
export * from './folders';

export const DIALOG_SELECT_LESSON = 'DIALOG_SELECT_LESSON';
export const DIALOG_SET_WHITEBOARD = 'DIALOG_SET_WHITEBOARD';
export const SET_LESSON_IDENT = 'SET_LESSON_IDENT';
export const SET_LESSON_SOCKET = 'SET_LESSON_SOCKET';
export const SET_LESSONS_ARR = 'SET_LESSONS_ARR';
export const SET_CHART_MESSAGE = 'SET_CHART_MESSAGE';
export const SET_USERS_INFO = 'SET_USERS_INFO';
export const SET_IMG_WHITEBOARD = 'SET_IMG_WHITEBOARD';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';