import * as types from '../constants/dialog';

const initialState = {
    dialog: false,
    show: false,
    tabNumber: 1,

    hide: false,
    lesson: null,
    whiteboard: {
        show: false,
        showBg: false,
        color: '#000',
        width: '10',
        clear: false,
    },
    socketMessage: {command: null},
    gradeParticipant: 0,
    showImgWhiteboard: false,
};

export default function dialog(state = initialState, action) {
    switch (action.type) {
        case types.SET_CHART_MESSAGE:
            return {
                ...state,
                socketMessage: action.payload
            };
        case types.SET_IMG_WHITEBOARD:
            return {
                ...state,
                showImgWhiteboard: action.payload
            };
        case types.DIALOG_SET_WHITEBOARD:
            return {
                ...state,
                whiteboard: action.payload
            };
        case types.DIALOG_SELECT_LESSON:
            return {
                ...state,
                lesson: action.payload
            };
        case types.SHOW_DIALOG:
            return {
                ...state,
                dialog: true,
                hide: false,
                show: true
            };
        case types.HIDE_DIALOG:
            return {
                ...state,
                dialog: false,
                show: false,
                hide: true
            };
        case types.TAKE_GRADE:
            return {
                ...state,
                gradeParticipant: action.payload
            }
        default:
            return state;
    }
};
