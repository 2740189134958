import * as types from '../constants';

const initialState = {
    addDomain: false,
    editDomain: false,
    updateDomain: false,
    showMessage: false,
};

export default function audit(state = initialState, action) {
    switch (action.type) {
        case types.ADD_DOMAIN_SUCCESS: 
            return {
                ...state,
                addDomain: true,
                showMessage: false
            };
        case types.EDIT_DOMAIN_SUCCESS:
            return {
                ...state,
                editDomain: true,
                showMessage: false
            }
        case types.UPDATE_DOMAIN_SUCCESS:
            return {
                ...state,
                updateDomain: true,
                showMessage: false
            };
        case types.ADD_DOMAIN_FAILURE:
        case types.EDIT_DOMAIN_FAILURE:
        case types.UPDATE_DOMAIN_FAILURE:
            return {
                ...state,
                showMessage: true
            };
        case types.ADD_DOMAIN_REQUEST:
        case types.EDIT_DOMAIN_REQUEST:
        case types.UPDATE_DOMAIN_REQUEST:
            return {
                ...state,
                showMessage: false
            };
        default:
            return state;
    }
};