import IntlMessages from "../../utils/IntlMessages";
import AuthLogo from "./imgs/auth_logo.png";
import React, {useState} from "react";
import {Alert} from "reactstrap";
import lang from "../../utils/lang";
import TextField from "./TextField";
import {NavLink} from "react-router-dom";
import Config from "../../config";
import {validateEmail} from "./helpers";
import callApi from '../../utils/call-api';

const PassRecovery = () => {

    const [state, setState] = useState({
        isSending: false,
        sendEmail: false,
        enterEmail: '',
        isError: false,
        isNoFound: false,
    })


    const isValidForm = validateEmail(state.enterEmail);


    let afterSendMessage = state.isError ?
        'Возникла ошибка. Обратитесь к администратору' :
        'Пароль был отправлен на указанный вами e-mail';

    if (state.isNoFound) {
        afterSendMessage = 'Пользователь с таким email не найден';
    }

    const additionalFormClass = state.sendEmail ? ' additionalFormClass' : ' ';
    return (
        <div className="AuthComponentContainer">
            <div className="AuthComponent">
                <div className="AuthComponentHeader"
                >
                    <div style={{position: 'absolute'}}><IntlMessages id="Забыли пароль?" /></div>
                </div>
                <div className="AuthComponentLogo">
                    <img alt="LOGO" src={AuthLogo}/>
                </div>

                <div className="AuthComponentPath">
                    <div className={"AuthComponentPathSignin"}>

                        <div className="AuthComponentPathForm AuthComponentPathFormSignin">
                            <div className={"recoveryMessageTop" + additionalFormClass}>
                                {state.sendEmail ?
                                    <IntlMessages id={afterSendMessage} />
                                    :
                                    <IntlMessages id="Введите e-mail ,на который вы регистрировались, и мы отправим вам на него письмо с паролем" />
                                }
                            </div>
                            {state.sendEmail ?
                                null
                                :
                                <TextField
                                    type="email"
                                    label="Email"
                                    value={state.enterEmail}
                                    handleInputChange={val => setState({...state, enterEmail: val})}
                                />
                            }


                            <div className="AuthComponentPathSigninLinks">
                                {state.sendEmail ?
                                    <div
                                        className="AuthComponentPathSigninLinksButton"
                                    >
                                        <NavLink style={{ textDecoration: 'none', color: 'white' }} to={Config.path + "/enter"}>
                                            <IntlMessages id="Вернуться назад"/>
                                        </NavLink>
                                    </div>
                                    :
                                    <div
                                        className="AuthComponentPathSigninLinksButton"
                                        style={isValidForm ? {} : { pointerEvents: 'none', filter: 'grayscale(90%)' }}
                                        onClick={() => {
                                            if (state.isSending) return;

                                            setState({ ...state, isSending: true });

                                            callApi('auth/passRecovery', 'post', {
                                                email: state.enterEmail,
                                            })
                                                .then(res => {
                                                    if (res && res.data && res.data.ok) {
                                                        setState({ ...state, sendEmail: true,  isError: false, isSending: false });
                                                    } else if (res && res.data && res.data.message === 'notFound') {
                                                        setState({ ...state, sendEmail: true,  isError: true, isSending: false, isNoFound: true, });
                                                    } else {
                                                        setState({ ...state, sendEmail: true,  isError: true, isSending: false });
                                                    }
                                                })
                                                .catch(() => {
                                                    setState({ ...state, sendEmail: true,  isError: true, isSending: false });
                                                });
                                        }}
                                    >
                                        <IntlMessages id="Отправить пароль"/>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="AuthComponentPathSigninRocket"/>
                    </div>
                </div>
                <div className="AuthComponentSiteName"
                     onClick={() => {
                         window.open('https://www.epiclang.com');
                     }}
                >
                    www.epiclang.com
                </div>
            </div>
        </div>
    )
}

export default PassRecovery;