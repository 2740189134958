import React from 'react';
import PropTypes from 'prop-types';
import {Switch, Route, Redirect} from 'react-router-dom';
import asyncComponent from '../../../utils/asyncComponent';
import Add from './Add';
import Edit from './Edit';

const Router = props => {
    const {path} = props.match;
    return (
        <Switch>
            <Route exact path={`${path}`} component={asyncComponent(() => import('./Container'))}/>
            <Route exact path={`${path}/add`} component={Add}/>
            <Route exact path={`${path}/edit`} component={Edit}/>
            <Redirect to={`${path}`} push/>
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.object
};

export default Router;
